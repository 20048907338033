import Api from '~/src/helpers/http-common';
import { IHousingEstate } from './housing.estate';
import { IHousingEstateHouse } from './housing.estate.house';
import { IResponseListData, IUpdateData } from '../interfaces/common';
import { IHousingEstateHousePorch } from './housing.estate.house.porch';
import { IHousingEstateHousePorchStoreyFlat } from './housing.estate.house.porch.storey.flat';

export const all = async (
    queryParams?: URLSearchParams,
): Promise<IResponseListData<IHousingEstateHousePorchStorey>> => {
    return await Api.get(
        `/housing-estate/house/porch/storey/?${queryParams != null ? queryParams.toString() : ''}`,
    );
};

export const create = async (
    data: IHousingEstateHousePorchStorey,
    files?: Record<string, FileList>,
): Promise<IHousingEstateHousePorchStorey | any> => {
    return (await Api.post<IHousingEstateHousePorchStorey>(
        '/housing-estate/house/porch/storey/',
        data,
        files,
    )) as IHousingEstateHousePorchStorey;
};

export const read = async (
    id: number,
): Promise<IHousingEstateHousePorchStorey> => {
    return await Api.get(`/housing-estate/house/porch/storey/${id}`);
};

export const update = async (
    id: string | number,
    data: IHousingEstateHousePorchStorey,
): Promise<IUpdateData<IHousingEstateHousePorchStorey>> => {
    return await Api.put<IUpdateData<IHousingEstateHousePorchStorey>>(
        `/housing-estate/house/porch/storey/${id}`,
        data,
    );
};

export const remove = async (
    id: string | number,
): Promise<IUpdateData<IHousingEstateHousePorchStorey>> => {
    return await Api.delete(`/housing-estate/house/porch/storey/${id}`);
};

export interface IHousingEstateHousePorchStorey {
    id?: number;
    housingEstateId?: number;
    housingEstateHouseId?: number;
    housingEstateHousePorchId?: number;
    layout?: string;
    number?: number;
    ceiling?: number;
    roominess?: number;
    createdAt?: Date;
    createdBy?: string;
    updatedAt?: Date;
    updatedBy?: string;
    estate?: IHousingEstate;
    house?: IHousingEstateHouse;
    porch?: IHousingEstateHousePorch;
    flats?: IHousingEstateHousePorchStoreyFlat[];

    floorsTotal?: number;
}
