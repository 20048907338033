import React, { FC } from 'react';
import { Badge } from 'react-bootstrap';
import { FormSpy } from 'react-final-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ShowErrorsBadge: FC<{ fields: string[] }> = ({ fields }): JSX.Element => {
    return (
        <FormSpy subscription={{ errors: true }}>
            {({ errors }) => {
                return (
                    <React.Fragment>
                        {fields.some((field) => errors[field]) && (
                            <Badge bg='danger' title='Есть ошибки'>
                                <FontAwesomeIcon
                                    icon={['fas', 'exclamation-circle']}
                                />
                            </Badge>
                        )}
                    </React.Fragment>
                );
            }}
        </FormSpy>
    );
};

export default ShowErrorsBadge;
