const suburbanHeatingSearch = [
    { label: 'Все', value: '' },
    { label: 'Газовое', value: 'gas' },
    { label: 'Печное', value: 'stove' },
    { label: 'Центральное', value: 'central' },
    { label: 'Электрическое', value: 'electricity' },
];

const suburbanHeating = [
    { title: '', value: '' },
    { title: 'Газовое', value: 'gas' },
    { title: 'Печное', value: 'stove' },
    { title: 'Центральное', value: 'central' },
    { title: 'Электрическое', value: 'electricity' },
];

export { suburbanHeatingSearch, suburbanHeating };
