import { NotificationManager } from 'react-notifications';

const createNotification = (props: TNProps): void => {
    const { type, title, message, timeout, cb, args, priority } = props;

    const onClick = cb instanceof Function ? cb.apply(cb, args) : null;

    NotificationManager.create({
        type,
        message,
        title,
        timeout,
        onClick,
        priority,
    });
};

export const successNotify = (props: TNProps): void => {
    createNotification({ ...props, type: 'success' });
};

export const warningNotify = (props: TNProps): void => {
    createNotification({ ...props, type: 'warning' });
};

export const errorNotify = (props: TNProps): void => {
    createNotification({ ...props, type: 'error' });
};

type TNProps = {
    type?: string;
    title?: string;
    message: string;
    timeout?: number;
    cb?: () => void;
    args?: Record<string, unknown>[];
    priority?: unknown;
};

export default createNotification;
