import { useQuery } from '~/src/helpers/hooks';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '~/src/components/Common/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BaseSyntheticEvent, useCallback } from 'react';
import { redirect } from '~/src/store/appSlice';
import {
    Button,
    ButtonGroup,
    Col,
    Form,
    Navbar,
    Row,
    Table,
} from 'react-bootstrap';
import ListRow from './ListRow';
import { selectAddress, all, goTo } from '~/src/store/address';
import QuickSearch from './QuickSearch';

const ListView = (): JSX.Element => {
    const query = useQuery();
    const dispatch = useDispatch();

    const { items, search } = useSelector(selectAddress);

    const sortHandler = useCallback(
        (e: BaseSyntheticEvent) => {
            dispatch(goTo({ sort: e.currentTarget.value }));
        },
        [dispatch],
    );

    const limitHandler = useCallback(
        (e: BaseSyntheticEvent) => {
            dispatch(goTo({ limit: e.currentTarget.value }));
        },
        [dispatch],
    );

    const handlePaginationClick = (page: number) => {
        dispatch(goTo({ page }));
    };

    return (
        <>
            <Navbar
                className='justify-content-between'
                bg='light'
                expand='lg'
                variant='light'
            >
                <ButtonGroup>
                    <Button
                        size='sm'
                        variant='secondary'
                        onClick={() => dispatch(all(query))}
                    >
                        <FontAwesomeIcon icon={['fas', 'sync']} /> Обновить
                    </Button>
                    <Button
                        size='sm'
                        variant='danger'
                        onClick={() => dispatch(redirect('/address'))}
                    >
                        <FontAwesomeIcon icon={['fas', 'sync']} /> Сбросить
                        фильтр
                    </Button>
                </ButtonGroup>
            </Navbar>
            <Row className='mb-2'>
                <Col xs={4}>
                    <Form.Label>Сортировать</Form.Label>
                    <Form.Select
                        className='form-control'
                        size='sm'
                        value={search?.sort || 'price_asc'}
                        onChange={sortHandler}
                    >
                        <option value='id_asc'>ID &uarr;</option>
                        <option value='id_desc'>ID &darr;</option>
                    </Form.Select>
                </Col>
                <Col xs={4}>
                    <Form.Label>На странице</Form.Label>
                    <Form.Select
                        className='form-control'
                        size='sm'
                        value={search?.limit || 25}
                        onChange={limitHandler}
                    >
                        <option value={1}>1</option>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={100}>100</option>
                    </Form.Select>
                </Col>
                <Col xs={4}>
                    <QuickSearch />
                </Col>
            </Row>
            {items && items.pagination?.have && (
                <Pagination
                    pagination={items.pagination}
                    onClick={handlePaginationClick}
                />
            )}
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <td>#</td>
                        <td>Полный адрес</td>
                        <td>Инфо</td>
                        <td>Управление</td>
                    </tr>
                </thead>
                <tbody>
                    {items && items.items && items.items.length > 0
                        ? items.items.map((item) => (
                              <ListRow key={item.id} item={item} />
                          ))
                        : null}
                </tbody>
            </Table>
            {items && items.pagination?.have && (
                <Pagination
                    pagination={items.pagination}
                    onClick={handlePaginationClick}
                />
            )}
        </>
    );
};

export default ListView;
