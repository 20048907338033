import appSlice, { sliceName as appSliceName } from '~/src/store/appSlice';
import authSlice, { sliceName as authSliceName } from '~/src/store/authSlice';
import usersSlice, {
    sliceName as usersSliceName,
} from '~/src/store/usersSlice';
import usersPASlice, {
    sliceName as usersPASliceName,
} from '~/src/store/paSlice';
import agencySlice, {
    sliceName as agencySliceName,
} from '~/src/store/agencySlice';
import RSSlice, {
    sliceName as RSSliceName,
} from '~/src/store/realtySecondary/realtySecondarySlice';
import RSMSlice, {
    sliceName as RSMSliceName,
} from '~/src/store/realtySecondary/realty.secondary.moderate';

import RSUBSlice, {
    sliceName as RSUBSliceName,
} from '~/src/store/realtySuburban/realtySuburbanSlice';
import RSUBMapSlice, {
    sliceName as RSUBMapSliceName,
} from '~/src/store/realtySuburban/displayMarkerMapSlice';
import RSUBMSlise, {
    sliceName as RSUBMSliseName,
} from '~/src/store/realtySuburban/realtySuburbanSlice.moderate';

import agencyAgentsSlice, {
    sliceName as agencyAgentsSliceName,
} from '~/src/store/agencyAgentsSlice';
import housingEstateSlice, {
    sliceName as housingEstateSliceName,
} from '~/src/store/housingEstateSlice';
import housingEstateHouseSlice, {
    sliceName as housingEstateHouseSliceName,
} from '~/src/store/housingEstateHouseSlice';

import permissionSlice, {
    sliceName as permissionSliceName,
} from '~/src/store/permission';

import developerSlice, {
    sliceName as developerSliceName,
} from '~/src/store/developer/developerSlice';
import developerHPSlice, {
    sliceName as developerHPSliceName,
} from '~/src/store/developer/developer.house.project';

import changeResponsibleOnDHPSlice, {
    sliceName as changeResponsibleOnDHPSliceName,
} from '~/src/store/developer/developer.house.project.changeResponsible';

import agencySettingsSlice, {
    sliceName as agencySettingsSliceName,
} from '~/src/store/agency.settings';

import agencyStatisticsSlice, {
    sliceName as agencyStatisticsSliceName,
} from '~/src/store/agency.statistics';
import agencyPermissionSlice, {
    sliceName as agencyPermissionSliceName,
} from '~/src/store/agency.permission';

import commentSlice, {
    sliceName as commentSliceName,
} from '~/src/store/comment';
import contentSlice, {
    sliceName as contentSliceName,
} from '~/src/store/content';
import addressSlice, {
    sliceName as addressSliceName,
} from '~/src/store/address';
import xmlFeedSlice, {
    sliceName as xmlFeedSliceName,
} from '~/src/store/xml.feed';
import filestoreSlice, {
    sliceName as filestoreSliceName,
} from '~/src/store/filestore';

import infoSlice, {
    sliceName as infoSliceName,
} from '~/src/store/info.agency.users.slice';

import agencyGroupSlice, {  sliceName as agencyGroupSliceName } from '~/src/store/agency.group.slice';

import buyerListSlice, {
    sliceName as buyerSliceName,
} from '~/src/store/buyer/buyer.list.slice';

import megasaleSlice, {
    sliceName as megasaleSliceName,
} from '~/src/store/megasaleSlice';
import DirectionSlice, {
    sliceName as DirectionSliceName,
} from '~/src/store/directionSlice';

import imageSlice, {
    sliceName as imageSliceName,
} from '~/src/store/realtySecondary/displayMarkerSlice';

import changePersonOnHousingSlice, {
    sliceName as changePersonOnHousingSliceName,
} from '~/src/store/changePersonOnHousingSlice';
import livingAreaSlice, {
    sliceName as livingAreaSliceName,
} from '~/src/store/livingAreaSlice';

import cottageVillageSlice, {
    sliceName as cottageVillageSliceName,
} from '~/src/store/cottage.village/cottage.village.store';
import cottageVillagePlotSlice, {
    sliceName as cottageVillagePlotSliceName,
} from '~/src/store/cottage.village/cottage.village.plot.store';
import changeResponsibleOnCVSlice, {
    sliceName as changeResponsibleOnCVSliceName,
} from '~/src/store/cottage.village/cottage.village.changeResponsible';

import { configureStore } from '@reduxjs/toolkit';

export type TState = ReturnType<typeof store.getState>;
export type TAppDispatch = typeof store.dispatch;
export type TAnyAction<T> = { type: string; payload: T };

const store = configureStore({
    reducer: {
        [RSSliceName]: RSSlice,
        [RSMSliceName]: RSMSlice,

        [RSUBSliceName]: RSUBSlice,
        [RSUBMapSliceName]: RSUBMapSlice,
        [RSUBMSliseName]: RSUBMSlise,

        [appSliceName]: appSlice,
        [authSliceName]: authSlice,
        [usersSliceName]: usersSlice,
        [usersPASliceName]: usersPASlice,
        [agencySliceName]: agencySlice,
        [agencyGroupSliceName]: agencyGroupSlice,
        [commentSliceName]: commentSlice,
        [contentSliceName]: contentSlice,
        [addressSliceName]: addressSlice,
        [filestoreSliceName]: filestoreSlice,

        [developerSliceName]: developerSlice,
        [developerHPSliceName]: developerHPSlice,

        [agencyAgentsSliceName]: agencyAgentsSlice,
        [housingEstateSliceName]: housingEstateSlice,
        [housingEstateHouseSliceName]: housingEstateHouseSlice,

        [cottageVillageSliceName]: cottageVillageSlice,
        [cottageVillagePlotSliceName]: cottageVillagePlotSlice,
        [changeResponsibleOnCVSliceName]: changeResponsibleOnCVSlice,

        [changeResponsibleOnDHPSliceName]: changeResponsibleOnDHPSlice,

        [permissionSliceName]: permissionSlice,
        [agencySettingsSliceName]: agencySettingsSlice,
        [agencyPermissionSliceName]: agencyPermissionSlice,
        [agencyStatisticsSliceName]: agencyStatisticsSlice,

        [xmlFeedSliceName]: xmlFeedSlice,

        [infoSliceName]: infoSlice,
        [buyerSliceName]: buyerListSlice,

        [imageSliceName]: imageSlice,
        [changePersonOnHousingSliceName]: changePersonOnHousingSlice,
        [livingAreaSliceName]: livingAreaSlice,

        [megasaleSliceName]: megasaleSlice,
        [DirectionSliceName]: DirectionSlice,
    },
    devTools: true,
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
