import React, { useState, useEffect, BaseSyntheticEvent } from 'react';
import IList from './IList';
import Select from 'react-select';
import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';
import DatePicker from 'react-datepicker';
import { useQuery } from '~/src/helpers/hooks';
import { selectAuth } from '~/src/store/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    all,
    selectAgencyStatistics,
    goTo,
} from '~/src/store/agency.statistics';

import {
    Col,
    Row,
    Form,
    Table,
    Navbar,
    Button,
    Container,
    InputGroup,
    ButtonGroup,
} from 'react-bootstrap';

type TFormFilter = {
    agentUid?: string;
    agencyGroupId?: number;
    dateFrom?: string;
    dateTo?: string;
};

const ListView = (): JSX.Element => {
    const dispatch = useDispatch();
    const query = useQuery();

    const { me } = useSelector(selectAuth);
    const { items, search } = useSelector(selectAgencyStatistics);
    const [formFilter, setFormFilter] = useState<TFormFilter>({});

    console.log(items);

    useEffect(() => {
        setFormFilter(search);
    }, [search]);

    const handleChangeValue = (e: BaseSyntheticEvent) => {
        setFormFilter({ ...formFilter, [e.target.name]: e.target.value });
    };

    const handleChangeDateValue = (name: string, value: string) => {
        setFormFilter({ ...formFilter, [name]: value });
    };

    const onSelectChange = (
        field: string,
        o: { label: string; value: string },
    ) => {
        setFormFilter({ ...formFilter, [field]: o.value });
    };

    const agents = me?.agencyInfo?.agencyAgents?.map(({ fullname, uid }) => {
        return {
            value: uid,
            label: fullname,
        };
    });

    return (
        <React.Fragment>
            <Navbar
                className='justify-content-between'
                bg='light'
                expand='lg'
                variant='light'
            >
                <ButtonGroup>
                    <Button
                        size='sm'
                        variant='secondary'
                        onClick={() => dispatch(all(query))}
                    >
                        <FontAwesomeIcon icon={['fas', 'sync']} /> Обновить
                    </Button>
                    <Button
                        size='sm'
                        variant='danger'
                        onClick={() => dispatch(goTo({ reset: true }))}
                    >
                        <FontAwesomeIcon icon={['fas', 'sync']} /> Сбросить
                    </Button>
                </ButtonGroup>
                <ButtonGroup>
                    <Button
                        size='sm'
                        variant='primary'
                        onClick={() => dispatch(goTo(formFilter))}
                    >
                        <FontAwesomeIcon icon={['fas', 'search']} /> Найти
                    </Button>
                </ButtonGroup>
            </Navbar>
            <Container>
                <Row>
                    <Col sm='6'>
                        <Form.Group className='mb-2'>
                            <Form.Label>
                                <strong>Период</strong>
                            </Form.Label>
                            <Row>
                                <Col>
                                    <InputGroup className='flex-nowrap'>
                                        <InputGroup.Text>
                                            <FontAwesomeIcon
                                                icon={['fas', 'calendar-alt']}
                                            />
                                        </InputGroup.Text>
                                        <DatePicker
                                            className='form-control'
                                            locale={ru}
                                            dateFormat='yyyy-MM-dd'
                                            selected={
                                                formFilter.dateFrom
                                                    ? new Date(
                                                        formFilter.dateFrom,
                                                    )
                                                    : null
                                            }
                                            openToDate={
                                                formFilter.dateFrom
                                                    ? new Date(
                                                        formFilter.dateFrom,
                                                    )
                                                    : new Date()
                                            }
                                            name='dateFrom'
                                            showYearDropdown
                                            dropdownMode='select'
                                            placeholderText='от'
                                            onChange={(e) => {
                                                handleChangeDateValue(
                                                    'dateFrom',
                                                    format(
                                                        new Date(e),
                                                        'yyyy-MM-dd',
                                                    ),
                                                );
                                            }}
                                            autoComplete='off'
                                        />
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <InputGroup className='flex-nowrap'>
                                        <InputGroup.Text>
                                            <FontAwesomeIcon
                                                icon={['fas', 'calendar-alt']}
                                            />
                                        </InputGroup.Text>
                                        <DatePicker
                                            className='form-control'
                                            locale={ru}
                                            dateFormat='yyyy-MM-dd'
                                            selected={
                                                formFilter.dateTo
                                                    ? new Date(
                                                        formFilter.dateTo,
                                                    )
                                                    : null
                                            }
                                            openToDate={
                                                formFilter.dateTo
                                                    ? new Date(
                                                        formFilter.dateTo,
                                                    )
                                                    : new Date()
                                            }
                                            name='dateTo'
                                            showYearDropdown
                                            dropdownMode='select'
                                            placeholderText='до'
                                            autoComplete='off'
                                            onChange={(e) => {
                                                handleChangeDateValue(
                                                    'dateTo',
                                                    format(
                                                        new Date(e),
                                                        'yyyy-MM-dd',
                                                    ),
                                                );
                                            }}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Label>
                            <strong>Агенты</strong>
                        </Form.Label>
                        <Select
                            name='agentUid'
                            options={agents ?? []}
                            value={agents?.filter(
                                (r) => formFilter.agentUid == r.value,
                            )}
                            onChange={(o) => onSelectChange('agentUid', o)}
                            placeholder='Агент'
                        />
                    </Col>
                    <Col>
                        <Form.Group className='mb-3'>
                            <Form.Label>
                                <strong>Группы</strong>
                            </Form.Label>
                            <Form.Select
                                onChange={handleChangeValue}
                                size='sm'
                                name='agencyGroupId'
                                value={formFilter.agencyGroupId || 0}
                            >
                                {me?.agencyInfo?.agencyGroups?.map(
                                    ({ title, id }, index) => {
                                        if (index === 0) {
                                            return (
                                                <React.Fragment key={id}>
                                                    <option value=''></option>
                                                    <option
                                                        key={id}
                                                        value={`${id}`}
                                                    >
                                                        {title}
                                                    </option>
                                                </React.Fragment>
                                            );
                                        } else return (
                                            <option
                                                key={id}
                                                value={`${id}`}
                                            >
                                                {title}
                                            </option>
                                        );
                                    },
                                )}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
            </Container>
            <div style={{
                overflow: 'scroll',
                height:'80%',
            }}>
                <Table striped bordered hover style={{
                    borderCollapse:'separate'
                }}>
                    <thead style={{
                        position:'sticky',
                        top:0,
                        background:'#E3E3E3',
                    }} className='border border-secondary'>
                        <tr>
                            <th rowSpan={2} >ID</th>
                            <th rowSpan={2} >ФИО</th>
                            <th rowSpan={2}>Группа</th>
                            <th colSpan={6} style={{
                                textAlign: 'center',
                                fontWeight: '800'
                            }}  className='border border-secondary'>Квартиры</th>
                            <th colSpan={5} style={{
                                textAlign: 'center',
                                fontWeight: '800'
                            }}  className='border border-secondary'>Загородная недвижимость</th>
                            <th colSpan={5} style={{
                                textAlign: 'center',
                                fontWeight: '800'
                            }}  className='border border-secondary'>Покупатели</th>
                        </tr>
                        <tr>
                            <th>Квартира в базе компании</th>
                            <th>Квартира на сайте</th>
                            <th>Добавление квартира</th>
                            <th>Добавление дольщики</th>
                            <th>Снижение по цене квартира</th>
                            <th>Скоро устареют квартира</th>

                            <th>Загородка в базе компании</th>
                            <th>Загородка на сайте</th>
                            <th>Добавление загородки</th>
                            <th>Снижение по цене загородка</th>
                            <th>Скоро устареют загородка</th>

                            <th>Добавлено</th>
                            <th>Горячие</th>
                            <th>Теплые</th>
                            <th>Холодные</th>
                            <th>Общее</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items && items.length > 0
                            ? items.map((item) => (
                                <IList key={item.id} item={item} />
                            ))
                            : null}
                        <tr>
                            <td>Итого</td>
                            <td></td>
                            <td></td>
                            <td>
                                {items.reduce((acc, item) => {
                                    return acc + item.statistics.secondaryTotal;
                                }, 0)}
                            </td>
                            <td>
                                {items.reduce((acc, item) => {
                                    return acc + item.statistics.secondaryVerified;
                                }, 0)}
                            </td>
                            <td>
                                {items.reduce((acc, item) => {
                                    return (
                                        acc + item.statistics.secondaryCreatedCount
                                    );
                                }, 0)}
                            </td>
                            <td>
                                {items.reduce((acc, item) => {
                                    return (
                                        acc +
                                        item.statistics.secondaryPrimaryCreatedCount
                                    );
                                }, 0)}
                            </td>
                            <td>
                                {items.reduce((acc, item) => {
                                    return (
                                        acc +
                                        item.statistics.secondaryPriceDownCount
                                    );
                                }, 0)}
                            </td>
                            <td>
                                {items.reduce((acc, item) => {
                                    return (
                                        acc +
                                        item.statistics
                                            .getSecondaryOutdatedSoonTotal
                                    );
                                }, 0)}
                            </td>


                            <td>
                                {items.reduce((acc, item) => {
                                    return acc + item.statistics.suburbanTotal;
                                }, 0)}
                            </td>
                            <td>
                                {items.reduce((acc, item) => {
                                    return acc + item.statistics.suburbanVerified;
                                }, 0)}
                            </td>
                            <td>
                                {items.reduce((acc, item) => {
                                    return (
                                        acc + item.statistics.suburbanCreatedCount
                                    );
                                }, 0)}
                            </td>

                            <td>
                                {items.reduce((acc, item) => {
                                    return (
                                        acc + item.statistics.suburbanPriceDownCount
                                    );
                                }, 0)}
                            </td>
                            <td>
                                {items.reduce((acc, item) => {
                                    return (
                                        acc +
                                        item.statistics.suburbanOutdatedSoonTotal
                                    );
                                }, 0)}
                            </td>


                            <td>
                                {items.reduce((acc, item) => {
                                    return acc + item.statistics.buyerCreatedCount;
                                }, 0)}
                            </td>

                            <td>
                                {items.reduce((acc, item) => {
                                    return acc + (!item ? 0 : Number(item.statistics.getBuyerCountByThermo[0].hot));
                                }, 0)}
                            </td>
                            <td>
                                {items.reduce((acc, item) => {
                                    return acc + (!item ? 0 : Number(item.statistics.getBuyerCountByThermo[0].warm));
                                }, 0)}
                            </td>
                            <td>
                                {items.reduce((acc, item) => {
                                    return acc + (!item ? 0 : Number(item.statistics.getBuyerCountByThermo[0].cold));
                                }, 0)}
                            </td>
                            <td>
                                {items.reduce((acc, item) => {
                                    return acc + (!item ? 0 : Number(item.statistics.getBuyerCountByThermo[0].all));
                                }, 0)}
                            </td>

                        </tr>
                    </tbody>
                </Table>
            </div>
        </React.Fragment >
    );
};

export default ListView;
