import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, ButtonGroup } from 'react-bootstrap';
import HaveAccess from '../Common/HaveAccess';
import phoneFormatter from '~/src/helpers/phoneFormatter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonWithConfirm from '../Common/ModalUI/ButtonWithConfirm';
import { remove, switchPublic, update } from '~/src/store/cottage.village/cottage.village.store';
import { ICottageVillage } from '~/src/service/cottageVillage/cottage.village.service';
import {
    COTTAGE_VILLAGE_DELETE_PERMISSION,
    COTTAGE_VILLAGE_UPDATE_PERMISSION,
} from './constants';
import { MapPopupWithMarker } from '../Common/Map/MapPopupWithMarker';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';

const ListRow: FC<{ item: ICottageVillage }> = ({ item }) => {
    const dispatch = useDispatch();

    const deleteCA = () => dispatch(remove(item?.id));

    const [isOpened, setOpened] = useState<boolean>(false);

    const handleSwitchPublicSwitch = () => {
        dispatch(switchPublic(item, item.id, item.isPublic));
    };

    console.log(item);


    return (
        <>
            <tr>
                <td>{item?.id}</td>
                <td>
                    <p>{item?.title}</p>
                    <p>
                        <Button
                            type='button'
                            variant='link'
                            onClick={() => setOpened(!isOpened)}
                        >
                            {item.addressDetail?.formattedAddress}
                        </Button>
                    </p>
                </td>
                <td>
                    {
                        (item.owner?.firstname && item.owner?.lastname && item.owner?.middlename) &&
                        <p>
                            <strong>
                                {`${item.owner?.lastname} ${item.owner?.firstname} ${item.owner?.middlename}`}
                            </strong>
                        </p>
                    }
                    <p>
                        {
                            phoneFormatter(item.owner?.phone)
                        }
                    </p>
                </td>

                <td>
                    <p>
                        Застройщик: {item.developer?.title}
                        <br />
                    </p>
                    {item.developer?.contactPhone && (
                        <p>
                            <strong>Телефон:</strong>
                            <br />
                            <span>
                                {phoneFormatter(item.developer?.contactPhone)}
                            </span>
                        </p>
                    )}
                    {item.developer?.email && (
                        <p>
                            <strong>Email:</strong>
                            <br />
                            <span>{item.developer?.email}</span>
                        </p>
                    )}
                </td>
                <td>
                    <p>{item?.mortgage && `Ипотека от ${item?.mortgage} %`}</p>
                    <p>
                        {item?.popularity && `Популярность ${item?.popularity}`}
                    </p>
                    <p>
                        {item?.hasCctv && (
                            <span>
                                Видеонаблюдение
                                <br />
                            </span>
                        )}
                        {item?.isClosedArea && (
                            <span>
                                Закрытая территория
                                <br />
                            </span>
                        )}
                        {item?.isProtectedArea && (
                            <span>
                                Охраняемая территория
                                <br />
                            </span>
                        )}
                        {item?.hasPlayGround && (
                            <span>
                                Игровая площадка
                                <br />
                            </span>
                        )}
                        {item?.hasSportGround && (
                            <span>
                                Спортивная площадка
                                <br />
                            </span>
                        )}
                        {item?.hasPicnicGround && (
                            <span>
                                Площадка для пикника
                                <br />
                            </span>
                        )}
                        {item?.hasBesideWater && (
                            <span>
                                Рядом водоем
                                <br />
                            </span>
                        )}
                        {item?.hasBesideMall && (
                            <span>
                                Рядом ТЦ
                                <br />
                            </span>
                        )}
                        {item?.hasBesideSchool && (
                            <span>
                                Рядом школа
                                <br />
                            </span>
                        )}
                        {item?.hasBesidePreSchool && (
                            <span>
                                Рядом детский сад
                                <br />
                            </span>
                        )}
                    </p>
                </td>
                <td>
                    <ButtonGroup vertical className='w-100'>
                        <Link
                            className='btn btn-sm btn-primary'
                            to={`/developer-house-project?cottageVillageId=${item?.id}&titleCV=${item?.title}`}
                        >
                            Подряды этого КП
                        </Link>
                        <HaveAccess
                            access={COTTAGE_VILLAGE_UPDATE_PERMISSION}
                            roles={['grand', 'admin']}
                        >
                            <Link
                                to={`/cottage-village/update/${item?.id}`}
                                className='btn btn-sm btn-warning'
                                title='Редактировать'
                            >
                                Редактировать
                            </Link>
                            {/* <Link
                                to={`/cottage-village/${item.id}/phases/`}
                                className='btn btn-sm btn-primary'
                                title='К домам'
                            >К участкам</Link> */}
                        </HaveAccess>
                        <HaveAccess
                            access={COTTAGE_VILLAGE_DELETE_PERMISSION}
                            roles={['admin', 'grand']}
                        >
                            <ButtonWithConfirm
                                modalText='Удалить?'
                                variant='danger'
                                label=''
                                onClick={deleteCA}
                            >
                                <FontAwesomeIcon icon={['fas', 'trash']} />{' '}
                                Удалить
                            </ButtonWithConfirm>
                        </HaveAccess>
                        <HaveAccess
                            access={COTTAGE_VILLAGE_UPDATE_PERMISSION}
                            roles={['grand', 'admin']}
                        >
                            <BootstrapSwitchButton
                                checked={item.isPublic}
                                onlabel='Включен'
                                offlabel='Выключен'
                                size='sm'
                                offstyle='warning'
                                onChange={handleSwitchPublicSwitch}
                            />
                        </HaveAccess>
                    </ButtonGroup>
                </td>
            </tr >
            <MapPopupWithMarker
                latitude={+item.addressDetail?.latitude}
                longitude={+item.addressDetail?.longitude}
                isOpened={isOpened}
                onClose={() => setOpened(false)}
            />
        </>
    );
};

export { ListRow };
