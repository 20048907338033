const createCn = (value: string): string => {
    value = value.replaceAll(/[^0-9:]/g, '');

    if (value.length <= 5) {
        return value.replace(/^(\d{2})(:)?(\d{1,2})$/g, '$1:$3');
    }
    if (value.length <= 11) {
        return value.replace(/^(\d{2}):(\d{2})(:)?(\d*)$/g, '$1:$2:$4');
    }

    if (value.length <= 13) {
        return value.replace(
            /^(\d{2}):(\d{2}):(\d{6}(\d{1})?(:)?)$/g,
            '$1:$2:$3',
        );
    }

    return value.replace(
        /^(\d{2}):(\d{2}):(\d{6}(\d{1})?)(:)?(\d*)$/g,
        '$1:$2:$3:$6',
    );
};

export { createCn };
