import Compressor from 'compressorjs';

export default function compressImage(
    file: File,
    options: Compressor.Options,
): Promise<File> {
    return new Promise<File>((resolve, reject) => {
        new Compressor(file, {
            ...options,
            success: (result) => {
                resolve(new File([result], file.name, { type: result.type }));
            },
            error: (error: Error) => reject(error),
        });
    });
}

export async function compressImages(
    files: File[],
    options: Compressor.Options,
): Promise<File[]> {
    const compressPromises: Promise<File>[] = [];
    for (const file of files) {
        compressPromises.push(compressImage(file, options));
    }

    return await Promise.all(compressPromises);
}
