import { createSlice, Slice } from '@reduxjs/toolkit';
import * as api from '~/src/service/developer/developer.house.project';
import type { TAppDispatch, TState, TAnyAction } from '~/src/store';
import { setLoading as setAppLoading } from '.././appSlice';

interface IChangeResponsibleOnDHP {
    allHouseProjects?: { value: number; label: string }[];
}
const initialState = {
    allHouseProjects: null,
};
export const sliceName = 'changeResponsibleOnDHP';

const changeResponsibleOnDHPSlice: Slice<IChangeResponsibleOnDHP> = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        unloadData: (
            state: IChangeResponsibleOnDHP,
            action: TAnyAction<any>,
        ) => {
            state.allHouseProjects = action.payload;
        },
    },
});

export const { unloadData } = changeResponsibleOnDHPSlice.actions;

export const unloadAll = () => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        const response = await api.unload();
        dispatch(
            unloadData(
                response.map(({ id, title }) => ({ value: id, label: title })),
            ),
        );
    };
};

export const setChangeResponsible = (data: Record<string, any>) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const response = await api.changeResponsible(data);
            if (response && response.success) {
                console.log('Данные успешно обновлены');
            } else {
                console.log('При добавлении данных произошла ошибка');
            }
        } catch (e) {
            console.warn(e);
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const selectChangeResponsibleOnDHP = (
    state: TState,
): IChangeResponsibleOnDHP => state.changeResponsibleOnDHP;

export default changeResponsibleOnDHPSlice.reducer;
