const keyBenefits = [
    {
        value: 'isComfortAndClosed',
        label: 'Поселок комфорт класса с закрытой территорией',
    },
    {
        value: 'besideForestAndWater',
        label: 'Участки рядом с лесом и водоемом',
    },
    { value: 'uniformStyle', label: 'Поселок в едином стиле' },
    { value: 'withRenovationAndFurniture', label: 'Дома с ремонтом и мебелью' },
    { value: 'isLastOffer', label: 'Остались последние предложения' },
    { value: 'idealForInvestment', label: 'Идеально для инвестиций' },
];

export { keyBenefits };
