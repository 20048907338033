import { useDispatch, useSelector } from 'react-redux';

import AgentRow from './AgentRow';
import NavBarInfo from './NavBarInfo';
import Table from 'react-bootstrap/Table';
import Pagination from '~/src/components/Common/Pagination';
import { selectInfo, goTo } from '~/src/store/info.agency.users.slice';

const AgentsList = (): JSX.Element => {
    const { agents } = useSelector(selectInfo);
    const dispatch = useDispatch();

    const handlePaginationClick = (page: number) => {
        console.log('goto page: ', page);
        dispatch(goTo({ page }));
    };

    return (
        <div>
            <NavBarInfo />
            {agents && agents.pagination?.have && (
                <Pagination
                    pagination={agents.pagination}
                    onClick={handlePaginationClick}
                />
            )}
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <td>#</td>
                        <td>ФИО</td>
                        <td>Контактные данные</td>
                        <td>Кол-во объектов</td>
                        <td>Управление</td>
                    </tr>
                </thead>
                <tbody>
                    {agents && agents.items && agents.items.length > 0
                        ? agents.items.map((item) => (
                              <AgentRow key={item.id} item={item} />
                          ))
                        : null}
                </tbody>
            </Table>
            {agents && agents.pagination?.have && (
                <Pagination
                    pagination={agents.pagination}
                    onClick={handlePaginationClick}
                />
            )}
        </div>
    );
};

export default AgentsList;
