import { IResponseListData, IUpdateData } from '../interfaces/common';
import Api from '~/src/helpers/http-common';

export const all = async (
    queryParams?: URLSearchParams,
): Promise<IResponseListData<IPermission>> => {
    return await Api.get(
        `/permission?${queryParams != null ? queryParams.toString() : ''}`,
    );
};

export const create = async (
    data: IPermission,
    files?: Record<string, FileList>,
): Promise<IPermission | any> => {
    return (await Api.post<IPermission>(
        '/permission',
        data,
        files,
    )) as IPermission;
};

export const read = async (id: number): Promise<IPermission> => {
    return await Api.get(`/permission/${id}`);
};

export const update = async (
    id: string | number,
    data: IPermission,
    files?: Record<string, FileList>,
): Promise<IUpdateData<IPermission>> => {
    return await Api.put<IUpdateData<IPermission>>(
        `/permission/${id}`,
        data,
        files,
    );
};

export const remove = async (id: string | number): Promise<any> => {
    return await Api.delete(`/permission/${id}`);
};

export const unload = async (): Promise<IPermission[]> => {
    return await Api.get('/permission/unload');
};

export interface IPermission {
    id: number;
    title: string;
    code: string;
    group: string;
}

export interface IUserPermission {
    id: number;
    userId: number;
    permissionId: number;
    isEnabled: boolean;
}

export interface IUserPermissionUnloaded extends IPermission {
    isEnabled: boolean | number;
}
