import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { selectApp } from '~/src/store/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import UpdateGroupView from '~/src/components/Agency/Group/update';
import {
    read,
} from '~/src/store/agency.group.slice';

const AgencyGroupUpdatePage = (): JSX.Element => {
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();
    const { loading } = useSelector(selectApp);

    useEffect(() => {
        dispatch(read(+id));
    }, [id, dispatch]);

    return (
        <React.Fragment>
            {loading ? <div>Загрузка...</div> : <UpdateGroupView />}
        </React.Fragment>
    );
};

export default AgencyGroupUpdatePage;
