import { LOCALES } from './locales';

export const messages = {
    [LOCALES.RUSSIAN]: {
        agency_agent: 'Агент',
        agency_user: 'Физическое лицо',
        agency_director: 'Директор',
        agency_supervisor: 'Руководитель подразделения',
        agency_administrator: 'Администратор',

        ['buyer.trello.today']: 'Позвонить сегодня',
        ['buyer.trello.tomorrow']: 'Позвонить завтра',
        ['buyer.trello.three']: '3 дня и более',
        ['buyer.trello.week']: '7 дней и более',
        ['buyer.trello.month']: '30 дней и более',

        realty_secondary_subtype_flat: 'Квартира',
        realty_secondary_subtype_studio: 'Студия',
        realty_secondary_subtype_pansionat: 'Пансионат',
        realty_secondary_subtype_multilevel: 'Многоуровневая',
        realty_secondary_subtype_malosemeyka: 'Малосемейка',
        realty_secondary_subtype_obshchezhitie: 'Общежитие',
        realty_secondary_subtype_apartments: 'Апартаменты',

        payment_mortgage: 'Ипотека',
        payment_cash: 'Наличные',
        payment_certificate: 'Сертификат',

        realty_suburban_type_land: 'Земельный участок',
        realty_suburban_type_house: 'Дом / коттедж',
        realty_suburban_type_chalet: 'Дача',

        realty_suburban_landAppointment_individual_housing_construction: 'ИЖС',
        realty_suburban_landAppointment_private_household: 'ЛПХ',
        realty_suburban_landAppointment_gardening:
            'Садоводство/огородничество',

        realty_suburban_heating_gas: 'Газовое',
        realty_suburban_heating_stove: 'Печное',
        realty_suburban_heating_central: 'Центральное',
        realty_suburban_heating_electricity: 'Электрическое',

        realty_suburban_waterSuply_well: 'Колодец',
        realty_suburban_waterSuply_chink: 'Скважина',
        realty_suburban_waterSuply_central: 'Центральное',

        house_material_brick: 'Кирпич',
        house_material_wood: 'Дерево',
        house_material_log: 'Бревно',
        house_material_solidWood: 'Твердая древесина',
        house_material_aerocreteBlock: 'Газобетонный блок',
        house_material_metal: 'Метал',
        house_material_metalFrame: 'Металлокаркас',
        house_material_foamConcreteBlock: 'Пенобетонный блок',
        house_material_sandwich_panel: 'Сэндвич панель',
        house_material_reinforced_concrete_panel: 'Армированный бетон',
        house_material_experimental: 'Экспериментальные материалы',
        house_material_arbolit: 'Арболит',
        house_material_boards: 'Щитовой',
        house_material_expandedClayConcrete: 'Керамзитобетон',
        house_material_gasSilicateBlock: 'Газосиликатный блок',
        house_material_gas_blocks: 'Газоблок',
        house_material_gluedLaminatedTimber: 'Клеёный брус',
        house_material_monolith: 'Монолитный',
        house_material_reinforcedConcretePanels: 'Железобетонные панели',
        house_material_sipPanels: 'Сип-панели',
        house_material_slagConcrete: 'Шлакобетон',
        house_material_wireframe: 'Каркасный',
        house_material_panel: 'Панель',
        house_material_null: 'Не указан',
        house_material_ceramicBlocks: 'Керамические блоки',
        house_material_frame: 'Каркасный',
        house_material_module: 'Модульный',
        house_material_roundedTimber: 'Оцилиндрованный брус',
        house_material_GluedLaminatedTimber: 'Клеёный брус',
        house_material_expandedClayBlock: 'Керамзитоблок',
        house_material_silicateBlock: 'Силикатный блок',
        house_material_timber: 'Брус',


        garage_no: 'Нет',
        garage_in_house: 'В доме',
        garage_separately: 'Отдельно',

        realty_secondary_room_type_isolated: 'Изолированные',
        realty_secondary_room_type_adjacent: 'Смежные',

        realty_secondary_renovation_standard: 'Косметический',
        realty_secondary_renovation_design: 'Дизайнерский',
        realty_secondary_renovation_no_decoration: 'Требуется',
        realty_secondary_renovation_noDecoration: 'Требуется ремонт',
        realty_secondary_renovation_euro: 'Евро',
        realty_secondary_renovation_withoutFinishing: 'Без отделки',
        realty_secondary_renovation_whiteBox: 'Предчистовая',
        realty_secondary_renovation_fineFinishing: 'Чистовая',

        realty_secondary_insert: 'Добавление',
        realty_secondary_update: 'Изменение',
        realty_secondary_verify: 'Верификация',
        realty_secondary_unverify: 'Снятие с верификации',
        realty_secondary_price: 'Цена',
        realty_secondary_priceRise: 'Возрастание цены',
        realty_secondary_priceChangedAt: 'Время изменения цены',
        realty_secondary_storey: 'Этаж',
        realty_secondary_spaceTotal: 'Общая площадь',
        realty_secondary_spaceLiving: 'Жилая площадь',
        realty_secondary_serviceElevator: 'Грузовой лифт',
        realty_secondary_description: 'Описание',
        realty_secondary_new_photo_add: 'Добавлены новые фото',
        realty_secondary_terrace: 'Терраса',
        realty_secondary_realtyType: 'Тип объекта',
        realty_secondary_realtySubtype: 'Подтип объекта',
        realty_secondary_addressPlaceDetailId: 'Адрес',
        realty_secondary_cn: 'Кадастровый номер',
        realty_secondary_spaceKitchen: 'Площадь кухни',
        realty_secondary_spaceBathroom: 'Площадь санузлов',
        realty_secondary_privateNote: 'Личные заметки',
        realty_secondary_houseStoreys: 'Этажность',
        realty_secondary_rooms: 'Количество комнат',
        realty_secondary_roomType: 'Тип комнат',
        realty_secondary_flatNumber: 'Номер квартиры',
        realty_secondary_bathroomCombined: 'Количество совмещенных санузлов',
        realty_secondary_bathroomSeparated: 'Количество раздельных санузлов',
        realty_secondary_ceiling: 'Высота потолков',
        realty_secondary_balcony: 'Наличие балкона или их количество',
        realty_secondary_loggia: 'Наличие лоджии или их количество',
        realty_secondary_passengerElevator:
            'Наличие пассажирского лифта или их количество',
        realty_secondary_bankEncumbrance: 'Наличие обременения банка',
        realty_secondary_bankEncumbranceTitle: 'Название банка - обременителя',
        realty_secondary_isFurnitureRemains: 'Остается ли мебель',
        realty_secondary_houseMaterial: 'Материал дома',
        realty_secondary_sellerName: 'Имя собственника',
        realty_secondary_sellerPhone: 'Телефон собственника',
        realty_secondary_sellerMinors: 'Несовершеннолетние собственники',
        realty_secondary_multilevel: 'Многоуровневая ли квартира/дом',
        realty_secondary_highflat: 'Хайфлет',
        realty_secondary_cityhouse: 'Ситихаус',
        realty_secondary_viewApartment: 'Видовая квартира',
        realty_secondary_stainedGlass: 'Витражные окна',
        realty_secondary_soundproofing: 'Шумоизоляция',
        realty_secondary_underfloorHeatingSystem: 'Теплый пол',
        realty_secondary_lowRiseEstate: 'Малоэтажный ЖК',
        realty_secondary_masterBedroom: 'Мастер - спальня',
        realty_secondary_smartHouseSystem: 'Система Умный дом',
        realty_secondary_kitchenLiving: 'Кухня - гостиная',
        realty_secondary_houseBuilt: 'Срок сдачи / Год постройки',
        realty_secondary_isAreaPlayGround: 'Игровая площадка',
        realty_secondary_isAreaSportGround: 'Спортивная площадка',
        realty_secondary_hasPicnicGround: 'Площадка для пикника',
        realty_secondary_hasBesidePark: 'Рядом парк',
        realty_secondary_hasBesideWater: 'Рядом водоем',
        realty_secondary_hasBesideForest: 'Рядом лес',
        realty_secondary_hasBesideMall: 'Рядом ТЦ',
        realty_secondary_canDeveloperRepaire: 'Возможен ремонт от застройщика',
        realty_secondary_isAreaCctv: 'Видеонаблюдение',
        realty_secondary_isRepaired: 'Квартира с ремонтом',
        realty_secondary_renovation: 'Ремонт',
        realty_secondary_isUndergroundParking: 'Подземный паркинг',
        realty_secondary_isGroundParking: 'Наземный паркинг',
        realty_secondary_isClosedArea: 'Закрытый двор',
        realty_secondary_isProtectedArea: 'Охраняемая территория',
        realty_secondary_isSchoolBeside: 'Рядом школа',
        realty_secondary_isPreschoolBeside: 'Рядом детский сад',
        realty_secondary_isUnderstatementNeed: 'Требуется занижение',
        realty_secondary_understatementSum: 'Сумма занижения',
        realty_secondary_photoCount: 'Количество фотографий',
    },
};
