import {
    Button,
    ButtonGroup,
    Col,
    Form,
    Navbar,
    Row,
    ToggleButton,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    getBoard,
    goTo,
    goToBoard,
    selectBuyerList,
    setSearch,
    setSearchBoard,
} from '~/src/store/buyer/buyer.list.slice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AuthLayer from '../../Common/Hoc';
import Select from 'react-select';
import { selectAuth } from '~/src/store/authSlice';
import React, { BaseSyntheticEvent, useEffect, useState } from 'react';
import RealtyTypeBtn from '../RealtyTypeBtn';
import BuyerChangeDatePicker from '../BuyerChangeDatePicker';
import { thermometer } from '../data/data';
import MainPopup from '../../Common/ModalUI/MainPopup';
import PieChart from '../../Common/Chart/PieChart';

const TrelloNav = () => {
    const dispatch = useDispatch();

    const { searchBoard, search, statistics } = useSelector(selectBuyerList);
    const { me, meGroup } = useSelector(selectAuth);
    const [showStatistics, setShowStatistics] = useState(false);

    const undefindStat = Number(statistics?.thermo?.all) - (Number(statistics?.thermo?.hot) + Number(statistics?.thermo?.warm) + Number(statistics?.thermo?.cold));

    const SetIsCloseModalPopup = () => setShowStatistics(false);

    useEffect(() => {
        dispatch(goToBoard(searchBoard));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        searchBoard.my,
        searchBoard.status,
        searchBoard.controlDate,
        dispatch,
        searchBoard.agencyGroupId,
        searchBoard.agentUid,
        searchBoard.thermometer,
    ]);

    const handleChangeValues = (e: BaseSyntheticEvent) => {
        if (searchBoard.my === e.target.value) {
            dispatch(setSearchBoard({ ...searchBoard, [e.target.name]: null }));
        } else {
            dispatch(
                setSearchBoard({
                    ...searchBoard,
                    [e.target.name]: e.target.value,
                }),
            );
        }
    };

    const handleSubmit = () => {
        dispatch(goToBoard(searchBoard));
    };
    const handleOpenList = () => {
        dispatch(setSearch(searchBoard));
        dispatch(goTo(searchBoard));
    };

    const groups = me?.agencyInfo?.agencyGroups?.map(({ title, id }) => {
        return {
            value: id,
            label: title,
        };
    });
    const dataThermo = {
        labels: ['Не определены', 'Горячие', 'Теплые', 'Холодные'],
        datasets: [
            {
                data: [
                    undefindStat,
                    statistics.thermo?.hot,
                    statistics.thermo?.warm,
                    statistics.thermo?.cold,
                ],
                backgroundColor: [
                    'rgb(171, 255, 255)',
                    'rgb(255, 59, 59)',
                    'rgb(255, 185, 102)',
                    'rgb(127, 115, 255)',
                ],
                borderColor: [
                    'rgb(0, 255, 255)',
                    'rgb(252, 0, 0)',
                    'rgb(255, 138, 0)',
                    'rgb(0, 56, 255)',
                ],
                borderWidth: 1,
            },
        ],
    };
    const dataRealtyType = {
        labels: ['Новостройки', 'Вторчная недвижимость', 'Дом', 'Земельный участок', 'Таунхаус', 'Дача'],
        datasets: [
            {
                data: [
                    statistics.realtyType?.primary,
                    statistics.realtyType?.secondary,
                    statistics.realtyType?.house,
                    statistics.realtyType?.land,
                    statistics.realtyType?.townhouse,
                    statistics.realtyType?.chalet
                ],
                backgroundColor: [
                    'rgb(171, 255, 255)',
                    'rgb(255, 59, 59)',
                    'rgb(255, 185, 102)',
                    'rgb(103, 246, 117)',
                    'rgb(127, 115, 255)',
                    'rgb(176, 140, 49)',
                ],
                borderColor: [
                    'rgb(0, 255, 255)',
                    'rgb(252, 0, 0)',
                    'rgb(255, 138, 0)',
                    'rgb(0, 255, 25)',
                    'rgb(0, 56, 255)',
                    'rgb(120, 87, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };


    const agents =
        meGroup?.position === 'supervisor'
            ? meGroup?.agents?.map(({ fullname, uid }) => {
                return {
                    value: uid,
                    label: fullname,
                };
            })
            : me?.agencyInfo?.agencyAgents?.map(({ fullname, uid }) => {
                return {
                    value: uid,
                    label: fullname,
                };
            });

    const onSelectChange = (
        field: string,
        o: { value: string | number; label: string },
    ) => {
        dispatch(setSearchBoard({ ...searchBoard, [field]: o?.value ?? null }));
    };

    const handleChangeCheckboxValue = (e: BaseSyntheticEvent) => {
        dispatch(
            setSearchBoard({
                ...searchBoard,
                [e.target.name]:
                    searchBoard[e.target.name] == 'yes' ? undefined : 'yes',
            }),
        );
    };

    const onKeyDown = (e) => {
        if (e.keyCode == 13) {
            dispatch(goToBoard(searchBoard));
        }
    };

    const handleChangeValue = (e: BaseSyntheticEvent) => {
        dispatch(
            setSearchBoard({ ...searchBoard, [e.target.name]: e.target.value }),
        );
    };

    return (
        <>
            <Navbar
                className='justify-content-between'
                bg='light'
                expand='lg'
                variant='light'
            >
                <ButtonGroup
                    style={{
                        display: 'flex',
                        gap: '10px',
                    }}
                >
                    <Link
                        to={'/buyer/create/'}
                        className='btn btn-sm btn-success'
                    >
                        <FontAwesomeIcon icon={['fas', 'plus']} /> Добавить
                    </Link>
                    <AuthLayer
                        position={['director', 'administrator', 'supervisor']}
                    >
                        <ToggleButton
                            size='sm'
                            id='my-id'
                            type='checkbox'
                            variant={
                                searchBoard?.my == 1
                                    ? 'primary'
                                    : 'outline-primary'
                            }
                            name='my'
                            value={1}
                            checked={searchBoard?.my == 1}
                            onChange={handleChangeValues}
                        >
                            Только мои
                        </ToggleButton>
                    </AuthLayer>
                    <RealtyTypeBtn
                        search={searchBoard}
                        setSearch={setSearchBoard}
                        goTo={goToBoard}
                    />
                    <BuyerChangeDatePicker
                        search={searchBoard}
                        setSearch={setSearchBoard}
                        goTo={goToBoard}
                    />
                    <Select
                        name='thermometer'
                        options={thermometer}
                        value={thermometer.filter(
                            (r) => searchBoard.thermometer == r.value,
                        )}
                        onChange={(o) => onSelectChange('thermometer', o)}
                        isClearable
                        placeholder='Термометр'
                    />
                </ButtonGroup>
                <ButtonGroup>
                    <Button btn-primary onClick={handleSubmit}>
                        Найти
                    </Button>
                    <Button
                        className='btn btn-sm btn-success'
                        onClick={handleOpenList}
                    >
                        <FontAwesomeIcon icon={['fas', 'eye']} /> Открыть список
                    </Button>
                    <Button
                        size='sm'
                        variant='secondary'
                        onClick={() => dispatch(getBoard())}
                    >
                        <FontAwesomeIcon icon={['fas', 'sync']} /> Обновить
                    </Button>
                    <Button
                        size='sm'
                        variant='danger'
                        onClick={() => dispatch(goToBoard({ reset: true }))}
                    >
                        <FontAwesomeIcon icon={['fas', 'sync']} /> Сбросить
                        фильтр
                    </Button>
                </ButtonGroup>
            </Navbar>
            <Row>
                <AuthLayer
                    position={['director', 'administrator', 'supervisor']}
                    permission='agency.permission.buyer.all'
                >
                    <Col>
                        <Select
                            name='agentUid'
                            options={agents}
                            value={agents?.filter(
                                (r) => searchBoard?.agentUid == r.value,
                            )}
                            onChange={(o) => onSelectChange('agentUid', o)}
                            isClearable
                            placeholder='Агент'
                        />
                    </Col>
                </AuthLayer>
                <AuthLayer
                    position={['director', 'administrator']}
                    permission='agency.permission.buyer.all'
                >
                    <Col>
                        <Form.Group className='mb-3'>
                            <Select
                                name='agencyGroupId'
                                options={groups}
                                value={groups?.filter(
                                    (r) =>
                                        searchBoard?.agencyGroupId == r.value,
                                )}
                                onChange={(o) =>
                                    onSelectChange('agencyGroupId', o)
                                }
                                isClearable
                                placeholder='Группа'
                            />
                        </Form.Group>
                    </Col>
                </AuthLayer>
                {me && me.agencyInfo ? (
                    <Col>
                        <Form.Group>
                            <Form.Control
                                name='q'
                                value={searchBoard?.q || ''}
                                onChange={handleChangeValue}
                                autoComplete='off'
                                placeholder='ФИО или телефон'
                                size='sm'
                                onKeyDown={onKeyDown}
                            />
                        </Form.Group>
                    </Col>
                ) : null}
            </Row>
            <Row className='mb-3'>
                <Col>
                    <ButtonGroup>
                        <ToggleButton
                            size='sm'
                            id='newbie'
                            type='checkbox'
                            variant={
                                searchBoard?.newbie == 'yes'
                                    ? 'primary'
                                    : 'outline-primary'
                            }
                            name='newbie'
                            style={{
                                marginRight: '20px'
                            }}
                            value={'yes'}
                            checked={searchBoard?.newbie == 'yes'}
                            onChange={handleChangeCheckboxValue}
                        >
                            Новые
                        </ToggleButton>
                        <ToggleButton
                            size='sm'
                            id='showStatistics'
                            type='checkbox'
                            variant={
                                showStatistics
                                    ? 'primary'
                                    : 'outline-primary'
                            }
                            name='showStatistics'
                            value={'yes'}
                            checked={showStatistics}
                            onChange={() => setShowStatistics((prev) => !prev)}
                        >
                            Статистика
                        </ToggleButton>
                    </ButtonGroup>
                </Col>
            </Row>
            {
                showStatistics &&
                <MainPopup
                    title={'Статистика по покупателям'}
                    onClose={() => {
                        // eslint-disable-next-line new-cap
                        SetIsCloseModalPopup();
                    }}
                    isOpened={showStatistics}
                    fullwidth={true}
                    overflow={true}
                >
                    <div className='d-flex justify-content-between'>
                        <PieChart data={dataThermo} title={'Термометр'} />
                        <PieChart data={dataRealtyType} title={'Тип недвижимости'}/>
                    </div>
                </MainPopup>

            }
        </>
    );
};

export default TrelloNav;
