import * as api from '~/src/service/comment';
import { IComment } from '../service/comment';
import { createSlice, Slice } from '@reduxjs/toolkit';
import { setLoading as setAppLoading } from './appSlice';
import type { TAppDispatch, TState, TAnyAction } from '~/src/store';

export const sliceName = 'comment';

interface ICommentSlice {
    items: IComment[];
    item?: IComment;
    errors?: any;
    loading?: boolean;
}

const initialState = {
    items: [],
    loading: true,
};

const commentSlice: Slice<ICommentSlice> = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        setAll: (state: ICommentSlice, action: TAnyAction<IComment[]>) => {
            state.items = action.payload;
        },
        set: (state: ICommentSlice, action: TAnyAction<IComment>) => {
            state.item = action.payload;
        },
        setErrors: (state: ICommentSlice, action: TAnyAction<any>) => {
            state.errors = action.payload;
        },
        setLoading: (state: ICommentSlice, action: TAnyAction<boolean>) => {
            state.loading = action.payload;
        },
    },
});

export const { setAll, set, setErrors, setLoading } = commentSlice.actions;

export const all = (section: string, entry: number) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(setLoading(true));
        const response = await api.all(section, entry);
        dispatch(setAll(response));
        dispatch(setLoading(false));
    };
};

export const clear = () => {
    return (dispatch: TAppDispatch): void => {
        dispatch(setAll([]));
    };
};

export const create = (data: IComment) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const item = await api.create(data);
            if (item && item.errors) {
                dispatch(setErrors(item.errors));
            } else {
                dispatch(setAll([item, ...getState().comment.items]));
            }
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const read = (id: number) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const data = await api.read(id);
            dispatch(set(data));
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const update = (id: number, data: any) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const response = await api.update(id, data);
            if (response && response.success) {
                dispatch(set(response.data));
                dispatch(
                    setAll(
                        getState().comment.items.map((comment) => {
                            if (comment.id == id) {
                                return response.data;
                            }

                            return comment;
                        }),
                    ),
                );
            } else {
                dispatch(setErrors(response.errors));
            }
        } catch (e) {
            console.error(e);
            dispatch(setErrors(e));
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

/* export const remove = (id: number) => {
    return async (dispatch: TAppDispatch, getState: () => TState): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const response = await api.remove(id);
            if (response && response.success) {
                dispatch(setAll({
                    ...getState().comment.items,
                    items: getState().comment.items.items.filter((item: IComment) => item.id != id),
                }));
            } else {
                dispatch(setErrors(response.errors));
            }
        } finally {
            dispatch(setAppLoading(false));
        }
    };
}; */

export const selectComment = (state: TState): ICommentSlice => state.comment;

export default commentSlice.reducer;
