import React, { useEffect, useState } from 'react';

import Lightbox from 'react-image-lightbox';
import { useNavigate } from 'react-router-dom';
import PhotoGallary from './RSPhotoViewPhotos';
import { sort } from '~/src/service/filestore';
import { arrayMoveImmutable } from 'array-move';
import { FILES_BASE } from '~/src/constants/urls';
import { IFilestore } from '~/src/service/filestore';
import { IRSModel } from '~/src/store/realtySecondary/realtySecondarySlice';
import { Button, ButtonGroup, Navbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const RSPhotosView: React.FC<TPhotosProps> = ({ item }) => {
    const navigate = useNavigate();
    const [isOpen, setOpen] = useState(false);
    const [index, setIndex] = useState(0);
    const [sortedPhotos, setPhotos] = useState(null);

    const { photos } = item;

    useEffect(() => {
        setPhotos(photos);
    }, [photos]);

    const handlePhotoClick = (index: number) => {
        setOpen(true);
        setIndex(index);
    };

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const sorted: IFilestore[] = arrayMoveImmutable(
            sortedPhotos,
            oldIndex,
            newIndex,
        );
        setPhotos(sorted);
        sort(sorted.map((i) => i.name));
    };

    if (sortedPhotos && sortedPhotos.length > 0) {
        return (
            <React.Fragment>
                <Navbar
                    className='justify-content-between mb-2'
                    bg='light'
                    expand='lg'
                    variant='light'
                >
                    <ButtonGroup>
                        <Button size='sm' onClick={() => navigate(-1)}>
                            <FontAwesomeIcon icon={['fas', 'fast-backward']} />{' '}
                            Назад
                        </Button>
                    </ButtonGroup>
                </Navbar>

                <React.Fragment>
                    <PhotoGallary
                        photos={sortedPhotos}
                        onSortEnd={onSortEnd}
                        pressDelay={200}
                        onClick={handlePhotoClick}
                        axis={'xy'}
                    />
                    {isOpen && (
                        <Lightbox
                            mainSrc={`${FILES_BASE}${sortedPhotos[index].name}`}
                            nextSrc={`${FILES_BASE}${sortedPhotos[(index + 1) % sortedPhotos.length].name}`}
                            prevSrc={`${FILES_BASE}${sortedPhotos[(index + sortedPhotos.length - 1) % sortedPhotos.length].name}`}
                            onCloseRequest={() => {
                                setOpen(false);
                                setIndex(0);
                            }}
                            onMovePrevRequest={() =>
                                setIndex(
                                    (index + sortedPhotos.length - 1) %
                                        sortedPhotos.length,
                                )
                            }
                            onMoveNextRequest={() =>
                                setIndex((index + 1) % sortedPhotos.length)
                            }
                        />
                    )}
                </React.Fragment>
            </React.Fragment>
        );
    } else {
        return null;
    }
};

type TPhotosProps = {
    item: IRSModel;
};

export default RSPhotosView;
