import React from 'react';
import Loading from '../Common/Loading';
import { BaseSyntheticEvent } from 'react';
import FileField from '../Common/Form/FileField';
import { FILES_BASE } from '~/src/constants/urls';
import { Form, Figure, Stack } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { myUploadLogo, selectAgency } from '~/src/store/agencySlice';

const UpdateLogo = () => {
    const dispatch = useDispatch();
    const { logoLoading, logo } = useSelector(selectAgency);

    const changeHandler = (e: BaseSyntheticEvent) => {
        dispatch(myUploadLogo(e.target.files));
    };

    return (
        <Stack className='ace-justify-content-center ace-align-items-center'>
            {logoLoading ? (
                <Loading />
            ) : (
                <React.Fragment>
                    <Figure>
                        {logo && (
                            <Figure.Image
                                width={300}
                                height={300}
                                src={`${FILES_BASE}${logo?.name}`}
                            />
                        )}
                    </Figure>
                    <Form.Group className='mb-3'>
                        <label className='btn btn-sm btn-primary'>
                            <FileField
                                name='logoUpload'
                                onChange={changeHandler}
                            />{' '}
                            Загрузить логотип
                        </label>
                    </Form.Group>
                </React.Fragment>
            )}
        </Stack>
    );
};

export default UpdateLogo;
