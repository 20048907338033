import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '~/src/helpers/hooks';
import { all } from '~/src/store/agency.permission';
import ListView from '~/src/components/Agency/Permission';
import { setHandleScrollPosition } from '~/src/store/appSlice';

const AgencyPermissionPage = (): JSX.Element => {
    const query = useQuery();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(all(query));
    }, [dispatch, query]);

    useEffect(() => {
        dispatch(setHandleScrollPosition(true));
    }, [dispatch]);

    return <ListView />;
};

export default AgencyPermissionPage;
