import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { selectAuth } from '~/src/store/authSlice';

type TProps = {
    access?: string;
    agencyId?: number | string;
    agencyAccess?: string;
    groupId?: number | string;
    groupAccess?: string;
    redirect?: string;
    me?: string;
    children?: JSX.Element | JSX.Element[];
    _default?: JSX.Element;
    roles?: string | string[];
};

const HaveAccess: FC<TProps> = ({
    access,
    agencyAccess,
    agencyId,
    groupId,
    groupAccess,
    children,
    redirect,
    me,
    _default = <></>,
    roles,
}) => {
    const { me: user } = useSelector(selectAuth);

    if (user && user.permissions) {
        if (
            user.permissions.includes('*') ||
            user.permissions.includes(access) ||
            (user.uid === me && me !== undefined)
        ) {
            return <React.Fragment>{children}</React.Fragment>;
        }
    }
    if (user && roles !== undefined) {
        if (
            (Array.isArray(roles) && roles.includes(user.role)) ||
            roles === user.role
        ) {
            return <React.Fragment>{children}</React.Fragment>;
        }
    }
    if (
        user &&
        user.agencyInfo &&
        agencyId !== undefined &&
        user.agencyInfo.agency?.id == agencyId &&
        (user.agencyInfo.isDirector ||
            user.agencyInfo.permissions?.includes(agencyAccess))
    ) {
        return <React.Fragment>{children}</React.Fragment>;
    }
    if (
        user &&
        user.agencyInfo &&
        agencyId !== undefined &&
        groupId !== undefined &&
        user.agencyInfo.agency?.id == agencyId &&
        user.agencyInfo.group?.id == groupId &&
        user.agencyInfo.permissions?.includes(groupAccess)
    ) {
        return <React.Fragment>{children}</React.Fragment>;
    }

    return redirect ? <Navigate to={redirect} replace /> : _default;
};

export default HaveAccess;
