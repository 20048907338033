import { controlDate } from './data/data';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import {
    goTo,
    selectBuyerList,
    setSearch,
} from '~/src/store/buyer/buyer.list.slice';
import { useDispatch, useSelector } from 'react-redux';
import { BaseSyntheticEvent } from 'react';

const ControlDateBtn = () => {
    const dispatch = useDispatch();
    const { search } = useSelector(selectBuyerList);

    const handleChangeValue = (e: BaseSyntheticEvent) => {
        const updatedSearch = { ...search, [e.target.name]: e.target.value };
        if (search[e.target.name] === e.target.value) {
            dispatch((dispatch) => {
                dispatch(setSearch({ ...search, [e.target.name]: null }));
                dispatch(goTo(updatedSearch));
            });
        } else {
            dispatch((dispatch) => {
                dispatch(setSearch(updatedSearch));
                dispatch(goTo(updatedSearch));
            });
        }
    };

    return (
        <ButtonGroup>
            {controlDate.map((radio, idx) =>
                search.status === 'archive' ? (
                    radio.title === 'Все' ? (
                        <ToggleButton
                            key={idx}
                            size='sm'
                            id={`${radio.date_number}-${idx}`}
                            type='checkbox'
                            name='controlDate'
                            variant={
                                search?.ControlDate?.includes(radio.date)
                                    ? 'primary'
                                    : 'outline-primary'
                            }
                            value={radio.date}
                            checked={search?.controlDate == radio.date}
                            onChange={handleChangeValue}
                        >
                            {radio.title}
                        </ToggleButton>
                    ) : null
                ) : (
                    <ToggleButton
                        key={idx}
                        size='sm'
                        id={`${radio.date_number}-${idx}`}
                        type='checkbox'
                        name='controlDate'
                        variant={
                            search?.ControlDate?.includes(radio.date)
                                ? 'primary'
                                : 'outline-primary'
                        }
                        value={radio.date}
                        checked={search?.controlDate == radio.date}
                        onChange={handleChangeValue}
                    >
                        {radio.title}
                    </ToggleButton>
                ),)}
        </ButtonGroup>
    );
};

export default ControlDateBtn;
