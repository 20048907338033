const suburbanMaterialSearch = [
    { label: 'Все', value: '' },
    { label: 'Кирпичный', value: 'brick' },
    { label: 'Газоблок', value: 'gas_blocks' },
    { label: 'Керамические блоки', value: 'ceramicBlocks' },
    { label: 'Каркасный', value: 'frame' },
    { label: 'Модульный', value: 'module' },
    { label: 'Оцилиндрованный брус', value: 'roundedTimber' },
    { label: 'Клеёный брус', value: 'GluedLaminatedTimber' },
    { label: 'Сип-панели', value: 'sipPanels' },
    { label: 'Керамзитоблок', value: 'expandedClayBlock' },
    { label: 'Силикатный блок', value: 'silicateBlock' },
    { label: 'Брус', value: 'timber' },
];

const suburbanMaterial = suburbanMaterialSearch.map((i) => ({
    title: i.label === 'Все' ? '' : i.label,
    value: i.value,
}));

export { suburbanMaterialSearch, suburbanMaterial };
