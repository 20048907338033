import { useState } from 'react';
import React from 'react';
import { FILES_BASE } from '~/src/constants/urls';
import Confirm from '../../ModalUI/AlertModals/Confirm';
import { IFilestore } from '~/src/service/filestore';
import { Button, ButtonGroup } from 'react-bootstrap';
import FigureImage from 'react-bootstrap/esm/FigureImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import download from '~/src/helpers/download';

const Photo: React.FC<TPhotoProps> = ({ photo, onClick, onDelete }) => {
    const [isOpened, setIsOpened] = useState(false);

    const handleDeletePhoto = () => {
        onDelete(photo.name);
        setIsOpened(false);
    };
    return (
        <div className='float-start position-relative me-2'>
            <FigureImage
                width={200}
                height={112}
                style={{ height: 112 }}
                src={`${FILES_BASE}${photo.name}`}
                onClick={onClick}
            />
            <div
                className='position-absolute'
                style={{ bottom: '10px', right: '10px' }}
            >
                <ButtonGroup>
                    <Button
                        size='sm'
                        onClick={() =>
                            download(
                                `${FILES_BASE}${photo.name}`,
                                `${photo.name}`,
                            )
                        }
                    >
                        <FontAwesomeIcon icon={['fas', 'download']} />
                    </Button>
                    <Button
                        size='sm'
                        variant='danger'
                        onClick={() => setIsOpened(true)}
                    >
                        <FontAwesomeIcon icon={['fas', 'trash']} />
                    </Button>
                </ButtonGroup>
            </div>
            <Confirm
                isOpened={isOpened}
                text='Удалить?'
                onClose={() => setIsOpened(false)}
                primaryButtonText='Да'
                primaryButtonOnClick={handleDeletePhoto}
                secondaryButtonText='Отмена'
                secondaryButtonOnClick={() => setIsOpened(false)}
            />
        </div>
    );
};

type TPhotoProps = {
    photo: IFilestore;
    onClick: (index) => void;
    onDelete: (photoName: string) => void;
};

export default Photo;
