const ownBtns = [
    {
        name: 'Общая база',
        value: 'all',
        variantOff: 'outline-primary',
        variantOn: 'primary',
    },
    {
        name: 'База компании',
        value: 'my_agency',
        variantOff: 'outline-primary',
        variantOn: 'primary',
    },
    {
        name: 'Мои',
        value: 'my',
        variantOff: 'outline-primary',
        variantOn: 'primary',
    },
    {
        name: 'Скоро устареют',
        value: 'my_outdated_soon',
        variantOff: 'outline-primary',
        variantOn: 'primary',
    },
    {
        name: 'Избранное',
        value: 'favorite',
        variantOff: 'outline-primary',
        variantOn: 'primary',
    },
    {
        name: 'Архив',
        value: 'sold',
        variantOff: 'outline-primary',
        variantOn: 'primary',
    },
];

const ownBtnSuburban = [
    {
        name: 'Общая база',
        value: 'all',
        variantOff: 'outline-primary',
        variantOn: 'primary',
    },
    {
        name: 'База компании',
        value: 'my_agency',
        variantOff: 'outline-primary',
        variantOn: 'primary',
    },
    {
        name: 'Мои',
        value: 'my',
        variantOff: 'outline-primary',
        variantOn: 'primary',
    },
    {
        name: 'Скоро устареют',
        value: 'my_outdated_soon',
        variantOff: 'outline-primary',
        variantOn: 'primary',
    },
    {
        name: 'Избранное',
        value: 'favorite',
        variantOff: 'outline-primary',
        variantOn: 'primary',
    },
    {
        name: 'Архив',
        value: 'sold',
        variantOff: 'outline-primary',
        variantOn: 'primary',
    },
];

const realtySubtypesBtns = [
    {
        name: 'Квартира',
        value: 'flat',
        variantOff: 'outline-primary',
        variantOn: 'primary',
    },
    {
        name: 'Апартаменты',
        value: 'apartments',
        variantOff: 'outline-primary',
        variantOn: 'primary',
    },
    {
        name: 'Студия',
        value: 'studio',
        variantOff: 'outline-primary',
        variantOn: 'primary',
    },
    {
        name: 'Пансионат',
        value: 'pansionat',
        variantOff: 'outline-primary',
        variantOn: 'primary',
    },
    {
        name: 'Малосемейка',
        value: 'malosemeyka',
        variantOff: 'outline-primary',
        variantOn: 'primary',
    },
    {
        name: 'Общежитие',
        value: 'obshchezhitie',
        variantOff: 'outline-primary',
        variantOn: 'primary',
    },
];

const realtyRoomsBtns = [
    {
        name: '1',
        value: '1',
        variantOff: 'outline-primary',
        variantOn: 'primary',
    },
    {
        name: '2',
        value: '2',
        variantOff: 'outline-primary',
        variantOn: 'primary',
    },
    {
        name: '3',
        value: '3',
        variantOff: 'outline-primary',
        variantOn: 'primary',
    },
    {
        name: '4',
        value: '4',
        variantOff: 'outline-primary',
        variantOn: 'primary',
    },
    {
        name: '5+',
        value: '5',
        variantOff: 'outline-primary',
        variantOn: 'primary',
    },
];

const realtyTypeBtns = [
    {
        name: 'Вторичка',
        value: 'secondary',
        variantOff: 'outline-primary',
        variantOn: 'primary',
    },
    {
        name: 'Новостройка от дольщика',
        value: 'primary',
        variantOff: 'outline-primary',
        variantOn: 'primary',
    },
];

export { ownBtns, ownBtnSuburban, realtySubtypesBtns, realtyRoomsBtns, realtyTypeBtns };
