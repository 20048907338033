import React from 'react';
import Select from 'react-select';
import { Form } from 'react-bootstrap';
import { Field } from 'react-final-form';

import Error from '../../Common/Form/Error';

const ReactSelectAdapter = ({ input, ...rest }) => (
    <Select {...input} {...rest} />
);

export default function ReactSelectField({
    name,
    options,
    value,
    label,
    ...rest
}: TProps) {
    return (
        <Field name={name} options={options} {...rest}>
            {({ input }) => (
                <Form.Group className='mb-3'>
                    <Form.Label>{label}</Form.Label>
                    <ReactSelectAdapter
                        input={input}
                        value={value}
                        options={options}
                        {...rest}
                    />
                    <Error name={name} />
                </Form.Group>
            )}
        </Field>
    );
}

type TProps = Omit<React.InputHTMLAttributes<HTMLSelectElement>, 'value'> & {
    name: string;
    label: string;
    value?: { label: string; value: any } | { label: string; value: any }[];
    options: { label: string; value: any }[];
    validators?: any;
    isMulti?: boolean;
};
