import { ReactNode } from 'react';
import Portal from '../Portal';

type TProps = {
    children?: ReactNode | undefined;
    onClose: () => void;
    isOpened: boolean;
};

const OverlayingPopup = ({
    children,
    onClose,
    isOpened,
}: TProps): JSX.Element => {
    if (!isOpened) {
        return null;
    }

    return (
        <Portal>
            <div className='portal_popup'>
                <div
                    className='portal_popup__overlay'
                    tabIndex={0}
                    role='button'
                    onClick={onClose}
                />
                {children}
            </div>
        </Portal>
    );
};

export default OverlayingPopup;
