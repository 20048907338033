import { Form } from 'react-bootstrap';
import { Field } from 'react-final-form';

type TProps = React.InputHTMLAttributes<HTMLInputElement> & {
    label: string;
};

export default function CheckboxField({
    name,
    label,
    onChange,
}: TProps): JSX.Element {
    return (
        <Field
            name={name}
            type='checkbox'
            format={(v) => v == 1}
            parse={(v) => (v ? 1 : 0)}
        >
            {({ input }) => (
                <Form.Group className='mb-3'>
                    <Form.Check type='switch'>
                        <Form.Check.Label>{label}</Form.Check.Label>
                        <Form.Check.Input
                            {...input}
                            type='checkbox'
                            onChange={
                                typeof onChange === 'function'
                                    ? onChange
                                    : input.onChange
                            }
                            checked={input.checked}
                        />
                    </Form.Check>
                </Form.Group>
            )}
        </Field>
    );
}
