import { Field, useForm } from 'react-final-form';
import { Col, Container, Form, Row } from 'react-bootstrap';
import React from 'react';
import InputField from '../../Common/Form/InputField';

const UpdateDHPContact = () => {
    const form = useForm();

    return (
        <>
            <InputField
                name='userUidPhone'
                label='Телефон закрепленного агента'
                onChange={(e) =>
                    form.mutators.setUserUidValue(
                        'userUidPhone',
                        e.target.value,
                    )
                }
            />
            <Field name='userUid'>
                {({ meta }) => (
                    <Form.Group>
                        <Form.Control.Feedback
                            type='invalid'
                            style={{
                                display:
                                    meta.error || meta.submitError
                                        ? 'block'
                                        : 'none',
                            }}
                        >
                            {meta.error || meta.submitError}
                        </Form.Control.Feedback>
                    </Form.Group>
                )}
            </Field>
            <Field name='userUidName'>
                {({ input }) => (
                    <Form.Group className='mb-3'>
                        <Form.Label>Имя закрепленного агента</Form.Label>
                        <Form.Control
                            {...input}
                            size='sm'
                            placeholder='Имя агента'
                            readOnly
                        />
                    </Form.Group>
                )}
            </Field>
        </>
    );
};

export { UpdateDHPContact };
