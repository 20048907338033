import dateFormat from 'dateformat';

interface IDateTrello {
    date: string;
    dateFirst: string;
    dateLast?: string;
}

const today = new Date();

const dateTrello: IDateTrello[] = [
    {
        date: 'today',
        dateFirst: dateFormat(today, 'dd.mm.yyyy'),
        dateLast: null,
    },
    {
        date: 'tomorrow',
        dateFirst: dateFormat(
            new Date(today.getTime() + 24 * 60 * 60 * 1000),
            'dd.mm.yyyy',
        ),
        dateLast: null,
    },
    {
        date: 'three',
        dateFirst: dateFormat(
            new Date(today.getTime() + 3 * 24 * 60 * 60 * 1000),
            'dd.mm.yyyy',
        ),
        dateLast: dateFormat(
            new Date(today.getTime() + 6 * 24 * 60 * 60 * 1000),
            'dd.mm.yyyy',
        ),
    },
    {
        date: 'week',
        dateFirst: dateFormat(
            new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000),
            'dd.mm.yyyy',
        ),
        dateLast: dateFormat(
            new Date(today.getTime() + 29 * 24 * 60 * 60 * 1000),
            'dd.mm.yyyy',
        ),
    },
    {
        date: 'month',
        dateFirst: dateFormat(
            new Date(
                today.getFullYear(),
                today.getMonth() + 1,
                today.getDate(),
            ),
            'dd.mm.yyyy',
        ),
        dateLast: '>',
    },
];

export const dateFormatterTrello = (date: string) => {
    const dates = dateTrello.find((_d) => _d.date === date);
    if (date !== 'archive' && date !== 'deal') {
        return dates.dateLast
            ? `${dates.dateFirst} - ${dates.dateLast}`
            : dates.dateFirst;
    }
};
