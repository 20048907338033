import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CreateForm from '~/src/components/HousingEstate/create';
import { unload, selectDeveloper } from '~/src/store/developer/developerSlice';
import { selectApp, setHandleScrollPosition } from '~/src/store/appSlice';

export default function CreatePage(): JSX.Element {
    const dispatch = useDispatch();
    const { unloaded } = useSelector(selectDeveloper);
    const { loading } = useSelector(selectApp);

    useEffect(() => {
        dispatch(setHandleScrollPosition(false));
    }, [dispatch]);

    useEffect(() => {
        if (unloaded === null) {
            dispatch(unload());
        }
    }, [dispatch, unloaded]);

    return (
        <React.Fragment>{loading ? <>...</> : <CreateForm />}</React.Fragment>
    );
}
