import { ICottageVillageConstructionPhase } from './cottage.village.construction.phase.service';
import { IResponseListData, IUpdateData } from '../../interfaces/common';
import { ICottageVillage } from './cottage.village.service';
import Api from '~/src/helpers/http-common';
import { IFilestore } from '../filestore';
import { AxiosRequestConfig } from 'axios';

export const all = async (
    queryParams?: URLSearchParams,
): Promise<IResponseListData<ICottageVillage>> => {
    return await Api.get(
        `/cottage-village/plot/all?${queryParams != null ? queryParams.toString() : ''}`,
    );
};

export const unload = async (): Promise<any> => {
    return await Api.get('/cottage-village/plot/unload');
};

export const create = async (
    data: ICottageVillagePlot,
    files?: Record<string, FileList>,
): Promise<ICottageVillagePlot | any> => {
    return await Api.post<ICottageVillagePlot>(
        '/cottage-village/plot',
        data,
        files,
    );
};

export const read = async (id: number): Promise<ICottageVillagePlot> => {
    return await Api.get(`/cottage-village/plot/${id}`);
};

export const update = async (
    id: string | number,
    data: ICottageVillagePlot,
    files?: Record<string, FileList>,
): Promise<IUpdateData<ICottageVillagePlot>> => {
    return await Api.put<IUpdateData<ICottageVillagePlot>>(
        `/cottage-village/plot/${id}`,
        data,
        files,
    );
};

export const remove = async (id: string | number): Promise<any> => {
    return await Api.delete(`/cottage-village/plot/${id}`);
};

export const uploadPhotos = async (
    id: number,
    files: FileList | File[],
    config?: AxiosRequestConfig,
): Promise<IFilestore[]> => {
    console.dir(files);
    return await Api.post(
        `/cottage-village/plot/upload-photo/${id}`,
        null,
        { files },
        config,
    );
};

export const deleteFile = async (
    id: string | number,
    name: string,
): Promise<IFilestore | any> => {
    return await Api.delete<IFilestore>(
        `/cottage-village/plot/${id}/files/${name}`,
    );
};

export interface ICottageVillagePlot {
    id: number;

    cottageVillageId: number;
    cottageVillageConstructionPhaseId: number;

    number: number;
    price: number;
    area: number;
    type: TCVPType;

    updatedAt: Date;
    createdAt: Date;
    updatedBy: string;
    createdBy: string;

    photos?: IFilestore[];

    cottageVillage: ICottageVillage;
    cottageVillageConstructionPhase: ICottageVillageConstructionPhase;
}

export type TCVPType = 'land' | 'with_house' | 'with_construction_contract';
