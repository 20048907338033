import { Link } from 'react-router-dom';
import { useQuery } from '~/src/helpers/hooks';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '~/src/components/Common/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BaseSyntheticEvent, useCallback, useState } from 'react';
import './Trello/TrelloView.css';
import {
    selectBuyerList,
    goTo,
    all,
    setSearch,
    setSearchBoard,
    goToBoard,
} from '~/src/store/buyer/buyer.list.slice';
import {
    Button,
    ButtonGroup,
    Col,
    Form,
    Navbar,
    Row,
    Table,
    ToggleButton,
} from 'react-bootstrap';
import ControlDateBtn from './ControlDateBtn';
import ListRow from './ListRow';
import { selectAuth } from '~/src/store/authSlice';
import Select from 'react-select';
import React from 'react';
import AuthLayer from '~/src/components/Common/Hoc/index';
import RealtyTypeBtn from './RealtyTypeBtn';
import BuyerChangeDatePicker from './BuyerChangeDatePicker';
import { thermometer } from './data/data';
import * as APConstants from '~/src/constants/agency.permission';
import MainPopup from '../Common/ModalUI/MainPopup';
import PieChart from '../Common/Chart/PieChart';

const HousingEstateListView = (): JSX.Element => {
    const query = useQuery();
    const dispatch = useDispatch();

    const { items, search, searchBoard, statistics } = useSelector(selectBuyerList);
    const { me, meGroup } = useSelector(selectAuth);
    const [showStatistics, setShowStatistics] = useState(false);
    const SetIsCloseModalPopup = () => setShowStatistics(false);

    const sortHandler = useCallback(
        (e: BaseSyntheticEvent) => {
            dispatch(goTo({ sort: e.currentTarget.value }));
        },
        [dispatch],
    );

    const limitHandler = useCallback(
        (e: BaseSyntheticEvent) => {
            dispatch(goTo({ limit: e.currentTarget.value }));
        },
        [dispatch],
    );

    const handlePaginationClick = (page: number) => {
        dispatch(goTo({ page }));
    };

    const handleOpenBoard = () => {
        dispatch(setSearchBoard(search));
        dispatch(goToBoard(search));
    };

    const agents = meGroup?.position === 'supervisor'
        ? meGroup?.agents?.map(({ fullname, uid }) => {
            return {
                value: uid,
                label: fullname,
            };
        })
        : me?.agencyInfo?.agencyAgents?.map(({ fullname, uid }) => {
            return {
                value: uid,
                label: fullname,
            };
        });
    const dataThermo = {
        labels: ['Все', 'Горячие', 'Теплые', 'Холодные'],
        datasets: [
            {
                data: [
                    statistics.thermo?.all,
                    statistics.thermo?.hot,
                    statistics.thermo?.warm,
                    statistics.thermo?.hot,
                ],
                backgroundColor: [
                    'rgb(171, 255, 255)',
                    'rgb(255, 59, 59)',
                    'rgb(255, 185, 102)',
                    'rgb(127, 115, 255)',
                ],
                borderColor: [
                    'rgb(0, 255, 255)',
                    'rgb(252, 0, 0)',
                    'rgb(255, 138, 0)',
                    'rgb(0, 56, 255)',
                ],
                borderWidth: 1,
            },
        ],
    };
    const dataRealtyType = {
        labels: ['Новостройки', 'Вторчная недвижимость', 'Дом', 'Земельный участок', 'Таунхаус', 'Дача'],
        datasets: [
            {
                data: [
                    statistics.realtyType?.primary,
                    statistics.realtyType?.secondary,
                    statistics.realtyType?.house,
                    statistics.realtyType?.land,
                    statistics.realtyType?.townhouse,
                    statistics.realtyType?.chalet
                ],
                backgroundColor: [
                    'rgb(171, 255, 255)',
                    'rgb(255, 59, 59)',
                    'rgb(255, 185, 102)',
                    'rgb(103, 246, 117)',
                    'rgb(127, 115, 255)',
                    'rgb(176, 140, 49)',
                ],
                borderColor: [
                    'rgb(0, 255, 255)',
                    'rgb(252, 0, 0)',
                    'rgb(255, 138, 0)',
                    'rgb(0, 255, 25)',
                    'rgb(0, 56, 255)',
                    'rgb(120, 87, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const onSelectChange = (
        field: string,
        o: { label: string; value: string },
    ) => {
        const updatedSearch = { ...search, [field]: o?.value ?? null };
        dispatch((dispatch) => {
            dispatch(setSearch(updatedSearch));
            dispatch(goTo(updatedSearch));
        });
    };

    const handleChangeValues = (e: BaseSyntheticEvent) => {
        const updatedSearch = { ...search, [e.target.name]: e.target.value };
        if (search[e.target.name] === e.target.value) {
            dispatch((dispatch) => {
                dispatch(setSearch({ ...search, [e.target.name]: null }));
                dispatch(goTo(updatedSearch));
            });
        } else {
            dispatch((dispatch) => {
                dispatch(setSearch(updatedSearch));
                dispatch(goTo(updatedSearch));
            });
        }
    };

    const handleChangeValue = (e: BaseSyntheticEvent) => {
        const updatedSearch = { ...search, [e.target.name]: e.target.value };
        dispatch((dispatch) => {
            dispatch(setSearch(updatedSearch));
            if (e.target.name !== 'q' && e.target.name !== 'id') {
                dispatch(goTo(updatedSearch));
            }
        });
    };
    const onKeyDown = (e) => {
        if (e.keyCode == 13) {
            dispatch(goTo(search));
        }
    };

    const handleChangeBSCheckboxValueStatus = (e: BaseSyntheticEvent) => {
        if (search.status === e.target.value) {
            const updatedSearch = { ...search, [e.target.name]: 'active' };
            dispatch(setSearch(updatedSearch));
            dispatch(goTo(updatedSearch));
        } else {
            const updatedSearch = {
                ...search,
                [e.target.name]: e.target.value,
                controlDate: null,
            };
            dispatch(setSearch(updatedSearch));
            dispatch(goTo(updatedSearch));
        }
    };

    const handleChangeCheckboxValue = (e: BaseSyntheticEvent) => {
        dispatch(
            goTo({
                ...search,
                [e.target.name]:
                    search[e.target.name] == 'yes' ? undefined : 'yes',
            }),
        );
    };

    const handleFind = () => {
        dispatch(goTo(search));
    };

    return (
        <>
            <Navbar
                className='justify-content-between mb-2'
                bg='light'
                expand='lg'
                variant='light'
            >
                <ButtonGroup>
                    <Link
                        to={'/buyer/create/'}
                        className='btn btn-sm btn-success'
                    >
                        <FontAwesomeIcon icon={['fas', 'plus']} /> Добавить
                    </Link>
                    <Button
                        onClick={handleOpenBoard}
                        className='btn btn-sm btn-primary'
                    >
                        <FontAwesomeIcon icon={['fas', 'table']} /> Таблица
                    </Button>
                </ButtonGroup>
                <ButtonGroup>
                    <Button
                        size='sm'
                        type='button'
                        variant='primary'
                        onClick={handleFind}
                    >
                        <FontAwesomeIcon icon={['fas', 'search']} /> Найти
                    </Button>
                    <Button
                        size='sm'
                        variant='secondary'
                        onClick={() => dispatch(all(query))}
                    >
                        <FontAwesomeIcon icon={['fas', 'sync']} /> Обновить
                    </Button>
                    <Button
                        size='sm'
                        variant='danger'
                        onClick={() => dispatch(goTo({ reset: true }))}
                    >
                        <FontAwesomeIcon icon={['fas', 'sync']} /> Сбросить
                        фильтр
                    </Button>
                </ButtonGroup>
            </Navbar>
            <Row className='mb-2'>
                <Col className='d-flex gap-3'>
                    <AuthLayer
                        position={['director', 'administrator', 'supervisor']}
                        permission={APConstants.BUYER_AGENCY_ALL}
                    >
                        <ToggleButton
                            size='sm'
                            id='my-id'
                            type='checkbox'
                            variant={
                                search?.my == 1 ? 'primary' : 'outline-primary'
                            }
                            name='my'
                            value={1}
                            checked={search?.my == 1}
                            onChange={handleChangeValues}
                        >
                            Только мои
                        </ToggleButton>
                    </AuthLayer>
                    <ToggleButton
                        size='sm'
                        id='status-idArchive'
                        type='checkbox'
                        variant={
                            search?.status == 'archive'
                                ? 'primary'
                                : 'outline-primary'
                        }
                        name='status'
                        value={'archive'}
                        checked={search?.status == 'archive'}
                        onChange={handleChangeBSCheckboxValueStatus}
                    >
                        Архив
                    </ToggleButton>
                    <ToggleButton
                        size='sm'
                        id='status-idDeal'
                        type='checkbox'
                        variant={
                            search?.status == 'deal'
                                ? 'primary'
                                : 'outline-primary'
                        }
                        name='status'
                        value={'deal'}
                        checked={search?.status == 'deal'}
                        onChange={handleChangeBSCheckboxValueStatus}
                    >
                        Сделка
                    </ToggleButton>
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col>
                    <ControlDateBtn />
                </Col>
            </Row>
            <Row>
                <Col>
                    <RealtyTypeBtn
                        search={search}
                        setSearch={setSearch}
                        goTo={goTo}
                    />
                </Col>
            </Row>
            <AuthLayer
                position={['director', 'administrator', 'supervisor']}
                permission='agency.permission.buyer.all'
            >
                <Row className='d-flex mt-3 align-items-center'>
                    <Col xs={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>
                                <strong>Агенты</strong>
                            </Form.Label>
                            <Select
                                name='agentUid'
                                options={agents}
                                value={agents?.filter(
                                    (r) => search?.agentUid == r.value,
                                )}
                                onChange={(o) => onSelectChange('agentUid', o)}
                                isClearable
                                placeholder='Агент'
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className='mb-3'>
                            <Form.Label>
                                <strong>Термометр</strong>
                            </Form.Label>
                            <Select
                                name='thermometer'
                                options={thermometer}
                                value={thermometer.filter(
                                    (r) => search.thermometer == r.value,
                                )}
                                onChange={(o) =>
                                    onSelectChange('thermometer', o)
                                }
                                isClearable
                                placeholder='Термометр'
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className='mb-3'>
                            <Form.Label>
                                <strong>Дата внесения покупателя</strong>
                            </Form.Label>
                            <BuyerChangeDatePicker
                                search={search}
                                setSearch={setSearch}
                                goTo={goTo}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </AuthLayer>
            <Row>
                <Col>
                    <ButtonGroup>
                        <ToggleButton
                            size='sm'
                            id='newbie'
                            type='checkbox'
                            variant={
                                search?.newbie == 'yes'
                                    ? 'primary'
                                    : 'outline-primary'
                            }
                            style={{
                                marginRight: '20px'
                            }}
                            name='newbie'
                            value={'yes'}
                            checked={search?.newbie == 'yes'}
                            onChange={handleChangeCheckboxValue}
                        >
                            Новые
                        </ToggleButton>
                        <ToggleButton
                            size='sm'
                            id='showStatistics'
                            type='checkbox'
                            variant={
                                showStatistics
                                    ? 'primary'
                                    : 'outline-primary'
                            }
                            name='showStatistics'
                            value={'yes'}
                            checked={showStatistics}
                            onChange={() => setShowStatistics((prev) => !prev)}
                        >
                            Статистика
                        </ToggleButton>
                    </ButtonGroup>
                </Col>
            </Row>
            <Row className='mb-3'>
                {me && me.agencyInfo ? (
                    <Col
                        xs={6}
                        className='d-flex justify-content-between gap-1'
                    >
                        <Col xs={8}>
                            <Form.Group>
                                <Form.Label>
                                    <strong>Телефон / ФИО покупателя</strong>
                                </Form.Label>
                                <Form.Control
                                    name='q'
                                    value={search?.q || ''}
                                    onChange={handleChangeValue}
                                    onKeyDown={onKeyDown}
                                    autoComplete='off'
                                    size='sm'
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={4}>
                            <Form.Group>
                                <Form.Label>
                                    <strong>ID покупателя</strong>
                                </Form.Label>
                                <Form.Control
                                    name='id'
                                    value={search?.id || ''}
                                    onChange={handleChangeValue}
                                    onKeyDown={onKeyDown}
                                    autoComplete='off'
                                    size='sm'
                                />
                            </Form.Group>
                        </Col>
                    </Col>
                ) : null}
                <AuthLayer
                    position={['director', 'administrator']}
                    permission='agency.permission.buyer.all'
                >
                    <Col>
                        <Form.Group className='mb-3'>
                            <Form.Label>
                                <strong>Группы</strong>
                            </Form.Label>
                            <Form.Select
                                onChange={handleChangeValue}
                                size='sm'
                                name='agencyGroupId'
                                value={search?.agencyGroupId || 0}
                            >
                                {me?.agencyInfo?.agencyGroups?.map(
                                    ({ title, id }, index) => {
                                        if (index === 0) {
                                            return (
                                                <React.Fragment key={id}>
                                                    <option value=''></option>
                                                    <option
                                                        key={id}
                                                        value={`${id}`}
                                                    >
                                                        {title}
                                                    </option>
                                                </React.Fragment>
                                            );
                                        } else return (
                                            <option
                                                key={id}
                                                value={`${id}`}
                                            >
                                                {title}
                                            </option>
                                        );
                                    },
                                )}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </AuthLayer>
            </Row>

            <Row className='mb-2'>
                <Col xs={4}>
                    <Form.Label>Сортировать</Form.Label>
                    <Form.Select
                        className='form-control'
                        size='sm'
                        value={search?.sort || 'id_asc'}
                        onChange={sortHandler}
                    >
                        <option value='id_asc'>ID &uarr;</option>
                        <option value='id_desc'>ID &darr;</option>
                        <option value='cd_asc'>КД &uarr;</option>
                        <option value='cd_desc'>КД &darr;</option>
                    </Form.Select>
                </Col>
                <Col xs={4}>
                    <Form.Label>На странице</Form.Label>
                    <Form.Select
                        className='form-control'
                        size='sm'
                        value={search?.limit || 25}
                        onChange={limitHandler}
                    >
                        <option value={1}>1</option>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={100}>100</option>
                    </Form.Select>
                </Col>
                <Col xs={4}>
                    <div className='mb-2'>
                        <Form.Label></Form.Label>
                    </div>
                    <Form.Label>
                        <strong>Всего объектов:</strong> {items.count}
                    </Form.Label>
                </Col>
            </Row>
            {items && items.pagination?.have && (
                <Pagination
                    pagination={items.pagination}
                    onClick={handlePaginationClick}
                />
            )}
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <td>#</td>
                        <td>ФИО</td>
                        <td>Запрос</td>
                        <td>Заметки</td>
                        <td>КД</td>
                        <td>Примечание</td>
                        <td>Управление</td>
                    </tr>
                </thead>
                <tbody>
                    {items && items.items && items.items.length > 0
                        ? items.items.map((item) => (
                            <ListRow key={item.id} item={item} />
                        ))
                        : null}
                </tbody>
            </Table>
            {items && items.pagination?.have && (
                <Pagination
                    pagination={items.pagination}
                    onClick={handlePaginationClick}
                />
            )}
            {
                showStatistics &&
                <MainPopup
                    title={'Статистика по покупателям'}
                    onClose={() => {
                        // eslint-disable-next-line new-cap
                        SetIsCloseModalPopup();
                    }}
                    isOpened={showStatistics}
                    fullwidth={true}
                    overflow={true}
                >
                    <div className='d-flex justify-content-between'>
                        <PieChart data={dataThermo} title={'Термометр'} />
                        <PieChart data={dataRealtyType} title={'Тип недвижимости'} />
                    </div>
                </MainPopup>

            }
        </>
    );
};

export default HousingEstateListView;
