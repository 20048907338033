import { createSlice, Slice } from '@reduxjs/toolkit';
import * as api from '~/src/service/housing.estate';
import type { TAppDispatch, TState, TAnyAction } from '~/src/store';

export interface ICombineHousingLivingAreaData {
    value: number;
    label: string;
    type?: string;
}

interface ILivingArea {
    combineHousingLivingAreaData?: ICombineHousingLivingAreaData[];
    allLivingArea?: ICombineHousingLivingAreaData[];
}
const initialState = {
    combineHousingLivingAreaData: null,
    allLivingArea: null,
};
export const sliceName = 'livingArea';

const livingAreaSlice: Slice<ILivingArea> = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        unloadData: (state: ILivingArea, action: TAnyAction<any>) => {
            state.allLivingArea = action.payload;
        },
        combineData: (state: ILivingArea, action: TAnyAction<any>) => {
            state.combineHousingLivingAreaData = action.payload;
        },
    },
});

export const { unloadData, combineData } = livingAreaSlice.actions;

export const unloadAllLivingArea = () => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        const response = await api.unloadArea();
        dispatch(
            unloadData(
                response
                    .filter((label, index) => label != null)
                    .map((label, index) => ({
                        value: index + 1,
                        label: label,
                    })),
            ),
        );
    };
};

export const combineHousingLivingArea = (array1, array2) => {
    return (dispatch: TAppDispatch) => {
        let lasnumber = array1?.at(-1)?.value;
        const newArray1 = array1?.map((item) => ({ ...item, type: 'housing' }));
        const newArray2 = array2?.map((item) => ({
            ...item,
            value: ++lasnumber,
            type: 'area',
        }));
        const combinedArray = [...newArray1, ...newArray2];
        dispatch(combineData(combinedArray));
    };
};

export const selectlivingArea = (state: TState): ILivingArea =>
    state.livingArea;

export default livingAreaSlice.reducer;
