import * as api from '~/src/service/agency.settings';
import { createSlice, Slice } from '@reduxjs/toolkit';
import { setLoading as setAppLoading } from './appSlice';
import { IAgencySetting } from '../service/agency.settings';
import type { TAppDispatch, TState, TAnyAction } from '~/src/store';

export const sliceName = 'agency.settings';

interface ISettingsSlice {
    items: IAgencySetting[];
    errors?: any;
}

const initialState = {
    items: [],
    loading: true,
    baseLink: '/agency/settings',
};

const settingsSlice: Slice<ISettingsSlice> = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        setAll: (
            state: ISettingsSlice,
            action: TAnyAction<IAgencySetting[]>,
        ) => {
            state.items = action.payload;
        },
        setErrors: (state: ISettingsSlice, action: TAnyAction<any>) => {
            state.errors = action.payload;
        },
    },
});

export const { setAll, setErrors } = settingsSlice.actions;

export const all = () => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(setAppLoading(true));
        const response = await api.all();

        const data = allsettings.map((setting) => {
            const resSetting = response.filter(
                (item) => item.code == setting.code,
            )[0];

            return {
                title: setting.title,
                code: setting.code,
                type: setting.type,
                enabled: resSetting?.enabled ?? false,
                value: resSetting?.value ?? null,
            };
        });

        dispatch(setAll(data));
        dispatch(setAppLoading(false));
    };
};

export const update = (id: number, data: any) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const response = await api.update(id, data);
            if (response && response.success) {
                // dispatch(set(response.data));
            } else {
                dispatch(setErrors(response.errors));
            }
        } catch (e) {
            console.error(e);
            dispatch(setErrors(e));
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const selectAgencySettings = (state: TState): ISettingsSlice =>
    state[sliceName];

export default settingsSlice.reducer;

const allsettings = [
    {
        title: 'При незаполнении обязательных полей объект уходит в архив',
        code: 'unfullfied.archive',
        type: 'number',
    },
    {
        title: 'Без продления и изменения объекта он уходит в архив',
        code: 'unfresh.archive',
        type: 'number',
    },
];
