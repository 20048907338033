import { Form } from 'react-bootstrap';
import React, { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { switchPermission } from '~/src/service/agency.permission';
import { IUserPermissionUnloaded } from '~/src/service/permission';

const ListRow: FC<{ item: IUserPermissionUnloaded }> = ({ item }) => {
    const { id: agencyAgentId } = useParams<{ id: string }>();
    const [isEnabled, setEnabled] = useState(false);

    useEffect(() => {
        setEnabled(!!item?.isEnabled);
    }, [item.isEnabled]);

    const handleClick = async () => {
        const res = await switchPermission(+agencyAgentId, item.code);

        if (res && res.success) {
            setEnabled(!isEnabled);
        }
    };

    return (
        <tr>
            <td>
                <p>{item.title}</p>
            </td>
            <td>
                <Form.Group className='mb-3'>
                    <Form.Check type='switch'>
                        <Form.Check.Input
                            type='checkbox'
                            checked={isEnabled}
                            defaultChecked={!!item?.isEnabled}
                            onClick={handleClick}
                        />
                    </Form.Check>
                </Form.Group>
            </td>
        </tr>
    );
};

export default ListRow;
