import React, { ReactNode } from 'react';
import { Card } from 'react-bootstrap';
import OverlayingPopup from '../OverlayingPopup';

type TProps = {
    isOpened: boolean;
    title?: string;
    onClose: () => void;
    children: ReactNode | undefined;
    fullwidth?: boolean;
    overflow?: boolean;
};

const MainPopup = ({
    title,
    onClose,
    children,
    isOpened,
    fullwidth = false,
    overflow = false,
}: TProps): JSX.Element => {
    return (
        <OverlayingPopup isOpened={isOpened} onClose={onClose}>
            <div
                className={'main_popup'}
                style={{
                    overflowY: `${overflow ? 'scroll' : 'visible'}`,
                    width: `${fullwidth ? '80vw' : ''}`,
                    height: `${fullwidth ? '90vh' : ''}`,
                }}
            >
                <Card>
                    <Card.Header>{title}</Card.Header>
                    <Card.Body>{children}</Card.Body>
                </Card>
            </div>
        </OverlayingPopup>
    );
};

export default MainPopup;
