import { Field, Form as FinalForm } from 'react-final-form';
import { useDebouncedCallback } from 'use-debounce';
import { rooms } from './data/rooms';
import { realtyTypes } from './data/realtyType';
import { useNavigate } from 'react-router-dom';
import Comments from '../Comment';
import InputField from '../Common/Form/InputField';
import { CommentForm } from '../Comment/views/form';
import { useDispatch, useSelector } from 'react-redux';
import TextareaField from '../Common/Form/TextareaField';
import DatePickerField from '../Common/Form/DatePickerField/DatePickerField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    create,
    update,
    changeStatusItem,
    addCommentFromRow,
} from '~/src/store/buyer/buyer.list.slice';
import { selectBuyerList } from '~/src/store/buyer/buyer.list.slice';
import { ToggleButtonFieldV2 } from '../Common/Form/ToggleButtonWrapperV2';
import createInitialFormValues from '~/src/helpers/createInitialFormValues';
import {
    composeValidators,
    mustBeNumber,
    onlyLength,
    required,
} from '~/src/helpers/validators';
import {
    Badge,
    Button,
    ButtonGroup,
    Col,
    Container,
    Form,
    Navbar,
    Row,
    Stack,
    ToggleButton,
} from 'react-bootstrap';
import Select from 'react-select';
import { selectAuth } from '~/src/store/authSlice';
import AuthLayer from '~/src/components/Common/Hoc/index';
import ButtonWithConfirmModal from '../Common/ModalUI/ButtonWithConfirm/btnModal';
import { checkDoubleContactPhone } from '~/src/service/buyer';
import { useState } from 'react';
import SimpleSelectV2Field from '../Common/Form/SimpleSelectV2';
import { cash, certificate, mortgage, payment } from './data/payment';

const UpdateForm = (): JSX.Element => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { item, sources } = useSelector(selectBuyerList);
    const { me } = useSelector(selectAuth);
    const [isDouble, setIsDouble] = useState(false);
    const [doubleId, setDoubleId] = useState();

    const agents = me?.agencyInfo?.agencyAgents?.map(({ fullname, uid }) => {
        return {
            value: uid,
            label: fullname,
        };
    });

    const handleArchive = async (value) => {
        dispatch(addCommentFromRow(item.id, value));
        item?.status === 'archive'
            ? dispatch(changeStatusItem(item?.id, 'active'))
            : dispatch(changeStatusItem(item?.id, 'archive'));
    };
    const handleDeal = async (value) => {
        dispatch(addCommentFromRow(item.id, value));
        item?.status === 'deal'
            ? dispatch(changeStatusItem(item?.id, 'active'))
            : dispatch(changeStatusItem(item?.id, 'deal'));
    };

    const customStyles = {
        menu: (provided) => ({
            ...provided,
            zIndex: 9999
        }),
        menuPortal: (base) => ({
            ...base,
            zIndex: 9999
        })
    };

    const onSubmit = async (values: Record<string, any>) => {
        if (item?.id === undefined) {
            try {
                const res = dispatch(
                    create({
                        ...values,
                        fields: {
                            ...values.fields,
                            priceFrom: values.fields?.priceFrom?.replaceAll(
                                /\D/g,
                                '',
                            ),
                            priceTo: values.fields?.priceTo?.replaceAll(
                                /\D/g,
                                '',
                            ),
                            realtyType: values.realtyType ? [...values.realtyType] : null,
                            rooms: values.rooms ? [...values.rooms] : null,
                        },
                        agentUid: !values.agentUid?.value
                            ? values.agentUid
                            : values.agentUid?.value,
                    }),
                );

                if (res instanceof Promise) {
                    res.then(() => navigate(-1));
                }
            } catch (error) {
                console.warn(error);
            }
        } else {
            try {
                const res = dispatch(
                    update(+values.id, {
                        ...values,
                        agentUid: !values.agentUid?.value
                            ? values.agentUid
                            : values.agentUid?.value,
                        fields: {
                            ...values.fields,
                            realtyType: values.realtyType ? [...values.realtyType] : null,
                            rooms: values.rooms ? [...values.rooms] : null,
                        }
                    }),
                );

                if (res instanceof Promise) {
                    res.then(() => navigate(-1));
                }
            } catch (error) {
                console.warn(error);
            }
        }
    };

    const debounced = useDebouncedCallback(async (phone) => {
        if (phone) {
            const res = await checkDoubleContactPhone(phone);
            if (res.success) {
                setIsDouble(true);
                setDoubleId(res.data.id);
            } else {
                setIsDouble(false);
            }
        }
    }, 2000);

    const goBack = () => {
        navigate(-1);
    };

    return (
        <Row>
            <Col>
                <FinalForm
                    onSubmit={onSubmit}
                    initialValues={{
                        ...createInitialFormValues(item),
                        agentUid: item?.agentUid || undefined,
                        realtyType: item?.fields?.realtyType,
                        rooms: item?.fields?.rooms,
                        paymentSelect:
                            item &&
                                ('cash' in item ||
                                    'certificate' in item ||
                                    'mortgage' in item)
                                ? item.cash !== null
                                    ? 'cash'
                                    : item.certificate !== null
                                        ? 'certificate'
                                        : item.mortgage !== null
                                            ? 'mortgage'
                                            : null
                                : null,
                    }}
                    mutators={{
                        setValue: ([field, value], state, { changeValue }) => {
                            changeValue(state, field, () => value);
                        },
                        setPriceValue: (
                            [field, value],
                            state,
                            { changeValue },
                        ) => {
                            value = value.replaceAll(/\D/g, '');
                            changeValue(state, field, () =>
                                new Intl.NumberFormat('ru-RU').format(value));
                        },
                        setPhoneNumberCheckValue: async (
                            [field, value],
                            state,
                            { changeValue },
                        ) => {
                            changeValue(state, field, () => value);
                            debounced(state.formState.values['contactPhone']);
                        },
                        changePaymentSelect: (
                            [field, value],
                            state,
                            { changeValue },
                        ) => {
                            if (
                                state.formState.values['paymentSelect'] == value
                            ) {
                                changeValue(state, field, () => null);
                            } else {
                                changeValue(state, field, () => value);
                            }
                            changeValue(state, 'mortgage', () => null);
                            changeValue(state, 'cash', () => null);
                            changeValue(state, 'certificate', () => null);
                        },
                        setMultiCheckbox: ([field, value], state, { changeValue }) => {
                            if (state.formState.values?.[field] === undefined) {
                                changeValue(state, field, () => value);
                            }
                            if (Array.isArray(state.formState.values[field])) {
                                if (state.formState.values[field]?.includes(value)) {
                                    changeValue(state, field, () => Array.from(
                                        state.formState.values[field],
                                    ).filter((v: string) => v !== value));
                                } else {
                                    changeValue(state, field, () => [
                                        ...state.formState.values[field],
                                        value
                                    ]);
                                }
                                return;
                            }
                            if (state.formState.values[field] === value) {
                                changeValue(state, field, () => []);
                            } else {
                                changeValue(state, field, () => [
                                    state.formState.values[field],
                                    value
                                ]);
                            }
                        }
                    }}
                    validate={(values) => {
                        const errors: any = {};

                        if (
                            !values.paymentSelect &&
                            me?.agencyInfo?.agent?.position !== 'administrator'
                        ) {
                            errors.paymentSelect = 'Выберите оплату';
                        }
                        return errors;
                    }}
                    render={({
                        handleSubmit,
                        form,
                        submitting,
                        valid,
                        values,
                    }) => (
                        <form onSubmit={handleSubmit} autoComplete='off'>
                            <Navbar
                                className='justify-content-between'
                                bg='light'
                                expand='lg'
                                variant='light'
                            >
                                <ButtonGroup>
                                    <Button
                                        size='sm'
                                        onClick={goBack}
                                        disabled={submitting}
                                    >
                                        <FontAwesomeIcon
                                            icon={['fas', 'fast-backward']}
                                        />{' '}
                                        Назад
                                    </Button>
                                    <Button
                                        size='sm'
                                        type='submit'
                                        className='btn btn-sm btn-success'
                                        disabled={submitting || !valid}
                                    >
                                        <FontAwesomeIcon
                                            icon={['fas', 'save']}
                                        />{' '}
                                        Сохранить
                                    </Button>
                                </ButtonGroup>
                            </Navbar>
                            <Container fluid>
                                <InputField
                                    name='fullname'
                                    label='Имя'
                                    validators={required}
                                />
                                <Form.Group className='mb-3'>
                                    <InputField
                                        label='Телефон'
                                        name='contactPhone'
                                        onChange={(e) =>
                                            form.mutators.setPhoneNumberCheckValue(
                                                'contactPhone',
                                                e.target.value,
                                            )
                                        }
                                        validators={composeValidators(
                                            required,
                                            onlyLength(10),
                                            mustBeNumber,
                                        )}
                                    />
                                    <Field name='contactPhoneDouble'>
                                        {({ meta }) => (
                                            <Form.Group>
                                                <Form.Control.Feedback
                                                    type='invalid'
                                                    style={{
                                                        display:
                                                            meta.error ||
                                                                meta.submitError ||
                                                                isDouble
                                                                ? 'block'
                                                                : 'none',
                                                    }}
                                                >
                                                    {meta.error ||
                                                        meta.submitError ||
                                                        (isDouble && (
                                                            <a
                                                                href={`/buyer/update/${doubleId}`}
                                                                target='_blank'
                                                                rel='noreferrer'
                                                                style={{
                                                                    color: 'orange',
                                                                    fontSize:
                                                                        '16px',
                                                                }}
                                                            >
                                                                Клиент с таким
                                                                номером уже
                                                                занесен в
                                                                систему: ID{' '}
                                                                {doubleId}
                                                            </a>
                                                        ))}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        )}
                                    </Field>
                                </Form.Group>
                                <ButtonGroup className='mb-2'>
                                    {realtyTypes.map((t) => (
                                        <ToggleButtonFieldV2
                                            key={t.code}
                                            label={t.title}
                                            type='checkbox'
                                            name={'realtyType'}
                                            value={t.code}
                                            checked={form.getState().values.realtyType == t.code}
                                            onChange={(e) => form.mutators.setMultiCheckbox('realtyType', e.target.value)}
                                            variant={
                                                form?.getState().values?.realtyType?.includes(t.code)
                                                    ? 'primary'
                                                    : 'outline-primary'
                                            }
                                        />
                                    ))}
                                </ButtonGroup>
                                <Form.Group>
                                    <Form.Label>Кол-во комнат</Form.Label>
                                </Form.Group>
                                <Form.Group className='mb-2'>
                                    <ButtonGroup>
                                        {rooms.map((t) => (
                                            <ToggleButtonFieldV2
                                                key={t.code}
                                                label={t.title}
                                                type='checkbox'
                                                name={'rooms'}
                                                value={t.code}
                                                checked={form.getState().values.rooms == t.code}
                                                onChange={(e) => form.mutators.setMultiCheckbox('rooms', e.target.value)}
                                                variant={
                                                    form?.getState().values?.rooms?.includes(t.code)
                                                        ? 'primary'
                                                        : 'outline-primary'
                                                }
                                            />
                                        ))}
                                    </ButtonGroup>
                                </Form.Group>
                                <Form.Group>
                                    <DatePickerField
                                        name='controlDate'
                                        label='Контрольная дата'
                                        showTimeSelect
                                        validators={required}
                                    />
                                </Form.Group>
                                <AuthLayer
                                    position={[
                                        'director',
                                        'administrator',
                                        'supervisor',
                                    ]}
                                >
                                    <Field name='agentUid'>
                                        {({ input }) => (
                                            <Form.Group className='mb-2'>
                                                <Form.Label>
                                                    Ведущий агент
                                                </Form.Label>
                                                <Select
                                                    {...input}
                                                    placeholder='Агент'
                                                    options={agents}
                                                    styles={customStyles}
                                                    value={agents?.find(
                                                        (agent) =>
                                                            agent.value ===
                                                            form.getState()
                                                                .values
                                                                .agentUid,
                                                    )}
                                                />
                                            </Form.Group>
                                        )}
                                    </Field>
                                </AuthLayer>
                                <Form.Group>
                                    <Form.Label>Оплата</Form.Label>
                                </Form.Group>
                                <Form.Group className='mb-2'>
                                    <ButtonGroup className='mb-2'>
                                        {payment.map((t, index) => (
                                            <ToggleButtonFieldV2
                                                key={index}
                                                label={t.title}
                                                type='checkbox'
                                                name={'paymentSelect'}
                                                value={t.value}
                                                onChange={(e) =>
                                                    form.mutators.changePaymentSelect(
                                                        'paymentSelect',
                                                        e.target.value,
                                                    )
                                                }
                                                checked={
                                                    form.getState().values
                                                        .paymentSelect ==
                                                    t.value
                                                }
                                            />
                                        ))}
                                    </ButtonGroup>
                                    <Field name='paymentSelect'>
                                        {({ meta }) => (
                                            <Form.Group>
                                                <Form.Control.Feedback
                                                    type='invalid'
                                                    style={{
                                                        display:
                                                            meta.error ||
                                                                meta.submitError ||
                                                                isDouble
                                                                ? 'block'
                                                                : 'none',
                                                    }}
                                                >
                                                    {meta.error ||
                                                        meta.submitError || (
                                                            <p>
                                                                Выберите оплату
                                                            </p>
                                                        )}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        )}
                                    </Field>
                                </Form.Group>

                                <Row>
                                    <Col>
                                        <Field name='purchasePeriod'>
                                            {() => (
                                                <SimpleSelectV2Field
                                                    label='Срок покупки'
                                                    name='purchasePeriod'
                                                    validators={
                                                        me?.agencyInfo?.agent
                                                            ?.position !==
                                                        'administrator' &&
                                                        required
                                                    }
                                                >
                                                    <option></option>
                                                    <option value={'hot'}>
                                                        {'до 1мес.'}
                                                    </option>
                                                    <option value={'warm'}>
                                                        {'1-3мес.'}
                                                    </option>
                                                    <option value={'cold'}>
                                                        {'от 3мес.'}
                                                    </option>
                                                </SimpleSelectV2Field>
                                            )}
                                        </Field>
                                    </Col>
                                    <Col>
                                        {values.paymentSelect && (
                                            <Field name={values?.paymentSelect}>
                                                {() => (
                                                    <SimpleSelectV2Field
                                                        label={
                                                            payment.find(
                                                                (item) =>
                                                                    item.value ===
                                                                    values.paymentSelect,
                                                            ).title
                                                        }
                                                        name={
                                                            values?.paymentSelect
                                                        }
                                                        validators={required}
                                                    >
                                                        <option></option>
                                                        {values.paymentSelect ==
                                                            'mortgage' &&
                                                            mortgage.map(
                                                                (el) => (
                                                                    <option
                                                                        key={
                                                                            el.value
                                                                        }
                                                                        value={
                                                                            el.value
                                                                        }
                                                                    >
                                                                        {
                                                                            el.title
                                                                        }
                                                                    </option>
                                                                ),
                                                            )}
                                                        {values.paymentSelect ==
                                                            'cash' &&
                                                            cash.map((el) => (
                                                                <option
                                                                    key={
                                                                        el.value
                                                                    }
                                                                    value={
                                                                        el.value
                                                                    }
                                                                >
                                                                    {el.title}
                                                                </option>
                                                            ))}
                                                        {values.paymentSelect ==
                                                            'certificate' &&
                                                            certificate.map(
                                                                (el, index) => (
                                                                    <option
                                                                        key={
                                                                            index
                                                                        }
                                                                        value={
                                                                            el.value
                                                                        }
                                                                    >
                                                                        {
                                                                            el.title
                                                                        }
                                                                    </option>
                                                                ),
                                                            )}
                                                    </SimpleSelectV2Field>
                                                )}
                                            </Field>
                                        )}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <InputField
                                            name='fields.priceFrom'
                                            type='text'
                                            label={'Цена от'}
                                            onChange={(e) =>
                                                form.mutators.setPriceValue(
                                                    'fields.priceFrom',
                                                    e.target.value,
                                                )
                                            }
                                        />
                                    </Col>
                                    <Col>
                                        <InputField
                                            name='fields.priceTo'
                                            type='text'
                                            label={'Цена до'}
                                            onChange={(e) =>
                                                form.mutators.setPriceValue(
                                                    'fields.priceTo',
                                                    e.target.value,
                                                )
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <InputField
                                            name='fields.spaceFrom'
                                            type='number'
                                            validators={mustBeNumber}
                                            label={'Площадь от'}
                                        />
                                    </Col>
                                    <Col>
                                        <InputField
                                            name='fields.spaceTo'
                                            type='number'
                                            validators={mustBeNumber}
                                            label={'Площадь до'}
                                        />
                                    </Col>
                                </Row>
                                <TextareaField
                                    name='note'
                                    label='Заметка для себя'
                                />
                                <InputField
                                    name='source'
                                    label='Источник поступления заявки'
                                    validators={required}
                                    autoComplete='off'
                                />
                                <Stack
                                    direction='horizontal'
                                    gap={2}
                                    className='mb-2 flex-wrap'
                                >
                                    {sources?.map((s) => (
                                        <Badge
                                            key={s.source}
                                            style={{ cursor: 'pointer' }}
                                            bg='secondary'
                                            onClick={() =>
                                                form.mutators.setValue(
                                                    'source',
                                                    s.source,
                                                )
                                            }
                                        >
                                            {s.source}
                                        </Badge>
                                    ))}
                                </Stack>
                            </Container>
                            <ButtonGroup>
                                <Button
                                    size='sm'
                                    type='submit'
                                    className='btn btn-sm btn-success'
                                    disabled={submitting || !valid}
                                >
                                    <FontAwesomeIcon icon={['fas', 'save']} />{' '}
                                    Сохранить
                                </Button>
                                <ButtonWithConfirmModal
                                    modalText=''
                                    text={'Оставьте комментарий'}
                                    label={
                                        item?.status === 'archive'
                                            ? 'Убрать из архива'
                                            : 'В архив'
                                    }
                                    variant={
                                        item?.status === 'archive'
                                            ? 'primary'
                                            : 'danger'
                                    }
                                    primaryButtonOnClick={handleArchive}
                                />
                                <ButtonWithConfirmModal
                                    modalText=''
                                    text={'Оставьте комментарий'}
                                    label={
                                        item?.status === 'deal'
                                            ? 'Находится в сделке'
                                            : 'Добавить в сделку'
                                    }
                                    variant={
                                        item?.status === 'deal'
                                            ? 'success'
                                            : 'primary'
                                    }
                                    primaryButtonOnClick={handleDeal}
                                />
                            </ButtonGroup>
                        </form>
                    )}
                />
            </Col>
            <Col>
                {item?.id && (
                    <>
                        <AuthLayer
                            position={[
                                'director',
                                'supervisor',
                                'administrator',
                            ]}
                            permission='agency.permission.buyer.all'
                            itemAgentUid={item.agentUid}
                        >
                            <CommentForm section={'buyer'} entry={item?.id} />
                            <Comments entry={item?.id} section={'buyer'} />
                        </AuthLayer>
                    </>
                )}
            </Col>
        </Row>
    );
};

export default UpdateForm;
