import RSPhotosView from '~/src/components/Realty/Secondary/photos';
import {
    read,
    selectRS,
} from '~/src/store/realtySecondary/realtySecondarySlice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

const RSPhotosPage = (): JSX.Element => {
    const dispatch = useDispatch();
    const params = useParams<TParams>();
    const [item, setItem] = useState(null);
    const RSStore = useSelector(selectRS);

    useEffect(() => {
        dispatch(read(+params.id));
        setItem(RSStore.item);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id, dispatch]);

    if (item) {
        return <RSPhotosView item={item} />;
    }

    return <div>Загрузка...</div>;
};

type TParams = {
    id: string;
};

export default RSPhotosPage;
