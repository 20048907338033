import React, { FC } from 'react';
import dateFormat from 'dateformat';
import { Link } from 'react-router-dom';
import { ButtonGroup } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { selectAuth } from '~/src/store/authSlice';
import { fire } from '~/src/store/agencyAgentsSlice';
import { useDispatch, useSelector } from 'react-redux';
import phoneFormatter from '~/src/helpers/phoneFormatter';
import { IAgencyAgent } from '~/src/service/agency.agents';
import ButtonWithConfirm from '../Common/ModalUI/ButtonWithConfirm';

const IList: FC<{ item: IAgencyAgent }> = ({ item }) => {
    const { userInfo } = item;
    const { me } = useSelector(selectAuth);
    const dispatch = useDispatch();

    const handleFire = () => {
        dispatch(fire(item.id));
    };

    console.dir(item);

    return (
        <React.Fragment>
            <tr>
                <td>фото</td>
                <td>
                    <p>
                        {userInfo?.fullname}
                        <br />
                        <FormattedMessage id={`agency_${item.position}`} />
                    </p>
                </td>
                <td>
                    <p>
                        <strong>Телефон:</strong>
                        <br />
                        {phoneFormatter(userInfo?.phone)}
                    </p>
                    {userInfo?.email && (
                        <p>
                            <strong>Почта:</strong>
                            <br />
                            {userInfo?.email}
                        </p>
                    )}
                </td>
                <td>
                    <p>Работает с {dateFormat(item.invitedAt, 'dd.mm.yyyy')}</p>
                    {item.group && <p>Группа: {item.group.title}</p>}
                </td>
                <td>
                    <ButtonGroup vertical={true} className='w-100'>
                        {me &&
                            (me.agencyInfo.isDirector ||
                                me.agencyInfo.permissions.includes(
                                    'agency.agent.permission',
                                )) &&
                            !item.userInfo?.agencyInfo?.isDirector && (
                                <Link
                                    className='btn btn-sm btn-warning'
                                    to={`/agency/agent/${item.id}/update`}
                                >
                                    Редактировать
                                </Link>
                            )}
                        {me &&
                            (me.agencyInfo.isDirector ||
                                me.agencyInfo.permissions.includes(
                                    'agency.agent.invite',
                                )) &&
                            me.uid != item.agentUid && (
                                <ButtonWithConfirm
                                    label='Уволить'
                                    modalText='Точно?'
                                    onClick={handleFire}
                                    variant='danger'
                                />
                            )}
                    </ButtonGroup>
                </td>
            </tr>
        </React.Fragment>
    );
};

export default IList;
