import ru from 'date-fns/locale/ru';
import { useState, FC } from 'react';
import MainPopup from '../MainPopup';
import DatePicker from 'react-datepicker';
import { Button, FormGroup, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format, formatISO } from 'date-fns';

const ModalOneDateField: FC<TProps> = ({
    onClose,
    isOpened,
    text,
    primaryButtonText = 'Да',
    primaryButtonOnClick,
    secondaryButtonText = 'Отмена',
    secondaryButtonOnClick,
    defaultValue = null,
}) => {
    const [value, setValue] = useState(defaultValue);

    const handleClose = () => {
        setValue(null);
        onClose();
    };

    const handlePBOnClick = () => {
        primaryButtonOnClick(value);
        handleClose();
    };

    const handleSBOnClick = () => {
        secondaryButtonOnClick();
        handleClose();
    };

    console.log('ModalOneDateField');
    console.log(value);

    return (
        <MainPopup isOpened={isOpened} onClose={handleClose}>
            <p>{text}</p>
            <FormGroup className='mb-2'>
                <InputGroup className='flex-nowrap'>
                    <InputGroup.Text>
                        <FontAwesomeIcon icon={['fas', 'calendar-alt']} />
                    </InputGroup.Text>
                    <DatePicker
                        className='form-control'
                        locale={ru}
                        dateFormat='yyyy-MM-dd'
                        openToDate={
                            defaultValue ? new Date(defaultValue) : new Date()
                        }
                        name='dateFrom'
                        selected={value ? new Date(value) : null}
                        showYearDropdown
                        dropdownMode='select'
                        onChange={(e) => setValue(formatISO(e))}
                        autoComplete='off'
                    />
                </InputGroup>
            </FormGroup>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '1rem',
                }}
            >
                <Button
                    size='sm'
                    type='button'
                    variant='danger'
                    onClick={handleSBOnClick}
                >
                    <FontAwesomeIcon icon={['fas', 'ban']} />{' '}
                    {secondaryButtonText}
                </Button>
                <Button
                    size='sm'
                    type='button'
                    variant='success'
                    onClick={handlePBOnClick}
                    disabled={value == null}
                >
                    <FontAwesomeIcon icon={['fas', 'check']} />{' '}
                    {primaryButtonText}
                </Button>
            </div>
        </MainPopup>
    );
};

type TProps = {
    isOpened: boolean;
    text: string;
    onClose: () => void;
    primaryButtonText?: string;
    primaryButtonOnClick: (value: any) => void;
    secondaryButtonText?: string;
    secondaryButtonOnClick: () => void;
    defaultValue?: any;
};

export default ModalOneDateField;
