import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Container } from 'react-bootstrap';
import { Form as FinalForm } from 'react-final-form';
import InputField from '../../Common/Form/InputField';
import { useDispatch, useSelector } from 'react-redux';
import SimpleSelect from '../../Common/Form/SimpleSelect';
import ModalOneField from '../../Common/ModalUI/ModalOneField';
import OverlayingPopup from '../../Common/ModalUI/OverlayingPopup';
import { selectAgency, createGroup } from '~/src/store/agencySlice';
import {
    composeValidators,
    onlyLength,
    required,
} from '~/src/helpers/validators';

const ModalNewAgent = ({ text, isOpened, onSubmit, phone, onClose }) => {
    const dispatch = useDispatch();
    const { id: agencyId } = useParams<{ id: string }>();

    const [isOpenedGroupModal, setOpenedGroupModal] = useState(false);

    const handleCreateGroup = async (title: string) => {
        dispatch(createGroup(agencyId, title));
    };

    const { groups } = useSelector(selectAgency);

    return (
        <OverlayingPopup onClose={onClose} isOpened={isOpened}>
            <div className='main_popup' style={{ width: '800px' }}>
                <p>{text}</p>
                <FinalForm
                    onSubmit={onSubmit}
                    initialValues={{
                        phone: phone,
                    }}
                    mutators={{
                        setValue: ([field, value], state, { changeValue }) => {
                            changeValue(state, field, () => value);
                        },
                        setGroupIdValue: async (
                            [field, value]: [string, string],
                            state,
                            { changeValue },
                        ) => {
                            if (value === '-1') {
                                setOpenedGroupModal(true);
                                return;
                            }
                            changeValue(state, field, () => value);
                        },
                    }}
                    validate={(values) => {
                        const errors: any = {};

                        return errors;
                    }}
                    render={({ handleSubmit, submitting, valid, form }) => (
                        <form onSubmit={handleSubmit}>
                            <Container fluid>
                                <InputField label='Фамилия' name='lastname' />
                                <InputField
                                    label='Имя'
                                    name='firstname'
                                    validators={composeValidators(required)}
                                />
                                <InputField
                                    label='Отчество'
                                    name='middlename'
                                />
                                <InputField
                                    label='Телефон'
                                    name='phone'
                                    validators={composeValidators(
                                        required,
                                        onlyLength(10),
                                    )}
                                />
                                <SimpleSelect
                                    label='Группа'
                                    name='groupId'
                                    opts={[
                                        { id: 0, title: '' },
                                        { id: -1, title: 'Новая' },
                                    ]
                                        .concat(groups)
                                        .map((item) => {
                                            if (item?.id === 0) return { value: '', title: '' };
                                            return {
                                                value: `${item?.id}`,
                                                title: item?.title,
                                            };
                                        })}
                                    onChange={(e) =>
                                        form.mutators.setGroupIdValue(
                                            'groupId',
                                            e.target.value,
                                        )
                                    }
                                />
                                <ModalOneField
                                    text='Введите название'
                                    isOpened={isOpenedGroupModal}
                                    onClose={() => setOpenedGroupModal(false)}
                                    primaryButtonOnClick={(v) =>
                                        handleCreateGroup(v)
                                    }
                                    secondaryButtonOnClick={() =>
                                        setOpenedGroupModal(false)
                                    }
                                />
                                <SimpleSelect
                                    label='Должность'
                                    name='position'
                                    opts={[
                                        { value: '', title: '' },
                                        { value: 'agent', title: 'Агент' },
                                        {
                                            value: 'director',
                                            title: 'Директор',
                                        },
                                        {
                                            value: 'supervisor',
                                            title: 'Руководитель',
                                        },
                                        {
                                            value: 'administrator',
                                            title: 'Администратор',
                                        },
                                    ]}
                                    validators={required}
                                />
                                <Button
                                    size='sm'
                                    type='submit'
                                    variant='success'
                                    disabled={submitting || !valid}
                                >
                                    Отправить приглашение
                                </Button>
                            </Container>
                        </form>
                    )}
                />
            </div>
        </OverlayingPopup>
    );
};

export default ModalNewAgent;
