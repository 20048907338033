import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RSUBListPhotos from '../IList/RSUBListPhotos';
import { Button } from 'react-bootstrap';
import MainPopup from '../../../Common/ModalUI/MainPopup';
import RSUBCard from '../card/card';
import { FormattedMessage } from 'react-intl';
import {
    selectRSUBMap,
    deleteItem,
    clear,
    favorites,
    addSelectedItem,
    clearSelectedItemNull,
    favoriteSelectedItem,
} from '~/src/store/realtySuburban/displayMarkerMapSlice';

const DisplayMarkerMap = (): JSX.Element => {
    const dispatch = useDispatch();

    const { items, selectItem } = useSelector(selectRSUBMap);

    const [isOpenModalPopup, setIsOpenModalPopup] = useState(false);

    const SetIsCloseModalPopup = () => setIsOpenModalPopup(false);

    const handleFavoriteClick = () => {
        dispatch(favorites(selectItem.id));
        dispatch(favoriteSelectedItem(selectItem));
    };

    return (
        <div
            style={{
                display: items.length > 0 ? 'flex' : 'none',
                height: '100%',
                top: '0px',
                right: '0px',
                background: 'white',
                overflowY: 'scroll',
                padding: '3px',
                flexDirection: 'column',
                maxWidth: '210px',
            }}
        >
            <MainPopup
                title={'Карточка квартиры'}
                onClose={() => {
                    dispatch(clearSelectedItemNull());
                    // eslint-disable-next-line new-cap
                    SetIsCloseModalPopup();
                }}
                isOpened={isOpenModalPopup}
                fullwidth={true}
                overflow={true}
            >
                {selectItem && (
                    <RSUBCard
                        item={selectItem}
                        handleFavorite={handleFavoriteClick}
                    />
                )}
            </MainPopup>
            <Button
                className='mb-1'
                variant='warning'
                size='sm'
                onClick={() => dispatch(clear())}
            >
                Убрать все ({items.length})
            </Button>
            {items.map((item, id) => (
                <div
                    className='info_realty'
                    key={item.id}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: '10px',
                        borderBottom: '1px solid white',
                        justifyContent: 'center',
                        gap: '5px',
                    }}
                >
                    <div
                        style={{
                            position: 'relative',
                            display: 'flex',
                            gap: '10px',
                        }}
                    >
                        {item.photos.length > 0 ? (
                            <RSUBListPhotos photos={item.photos} />
                        ) : (
                            <div
                                style={{
                                    width: '100%',
                                    height: '40px',
                                    background: '#cccccc',
                                }}
                            ></div>
                        )}
                        {item.isFavorite ? (
                            <Button
                                style={{
                                    position: 'absolute',
                                    top: '2px',
                                    left: '2px',
                                }}
                                size='sm'
                                variant='primary'
                                title='Убрать из избранного'
                                onClick={() => dispatch(favorites(item.id))}
                            >
                                <FontAwesomeIcon icon={['fas', 'heart']} />
                            </Button>
                        ) : (
                            <Button
                                style={{
                                    position: 'absolute',
                                    top: '2px',
                                    left: '2px',
                                }}
                                size='sm'
                                variant='outline-primary'
                                title='Добавить в избранное'
                                onClick={() => dispatch(favorites(item.id))}
                            >
                                <FontAwesomeIcon icon={['fas', 'heart']} />
                            </Button>
                        )}
                        <span
                            style={{
                                position: 'absolute',
                                color: 'white',
                                top: '3px',
                                right: '3px',
                            }}
                            title='Убрать'
                            onClick={() => dispatch(deleteItem(id))}
                        >
                            <FontAwesomeIcon icon={['fas', 'times-circle']} />
                        </span>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div>
                            <FormattedMessage
                                id={`realty_suburban_type_${item?.type}`}
                            />
                        </div>
                        <div>
                            <FormattedMessage
                                id={`realty_suburban_landAppointment_${item?.landAppointment}`}
                            />
                        </div>
                        <div>
                            Цена:{' '}
                            {new Intl.NumberFormat('ru-RU').format(+item.price)}
                        </div>
                    </div>
                    <div>
                        {item.addressDetail.formattedAddress}
                        <br />
                        id: {item.id}
                        <br />
                        {item.area && (
                            <p>
                                <strong>Площадь участка: </strong>
                                {item.area}
                            </p>
                        )}
                        {item.houseSpaceTotal && (
                            <p>
                                <strong>Площадь дома: </strong>
                                {item.houseSpaceTotal}
                            </p>
                        )}
                    </div>
                    <button
                        onClick={() => {
                            dispatch(addSelectedItem(item));
                            setIsOpenModalPopup(true);
                        }}
                        style={{
                            border: 'none',
                            borderRadius: '3px',
                            backgroundColor: '#237cc9',
                            color: 'white',
                        }}
                    >
                        Карточка
                    </button>
                </div>
            ))}
        </div>
    );
};
export default DisplayMarkerMap;
