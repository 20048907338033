import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({
    data,
    title
}) => {

    const isEmpty = data.datasets.every(dataset => dataset.data.every(value => Number(value) == 0));

    if (isEmpty) {
        return <p>Нет данных для отображения</p>;
    }

    // Вывод значений если нужно будет
    // const options = {
    //     plugins: {
    //         datalabels: {
    //             color: 'white',
    //             display: true,
    //             formatter: (value: number) => value.toString(),
    //             font: {
    //                 weight: 'bold' as 'bold'
    //             },
    //         },
    //     },
    // };

    return (
        <div style={{ width: '400px', height: '400px' }}>
            <h2>{title}</h2>
            <Pie data={data} />
        </div>
    );
};

export default PieChart;
