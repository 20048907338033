import Api from '~/src/helpers/http-common';
import { IResponseListData, IUpdateData } from '../interfaces/common';

export const all = async (
    queryParams?: URLSearchParams,
): Promise<IResponseListData<IAgencyPermission>> => {
    return await Api.get(
        `/agency-permission?${queryParams != null ? queryParams.toString() : ''}`,
    );
};

export const create = async (
    data: IAgencyPermission,
): Promise<IAgencyPermission | any> => {
    return (await Api.post<IAgencyPermission>(
        '/agency-permission',
        data,
    )) as IAgencyPermission;
};

export const read = async (code: string): Promise<IAgencyPermission> => {
    return await Api.get(`/agency-permission/${code}`);
};

export const update = async (
    code: string,
    data: IAgencyPermission,
): Promise<IUpdateData<IAgencyPermission>> => {
    return await Api.put<IUpdateData<IAgencyPermission>>(
        `/agency-permission/${code}`,
        data,
    );
};

export const remove = async (id: string): Promise<any> => {
    return await Api.delete(`/agency-permission/${id}`);
};

export const unload = async (): Promise<IAgencyPermission[]> => {
    return await Api.get('/agency-permission/unload');
};

export const switchPermission = async (
    agentId: number,
    code: string,
): Promise<{ success: boolean }> => {
    return await Api.post(`/agency-agent/${agentId}/permission/${code}/switch`);
};

export interface IAgencyPermission {
    id: number;
    title: string;
    code: string;
    group: string;
}
