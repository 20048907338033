import React from 'react';
import { Navbar, ButtonGroup, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectAgencyGroup } from '~/src/store/agency.group.slice';
import AgencyGroupList from './IList';

const AgencyGroupView = () => {
    const { itemsUnload } = useSelector(selectAgencyGroup);

    return (
        <React.Fragment>
            <Navbar
                className='justify-content-between'
                bg='light'
                expand='lg'
                variant='light'
            >
                <ButtonGroup>
                    {/* {me &&
                        (me.agencyInfo.isDirector ||
                            me.agencyInfo.permissions.includes(
                                'agency.agent.invite',
                            )) && (
                            <React.Fragment>
                                <InviteAgent />
                                <InviteAgentByExcel />
                            </React.Fragment>
                        )} */}
                    {/* <Button
                        size='sm'
                        variant='secondary'
                        onClick={() => dispatch(all(id, query))}
                    >
                        <FontAwesomeIcon icon={['fas', 'sync']} /> Обновить
                    </Button> */}
                    <Link
                        className='btn btn-sm btn-primary'
                        title='Агенты'
                        to={'/agency/my/agent'}
                    >
                        Агенты
                    </Link>
                </ButtonGroup>
                {/* <ButtonGroup>
                    <>
                        <Form.Label>&nbsp;</Form.Label>
                        <InputGroup size='sm'>
                            <Form.Control
                                value={value}
                                onChange={filterItems}
                                autoComplete='off'
                            />
                        </InputGroup>
                    </>
                </ButtonGroup> */}
            </Navbar>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <td>Фото</td>
                        <td>Группа</td>
                        <td>Руководитель подразделения</td>
                        <td>Данные</td>
                        <td>Управление</td>
                    </tr>
                </thead>
                <tbody>
                    {itemsUnload && itemsUnload.length > 0
                        ? itemsUnload.map((item) => (
                            <AgencyGroupList key={item.id} item={item} />
                        ))
                        : null}
                </tbody>
            </Table>
        </React.Fragment>
    );
};

export default AgencyGroupView;
