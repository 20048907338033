import { useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import ru from 'date-fns/locale/ru';
import DatePicker from 'react-datepicker';
import dateFormat from 'dateformat';
import { formatISO } from 'date-fns';
import { useDispatch } from 'react-redux';

const BuyerChangeDatePicker = ({ search, setSearch, goTo }) => {
    const dispatch = useDispatch();

    // Стейт для отслеживания открытия окна с выбором дат занесеняи покупателей
    const [openBuyerChangeDate, setOpenBuerChangeDate] = useState({
        from: false,
        to: false,
    });
    // Логика закрытия и открытия окна с выборам даты занесения покупателя
    const handleOpenBuerChangeDate = (e) => {
        setOpenBuerChangeDate((prev) => {
            const newState = { ...prev };
            Object.keys(newState).forEach((key) => {
                if (key !== e.target.name) {
                    // Если ключ не равен имени текущего элемента, обнуляем его значение
                    newState[key] = false;
                } else {
                    // Если это наш текущий элемент то открываем или закрываем его в зависимости от начального стейта
                    newState[key] = !newState[e.target.name];
                }
            });
            return newState;
        });
    };

    const setBuerChangeDate = (date: Date, name: string) => {
        const dateQuery = formatISO(date);
        const updatedSearch = { ...search, [name]: dateQuery };
        dispatch(setSearch(updatedSearch));
        dispatch(goTo(updatedSearch));
    };

    return (
        <ButtonGroup className='d-flex gap-2'>
            <div>
                <Button
                    name='from'
                    variant='outline-primary'
                    onClick={handleOpenBuerChangeDate}
                >
                    {search.createdFrom
                        ? dateFormat(search.createdFrom, 'dd.mm.yyyy')
                        : 'От'}
                </Button>
                {openBuyerChangeDate.from && (
                    <div style={{ position: 'absolute', zIndex: '1000' }}>
                        <DatePicker
                            className='form-control'
                            locale={ru}
                            dateFormat='yyyy-MM-dd'
                            name='createdFrom'
                            selected={
                                search.createdFrom
                                    ? new Date(search.createdFrom)
                                    : null
                            }
                            placeholderText='От'
                            showYearDropdown
                            inline
                            dropdownMode='select'
                            onChange={(date) =>
                                setBuerChangeDate(date, 'createdFrom')
                            }
                            autoComplete='off'
                        />
                    </div>
                )}
            </div>
            <div>
                <Button
                    name='to'
                    variant='outline-primary'
                    onClick={handleOpenBuerChangeDate}
                >
                    {search.createdTo
                        ? dateFormat(search.createdTo, 'dd.mm.yyyy')
                        : 'До'}
                </Button>
                {openBuyerChangeDate.to && (
                    <div style={{ position: 'absolute', zIndex: '1000' }}>
                        <DatePicker
                            className='form-control'
                            locale={ru}
                            dateFormat='yyyy-MM-dd'
                            name='createdTo'
                            selected={
                                search.createdTo
                                    ? new Date(search.createdTo)
                                    : null
                            }
                            placeholderText='До'
                            showYearDropdown
                            inline
                            dropdownMode='select'
                            onChange={(date) =>
                                setBuerChangeDate(date, 'createdTo')
                            }
                            autoComplete='off'
                        />
                    </div>
                )}
            </div>
        </ButtonGroup>
    );
};

export default BuyerChangeDatePicker;
