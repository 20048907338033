// ['house', 'land', 'chalet'];

const realtySuburbanSubtypes = [
    {
        name: 'Земельный участок',
        value: 'land',
        variantOff: 'outline-primary',
        variantOn: 'primary',
    },
    {
        name: 'Дом / коттедж',
        value: 'house',
        variantOff: 'outline-primary',
        variantOn: 'primary',
    },
    {
        name: 'Дача',
        value: 'chalet',
        variantOff: 'outline-primary',
        variantOn: 'primary',
    },
];

export { realtySuburbanSubtypes };
