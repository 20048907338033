import { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const RSUBListPhotos: React.FC<RSListPhotosProps> = ({ photos }) => {
    const [isOpen, setOpen] = useState(false);
    const [index, setIndex] = useState(0);

    const base = 'https://files.citidom.com/';

    if (photos && photos.length > 0) {
        return (
            <div>
                <LazyLoadImage
                    width={200}
                    height={112}
                    style={{ height: 112 }}
                    src={`${base}${photos[0].name}`}
                    onClick={() => setOpen(true)}
                    effect='blur'
                />
                {isOpen && (
                    <Lightbox
                        mainSrc={`${base}${photos[index].name}`}
                        nextSrc={`${base}${photos[(index + 1) % photos.length].name}`}
                        prevSrc={`${base}${photos[(index + photos.length - 1) % photos.length].name}`}
                        onCloseRequest={() => {
                            setOpen(false);
                            setIndex(0);
                        }}
                        onMovePrevRequest={() =>
                            setIndex(
                                (index + photos.length - 1) % photos.length,
                            )
                        }
                        onMoveNextRequest={() =>
                            setIndex((index + 1) % photos.length)
                        }
                    />
                )}
            </div>
        );
    } else {
        return null;
    }
};

type RSListPhotosProps = {
    photos: {
        name: string;
        title: string;
    }[];
};

export default RSUBListPhotos;
