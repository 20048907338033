import React from 'react';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { selectComment } from '~/src/store/comment';
import Comment from './Comment';

export default function CommentView() {
    const { items } = useSelector(selectComment);

    return (
        <div style={{ maxHeight: '600px', overflowY: 'scroll' }}>
            {items && items.length > 0 ? (
                <Card>
                    <Card.Header>Комментарии</Card.Header>
                    <Card.Body>
                        {items.map((comment) => (
                            <Comment key={comment.id} comment={comment} />
                        ))}
                    </Card.Body>
                </Card>
            ) : null}
        </div>
    );
}
