import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import UpdatePhotosView from '../../Common/Filestore/Photos/UpdatePhotosView';

import {
    deleteFile,
    selectDHP,
    uploadPhoto,
} from '~/src/store/developer/developer.house.project';

type TProps = {
    id: number;
};

const UpdateDHPPhotoView: FC<TProps> = ({ id }) => {
    const dispatch = useDispatch();

    const { itemPhotos } = useSelector(selectDHP);

    const [preview, setPreview] = useState([]);

    const onUploadProgress = (event) => {
        const percentCompleted = Math.round((event.loaded * 100) / event.total);
        if (percentCompleted === 100) {
            setPreview(null);
        }
    };

    const setUploadFiles = (compressedFiles: File[] | FileList) => {
        dispatch(
            uploadPhoto({ files: compressedFiles, id: id, onUploadProgress, entryGroup: 'developer_house_project' }),
        );
    };

    return (
        <UpdatePhotosView
            filesFieldName='photosUpload'
            photos={itemPhotos}
            preview={preview}
            setPreview={setPreview}
            setUploadFiles={setUploadFiles}
            onDelete={(name) => dispatch(deleteFile(id, name, 'photos'))}
        />
    );
};

export { UpdateDHPPhotoView };
