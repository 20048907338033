import Api from '~/src/helpers/http-common';
import { IResponseListData, IUpdateData } from '../interfaces/common';

export const all = async (
    queryParams?: URLSearchParams,
): Promise<IResponseListData<IAddressPlaceDetail>> => {
    return await Api.get(
        `/address?${queryParams != null ? queryParams.toString() : ''}`,
    );
};

export const read = async (id: number): Promise<IAddressPlaceDetail> => {
    return await Api.get(`address/${id}`);
};

export const update = async (
    id: string | number,
    data: IAddressPlaceDetail,
): Promise<IUpdateData<IAddressPlaceDetail>> => {
    return await Api.put<IUpdateData<IAddressPlaceDetail>>(
        `/address/${id}`,
        data,
    );
};

export const autocomplete = async (q: string) => {
    return await Api.get('address/autocomplete', { params: { q } });
};

export const geocode = async (lat: number, lng: number) => {
    return await Api.get('address/geocode', { params: { lat, lon: lng } });
};

export interface IAddressPlaceDetail {
    id: number;
    fullAddress: string;
    formattedAddress: string;
    shortAddress: string;
    latitude: string;
    longitude: string;
    district?: string;
    livingArea?: string;
    placeId: string;
    isLocked: boolean;
}
