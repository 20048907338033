import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '~/src/helpers/hooks';
import { selectApp, setActiveMenuKey } from '~/src/store/appSlice';
import { all } from '~/src/store/agencyAgentsSlice';
import { useDispatch, useSelector } from 'react-redux';
import AgentsView from '~/src/components/Agency/Agent';
import { unload, selectPermission } from '~/src/store/agency.permission';
import { selectAgency, unloadGroups } from '~/src/store/agencySlice';

const AgencyAgentsPage = (): JSX.Element => {
    const query = useQuery();
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();

    const { loading } = useSelector(selectApp);
    const { groups } = useSelector(selectAgency);
    const { unloaded } = useSelector(selectPermission);

    useEffect(() => {
        dispatch(all(id, query));
    }, [dispatch, id, query.toString()]);

    useEffect(() => {
        if (unloaded === null) {
            dispatch(unload());
        }
    }, [dispatch, unloaded]);

    useEffect(() => {
        if (groups === null) {
            dispatch(unloadGroups(id));
        }
    }, [dispatch, id, groups]);

    useEffect(() => {
        dispatch(setActiveMenuKey('AgencyAgents'));
    }, [dispatch]);

    return (
        <React.Fragment>
            {loading ? <div>Загрузка...</div> : <AgentsView />}
        </React.Fragment>
    );
};

export default AgencyAgentsPage;
