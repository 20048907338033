import { useEffect } from 'react';
import { selectApp } from '~/src/store/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setGenerateData } from '~/src/store/cottage.village/cottage.village.store';
import { GenerateCottageVillagePhaseView } from '~/src/components/CottageVillage/Phases/Generate';

const GenerateCottageVillagePhasePage = (): JSX.Element => {
    const dispatch = useDispatch();
    const { loading } = useSelector(selectApp);

    useEffect(() => {
        () => dispatch(setGenerateData(null));
    }, [dispatch]);

    return loading ? <>Загрузка ...</> : <GenerateCottageVillagePhaseView />;
};

export { GenerateCottageVillagePhasePage };
