import { useDispatch } from 'react-redux';
import { FC, useEffect } from 'react';
import { getMe } from '~/src/store/authSlice';
import LoginView from '~/src/components/Login/index';

import './index.css';

const Login: FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getMe());
    }, [dispatch]);

    return <LoginView />;
};

export default Login;
