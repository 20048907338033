import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { Form as FinalForm } from 'react-final-form';
import { Button, ButtonGroup, Navbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import createInitialFormValues from '~/src/helpers/createInitialFormValues';
import { useSelector } from 'react-redux';
import { selectApp } from '~/src/store/appSlice';
import { getUserByPhone } from '~/src/service/user';

type TProps = {
    initial?: Record<string, any>;
    onSubmit: (values: Record<string, any>) => void;
};

const MainForm: React.FC<React.PropsWithChildren<TProps>> = ({
    children,
    onSubmit,
    initial,
}) => {
    const navigate = useNavigate();
    const [holdSubmit, setHoldSubmit] = useState(false);
    const findUserByPhone = async (phone: number) => {
        const _res = await getUserByPhone(phone);

        return _res;
    };

    const { loading } = useSelector(selectApp);

    if (loading) return <>Loading....</>;

    return (
        <FinalForm
            onSubmit={onSubmit}
            mutators={{
                // expect (field, value) args from the mutator
                setValue: ([field, value], state, { changeValue }) => {
                    changeValue(state, field, () => value);
                },
                setPriceValue: ([field, value], state, { changeValue }) => {
                    value = value.replaceAll(/\D/g, '');
                    changeValue(state, field, () =>
                        new Intl.NumberFormat('ru-RU').format(value),);
                },
                setDecimalValue: ([field, value], state, { changeValue }) => {
                    value = value.replaceAll(',', '.');
                    changeValue(state, field, () => value);
                },
                setSelectValue: ([field, value], state, { changeValue }) => {
                    changeValue(state, field, () => value.value);
                },
                setUserUidValue: async (
                    [field, value],
                    state,
                    { changeValue },
                ) => {
                    changeValue(state, field, () => value);
                    if (value.length == 10) {
                        setHoldSubmit(true);
                        const founded = await findUserByPhone(value);

                        if (founded && founded.success) {
                            changeValue(
                                state,
                                'userUid',
                                () => founded.data.uid,
                            );
                            changeValue(
                                state,
                                'userUidName',
                                () => founded.data.fullname,
                            );
                            // -- setDirectorName(founded.fullname);
                        }
                        setHoldSubmit(false);
                    } else {
                        changeValue(state, 'userUid', () => null);
                        changeValue(state, 'userUidName', () => '');
                    }
                },
            }}
            initialValues={{
                ...createInitialFormValues(initial),
                userUidName: `${initial?.owner?.lastname || ''} ${initial?.owner?.firstname || ''} ${initial?.owner?.middlename || ''}`,
                userUidPhone: initial?.owner?.phone || '',
                price: initial?.price && new Intl.NumberFormat('ru-RU').format(
                    +initial?.price,
                ),
                priceDiscount: initial?.priceDiscount && new Intl.NumberFormat('ru-RU').format(
                    +initial?.priceDiscount,
                ),
            }}
            validate={(values) => {
                const errors: any = {};
                if (values.developerId === undefined) {
                    errors.developerId = 'Укажите застройщика';
                }

                return errors;
            }}
            render={({ handleSubmit, submitting, valid }) => (
                <form onSubmit={handleSubmit}>
                    <Navbar
                        className='justify-content-between'
                        bg='light'
                        expand='lg'
                        variant='light'
                    >
                        <ButtonGroup>
                            <Button
                                size='sm'
                                onClick={() => navigate(-1)}
                                disabled={submitting}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'fast-backward']}
                                />{' '}
                                Назад
                            </Button>
                            <Button
                                size='sm'
                                type='submit'
                                className='btn btn-sm btn-success'
                                disabled={submitting || !valid}
                            >
                                <FontAwesomeIcon icon={['fas', 'save']} />{' '}
                                Сохранить
                            </Button>
                        </ButtonGroup>
                    </Navbar>
                    {children}
                </form>
            )}
        />
    );
};

export { MainForm };
