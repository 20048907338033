import { useState } from 'react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { selectApp } from '~/src/store/appSlice';
import { Form as FinalForm } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { selectAgencyGroup, update } from '~/src/store/agency.group.slice';

import createInitialFormValues from '~/src/helpers/createInitialFormValues';
import {
    Button,
    ButtonGroup,
    Col,
    Container,
    Navbar,
    Row,
    Table,
} from 'react-bootstrap';
import InputField from '../../Common/Form/InputField';

const UpdateGroupView = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { loading } = useSelector(selectApp);
    const { item } = useSelector(selectAgencyGroup);

    const [submittinG, setSubmitting] = useState(false);
    const [valiD, setValid] = useState(false);


    const onSubmit = (values) => {
        dispatch(update(item.id, {
            title: values.title
        }));
    };

    if (loading || item === null) {
        return <>Загрузка...</>;
    }

    let submit;

    return (
        <Container fluid>
            <Navbar
                className='justify-content-between'
                bg='light'
                expand='lg'
                variant='light'
            >
                <ButtonGroup>
                    <Button
                        size='sm'
                        onClick={() => navigate(-1)}
                        disabled={submittinG}
                    >
                        <FontAwesomeIcon icon={['fas', 'fast-backward']} />{' '}
                        Назад
                    </Button>
                    <Button
                        size='sm'
                        type='submit'
                        onClick={(event) => {
                            submit(event);
                        }}
                        className='btn btn-sm btn-success'
                        disabled={submittinG || !valiD}
                    >
                        <FontAwesomeIcon icon={['fas', 'save']} /> Сохранить
                    </Button>
                </ButtonGroup>
            </Navbar>
            <Row>
                <Col>
                    <FinalForm
                        onSubmit={onSubmit}
                        initialValues={{
                            ...createInitialFormValues(item),
                        }}
                        mutators={{
                            setValue: (
                                [field, value],
                                state,
                                { changeValue },
                            ) => {
                                changeValue(state, field, () => value);
                            },
                        }}
                        validate={() => {
                            const errors: any = {};
                            return errors;
                        }}
                        render={({ handleSubmit, form, submitting, valid }) => {
                            submit = handleSubmit;
                            setSubmitting(submitting);
                            setValid(valid);

                            return (
                                <form onSubmit={handleSubmit}>
                                    <InputField
                                        name='title'
                                        label='Название группы'
                                        placeholder='Введите название группы'
                                    />
                                </form>
                            );
                        }}
                    />
                </Col>
            </Row>
        </Container>
    );
};

export default UpdateGroupView;
