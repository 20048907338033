import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UpdateView from '~/src/components/Users/update';
import { setActiveMenuKey } from '~/src/store/appSlice';
import { getReviews, getRewards, getUser, selectUsersPA, unloadedSocial } from '~/src/store/paSlice';

const UpdatePage = (): JSX.Element => {
    const dispatch = useDispatch();

    const { item } = useSelector(selectUsersPA);

    useEffect(() => {
        dispatch(getUser());
        dispatch(getRewards());
        dispatch(getReviews());
        dispatch(unloadedSocial());
        dispatch(setActiveMenuKey('PA'));
    }, [dispatch]);

    if (!item) {
        <div>Загрузка...</div>;
    }

    return (
        <React.Fragment>
            <UpdateView />
        </React.Fragment>
    );
};

export default UpdatePage;
