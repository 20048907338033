import cianLogo from '~/src/assets/img/cian.png';
import avitoLogo from '~/src/assets/img/avito.png';
import yandexLogo from '~/src/assets/img/yandex.png';
import domclickLogo from '~/src/assets/img/domclick.png';
import React from 'react';
import { IXmlFeedRealty } from '~/src/service/xml.feed';

interface IProps {
    xmlfeedunload: IXmlFeedRealty[];
}

const InfoXmlFeed = ({ xmlfeedunload }: IProps) => {
    return (
        <React.Fragment>
            <br/>
            {xmlfeedunload.map((feed) => {
                if (feed.enabled) {
                    switch (feed.feed?.type) {
                        case 'avito':
                            return (
                                <p>
                                    <img
                                        src={avitoLogo}
                                        width={16}
                                        height={16}
                                        title={feed.feed.title}
                                    />
                                </p>
                            );
                        case 'cian':
                            return (
                                <p>
                                    <img
                                        src={cianLogo}
                                        width={16}
                                        height={16}
                                        title={feed.feed.title}
                                    />
                                </p>
                            );
                        case 'domclick':
                            return (
                                <p>
                                    <img
                                        src={domclickLogo}
                                        width={16}
                                        height={16}
                                        title={feed.feed.title}
                                    />
                                </p>
                            );
                        case 'yandex':
                            return (
                                <p>
                                    <img
                                        src={yandexLogo}
                                        width={16}
                                        height={16}
                                        title={feed.feed.title}
                                    />
                                </p>
                            );
                    }
                }
            })}
        </React.Fragment>
    );
};

export default InfoXmlFeed;
