import { useNavigate } from 'react-router-dom';
import { IBuyer } from '~/src/service/buyer';
import TrelloInfoCard from './TrelloInfoCard';

const TrelloCard = ({
    dragProvided,
    item,
}: {
    dragProvided: any;
    item: IBuyer;
}) => {
    const navigate = useNavigate();

    const handleCardClick = (cardId: string) => {
        navigate(`/buyer/update/${cardId}`);
    };

    return (
        <div
            className={`cardDate ${item?.thermometer}Card`}
            // className={`CardDate ${item?.thermometer}Card`}
            ref={dragProvided.innerRef}
            {...dragProvided.draggableProps}
            {...dragProvided.dragHandleProps}
            onClick={() => handleCardClick(item.id.toString())}
        >
            <TrelloInfoCard itemInfo={item} />
        </div>
    );
};

export default TrelloCard;
