import classNames from 'classnames';
import { useSelector } from 'react-redux';
import Navbar from 'react-bootstrap/Navbar';
import { selectInfo } from '~/src/store/info.agency.users.slice';

import { Link } from 'react-router-dom';
import { Badge } from 'react-bootstrap';

export default function NavBarInfo(): JSX.Element {
    const { active, unverifiedCount } = useSelector(selectInfo);

    return (
        <Navbar
            className='justify-content-md-start'
            bg='light'
            expand='lg'
            variant='light'
        >
            <Link
                to={'/info-agency'}
                className={classNames([
                    'btn btn-sm me-1',
                    active === 'agency'
                        ? 'btn-secondary'
                        : 'btn-outline-secondary',
                ])}
            >
                Агентства недвижимости
            </Link>
            <Link
                to={'/info-agents?limit=25&page=1'}
                className={classNames([
                    'btn btn-sm me-1',
                    active === 'agents' ? 'btn-info' : 'btn-outline-info',
                ])}
            >
                Агенты без агентства
            </Link>
            <Link
                to={'/info-users?limit=25&page=1'}
                className={classNames([
                    'btn btn-sm me-1',
                    active === 'users'
                        ? 'btn-secondary'
                        : 'btn-outline-secondary',
                ])}
            >
                Физические лица
            </Link>
            <Link
                to={'/info-unverified-agents?limit=25&page=1'}
                className={classNames([
                    'btn btn-sm me-1',
                    active === 'unverifiedAgents'
                        ? 'btn-secondary'
                        : 'btn-outline-secondary',
                ])}
            >
                Неподтвержденные{' '}
                {unverifiedCount > 0 && (
                    <Badge bg='danger'>{unverifiedCount}</Badge>
                )}
            </Link>
        </Navbar>
    );
}
