import classNames from 'classnames';
import { Form } from 'react-bootstrap';
import { Field } from 'react-final-form';

interface IProps {
    name: string;
    label: string;
    validators?: any;
    opts: { title: string; value: string }[];
    onChange?: (event: React.ChangeEvent | any) => void;
    initialValue?: string | number;
    [otherProp: string]: any;
}

export default function SimpleSelectField({
    name,
    label,
    validators,
    opts,
    onChange,
    initialValue,
}: IProps): JSX.Element {
    return (
        <Field
            name={name}
            validate={validators}
            parse={(value) => (value === '' ? '' : value)}
            initialValue={initialValue}
        >
            {({ input, meta }) => {
                return (
                    <Form.Group className='mb-3'>
                        <Form.Label>
                            <span>{label}</span>
                            <span
                                className={classNames({
                                    'text-info': true,
                                    'd-none': !meta.dirty,
                                })}
                            >
                                <small> изменено</small>
                            </span>
                        </Form.Label>
                        <Form.Select
                            {...input}
                            onChange={
                                typeof onChange === 'function'
                                    ? onChange
                                    : input.onChange
                            }
                            size='sm'
                            isInvalid={meta.error || meta.submitError}
                            placeholder={label}
                        >
                            {opts.map(({ title, value }) => (
                                <option key={value} value={`${value}`}>
                                    {title}
                                </option>
                            ))}
                        </Form.Select>
                        <Form.Control.Feedback type='invalid'>
                            {meta.error || meta.submitError}
                        </Form.Control.Feedback>
                    </Form.Group>
                );
            }}
        </Field>
    );
}
