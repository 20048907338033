import * as api from '~/src/service/agency.group';
import { createSlice, Slice } from '@reduxjs/toolkit';
import { setLoading as setAppLoading } from './appSlice';
import type { TAppDispatch, TState, TAnyAction } from '~/src/store';
import { IUnloadGroup } from '../service/agency.group';
import { IAgencyGroup } from '../service/agency';

export const sliceName = 'agencyGroup';

interface IAgencyGroupSlice {
    itemsUnload: IUnloadGroup[];
    item?: IUnloadGroup;
    search?: any;
    errors?: any;
    navLink: string;
}

const initialState = {
    itemsUnload: [],
    search: {
        sort: 'id_desc',
        limit: 25,
        page: 1,
    },
    navLink: '/agency/my/group?sort=id_desc&limit=25&page=1',
    baseLink: '/agency/my/group',
};

const agencyGroupSlice: Slice<IAgencyGroupSlice> = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        setUnloadItems: (state: IAgencyGroupSlice, action: TAnyAction<IAgencyGroup[]>) => {
            state.itemsUnload = action.payload;
        },
        updateItemInList: (state, action: TAnyAction<IAgencyGroup>) => {
            const index = state.itemsUnload.findIndex(item => item.id === action.payload.id);
            if (index !== -1) {
              state.itemsUnload[index] = { ...state.itemsUnload[index], ...action.payload };
            }
          },
        setItem: (state: IAgencyGroupSlice, action: TAnyAction<IAgencyGroup>) => {
            state.item = action.payload;
        },
        setErrors: (state: IAgencyGroupSlice, action: TAnyAction<any>) => {
            state.errors = action.payload;
        },
    },
});

const {
    setUnloadItems,
    setItem,
    setErrors,
    updateItemInList,
} = agencyGroupSlice.actions;

export const unloadAllGroup = () => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(setAppLoading(true));
        const groups = await api.unloadGroup();
        dispatch(setUnloadItems(groups));
        dispatch(setAppLoading(false));
    };
};

export const read = (id: number) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const data = await api.read(id);
            dispatch(setItem(data));
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const update = (id: number, data: IUnloadGroup) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const response = await api.update(id, data);
            if (response && response.success) {
                dispatch(setItem(response.data));
                dispatch(updateItemInList(response.data));
            } else {
                dispatch(setErrors(response.errors));
            }
        } catch (e) {
            dispatch(setErrors(e));
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const remove = (id: number) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const response = await api.remove(id);
            if (response && response.success) {
                const updatedItems = getState().agencyGroup.itemsUnload.filter(
                    (item: IUnloadGroup) => item.id !== id
                );
                dispatch(setUnloadItems(updatedItems));
            } else {
                dispatch(setErrors(response.errors));
            }
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};


export const selectAgencyGroup = (state: TState): IAgencyGroupSlice =>
    state.agencyGroup;

export default agencyGroupSlice.reducer;
