import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import TrelloView from '~/src/components/Buyer/Trello/TrelloView';
import { useQuery } from '~/src/helpers/hooks';
import { setActiveMenuKey } from '~/src/store/appSlice';
import { getBoard, getStatistics } from '~/src/store/buyer/buyer.list.slice';

const Page = (): JSX.Element => {
    const query = useQuery();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getBoard(query));
        dispatch(getStatistics(query));
    }, [dispatch, query]);

    useEffect(() => {
        dispatch(setActiveMenuKey('Buyer'));
    }, [dispatch]);

    return <TrelloView />;
};

export default Page;
