import queryString from 'query-string';
import * as api from '~/src/service/direction';
import { createSlice, Slice } from '@reduxjs/toolkit';
import { IResponseListData } from '../interfaces/common';
import { redirect, setLoading as setAppLoading } from './appSlice';
import type { TAppDispatch, TState, TAnyAction } from '~/src/store';
import { IDirection, IUnloadDirection } from '~/src/service/direction';

export const sliceName = 'direction';

interface IDirectionSlice {
    items: IResponseListData<IDirection>;
    item?: IDirection;
    unloadItems?: IUnloadDirection[];
    search?: any;
    errors?: any;
    navLink?: string;
    baseLink?: string;
}

const initialState = {
    items: {},
    unloadItems: [],
    search: {
        sort: 'id_desc',
        limit: 25,
        page: 1,
    },
    navLink: '',
    baseLink: '',
};

const DirectionSlice: Slice<IDirectionSlice> = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        setAll: (
            state: IDirectionSlice,
            action: TAnyAction<IResponseListData<IDirection>>,
        ) => {
            state.items = action.payload;
        },

        set: (state: IDirectionSlice, action: TAnyAction<IDirection>) => {
            state.item = action.payload;
        },

        setUnload: (
            state: IDirectionSlice,
            action: TAnyAction<IUnloadDirection[]>,
        ) => {
            state.unloadItems = action.payload;
        },
    },
});

const { setAll, set, setUnload } = DirectionSlice.actions;

export const unload = () => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        const response = await api.unload();
        dispatch(setUnload(response));
    };
};

export const selectDirection = (state: TState): IDirectionSlice =>
    state.direction;

export default DirectionSlice.reducer;
