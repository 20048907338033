import { AxiosRequestConfig } from 'axios';
import Api from '~/src/helpers/http-common';

export const unload = async (): Promise<IUnloadDirection> => {
    return await Api.get('/direction/unload');
};

export interface IDirection {
    id: number;
    title: string;
    code: string;
    createdAt: Date;
    createdBy: Date;
    updatedAt: Date;
    updatedBy: Date;
}

export interface IUnloadDirection {
    id: number;
    title: string;
    code: string;
}
