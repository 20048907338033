import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import View from '~/src/components/Content/update';
import { setActiveMenuKey } from '~/src/store/appSlice';
import { readByCode } from '~/src/store/content';

const AboutService: FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(readByCode('about-service'));
    }, [dispatch]);

    useEffect(() => {
        dispatch(setActiveMenuKey('AboutService'));
    }, [dispatch]);

    return <View />;
};

export default AboutService;
