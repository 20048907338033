import { IResponseListData, IUpdateData } from '../../interfaces/common';
import Api from '~/src/helpers/http-common';
import { IDeveloper } from './developer';
import { AxiosRequestConfig } from 'axios';
import { IFilestore } from '../filestore';
import { IUser } from '../user';

export const all = async (
    queryParams?: URLSearchParams,
): Promise<IResponseListData<IDeveloperHouseProject>> => {
    return await Api.get(
        `/developer/house-project/all?${queryParams != null ? queryParams.toString() : ''}`,
    );
};

export const create = async (
    data: IDeveloperHouseProject,
    files?: Record<string, FileList>,
): Promise<IDeveloperHouseProject | any> => {
    return await Api.post<IDeveloperHouseProject>(
        '/developer/house-project',
        data,
        files,
    );
};

export const read = async (id: number): Promise<IDeveloperHouseProject> => {
    return await Api.get(`/developer/house-project/${id}`);
};

export const update = async (
    id: string | number,
    data: IDeveloperHouseProject,
    files?: Record<string, FileList>,
): Promise<IUpdateData<IDeveloperHouseProject>> => {
    return await Api.put<IUpdateData<IDeveloperHouseProject>>(
        `/developer/house-project/${id}`,
        data,
        files,
    );
};

export const remove = async (id: string | number): Promise<any> => {
    return await Api.delete(`/developer/house-project/${id}`);
};

export const unload = async (
    queryParams?: URLSearchParams,
): Promise<Partial<any>> => {
    return await Api.get(
        `/developer/house-project/unload?${queryParams != null ? queryParams.toString() : ''}`,
    );
};

export const changeResponsible = async (
    data: Record<string, any>,
): Promise<{ success: boolean }> => {
    return await Api.post('/developer/house-project/change-responsible', data);
};

export const uploadPhotos = async (
    id: number,
    files: FileList | File[],
    entryGroup: string,
    config?: AxiosRequestConfig,
): Promise<IFilestore[]> => {
    return await Api.post(
        `/developer/house-project/upload-photo/${id}`,
        { entryGroup: entryGroup },
        { files },
        config,
    );
};

export const deleteFile = async (
    id: string | number,
    name: string,
): Promise<IFilestore | any> => {
    return await Api.delete<IFilestore>(
        `/developer/house-project/${id}/files/${name}`,
    );
};

export const actionHouseProjectCottageVillage = async (
    data: IHouseProjectCottageVilalgeActionDataParams,
): Promise<IHouseProjectCottageVilalgeAction> => {
    return await Api.post<IHouseProjectCottageVilalgeAction>('/cottage-village/action-house-project', data);
};

export interface IDeveloperHouseProject {
    id: number;
    developerId: number;
    title: string;
    spaceTotal: number;
    price: number;
    priceDiscount: number;
    garage: TDeveloperHouseProjectGarage;
    material: TDeveloperHouseProjectMaterial;
    cottageVillages: IcottageVillages[];
    storeys: number;
    rooms: number;
    basement: boolean;
    exclusiveForCv?: boolean;
    area?: number;

    owner?: Partial<IUser>

    description?: string;

    developer: IDeveloper;

    photos?: IFilestore[];
    realPhotos?: IFilestore[];
}

export type TDeveloperHouseProjectGarage = 'no' | 'in_house' | 'separately';

export type TDeveloperHouseProjectMaterial =
    | 'brick'
    | 'timber'
    | 'log'
    | 'gas_blocks'
    | 'metal'
    | 'foam_block'
    | 'sandwich_panel'
    | 'reinforced_concrete_panel'
    | 'experimental';

export interface IHouseProjectCottageVilalgeAction {
    success: boolean,
    data: IHouseProjectCottageVilalgeActionData,
    errors: string;
    message: string;
}

export interface IHouseProjectCottageVilalgeActionDataParams {
    cottageVillageId: number,
    developerHouseProjectId: number
}

interface IHouseProjectCottageVilalgeActionData {
    isEnabled: boolean,
    id: number,
    cottageVillageId: number,
    developerHouseProjectId: number
}

export interface IcottageVillages {
    id: number,
    title: string
}
