import { Form, Button } from 'react-bootstrap';
import { Form as FinalForm } from 'react-final-form';
import { useState } from 'react';
import InputField from '~/src/components/Common/Form/InputField';
import {
    composeValidators,
    required,
    isPhone,
    onlyLength,
} from '~/src/helpers/validators';
import { useDispatch } from 'react-redux';
import { forgotPassword, confirmCode } from '~/src/store/authSlice';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [step, setStep] = useState('oneStep');
    const [phoneError, setPhoneError] = useState(false);

    const forgotPasswordCode = async (value) => {
        try {
            const response = await dispatch(forgotPassword(value));
            response ? setStep('twoStep') : setPhoneError(true);
        } catch (error) {
            console.error(error);
        }
    };

    const onSubmit = async (values, form) => {
        try {
            const response = await dispatch(
                confirmCode(values.phone, values.confirmCode),
            );
            response
                ? navigate('/login')
                : (form.getState().errors.confirmCode = 'код введен не верно');
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className='container-fluid login-body'>
            <div className='d-flex justify-content-center'>
                <div className='login-form p-3'>
                    <FinalForm
                        onSubmit={onSubmit}
                        subscription={{
                            submitting: true,
                            pristine: true,
                            valid: true,
                            submitSucceeded: true,
                            values: true,
                        }}
                        mutators={{
                            setValue: (
                                [field, value],
                                state,
                                { changeValue },
                            ) => {
                                changeValue(state, field, () => value);
                            },
                            setValuePhone: async (
                                [field, value],
                                state,
                                { changeValue },
                            ) => {
                                changeValue(state, field, () => value);
                                setPhoneError(false);
                            },
                        }}
                        validate={(values) => {
                            const errors: any = {};
                            if (
                                values.phone &&
                                !/^\d{10}$/.test(values.phone)
                            ) {
                                errors.phone =
                                    'Телефонный номер должен содержать 10 цифр';
                            }
                            if (phoneError) {
                                errors.phone = 'Телефон введен не верно';
                            }
                            return errors;
                        }}
                        render={({ handleSubmit, submitting, valid, form }) => (
                            <form onSubmit={handleSubmit}>
                                <InputField
                                    label={'Введите телефон'}
                                    placeholder={'Телефон вводится без +7 / 8'}
                                    name='phone'
                                    validators={composeValidators(
                                        required,
                                        isPhone,
                                    )}
                                    onChange={(e) =>
                                        form.mutators.setValuePhone(
                                            'phone',
                                            e.target.value,
                                        )
                                    }
                                />
                                {step == 'twoStep' && (
                                    <Form.Group>
                                        <InputField
                                            label={'Введите код из смс'}
                                            name='confirmCode'
                                            validators={composeValidators(
                                                required,
                                                onlyLength(6),
                                            )}
                                        />
                                    </Form.Group>
                                )}
                                <div className='d-grid'>
                                    {step !== 'oneStep' ? (
                                        <Button
                                            type='submit'
                                            variant='primary'
                                            disabled={!valid || submitting}
                                        >
                                            Восстановить пароль
                                        </Button>
                                    ) : (
                                        <Button
                                            type='button'
                                            onClick={() =>
                                                forgotPasswordCode(
                                                    form.getState().values
                                                        .phone,
                                                )
                                            }
                                            variant='primary'
                                            disabled={!valid || submitting}
                                        >
                                            Получить код
                                        </Button>
                                    )}
                                </div>
                            </form>
                        )}
                    />
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
