import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addItem, selectImageMarket } from '~/src/store/realtySecondary/displayMarkerSlice';
import { selectRS } from '~/src/store/realtySecondary/realtySecondarySlice';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { Button, ButtonGroup, Navbar } from 'react-bootstrap';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DisplayAppMarker from '~/src/components/Realty/Secondary/DisplayAppMarker';
import redMarker from '~/src/assets/marker/redMarker.png';
import blueMarker from '~/src/assets/marker/blueMarker.png';
import L, { marker } from 'leaflet';


export default function BigMap(): JSX.Element {
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { mapItems: markers } = useSelector(selectRS);
    const { items, selectItem } = useSelector(selectImageMarket);


    const blueIcon = new L.Icon({
        iconUrl: blueMarker,
        iconSize: [40, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
    });

    const redIcon = new L.Icon({
        iconUrl: redMarker,
        iconSize: [40, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
    });

    return (
        <React.Fragment>
            <Navbar
                className='justify-content-between'
                bg='light'
                expand='lg'
                variant='light'
            >
                <ButtonGroup>
                    <Button size='sm' onClick={() => navigate(-1)}>
                        <FontAwesomeIcon icon={['fas', 'fast-backward']} />{' '}
                        Назад
                    </Button>
                </ButtonGroup>
            </Navbar>
            <div
                className='rs-map-wrapper'
                style={{ height: '90vh', display: 'flex' }}
            >
                <MapContainer
                    style={{ height: '100%', width: '100%' }}
                    center={[57.14941, 65.550389]}
                    zoom={13}
                    maxZoom={18}
                    attributionControl={false}
                >
                    <TileLayer
                        url='http://{s}.maps.2gis.com/tiles?x={x}&y={y}&z={z}'
                        subdomains={['tile0', 'tile1', 'tile2', 'tile3']}
                    />
                    {markers && (
                        <MarkerClusterGroup>
                            {markers.map((item) => (
                                <Marker
                                    key={item?.id}
                                    position={[item?.latitude, item?.longitude]}
                                    title={item?.title}
                                    icon={items.some(_item => _item?.id === item?.id) ? redIcon : blueIcon}
                                    eventHandlers={{
                                        click: () => {
                                            dispatch(addItem(item?.id));
                                        },
                                    }}
                                />
                            ))}
                        </MarkerClusterGroup>
                    )}
                </MapContainer>
                <DisplayAppMarker />
            </div>
        </React.Fragment>
    );
}
