import React, { BaseSyntheticEvent, useEffect, useState } from 'react';

import Lightbox from 'react-image-lightbox';
import FileField from '../../Form/FileField';
import { Form, Image } from 'react-bootstrap';
import PhotoGallary from './SortablePhotosView';
import { arrayMoveImmutable } from 'array-move';
import { FILES_BASE } from '~/src/constants/urls';
import { sort, IFilestore } from '~/src/service/filestore';
import { compressImages } from '~/src/helpers/compressImage';
import { useDispatch } from 'react-redux';

type TProps = {
    photos: IFilestore[];
    setUploadFiles: (files: FileList | File[]) => void;
    setPreview: (files: TCompressedFile[]) => void;
    preview: TCompressedFile[];
    filesFieldName: string;
    onDelete: (filename: string) => void;
    currentIndex?: number;
    setCurrentIndex?: any;
};

type TCompressedFile = {
    name: string;
    url: string;
    progress: number;
};

export default function UpdatePhotosView({
    photos,
    setUploadFiles,
    preview,
    setPreview,
    filesFieldName,
    onDelete,
    currentIndex,
    setCurrentIndex,
}: TProps): JSX.Element {
    const [isOpen, setOpen] = useState(false);
    const [sortedPhotos, setPhotos] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        setPhotos(photos);
    }, [photos]);

    const handlePhotoClick = () => {
        setOpen(true);
    };

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const sorted: IFilestore[] = arrayMoveImmutable(
            sortedPhotos,
            oldIndex,
            newIndex,
        );
        setPhotos(sorted);
        sort(sorted.map((i) => i.name));
    };

    const changeHandler = async (e: BaseSyntheticEvent) => {
        const compressedFiles = await compressImages(e.target.files, {
            quality: 0.8,
            // maxWidth: 1200,
        });

        const ff = [];
        for (const compressed of compressedFiles) {
            ff.push({
                name: compressed.name,
                url: URL.createObjectURL(compressed),
                progress: 0,
            });
        }

        setPreview(ff);
        setUploadFiles(compressedFiles);
    };

    return (
        <React.Fragment>
            {sortedPhotos && sortedPhotos.length > 0 && (
                <div className='clearfix'>
                    <PhotoGallary
                        photos={sortedPhotos}
                        onSortEnd={onSortEnd}
                        pressDelay={200}
                        onClick={handlePhotoClick}
                        onDelete={onDelete}
                        setCurrentIndex={setCurrentIndex}
                        axis={'xy'}
                    />
                    {isOpen && (
                        <Lightbox
                            mainSrc={`${FILES_BASE}${sortedPhotos[currentIndex].name}`}
                            nextSrc={`${FILES_BASE}${sortedPhotos[(currentIndex + 1) % sortedPhotos.length].name}`}
                            prevSrc={`${FILES_BASE}${sortedPhotos[(currentIndex + sortedPhotos.length - 1) % sortedPhotos.length].name}`}
                            onCloseRequest={() => {
                                setOpen(false);
                                dispatch(setCurrentIndex(null));
                            }}
                            onMovePrevRequest={() =>
                                dispatch(
                                    setCurrentIndex(
                                        (currentIndex +
                                            sortedPhotos.length -
                                            1) %
                                            sortedPhotos.length,
                                    ),
                                )
                            }
                            onMoveNextRequest={() =>
                                dispatch(
                                    setCurrentIndex(
                                        (currentIndex + 1) %
                                            sortedPhotos.length,
                                    ),
                                )
                            }
                        />
                    )}
                </div>
            )}
            <Form.Group className='mb-3'>
                <label className='btn btn-sm btn-primary'>
                    <FileField
                        name={filesFieldName}
                        onChange={(e) => changeHandler(e)}
                        multiple
                    />{' '}
                    Загрузить фото
                </label>
                {preview && preview.length > 0 && (
                    <div>
                        {preview.map((image, index) => {
                            return (
                                <Image
                                    key={`imagePreview${index}`}
                                    src={image.url}
                                    width={160}
                                    height={90}
                                    thumbnail
                                />
                            );
                        })}
                    </div>
                )}
            </Form.Group>
        </React.Fragment>
    );
}
