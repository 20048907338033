import queryString from 'query-string';
import { redirect, setLoading as setAppLoading } from '../appSlice';
import { createSlice, Slice } from '@reduxjs/toolkit';
import { IResponseListData } from '../../interfaces/common';
import * as api from '~/src/service/realty.suburban';
import type { TAppDispatch, TState, TAnyAction } from '~/src/store';
import { IRSUBModel, IRSUBModelSearch } from './realtySuburbanSlice';

export const sliceName = 'realtySuburbanModerate';

interface IRSMSlice {
    items: IResponseListData<IRSUBModel>;
    item?: IRSUBModel;
    search?: IRSUBModelSearch;
    errors?: any;
    loading: boolean;
    navLink: string;
    baseLink: string;
}

const initialState = {
    items: {},
    search: {
        limit: 25,
        page: 1,
        isVerified: 0,
        isFullfied: 1,
    },
    loading: true,
    navLink:
        '/realty-suburban-moderate?limit=25&page=1&verified=0&isFullfied=1',
    baseLink: '/realty-suburban-moderate',
    errors: {},
};

const RSMSlice: Slice<IRSMSlice> = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        setAll: (
            state: IRSMSlice,
            action: TAnyAction<IResponseListData<IRSUBModel>>,
        ) => {
            state.items = action.payload;
        },
        set: (state: IRSMSlice, action: TAnyAction<IRSUBModel>) => {
            state.item = action.payload;
        },
        setErrors: (state: IRSMSlice, action: TAnyAction<any>) => {
            state.errors = action.payload;
        },
        setSearch: (state: IRSMSlice, action: TAnyAction<IRSUBModelSearch>) => {
            state.search = action.payload;
        },
        setNavLink: (state: IRSMSlice, action: TAnyAction<string>) => {
            state.navLink = action.payload;
        },
        setLoading: (state: IRSMSlice, action: TAnyAction<boolean>) => {
            state.loading = action.payload;
        },
    },
});

const { setAll, set, setSearch, setNavLink, setErrors } = RSMSlice.actions;

export const goTo =
    (params: Record<string, any>) =>
    (dispatch: TAppDispatch, getState: () => TState): void => {
        if (params.reset === true) {
            params = initialState.search;
        } else params = { ...getState().realtySuburbanModerate.search, ...params };
        dispatch(
            redirect(
                `${getState().realtySuburbanModerate.baseLink}?${queryString.stringify(params, { skipNull: true })}`,
            ),
        );
    };

export const all = (queryParams: URLSearchParams = null) => {
    return async (dispatch: TAppDispatch): Promise<any> => {
        dispatch(setAppLoading(true));
        const items = await api.all(queryParams);
        const obj = {};

        for (const key of queryParams.keys()) {
            if (queryParams.getAll(key).length > 1) {
                obj[key] = queryParams.getAll(key);
            } else {
                obj[key] = queryParams.get(key);
            }
        }

        dispatch(setSearch(obj));
        dispatch(
            setNavLink(`/realty-suburban-moderate?${queryParams.toString()}`),
        );
        dispatch(setAll(items));
        dispatch(setAppLoading(false));

        return items;
    };
};

export const read = (id: number) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(setAppLoading(true));
        const item = await api.read(id);
        dispatch(set(item));
        dispatch(setAppLoading(false));
    };
};

export const verify = (id: number) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        dispatch(setAppLoading(true));
        const response = await api.verify(id);
        if (response && response.success) {
            dispatch(
                setAll({
                    ...getState().realtySuburbanModerate.items,
                    items: getState().realtySuburbanModerate.items.items.filter(
                        (item: IRSUBModel) => item.id != id,
                    ),
                }),
            );
        } else {
            dispatch(setErrors(response.errors));
        }
        dispatch(setAppLoading(false));
    };
};

export const unverify = (id: number, data: any) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        dispatch(setAppLoading(true));
        const response = await api.unverify(id, data);
        if (response && response.success) {
            dispatch(
                setAll({
                    ...getState().realtySuburbanModerate.items,
                    items: getState().realtySuburbanModerate.items.items.filter(
                        (item: IRSUBModel) => item.id != id,
                    ),
                }),
            );
        } else {
            dispatch(setErrors(response.errors));
        }
        dispatch(setAppLoading(false));
    };
};

export const selectRSUBM = (state: TState): IRSMSlice =>
    state.realtySuburbanModerate;

export default RSMSlice.reducer;
