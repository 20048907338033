import { Field } from 'react-final-form';
import type { InputHTMLAttributes } from 'react';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
}

const FileField = ({ name, ...props }: Props): JSX.Element => (
    <Field<FileList> name={name}>
        {({ input: { value, onChange, ...input } }) => (
            <input
                {...input}
                type='file'
                onChange={({ target }) => onChange(target.files)}
                {...props}
            />
        )}
    </Field>
);

export default FileField;
