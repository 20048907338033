import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { all } from '~/src/store/permission';
import { useQuery } from '~/src/helpers/hooks';
import ListView from '~/src/components/Permission';
import {
    setActiveMenuKey,
    setHandleScrollPosition,
} from '~/src/store/appSlice';

const HousingEstatePage = (): JSX.Element => {
    const query = useQuery();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(all(query));
    }, [dispatch, query]);

    useEffect(() => {
        dispatch(setActiveMenuKey('PRM'));
    }, [dispatch]);

    useEffect(() => {
        dispatch(setHandleScrollPosition(false));
    }, [dispatch]);

    return <ListView />;
};

export default HousingEstatePage;
