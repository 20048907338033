export const AGENCY_UPDATE = 'agency.update';
export const REALTY_SECONDARY_GROUP_UPDATE = 'realty.secondary.group.update';
export const REALTY_SECONDARY_AGENCY_UPDATE = 'realty.secondary.agency.update';

export const REALTY_SECONDARY_AGENCY_TRANSFER =
    'realty.secondary.agency.transfer';
export const REALTY_SECONDARY_GROUP_TRANSFER =
    'realty.secondary.group.transfer';

export const REALTY_SUBURBAN_GROUP_UPDATE = 'realty.suburban.group.update';
export const REALTY_SUBURBAN_AGENCY_UPDATE = 'realty.suburban.agency.update';

export const XML_FEED_AGENCY_DELETE = 'xml.feed.delete';
export const XML_FEED_REALTY_ADD = 'xml.feed.realty.add';
export const XML_FEED_AGENCY_UPDATE = 'xml.feed.update';

export const AGENCY_SETTINGS_UPDATE = 'agency.setting.update';
export const AGENCY_STATISTICS = 'agency.statistics';

export const BUYER_GROUP_UPDATE = 'buyer.group.update';
export const BUYER_AGENCY_UPDATE = 'buyer.agency.update';
export const BUYER_AGENCY_ALL = 'agency.permission.buyer.all';
