import React, { FC } from 'react';
import dateFormat from 'dateformat';
import { IUser } from '~/src/service/user';
import { FormattedMessage } from 'react-intl';
import { ButtonGroup } from 'react-bootstrap';
import ButtonWithConfirm from '../Common/ModalUI/ButtonWithConfirm';
import { verifyRole, remove } from '~/src/store/info.agency.users.slice';
import { useDispatch } from 'react-redux';
import HaveAccess from '../Common/HaveAccess';

const UnverifiedRowView: FC<{ item: IUser }> = ({ item }) => {
    const dispatch = useDispatch();

    const handleBlock = () => {
        console.log('handleBlock');
    };

    const handleRemove = () => {
        console.log('handleRemove');
        dispatch(remove(item.id));
    };

    const handleVerify = () => {
        console.log('handleVerify');

        dispatch(verifyRole(item.id));
    };

    return (
        <React.Fragment>
            <tr>
                <td>{item.id}</td>
                <td>
                    <span>{item.fullname}</span>
                    <br />
                    <span>
                        <FormattedMessage id={`agency_${item.role}`} />
                    </span>
                    <br />
                </td>
                <td>
                    {item.role === 'agent' && (
                        <>
                            <span>Название АН (ИНН): {item.inn}</span>
                            <br />
                        </>
                    )}
                    <span>
                        Регистрация в системе:{' '}
                        {dateFormat(item.createdAt, 'dd.mm.yyyy')}
                    </span>
                    <br />
                    <span>Контактный телефон: {item.phone}</span>
                    <br />
                    <span>Email: {item.email}</span>
                    <br />
                </td>
                <td>
                    <ButtonGroup vertical className='w-100'>
                        <HaveAccess access={'users.verify'}>
                            <ButtonWithConfirm
                                variant='info'
                                size='sm'
                                onClick={handleVerify}
                                modalText={'Точно подтвердить?'}
                            >
                                Подтвердить{' '}
                                {item.role === 'agent'
                                    ? 'как агента'
                                    : 'как физика'}
                            </ButtonWithConfirm>
                        </HaveAccess>
                        <HaveAccess access={'users.block'}>
                            <ButtonWithConfirm
                                variant='secondary'
                                size='sm'
                                onClick={handleBlock}
                                modalText={'Точно заблокировать?'}
                            >
                                Заблокировать учетку
                            </ButtonWithConfirm>
                        </HaveAccess>
                        <HaveAccess access={'users.delete'}>
                            <ButtonWithConfirm
                                variant='danger'
                                size='sm'
                                onClick={handleRemove}
                                modalText={'Точно удалить?'}
                            >
                                Удалить учетку
                            </ButtonWithConfirm>
                        </HaveAccess>
                    </ButtonGroup>
                </td>
            </tr>
        </React.Fragment>
    );
};

export default UnverifiedRowView;
