import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '~/src/helpers/hooks';
import AgencyListView from '~/src/components/Agency';
import { all } from '~/src/store/agencySlice';
import {
    setActiveMenuKey,
    setHandleScrollPosition,
} from '~/src/store/appSlice';

const AgencyPage = (): JSX.Element => {
    const query = useQuery();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setActiveMenuKey('AGN'));
    }, [dispatch]);

    useEffect(() => {
        dispatch(all(query));
    }, [dispatch, query]);

    useEffect(() => {
        dispatch(setHandleScrollPosition(true));
    }, [dispatch]);

    return <AgencyListView />;
};

export default AgencyPage;
