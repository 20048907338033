import React, { BaseSyntheticEvent, useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import PhotoGallary from './SUBPhotoViewPhotos';
import { arrayMoveImmutable } from 'array-move';
import FileField from '../../../../Common/Form/FileField';
import { FILES_BASE } from '~/src/constants/urls';
import RSPhotoUploadRules from './SUBPhotoUploadRules';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Image, ProgressBar } from 'react-bootstrap';
import { sort, IFilestore } from '~/src/service/filestore';
import { compressImages } from '~/src/helpers/compressImage';
import {
    uploadPhoto,
    selectSUB,
    setCurrenIndex,
} from '~/src/store/realtySuburban/realtySuburbanSlice';

export default function UpdatePhotosView(): JSX.Element {
    const dispatch = useDispatch();

    const [isOpen, setOpen] = useState(false);
    const [sortedPhotos, setPhotos] = useState(null);
    const [progress, setProgress] = useState(0);
    const [preview, setPreview] = useState([]);

    const { itemPhotos, item, currentIndex } = useSelector(selectSUB);

    useEffect(() => {
        setPhotos(itemPhotos);
    }, [itemPhotos]);

    const handlePhotoClick = () => {
        setOpen(true);
    };

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const sorted: IFilestore[] = arrayMoveImmutable(
            sortedPhotos,
            oldIndex,
            newIndex,
        );
        setPhotos(sorted);
        sort(sorted.map((i) => i.name));
    };

    const onUploadProgress = (event) => {
        const percentCompleted = Math.round((event.loaded * 100) / event.total);
        setProgress(percentCompleted);
        if (percentCompleted === 100) {
            setPreview(null);
        }
    };

    const changeHandler = async (e: BaseSyntheticEvent) => {
        const compressedFiles = await compressImages(e.target.files, {
            quality: 0.6,
            maxWidth: 1200,
        });

        const ff = [];
        for (const compressed of compressedFiles) {
            ff.push({
                name: compressed.name,
                url: URL.createObjectURL(compressed),
                progress: 0,
            });
        }

        setPreview(ff);
        dispatch(
            uploadPhoto({
                files: compressedFiles,
                id: item.id,
                onUploadProgress,
            }),
        );
    };

    return (
        <React.Fragment>
            {sortedPhotos && sortedPhotos.length > 0 && (
                <div className='clearfix'>
                    <PhotoGallary
                        photos={sortedPhotos}
                        onSortEnd={onSortEnd}
                        pressDelay={200}
                        onClick={handlePhotoClick}
                        axis={'xy'}
                    />
                    {isOpen && (
                        <Lightbox
                            mainSrc={`${FILES_BASE}${sortedPhotos[currentIndex]?.name}`}
                            nextSrc={`${FILES_BASE}${sortedPhotos[(currentIndex + 1) % sortedPhotos.length]?.name}`}
                            prevSrc={`${FILES_BASE}${sortedPhotos[(currentIndex + sortedPhotos.length - 1) % sortedPhotos.length]?.name}`}
                            onCloseRequest={() => {
                                setOpen(false);
                                dispatch(setCurrenIndex(null));
                            }}
                            onMovePrevRequest={() =>
                                dispatch(
                                    setCurrenIndex(
                                        (currentIndex +
                                            sortedPhotos.length -
                                            1) %
                                            sortedPhotos.length,
                                    ),
                                )
                            }
                            onMoveNextRequest={() =>
                                dispatch(
                                    setCurrenIndex(
                                        (currentIndex + 1) %
                                            sortedPhotos.length,
                                    ),
                                )
                            }
                        />
                    )}
                </div>
            )}
            <Form.Group className='mb-3 d-flex gap-3 mt-3'>
                <label className='btn btn-sm btn-primary'>
                    <FileField
                        name='photosUpload'
                        onChange={(e) => changeHandler(e)}
                        multiple
                    />{' '}
                    Загрузить фото
                </label>
                <RSPhotoUploadRules />
            </Form.Group>
            <div className='mt-2 clearfix'>
                {preview &&
                    preview.length > 0 &&
                    preview.map((image, index) => {
                        return (
                            <div
                                key={`imagePreview${index}`}
                                className='float-start'
                            >
                                <Image
                                    src={image.url}
                                    width={160}
                                    height={90}
                                    thumbnail
                                />
                            </div>
                        );
                    })}
            </div>
            {progress > 0 && progress < 100 && (
                <>
                    <p>
                        Фотографии загрузятся в фоне, кнопку сохранить нажимать
                        для этого не нужно
                    </p>
                    <ProgressBar
                        now={progress}
                        label={`Загрузка завершена на ${progress}%`}
                    />
                </>
            )}
        </React.Fragment>
    );
}
