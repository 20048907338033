import L from 'leaflet';
import simpleBlueIcon from '~/src/assets/marker/icons-blue-filled-location-50.png';

const iconSimpleBlue = new L.Icon({
    iconUrl: simpleBlueIcon,
    iconRetinaUrl: simpleBlueIcon,
    iconAnchor: [20, 32],
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [32, 32],
    // className: 'leaflet-div-icon'
});

export default iconSimpleBlue;
