import { realtyTypes } from './data/realtyType';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { BaseSyntheticEvent } from 'react';
import { useDebouncedCallback } from 'use-debounce';

const RealtyTypeBtn = ({ search, setSearch, goTo }) => {
    const dispatch = useDispatch();

    const debounced = useDebouncedCallback(() => {
        dispatch(goTo(search));
    }, 2000);

    const handleChangeMultiCheckboxValue = (e: BaseSyntheticEvent) => {
        if (search[e.target.name] === undefined) {
            dispatch(setSearch({ ...search, [e.target.name]: e.target.value }));
            debounced();
        }
        if (Array.isArray(search[e.target.name])) {
            if (search[e.target.name]?.includes(e.target.value)) {
                dispatch(
                    setSearch({
                        ...search,
                        [e.target.name]: Array.from(
                            search[e.target.name],
                        ).filter((v: string) => v !== e.target.value),
                    }),
                );
                debounced();
            } else {
                dispatch(
                    setSearch({
                        ...search,
                        [e.target.name]: [
                            ...search[e.target.name],
                            e.target.value,
                        ],
                    }),
                );
                debounced();
            }
            return;
        }
        if (search[e.target.name] === e.target.value) {
            dispatch(setSearch({ ...search, [e.target.name]: [] }));
            debounced();
        } else {
            dispatch(
                setSearch({
                    ...search,
                    [e.target.name]: [search[e.target.name], e.target.value],
                }),
            );
            debounced();
        }
    };
    return (
        <ButtonGroup>
            {realtyTypes.map((radio, idx) => (
                <ToggleButton
                    key={idx}
                    size='sm'
                    id={`${radio.code}-${idx}`}
                    type='checkbox'
                    name='realtyType'
                    // radios.some(radio => search?.realtyType?.includes(radio.code));
                    variant={
                        search?.realtyType?.includes(radio.code)
                            ? 'primary'
                            : 'outline-primary'
                    }
                    value={radio.code}
                    checked={search?.realtyType == radio.code}
                    onChange={handleChangeMultiCheckboxValue}
                >
                    {radio.title}
                </ToggleButton>
            ))}
        </ButtonGroup>
    );
};

export default RealtyTypeBtn;
