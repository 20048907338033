import { selectRS } from '~/src/store/realtySecondary/realtySecondarySlice';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Dashboard = (): JSX.Element => {
    const { navLink } = useSelector(selectRS);

    return <Navigate to={navLink} replace />;
};

export default Dashboard;
