import { useEffect } from 'react';
import { useQuery } from '~/src/helpers/hooks';
import { useDispatch, useSelector } from 'react-redux';
import CottageVillageListView from '~/src/components/CottageVillage';
import { all } from '~/src/store/cottage.village/cottage.village.store';
import { setActiveMenuKey } from '~/src/store/appSlice';
import { selectDeveloper, unloadSuburban } from '~/src/store/developer/developerSlice';
import {
    selectChangeResponsibleOnCV,
    unloadAll,
} from '~/src/store/cottage.village/cottage.village.changeResponsible';

const CottageVillagePage = (): JSX.Element => {
    const query = useQuery();
    const dispatch = useDispatch();
    const { unloadedSuburban } = useSelector(selectDeveloper);
    const { allCottageVillage } = useSelector(selectChangeResponsibleOnCV);

    useEffect(() => {
        dispatch(all(query));
    }, [dispatch, query]);

    useEffect(() => {
        dispatch(setActiveMenuKey('CV'));
    }, [dispatch]);

    useEffect(() => {
        if (unloadedSuburban === null) {
            dispatch(unloadSuburban());
        }
    }, [dispatch, unloadedSuburban]);

    useEffect(() => {
        if (allCottageVillage === null) {
            dispatch(unloadAll());
        }
    }, [dispatch, allCottageVillage]);

    return <CottageVillageListView />;
};

export default CottageVillagePage;
