import React from 'react';
import ReactDOM from 'react-dom';
import { router } from '~/src/router';
import { IntlProvider } from 'react-intl';
import reportWebVitals from './reportWebVitals';
import { LOCALES } from '~/src/i18n/locales';
import { messages } from '~/src/i18n/messages';
import { Provider } from 'react-redux';
import store from '~/src/store';

import './index.css';
import '~/src/components/App/style/reset.css';
import '~/src/components/App/style/framework.css';
import '~/src/components/App/style/main.css';
import '~/src/components/App/style/index.css';
import '~/src/components/App/style/bootstrap.min.css';
import 'react-image-lightbox/style.css';
import 'react-notifications/lib/notifications.css';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'draft-js/dist/Draft.css';

import L from 'leaflet';

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { RouterProvider } from 'react-router-dom';

import MyLoadingOverlay from './components/Common/MyLoadingOverlay';
import { NotificationContainer } from 'react-notifications';

const DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

const locale = LOCALES.RUSSIAN;

ReactDOM.render(
    <React.Fragment>
        <Provider store={store}>
            <IntlProvider locale={locale} messages={messages[locale]}>
                <NotificationContainer />
                <MyLoadingOverlay />
                <RouterProvider router={router} />
            </IntlProvider>
        </Provider>
    </React.Fragment>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
