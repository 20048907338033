import styled, { css } from 'styled-components';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useSelector } from 'react-redux';
import { selectApp } from '~/src/store/appSlice';
import { PulseLoader } from 'react-spinners';

const DarkBackground = styled.div<{ disappear: boolean }>`
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 999; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

    ${(props) =>
        props.disappear &&
        css`
            display: block; /* show */
        `}
`;

export default function MyLoadingOverlay() {
    const { loading } = useSelector(selectApp);

    return (
        <DarkBackground disappear={loading}>
            <LoadingOverlay
                active={true}
                spinner={<PulseLoader color='#eee' />}
                // spinner={true}
                text='Загрузка...'
            />
        </DarkBackground>
    );
}
