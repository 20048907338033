import React, { BaseSyntheticEvent } from 'react';
import { ButtonGroup, Form, ToggleButton } from 'react-bootstrap';
import { realtyTypeBtns } from '../../../../common/data/search.form';
import { useDispatch } from 'react-redux';
import { goTo } from '~/src/store/realtySecondary/realtySecondarySlice';

const RealtyType = ({ formFilter, setFormFilter }) => {
    const dispatch = useDispatch();

    const handleChangeValue = (e: BaseSyntheticEvent) => {
        if (formFilter[e.target.name] === e.target.value) {
            setFormFilter((prevFormFilter) => {
                const updatedFormFilter = {
                    ...prevFormFilter,
                    [e.target.name]: null,
                };
                dispatch(goTo(updatedFormFilter));
                return updatedFormFilter;
            });
        } else {
            setFormFilter((prevFormFilter) => {
                const updatedFormFilter = {
                    ...prevFormFilter,
                    [e.target.name]: e.target.value,
                };
                dispatch(goTo(updatedFormFilter));
                return updatedFormFilter;
            });
        }
    };

    return (
        <Form.Group>
            <ButtonGroup>
                {realtyTypeBtns.map((radio, idx) => (
                    <ToggleButton
                        key={idx}
                        size='sm'
                        id={`realtyType-btns-${idx}`}
                        type='checkbox'
                        variant={
                            formFilter.realtyType === radio.value
                                ? radio.variantOn
                                : radio.variantOff
                        }
                        name='realtyType'
                        value={radio.value}
                        checked={formFilter.realtyType === radio.value}
                        onChange={handleChangeValue}
                    >
                        {radio.name}
                    </ToggleButton>
                ))}
            </ButtonGroup>
        </Form.Group>
    );
};

export default RealtyType;
