import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { ButtonGroup } from 'react-bootstrap';
import HaveAccess from '../Common/HaveAccess';
import { IAgency } from '~/src/service/agency';
import dateFormat from 'dateformat';
import { FormattedMessage } from 'react-intl';

const AgencyRow: FC<{ item: IAgency }> = ({ item }) => {
    return (
        <React.Fragment>
            <tr>
                <td>{item.id}</td>
                <td>
                    <span>Название: {item.title}</span>
                    <br />
                    <span>
                        Дата начала работы:{' '}
                        {dateFormat(item.foundationDate, 'dd.mm.yyyy')}
                    </span>
                    <br />
                    <span>Контактный телефон: {item.contactPhone}</span>
                    <br />
                    <span>Email: {item.email}</span>
                    <br />
                    <span>Директор: {item.director?.fullname}</span>
                    <br />
                </td>
                <td>
                    {item.countUsersByPosition
                        ? item.countUsersByPosition.map((i) => (
                              <>
                                  <FormattedMessage
                                      id={`agency_${i.position}`}
                                  />
                                  : {i.count}
                                  <br />
                              </>
                          ))
                        : null}
                </td>
                <td>
                    <span>Вторичка всего: {item.secondaryTotal}</span>
                    <br />
                    <span>Вторичка в приложении: {item.secondaryVerified}</span>
                    <br />
                </td>
                <td>
                    <ButtonGroup vertical className='w-100'></ButtonGroup>
                </td>
            </tr>
        </React.Fragment>
    );
};

export default AgencyRow;
