import { Dropdown } from 'react-bootstrap';
import { useDebouncedCallback } from 'use-debounce';
import { autocomplete } from '~/src/service/address';
import { useEffect, useState } from 'react';
import InputField from '../Form/InputField';
import { FormSpy } from 'react-final-form';

export default function DropDownSearchApi(
    props: Record<any, any>,
): JSX.Element {
    const {
        label,
        name,
        readOnly,
        defaultValue,
        onSelect,
        validator,
        children,
    } = props;

    const [value, setValue] = useState('');
    const [show, setShow] = useState(false);
    const [items, setItems] = useState([]);

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    const debounced = useDebouncedCallback((v: string) => {
        if (v.length > 3) {
            searchAddress(v);
        }
    }, 1000);

    const handleChange = (v: string) => {
        debounced(v);
        // setShow(true);
    };

    const handleSelect = async (item) => {
        setValue(item.address);
        onSelect(item);
        setShow(false);
        setItems([]);
    };

    const searchAddress = async (value: string) => {
        const _res = await autocomplete(value);

        if (_res) {
            setItems(_res);
            setShow(true);
        }
    };

    return (
        <div className='mb-3'>
            <InputField
                label={label}
                name={name}
                readOnly={readOnly}
                validators={validator}
            />
            <FormSpy
                subscription={{ values: true, dirty: true, active: true }}
                onChange={({ values, dirty, active }) => {
                    setValue(values.address);
                    if (values.address !== value && dirty && active === name) {
                        handleChange(values.address);
                    }
                }}
            />
            {children}
            <Dropdown show={show}>
                <Dropdown.Menu>
                    {items.map((item: Record<string, string>) => (
                        <Dropdown.Item
                            key={item.placeId}
                            eventKey={item.placeId}
                            onClick={() => handleSelect(item)}
                        >
                            {item.address}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}
