import React, { useEffect } from 'react';
import { FormApi } from 'final-form';
import Form from 'react-bootstrap/Form';
import Error from '../../Common/Form/Error';
import { Field, useForm } from 'react-final-form';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButtonField from '../../Common/Form/ToggleButtonWrapper';
import DropDownSearchApi from '~/src/components/Common/DropDownSearchApi';
import {
    composeValidators,
    mustBeNumber,
    required,
} from '~/src/helpers/validators';
import CheckboxField from '~/src/components/Common/Form/CheckboxField';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { selectChangePersonOnHousing } from '~/src/store/changePersonOnHousingSlice';

type TUpdatePartialViewProps = {
    defaultDropDownValue: string;
    isDouble: boolean;
    doubleId: number | null;
};

export default function UpdateAddressView({
    isDouble,
    doubleId,
}: TUpdatePartialViewProps): JSX.Element {
    const form: FormApi = useForm();
    const { allHousingEstate } = useSelector(selectChangePersonOnHousing);

    return (
        <React.Fragment>
            <Form.Group>
                {/* <ButtonGroup className='mb-2'>
                    <ToggleButtonField
                        label='Продажа'
                        type='radio'
                        name='dealType'
                        value='sale'
                        size='sm'
                        variantChecked='primary'
                        variantUnChecked='outline-primary'
                    />
                    <ToggleButtonField
                        label='Аренда'
                        type='radio'
                        name='dealType'
                        value='rent'
                        size='sm'
                        variantChecked='primary'
                        variantUnChecked='outline-primary'
                    />
                </ButtonGroup> */}
            </Form.Group>
            <Form.Group>
                <ButtonGroup className='mb-2'>
                    <ToggleButtonField
                        label='Вторичка'
                        type='radio'
                        name='realtyType'
                        value='secondary'
                        size='sm'
                        variantChecked='primary'
                        variantUnChecked='outline-primary'
                    />
                    <ToggleButtonField
                        label='Новостройка'
                        type='radio'
                        name='realtyType'
                        value='primary'
                        size='sm'
                        variantChecked='primary'
                        variantUnChecked='outline-primary'
                    />
                </ButtonGroup>
            </Form.Group>
            <Form.Group>
                <ButtonGroup className='mb-2'>
                    <ToggleButtonField
                        type='radio'
                        label='Квартира'
                        name='realtySubtype'
                        value='flat'
                        size='sm'
                        variantChecked='primary'
                        variantUnChecked='outline-primary'
                    />
                    <ToggleButtonField
                        type='radio'
                        label='Апартаменты'
                        name='realtySubtype'
                        value='apartments'
                        size='sm'
                        variantChecked='primary'
                        variantUnChecked='outline-primary'
                    />
                    <ToggleButtonField
                        type='radio'
                        label='Студия'
                        name='realtySubtype'
                        value='studio'
                        size='sm'
                        variantChecked='primary'
                        variantUnChecked='outline-primary'
                    />
                    <ToggleButtonField
                        type='radio'
                        label='Пансионат'
                        name='realtySubtype'
                        value='pansionat'
                        size='sm'
                        variantChecked='primary'
                        variantUnChecked='outline-primary'
                    />
                    <ToggleButtonField
                        type='radio'
                        label='Малосемейка'
                        name='realtySubtype'
                        value='malosemeyka'
                        size='sm'
                        variantChecked='primary'
                        variantUnChecked='outline-primary'
                    />
                    <ToggleButtonField
                        type='radio'
                        label='Общежитие'
                        name='realtySubtype'
                        value='obshchezhitie'
                        size='sm'
                        variantChecked='primary'
                        variantUnChecked='outline-primary'
                    />
                </ButtonGroup>
            </Form.Group>
            <Error name='realtySubtype' />
            <Field name='housingEstateId'>
                {({ input }) => (
                    <Form.Group className='mb-2'>
                        <Form.Label>
                            <>Жилой комплекс</>
                        </Form.Label>
                        <Select
                            {...input}
                            placeholder='Выберите ЖК'
                            onChange={(e) =>
                                form.mutators.changeHousingEstate(
                                    'housingEstateId',
                                    e.value,
                                )
                            }
                            options={allHousingEstate}
                            value={allHousingEstate?.find(
                                (e) =>
                                    e.value ===
                                    form.getState().values
                                        .housingEstateId,
                            )}
                        />
                    </Form.Group>
                )}
            </Field>
            {form.getState().values.realtyType ==
                'primary' && (
                    <Error name='housingEstateId' />
                )}
            <Field
                name='rooms'
                validate={composeValidators(required, mustBeNumber)}
            >
                {({ input, meta }) => (
                    <Form.Group className='mb-3'>
                        <Form.Label>Количество комнат</Form.Label>
                        <Form.Control
                            {...input}
                            type='number'
                            size='sm'
                            isInvalid={meta.error || meta.submitError}
                            placeholder='Количество комнат'
                        />
                        <Form.Control.Feedback type='invalid'>
                            {meta.error || meta.submitError}
                        </Form.Control.Feedback>
                    </Form.Group>
                )}
            </Field>
            <Row>
                <Col>
                    <CheckboxField name='multilevel' label='Многоуровневая' />
                </Col>
                <Col>
                    <CheckboxField name='isExclusive' label='Эксклюзив' />
                </Col>
            </Row>
            <div>
                <DropDownSearchApi
                    label='Адрес'
                    name='address'
                    readOnly={form.getState().values.verified}
                    onSelect={(item) => {
                        form.mutators.setAddressCheckValue(
                            'addressPlaceDetailId',
                            item.placeId,
                        );
                        form.mutators.setValue('address', item.address);
                        form.mutators.setValue('latitude', item.latitude);
                        form.mutators.setValue('longitude', item.longitude);
                    }}
                >
                    <Field name='addressPlaceDetailId'>
                        {({ meta }) => (
                            <Form.Group>
                                <Form.Control.Feedback
                                    type='invalid'
                                    style={{
                                        display:
                                            meta.error ||
                                                meta.submitError ||
                                                isDouble
                                                ? 'block'
                                                : 'none',
                                    }}
                                >
                                    {meta.error ||
                                        meta.submitError ||
                                        (isDouble && (
                                            <a
                                                href={`/realty-secondary/card/${doubleId}`}
                                                target='_blank'
                                                rel='noreferrer'
                                            >
                                                Такой объект уже есть в базе{' '}
                                                {doubleId}
                                            </a>
                                        ))}
                                </Form.Control.Feedback>
                            </Form.Group>
                        )}
                    </Field>
                </DropDownSearchApi>
                <Field name='flatNumber' validate={required}>
                    {({ input, meta }) => (
                        <Form.Group className='mb-2'>
                            <Form.Label>Номер квартиры</Form.Label>
                            <Form.Control
                                {...input}
                                type='text'
                                size='sm'
                                isInvalid={meta.error || meta.submitError}
                                onChange={(e) =>
                                    form.mutators.setAddressCheckValue(
                                        'flatNumber',
                                        e.target.value,
                                    )
                                }
                                readOnly={form.getState().values.verified}
                                placeholder={'Номер квартиры'}
                                title={
                                    form.getState().values.verified
                                        ? 'У подтвержденного объекта нельзя менять адрес'
                                        : 'Номер квартиры'
                                }
                            />
                            <Form.Control.Feedback type='invalid'>
                                {meta.error || meta.submitError}
                            </Form.Control.Feedback>
                        </Form.Group>
                    )}
                </Field>
            </div>
        </React.Fragment>
    );
}
