import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { changeControlDate, archive } from '~/src/service/buyer';
import ModalOneTextAreaField from '../../Common/ModalUI/ModalOneField/TextAreaField';
import {
    selectBuyerList,
    setBoard,
    changeControlDateBoard,
    changeStatusBoard,
    addCommentFromRow
} from '~/src/store/buyer/buyer.list.slice';
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd';
import { StrictModeDroppable as Droppable } from '~/src/helpers/StrictModeDroppable';
import './TrelloView.css';
import TrelloCard from './TrelloCard';
import TrelloBoard from './TrelloBoard';
import TrelloNav from './TrelloNav';

const TrelloView = (): JSX.Element => {
    const dispatch = useDispatch();

    const scrollRef = useRef(null);

    const { board, loading } = useSelector(selectBuyerList);
    const [isOpened, setOpen] = useState(false);
    const [resultS, setResult] = useState<DropResult>(
        {} as unknown as DropResult,
    );

    const onDragEnd = async (result: DropResult) => {
        setResult(result);
        if (result.source.droppableId !== result.destination.droppableId) {
            setOpen(true);
        } else {
            const [removed] = [
                ...board[result.source.droppableId].items,
            ].splice(result.source.index, 1);
            const destItems = [...board[result.destination.droppableId].items];
            destItems.splice(result.source.index, 1);
            destItems.splice(result.destination.index, 0, removed);
            dispatch(
                setBoard(
                    board.map((b) => {
                        if (b.id === board[result.source.droppableId].id) {
                            return {
                                ...b,
                                items: destItems,
                            };
                        }
                        return { ...b };
                    }),
                ),
            );
        }
    };
    const handlePrimaryButtonClick = async (value: string) => {
        const { source, destination } = resultS;
        const sourceColumn = board[source.droppableId];
        const destColumn = board[destination.droppableId];
        const sourceItems = [...sourceColumn.items];
        const destItems = [...destColumn.items];
        const [removed] = sourceItems.splice(source.index, 1);
        destItems.splice(destination.index, 0, removed);

        if (!resultS.destination) return;
        dispatch(
            setBoard(
                board.map((b) => {
                    if (b.id === sourceColumn.id) {
                        return {
                            ...b,
                            items: sourceItems,
                            count: b.count - 1,
                        };
                    }
                    if (b.id == destColumn.id) {
                        return {
                            ...b,
                            items: destItems,
                            count: b.count + 1,
                        };
                    }
                    return { ...b };
                }),
            ),
        );
        dispatch(addCommentFromRow(removed.id, value));
        if (destColumn.date === 'archive') {
            dispatch(changeStatusBoard(removed.id, 'archive'));
        } else if (destColumn.date === 'deal') {
            dispatch(changeStatusBoard(removed.id, 'deal'));
        } else {
            if (removed.status == 'archive' || removed.status == 'deal') {
                dispatch(changeStatusBoard(removed.id, 'active'));
            }
            dispatch(
                changeControlDateBoard(removed.id, {
                    controlDate: destColumn.date,
                    comment: value,
                }),
            );
        }
        setOpen(false);
    };

    if (loading) {
        return <>Loading...</>;
    }

    const handleCloseModal = () => {
        setOpen(false);
    };

    return (
        <div className='wrapperBuyer'>
            <TrelloNav />
            <DragDropContext onDragEnd={onDragEnd}>
                <div className='containerDnd' ref={scrollRef}>
                    {board.length > 0 &&
                        board.map((_board) => (
                            <Droppable
                                droppableId={_board.id.toString()}
                                key={_board.id}
                            >
                                {(provided: any) => {
                                    return (
                                        <TrelloBoard
                                            provided={provided}
                                            _board={_board}
                                        >
                                            {_board.items.map((item, index) => {
                                                return (
                                                    <Draggable
                                                        key={item.id}
                                                        draggableId={item.id.toString()}
                                                        index={index}
                                                    >
                                                        {(dragProvided) => {
                                                            return (
                                                                <TrelloCard
                                                                    dragProvided={
                                                                        dragProvided
                                                                    }
                                                                    item={item}
                                                                />
                                                            );
                                                        }}
                                                    </Draggable>
                                                );
                                            })}
                                            {provided.placeholder}
                                        </TrelloBoard>
                                    );
                                }}
                            </Droppable>
                        ))}
                </div>
            </DragDropContext>
            <div className='containerDnd_empty'></div>
            <ModalOneTextAreaField
                isOpened={isOpened}
                text={'Оставьте комментарий'}
                onClose={handleCloseModal}
                primaryButtonOnClick={handlePrimaryButtonClick}
                secondaryButtonOnClick={() => null}
            />
        </div>
    );
};

export default TrelloView;
