import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import React, { BaseSyntheticEvent, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OverlayingPopup from '../../Common/ModalUI/OverlayingPopup';
import { uploadAgentsByExcel } from '~/src/store/agencyAgentsSlice';
import ExampleExcel from '../../../assets/resources/agents_excel_list_example.xlsx';

const InviteAgentByExcel = () => {
    const dispatch = useDispatch();

    const [isOpened, setOpen] = useState(false);

    const open = () => setOpen(true);
    const close = () => setOpen(false);

    const changeHandler = (e: BaseSyntheticEvent) => {
        dispatch(uploadAgentsByExcel(e.target.files));
        e.target.value = null;
    };

    return (
        <React.Fragment>
            <Button size='sm' variant='primary' onClick={open}>
                <FontAwesomeIcon icon={['fas', 'file-excel']} /> Добавить из
                файла
            </Button>
            <OverlayingPopup onClose={close} isOpened={isOpened}>
                <div className='main_popup' style={{ width: '800px' }}>
                    <div className='flex flex-column'>
                        <label className='btn btn-sm btn-primary d-flex w-50 justify-content-center align-items-center m-auto'>
                            <input
                                type='file'
                                name='users'
                                onChange={changeHandler}
                            />
                            <FontAwesomeIcon icon={['fas', 'file-excel']} />
                            &nbsp;Загрузить
                        </label>
                        <Link
                            className='d-flex justify-content-center align-items-center m-auto'
                            to={ExampleExcel}
                            download
                            target='_blank'
                            rel='noreferrer'
                        >
                            Пример файла
                        </Link>
                    </div>
                </div>
            </OverlayingPopup>
        </React.Fragment>
    );
};

export default InviteAgentByExcel;
