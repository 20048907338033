import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Card,
    Stack,
    Button,
    Navbar,
    Container,
    ButtonGroup,
} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UploadUsers from './UploadUsers';

const AdminToolsView = (): JSX.Element => {
    const navigate = useNavigate();
    const [userUploadIsOpen, setUserUploadIsOpen] = useState(false);

    const handleUserUploadClick = () => {
        setUserUploadIsOpen(true);
    };

    const setUserUploadToFalse = () => setUserUploadIsOpen(false);

    return (
        <React.Fragment>
            <Navbar
                className='justify-content-between'
                bg='light'
                expand='lg'
                variant='light'
            >
                <ButtonGroup>
                    <Button size='sm' onClick={() => navigate(-1)}>
                        <FontAwesomeIcon icon={['fas', 'fast-backward']} />{' '}
                        Назад
                    </Button>
                </ButtonGroup>
            </Navbar>
            <Container fluid>
                <Stack direction='horizontal' gap={3}>
                    <Card style={{ width: '10rem', height: '10rem' }}>
                        <Card.Body
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                            }}
                            title='Загрузить пользователей'
                            onClick={handleUserUploadClick}
                        >
                            <FontAwesomeIcon icon={['fas', 'user-plus']} />
                        </Card.Body>
                    </Card>
                </Stack>
            </Container>
            <UploadUsers
                isOpen={userUploadIsOpen}
                onClose={setUserUploadToFalse}
            />
        </React.Fragment>
    );
};

export default AdminToolsView;
