import ListRow from './ListRow';
import { Link } from 'react-router-dom';
import { useQuery } from '~/src/helpers/hooks';
import { redirect } from '~/src/store/appSlice';
import SortLimitRow from '../../Common/SortLimitRow';
import { useDispatch, useSelector } from 'react-redux';
import { BaseSyntheticEvent, useCallback } from 'react';
import Pagination from '~/src/components/Common/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup, Navbar, Table } from 'react-bootstrap';
import { selectPermission, goTo, all } from '~/src/store/agency.permission';

const ListView = (): JSX.Element => {
    const query = useQuery();
    const dispatch = useDispatch();

    const { items, search } = useSelector(selectPermission);

    const sortHandler = useCallback(
        (e: BaseSyntheticEvent) => {
            dispatch(goTo({ sort: e.currentTarget.value }));
        },
        [dispatch],
    );

    const limitHandler = useCallback(
        (e: BaseSyntheticEvent) => {
            dispatch(goTo({ limit: e.currentTarget.value }));
        },
        [dispatch],
    );

    const handlePaginationClick = (page: number) => {
        dispatch(goTo({ page }));
    };

    const sortOpts = [
        { value: 'id_asc', title: 'ID ', up: true },
        { value: 'id_desc', title: 'ID ', down: true },
        { value: 'title_asc', title: 'Название ', up: true },
        { value: 'title_desc', title: 'Название ', down: true },
    ];

    return (
        <>
            <Navbar
                className='justify-content-between'
                bg='light'
                expand='lg'
                variant='light'
            >
                <ButtonGroup>
                    <Link
                        to={'/agency/permission/create/'}
                        className='btn btn-sm btn-success'
                    >
                        <FontAwesomeIcon icon={['fas', 'plus']} /> Добавить
                    </Link>
                </ButtonGroup>
                <ButtonGroup>
                    <Button
                        size='sm'
                        variant='secondary'
                        onClick={() => dispatch(all(query))}
                    >
                        <FontAwesomeIcon icon={['fas', 'sync']} /> Обновить
                    </Button>
                    <Button
                        size='sm'
                        variant='danger'
                        onClick={() => dispatch(redirect('/agency/permission'))}
                    >
                        <FontAwesomeIcon icon={['fas', 'sync']} /> Сбросить
                        фильтр
                    </Button>
                </ButtonGroup>
            </Navbar>
            <SortLimitRow
                sort={search?.sort}
                limit={search?.limit}
                sortHandler={sortHandler}
                limitHandler={limitHandler}
                sortOpts={sortOpts}
            />
            {items && items.pagination?.have && (
                <Pagination
                    pagination={items.pagination}
                    onClick={handlePaginationClick}
                />
            )}
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <td>#</td>
                        <td>Название</td>
                        <td>Код</td>
                        <td>Группа</td>
                        <td>Управление</td>
                    </tr>
                </thead>
                <tbody>
                    {items && items.items && items.items.length > 0
                        ? items.items.map((item) => (
                              <ListRow key={item.code} item={item} />
                          ))
                        : null}
                </tbody>
            </Table>
            {items && items.pagination?.have && (
                <Pagination
                    pagination={items.pagination}
                    onClick={handlePaginationClick}
                />
            )}
        </>
    );
};

export default ListView;
