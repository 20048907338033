import ReactDOM from 'react-dom';
import { ReactNode, useEffect, useState } from 'react';

type TProps = {
    children: ReactNode | undefined;
};

const Portal = ({ children }: TProps): JSX.Element => {
    const [container] = useState(() => document.createElement('div'));

    useEffect(() => {
        document.body.appendChild(container);

        return () => {
            document.body.removeChild(container);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return ReactDOM.createPortal(children, container);
};

export default Portal;
