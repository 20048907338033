import classNames from 'classnames';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import createInitialFormValues from '~/src/helpers/createInitialFormValues';
import {
    ICottageVillagePlot,
    update,
} from '~/src/service/cottageVillage/cottage.village.plot.service';
import CheckboxField from '../../Common/Form/CheckboxField';
import ReactSelectField from '../../Common/Form/ReactSelectField';
import { selectDHP } from '~/src/store/developer/developer.house.project';
import { useSelector } from 'react-redux';

import InputField from '../../Common/Form/InputField';
import SimpleSelectFieldV2 from '~/src/components/Common/Form/SimpleSelectV2';
import {
    composeValidators,
    price,
    required,
    space,
} from '~/src/helpers/validators';
import { suburbanCottageVillagePlotTypes } from '~/src/common/data/suburban.cottage.village.plot.type';
import { Button, ButtonGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PlotChessCard = ({ plot }) => {
    const { unloaded } = useSelector(selectDHP);

    const unloadedPrepared = unloaded?.map((u) => ({
        value: u.id,
        label: u.title,
    }));

    const onSubmit = async (values: Record<string, any>) => {
        await update(values.id, {
            ...values,
            price: values.price.replaceAll(/\D/g, ''),
        } as unknown as ICottageVillagePlot);
    };

    return (
        <div
            className={classNames([
                'd-flex',
                'p-2',
                'bg-opacity-50',
                'border',
                plot.sold ? 'bg-danger' : 'bg-success',
            ])}
            style={{ width: '200px', borderWidth: '0 1px' }}
        >
            <FinalForm
                onSubmit={onSubmit}
                initialValues={{
                    ...createInitialFormValues(plot),
                    price: new Intl.NumberFormat('ru-RU').format(
                        Number(plot.price),
                    ),
                    showDeveloperContracts:
                        plot.type == 'with_construction_contract',
                    developerHouseProjectId: plot.houseProjects?.map(
                        (hp) => hp.id,
                    ),
                }}
                mutators={{
                    // expect (field, value) args from the mutator
                    setValue: ([field, value], state, { changeValue }) => {
                        changeValue(state, field, () => value);
                    },
                    setPriceValue: ([field, value], state, { changeValue }) => {
                        value = value.replaceAll(/\D/g, '');
                        changeValue(state, field, () => value);
                    },
                    setDecimalValue: (
                        [field, value],
                        state,
                        { changeValue },
                    ) => {
                        value = value.replaceAll(',', '.');
                        changeValue(state, field, () => value);
                    },
                    setSelectValue: (
                        [field, value],
                        state,
                        { changeValue },
                    ) => {
                        changeValue(state, field, () => [
                            ...value.map((v) => v.value),
                        ]);
                    },
                    changePlotType: (
                        [field, value],
                        state,
                        { changeValue },
                    ) => {
                        changeValue(state, field, () => value);
                        changeValue(
                            state,
                            'developerHouseProjectId',
                            () => null,
                        );

                        if (value == 'with_construction_contract') {
                            changeValue(
                                state,
                                'showDeveloperContracts',
                                () => true,
                            );
                        } else {
                            changeValue(
                                state,
                                'showDeveloperContracts',
                                () => false,
                            );
                        }
                    },
                }}
                validate={() => {
                    const errors: any = {};
                    return errors;
                }}
                render={({ handleSubmit, form, submitting, valid }) => (
                    <form onSubmit={handleSubmit}>
                        <p>
                            <strong>Номер участка #{plot.number}</strong>
                        </p>
                        <SimpleSelectFieldV2
                            name='type'
                            label='Тип'
                            onChange={(e) =>
                                form.mutators.changePlotType(
                                    'type',
                                    e.target.value,
                                )
                            }
                            validators={required}
                        >
                            <option></option>
                            {suburbanCottageVillagePlotTypes.map(
                                ({ value, title }) => (
                                    <option key={value} value={value}>
                                        {title}
                                    </option>
                                ),
                            )}
                        </SimpleSelectFieldV2>
                        <FormSpy subscription={{ values: true }}>
                            {({ values }) => {
                                return (
                                    <>
                                        {values.showDeveloperContracts ==
                                        true ? (
                                            <>
                                                <CheckboxField
                                                    label='Все проекты застройщика'
                                                    name='allProjects'
                                                />
                                                <ReactSelectField
                                                    label='Проекты'
                                                    name='developerHouseProjectId'
                                                    value={unloadedPrepared?.filter(
                                                        (d) =>
                                                            form
                                                                .getState()
                                                                .values?.developerHouseProjectId?.includes(
                                                                    d.value,
                                                                ),
                                                    )}
                                                    onChange={(v) =>
                                                        form.mutators.setSelectValue(
                                                            'developerHouseProjectId',
                                                            v,
                                                        )
                                                    }
                                                    validators={required}
                                                    options={unloadedPrepared}
                                                    isMulti={true}
                                                />
                                            </>
                                        ) : null}
                                    </>
                                );
                            }}
                        </FormSpy>
                        <InputField
                            name='price'
                            label='Стоимость'
                            onChange={(e) =>
                                form.mutators.setPriceValue(
                                    'price',
                                    e.target.value,
                                )
                            }
                            validators={composeValidators(required, price)}
                        />
                        <InputField
                            name='area'
                            label='Площадь (в сотках)'
                            validators={composeValidators(required, space)}
                        />
                        <CheckboxField label='Продан' name='sold' />
                        <div className='text-center'>
                            <ButtonGroup className='mb-3'>
                                <Button
                                    type='submit'
                                    disabled={submitting || !valid}
                                    size='sm'
                                    variant='success'
                                >
                                    <FontAwesomeIcon icon={['fas', 'save']} />
                                </Button>
                            </ButtonGroup>
                        </div>
                    </form>
                )}
            />
        </div>
    );
};

export { PlotChessCard };
