const createInitialFormValues = (item: Record<string, any>) => {
    if (item === null || item === undefined) return {};

    return Object.keys(item)
        .filter((k) => item[k] != null)
        .reduce((a, k) => ({ ...a, [k]: item[k] }), {});
};

export default createInitialFormValues;

export { createInitialFormValues };
