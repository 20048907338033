import React from 'react';
import { FormApi } from 'final-form';
import Form from 'react-bootstrap/Form';
import Error from '../../../Common/Form/Error';
import { Field, useForm, FormSpy } from 'react-final-form';
import MapUpdateView from '../../../HousingEstate/MapUpdateView';
import { geocode } from '~/src/service/address';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButtonField from '../../../Common/Form/ToggleButtonWrapper';
import DropDownSearchApi from '~/src/components/Common/DropDownSearchApi';
import { required } from '~/src/helpers/validators';
import CheckboxField from '~/src/components/Common/Form/CheckboxField';
import { Col, Row } from 'react-bootstrap';
import SimpleSelectV2Field from '../../../Common/Form/SimpleSelectV2';
import { landAppointmentSelect } from '~/src/common/data/realtySuburban/realty.suburba.landAppointment';
import InputField from '../../../Common/Form/InputField';
import { IUnloadDirection } from '~/src/service/direction';
import { useSelector } from 'react-redux';
import { selectCV } from '~/src/store/cottage.village/cottage.village.store';

type TUpdatePartialViewProps = {
    defaultDropDownValue: string;
    isDouble: boolean;
    doubleId: number | null;
    unloadItems: IUnloadDirection[];
};

const UpdateAddressView = ({
    isDouble,
    doubleId,
    unloadItems,
}: TUpdatePartialViewProps) => {
    const form: FormApi = useForm();
    const { unloadCVItems } = useSelector(selectCV);

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Form.Group>
                        {/* <ButtonGroup className='mb-2'>
                <ToggleButtonField
                    label='Продажа'
                    type='radio'
                    name='dealType'
                    value='sale'
                    size='sm'
                    variantChecked='primary'
                    variantUnChecked='outline-primary'
                />
                <ToggleButtonField
                    label='Аренда'
                    type='radio'
                    name='dealType'
                    value='rent'
                    size='sm'
                    variantChecked='primary'
                    variantUnChecked='outline-primary'
                />
            </ButtonGroup> */}
                    </Form.Group>
                    <Form.Group>
                        <ButtonGroup className='mb-2'>
                            <ToggleButtonField
                                type='radio'
                                label='Земельный участок'
                                name='type'
                                value='land'
                                size='sm'
                                variantChecked='primary'
                                variantUnChecked='outline-primary'
                            />
                            <ToggleButtonField
                                type='radio'
                                label='Дом/коттедж'
                                name='type'
                                value='house'
                                size='sm'
                                variantChecked='primary'
                                variantUnChecked='outline-primary'
                            />
                            <ToggleButtonField
                                type='radio'
                                label='Дача'
                                name='type'
                                value='chalet'
                                size='sm'
                                variantChecked='primary'
                                variantUnChecked='outline-primary'
                            />
                        </ButtonGroup>
                    </Form.Group>
                    <Error name='type' />
                    <Field name='landAppointment' validate={required}>
                        {() => (
                            <SimpleSelectV2Field
                                label='Назначение земельного участка'
                                name='landAppointment'
                                validators={required}
                            >
                                <option></option>
                                {landAppointmentSelect.map((el) => (
                                    <option key={el.value} value={el.value}>
                                        {el.label}
                                    </option>
                                ))}
                            </SimpleSelectV2Field>
                        )}
                    </Field>
                    <div>
                        <DropDownSearchApi
                            label='Адрес'
                            name='address'
                            readOnly={form.getState().values.verified}
                            validator={required}
                            onSelect={(item) => {
                                form.mutators.setAddressCheckValue(
                                    'addressPlaceDetailId',
                                    item.placeId,
                                );
                                form.mutators.setValue('address', item.address);
                                form.mutators.setValue(
                                    'latitude',
                                    item.latitude,
                                );
                                form.mutators.setValue(
                                    'longitude',
                                    item.longitude,
                                );
                            }}
                        >
                            <Field name='addressPlaceDetailId'>
                                {({ meta }) => (
                                    <Form.Group>
                                        <Form.Control.Feedback
                                            type='invalid'
                                            style={{
                                                display:
                                                    meta.error ||
                                                        meta.submitError ||
                                                        isDouble
                                                        ? 'block'
                                                        : 'none',
                                            }}
                                        >
                                            {meta.error ||
                                                meta.submitError ||
                                                (isDouble && (
                                                    <a
                                                        href={`/realty-suburban/card/${doubleId}`}
                                                        target='_blank'
                                                        rel='noreferrer'
                                                    >
                                                        Такой объект уже есть в
                                                        базе {doubleId}
                                                    </a>
                                                ))}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            </Field>
                        </DropDownSearchApi>
                        <Row>
                            <Col>
                                <CheckboxField
                                    name='isExclusive'
                                    label='Эксклюзив'
                                />
                            </Col>
                            <Col>
                                <CheckboxField
                                    name='fromLegalEntity'
                                    label='От юридического лица'
                                />
                            </Col>
                            <Col>
                                <CheckboxField
                                    name='ruralMortgage'
                                    label='Подходит под сельскую ипотеку'
                                />
                            </Col>
                        </Row>
                        <Field name='cottageVillageId'>
                            {({ input, meta }) => (
                                <SimpleSelectV2Field
                                    label='КП'
                                    name='cottageVillageId'
                                >
                                    <option></option>
                                    {unloadCVItems.map((el) => (
                                        <option key={el.id} value={el.id}>
                                            {el.title}
                                        </option>
                                    ))}
                                </SimpleSelectV2Field>
                            )}
                        </Field>
                        <Field name='directionId' validate={required}>
                            {({ input, meta }) => (
                                <SimpleSelectV2Field
                                    label='Тракт'
                                    name='directionId'
                                    validators={required}
                                >
                                    <option></option>
                                    {unloadItems.map((el) => (
                                        <option key={el.id} value={el.id}>
                                            {el.title}
                                        </option>
                                    ))}
                                </SimpleSelectV2Field>
                            )}
                        </Field>
                        <InputField
                            name='cn'
                            label='Кадастровый номер земельного участка'
                            onChange={(e) =>
                                form.mutators.setCnValue('cn', e.target.value)
                            }
                            validators={required}
                        />
                    </div>
                </Col>
                <Col>
                    <FormSpy subscription={{ values: true }}>
                        {({ values }) => (
                            <MapUpdateView
                                latitude={values.latitude || undefined}
                                longitude={values.longitude || undefined}
                                onClick={async (lat, lng) => {
                                    const res = await geocode(lat, lng);
                                    if (Array.isArray(res) && res.length > 0) {
                                        form.mutators.setAddressCheckValue(
                                            'addressPlaceDetailId',
                                            res[0].placeId,
                                        );
                                        form.mutators.setValue(
                                            'address',
                                            res[0].address,
                                        );
                                        form.mutators.setValue(
                                            'latitude',
                                            res[0].latitude,
                                        );
                                        form.mutators.setValue(
                                            'longitude',
                                            res[0].longitude,
                                        );
                                    }
                                }}
                            />
                        )}
                    </FormSpy>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default UpdateAddressView;
