import { useState, FC } from 'react';
import MainPopup from '../MainPopup';
import { Button, Form, FormGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ModalOneTextAreaField: FC<TProps> = ({
    onClose,
    isOpened,
    text,
    primaryButtonText = 'Да',
    primaryButtonOnClick,
    secondaryButtonText = 'Отмена',
    secondaryButtonOnClick,
    defaultValue = '',
}) => {
    const [value, setValue] = useState(defaultValue);

    const handleClose = () => {
        setValue(defaultValue);
        onClose();
    };

    const handlePBOnClick = () => {
        primaryButtonOnClick(value);
        handleClose();
    };

    const handleSBOnClick = () => {
        secondaryButtonOnClick();
        handleClose();
    };

    return (
        <MainPopup isOpened={isOpened} onClose={handleClose}>
            <p>{text}</p>
            <FormGroup className='mb-2'>
                <Form.Control
                    type='text'
                    as='textarea'
                    value={value || ''}
                    rows={3}
                    required
                    onChange={(e) => setValue(e.target.value)}
                />
            </FormGroup>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '1rem',
                }}
            >
                <Button
                    size='sm'
                    type='button'
                    variant='danger'
                    onClick={handleSBOnClick}
                >
                    <FontAwesomeIcon icon={['fas', 'ban']} />{' '}
                    {secondaryButtonText}
                </Button>
                <Button
                    size='sm'
                    type='button'
                    variant='success'
                    onClick={handlePBOnClick}
                    disabled={value?.length == 0}
                >
                    <FontAwesomeIcon icon={['fas', 'check']} />{' '}
                    {primaryButtonText}
                </Button>
            </div>
        </MainPopup>
    );
};

type TProps = {
    isOpened: boolean;
    text: string;
    onClose: () => void;
    primaryButtonText?: string;
    primaryButtonOnClick?: (value: string) => void;
    secondaryButtonText?: string;
    secondaryButtonOnClick: () => void;
    defaultValue?: string;
};

export default ModalOneTextAreaField;
