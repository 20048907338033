import Api from '~/src/helpers/http-common';
import { IAgency, IAgencyGroup } from './agency';
import { IResponseListData, IUpdateData } from '../interfaces/common';
import { IUserPermissionUnloaded } from './permission';
import { IAgencyAgent } from './agency.agents';
import { IFilestore } from './filestore';
import { AxiosRequestConfig } from 'axios';

export const getUserList = async (
    queryParams?: URLSearchParams,
): Promise<IUser[]> => {
    return await Api.get(
        `/user?${queryParams != null ? queryParams.toString() : ''}`,
    );
};

export const create = async (
    data: IUser,
    files?: Record<string, FileList>,
): Promise<IUser | any> => {
    return (await Api.post('/user', data, files)) as IUser;
};

export const getUser = async (id: number): Promise<IUser> => {
    return await Api.get(`/user/${id}`);
};

export const getUserPA = async (): Promise<IUsersPA> => {
    return await Api.get('/user/pa');
};

export const getRewards = async (): Promise<IResponseListData<IReward>> => {
    return await Api.get('/user/reward/my');
};

export const createRewards = async (
    data: IRewardCreate,
    files: FileList | File[],
    config?: AxiosRequestConfig
): Promise<IReward> => {
    return await Api.post(
        '/user/reward/myself',
        data,
        { photos: files },
        config,
    );
};

export const updateRewards = async (
    id: number,
    data: IRewardCreate,
    files: FileList | File[],
    config?: AxiosRequestConfig
): Promise<IReward> => {
    return await Api.put(
        `/user/reward/${id}`,
        data,
        { photos: files },
        config,
    );
};

export const deleteFileReward = async (
    id: number,
    name: string,
): Promise<IReward> => {
    return await Api.delete(`/user/reward/${id}/files/${name}`);
};

export const getReviews = async (): Promise<IResponseListData<IReviews>> => {
    return await Api.get('/reviews/my');
};

export const createReviews = async (
    data: IRewardCreate,
    files: FileList | File[],
    config?: AxiosRequestConfig
): Promise<IReviews> => {
    return await Api.post(
        '/reviews/myself',
        data,
        { files },
        config,
    );
};

export const updateReviews = async (
    id: number,
    data: IRewardCreate,
    files: FileList | File[],
    config?: AxiosRequestConfig
): Promise<IReviews> => {
    return await Api.put(
        `/reviews/${id}`,
        data,
        { files },
        config,
    );
};

export const deleteFileReviews = async (
    id: number,
    name: string,
): Promise<IReviews> => {
    return await Api.delete(`/reviews/${id}/files/${name}`);
};


export const getUserByPhone = async (
    phone: number,
): Promise<IUpdateData<IUser>> => {
    return await Api.post('user/findbyphone', { phone });
};

export const getUsersByFullname = async (
    fullname: string,
): Promise<IUser[]> => {
    return await Api.post('user/findbyfullname', { fullname });
};

export const updateUser = async (
    id: string | number,
    data: IUser | IUsersPA,
    files?: Record<string, File[]>,
): Promise<IUpdateData<IUser>> => {
    return await Api.put<IUpdateData<IUser>>(`/user/${id}`, data, files);
};

export const removeUser = async (id: string | number): Promise<any> => {
    return await Api.delete(`/user/${id}`);
};

export const changePassword = async (
    old: string,
    newpass: string,
): Promise<any> => {
    return await Api.post('/user/change-password', { old, newpass });
};

export const resetPassword = async (id: number): Promise<any> => {
    return await Api.post(`/user/${id}/reset-password`);
};

export const getUnloadedUserPermissions = async (
    id: string | number,
): Promise<IUserPermissionUnloaded[]> => {
    return await Api.get(`/user/${id}/permission/unload`);
};

export const switchUserPermission = async (
    userId: string | number,
    permissionId: string | number,
): Promise<{ success: boolean }> => {
    return await Api.get(`/user/${userId}/permission/${permissionId}/switch`);
};

export const getAgents = async (
    queryParams?: URLSearchParams,
): Promise<IResponseListData<IUser>> => {
    queryParams.set('role', 'agent');
    return await Api.get(
        `/user?${queryParams != null ? queryParams.toString() : ''}`,
    );
};

export const getAgentsSingle = async (
    queryParams?: URLSearchParams,
): Promise<IResponseListData<IUser>> => {
    queryParams.set('role', 'agent');
    queryParams.set('withoutAgency', '1');
    return await Api.get(
        `/user?${queryParams != null ? queryParams.toString() : ''}`,
    );
};

export const getUsers = async (
    queryParams?: URLSearchParams,
): Promise<IResponseListData<IUser>> => {
    queryParams.set('role', 'user');
    return await Api.get(
        `/user?${queryParams != null ? queryParams.toString() : ''}`,
    );
};

export const getUnverified = async <T>(
    queryParams?: URLSearchParams,
): Promise<T> => {
    queryParams.delete('role');
    queryParams.append('role', 'user');
    queryParams.append('role', 'agent');
    queryParams.set('isRoleVerified', '0');
    return await Api.get(
        `/user?${queryParams != null ? queryParams.toString() : ''}`,
    );
};

export const verifyRole = async (id: number) => {
    return await Api.post(`/user/${id}/role-verify`);
};

export const block = async (id: number) => {
    return await Api.post(`/user/${id}/block`);
};

export const uploadGroupData = async (): Promise<IGroupMy> => {
    return await Api.get('/agency/my/group');
};

export interface IUser {
    id?: number;
    fullname?: string;
    uid?: string;
    role?: string;
    firstname?: string;
    lastname?: string;
    middlename?: string;
    email?: string;
    inn?: string;
    phone?: string;
    replacementPhone?: string;
    isReplacementPhoneActive?: boolean;
    birthdate?: Date;
    confirmCode?: number;
    isBlocked?: boolean;
    isAdmin?: boolean;
    blockReason?: string;
    blockDescription?: string;
    createdAt?: Date;
    createdBy?: string;
    updatedAt?: Date;
    updatedBy?: string;
    DeletedAt?: Date;
    DeletedBy?: string;
    permissions?: string[];

    isDirector?: boolean;
    myAgency?: IAgency | null;

    token?: string;

    agencyInfo?: IUserAgencyInfo | null;

    secondaryTotal?: number;
    secondaryVerified?: number;
}

interface IUserAgencyInfo {
    agency?: IAgency;
    agent?: IAgencyAgent;
    group?: IAgencyGroup;
    isDirector: boolean;
    permissions?: string[];
    agencyGroups?: IAgencyGroup[];
    agencyAgents?: IUser[];
}

export interface IUserWithStatistics extends IUser {
    statistics: TStat;
}

type TStat = {
    secondaryTotal: number;
    secondaryVerified: number;
    secondaryCreatedCount: number;
    secondaryPriceDownCount: number;
    getSecondaryOutdatedSoonTotal: number;
    secondaryPrimaryCreatedCount: number;
    suburbanCreatedCount: number;
    suburbanOutdatedSoonTotal: number;
    suburbanPriceDownCount: number;
    suburbanTotal: number;
    suburbanVerified: number;
    buyerCreatedCount: number;
    getBuyerCountByThermo: IBuyeryThermo
};

export interface IBuyeryThermo {
    all: number;
    cold: number;
    warm: number;
    hot: number;
}


export interface IGroupMy {
    agents: IUser[];
    group: IAgencyGroup;
    position: string;
}

export interface IReward {
    id?: number;
    userId?: number;
    title?: string;
    content?: string;
    receiveAt?: string;
    updatedAt?: string;
    updatedBy?: number | string;
    createdAt?: string;
    createdBy?: number | string;
    user_id?: number;
    updated_by?: number | string;
    created_by?: number | string;
    photos?: IFilestore[];
}

export interface IUsersPA {
    id?: number;
    uid?: string;
    role?: string;
    fullname?: string;
    firstname?: string;
    lastname?: string;
    middlename?: string;
    email?: string;
    inn?: string;
    phone?: string;
    specPrimary?: number,
    specSecondary?: number,
    specSuburban?: number,
    specComm?: number,
    videoUrl?: string,
    replacementPhone?: string,
    isReplacementPhoneActive?: number,
    birthdate?: Date,
    password?: any,
    photo?: string;
    confirmCode?: string,
    isBlocked?: number,
    blockReason?: string,
    blockDescription?: string,
    createdAt?: Date,
    createdBy?: number,
    updatedAt?: Date,
    updatedBy?: number,
    deletedAt?: Date,
    deletedBy?: number
    isRoleVerified?: number,
    isRoleVerifiedAt?: Date,
    isRoleVerifiedBy?: number,
    isDirector?: boolean;
    myAgency?: IAgency | null;
    token?: string;
}

export interface IRewardCreate {
    title: string;
    content: string;
    receiveAt: string;
}

export interface IReviews {
    id?: number;
    userId?: number;
    raiting?: number;
    content?: string;
    verified?: boolean;
    moderated?: boolean,
    updatedAt?: Date,
    updatedBy?: number,
    createdAt?: Date,
    createdBy?: number
    files?: IFilestore[];
}
