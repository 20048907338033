import classNames from 'classnames';
import React, { Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useNavigate, useNavigation } from 'react-router-dom';
import { selectApp } from '~/src/store/appSlice';
import ComponentsContainer from './ComponentsContainer';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { library, IconPack } from '@fortawesome/fontawesome-svg-core';

import LeftSideNav from './LeftSideNav';
import 'react-slidedown/lib/slidedown.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import Check from '../Info/check';
import HaveAccess from '../Common/HaveAccess';

library.add(fab as IconPack, fas as IconPack);

const App = (): JSX.Element => {
    const { redirect } = useSelector(selectApp);

    console.log('APP RENDER!!!!');

    return (
        <Suspense fallback={<div>Загрузка...</div>}>
            {redirect && <Navigate to={redirect} />}
            <React.Fragment>
                <div className='app'>
                    <div
                        className={classNames([
                            'ace-absolute',
                            'ace-bottom-0',
                            'ace-flex',
                            'ace-flex-column',
                            'ace-flex-nowrap',
                            'ace-left-0',
                            'ace-overflow-hidden',
                            'ace-right-0',
                            'ace-top-0',
                        ])}
                    >
                        <div
                            className={classNames([
                                'ace-flex',
                                'ace-flex-column',
                                'ace-flex-nowrap',
                                'ace-full-height',
                            ])}
                        >
                            <div
                                className={classNames([
                                    'ace-flex',
                                    'ace-flex-nowrap',
                                    'ace-full-height',
                                    'ace-overflow-hidden',
                                    'ace-relative',
                                ])}
                            >
                                <LeftSideNav />
                                <div
                                    className={classNames([
                                        'ace-flex',
                                        'ace-flex-column',
                                        'ace-flex-grow-1',
                                        'ace-full-height',
                                        'ace-full-width',
                                        'ace-overflow-hidden',
                                        'ace-relative',
                                        'ace-z-default',
                                        'aceilight-main',
                                    ])}
                                >
                                    <ComponentsContainer>
                                        <Outlet />
                                    </ComponentsContainer>
                                    <HaveAccess access={'users.verify'}>
                                        <Check />
                                    </HaveAccess>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        </Suspense>
    );
};

export default App;
