import React, { useState, ReactNode } from 'react';
import { Button, ButtonProps } from 'react-bootstrap';
import Confirm from '../AlertModals/Confirm';
import ModalOneTextAreaField from '../ModalOneField/TextAreaField';

type TProps = {
    label: string;
    variant?: string;
    modalText: string;
    children?: ReactNode;
};

interface IProps extends ButtonProps {
    modalText: string;
    children?: ReactNode;
    label?: string;
    primaryButtonOnClick?: (value?: string) => void;
    text: string;
}

const ButtonWithConfirmModal = ({
    label,
    variant,
    primaryButtonOnClick,
    modalText,
    children,
}: IProps): JSX.Element => {
    const [isOen, setisOpen] = useState(false);

    const handleSwitchModal = () => {
        setisOpen((prev) => !prev);
    };

    return (
        <React.Fragment>
            <Button
                size='sm'
                variant={variant || 'secondary'}
                title={label}
                onClick={handleSwitchModal}
            >
                {children || label}
            </Button>
            <ModalOneTextAreaField
                isOpened={isOen}
                text={'Оставьте комментарий'}
                onClose={handleSwitchModal}
                primaryButtonOnClick={primaryButtonOnClick}
                secondaryButtonOnClick={() => null}
            />
        </React.Fragment>
    );
};

export default ButtonWithConfirmModal;
