import { useState, BaseSyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { goTo, selectAddress } from '~/src/store/address';
import { Button, Form, InputGroup } from 'react-bootstrap';

export default function QuickSearch(): JSX.Element {
    const dispatch = useDispatch();

    const { search } = useSelector(selectAddress);

    const [value, setValue] = useState(search?.q || '');

    const handleChange = (e: BaseSyntheticEvent) => {
        setValue(e.target.value);
    };

    const handleSubmit = async () => {
        dispatch(goTo({ q: value }));
    };

    return (
        <>
            <Form.Label>&nbsp;</Form.Label>
            <InputGroup className='mb-3' size='sm'>
                <Form.Control
                    name='name'
                    value={value}
                    onChange={handleChange}
                />
                <Button type='submit' onClick={handleSubmit}>
                    Найти
                </Button>
            </InputGroup>
        </>
    );
}
