import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';

import { FormApi } from 'final-form';
import { Field, useForm } from 'react-final-form';

import Condition from '../../Common/Form/Condition';
import InputField from '../../Common/Form/InputField';
import CheckboxField from '~/src/components/Common/Form/CheckboxField';

import { composeValidators, price, required } from '~/src/helpers/validators';

export default function UpdateAdditionView(): JSX.Element {
    const form: FormApi = useForm();

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <CheckboxField name='stainedGlass' label='Витражные окна' />
                    <CheckboxField name='soundproofing' label='Шумоизоляция' />
                    <CheckboxField
                        name='kitchenLiving'
                        label='Кухня - гостиная'
                    />
                    <CheckboxField
                        name='masterBedroom'
                        label='Мастер - спальня'
                    />
                    <CheckboxField
                        name='underfloorHeatingSystem'
                        label='Теплый пол'
                    />
                    <CheckboxField
                        name='smartHouseSystem'
                        label='Система Умный дом'
                    />
                    <CheckboxField
                        name='sellerMinors'
                        label='Несовершеннолетние собственники'
                    />
                    <CheckboxField name='terrace' label='Терраса' />
                    <CheckboxField name='highflat' label='Хайфлет' />
                    <CheckboxField
                        name='viewApartment'
                        label='Видовая квартира'
                    />
                    <CheckboxField
                        name='isGroundParking'
                        label='Наземный паркинг'
                    />
                    <CheckboxField
                        name='isUndergroundParking'
                        label='Подземный паркинг'
                    />
                </Col>
                <Col>
                    <CheckboxField
                        name='lowRiseEstate'
                        label='Малоэтажный ЖК'
                    />
                    <CheckboxField name='isAreaCctv' label='Видеонаблюдение' />
                    <CheckboxField
                        name='isClosedArea'
                        label='Закрытая территория'
                    />
                    <CheckboxField
                        name='isProtectedArea'
                        label='Охраняемая территория'
                    />
                    <CheckboxField
                        name='isAreaSportGround'
                        label='Спортивная площадка'
                    />
                    <CheckboxField name='isSchoolBeside' label='Рядом школа' />
                    <CheckboxField
                        name='isPreschoolBeside'
                        label='Рядом д. сад'
                    />
                    <CheckboxField name='hasBesideMall' label='Рядом ТЦ' />
                    <CheckboxField name='hasBesideWater' label='Рядом водоем' />
                    <CheckboxField name='hasBesideForest' label='Рядом лес' />
                    <CheckboxField name='hasBesidePark' label='Рядом парк' />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Field
                        name='bankEncumbrance'
                        validate={composeValidators(price)}
                    >
                        {({ input, meta }) => (
                            <Form.Group className='mb-3'>
                                <Form.Label>Обременение банка</Form.Label>
                                <Form.Control
                                    {...input}
                                    type='text'
                                    size='sm'
                                    isInvalid={meta.error || meta.submitError}
                                    onChange={(e) =>
                                        form.mutators.setPriceValue(
                                            'bankEncumbrance',
                                            e.target.value,
                                        )
                                    }
                                    placeholder='Обременение банка'
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {meta.error || meta.submitError}
                                </Form.Control.Feedback>
                            </Form.Group>
                        )}
                    </Field>
                </Col>
                <Col>
                    <Field name='bankEncumbranceTitle'>
                        {({ input, meta }) => (
                            <Form.Group className='mb-3'>
                                <Form.Label>
                                    Наименование банка - обременителя
                                </Form.Label>
                                <Form.Control
                                    {...input}
                                    type='text'
                                    size='sm'
                                    isInvalid={meta.error || meta.submitError}
                                    placeholder='Наименование банка'
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {meta.error || meta.submitError}
                                </Form.Control.Feedback>
                            </Form.Group>
                        )}
                    </Field>
                </Col>
            </Row>
            <Row>
                <Col>
                    <CheckboxField
                        name='isUnderstatementNeed'
                        label='Занижение в ДКП'
                    />
                    <Condition when='isUnderstatementNeed' is={1}>
                        <InputField
                            label='Сумма'
                            validators={required}
                            name='understatementSum'
                            onChange={(e) =>
                                form.mutators.setPriceValue(
                                    'understatementSum',
                                    e.target.value,
                                )
                            }
                        />
                    </Condition>
                </Col>
            </Row>
        </React.Fragment>
    );
}
