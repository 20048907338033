import React from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';

import { Field } from 'react-final-form';

import {
    composeValidators,
    mustBeNumber,
    onlyLength,
    required,
} from '~/src/helpers/validators';

export default function UpdateSellerView(): JSX.Element {
    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Field name='sellerName' validate={required}>
                        {({ input, meta }) => (
                            <Form.Group className='mb-3'>
                                <Form.Label>Продавец</Form.Label>
                                <Form.Control
                                    {...input}
                                    type='text'
                                    size='sm'
                                    isInvalid={meta.error || meta.submitError}
                                    placeholder='Продавец'
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {meta.error || meta.submitError}
                                </Form.Control.Feedback>
                            </Form.Group>
                        )}
                    </Field>
                </Col>
                <Col>
                    <Field
                        name='sellerPhone'
                        validate={composeValidators(
                            required,
                            mustBeNumber,
                            onlyLength(10),
                        )}
                    >
                        {({ input, meta }) => (
                            <Form.Group className='mb-3'>
                                <Form.Label>
                                    Телефон продавца{' '}
                                    <small className='text-muted'>
                                        (10 цифр без 8)
                                    </small>
                                </Form.Label>
                                <Form.Control
                                    {...input}
                                    type='number'
                                    size='sm'
                                    isInvalid={meta.error || meta.submitError}
                                    placeholder='Телефон продавца'
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {meta.error || meta.submitError}
                                </Form.Control.Feedback>
                            </Form.Group>
                        )}
                    </Field>
                </Col>
            </Row>
        </React.Fragment>
    );
}
