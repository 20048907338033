import dateFormat from 'dateformat';
import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { FormattedMessage } from 'react-intl';
import FigureImage from 'react-bootstrap/FigureImage';
import phoneFormatter from '~/src/helpers/phoneFormatter';
import { IRSModel } from '~/src/store/realtySecondary/realtySecondarySlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button, Card, Col, Row } from 'react-bootstrap';
import Comments from '../../../Comment';
import cianLogo from '~/src/assets/img/cian.png';
import avitoLogo from '~/src/assets/img/avito.png';
import yandexLogo from '~/src/assets/img/yandex.png';
import domclickLogo from '~/src/assets/img/domclick.png';
import IListUpdateInfo from '../IListUpdateInfo';
import classNames from 'classnames';
import EncumbranceUnderstatementNeed from '../../CommonRealty/EncumbranceUnderstatementNeed';
import { Link } from 'react-router-dom';

export default function RSCardView({
    item,
    handleFavorite,
}: {
    item: IRSModel;
    handleFavorite: () => void;
}): JSX.Element {
    const [index, setIndex] = useState(0);
    const [isOpen, setOpen] = useState(false);

    const base = 'https://files.citidom.com/';
    console.log(item);
    console.log(item.passengerElevator > 0 || item.serviceElevator > 0);

    return (
        <React.Fragment>
            <Row>
                <Col xs={4}>
                    {item.photoCount > 0 ? (
                        <React.Fragment>
                            <Card>
                                <Card.Header>Фотографии</Card.Header>
                                <Card.Body style={{ position: 'relative' }}>
                                    <FigureImage
                                        src={`${base}${item.photos[0].name}`}
                                        onClick={() => setOpen(true)}
                                    />
                                    {isOpen && (
                                        <Lightbox
                                            mainSrc={`${base}${item.photos[index].name}`}
                                            nextSrc={`${base}${item.photos[(index + 1) % item.photos.length].name}`}
                                            prevSrc={`${base}${item.photos[(index + item.photos.length - 1) % item.photos.length].name}`}
                                            onCloseRequest={() => {
                                                setOpen(false);
                                                setIndex(0);
                                            }}
                                            onMovePrevRequest={() =>
                                                setIndex(
                                                    (index +
                                                        item.photos.length -
                                                        1) %
                                                    item.photos.length,
                                                )
                                            }
                                            onMoveNextRequest={() =>
                                                setIndex(
                                                    (index + 1) %
                                                    item.photos.length,
                                                )
                                            }
                                        />
                                    )}
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: '2rem',
                                            left: '2rem',
                                        }}
                                    >
                                        {item.favorite ? (
                                            <Button
                                                size='sm'
                                                variant='primary'
                                                title='Убрать из избранного'
                                                onClick={() => handleFavorite()}
                                            >
                                                <FontAwesomeIcon
                                                    icon={['fas', 'heart']}
                                                />
                                            </Button>
                                        ) : (
                                            <Button
                                                size='sm'
                                                variant='outline-primary'
                                                title='Добавить в избранное'
                                                onClick={() => handleFavorite()}
                                            >
                                                <FontAwesomeIcon
                                                    icon={['fas', 'heart']}
                                                />
                                            </Button>
                                        )}
                                    </div>
                                </Card.Body>
                            </Card>
                            <br></br>
                        </React.Fragment>
                    ) : null}
                    <Card>
                        <Card.Header>Информация</Card.Header>
                        <Card.Body>
                            <p>
                                Внесен:{' '}
                                {dateFormat(
                                    item.createdAt,
                                    'dd.mm.yyyy hh:MM:ss',
                                )}
                                <br />
                                {item.fullfiedFirstAt &&
                                    `Заполнены все поля: ${dateFormat(item.fullfiedFirstAt, 'dd.mm.yyyy hh:MM:ss')}`}
                                {item.fullfiedFirstAt && <br />}
                                <IListUpdateInfo item={item} />
                                {/* В базе еще {item.freshAgo > 1 ? `${item.freshAgo} дней` : `${item.freshAgoH} часов`} */}
                            </p>
                            {item.xmlfeedunload &&
                                item.xmlfeedunload.length > 0 && (
                                    <React.Fragment>
                                        {item.xmlfeedunload.map((feed) => {
                                            if (feed.enabled) {
                                                switch (feed.feed?.type) {
                                                    case 'avito':
                                                        return (
                                                            <img
                                                                src={avitoLogo}
                                                                width={16}
                                                                height={16}
                                                                title={
                                                                    feed.feed
                                                                        .title
                                                                }
                                                                style={{
                                                                    marginRight:
                                                                        '5px',
                                                                }}
                                                            />
                                                        );
                                                    case 'cian':
                                                        return (
                                                            <img
                                                                src={cianLogo}
                                                                width={16}
                                                                height={16}
                                                                title={
                                                                    feed.feed
                                                                        .title
                                                                }
                                                                style={{
                                                                    marginRight:
                                                                        '5px',
                                                                }}
                                                            />
                                                        );
                                                    case 'domclick':
                                                        return (
                                                            <img
                                                                src={
                                                                    domclickLogo
                                                                }
                                                                width={16}
                                                                height={16}
                                                                title={
                                                                    feed.feed
                                                                        .title
                                                                }
                                                                style={{
                                                                    marginRight:
                                                                        '5px',
                                                                }}
                                                            />
                                                        );
                                                    case 'yandex':
                                                        return (
                                                            <img
                                                                src={yandexLogo}
                                                                width={16}
                                                                height={16}
                                                                title={
                                                                    feed.feed
                                                                        .title
                                                                }
                                                                style={{
                                                                    marginRight:
                                                                        '5px',
                                                                }}
                                                            />
                                                        );
                                                }
                                            }
                                        })}
                                    </React.Fragment>
                                )}
                            {item?.videoUrl &&
                                <Link
                                    to={item?.videoUrl}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    className='btn btn-sm btn-success'
                                    title='Видео'
                                >
                                    <FontAwesomeIcon icon={['fas', 'video']} />{' '}
                                    Видео
                                </Link>
                            }
                        </Card.Body>
                    </Card>
                    <br></br>
                    <Card>
                        <Card.Header>Продавец</Card.Header>
                        <Card.Body>
                            {item.agent?.agencyInfo?.agency && (
                                <p>
                                    <strong>Агентство</strong>
                                    <br></br>
                                    <span>
                                        {item.agent?.agencyInfo?.agency.title}
                                    </span>
                                </p>
                            )}
                            <p>
                                <strong>
                                    {item?.agent?.role != 'agent'
                                        ? 'Собственник'
                                        : 'Агент'}
                                </strong>
                                <br></br>
                                <span>{item.agent?.fullname}</span>
                            </p>
                            <p>
                                <strong>Телефон</strong>
                                <br></br>
                                <span>
                                    {phoneFormatter(
                                        item.agent?.phone.toString(),
                                    )}
                                </span>
                            </p>
                        </Card.Body>
                    </Card>
                    <br></br>
                </Col>
                <Col xs={8}>
                    <Card>
                        <Card.Header>Основные характеристики</Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <p>
                                        <strong>ID</strong>
                                        <br />
                                        <span>{item.id}</span>
                                    </p>
                                    <p>
                                        {item.realtyType === 'secondary' ? (
                                            <strong>Вторичка</strong>
                                        ) : (
                                            <strong>
                                                Новостройка от дольщика
                                            </strong>
                                        )}
                                    </p>
                                    <p>
                                        <strong>Тип</strong>
                                        <br />
                                        <span>
                                            <FormattedMessage
                                                id={`realty_secondary_subtype_${item.realtySubtype}`}
                                            />
                                        </span>
                                    </p>
                                    {
                                        item?.housingEstate && (
                                            <p>{item?.housingEstate?.title}</p>
                                        )
                                    }
                                    {item?.addressDetail?.livingArea && (
                                        <p>
                                            <strong>Район</strong>
                                            <br />
                                            {item?.addressDetail?.livingArea}
                                        </p>
                                    )}
                                    <p>
                                        <strong>Адрес</strong>
                                        <br />
                                        <span>
                                            {item?.addressDetail?.shortAddress}
                                        </span>
                                    </p>
                                    <p>
                                        <strong>Этаж</strong>
                                        <br></br>
                                        <span>
                                            {item.storey} / {item.houseStoreys}
                                        </span>
                                    </p>
                                </Col>
                                <Col>
                                    <p>
                                        <strong>Материал стен</strong>
                                        <br></br>
                                        <span>
                                            <FormattedMessage
                                                id={`house_material_${item.houseMaterial}`}
                                            />
                                        </span>
                                    </p>
                                    {item.houseBuilt && (
                                        <p>
                                            {item.realtyType == 'primary' ? (
                                                <>
                                                    <strong>
                                                        Срок сдачи дома
                                                    </strong>
                                                    <br />
                                                    {`${item.houseBuiltQuarter} кв. ${item?.houseBuilt} г.`}
                                                </>
                                            ) : (
                                                <>
                                                    <strong>
                                                        Год постройки
                                                    </strong>
                                                    <br />
                                                    {`${item.houseBuilt} г.`}
                                                </>
                                            )}
                                            <br />
                                        </p>
                                    )}
                                    {item.passengerElevator > 0 ||
                                        item.serviceElevator > 0 ? (
                                        <p>
                                            <strong>Лифты</strong>
                                            <br></br>
                                            {item.passengerElevator > 0 ? (
                                                <React.Fragment>
                                                    <span>
                                                        Пассажирских:{' '}
                                                        {item.passengerElevator}
                                                    </span>
                                                    <br></br>
                                                </React.Fragment>
                                            ) : null}
                                            {item.serviceElevator > 0 ? (
                                                <span>
                                                    Грузовых:{' '}
                                                    {item.serviceElevator}
                                                </span>
                                            ) : null}
                                        </p>
                                    ) : null}
                                </Col>
                                <Col>
                                    <p>
                                        <strong>Кол-во комнат</strong>
                                        <br></br>
                                        <span>{item.rooms}</span>
                                    </p>
                                    <p>
                                        <strong>Общая площадь</strong>
                                        <br></br>
                                        <span>{item.spaceTotal}</span>
                                    </p>
                                    {item.spaceLiving && (
                                        <p>
                                            <strong>Жилая площадь</strong>
                                            <br></br>
                                            <span>{item.spaceLiving}</span>
                                        </p>
                                    )}
                                    <p>
                                        <strong>Площадь кухни</strong>
                                        <br></br>
                                        <span>{item.spaceKitchen}</span>
                                    </p>
                                    {item.ceiling && (
                                        <p>
                                            <strong>Высота потолков</strong>
                                            <br></br>
                                            <span>{item.ceiling}</span>
                                        </p>
                                    )}
                                    {+item.bathroomCombined > 0 ||
                                        +item.bathroomSeparated > 0 ? (
                                        <p>
                                            <strong>Санузел</strong>
                                            <br></br>
                                            {+item.bathroomCombined > 0 ? (
                                                <React.Fragment>
                                                    <span>
                                                        Совмещенных:{' '}
                                                        {item.bathroomCombined}
                                                    </span>
                                                    <br></br>
                                                </React.Fragment>
                                            ) : null}
                                            {+item.bathroomSeparated > 0 ? (
                                                <span>
                                                    Раздельных:{' '}
                                                    {item.bathroomSeparated}
                                                </span>
                                            ) : null}
                                        </p>
                                    ) : null}
                                    {item.balcony > 0 && (
                                        <p>
                                            <strong>Балкон: </strong>{' '}
                                            {item.balcony}
                                        </p>
                                    )}
                                    {item.loggia > 0 && (
                                        <p>
                                            <strong>Лоджия: </strong>{' '}
                                            {item.loggia}
                                        </p>
                                    )}
                                </Col>
                                <Col>
                                    <p>
                                        <strong>Цена</strong>
                                        <br></br>
                                        <span>
                                            {new Intl.NumberFormat(
                                                'ru-RU',
                                            ).format(+item.price)}
                                        </span>
                                        <br></br>
                                        <strong>
                                            Цена за м<sup>2</sup>
                                        </strong>
                                        <br></br>
                                        <span>
                                            {new Intl.NumberFormat(
                                                'ru-RU',
                                            ).format(
                                                Math.floor(
                                                    +item.price /
                                                    +item.spaceTotal,
                                                ),
                                            )}
                                        </span>
                                    </p>
                                    {item.roomType && (
                                        <p>
                                            <strong>Тип комнат</strong>
                                            <br></br>
                                            <span>
                                                <FormattedMessage
                                                    id={`realty_secondary_room_type_${item.roomType}`}
                                                />
                                            </span>
                                        </p>
                                    )}
                                    {item.renovation && (
                                        <p>
                                            <strong>Ремонт</strong>
                                            <br></br>
                                            <span>
                                                <FormattedMessage
                                                    id={`realty_secondary_renovation_${item.renovation}`}
                                                />
                                            </span>
                                        </p>
                                    )}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <br></br>
                    <Card>
                        <Card.Header>Дополнительные характеристики</Card.Header>
                        <Card.Body className='flex-wrap'>
                            {item.stainedGlass && (
                                <Badge className='me-1'>Витражные окна</Badge>
                            )}
                            {item.kitchenLiving && (
                                <Badge className='me-1'>Кухня - гостиная</Badge>
                            )}
                            {item.isGroundParking && (
                                <Badge className='me-1'>Наземный паркинг</Badge>
                            )}
                            {item.isUndergroundParking && (
                                <Badge className='me-1'>
                                    Подземный паркинг
                                </Badge>
                            )}
                            {item.sellerMinors > 0 && (
                                <Badge className='me-1'>
                                    Несовершеннолетние собственники
                                </Badge>
                            )}
                            {item.highflat > 0 && (
                                <Badge className='me-1'>Хайфлет</Badge>
                            )}
                            {item.isClosedArea && (
                                <Badge className='me-1'>
                                    Закрытая территория
                                </Badge>
                            )}
                            {item.isSchoolBeside && (
                                <Badge className='me-1'>Рядом школа</Badge>
                            )}
                            {item.isPreschoolBeside && (
                                <Badge className='me-1'>Рядом д. сад</Badge>
                            )}
                            {item.isAreaCctv && (
                                <Badge className='me-1'>Видеонаблюдение</Badge>
                            )}
                            {item.multilevel > 0 && (
                                <Badge className='me-1'>Многоуровневая</Badge>
                            )}
                            {item.terrace > 0 && (
                                <Badge className='me-1'>Терраса</Badge>
                            )}
                            {item.underfloorHeatingSystem && (
                                <Badge className='me-1'>Теплый пол</Badge>
                            )}
                            {item.masterBedroom && (
                                <Badge className='me-1'>Мастер - спальня</Badge>
                            )}
                            {item.viewApartment && (
                                <Badge className='me-1'>Видовая квартира</Badge>
                            )}
                            {item.soundproofing && (
                                <Badge className='me-1'>Шумоизоляция</Badge>
                            )}
                            {item.smartHouseSystem && (
                                <Badge className='me-1'>
                                    Система Умный дом
                                </Badge>
                            )}
                            {item.lowRiseEstate && (
                                <Badge className='me-1'>Малоэтажный ЖК</Badge>
                            )}
                            {item.isProtectedArea && (
                                <Badge className='me-1'>
                                    Охраняемая территория
                                </Badge>
                            )}
                            {item.isAreaSportGround && (
                                <Badge className='me-1'>
                                    Спортивная площадка
                                </Badge>
                            )}
                            {item.hasBesideMall && (
                                <Badge className='me-1'>Рядом ТЦ</Badge>
                            )}
                            {item.hasBesideWater && (
                                <Badge className='me-1'>Рядом водоем</Badge>
                            )}
                            {item.hasBesideForest && (
                                <Badge className='me-1'>Рядом лес</Badge>
                            )}
                            {item.hasBesidePark && (
                                <Badge className='me-1'>Рядом парк</Badge>
                            )}
                            {item.isExclusive && (
                                <Badge className='bg-success'>
                                    Эксклюзивный
                                </Badge>
                            )}
                            {item.isDiscount && (
                                <Badge className='bg-success'>Промо</Badge>
                            )}
                            <div className='d-flex gap-3 mt-3'>
                                <EncumbranceUnderstatementNeed item={item} />
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <br></br>
                    <Card>
                        <Card.Header>Описание</Card.Header>
                        <Card.Body>{item.description}</Card.Body>
                    </Card>
                    <br></br>
                    {item.isMy && (
                        <Card>
                            <Card.Header>Для себя</Card.Header>
                            <Card.Body>{item.privateNote}</Card.Body>
                        </Card>
                    )}
                    <Comments section='realty_secondary' entry={item.id} />
                </Col>
            </Row>
        </React.Fragment>
    );
}
