import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { BaseSyntheticEvent } from 'react';
import { ButtonGroup, Form, ToggleButton } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { IHousingEstateHouse } from '~/src/service/housing.estate.house';
import {
    getChessPorch,
    getInfoHousingEstateHouse,
    selectHE,
    setActivePorch,
    setNavPanel,
} from '~/src/store/housingEstateSlice';

const Title = ({ house }: { house: IHousingEstateHouse }) => {
    const _warning =
        house.countSaleFlatsWithoutLayout > 0 ? (
            <FontAwesomeIcon
                icon={['fas', 'exclamation-triangle']}
                title='Нет планировок'
            />
        ) : null;
    return (
        <>
            {_warning} {house.title} ({house.countSaleFlats})
        </>
    );
};

const HousesBtn = ({ houses }) => {
    const { navPanel } = useSelector(selectHE);
    const dispatch = useDispatch();

    const handleChangeValue = (e: BaseSyntheticEvent, radio: any) => {
        dispatch(
            setNavPanel({
                ...navPanel,
                [e.target.name]: Number(e.target.value),
                porches: Number(radio?.porches[0]?.id),
            }),
        );
        dispatch(setActivePorch(radio?.porches[0]));
        dispatch(getChessPorch(Number(radio?.porches[0]?.id)));
        dispatch(getInfoHousingEstateHouse(Number(e.target.value)));
    };

    return (
        <Form.Group>
            <ButtonGroup className='d-flex mb-3'>
                {houses.map((radio, idx) => (
                    <ToggleButton
                        key={idx}
                        id={`housesTab-btns-${idx}`}
                        type='checkbox'
                        variant={
                            navPanel.houses === radio.id
                                ? 'secondary'
                                : 'outline-secondary'
                        }
                        name='houses'
                        value={radio.id}
                        checked={navPanel.houses === radio.id}
                        onChange={(e) => handleChangeValue(e, radio)}
                    >
                        <Title house={radio} />
                    </ToggleButton>
                ))}
            </ButtonGroup>
        </Form.Group>
    );
};

export default HousesBtn;
