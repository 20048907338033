import Api from '~/src/helpers/http-common';
import { IHousingEstate } from './housing.estate';
import { IResponseListData, IUpdateData } from '../interfaces/common';
import { IHousingEstateHousePorch } from './housing.estate.house.porch';

export const all = async (
    queryParams?: URLSearchParams,
): Promise<IResponseListData<IHousingEstateHouse>> => {
    return await Api.get(
        `/housing-estate/house/?${queryParams != null ? queryParams.toString() : ''}`,
    );
};

export const unload = async (
    queryParams?: URLSearchParams,
): Promise<IHousingEstateHouseUnloaded[]> => {
    return await Api.get(
        `/housing-estate/house/unload?${queryParams != null ? queryParams.toString() : ''}`,
    );
};

export const create = async (
    data: IHousingEstateHouse,
    files?: Record<string, FileList>,
): Promise<IHousingEstateHouse | any> => {
    return (await Api.post<IHousingEstateHouse>(
        '/housing-estate/house/',
        data,
        files,
    )) as IHousingEstateHouse;
};

export const read = async (id: number): Promise<IHousingEstateHouse> => {
    return await Api.get(`/housing-estate/house/${id}`);
};

export const update = async (
    id: string | number,
    data: IHousingEstateHouse,
): Promise<IUpdateData<IHousingEstateHouse>> => {
    return await Api.put<IUpdateData<IHousingEstateHouse>>(
        `/housing-estate/house/${id}`,
        data,
    );
};

export const remove = async (
    id: string | number,
): Promise<IUpdateData<IHousingEstateHouse>> => {
    return await Api.delete(`/housing-estate/house/${id}`);
};

export interface IHousingEstateHouse extends IHousingEstateHouseUnloaded {
    housingEstate: IHousingEstate;
    code: string;
    material: string;
    latitude: number;
    longitude: number;
    startConstruction: Date | string;
    endConstruction: Date | string;
    description: string;
    year: number;
    quarter: number;

    countSaleFlats?: number;
    countSaleFlatsWithoutLayout?: number;

    porches?: IHousingEstateHousePorch[];

    createdAt?: Date;
    createdBy?: string;

    updatedAt?: Date;
    updatedBy?: string;
}

export interface IHousingEstateHouseUnloaded {
    id: number;
    title: string;
}
