import { useDispatch, useSelector } from 'react-redux';
import { ButtonGroup, Form, ToggleButton } from 'react-bootstrap';
import {
    selectCV,
    setActivePhaseAndLoadPlots,
} from '~/src/store/cottage.village/cottage.village.store';

const PhasesNav = () => {
    const dispatch = useDispatch();

    const { item, activePhase } = useSelector(selectCV);

    const handleChangeValue = (phase) => () => {
        dispatch(setActivePhaseAndLoadPlots(phase, new URLSearchParams()));
    };

    return (
        <Form.Group>
            <ButtonGroup className='d-flex mb-3'>
                {item.phases.map((phase, idx) => (
                    <ToggleButton
                        key={idx}
                        id={`phases-btns-${idx}`}
                        type='checkbox'
                        variant={
                            activePhase?.id == phase.id
                                ? 'secondary'
                                : 'outlined-secondary'
                        }
                        value={phase.id}
                        checked={activePhase?.id == phase.id}
                        onChange={handleChangeValue(phase)}
                    >
                        <span>{phase.title}</span>
                    </ToggleButton>
                ))}
            </ButtonGroup>
        </Form.Group>
    );
};

export { PhasesNav };
