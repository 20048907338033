import PhotoView from './PhotoView';
import { IFilestore } from '~/src/service/filestore';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import React from 'react';
import { useDispatch } from 'react-redux';

const SortablePhoto = SortableElement<TPhotoProps>((props: TPhotoProps) => {
    return (
        <div>
            <PhotoView {...props} />
        </div>
    );
});

const SortableGallery = SortableContainer<TPhotosProps>(
    ({ photos, onClick, onDelete, setCurrentIndex }) => {

        const dispatch = useDispatch();

        const handleClickPhoto = (index) => {
            dispatch(setCurrentIndex(index));
            onClick();
        };

        return (
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '10px',
                }}
            >
                {photos.map((photo, index) => (
                    <SortablePhoto
                        key={photo.name}
                        index={index}
                        photo={photo}
                        onClick={() => handleClickPhoto(index)}
                        onDelete={onDelete}
                    />
                ))}
            </div>
        );
    },
);

type TPhotosProps = {
    photos: IFilestore[];
    onClick: (index: number | string) => void;
    onDelete: (photoName: string) => void;
    setCurrentIndex: any;
};

type TPhotoProps = {
    photo: IFilestore;
    onClick: (index: number | string) => void;
    onDelete: (photoName: string) => void;
};

export default SortableGallery;
