import { useNavigate } from 'react-router-dom';
import { selectApp } from '~/src/store/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Form as FinalForm, Field } from 'react-final-form';
import { update, selectAgency } from '~/src/store/agencySlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createInitialFormValues from '~/src/helpers/createInitialFormValues';
import { Button, ButtonGroup, Form, Navbar, Col, Row } from 'react-bootstrap';
import {
    composeValidators,
    required,
    mustBeNumber,
    onlyLength,
} from '~/src/helpers/validators';
import UpdateLogo from './UpdateLogo';
import UpdateForm from './UpdateForm';

export default function UpdateComponent(): JSX.Element {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { item } = useSelector(selectAgency);
    const { loading } = useSelector(selectApp);

    const onSubmit = (values: Record<string, any>) => {
        dispatch(update(item.id, { ...values }));
    };

    if (loading || item === null) {
        return <></>;
    }

    console.log('render update agency');

    return (
        <FinalForm
            onSubmit={onSubmit}
            initialValues={{
                ...createInitialFormValues(item),
                address: item?.addressDetail?.formattedAddress,
            }}
            mutators={{
                // expect (field, value) args from the mutator
                setValue: ([field, value], state, { changeValue }) => {
                    changeValue(state, field, () => value);
                },
            }}
            validate={(values) => {
                const errors: any = {};
                if (!values.addressPlaceDetailId) {
                    errors.addressPlaceDetailId = 'Введите адрес';
                }
                return errors;
            }}
            render={({ handleSubmit, form, submitting, valid }) => (
                <form onSubmit={handleSubmit}>
                    <Navbar
                        className='justify-content-between'
                        bg='light'
                        expand='lg'
                        variant='light'
                    >
                        <ButtonGroup>
                            <Button
                                size='sm'
                                onClick={() => navigate(-1)}
                                disabled={submitting}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'fast-backward']}
                                />{' '}
                                Назад
                            </Button>
                            <Button
                                size='sm'
                                type='submit'
                                className='btn btn-sm btn-success'
                                disabled={submitting || !valid}
                            >
                                <FontAwesomeIcon icon={['fas', 'save']} />{' '}
                                Сохранить
                            </Button>
                        </ButtonGroup>
                    </Navbar>
                    <Row>
                        <Col className={'ace-align-self-center'}>
                            <UpdateLogo />
                        </Col>
                        <Col>
                            <UpdateForm />
                        </Col>
                    </Row>
                    <Button
                        size='sm'
                        type='submit'
                        className='btn btn-sm btn-success'
                        disabled={submitting || !valid}
                    >
                        <FontAwesomeIcon icon={['fas', 'save']} /> Сохранить
                    </Button>
                </form>
            )}
        />
    );
}
