// import { BASE_URL } from './api';
import { setupInterceptorsTo } from '~/src/interceptors/axios.interceptors';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

class Api {
    private axiosInstance: AxiosInstance;

    private prepareInstance = (contentType = 'application/json') => {
        this.axiosInstance = axios.create({
            baseURL: process.env.REACT_APP_BASE_API_URL,
            headers: {
                'Content-type': contentType,
            },
            withCredentials: true,
        });

        setupInterceptorsTo(this.axiosInstance);
    };

    public get = async (
        url: string,
        config?: AxiosRequestConfig,
    ): Promise<any> => {
        this.prepareInstance();
        const response = await this.axiosInstance.get(url, config);
        return response.data;
    };

    public post = async <T>(
        url: string,
        params?: Record<string, any>,
        files?: Record<string, Iterable<File>>,
        config?: AxiosRequestConfig,
    ): Promise<T> => {
        if (files === undefined) {
            this.prepareInstance();
            const response = await this.axiosInstance.post(url, params, {
                ...config,
            });

            return response.data;
        }

        this.prepareInstance('multipart/form-data');

        const formData = new FormData();

        for (const key in params) {
            if (Array.isArray(params[key])) {
                for (const part of params[key]) {
                    if (typeof part == 'object') {
                        formData.append(`${key}[]`, JSON.stringify(part));
                    } else formData.append(`${key}[]`, part);
                }
            } else formData.append(key, params[key]);
        }

        if (files != undefined) {
            for (const key of Object.keys(files)) {
                for (const k in files[key]) {
                    formData.append(key, files[key][k]);
                }
            }
        }

        const response = await this.axiosInstance.post(url, formData, config);

        return response.data;
    };

    public put = async <T>(
        url: string,
        params: Record<string, any>,
        files?: Record<string, FileList | File[]>,
        config?: AxiosRequestConfig,
    ): Promise<T> => {
        if (files === undefined) {
            this.prepareInstance();
            const response = await this.axiosInstance.put(url, params, config);

            return response.data as T;
        }

        this.prepareInstance('multipart/form-data');

        const formData = new FormData();

        for (const key in params) {
            if (Array.isArray(params[key])) {
                for (const part of params[key]) {
                    if (typeof part == 'object') {
                        formData.append(`${key}[]`, JSON.stringify(part));
                    } else formData.append(`${key}[]`, part);
                }
            } else formData.append(key, params[key]);
        }

        if (files != undefined) {
            for (const key of Object.keys(files)) {
                for (const k in files[key]) {
                    formData.append(key, files[key][k]);
                }
            }
        }

        const res = Array.from(formData.entries(), ([key, prop]) => ({
            [key]: {
                ContentLength:
                    typeof prop === 'string' ? prop.length : prop.size,
            },
        }));

        console.log('RES');
        console.log(res);

        const response = await this.axiosInstance.put<T>(url, formData, config);

        return response.data as T;
    };

    public delete = async <T>(url: string): Promise<T> => {
        this.prepareInstance();

        const response = await this.axiosInstance.delete(url);

        return response.data as T;
    };

    public uploadSingle = async <T>(url: string, file: File): Promise<T> => {
        console.dir(file);
        this.prepareInstance('multipart/form-data');

        const formData = new FormData();

        formData.append('file', file);

        const response = await this.axiosInstance.post(url, formData);

        return response.data;
    };

    public postMultipart = async (
        url: string,
        form: HTMLFormElement | any,
        files?: FileList,
        config?: AxiosRequestConfig,
    ): Promise<AxiosResponse> => {
        try {
            this.axiosInstance = axios.create({
                baseURL: process.env.REACT_APP_BASE_API_URL,
                headers: {
                    'Content-type': 'multipart/form-data',
                },
                withCredentials: true,
            });

            const formData = new FormData();

            for (const key in form) {
                formData.append(key, form[key]);
            }

            if (files != undefined) {
                for (const key of Object.keys(files)) {
                    formData.append('photosUpload', files[key]);
                }
            }

            const response = await this.axiosInstance.post(
                url,
                formData,
                config,
            );

            return response;
        } catch (error) {
            console.error(error);
            return null;
        }
    };
}

export default new Api();
