import { useDispatch, useSelector } from 'react-redux';

import { Nav, Tab } from 'react-bootstrap';
import ShowErrorsBadge from '../../Common/ShowErrorBadge';

import { MainForm } from './MainForm';
import { BasisForm } from './BasisFormView';
import { UpdateDHPPhotoView } from './UpdateDHPPhotoView';

import { selectDHP, update } from '~/src/store/developer/developer.house.project';
import { UpdateDHPContact } from './UpdateDHPContact';
import UpdateDHPPhotoViewReal from './UpdateDHPPhotoViewReal';

const HouseProjectUpdateView = () => {
    const dispatch = useDispatch();

    const { item } = useSelector(selectDHP);

    if (item == null) return <></>;

    const onSubmit = (values: Record<string, any>) => {
        dispatch(
            update(values.id, {
                ...values,
                price: values?.price?.toString().replaceAll(/\D/g, ''),
                priceDiscount: values?.priceDiscount?.toString().replaceAll(/\D/g, ''),
            }),
        );
    };

    return (
        <MainForm initial={item} onSubmit={onSubmit}>
            <Tab.Container defaultActiveKey={'basis'}>
                <Nav justify variant='tabs'>
                    <Nav.Item>
                        <Nav.Link eventKey='basis'>
                            <span>Основное </span>
                            <ShowErrorsBadge fields={[]} />
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey='photos'>Фото</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey='photosReal'>Реальные фото</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey='agent'>Контакты</Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content className='container-fluid mt-2 p-0'>
                    <Tab.Pane eventKey='basis'>
                        <BasisForm />
                    </Tab.Pane>
                    <Tab.Pane eventKey='photos'>
                        <UpdateDHPPhotoView id={item.id} />
                    </Tab.Pane>
                    <Tab.Pane eventKey='photosReal'>
                        <UpdateDHPPhotoViewReal id={item.id} />
                    </Tab.Pane>
                    <Tab.Pane eventKey='agent'>
                        <UpdateDHPContact/>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </MainForm>
    );
};

export { HouseProjectUpdateView };
