import { useState } from 'react';
import { useDispatch } from 'react-redux';
import React from 'react';
import download from '~/src/helpers/download';
import { FILES_BASE } from '~/src/constants/urls';
import { IFilestore } from '~/src/service/filestore';
import { Button, ButtonGroup } from 'react-bootstrap';
import FigureImage from 'react-bootstrap/esm/FigureImage';
import Confirm from '../../Common/ModalUI/AlertModals/Confirm';
import { deleteFile } from '~/src/store/realtySecondary/realtySecondarySlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Photo: React.FC<TPhotoProps> = ({ photos, photo, onClick }) => {
    const dispatch = useDispatch();
    const [isOpened, setIsOpened] = useState(false);

    const handleDeletePhoto = () => {
        dispatch(deleteFile(photo.entryIndex, photo.name));
        setIsOpened(false);
    };

    const text =
        photos.length === 3
            ? 'Количество фотографий сейчас: 3, после удаления объект будет отправлен на проверку. Удалить?'
            : 'Удалить?';

    return (
        <div className='float-start position-relative me-2'>
            <FigureImage
                width={200}
                height={112}
                style={{ height: 112 }}
                src={`${FILES_BASE}${photo.name}`}
                onClick={onClick}
            />
            <div
                className='position-absolute'
                style={{ bottom: '10px', right: '10px' }}
            >
                <ButtonGroup>
                    <Button
                        size='sm'
                        onClick={() =>
                            download(
                                `${FILES_BASE}${photo.name}`,
                                `${photo.name}`,
                            )
                        }
                    >
                        <FontAwesomeIcon icon={['fas', 'download']} />
                    </Button>
                    <Button
                        size='sm'
                        variant='danger'
                        onClick={() => setIsOpened(true)}
                    >
                        <FontAwesomeIcon icon={['fas', 'trash']} />
                    </Button>
                </ButtonGroup>
            </div>
            <Confirm
                isOpened={isOpened}
                text={text}
                onClose={() => setIsOpened(false)}
                primaryButtonText='Да'
                primaryButtonOnClick={handleDeletePhoto}
                secondaryButtonText='Отмена'
                secondaryButtonOnClick={() => setIsOpened(false)}
            />
        </div>
    );
};

type TPhotoProps = {
    photo: IFilestore;
    photos: IFilestore[];
    onClick: (index) => void;
};

export default Photo;
