import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '~/src/helpers/hooks';
import { all, getStatistics } from '~/src/store/buyer/buyer.list.slice';
import ListView from '~/src/components/Buyer/ListView';
import { setActiveMenuKey } from '~/src/store/appSlice';

const Page = (): JSX.Element => {
    const query = useQuery();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(all(query));
        dispatch(getStatistics(query));
    }, [dispatch, query]);

    useEffect(() => {
        dispatch(setActiveMenuKey('Buyer'));
    }, [dispatch]);

    return <ListView />;
};

export default Page;
