import { useSelector } from 'react-redux';

import UserRow from './UserRow';
import NavBarInfo from './NavBarInfo';
import Table from 'react-bootstrap/Table';
import Pagination from '~/src/components/Common/Pagination';
import { selectInfo } from '~/src/store/info.agency.users.slice';

const ListView = (): JSX.Element => {
    const { users: items } = useSelector(selectInfo);

    const handlePaginationClick = (page: number) => {
        // dispatch(goTo({ page }));
        console.log('goto page: ', page);
    };

    return (
        <div>
            <NavBarInfo />
            {items && items.pagination?.have && (
                <Pagination
                    pagination={items.pagination}
                    onClick={handlePaginationClick}
                />
            )}
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <td>#</td>
                        <td>ФИО</td>
                        <td>Контактные данные</td>
                        <td>Кол-во объектов</td>
                        <td>Управление</td>
                    </tr>
                </thead>
                <tbody>
                    {items && items.items && items.items.length > 0
                        ? items.items.map((item) => (
                              <UserRow key={item.id} item={item} />
                          ))
                        : null}
                </tbody>
            </Table>
            {items && items.pagination?.have && (
                <Pagination
                    pagination={items.pagination}
                    onClick={handlePaginationClick}
                />
            )}
        </div>
    );
};

export default ListView;
