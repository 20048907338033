import React, { ReactNode } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
    goTo,
    selectBuyerList,
    setSearch,
} from '~/src/store/buyer/buyer.list.slice';
import { TLane } from '~/src/store/buyer/types';
import { dateFormatterTrello } from './dateFormatter';

const TrelloBoard = ({
    children,
    provided,
    _board,
}: {
    children: ReactNode | undefined;
    provided: any;
    _board: TLane;
}) => {
    const { search, searchBoard } = useSelector(selectBuyerList);
    const dispatch = useDispatch();

    const handleLaneFooterClick = (laneId: string) => {
        if (laneId !== 'archive') {
            const newSearch = {
                ...search,
                controlDate: laneId,
                my: searchBoard.my,
                realtyType: searchBoard.realtyType,
            };
            dispatch(setSearch(newSearch));
            dispatch(goTo(newSearch));
        } else {
            const newSearch = {
                ...search,
                controlDate: null,
                status: 'archive',
                my: searchBoard.my,
                realtyType: searchBoard.realtyType,
            };
            dispatch(setSearch(newSearch));
            dispatch(goTo(newSearch));
        }
    };

    return (
        <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className='board'
        >
            <div className='headerBoard'>
                <div className='boardLabel'>
                    {_board.title}
                    <div className='boardLabelDate'>
                        {dateFormatterTrello(_board.date)}
                    </div>
                    <a
                        className='boardLink'
                        onClick={() => handleLaneFooterClick(_board.date)}
                    >
                        Перейти
                    </a>
                </div>
                <div>кол-во: {_board.count}</div>
            </div>
            <div className='scroll'>
                <div className='dropZone'>{children}</div>
            </div>
            <div className='cardHeaderButton'>
                {_board.count >= 1 && (
                    <Button
                        variant='link'
                        onClick={() => handleLaneFooterClick(_board.date)}
                    >
                        Открыть список
                    </Button>
                )}
            </div>
        </div>
    );
};

export default TrelloBoard;
