import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { selectApp } from '~/src/store/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import UpdateForm from '~/src/components/Agency/Agent/update';
import {
    getUnloadedAgencyAgentPermissions,
    read,
} from '~/src/store/agencyAgentsSlice';

const UpdatePage = (): JSX.Element => {
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();

    const { loading } = useSelector(selectApp);

    useEffect(() => {
        dispatch(read(+id));
    }, [id, dispatch]);

    useEffect(() => {
        dispatch(getUnloadedAgencyAgentPermissions(+id));
    }, [dispatch, id]);

    return (
        <React.Fragment>
            {loading ? <div>Загрузка...</div> : <UpdateForm />}
        </React.Fragment>
    );
};

export default UpdatePage;
