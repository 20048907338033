import { Spinner } from 'react-bootstrap';

import './index.css';

const Loading = (): JSX.Element => (
    <div className='loading'>
        <Spinner animation='border' variant='dark' />
    </div>
);

export default Loading;
