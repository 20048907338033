import React from 'react';
import { useEffect } from 'react';
import { selectApp } from '~/src/store/appSlice';
import { unloadSuburban } from '~/src/store/developer/developerSlice';
import { useDispatch, useSelector } from 'react-redux';
import { HouseProjectCreateView } from '~/src/components/Developer/HouseProject/create';

const CreateDHPPage = (): JSX.Element => {
    const dispatch = useDispatch();
    const { loading } = useSelector(selectApp);

    useEffect(() => {
        dispatch(unloadSuburban());
    }, [dispatch]);

    return (
        <React.Fragment>
            {loading ? <>...</> : <HouseProjectCreateView />}
        </React.Fragment>
    );
};

export { CreateDHPPage };
