import { Field } from 'react-final-form';

const Condition = ({ when, is, children }) => (
    <Field name={when} subscription={{ value: true }}>
        {({ input: { value, name } }) => {
            return value === is ? children : null;
        }}
    </Field>
);

export default Condition;
