import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup } from 'react-bootstrap';
import { IPermission } from '~/src/service/permission';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ListRow: FC<{ item: IPermission }> = ({ item }) => {
    console.log('Agency Permission item');
    console.dir(item);

    const handleDelete = () => {
        console.log(`delete permission with code ${item.code}`);
    };

    return (
        <React.Fragment>
            <tr>
                <td>
                    <p></p>
                </td>
                <td>
                    <p>{item.title}</p>
                </td>
                <td>{item.code}</td>
                <td>{item.group}</td>
                <td>
                    <ButtonGroup>
                        <Link
                            to={`/agency/permission/update/${item.code}`}
                            className='btn btn-sm btn-warning'
                            title='Редактировать'
                        >
                            <FontAwesomeIcon icon={['fas', 'pencil-alt']} />
                        </Link>
                        <Button
                            size='sm'
                            type='button'
                            variant='danger'
                            title='Удалить'
                            onClick={handleDelete}
                        >
                            <FontAwesomeIcon icon={['fas', 'trash']} />
                        </Button>
                    </ButtonGroup>
                </td>
            </tr>
        </React.Fragment>
    );
};

export default ListRow;
