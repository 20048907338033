import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import Comments from './Comments/Comments';
import ModalCommentsBuyer from '../Common/ModalUI/ModalCommentsBuyer';

const ModalComment = ({
    id,
    handleLeaveComment,
}: {
    id: number;
    handleLeaveComment: (content: string) => Promise<void>;
}) => {
    const [isOpenModalPopup, setIsOpenModalPopup] = useState(false);

    const SetIsCloseModalPopup = () => setIsOpenModalPopup(false);

    return (
        <>
            <Button
                size='sm'
                type='button'
                variant='success'
                onClick={() => setIsOpenModalPopup(true)}
            >
                <FontAwesomeIcon icon={['fas', 'eye']} /> Просмотреть все
            </Button>
            <ModalCommentsBuyer
                onClose={SetIsCloseModalPopup}
                isOpened={isOpenModalPopup}
                fullwidth={true}
                overflow={true}
                title='Комментарии'
                handleLeaveComment={handleLeaveComment}
            >
                <Comments entry={id} section={'buyer'} />
            </ModalCommentsBuyer>
        </>
    );
};

export default ModalComment;
