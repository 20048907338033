const download = (url: string, title?: string) => {
    fetch(url).then((response) => {
        response.blob().then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download =
                title || response.headers.get('Filename') || 'filename';
            a.click();
        });
    });
};

export default download;
