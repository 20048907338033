import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { Form as FinalForm, FormSpy } from 'react-final-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup, Card, Container, Navbar } from 'react-bootstrap';

import {
    composeValidators,
    price,
    regex,
    required,
    space,
} from '~/src/helpers/validators';
import {
    selectCV,
    setGenerateData,
} from '~/src/store/cottage.village/cottage.village.store';

import InputField from '../../Common/Form/InputField';
import CheckboxField from '../../Common/Form/CheckboxField';
import SimpleSelectFieldV2 from '~/src/components/Common/Form/SimpleSelectV2';

import { unloadByDeveloperId } from '~/src/store/developer/developer.house.project';
import createInitialFormValues from '~/src/helpers/createInitialFormValues';
import { suburbanCottageVillagePlotTypes } from '~/src/common/data/suburban.cottage.village.plot.type';

import { selectDHP } from '~/src/store/developer/developer.house.project';
import ReactSelectField from '../../Common/Form/ReactSelectField';

import { generatePhase } from '~/src/store/cottage.village/cottage.village.store';

const GenerateCottageVillagePhaseView = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { unloaded } = useSelector(selectDHP);

    const { activePhase, item, generateData } = useSelector(selectCV);
    const onSubmit = (values) => {
        console.log('onSubmit');

        dispatch(
            generatePhase(activePhase.id, {
                ...values,
                price: values.price.replaceAll(/\D/g, ''),
            }),
        );
    };

    const initial = generateData;

    const unloadedPrepared = unloaded?.map((u) => ({
        value: u.id,
        label: u.title,
    }));

    return (
        <>
            <p>
                Генерация участков для {activePhase.title} КП {item.title}
            </p>
            <FinalForm
                onSubmit={onSubmit}
                initialValues={{
                    ...createInitialFormValues(initial),
                    cottageVillageId: item.id,
                    cottageVillageConstructionPhaseId: activePhase.id,
                }}
                mutators={{
                    // expect (field, value) args from the mutator
                    setValue: ([field, value], state, { changeValue }) => {
                        changeValue(state, field, () => value);
                    },
                    setPriceValue: ([field, value], state, { changeValue }) => {
                        value = value.replaceAll(/\D/g, '');
                        changeValue(state, field, () =>
                            new Intl.NumberFormat('ru-RU').format(value),);
                    },
                    setDecimalValue: (
                        [field, value],
                        state,
                        { changeValue },
                    ) => {
                        value = value.replaceAll(',', '.');
                        changeValue(state, field, () => value);
                    },
                    setSelectValue: (
                        [field, value],
                        state,
                        { changeValue },
                    ) => {
                        changeValue(state, field, () => [
                            ...value.map((v) => v.value),
                        ]);
                    },
                    changePlotType: (
                        [field, value],
                        state,
                        { changeValue },
                    ) => {
                        changeValue(state, field, () => value);
                        changeValue(
                            state,
                            'developerHouseProjectId',
                            () => null,
                        );

                        if (value == 'with_construction_contract') {
                            changeValue(
                                state,
                                'showDeveloperContracts',
                                () => true,
                            );
                            dispatch(setGenerateData(state.formState.values));
                            dispatch(unloadByDeveloperId(item.developerId));
                        } else {
                            changeValue(
                                state,
                                'showDeveloperContracts',
                                () => false,
                            );
                        }
                    },
                }}
                validate={(values) => {
                    const errors: any = {};

                    if (
                        values.type === 'with_construction_contract' &&
                        values.developerHouseProjectId?.length == 0 &&
                        values.allProject != true
                    ) {
                        errors.developerHouseProjectId =
                            'Должен быть выбран хотя бы один проект либо флажок Все проекты';
                    }
                    return errors;
                }}
                render={({ handleSubmit, form, submitting, valid }) => (
                    <form onSubmit={handleSubmit}>
                        <Container fluid>
                            <Navbar
                                className='justify-content-between'
                                bg='light'
                                expand='lg'
                                variant='light'
                            >
                                <ButtonGroup>
                                    <Button
                                        size='sm'
                                        onClick={() => navigate(-1)}
                                    >
                                        <FontAwesomeIcon
                                            icon={['fas', 'fast-backward']}
                                        />{' '}
                                        Назад
                                    </Button>
                                    <Button
                                        type='submit'
                                        variant='success'
                                        size='sm'
                                        disabled={!valid || submitting}
                                    >
                                        Сгенерировать
                                    </Button>
                                </ButtonGroup>
                            </Navbar>
                            <Card.Text className='text-muted mb-2'>
                                Можно ввести номера через запятую (1, 2, 5, 10),
                                используя тире для промежутка (1-5, 7, 10,
                                13-15), либо нажать кнопку - «Все»
                            </Card.Text>
                        </Container>
                        <InputField
                            name='numbers'
                            label='Номера'
                            validators={composeValidators(
                                required,
                                regex(
                                    /(([1-9][0-9]*\s*-\s*[1-9][0-9]*)|([1-9][0-9]*))/g,
                                ),
                            )}
                        />
                        <CheckboxField
                            label='Обновить уже внесенные участки'
                            name='update'
                        />
                        <SimpleSelectFieldV2
                            name='type'
                            label='Тип'
                            onChange={(e) =>
                                form.mutators.changePlotType(
                                    'type',
                                    e.target.value,
                                )
                            }
                            validators={required}
                        >
                            <option></option>
                            {suburbanCottageVillagePlotTypes.map(
                                ({ value, title }) => (
                                    <option key={value} value={value}>
                                        {title}
                                    </option>
                                ),
                            )}
                        </SimpleSelectFieldV2>
                        <FormSpy subscription={{ values: true }}>
                            {({ values }) => {
                                return (
                                    <>
                                        {values.showDeveloperContracts ==
                                        true ? (
                                            <>
                                                <CheckboxField
                                                    label='Все проекты застройщика'
                                                    name='allProjects'
                                                />
                                                <ReactSelectField
                                                    label='Проекты'
                                                    name='developerHouseProjectId'
                                                    value={unloadedPrepared?.find(
                                                        (d) =>
                                                            form
                                                                .getState()
                                                                .values?.developerId?.includes(
                                                                    d.value,
                                                                ),
                                                    )}
                                                    onChange={(v) =>
                                                        form.mutators.setSelectValue(
                                                            'developerHouseProjectId',
                                                            v,
                                                        )
                                                    }
                                                    validators={required}
                                                    options={unloadedPrepared}
                                                    isMulti={true}
                                                />
                                            </>
                                        ) : null}
                                    </>
                                );
                            }}
                        </FormSpy>
                        <InputField
                            name='price'
                            label='Стоимость'
                            onChange={(e) =>
                                form.mutators.setPriceValue(
                                    'price',
                                    e.target.value,
                                )
                            }
                            validators={composeValidators(required, price)}
                        />
                        <InputField
                            name='area'
                            label='Площадь (в сотках)'
                            validators={composeValidators(required, space)}
                        />
                        <CheckboxField label='Продан' name='sold' />
                    </form>
                )}
            />
        </>
    );
};

export { GenerateCottageVillagePhaseView };
