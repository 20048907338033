import { useDispatch } from 'react-redux';
import { all, clear } from '~/src/store/comment';
import React, { useEffect } from 'react';
import CommentView from './views';

type TProps = {
    entry: number;
    section: string;
};

const Comments = ({ section, entry }: TProps): JSX.Element => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(all(section, entry));

        () => dispatch(clear());
    });

    return <CommentView />;
};

export default Comments;
