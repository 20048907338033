import queryString from 'query-string';
import * as api from '~/src/service/developer/developer.house.project';
import { createSlice, Slice } from '@reduxjs/toolkit';
import { IResponseListData } from '../../interfaces/common';
import { redirect, setLoading as setAppLoading } from '../appSlice';
import type { TAppDispatch, TState, TAnyAction } from '~/src/store';
import { IFilestore } from '../../service/filestore';
import { successNotify } from '../../helpers/createNotify';
import { UploadProps } from '../../service/housing.estate';
import { title } from 'process';
import { ICottageVillage } from '~/src/service/cottageVillage/cottage.village.service';
import { read as readCVService } from '~/src/service/cottageVillage/cottage.village.service';
import { convertStringToNumber } from '~/src/helpers/convertStringToNumberResponse';

export const sliceName = 'developer.house.project';

interface IDeveloperHouseProjectSlice {
    items: IResponseListData<api.IDeveloperHouseProject>;
    item?: api.IDeveloperHouseProject;
    itemPhotos?: IFilestore[];
    itemPhotosReal?: IFilestore[];
    creating?: api.IDeveloperHouseProject;
    search?: any;
    errors?: any;
    cv?: ICottageVillage;
    cvID?: ICottageVillage;
    unloaded?: { id: number; title: string }[];
    baseLink: string;
    navLink: string;
}

const initialState = {
    items: {},
    item: null,
    itemPhotos: [],
    itemPhotosReal: [],
    creating: null,
    search: {
        sort: 'id_desc',
        limit: 25,
        page: 1,
    },
    cv: null,
    cvID: null,
    unloaded: [],
    loading: true,
    navLink: '/developer-house-project?sort=id_desc&limit=25&page=1',
    baseLink: '/developer-house-project',
};

const developerHouseProjectSlice: Slice<IDeveloperHouseProjectSlice> =
    createSlice({
        name: sliceName,
        initialState: initialState,
        reducers: {
            setAll: (
                state: IDeveloperHouseProjectSlice,
                action: TAnyAction<
                    IResponseListData<api.IDeveloperHouseProject>
                >,
            ) => {
                state.items = action.payload;
            },
            set: (
                state: IDeveloperHouseProjectSlice,
                action: TAnyAction<api.IDeveloperHouseProject>,
            ) => {
                state.item = action.payload;
                state.itemPhotos = state.item.photos;
                state.itemPhotosReal = state.item.realPhotos;
            },
            setPhotos: (
                state: IDeveloperHouseProjectSlice,
                action: TAnyAction<IFilestore[]>,
            ) => {
                state.itemPhotos = action.payload;
            },
            setPhotosReal: (
                state: IDeveloperHouseProjectSlice,
                action: TAnyAction<IFilestore[]>,
            ) => {
                state.itemPhotosReal = action.payload;
            },
            setErrors: (
                state: IDeveloperHouseProjectSlice,
                action: TAnyAction<any>,
            ) => {
                state.errors = action.payload;
            },
            setSearch: (
                state: IDeveloperHouseProjectSlice,
                action: TAnyAction<any>,
            ) => {
                state.search = action.payload;
            },
            setUnload: (
                state: IDeveloperHouseProjectSlice,
                action: TAnyAction<any>,
            ) => {
                state.unloaded = action.payload;
            },
            setCV: (state: IDeveloperHouseProjectSlice, action: TAnyAction<any>) => {
                state.cv = action.payload;
            },
            setCVId: (state: IDeveloperHouseProjectSlice, action: TAnyAction<any>) => {
                state.cvID = action.payload;
            },
            setCreating: (
                state: IDeveloperHouseProjectSlice,
                action: TAnyAction<api.IDeveloperHouseProject>,
            ) => {
                state.creating = action.payload;
            },
            setNavLink: (
                state: IDeveloperHouseProjectSlice,
                action: TAnyAction<string>,
            ) => {
                state.navLink = action.payload;
            },
        },
    });

export const {
    setAll,
    set,
    setErrors,
    setSearch,
    setUnload,
    setCreating,
    setPhotos,
    setPhotosReal,
    setCV,
    setCVId,
    setNavLink,
} = developerHouseProjectSlice.actions;

export const unload = () => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(setAppLoading(true));
        const response = await api.unload();
        dispatch(setUnload(response));
        dispatch(setAppLoading(false));
    };
};

export const unloadByDeveloperId = (id: number) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(setAppLoading(true));
        const query = new URLSearchParams({ developerId: `${id}` });
        const response = await api.unload(query);
        dispatch(setUnload(response));
        dispatch(setAppLoading(false));
    };
};

export const all = (queryParams: URLSearchParams = null) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(setAppLoading(true));
        const response = await api.all(queryParams);

        const convertedSearch = convertStringToNumber(response.search);

        dispatch(setSearch({
            ...convertedSearch,
            sort: queryParams.get('sort') || 'id_desc'
        }));
        dispatch(
            setNavLink(`${initialState.baseLink}?${queryParams.toString()}`),
        );
        dispatch(setAll(response));
        dispatch(setAppLoading(false));
    };
};

export const create = (data: any, files?: any) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            dispatch(setCreating(data));
            const item = await api.create(data, files);
            if (item && item.errors) {
                dispatch(setErrors(item.errors));
            } else if (item) {
                dispatch(setCreating(initialState.creating));
                dispatch(redirect(getState()[sliceName].baseLink));
                dispatch(redirect(`/developer-house-project/update/${item.id}`));
            }
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};
export const createExclusive = (data: any, files?: any, cvId?: number) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            dispatch(setCreating(data));
            const item = await api.create(data, files);
            if (item && item.errors) {
                dispatch(setErrors(item.errors));
            } else if (item) {
                dispatch(setCreating(initialState.creating));
                const actionCV = await api.actionHouseProjectCottageVillage({
                    cottageVillageId: cvId,
                    developerHouseProjectId: item.id
                });
                if (actionCV) {
                    dispatch(redirect(getState()[sliceName].baseLink));
                    dispatch(redirect(`/developer-house-project/update/${item.id}`));
                }
            }
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};
export const read = (id: number) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const data = await api.read(id);
            dispatch(set(data));
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const readCV = (id: number) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const data = await readCVService(id);
            dispatch(setCV(data));
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const update = (
    id: number,
    data: any,
    files?: Record<string, FileList>,
) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const response = await api.update(id, data, files);
            if (response && response.success) {
                dispatch(set(response.data));
            } else {
                dispatch(setErrors(response.errors));
            }
        } catch (e) {
            console.error(e);
            dispatch(setErrors(e));
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const remove = (id: number) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const response = await api.remove(id);
            if (response && response.success) {
                dispatch(
                    setAll({
                        ...getState()[sliceName].items,
                        items: getState()[sliceName].items.items.filter(
                            (item: api.IDeveloperHouseProject) => item.id != id,
                        ),
                    }),
                );
            } else {
                dispatch(setErrors(response.errors));
            }
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const uploadPhoto = ({ id, files, entryGroup, onUploadProgress }: UploadProps) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        const response = await api.uploadPhotos(id, files, entryGroup, {
            onUploadProgress: onUploadProgress,
        });

        if (response && Array.isArray(response)) {
            if (getState()[sliceName].item.id === id) {
                if (response.find(_res => _res.entryGroup == 'developer_house_project_real')) {
                    dispatch(
                        setPhotosReal([
                            ...getState()[sliceName].itemPhotosReal,
                            ...response,
                        ]),
                    );
                } else if (response.find(_res => _res.entryGroup == 'developer_house_project')) {
                    dispatch(
                        setPhotos([
                            ...getState()[sliceName].itemPhotos,
                            ...response,
                        ]),
                    );
                }

            }

            successNotify({
                message: `Загружено ${response.length} фото`,
                title: 'Успешно!',
            });
        }
    };
};

export const deleteFile = (id: number, name: string, field: string) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        const response = await api.deleteFile(id, name);
        if (response) {
            dispatch(
                set({
                    ...getState()[sliceName].item,
                    [field]: getState()[sliceName].item[field].filter(
                        (photo: IFilestore) => photo.name !== name,
                    ),
                }),
            );
        } else {
            dispatch(setErrors(response.errors));
        }
    };
};


// Пока оставить, в дальнейшем может понадобится
// export const doesObjectExist = (arr: api.IcottageVillages[], id: number): boolean => {
//     return arr.some(obj => obj.id === id);
// };
// const removeObjectById = (arr: api.IcottageVillages[], id: number): api.IcottageVillages[] => {
//     return arr.filter(obj => obj.id !== id);
// };

// export const actionHouseProjectCottageVillage = (idHP: number, idCV: number, titleCV: string) => {
//     return async (
//         dispatch: TAppDispatch,
//         getState: () => TState,
//     ): Promise<void> => {
//         const response = await api.actionHouseProjectCottageVillage({
//             cottageVillageId: idCV,
//             developerHouseProjectId: idHP
//         });
//         if (response && response.success) {
//             dispatch(
//                 setAll({
//                     ...getState()['developer.house.project'].items,
//                     items: getState()['developer.house.project'].items.items.map(
//                         (item: api.IDeveloperHouseProject) => {
//                             if (item.id === idHP) {
//                                 if (doesObjectExist(item.cottageVillages, idCV)) {
//                                     return {
//                                         ...item,
//                                         cottageVillages: removeObjectById(item.cottageVillages, idCV)
//                                     };
//                                 } else {
//                                     return {
//                                         ...item,
//                                         cottageVillages: [
//                                             ...item.cottageVillages,
//                                             {
//                                                 id: idCV,
//                                                 title: titleCV,
//                                             }
//                                         ]
//                                     };
//                                 }
//                             }
//                             return item;
//                         },
//                     ),
//                 }),
//             );


//         } else {
//             response.errors ? dispatch(setErrors(response.errors)) : dispatch(setErrors(response.message));
//         }
//     };
// };

export const goTo = (params: Record<string, any>) =>
    (dispatch: TAppDispatch, getState: () => TState): void => {
        if (params.reset === true) {
            params = initialState.search;
        } else params = { ...getState()[sliceName].search, ...params };

        dispatch(
            redirect(
                `${initialState.baseLink}?${queryString.stringify(params, { skipNull: true })}`,
            ),
        );
    };

export const selectDHP = (state: TState): IDeveloperHouseProjectSlice =>
    state[sliceName];

export default developerHouseProjectSlice.reducer;
