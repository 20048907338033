import dateFormat from 'dateformat';
import React, { useState } from 'react';
import Stack from 'react-bootstrap/Stack';
import HaveAccess from '../../../Common/HaveAccess';
import { FormattedMessage } from 'react-intl';
import { IRSUBModel } from '~/src/store/realtySuburban/realtySuburbanSlice';
import OverlayingPopup from '../../../Common/ModalUI/OverlayingPopup';
import { navigate } from '~/src/service/realty.secondary.service';
import classNames from 'classnames';

type TProps = {
    item: IRSUBModel;
};

export default function IListUpdateInfo({ item }: TProps): JSX.Element {
    const [isOpened, setIsOpened] = useState(false);
    const [data, setData] = useState(null);

    const open = () => setIsOpened(true);
    const close = () => setIsOpened(false);

    const getnavigate = async () => {
        const _d = await navigate(item.id);
        setData(_d);
        open();
    };

    const _default = (
        <>
            Обновлен:{' '}
            {dateFormat(
                item.freshAt || item.updatedAt || item.createdAt,
                'dd.mm.yyyy',
            )}
            <br />
        </>
    );

    return (
        <HaveAccess
            access='realty.secondary.navigate'
            roles='grand'
            _default={_default}
        >
            <>
                <span
                    className='btn-link'
                    style={{ cursor: 'pointer' }}
                    onClick={getnavigate}
                >
                    {_default}
                </span>
                <OverlayingPopup isOpened={isOpened} onClose={close}>
                    <div
                        style={{
                            overflow: 'auto',
                            height: 'auto',
                            maxHeight: '80%',
                            maxWidth: '80%',
                            backgroundColor: 'white',
                            position: 'absolute',
                            padding: '10px 10px 0',
                        }}
                    >
                        <p>История изменений</p>
                        <Stack>
                            {data &&
                                data.map((d) => {
                                    return (
                                        <div
                                            className={classNames([
                                                'mb-1',
                                                'p-2',
                                                'border',
                                                'border-1',
                                                'border-info',
                                                'bg-light-blue',
                                            ])}
                                            key={d.id}
                                        >
                                            <p>
                                                <FormattedMessage
                                                    id={`realty_secondary_${d.type}`}
                                                />
                                                :{' '}
                                                {dateFormat(
                                                    d.createdAt,
                                                    'dd.mm.yyyy',
                                                )}
                                            </p>
                                            {d.prevDump &&
                                                Object.keys(d.prevDump).map(
                                                    (keyName, i) => (
                                                        <li key={i}>
                                                            <strong>
                                                                <u>
                                                                    <FormattedMessage
                                                                        id={`realty_secondary_${keyName}`}
                                                                    />
                                                                </u>
                                                            </strong>
                                                            :{' '}
                                                            <span>
                                                                {
                                                                    d.prevDump[
                                                                        keyName
                                                                    ]
                                                                }
                                                            </span>
                                                        </li>
                                                    ),
                                                )}
                                        </div>
                                    );
                                })}
                        </Stack>
                    </div>
                </OverlayingPopup>
            </>
        </HaveAccess>
    );
}
