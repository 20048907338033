import { Form } from 'react-bootstrap';
import { Field } from 'react-final-form';

export default function Error({ name }: { name: string }) {
    return (
        <Field name={name}>
            {({ meta }) => (
                <Form.Group>
                    <Form.Control.Feedback
                        type='invalid'
                        style={{
                            display:
                                meta.error || meta.submitError
                                    ? 'block'
                                    : 'none',
                        }}
                    >
                        {meta.error || meta.submitError}
                    </Form.Control.Feedback>
                </Form.Group>
            )}
        </Field>
    );
}
