import React, { ChangeEventHandler } from 'react';
import Form from 'react-bootstrap/Form';

type TProps = {
    checked: boolean;
    onChange: ChangeEventHandler | undefined;
    name: string;
    label: string;
    value?: string | number | string[];
};

export default function Checkbox({
    checked,
    onChange,
    name,
    label,
    value,
}: TProps): JSX.Element {
    return (
        <Form.Group>
            <Form.Check type='switch'>
                <Form.Check.Label>{label}</Form.Check.Label>
                <Form.Check.Input
                    type='checkbox'
                    name={name}
                    value={value || '1'}
                    checked={checked}
                    onChange={onChange}
                />
            </Form.Check>
        </Form.Group>
    );
}
