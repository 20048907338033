import * as api from '~/src/service/auth';
import { IRegistrationParams } from '~/src/service/auth';
import { IGroupMy, uploadGroupData } from '~/src/service/user';
import { IUser } from '~/src/service/user';
import { createSlice, Slice } from '@reduxjs/toolkit';
import type { TAppDispatch, TState, TAnyAction } from '~/src/store';
import { redirect, setLoading as setAppLoading } from '~/src/store/appSlice';
import { setNavLink } from './realtySecondary/realtySecondarySlice';

export const sliceName = 'auth';

interface IAuthSlice {
    me?: IUser;
    meGroup?: IGroupMy;
    errors?: any;
    loading: boolean;
}

const authSlice: Slice<IAuthSlice> = createSlice({
    name: sliceName,
    initialState: {
        loading: true,
    },
    reducers: {
        set: (state: IAuthSlice, action: TAnyAction<IUser>) => {
            state.me = action.payload;
        },
        setGroup: (state: IAuthSlice, action: TAnyAction<IGroupMy>) => {
            state.meGroup = action.payload;
        },
        setErrors: (state: IAuthSlice, action: TAnyAction<any>) => {
            state.errors = action.payload;
        },
        setLoading: (state: IAuthSlice, action: TAnyAction<boolean>) => {
            state.loading = action.payload;
        },
    },
});

const { set, setErrors, setGroup } = authSlice.actions;

export const getMe = (r?: string) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(setAppLoading(true));
        try {
            const user = await api.getMe();
            const userGroup = await uploadGroupData();
            dispatch(set(user));
            dispatch(setGroup(userGroup));
            r !== undefined && dispatch(redirect(r));
        } catch (e) {
            dispatch(redirect('/login'));
        }
        dispatch(setAppLoading(false));
    };
};

export const login = (phone: string | number, password: string) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(setAppLoading(true));
        try {
            const user = await api.login(phone, password);
            if (user && user?.role !== 'user') {
                dispatch(set(user));
                dispatch(setNavLink('/realty-secondary'));
                const userGet = await api.getMe();
                userGet?.agencyInfo
                    ? dispatch(
                          redirect(
                              '/realty-secondary?limit=25&own=my_agency&page=1&sort=price_asc',
                          ),
                      )
                    : dispatch(
                          redirect(
                              '/realty-secondary?limit=25&own=all&page=1&sort=price_asc',
                          ),
                      );
            } else {
                dispatch(logout());
            }
        } catch (e) {
            dispatch(setErrors(e));
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const logout = () => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(setAppLoading(true));
        try {
            await api.logout();
            dispatch(set(null));
            dispatch(redirect('/login'));
        } catch (e) {
            console.error(e);
        }
        dispatch(setAppLoading(false));
    };
};

export const registration = (params: IRegistrationParams) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        const user = await api.registration(params);

        console.log('registration');
        console.dir(user);
        dispatch(set(user));
        if (user.token) {
            window.location.replace('/');
        }
    };
};

export const forgotPassword = (phone: string) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(setAppLoading(true));
        try {
            const res = await api.forgotPassword(phone);
            return res;
        } catch (e) {
            console.error(e);
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const confirmCode = (phone: string, confirmCode: string) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(setAppLoading(true));
        try {
            const res = await api.confirmCode(phone, confirmCode);
            return res;
        } catch (e) {
            console.error(e);
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const selectAuth = (state: TState): IAuthSlice => state.auth;

export default authSlice.reducer;
