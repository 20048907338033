import queryString from 'query-string';
import * as agencyApi from '~/src/service/agency';
import * as userApi from '~/src/service/user';
import { createSlice, Slice } from '@reduxjs/toolkit';
import { IResponseListData } from '../interfaces/common';
import { IAgency } from '~/src/service/agency';
import { redirect, setLoading as setAppLoading } from './appSlice';
import type { TAppDispatch, TState, TAnyAction } from '~/src/store';
import { IUser } from '../service/user';

export const sliceName = 'info';

interface IInfoSlice {
    agency: IResponseListData<IAgency>;
    agents: IResponseListData<IUser>;
    users: IResponseListData<IUser>;
    unverified: IResponseListData<IUser>;
    active: string;
    unverifiedCount: number;
}

const initialState = {
    agency: {},
    agents: {},
    users: {},
    unverified: {},
    active: 'unverified',
    loading: true,
    logoLoading: false,
    unverifiedCount: 0,
};

const infoSlice: Slice<IInfoSlice> = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        setAgencyPayload: (
            state: IInfoSlice,
            action: TAnyAction<IResponseListData<IAgency>>,
        ) => {
            state.agency = action.payload;
        },
        setAgentsPayload: (
            state: IInfoSlice,
            action: TAnyAction<IResponseListData<IUser>>,
        ) => {
            state.agents = action.payload;
        },
        setUsersPayload: (
            state: IInfoSlice,
            action: TAnyAction<IResponseListData<IUser>>,
        ) => {
            state.users = action.payload;
        },
        setUnverifiedPayload: (
            state: IInfoSlice,
            action: TAnyAction<IResponseListData<IUser>>,
        ) => {
            state.unverified = action.payload;
        },
        setUnverifiedCount: (state: IInfoSlice, action: TAnyAction<number>) => {
            state.unverifiedCount = action.payload;
        },
        setActive: (state: IInfoSlice, action: TAnyAction<string>) => {
            state.active = action.payload;
        },
    },
});

const {
    setAgencyPayload,
    setAgentsPayload,
    setUsersPayload,
    setUnverifiedPayload,
    setUnverifiedCount,
    setActive,
} = infoSlice.actions;

export const getAgency = (queryParams: URLSearchParams = null) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(setAppLoading(true));
        const agency = await agencyApi.all(queryParams);

        dispatch(setAgencyPayload(agency));
        dispatch(setActive('agency'));

        dispatch(setAppLoading(false));
    };
};

export const getAgentsSingle = (queryParams: URLSearchParams = null) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(setAppLoading(true));
        const res = await userApi.getAgentsSingle(queryParams);

        dispatch(setAgentsPayload(res));
        dispatch(setActive('agents'));

        dispatch(setAppLoading(false));
    };
};

export const getUsers = (queryParams: URLSearchParams = null) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(setAppLoading(true));
        const res = await userApi.getUsers(queryParams);

        dispatch(setUsersPayload(res));
        dispatch(setActive('users'));

        dispatch(setAppLoading(false));
    };
};

export const getUnverified = (queryParams: URLSearchParams = null) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(setAppLoading(true));
        const res =
            await userApi.getUnverified<IResponseListData<IUser>>(queryParams);

        dispatch(setUnverifiedPayload(res));
        dispatch(setUnverifiedCount(res.count));
        dispatch(setActive('unverifiedAgents'));

        dispatch(setAppLoading(false));
    };
};

export const getUnverifiedCount = () => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        const queryParams = new URLSearchParams();
        queryParams.set('onlyCount', '1');
        const res = await userApi.getUnverified<number>(queryParams);
        dispatch(setUnverifiedCount(res));
    };
};

export const verifyRole = (id: number) => {
    return async (dispatch: TAppDispatch, getState: () => TState) => {
        try {
            await userApi.verifyRole(id);

            dispatch(
                setUnverifiedPayload({
                    ...getState().info.unverified,
                    items: [
                        ...getState().info.unverified.items.filter(
                            (item) => item.id != id,
                        ),
                    ],
                }),
            );
        } catch (e) {
            console.error(e);
        }
    };
};

export const remove = (id: number) => {
    return async (dispatch: TAppDispatch, getState: () => TState) => {
        try {
            await userApi.removeUser(id);

            dispatch(
                setUnverifiedPayload({
                    ...getState().info.unverified,
                    items: [
                        ...getState().info.unverified.items.filter(
                            (item) => item.id != id,
                        ),
                    ],
                }),
            );
        } catch (e) {
            console.error(e);
        }
    };
};

export const goTo =
    (params: Record<string, any>) =>
    (dispatch: TAppDispatch): void => {
        const urlParams = new URLSearchParams(window.location.search);
        const _params = Object.fromEntries(urlParams.entries());
        dispatch(
            redirect(
                `${window.location.pathname}?${queryString.stringify({ ..._params, ...params }, { skipNull: true })}`,
            ),
        );
    };

export const selectInfo = (state: TState): IInfoSlice => state.info;

export default infoSlice.reducer;
