import { useState, Suspense } from 'react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { selectApp } from '~/src/store/appSlice';
import { selectAuth } from '~/src/store/authSlice';
import UpdateAddressView from './UpdateAddressView';
import { useDebouncedCallback } from 'use-debounce';
import { useDispatch, useSelector } from 'react-redux';
import { update } from '~/src/store/realtySuburban/realtySuburbanSlice';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { checkDouble } from '~/src/service/realty.suburban';
import { Button, ButtonGroup, Nav, Navbar, Tab } from 'react-bootstrap';
import ShowErrorsBadge from '../../../Common/ShowErrorBadge';
import { selectSUB } from '~/src/store/realtySuburban/realtySuburbanSlice';
import UpdateFlatParamsView from './UpdateFlatParamsView';
import UpdateSellerView from './UpdateSellerView';
import UpdatePhotosView from './UpdatePhotos/UpdatePhotosView';
import UpdateAdditionalView from './UpdateAdditionalView';
import { createCn } from '~/src/helpers';
import { CN_REGEX_VALIDATE } from '~/src/constants';
import { selectDirection } from '~/src/store/directionSlice';

export default function RSUBUpdateForm(): JSX.Element {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [holdSubmit, setHoldSubmit] = useState(false);
    const [isDouble, setIsDouble] = useState(false);
    const [doubleId, setDoubleId] = useState(null);
    const { item } = useSelector(selectSUB);
    const { loading } = useSelector(selectApp);
    const { me } = useSelector(selectAuth);
    const { unloadItems } = useSelector(selectDirection);

    const onSubmit = (values: Record<string, any>) => {
        dispatch(
            update(+values?.id, {
                ...values,
                price: values?.price.replaceAll(/\D/g, ''),
            }),
        );
    };

    const debounced = useDebouncedCallback(
        async (addressPlaceDetailId, cn, userUid, id) => {
            if (id && cn) {
                const res = await checkDouble({ cn, userUid, id });
                if (res) {
                    setIsDouble(res.double);
                    setDoubleId(res.id);
                }
            }
            setHoldSubmit(false);
        },
        500,
    );
    return (
        <Suspense fallback={<div>Loading...</div>}>
            {loading || item === null || item === undefined ? (
                <div>Загрузка...</div>
            ) : (
                <FinalForm
                    onSubmit={onSubmit}
                    subscription={{
                        submitting: true,
                        pristine: true,
                        valid: true,
                        submitSucceeded: true,
                        values: true,
                    }}
                    initialValues={{
                        ...Object.keys(item)
                            .filter((k) => item[k] != null)
                            .reduce((a, k) => ({ ...a, [k]: item[k] }), {}),
                        price: new Intl.NumberFormat('ru-RU').format(
                            +item.price,
                        ),
                        address: item.addressDetail?.formattedAddress,
                        addressPlaceDetailId: item.addressDetail?.id,
                        isUnderstatementNeed:
                            item?.isUnderstatementNeed == true ? 1 : 0,
                        bankEncumbrance: new Intl.NumberFormat('ru-RU').format(
                            +item?.bankEncumbrance,
                        ),
                        latitude: item.addressDetail?.latitude,
                        longitude: item.addressDetail?.longitude,
                    }}
                    mutators={{
                        // expect (field, value) args from the mutator
                        setValue: ([field, value], state, { changeValue }) => {
                            changeValue(state, field, () => value);
                        },
                        setPriceValue: (
                            [field, value],
                            state,
                            { changeValue },
                        ) => {
                            value = value.replaceAll(/\D/g, '');
                            !item?.megasale &&
                                changeValue(state, field, () => new Intl.NumberFormat('ru-RU').format(value));
                        },
                        setCnValue: (
                            [field, value],
                            state,
                            { changeValue },
                        ) => {
                            changeValue(state, field, () => createCn(value));
                        },
                        setAddressCheckValue: async (
                            [field, value],
                            state,
                            { changeValue },
                        ) => {
                            setHoldSubmit(true);
                            changeValue(state, field, () => value);
                            debounced(
                                state.formState.values['addressPlaceDetailId'],
                                state.formState.values['cn'],
                                me.uid,
                                state.formState.values['id'],
                            );
                        },
                    }}
                    validate={(values) => {
                        const errors: any = {};
                        // if (!values.addressPlaceDetailId) {
                        //     errors.addressPlaceDetailId = 'Выберите адрес';
                        // }
                        if (!values.type) {
                            errors.type = 'Укажите тип недвижимости';
                        }
                        if (!CN_REGEX_VALIDATE.test(values.cn)) {
                            errors.cn = 'Невалидный кадастровый номер';
                        }
                        return errors;
                    }}
                    render={({ handleSubmit, submitting, valid, form }) => (
                        <form onSubmit={handleSubmit}>
                            <FormSpy
                                subscription={{
                                    values: true,
                                    dirty: true,
                                    active: true,
                                }}
                                onChange={({ values, dirty, active }) => {
                                    console.log(`dirty: ${dirty}`);
                                    console.log(`active: ${active}`);
                                    console.log(
                                        `active value: ${values[active]}`,
                                    );
                                }}
                            />
                            <Navbar
                                className='justify-content-between'
                                bg='light'
                                expand='lg'
                                variant='light'
                            >
                                <ButtonGroup>
                                    <Button
                                        size='sm'
                                        onClick={() => navigate(-1)}
                                        disabled={submitting || holdSubmit}
                                    >
                                        <FontAwesomeIcon
                                            icon={['fas', 'fast-backward']}
                                        />{' '}
                                        Назад
                                    </Button>
                                    <Button
                                        size='sm'
                                        type='submit'
                                        className='btn btn-sm btn-success'
                                        disabled={
                                            submitting ||
                                            !valid ||
                                            isDouble ||
                                            holdSubmit
                                        }
                                    >
                                        <FontAwesomeIcon
                                            icon={['fas', 'save']}
                                        />{' '}
                                        Сохранить
                                    </Button>
                                </ButtonGroup>
                            </Navbar>
                            <Tab.Container defaultActiveKey={'address'}>
                                <Nav justify variant='tabs'>
                                    <Nav.Item>
                                        <Nav.Link eventKey='address'>
                                            <span>Адрес </span>
                                            <ShowErrorsBadge
                                                fields={[
                                                    'landAppointment',
                                                    'address',
                                                    'cn',
                                                ]}
                                            />
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey='flat'>
                                            <span>Параметры </span>
                                            <ShowErrorsBadge
                                                fields={[
                                                    'price',
                                                    'area',
                                                    'gas',
                                                    'heating',
                                                    'houseStoreys',
                                                    'waterSuply',
                                                    'houseMaterial',
                                                    'renovation',
                                                    'description',
                                                    'houseSpaceTotal',
                                                ]}
                                            />
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey='seller'>
                                            <span>Продавец </span>
                                            <ShowErrorsBadge
                                                fields={[
                                                    'sellerName',
                                                    'sellerPhone',
                                                ]}
                                            />
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey='photos'>
                                            Фото
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey='additional'>
                                            Дополнительно
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content className='container-fluid mt-2 p-0'>
                                    <Tab.Pane eventKey='address'>
                                        <UpdateAddressView
                                            defaultDropDownValue={
                                                item.addressDetail
                                                    ?.formattedAddress
                                            }
                                            isDouble={isDouble}
                                            doubleId={doubleId}
                                            unloadItems={unloadItems}
                                        />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey='flat'>
                                        <UpdateFlatParamsView />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey='seller'>
                                        <UpdateSellerView />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey='additional'>
                                        <UpdateAdditionalView />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey='photos'>
                                        <UpdatePhotosView />
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                            <Button
                                size='sm'
                                type='submit'
                                className='btn btn-sm btn-success mb-2'
                                disabled={
                                    submitting ||
                                    !valid ||
                                    isDouble ||
                                    holdSubmit
                                }
                            >
                                <FontAwesomeIcon icon={['fas', 'save']} />{' '}
                                Сохранить
                            </Button>
                        </form>
                    )}
                />
            )}
        </Suspense>
    );
}
