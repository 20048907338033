import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { all } from '~/src/store/agency.settings';
import ListView from '~/src/components/Agency/Settings';

const AgencySettingsPage = (): JSX.Element => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(all());
    }, [dispatch]);

    return <ListView />;
};

export default AgencySettingsPage;
