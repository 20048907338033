import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import View from '~/src/components/Filestore';
import { all } from '~/src/store/filestore';

const FilestorePage: FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(all());
    }, [dispatch]);

    return <View />;
};

export default FilestorePage;
