import { BaseSyntheticEvent } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

type TProps = {
    sort?: string;
    limit?: number;
    sortHandler: (e: BaseSyntheticEvent) => void;
    limitHandler: (e: BaseSyntheticEvent) => void;
    sortOpts: { value: string; title: string; up?: boolean; down?: boolean }[];
};

const SortLimitRow = ({
    sort,
    limit,
    sortHandler,
    limitHandler,
    sortOpts,
}: TProps): JSX.Element => {
    return (
        <Row className='mb-2'>
            <Col xs={4}>
                <Form.Label>Сортировать</Form.Label>
                <Form.Select
                    className='form-control'
                    size='sm'
                    value={sort || 'id_desc'}
                    onChange={sortHandler}
                >
                    {sortOpts.map(({ value, title, up, down }) => {
                        return up == true ? (
                            <option key={value} value={value}>
                                {title} &uarr;
                            </option>
                        ) : down == true ? (
                            <option key={value} value={value}>
                                {title} &darr;
                            </option>
                        ) : (
                            <option key={value} value={value}>
                                {title}
                            </option>
                        );
                    })}
                </Form.Select>
            </Col>
            <Col xs={4}>
                <Form.Label>На странице</Form.Label>
                <Form.Select
                    className='form-control'
                    size='sm'
                    value={limit || 25}
                    onChange={limitHandler}
                >
                    <option value={1}>1</option>
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={100}>100</option>
                </Form.Select>
            </Col>
        </Row>
    );
};

export default SortLimitRow;
