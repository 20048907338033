import queryString from 'query-string';
import * as api from '~/src/service/permission';
import { IPermission } from '../service/permission';
import { createSlice, Slice } from '@reduxjs/toolkit';
import { IResponseListData } from '../interfaces/common';
import { redirect, setLoading as setAppLoading } from './appSlice';
import type { TAppDispatch, TState, TAnyAction } from '~/src/store';

export const sliceName = 'permission';

interface IPermissionSlice {
    items: IResponseListData<IPermission>;
    item?: IPermission;
    search?: any;
    errors?: any;
    unloaded?: IPermission[];
}

const initialState = {
    items: {},
    search: {
        sort: 'id_desc',
        limit: 25,
        page: 1,
    },
    unloaded: [],
    loading: true,
    baseLink: '/permission',
};

const permissionSlice: Slice<IPermissionSlice> = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        setAll: (
            state: IPermissionSlice,
            action: TAnyAction<IResponseListData<IPermission>>,
        ) => {
            state.items = action.payload;
        },
        set: (state: IPermissionSlice, action: TAnyAction<IPermission>) => {
            state.item = action.payload;
        },
        setErrors: (state: IPermissionSlice, action: TAnyAction<any>) => {
            state.errors = action.payload;
        },
        setSearch: (state: IPermissionSlice, action: TAnyAction<any>) => {
            state.search = action.payload;
        },
        setUnload: (
            state: IPermissionSlice,
            action: TAnyAction<IPermission[]>,
        ) => {
            state.unloaded = action.payload;
        },
    },
});

const { setAll, set, setErrors, setSearch, setUnload } =
    permissionSlice.actions;

export const unload = () => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        const response = await api.unload();
        dispatch(setUnload(response));
    };
};

export const all = (queryParams: URLSearchParams = null) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(setAppLoading(true));
        const response = await api.all(queryParams);
        const obj = {};

        for (const key of queryParams.keys()) {
            if (queryParams.getAll(key).length > 1) {
                obj[key] = queryParams.getAll(key);
            } else {
                obj[key] = queryParams.get(key);
            }
        }

        dispatch(setSearch(obj));
        dispatch(setAll(response));
        dispatch(setAppLoading(false));
    };
};

export const create = (data: any, files?: any) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const item = await api.create(data, files);
            if (item && item.errors) {
                dispatch(setErrors(item.errors));
            } else if (item) {
                dispatch(redirect(initialState.baseLink));
            }
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const read = (id: number) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const data = await api.read(id);
            dispatch(set(data));
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const update = (
    id: number,
    data: any,
    files?: Record<string, FileList>,
) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const response = await api.update(id, data, files);
            if (response && response.success) {
                dispatch(set(response.data));
            } else {
                dispatch(setErrors(response.errors));
            }
        } catch (e) {
            console.error(e);
            dispatch(setErrors(e));
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const remove = (id: number) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const response = await api.remove(id);
            if (response && response.success) {
                dispatch(
                    setAll({
                        ...getState().permission.items,
                        items: getState().permission.items.items.filter(
                            (item: IPermission) => item.id != id,
                        ),
                    }),
                );
            } else {
                dispatch(setErrors(response.errors));
            }
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const goTo =
    (params: Record<string, any>) =>
    (dispatch: TAppDispatch, getState: () => TState): void => {
        if (params.reset === true) {
            params = initialState.search;
        } else params = { ...getState().permission.search, ...params };

        dispatch(
            redirect(
                `${initialState.baseLink}?${queryString.stringify(params, { skipNull: true })}`,
            ),
        );
    };

export const selectPermission = (state: TState): IPermissionSlice =>
    state.permission;

export default permissionSlice.reducer;
