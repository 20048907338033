import Api from '~/src/helpers/http-common';
import { IUpdateData } from '../interfaces/common';

export const all = async (
    section: string,
    entry: number,
): Promise<IComment[]> => {
    return await Api.get(`/comment/${section}/${entry}`);
};

export const create = async (data: IComment): Promise<IComment | any> => {
    return (await Api.post(
        `/comment/${data.section}/${data.entry}`,
        data,
    )) as IComment;
};

export const read = async (id: number): Promise<IComment> => {
    return await Api.get(`/comment/${id}`);
};

export const update = async (
    id: string | number,
    data: IComment,
): Promise<IUpdateData<IComment>> => {
    return await Api.put<IUpdateData<IComment>>(`/comment/${id}`, data);
};

export const remove = async (id: string | number): Promise<any> => {
    return await Api.delete(`/comment/${id}`);
};

export interface IComment {
    id?: number;
    section?: string;
    entry?: number;
    content?: string;
    createdAt?: Date;
    createdBy?: string;
    updatedAt?: Date;
    updatedBy?: string;
}
