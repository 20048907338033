import dateFormat from 'dateformat';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import React, { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import HaveAccess from '../../Common/HaveAccess';
import MainPopup from '../../Common/ModalUI/MainPopup';
import phoneFormatter from '~/src/helpers/phoneFormatter';
import RSListPhotos from '../Secondary/RSListPhotos';
import { IRSModel } from '~/src/store/realtySecondary/realtySecondarySlice';
import { Badge, Button, ButtonGroup } from 'react-bootstrap';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import classNames from 'classnames';
import {
    unverify,
    verify,
} from '~/src/store/realtySecondary/realty.secondary.moderate';
import ButtonWithModerateReject from '../../Common/Button/ButtonWithModerateReject';
import ConfirmButton from '~/src/components/Common/ModalUI/ButtonWithConfirm';
import IListUpdateInfo from '../Secondary/IListUpdateInfo';

const IList: FC<TListProps> = ({ item }) => {
    const dispatch = useDispatch();

    const [showOnMap, setShowOnMap] = useState(false);

    const handleClickAddress = () => setShowOnMap(true);

    const setMap = (map) => {
        const resizeObserver = new ResizeObserver(() => {
            map.invalidateSize();
        });

        const container = document.getElementById(`map-container-${item.id}`);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        resizeObserver.observe(container!);
    };

    const handleConfirmModerate = () => {
        dispatch(verify(item.id));
    };

    return (
        <React.Fragment>
            <tr>
                <td>
                    <Link to={`/realty-secondary/${item.id}`}>{item.id}</Link>
                    {item.verified && (
                        <React.Fragment>
                            <br />
                            <FontAwesomeIcon
                                icon={['fas', 'check-circle']}
                                style={{ color: 'blue' }}
                                title='Подтвержденный объект'
                            />
                        </React.Fragment>
                    )}
                    <br />
                    {!item.isFullfied && (
                        <FontAwesomeIcon
                            icon={['fas', 'exclamation-triangle']}
                            title={item.fullfiedStatus
                                .map((field) => {
                                    return `${field.key}: ${field.value === true ? 'Да' : 'Нет'}`;
                                })
                                .join('\n')}
                        />
                    )}
                </td>
                <td>
                    <RSListPhotos photos={item.photos} />
                    <p>
                        Внесен: {dateFormat(item.createdAt, 'dd.mm.yyyy')}
                        <br />
                        <IListUpdateInfo item={item} />В базе еще{' '}
                        {item.freshAgo > 1
                            ? `${item.freshAgo} дней`
                            : `${item.freshAgoH} часов`}
                    </p>
                    {item.sold && <Badge bg='danger'>В архиве</Badge>}
                </td>
                <td>
                    <p>
                        {item.realtyType === 'secondary' ? (
                            <strong>Вторичка</strong>
                        ) : (
                            <strong>Новостройка от дольщика</strong>
                        )}
                    </p>
                    <p>
                        <strong>Тип</strong>
                        <br />
                        <FormattedMessage
                            id={`realty_secondary_subtype_${item.realtySubtype}`}
                        />
                    </p>
                    {item?.realtyType === 'primary' && item?.housingEstate && (
                        <>
                            <p>{item?.housingEstate?.title}</p>
                            {item?.addressDetail?.livingArea && (
                                <p>
                                    <strong>Район</strong> <br />
                                    {item?.addressDetail?.livingArea}
                                </p>
                            )}
                        </>
                    )}
                    <p>
                        <strong>Адрес</strong>
                        <br />
                        <Button
                            size='sm'
                            type='button'
                            variant='link'
                            onClick={handleClickAddress}
                        >
                            {item.addressDetail?.shortAddress}
                        </Button>
                    </p>
                    {item.storey && item.houseStoreys && (
                        <p>
                            <strong>Этаж</strong>
                            <br />
                            {item.storey} / {item.houseStoreys}
                        </p>
                    )}
                    {item.isMy && (
                        <p>
                            <strong>Квартира</strong>
                            <br />
                            {item.flatNumber}
                        </p>
                    )}
                </td>
                <td>
                    {item.rooms && (
                        <p>
                            <strong>Комнат</strong>
                            <br />
                            {item.rooms}
                        </p>
                    )}
                    {item.spaceTotal && (
                        <p>
                            <strong>Общая площадь</strong>
                            <br />
                            {item.spaceTotal}
                        </p>
                    )}
                    {item.spaceKitchen && (
                        <p>
                            <strong>Площадь кухни</strong>
                            <br />
                            {item.spaceKitchen}
                        </p>
                    )}
                    {(item.bathroomCombined > 0 ||
                        item.bathroomSeparated > 0) && (
                        <p>
                            <strong>Санузлы</strong>
                            {item.bathroomCombined > 0 && (
                                <React.Fragment>
                                    <br />
                                    <span>
                                        Совмещенных: {item.bathroomCombined}
                                    </span>
                                </React.Fragment>
                            )}
                            {item.bathroomSeparated > 0 && (
                                <React.Fragment>
                                    <br />
                                    <span>
                                        Раздельных: {item.bathroomSeparated}
                                    </span>
                                </React.Fragment>
                            )}
                        </p>
                    )}
                    {item.balcony > 0 && (
                        <p>
                            <strong>Балкон: </strong> {item.balcony}
                        </p>
                    )}
                    {item.loggia > 0 && (
                        <p>
                            <strong>Лоджия: </strong> {item.loggia}
                        </p>
                    )}
                </td>
                <td>
                    {item.houseMaterial && (
                        <p>
                            <strong>Материал стен</strong>
                            <br />
                            <FormattedMessage
                                id={`house_material_${item.houseMaterial}`}
                            />
                        </p>
                    )}

                    {item.houseBuilt && (
                        <p>
                            {item.realtyType == 'primary' ? (
                                <>
                                    <strong>Срок сдачи дома</strong>
                                    <br />
                                    {`${item.houseBuiltQuarter} кв. ${item?.houseBuilt} г.`}
                                </>
                            ) : (
                                <>
                                    <strong>Год постройки</strong>
                                    <br />
                                    {`${item.houseBuilt} г.`}
                                </>
                            )}
                            <br />
                        </p>
                    )}

                    {(item.serviceElevator > 0 ||
                        item.passengerElevator > 0) && (
                        <p>
                            <strong>Лифты</strong>
                            {item.passengerElevator > 0 && (
                                <React.Fragment>
                                    <br />
                                    <span>
                                        Пассажирских: {item.passengerElevator}
                                    </span>
                                </React.Fragment>
                            )}
                            {item.serviceElevator > 0 && (
                                <React.Fragment>
                                    <br />
                                    <span>
                                        Грузовых: {item.serviceElevator}
                                    </span>
                                </React.Fragment>
                            )}
                        </p>
                    )}
                </td>
                <td>
                    <p>
                        <strong>Цена</strong>
                        <br />
                        {new Intl.NumberFormat('ru-RU').format(+item.price)}
                        <br></br>
                        <strong>
                            Цена за м<sup>2</sup>
                        </strong>
                        <br></br>
                        <span>
                            {new Intl.NumberFormat('ru-RU').format(
                                Math.floor(+item.price / +item.spaceTotal),
                            )}
                        </span>
                    </p>
                    {item.isUnderstatementNeed ? (
                        <p>
                            <strong
                                className={classNames(
                                    'border',
                                    'border-2',
                                    'border-danger',
                                )}
                            >
                                Занижение
                            </strong>
                            <br />
                            {new Intl.NumberFormat('ru-RU').format(
                                +item.understatementSum,
                            )}
                            <br />
                        </p>
                    ) : null}
                    {+item.bankEncumbrance > 0 ? (
                        <p>
                            <strong
                                className={classNames(
                                    'border',
                                    'border-2',
                                    'border-danger',
                                )}
                            >
                                Обременение
                            </strong>
                            <br />
                            {item.bankEncumbranceTitle}
                            <br />
                            {new Intl.NumberFormat('ru-RU').format(
                                +item.bankEncumbrance,
                            )}
                            <br />
                        </p>
                    ) : null}
                </td>
                <td>
                    {item.isMy ? (
                        <React.Fragment>
                            {item.sellerName && (
                                <p>
                                    <strong>Продавец</strong>
                                    <br />
                                    {item.sellerName}
                                </p>
                            )}

                            {item.sellerPhone && (
                                <p>
                                    <strong>Телефон</strong>
                                    <br />
                                    {phoneFormatter(`${item.sellerPhone}`)}
                                </p>
                            )}
                        </React.Fragment>
                    ) : (
                        item.agent && (
                            <React.Fragment>
                                {item.agent?.agencyInfo?.agency && (
                                    <p>
                                        <strong>Агентство</strong>
                                        <br></br>
                                        <span>
                                            {
                                                item.agent?.agencyInfo?.agency
                                                    .title
                                            }
                                        </span>
                                    </p>
                                )}
                                <p>
                                    <strong>
                                        {item?.agent?.role != 'agent'
                                            ? 'Собственник'
                                            : 'Агент'}
                                    </strong>
                                    <br />
                                    {item.agent?.fullname}
                                </p>

                                <p>
                                    <strong>Телефон</strong>
                                    <br />
                                    {phoneFormatter(`${item.agent?.phone}`)}
                                </p>
                            </React.Fragment>
                        )
                    )}
                </td>
                <td>
                    <ButtonGroup vertical={true} className='w-100'>
                        <Link
                            to={`/realty-secondary/card/${item.id}`}
                            className='btn btn-sm btn-secondary'
                            title='Карточка'
                        >
                            Карточка
                        </Link>
                        <HaveAccess access='realty.secondary.moderate'>
                            <ConfirmButton
                                variant='success'
                                label='Подтвердить'
                                modalText={`Объект ${item.id} окажется в общей базе, подтвердить?`}
                                onClick={handleConfirmModerate}
                            >
                                Подтвердить
                            </ConfirmButton>
                            <ButtonWithModerateReject
                                id={item.id}
                                unverify={unverify}
                            />
                        </HaveAccess>
                    </ButtonGroup>
                </td>
            </tr>
            <MainPopup
                isOpened={showOnMap}
                onClose={() => setShowOnMap(false)}
                fullwidth={true}
            >
                <MapContainer
                    center={[
                        +item.addressDetail.latitude,
                        +item.addressDetail.longitude,
                    ]}
                    zoom={15}
                    maxZoom={18}
                    id={`map-container-${item.id}`}
                    whenCreated={setMap}
                    attributionControl={false}
                >
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url='http://{s}.maps.2gis.com/tiles?x={x}&y={y}&z={z}'
                        subdomains={['tile0', 'tile1', 'tile2', 'tile3']}
                    />
                    <Marker
                        position={[
                            +item.addressDetail.latitude,
                            +item.addressDetail.longitude,
                        ]}
                    ></Marker>
                </MapContainer>
            </MainPopup>
        </React.Fragment>
    );
};

type TListProps = {
    item: IRSModel;
};

export default IList;
