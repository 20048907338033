import validator from 'validator';

export const composeValidators =
    <T>(...validators) =>
        (value: T): string | undefined =>
            validators.reduce(
                (error, validator) => error || validator(value),
                undefined,
            );

export const required = <T>(value: T): string | undefined =>
    value ? undefined : 'Заполните поле';

export const requiredEmpty = <T>(value: T): string | undefined =>
    value !== '' ? undefined : 'Заполните поле';

export const mustBeNumber = <T>(value: T): string | undefined =>
    value
        ? isNaN(value as any)
            ? 'Допускаются только цифры'
            : undefined
        : undefined;

export const onlyLength =
    (l: number) =>
        (value: unknown): string | undefined => {
            return value === undefined
                ? undefined
                : `${value}`.length != l
                    ? `Допускается ${l} символов, сейчас ${`${value}`.length}`
                    : undefined;
        };

export const minValue =
    (min: number) =>
        (value: number): string | undefined =>
            isNaN(value as any) || value > min
                ? undefined
                : `Должно быть больше чем ${min}`;

export const minEqValue =
    (min: number) =>
        (value: number): string | undefined =>
            isNaN(value as any) || value >= min
                ? undefined
                : `Должно быть больше или равно чем ${min}`;

export const maxValue =
    (max: number) =>
        (value: number): string | undefined =>
            isNaN(value as any) || value < max
                ? undefined
                : `Должно быть меньше чем ${max}`;

export const maxEqValue =
    (max: number) =>
        (value: number): string | undefined =>
            isNaN(value as any) || value < max
                ? undefined
                : `Должно быть больше или равно чем ${max}`;

export const price = (value: string): string | undefined =>
    /[0-9+\s]/.test(value) ? undefined : 'Допускаются только цифры';

export const priceSixDigit = (
    value: string,
): string | undefined =>
    Number(value?.replace(/\s/g, '')) >= 1000000
        ? undefined
        : 'Неккоректная сумма';

export const priceFiveDigit = (
    value: string,
): string | undefined =>
    Number(value?.replace(/\s/g, '')) >= 100000
        ? undefined
        : 'Неккоректная сумма';

export const isEmail = (value: string): string | undefined =>
    value && value.length > 0
        ? validator.isEmail(value)
            ? undefined
            : 'Введите корректный Email'
        : undefined;

export const requiredArray = (value: any) =>
    value && value.length > 0 ? undefined : 'Required';

export const regex =
    (r: RegExp) =>
        (value: string, message?: string): string | undefined =>
            r.test(value) ? undefined : message || 'Данные не корректны';

export const space = (value: string): string | undefined =>
    regex(/^[0-9.]+$/)(value, 'Допускаются цифры и точка');

export const isPhone = (value: string): string | undefined => {
    const digits = value.replace(/\D/g, '');

    return regex(/^[78]?9[0-9]{9}$/)(digits, 'Номер телефона не корректный');
};
