import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
    getUnloadedUserPermissions,
    selectUsers,
} from '~/src/store/usersSlice';
import UserPermissionView from '~/src/components/Users/permission';
import { selectApp, setActiveMenuKey } from '~/src/store/appSlice';

const UserPermission = (): JSX.Element => {
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();

    const { loading } = useSelector(selectApp);
    const { item } = useSelector(selectUsers);

    useEffect(() => {
        dispatch(getUnloadedUserPermissions(+id));
    }, [dispatch, id]);

    useEffect(() => {
        dispatch(setActiveMenuKey('PA'));
    }, [dispatch]);

    return (
        <React.Fragment>
            {loading || item === null ? (
                <div>Загрузка...</div>
            ) : (
                <UserPermissionView />
            )}
        </React.Fragment>
    );
};

export default UserPermission;
