import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '~/src/helpers/hooks';
import InfoListView from '~/src/components/Info';
import { getAgency } from '~/src/store/info.agency.users.slice';
import {
    setActiveMenuKey,
    setHandleScrollPosition,
} from '~/src/store/appSlice';

const InfoPage = (): JSX.Element => {
    const query = useQuery();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setActiveMenuKey('info-agency'));
    }, [dispatch]);

    useEffect(() => {
        dispatch(getAgency(query));
    }, [dispatch, query]);

    useEffect(() => {
        dispatch(setHandleScrollPosition(true));
    }, [dispatch]);

    return <InfoListView />;
};

export default InfoPage;
