import arrayMutators from 'final-form-arrays';
import { useNavigate } from 'react-router-dom';
import { geocode } from '~/src/service/address';
import InputField from '../Common/Form/InputField';
import { useDispatch, useSelector } from 'react-redux';
import SimpleSelect from '../Common/Form/SimpleSelect';
import {
    create,
    selectCV,
} from '~/src/store/cottage.village/cottage.village.store';
import { Form as FinalForm, Field, FormSpy } from 'react-final-form';
import { selectDeveloper } from '~/src/store/developer/developerSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MapUpdateView from '~/src/components/Common/Map/MapUpdateView';
import { Button, ButtonGroup, Form, Navbar, Col, Row } from 'react-bootstrap';
import {
    composeValidators,
    required,
    mustBeNumber,
    maxValue,
    price,
} from '~/src/helpers/validators';
import DropDownSearchApi from '../Common/DropDownSearchApi';
import createInitialFormValues from '~/src/helpers/createInitialFormValues';
import { keyBenefits } from '~/src/common/data/cottage.village.key.benefits';
import { benefitsWhenPurchasing } from '~/src/common/data/cottage.village.benefitsWhenPurchasing';
import Select from 'react-select';
import { mergeObjects } from '~/src/helpers/mergeObjects';
import SimpleSelectV2Field from '../Common/Form/SimpleSelectV2';
import { selectDirection } from '~/src/store/directionSlice';
import CheckboxField from '../Common/Form/CheckboxField';
import slugify from 'slugify';

export default function CreateForm(): JSX.Element {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { unloadedSuburban: developers } = useSelector(selectDeveloper);
    const { creating } = useSelector(selectCV);
    const { unloadItems } = useSelector(selectDirection);

    const onSubmit = (values: Record<string, any>) => {
        const paramsOther = mergeObjects(
            values.keyBenefits,
            values.benefitsWhenPurchasing,
        );
        dispatch(create({
            ...values,
            ...paramsOther,
            code: slugify(values.codeName, {
                lower: true,
                locale: 'ru'
              })
         }));
    };

    return (
        <FinalForm
            onSubmit={onSubmit}
            mutators={{
                // expect (field, value) args from the mutator
                setValue: ([field, value], state, { changeValue }) => {
                    changeValue(state, field, () => value);
                },
                setDecimalValue: ([field, value], state, { changeValue }) => {
                    value = value.replaceAll(',', '.');
                    changeValue(state, field, () => value);
                },
                setPriceValue: ([field, value], state, { changeValue }) => {
                    value = value.replaceAll(/\D/g, '');
                    changeValue(state, field, () => new Intl.NumberFormat('ru-RU').format(value));
                },
                ...arrayMutators,
            }}
            initialValues={{
                ...createInitialFormValues(creating),
            }}
            validate={(values) => {
                const errors: any = {};

                // if (values.phases === undefined) {
                //     errors.mustBeOnePhase = 'Должна быть хотя бы одна очередь';
                // }

                if (values.phases?.length < 1) {
                    errors.mustBeOnePhase = 'Должна быть хотя бы одна очередь';
                }
                if (values.keyBenefits && values.keyBenefits.length > 3) {
                    errors.keyBenefits =
                        'Вы должны выбрать не более 3 приемуществ';
                }
                if (
                    !values.keyBenefits ||
                    Object.keys(values.keyBenefits).length == 0
                ) {
                    errors.keyBenefits = 'Выберите ключевые приемущества';
                }
                if (
                    !values.benefitsWhenPurchasing ||
                    Object.keys(values.benefitsWhenPurchasing).length == 0
                ) {
                    errors.benefitsWhenPurchasing =
                        'Выберите выгоды при покупке';
                }
                return errors;
            }}
            render={({ handleSubmit, form, submitting, valid }) => (
                <form onSubmit={handleSubmit}>
                    <Navbar
                        className='justify-content-between'
                        bg='light'
                        expand='lg'
                        variant='light'
                    >
                        <ButtonGroup>
                            <Button
                                size='sm'
                                onClick={() => navigate(-1)}
                                disabled={submitting}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'fast-backward']}
                                />{' '}
                                Назад
                            </Button>
                            <Button
                                size='sm'
                                type='submit'
                                className='btn btn-sm btn-success'
                                disabled={submitting || !valid}
                            >
                                <FontAwesomeIcon icon={['fas', 'save']} />{' '}
                                Сохранить
                            </Button>
                        </ButtonGroup>
                    </Navbar>
                    <Row>
                        <Col>
                            <InputField
                                name='title'
                                label='Название'
                                validators={required}
                            />
                        </Col>
                        <Col>
                            <SimpleSelect
                                label='Застройщик'
                                name='developerId'
                                opts={[{ id: 0, title: '' }]
                                    .concat(developers)
                                    .map((developer) => {
                                        if (developer?.id === 0) return { value: '', title: '' };
                                        return {
                                            value: `${developer?.id}`,
                                            title: developer?.title,
                                        };
                                    })}
                                validators={required}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputField
                                name='codeName'
                                label='Краткое название'
                                placeholder='Вишня'
                                validators={required}
                            />
                            <Field name='keyBenefits'>
                                {({ input, meta }) => (
                                    <Form.Group className='mb-3'>
                                        <Form.Group className='d-flex gap-3'>
                                            <Form.Label>
                                                <>Ключевые приемущества</>
                                            </Form.Label>
                                        </Form.Group>
                                        <Select
                                            {...input}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            options={keyBenefits}
                                            placeholder='Выберите ключевые приемущества'
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    maxHeight: '100px',
                                                    overflowY: 'auto',
                                                    '::-webkit-scrollbar': {
                                                        width: '4px',
                                                    },
                                                    '::-webkit-scrollbar-thumb':
                                                    {
                                                        backgroundColor:
                                                            '#0d6efd',
                                                        borderRadius: '2px',
                                                    },
                                                    '::-webkit-scrollbar-track':
                                                    {
                                                        backgroundColor:
                                                            '#ECECEC',
                                                        borderRadius: '2px',
                                                    },
                                                }),
                                            }}
                                        />
                                        {meta.error && meta.touched && (
                                            <span
                                                style={{
                                                    color: 'red',
                                                    fontSize: '14px',
                                                }}
                                            >
                                                {meta.error}
                                            </span>
                                        )}
                                    </Form.Group>
                                )}
                            </Field>
                            <Field name='benefitsWhenPurchasing'>
                                {({ input, meta }) => (
                                    <Form.Group className='mb-3'>
                                        <Form.Group className='d-flex gap-3'>
                                            <Form.Label>
                                                <>Выгоды при покупке</>
                                            </Form.Label>
                                        </Form.Group>
                                        <Select
                                            {...input}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            options={benefitsWhenPurchasing}
                                            placeholder='Выберите выгодные предложения при покупке'
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    maxHeight: '100px',
                                                    overflowY: 'auto',
                                                    '::-webkit-scrollbar': {
                                                        width: '4px',
                                                    },
                                                    '::-webkit-scrollbar-thumb':
                                                    {
                                                        backgroundColor:
                                                            '#0d6efd',
                                                        borderRadius: '2px',
                                                    },
                                                    '::-webkit-scrollbar-track':
                                                    {
                                                        backgroundColor:
                                                            '#ECECEC',
                                                        borderRadius: '2px',
                                                    },
                                                }),
                                            }}
                                        />
                                        {meta.error && meta.touched && (
                                            <span
                                                style={{
                                                    color: 'red',
                                                    fontSize: '14px',
                                                }}
                                            >
                                                {meta.error}
                                            </span>
                                        )}
                                    </Form.Group>
                                )}
                            </Field>
                            <DropDownSearchApi
                                label='Адрес'
                                name='address'
                                validator={required}
                                defaultValue={form.getState().values.address}
                                onSelect={(item) => {
                                    form.mutators.setValue(
                                        'addressPlaceDetailId',
                                        item.placeId,
                                    );
                                    form.mutators.setValue(
                                        'address',
                                        item.address,
                                    );
                                    form.mutators.setValue(
                                        'latitude',
                                        item.latitude,
                                    );
                                    form.mutators.setValue(
                                        'longitude',
                                        item.longitude,
                                    );
                                }}
                            >
                                <Field name='addressPlaceDetailId'>
                                    {({ meta }) => (
                                        <Form.Group>
                                            <Form.Control.Feedback
                                                type='invalid'
                                                style={{
                                                    display:
                                                        meta.error ||
                                                            meta.submitError
                                                            ? 'block'
                                                            : 'none',
                                                }}
                                            >
                                                {meta.error || meta.submitError}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>
                            </DropDownSearchApi>
                            <InputField
                                name='latitude'
                                validators={required}
                                label={'Широта'}
                                readOnly
                            />
                            <InputField
                                name='longitude'
                                validators={required}
                                label={'Долгота'}
                                readOnly
                            />
                            <Field name='directionId' validate={required}>
                                {({ input, meta }) => (
                                    <SimpleSelectV2Field
                                        label='Тракт'
                                        name='directionId'
                                        validators={required}
                                    >
                                        <option></option>
                                        {unloadItems.map((el) => (
                                            <option key={el.id} value={el.id}>
                                                {el.title}
                                            </option>
                                        ))}
                                    </SimpleSelectV2Field>
                                )}
                            </Field>
                            <InputField
                                name='mortgage'
                                label='Минимальная ставка по ипотеке'
                                onChange={(e) =>
                                    form.mutators.setDecimalValue(
                                        'mortgage',
                                        e.target.value,
                                    )
                                }
                                validators={composeValidators(mustBeNumber)}
                                initialValue={null}
                            />
                            <InputField
                                label='Скидка (ввести без %)'
                                validators={composeValidators(price)}
                                placeholder='Скидка (без %)'
                                name='landSaleDiscount'
                                onChange={(e) =>
                                    form.mutators.setPriceValue(
                                        'landSaleDiscount',
                                        e.target.value,
                                    )
                                }
                            />
                            <CheckboxField name={'allHouseProjects'} label={'Возможны любые подряды'} />
                        </Col>
                        <Col className='mb-3'>
                            <FormSpy subscription={{ values: true }}>
                                {({ values }) => (
                                    <MapUpdateView
                                        latitude={values.latitude || undefined}
                                        longitude={
                                            values.longitude || undefined
                                        }
                                        onClick={async (lat, lng) => {
                                            const _res = await geocode(
                                                lat,
                                                lng,
                                            );
                                            lat = _res[0]?.latitude || lat;
                                            lng = _res[0]?.longitude || lng;
                                            if (_res[0]?.placeId) {
                                                form.mutators.setValue(
                                                    'addressPlaceDetailId',
                                                    _res[0]?.placeId,
                                                );
                                                form.mutators.setValue(
                                                    'address',
                                                    _res[0]?.address,
                                                );
                                            }
                                            form.mutators.setValue(
                                                'latitude',
                                                lat,
                                            );
                                            form.mutators.setValue(
                                                'longitude',
                                                lng,
                                            );
                                        }}
                                    />
                                )}
                            </FormSpy>
                        </Col>
                    </Row>
                    {/* <ConstructionPhasesFormBlock /> */}
                    {/* <Error name='mustBeOnePhase' /> */}
                    {/* <Field name='description'>
                        {({ input, meta }) => (
                            <Form.Group className='mb-3'>
                                <Form.Label>Описание</Form.Label>
                                <Form.Control
                                    {...input}
                                    as='textarea'
                                    rows={5}
                                    size='sm'
                                    isInvalid={meta.error || meta.submitError}
                                    placeholder='Описание'
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {meta.error || meta.submitError}
                                </Form.Control.Feedback>
                            </Form.Group>
                        )}
                    </Field> */}
                    <Button
                        size='sm'
                        type='submit'
                        className='btn btn-sm btn-success'
                        disabled={submitting || !valid}
                    >
                        <FontAwesomeIcon icon={['fas', 'save']} /> Сохранить
                    </Button>
                </form>
            )}
        />
    );
}
