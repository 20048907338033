import Api from '~/src/helpers/http-common';
import { IUpdateData } from '../interfaces/common';

export const all = async (): Promise<IAgencySetting[]> => {
    return await Api.get('/agency/my/settings');
};

export const update = async (
    id: string | number,
    data: IAgencySetting,
): Promise<IUpdateData<IAgencySetting>> => {
    return await Api.post<IUpdateData<IAgencySetting>>(
        '/agency/my/settings/update',
        data,
    );
};

export interface IAgencySetting {
    id: number;
    title: string;
    code: string;
    agencyId: number;
    type: string;
    value: any;
    enabled: boolean;
}
