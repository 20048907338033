import { FormApi } from 'final-form';
import classNames from 'classnames';
import Lightbox from 'react-image-lightbox';
import HaveAccess from '../../Common/HaveAccess';
import FileField from '../../Common/Form/FileField';
import { FILES_BASE } from '~/src/constants/urls';
import InputField from '../../Common/Form/InputField';
import { BaseSyntheticEvent, useEffect, useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import SimpleSelectField from '../../Common/Form/SimpleSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FlatChessAdditionalParams from './FlatChessAdditionalParams';
import ButtonWithConfirm from '../../Common/ModalUI/ButtonWithConfirm';
import { Button, ButtonGroup, Container, Form } from 'react-bootstrap';
import createInitialFormValues from '~/src/helpers/createInitialFormValues';
import {
    LazyLoadImage,
    trackWindowScroll,
} from 'react-lazy-load-image-component';
import ButtonWithModalOneField from '~/src/components/Common/ModalUI/ModalOneField/ButtonWith';
import {
    composeValidators,
    required,
    space,
    price as priceValidator,
} from '~/src/helpers/validators';
import { priceSixDigit } from '~/src/helpers/validators';
import { IHousingEstateHousePorchStoreyFlat } from '~/src/service/housing.estate.house.porch.storey.flat';
import {
    update,
    updateSameByStoreys,
    saleChange,
    remove,
} from '~/src/service/housing.estate.house.porch.storey.flat';
import ButtonWithAddMegaSale from '../../Sale/ButtonWithAddMegaSale';
import { useSelector } from 'react-redux';
import { selectMegaSale } from '~/src/store/megasaleSlice';
import InputFieldError from '../../Common/Form/InputFieldError';

interface IProps {
    flat: IHousingEstateHousePorchStoreyFlat;
    scrollPosition: any;
    floorsTotal: number;
}

const FlatChess = ({
    flat,
    scrollPosition,
    floorsTotal,
}: IProps): JSX.Element => {
    const { id, sold, rooms, price, layout } = flat;

    const [preview, setPreview] = useState(null);
    const [isOpen, setOpen] = useState(false);
    const [soldStatus, setSold] = useState(sold);
    const [deleted, setDeleted] = useState(false);
    const { megasaleActive } = useSelector(selectMegaSale);
    const [flatItem, setFlatItem] = useState(null);

    useEffect(() => {
        megasaleActive && setFlatItem(flat);
    }, [flat]);

    const onSubmit = async (values: Record<string, any>) => {
        const filesRecord =
            values.uploadLayout === null
                ? undefined
                : { uploadLayout: values.uploadLayout };

        await update(
            values.id,
            {
                ...values,
                sold: soldStatus,
                price: values.price.replaceAll(/\D/g, ''),
            },
            filesRecord,
        );
        setFlatItem((prev) => {
            return {
                ...prev,
                price: values.price.replaceAll(/\D/g, ''),
            };
        });
    };

    const deleteFlat = async () => {
        const res = await remove(id);
        if (res.success) {
            setDeleted(true);
        }
    };

    const uploadHandler = (e: BaseSyntheticEvent, form: FormApi) => {
        setPreview(URL.createObjectURL(e.target.files[0]));
        form.mutators.setValue('uploadLayout', e.target.files[0]);
    };

    const handleSaleChange = async () => {
        const response = await saleChange(id);

        if (response.success) setSold(!soldStatus);
    };

    const handleChangeAll = async (values: Record<string, any>) => {
        const filesRecord =
            values.uploadLayout === null
                ? undefined
                : { uploadLayout: values.uploadLayout };

        const response = await updateSameByStoreys(
            values.id,
            {
                ...values,
                sold: soldStatus,
                price: values.price.replaceAll(/\D/g, ''),
            },
            filesRecord,
        );

        if (response.success) {
            window.location.reload();
        }
    };

    return (
        <Container
            className={classNames([
                'p-2',
                'bg-opacity-50',
                soldStatus ? 'bg-danger' : 'bg-success',
                deleted && 'd-none',
            ])}
        >
            <FinalForm
                onSubmit={onSubmit}
                initialValues={{
                    ...createInitialFormValues(flat),
                    price: new Intl.NumberFormat('ru-RU').format(+price),
                }}
                mutators={{
                    // expect (field, value) args from the mutator
                    setValue: ([field, value], state, { changeValue }) => {
                        changeValue(state, field, () => value);
                    },
                    setPriceValue: ([field, value], state, { changeValue }) => {
                        value = value.replaceAll(/\D/g, '');
                        !flatItem?.megasale &&
                            changeValue(state, field, () =>
                                new Intl.NumberFormat('ru-RU').format(value),);
                    },
                    setDecimalValue: (
                        [field, value],
                        state,
                        { changeValue },
                    ) => {
                        value = value.replaceAll(',', '.');
                        changeValue(state, field, () => value);
                    },
                }}
                validate={() => {
                    const errors: any = {};
                    return errors;
                }}
                render={({ handleSubmit, form, submitting, valid, values }) => (
                    <form onSubmit={handleSubmit}>
                        <p>
                            <strong>На площадке #{flat.room}</strong>
                        </p>
                        <SimpleSelectField
                            name='rooms'
                            label='Кол-во комнат'
                            initialValue={rooms == 0 ? 'studio' : rooms}
                            validators={composeValidators(required)}
                            opts={Array.from({ length: 12 }, (_, i) => {
                                if (i == 0) {
                                    return {
                                        title: '',
                                        value: '',
                                    };
                                }
                                return {
                                    title: i == 1 ? 'Студия' : `${i - 1}`,
                                    value: i == 1 ? 'studio' : `${i - 1}`,
                                };
                            })}
                        />
                        <InputField
                            name='ceiling'
                            label='Высота потолков'
                            onChange={(e) =>
                                form.mutators.setDecimalValue(
                                    'ceiling',
                                    e.target.value,
                                )
                            }
                            validators={composeValidators(required)}
                        />
                        {flatItem?.megasale ? (
                            <InputFieldError
                                label='Цена'
                                name='price'
                                onChange={(e) =>
                                    form.mutators.setPriceValue(
                                        'price',
                                        e.target.value,
                                    )
                                }
                                errorMessage={
                                    'Объект на распродаже, менять цену запрещено'
                                }
                                validators={composeValidators(
                                    required,
                                    soldStatus
                                        ? priceValidator
                                        : priceSixDigit,
                                )}
                            />
                        ) : (
                            <InputField
                                label='Цена'
                                name='price'
                                onChange={(e) =>
                                    form.mutators.setPriceValue(
                                        'price',
                                        e.target.value,
                                    )
                                }
                                validators={composeValidators(
                                    required,
                                    soldStatus
                                        ? priceValidator
                                        : priceSixDigit,
                                )}
                            />
                        )}
                        {flatItem?.megasale && (
                            <InputField
                                placeholder={new Intl.NumberFormat(
                                    'ru-RU',
                                ).format(flatItem?.megasale?.price)}
                                label='Цена на распродаже'
                                name='priceMegasale'
                                readOnly
                            />
                        )}
                        <InputField
                            label='Общая площадь'
                            name='spaceTotal'
                            onChange={(e) =>
                                form.mutators.setDecimalValue(
                                    'spaceTotal',
                                    e.target.value,
                                )
                            }
                            validators={composeValidators(required, space)}
                        />
                        <InputField
                            name='spaceLiving'
                            label='Жилая площадь'
                            onChange={(e) =>
                                form.mutators.setDecimalValue(
                                    'spaceLiving',
                                    e.target.value,
                                )
                            }
                            validators={composeValidators(required, space)}
                        />
                        <InputField
                            name='spaceKitchen'
                            label='Площадь кухни'
                            onChange={(e) =>
                                form.mutators.setDecimalValue(
                                    'spaceKitchen',
                                    e.target.value,
                                )
                            }
                            validators={composeValidators(required, space)}
                        />
                        <FlatChessAdditionalParams flat={flat} />
                        <Form.Group className='mb-3 vstack'>
                            <label className='btn btn-sm btn-primary'>
                                <FileField
                                    name='layout'
                                    onChange={(e) => uploadHandler(e, form)}
                                    multiple
                                />{' '}
                                Загрузить фото
                            </label>
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            {(preview !== null || layout !== null) && (
                                <>
                                    <LazyLoadImage
                                        src={
                                            preview !== null
                                                ? preview
                                                : layout !== null
                                                  ? `${FILES_BASE}${layout}`
                                                  : null
                                        }
                                        className={classNames([
                                            'm-auto',
                                            'd-block',
                                        ])}
                                        width={160}
                                        height={160}
                                        effect='blur'
                                        style={{
                                            minHeight: 160,
                                            minWidth: 160,
                                            maxWidth: 160,
                                            maxHeight: 160,
                                        }}
                                        onClick={() => setOpen(true)}
                                        scrollPosition={scrollPosition}
                                    />
                                    {isOpen && (
                                        <Lightbox
                                            mainSrc={
                                                preview !== null
                                                    ? preview
                                                    : layout !== null
                                                      ? `${FILES_BASE}${layout}`
                                                      : null
                                            }
                                            onCloseRequest={() => {
                                                setOpen(false);
                                            }}
                                        />
                                    )}
                                </>
                            )}
                        </Form.Group>
                        <div className='text-center'>
                            <ButtonGroup className='mb-3'>
                                <Button
                                    type='submit'
                                    disabled={submitting || !valid}
                                    size='sm'
                                    variant='success'
                                >
                                    <FontAwesomeIcon icon={['fas', 'save']} />
                                </Button>
                                <Button
                                    type='button'
                                    disabled={submitting || !valid}
                                    size='sm'
                                    variant='warning'
                                    title={
                                        soldStatus
                                            ? 'Вернуть в продажу'
                                            : 'В продан'
                                    }
                                    onClick={handleSaleChange}
                                >
                                    {soldStatus ? (
                                        <FontAwesomeIcon
                                            icon={['fas', 'undo']}
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            icon={['fas', 'dollar-sign']}
                                        />
                                    )}
                                </Button>
                                <ButtonWithModalOneField
                                    modalText={`Вы собираетесь изменить все или часть квартир на площадке ${flat.room}?`}
                                    title={`Изменить все или часть квартир на площадке ${flat.room}`}
                                    primaryButtonOnClick={(v) =>
                                        handleChangeAll({
                                            ...values,
                                            storeys: v,
                                        })
                                    }
                                    size='sm'
                                    defaultValue={`1-${floorsTotal}`}
                                >
                                    <FontAwesomeIcon
                                        icon={['fas', 'surprise']}
                                    />
                                </ButtonWithModalOneField>
                                <HaveAccess access='housing.estate.flat.delete'>
                                    <ButtonWithConfirm
                                        modalText='Удалить?'
                                        variant='danger'
                                        label=''
                                        onClick={deleteFlat}
                                    >
                                        <FontAwesomeIcon
                                            icon={['fas', 'trash']}
                                        />
                                    </ButtonWithConfirm>
                                </HaveAccess>
                            </ButtonGroup>
                            {megasaleActive && !flat.sold && (
                                <ButtonWithAddMegaSale
                                    item={flatItem}
                                    setFlatItem={setFlatItem}
                                    type='primary'
                                />
                            )}
                        </div>
                    </form>
                )}
            />
        </Container>
    );
};

export default trackWindowScroll(FlatChess);
