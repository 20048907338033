import { createSlice, Slice } from '@reduxjs/toolkit';
import type { TAppDispatch, TState, TAnyAction } from '~/src/store';
import type { IRSModel } from '~/src/store/realtySecondary/realtySecondarySlice';
import { read, favorite } from '~/src/service/realty.secondary.service';

interface IMarkerIdAdd {
    items: IRSModel[];
    selectItem: IRSModel;
}
const initialState = {
    items: [],
    selectItem: null,
};
export const sliceName = 'markerIdAdd';
const imageSlice: Slice<IMarkerIdAdd> = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        setRealty: (state: IMarkerIdAdd, action: TAnyAction<IRSModel>) => {
            const newObj = action.payload;
            if (!state.items.some((item) => item.id === newObj.id)) {
                state.items.push(action.payload);
            }
        },
        setSelectedItem: (
            state: IMarkerIdAdd,
            action: TAnyAction<IRSModel>,
        ) => {
            state.selectItem = action.payload;
        },
        setFavourite: (state: IMarkerIdAdd, action: TAnyAction<number>) => {
            state.items
                .filter((elem) => elem.id == action.payload)
                .map((elems) => (elems.favorite = !elems.favorite));
        },

        setFavouriteSelectedItem: (state: IMarkerIdAdd) => {
            state.selectItem.favorite = !state.selectItem.favorite;
        },

        removeRealty: (state: IMarkerIdAdd, action: TAnyAction<number>) => {
            state.items.splice(action.payload, 1);
        },
        clearItems: (state: IMarkerIdAdd) => {
            state.items = [];
        },
        clearSelectedItem: (state: IMarkerIdAdd) => {
            state.selectItem = null;
        },
    },
});

export const {
    addIdMarker,
    setRealty,
    removeRealty,
    clearItems,
    setFavourite,
    setSelectedItem,
    clearSelectedItem,
    setFavouriteSelectedItem,
} = imageSlice.actions;

export const favorites = (id: number) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        const response = await favorite(id);
        if (response.success) {
            dispatch(setFavourite(id));
        }
    };
};

export const favoriteSelectedItem = (item: IRSModel) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(setFavouriteSelectedItem(item));
    };
};

export const addItem = (id: number) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        const item = await read(id);
        dispatch(setRealty(item));
    };
};

export const addSelectedItem = (item: IRSModel) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(setSelectedItem(item));
    };
};

export const clearSelectedItemNull = () => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(clearSelectedItem(null));
    };
};

export const deleteItem = (id: number) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(removeRealty(id));
    };
};

export const clear = () => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(clearItems({}));
    };
};

export const selectImageMarket = (state: TState): IMarkerIdAdd =>
    state.markerIdAdd;

export default imageSlice.reducer;
