import { IUser } from './user';
import Api from '~/src/helpers/http-common';
import { IResponseListData, IUpdateData } from '../interfaces/common';
import { IComment } from './comment';
import { TLane } from '../store/buyer/types';

export const all = async (
    queryParams?: URLSearchParams,
): Promise<IResponseListData<IBuyer>> => {
    return await Api.get(
        `/buyer?${queryParams != null ? queryParams.toString() : ''}`,
    );
};

export const getBoard = async (
    queryParams?: URLSearchParams,
): Promise<TLane[]> => {
    return await Api.get(
        `/buyer/board?${queryParams != null ? queryParams.toString() : ''}`,
    );
};

export const create = async (
    data: IBuyer,
    files?: Record<string, FileList>,
): Promise<IBuyer | any> => {
    return (await Api.post<IBuyer>('/buyer', data, files)) as IBuyer;
};

export const read = async (id: number): Promise<IBuyer> => {
    return await Api.get(`/buyer/${id}`);
};

export const update = async (
    id: string | number,
    data: IBuyer,
    files?: Record<string, FileList>,
): Promise<IUpdateData<IBuyer>> => {
    return await Api.put<IUpdateData<IBuyer>>(`/buyer/${id}`, data, files);
};

export const remove = async (id: string | number): Promise<any> => {
    return await Api.delete(`/buyer/${id}`);
};

export const getByCD = async (
    days: number | string,
    queryParams?: URLSearchParams,
): Promise<IBuyer[]> => {
    return await Api.get(
        `/buyer/getbycd/${days}?${queryParams != null ? queryParams.toString() : ''}`,
    );
};

export const getPopularSources = async (): Promise<string[]> => {
    return await Api.get('/buyer/sources/popular');
};

export const changeControlDate = async (
    id: string | number,
    data,
): Promise<IUpdateData<IBuyer & any>> => {
    return await Api.put<IUpdateData<IBuyer>>(
        `/buyer/${id}/change-control-date`,
        data,
    );
};

export const changeStatus = async (
    id: number,
    status: string,
): Promise<IUpdateData<IBuyer & any>> => {
    return await Api.post<IUpdateData<IBuyer>>(`/buyer/${id}/change-status`, {
        status: status,
    });
};

export const checkDoubleContactPhone = async (
    phone: string,
): Promise<IUpdateData<IBuyer & any>> => {
    return await Api.get(`/buyer/findforagency?phone=${phone}`);
};

export const statistics = async (
    queryParams?: URLSearchParams,
): Promise<IBuyerStatistics> => {
    return await Api.get(
        `/buyer/statistics?${queryParams != null ? queryParams.toString() : ''}`,
    );
};

export const transfer = async (
    id: number[],
    uid: string,
): Promise<IUpdateData<IBuyer & any>> => {
    return await Api.post('/buyer/transfer', {
        id: [...id],
        userUid: uid,
    });
};


export interface IBuyer {
    id: number;
    controlDate: Date;
    fullname: string;
    agentUid: string;
    newbie: boolean;
    email?: string;
    contactPhoneCountryCode: number;
    contactPhone: number;
    note: string;
    fields: Record<string, any>;
    source?: string;
    status: string;

    cash?: string;
    mortgage?: string;
    certificate?: string;
    payment?: string;
    purchasePeriod?: string;
    thermometer?: string;

    Expired?: boolean;

    createdAt: Date;
    createdBy: string;
    updatedAt: Date;
    updatedBy: string;

    agent?: IUser;
    isOutdated?: boolean;
    lastComment?: IComment;
}

export interface IBuyerStatistics {
    thermo?: IBuyerStatisticsThermo,
    realtyType?: IBuyerStatisticsRealtyType,
}

interface IBuyerStatisticsThermo {
    all: number,
    archive: number,
    hot: number,
    warm: number,
    cold: number
}
interface IBuyerStatisticsRealtyType {
    primary: number,
    secondary: number,
    house: number,
    land: number,
    townhouse: number,
    chalet: number
}

