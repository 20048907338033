function mergeObjects(...objects) {
    const result = {};
    objects.forEach((obj) => {
        obj.forEach((item) => {
            result[item.value] = 1;
        });
    });
    return result;
}

export { mergeObjects };
