import moment from 'moment';
import React, { useState } from 'react';
import arrayMutators from 'final-form-arrays';
import { Form as FinalForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useDispatch, useSelector } from 'react-redux';
import { selectAgency } from '~/src/store/agencySlice';
import DatePickerField from '../../Common/Form/DatePickerField/DatePickerField';
import OverlayingPopup from '../../Common/ModalUI/OverlayingPopup';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import CheckboxField from '~/src/components/Common/Form/CheckboxField';

import { TAppDispatch, TState } from '~/src/store';
import { IXmlFeedRealty } from '~/src/service/xml.feed';

type TProps = {
    item: IItem;
    xmlfeedaction: (id: number, data: Record<string, boolean>) => (dispatch: TAppDispatch, getState: () => TState) => Promise<void>
};

const ButtonWithXmlFeedRealtyControl = ({ item, xmlfeedaction }: TProps): JSX.Element => {
    const [isOpened, setIsOpened] = useState(false);

    const { xmlFeeds } = useSelector(selectAgency);

    const dispatch = useDispatch();

    const handleSubmit = (values: Record<string, any>) => {
        /* console.dir(values);

        return; */

        const prepared = values.feeds.map((feed) => {
            if (feed.promotion) {
                const p = [];

                for (const prop in feed.promotion) {
                    if (feed.promotion[prop] == 1) {
                        p.push(prop);
                    }
                }

                return {
                    ...feed,
                    promotion: p,
                };
            }

            return feed;
        });

        console.dir(prepared);

        dispatch(xmlfeedaction(item?.id, { feeds: prepared }));
        setIsOpened(false);
    };

    const close = () => setIsOpened(false);
    const open = () => setIsOpened(true);

    return (
        <React.Fragment>
            <Button
                size='sm'
                variant='secondary'
                title='Автовыгрузка'
                onClick={open}
            >
                Автовыгрузка
            </Button>
            <OverlayingPopup onClose={close} isOpened={isOpened}>
                <div className='main_popup' style={{ width: '800px' }}>
                    <p>Добавить / Убрать с автовыгрузки ID {item?.id}</p>
                    <FinalForm
                        onSubmit={handleSubmit}
                        initialValues={{
                            feeds: xmlFeeds?.map((feed) => {
                                return {
                                    id: feed.id,
                                    title: feed.title,
                                    type: feed.type,
                                    promotion: item?.xmlfeedunload
                                        ?.find((el) => el.xmlFeedId === feed.id)
                                        ?.promotion?.reduce(
                                            (a, v) => ({ ...a, [v]: 1 }),
                                            {},
                                        ),
                                    enabled:
                                        item?.xmlfeedunload?.find(
                                            (el) => el.xmlFeedId === feed.id,
                                        )?.enabled ?? false,
                                    unloadStartAt:
                                        item?.xmlfeedunload?.find(
                                            (el) => el.xmlFeedId === feed.id,
                                        )?.unloadStartAt ?? moment(),
                                    unloadEndAt:
                                        item?.xmlfeedunload?.find(
                                            (el) => el.xmlFeedId === feed.id,
                                        )?.unloadEndAt ??
                                        moment().add(30, 'days'),
                                };
                            }),
                        }}
                        mutators={{
                            setValue: (
                                [field, value],
                                state,
                                { changeValue },
                            ) => {
                                changeValue(state, field, () => value);
                            },
                            ...arrayMutators,
                        }}
                        validate={() => {
                            const errors: any = {};

                            return errors;
                        }}
                        render={({ handleSubmit, submitting, valid }) => (
                            <form onSubmit={handleSubmit}>
                                <Container fluid>
                                    <FieldArray name='feeds'>
                                        {({ fields }) =>
                                            fields.map((name, index) => (
                                                <React.Fragment key={name}>
                                                    <Row>
                                                        <Col>
                                                            <Form.Group className='mb-2'>
                                                                <Form.Label></Form.Label>
                                                            </Form.Group>
                                                            <CheckboxField
                                                                name={`${name}.enabled`}
                                                                label={
                                                                    fields
                                                                        .value[
                                                                        index
                                                                    ].title
                                                                }
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <DatePickerField
                                                                label='Начать выгрузку с'
                                                                name={`${name}.unloadStartAt`}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <DatePickerField
                                                                label='Закончить выгрузку'
                                                                name={`${name}.unloadEndAt`}
                                                                autocomplete='off'
                                                            />
                                                        </Col>
                                                    </Row>
                                                    {fields.value[index]
                                                        .type === 'cian' && (
                                                        <Row>
                                                            <Col>
                                                                <CheckboxField
                                                                    name={`${name}.promotion.top3`}
                                                                    label='top3'
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <CheckboxField
                                                                    name={`${name}.promotion.premium`}
                                                                    label='premium'
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <CheckboxField
                                                                    name={`${name}.promotion.paid`}
                                                                    label='paid'
                                                                />
                                                            </Col>
                                                        </Row>
                                                    )}
                                                    <hr />
                                                </React.Fragment>
                                            ))
                                        }
                                    </FieldArray>
                                    <Button
                                        size='sm'
                                        type='submit'
                                        variant='success'
                                        disabled={submitting || !valid}
                                    >
                                        Сохранить
                                    </Button>
                                </Container>
                            </form>
                        )}
                    />
                </div>
            </OverlayingPopup>
        </React.Fragment>
    );
};

export default ButtonWithXmlFeedRealtyControl;


export interface IItem {
    xmlfeedunload: IXmlFeedRealty[],
    id: number,
}
