import { useDispatch, useSelector } from 'react-redux';
import { MainForm } from './MainForm';
import { BasisForm } from './BasisFormView';
import { create, createExclusive, selectDHP } from '~/src/store/developer/developer.house.project';

const HouseProjectCreateView = () => {
    const dispatch = useDispatch();

    const { creating, cv } = useSelector(selectDHP);

    const onSubmit = (values: Record<string, any>) => {
        if (cv) {
            dispatch(
                createExclusive({
                    ...values,
                    price: values?.price.replaceAll(/\D/g, ''),
                    exclusiveForCv: true,
                }, undefined, cv.id),
            );
        } else {
            dispatch(
                create({
                    ...values,
                    price: values?.price.replaceAll(/\D/g, ''),
                }),
            );
        }
    };

    return (
        <MainForm initial={creating} onSubmit={onSubmit}>
            <BasisForm />
        </MainForm>
    );
};

export { HouseProjectCreateView };
