import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '~/src/helpers/hooks';
import InfoAgentsListView from '~/src/components/Info/agents';
import { getAgentsSingle } from '~/src/store/info.agency.users.slice';
import {
    setActiveMenuKey,
    setHandleScrollPosition,
} from '~/src/store/appSlice';

const InfoAgentsPage = (): JSX.Element => {
    const query = useQuery();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setActiveMenuKey('info-agents'));
    }, [dispatch]);

    useEffect(() => {
        dispatch(getAgentsSingle(query));
    }, [dispatch, query]);

    useEffect(() => {
        dispatch(setHandleScrollPosition(true));
    }, [dispatch]);

    return <InfoAgentsListView />;
};

export default InfoAgentsPage;
