import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInfoHousingEstate, selectHE } from '~/src/store/housingEstateSlice';
import ListView from '~/src/components/HousingEstate/Chess/houses';
import { useParams } from 'react-router-dom';
import { selectApp, setHandleScrollPosition } from '~/src/store/appSlice';
import { checkMegasale } from '~/src/store/megasaleSlice';

const HousingEstateHousesPage = (): JSX.Element => {
    const dispatch = useDispatch();
    const { housingEstateId } = useParams<{ housingEstateId: string }>();

    const { navPanel } = useSelector(selectHE);
    const { loading } = useSelector(selectApp);

    useEffect(() => {
        if (navPanel.estate !== +housingEstateId) {
            dispatch(getInfoHousingEstate(+housingEstateId));
        }
    }, [dispatch, housingEstateId, navPanel.estate]);

    useEffect(() => {
        dispatch(checkMegasale());
    }, [dispatch]);

    useEffect(() => {
        dispatch(setHandleScrollPosition(false));
    }, [dispatch]);

    console.log('HousingEstateHouses PAGE RENDERING');

    return loading ? <>Загрузка ...</> : <ListView />;
};

export default HousingEstateHousesPage;
