import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useInterval } from '~/src/helpers/hooks';
import { getUnverifiedCount } from '~/src/store/info.agency.users.slice';

const Check = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUnverifiedCount());
    }, []);

    useInterval(() => {
        dispatch(getUnverifiedCount());
    }, 30000);

    console.log('checking count unverified');

    return null;
};

export default Check;
