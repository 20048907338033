import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useQuery } from '~/src/helpers/hooks';
import CottageVillagePhases from '~/src/components/CottageVillage/Phases';
import { readForChess } from '~/src/store/cottage.village/cottage.village.store';

const CottageVillagePhasesPage = () => {
    const query = useQuery();
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        dispatch(readForChess(Number(id), query));
    }, [dispatch, id, query]);

    return <CottageVillagePhases />;
};

export { CottageVillagePhasesPage };
