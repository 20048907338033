import { IUser } from './user';
import { IFilestore } from './filestore';
import Api from '~/src/helpers/http-common';
import { IAddressPlaceDetail } from './address';
import { IResponseListData, IUpdateData } from '../interfaces/common';
import { IXmlFeed } from './xml.feed';

export const all = async (
    queryParams?: URLSearchParams,
): Promise<IResponseListData<IAgency>> => {
    return await Api.get(
        `/agency?${queryParams != null ? queryParams.toString() : ''}`,
    );
};

export const create = async (
    data: IAgency,
    files?: Record<string, FileList>,
): Promise<IAgency | any> => {
    return (await Api.post('/agency', data, files)) as IAgency;
};

export const read = async (id: number): Promise<IAgency> => {
    return await Api.get(`/agency/${id}`);
};

export const update = async (
    id: string | number,
    data: IAgency,
): Promise<IUpdateData<IAgency>> => {
    return await Api.put<IUpdateData<IAgency>>(`/agency/${id}`, data);
};

export const remove = async (id: string | number): Promise<any> => {
    return await Api.delete(`/agency/${id}`);
};

export const myUploadLogo = async (files?: FileList): Promise<any> => {
    return await Api.post('/agency/my/upload-logo', {}, { files: files });
};

export const inviteagent = async (
    data: IInviteAgent,
): Promise<{ success: boolean }> => {
    return await Api.post('/agency/inviteagent', data);
};

export const invitenewagent = async (
    data: IInviteNewAgent,
): Promise<{ success: boolean; errors?: string[] }> => {
    return await Api.post('agency/invitenewagent', data);
};

export const unloadGroups = async (
    id: string | number,
): Promise<IAgencyGroup[]> => {
    return await Api.get(`/agency/${id}/unloadgroups`);
};

export const unloadXmlFeeds = async (
    id: string | number,
): Promise<IXmlFeed[]> => {
    return await Api.get('/agency/my/unloadxmlfeeds');
};

export const createGroup = async (
    agencyId: string,
    title: string,
): Promise<IAgencyGroup> => {
    return await Api.post(`/agency/${agencyId}/group`, { title });
};

export const uploadAgents = async (files: FileList): Promise<any> => {
    return await Api.post('/agency/my/upload-agents', {}, { file: files });
};

export const createAgencyRequest = async (
    params,
): Promise<{ success: boolean }> => {
    return await Api.post('/agency/create-agency-request', params);
};

export interface IAgency {
    id?: number;
    uid?: string;
    directorUid?: string;
    addressPlaceDetailId?: number;
    addressNote?: string;
    title?: string;
    email?: string;
    foundationDate?: Date;
    contactPhoneCountryCode?: number;
    contactPhone?: number;
    createdAt?: Date;
    createdBy?: string;
    updatedAt?: Date;
    updatedBy?: string;

    addressDetail?: IAddressPlaceDetail;
    director?: Partial<IUser>;
    logo?: IFilestore;
    countUsersByPosition?: { count: number; position: string }[];
    secondaryTotal?: number;
    secondaryVerified?: number;
}

export interface IAgencyGroup {
    id: number;
    agencyId: number;
    title: string;
}

export interface IInviteAgent {
    agentUid: string;
    position: string;
    groupId?: number;
    phone: number;
}

export interface IInviteNewAgent {
    phone: number;
    firstname: string;
    position: string;
    groupId?: number;
}
