import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { FormApi } from 'final-form';
import { FC } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import { Field, useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import ConfirmButton from '~/src/components/Common/ModalUI/ButtonWithConfirm';
import {
    composeValidators,
    isEmail,
    mustBeNumber,
    onlyLength,
} from '~/src/helpers/validators';
import InputField from '../Common/Form/InputField';

const AdditionalContacts: FC = (): JSX.Element => {
    const form: FormApi = useForm();

    const handleDelete = async (index: number, fields) => {
        const house = fields.value[index];

        if (house && house.id) {
            console.log('delete contact from database');
            console.log('delete contact by index');
        } else {
            console.log('just remove from fields');
            fields.remove(index);
        }
    };

    return (
        <Container className={classNames('ps-5')} fluid>
            <Form.Label>Доп. контакты</Form.Label>
            <FieldArray name='contacts'>
                {({ fields }) =>
                    fields.map((name, index) => (
                        <div key={name}>
                            <ConfirmButton
                                variant='warning'
                                modalText='Удалить контакт'
                                label='Удалить контакт'
                                onClick={() => handleDelete(index, fields)}
                            >
                                <FontAwesomeIcon icon={['fas', 'trash']} />{' '}
                                Удалить контакт {fields.value[index]?.title}
                            </ConfirmButton>
                            <InputField
                                label='Email'
                                name={`${name}.email`}
                                validators={isEmail}
                            />
                            <InputField
                                label='Телефон'
                                name={`${name}.phone`}
                                validators={composeValidators(
                                    mustBeNumber,
                                    onlyLength(10),
                                )}
                            />
                            <Field name={`${name}.note`}>
                                {({ input, meta }) => (
                                    <Form.Group className='mb-3'>
                                        <Form.Label>Примечание</Form.Label>
                                        <Form.Control
                                            {...input}
                                            as='textarea'
                                            rows={2}
                                            size='sm'
                                            isInvalid={
                                                meta.error || meta.submitError
                                            }
                                            placeholder='Примечание'
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {meta.error || meta.submitError}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            </Field>
                            <hr />
                        </div>
                    ))
                }
            </FieldArray>
            <Form.Group>
                <Button
                    type='button'
                    size='sm'
                    onClick={() => form.mutators.push('contacts', {})}
                >
                    <FontAwesomeIcon icon={['fas', 'plus']} /> Добавить контакт
                </Button>
            </Form.Group>
        </Container>
    );
};

export default AdditionalContacts;
