import dateFormat from 'dateformat';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import React, { FC, useState } from 'react';
import Confirm from '../Common/ModalUI/AlertModals/Confirm';
import phoneFormatter from '~/src/helpers/phoneFormatter';
import { Button, ButtonGroup } from 'react-bootstrap';
import { IUser } from '~/src/service/user';
import HaveAccess from '../Common/HaveAccess';
import { remove } from '~/src/store/usersSlice';

const IList: FC<TListProps> = ({ item }) => {
    const dispatch = useDispatch();

    const [deleteConfirmShow, setDeleteConfirmShow] = useState(false);

    const handleDeleteClick = () => {
        dispatch(remove(item.id));
        setDeleteConfirmShow(false);
    };

    return (
        <React.Fragment>
            <tr>
                <td>
                    <Link to={`/user/${item.id}`}>{item.id}</Link>
                </td>
                <td>Фото</td>
                <td>
                    <strong>Роль: </strong>
                    {item.role}
                    <br />
                    <strong>Администратор: </strong>
                    {item.isAdmin ? 'Да' : 'Нет'}
                    <br />
                </td>
                <td>
                    <strong>{item.fullname}</strong>
                    <br />
                    <strong>Телефон: </strong>
                    {phoneFormatter(item.phone)}
                    <br />
                    {item.email && (
                        <React.Fragment>
                            <strong>Email: </strong>
                            {item.email}
                            <br />
                        </React.Fragment>
                    )}
                    {item.birthdate && (
                        <React.Fragment>
                            <strong>
                                День Рождения:{' '}
                                {dateFormat(item.birthdate, 'dd.mm.yyyy')}
                            </strong>
                            <br />
                        </React.Fragment>
                    )}
                </td>
                <td>
                    <HaveAccess access='users.all' roles={'grand'}>
                        <ButtonGroup vertical={true} className='w-100'>
                            {/* <Link
                                to={`/user/card/${item.id}`}
                                className='btn btn-sm btn-secondary'
                                title='Карточка'
                            >Карточка</Link> */}
                            <Link
                                to={`/users/edit/${item.id}`}
                                className='btn btn-sm btn-warning'
                                title='Редактировать'
                            >
                                Редактировать
                            </Link>
                            <Button
                                type='button'
                                size='sm'
                                variant='danger'
                                onClick={() => setDeleteConfirmShow(true)}
                            >
                                Удалить
                            </Button>
                        </ButtonGroup>
                    </HaveAccess>
                </td>
            </tr>
            <Confirm
                text='Удалить? Это действие безвозвратно!'
                onClose={() => setDeleteConfirmShow(false)}
                isOpened={deleteConfirmShow}
                primaryButtonOnClick={handleDeleteClick}
                secondaryButtonOnClick={() => setDeleteConfirmShow(false)}
            />
        </React.Fragment>
    );
};

type TListProps = {
    item: IUser;
};

export default IList;
