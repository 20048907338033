import React, { ReactNode } from 'react';
import { Button, Card } from 'react-bootstrap';
import OverlayingPopup from '../OverlayingPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonWithModalOneField from '../ModalOneField/ButtonWith';

type TProps = {
    isOpened: boolean;
    title?: string;
    onClose: () => void;
    children: ReactNode | undefined;
    fullwidth?: boolean;
    overflow?: boolean;
    handleLeaveComment: (content: string) => Promise<void>;
};

const ModalCommentsBuyer = ({
    title,
    onClose,
    children,
    isOpened,
    fullwidth = false,
    overflow = false,
    handleLeaveComment,
}: TProps): JSX.Element => {
    return (
        <OverlayingPopup isOpened={isOpened} onClose={onClose}>
            <div
                className={'main_popup'}
                style={{
                    overflowY: `${overflow ? 'scroll' : 'visible'}`,
                    width: `${fullwidth ? '80vw' : ''}`,
                    height: `${fullwidth ? '90vh' : ''}`,
                }}
            >
                <Card>
                    <Card.Header className='d-flex justify-content-between'>
                        {title}
                        <div style={{ display: 'flex', gap: '7px' }}>
                            <ButtonWithModalOneField
                                modalText={'Оставить комментарий'}
                                primaryButtonOnClick={handleLeaveComment}
                            >
                                <FontAwesomeIcon icon={['fas', 'comment']} />{' '}
                                <small>Оставить комментарий</small>
                            </ButtonWithModalOneField>
                            <br />
                            <Button variant='dark' onClick={onClose}>
                                <FontAwesomeIcon
                                    icon={['fas', 'window-close']}
                                />
                            </Button>
                        </div>
                    </Card.Header>
                    <Card.Body>{children}</Card.Body>
                </Card>
            </div>
        </OverlayingPopup>
    );
};

export default ModalCommentsBuyer;
