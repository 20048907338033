import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Button, ButtonGroup, Navbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { selectCV } from '~/src/store/cottage.village/cottage.village.store';

import { ChessPhase } from './chess';
import { PhasesNav } from './PhasesNav';

export default function CottageVillagePhases() {
    const navigate = useNavigate();

    const { item, loading, activePhase } = useSelector(selectCV);

    if (item === null || activePhase === null) return <>...</>;

    return (
        <>
            <Navbar
                className='justify-content-between'
                bg='light'
                expand='lg'
                variant='light'
            >
                <ButtonGroup>
                    <Button
                        size='sm'
                        onClick={() => navigate('/cottage-village')}
                    >
                        <FontAwesomeIcon icon={['fas', 'fast-backward']} />{' '}
                        Назад
                    </Button>
                </ButtonGroup>
            </Navbar>
            <PhasesNav />
            <Navbar
                className='justify-content-between'
                bg='light'
                expand='lg'
                variant='light'
            >
                <ButtonGroup>
                    <Link
                        to={`/cottage-village/${item.id}/phases/${activePhase.id}/generate`}
                        className='btn btn-sm btn-success'
                    >
                        <FontAwesomeIcon icon={['fas', 'magic']} />{' '}
                        Сгенерировать участки
                    </Link>
                </ButtonGroup>
            </Navbar>
            {loading ? (
                <>Данные загружаются</>
            ) : (
                <div style={{ overflowX: 'scroll' }}>
                    <ChessPhase />
                </div>
            )}
        </>
    );
}
