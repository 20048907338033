import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { IRSUBModel } from '~/src/store/realtySuburban/realtySuburbanSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup, Nav } from 'react-bootstrap';
import HaveAccess from '../../../Common/HaveAccess';
import {
    unverify,
    verify,
} from '~/src/store/realtySuburban/realtySuburbanSlice.moderate';
import ConfirmButton from '~/src/components/Common/ModalUI/ButtonWithConfirm';
import * as APConstants from '~/src/constants/agency.permission';
import ButtonWithModerateReject from '../../../Common/Button/ButtonWithModerateReject';
// import ButtonWithXmlFeedRealtyControl from '../ButtonWithXmlFeedRealtyControl';

export default function SUBCardViewNav({
    item,
}: {
    item: IRSUBModel;
}): JSX.Element {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleConfirmModerate = () => {
        dispatch(verify(item.id));
    };
    return (
        <React.Fragment>
            <Nav className='justify-content-between'>
                <ButtonGroup>
                    <Button
                        title='Назад'
                        size='sm'
                        variant='primary'
                        onClick={() => navigate(-1)}
                    >
                        <FontAwesomeIcon icon={['fas', 'backward']} /> Назад
                    </Button>
                    {item.isMy && (
                        <Link
                            to={`/realty-suburban/edit/${item.id}`}
                            className='btn btn-sm btn-warning'
                            title='Редактировать'
                        >
                            Редактировать
                        </Link>
                    )}
                </ButtonGroup>
                <ButtonGroup>
                    <HaveAccess
                        access='xml.feed.realty.add'
                        agencyId={item.agentInfo?.agencyInfo?.agency?.id}
                        agencyAccess={APConstants.XML_FEED_REALTY_ADD}
                        roles='grand'
                    >
                        {/* <ButtonWithXmlFeedRealtyControl item={item} /> */}
                    </HaveAccess>
                    {item.verified === false &&
                        item.isFullfied &&
                        item.isEspecial ? (
                        <HaveAccess access='realty.secondary.moderate'>
                            <ConfirmButton
                                variant='success'
                                label='Подтвердить'
                                modalText={`Объект ${item.id} окажется в общей базе, подтвердить?`}
                                onClick={handleConfirmModerate}
                            >
                                Подтвердить
                            </ConfirmButton>
                            <ButtonWithModerateReject
                                id={item.id}
                                unverify={unverify}
                            />
                        </HaveAccess>
                    ) : null}
                </ButtonGroup>
            </Nav>
        </React.Fragment>
    );
}
