import queryString from 'query-string';
import * as api from '~/src/service/address';
import { IAddressPlaceDetail as IAddress } from '../service/address';
import { createSlice, Slice } from '@reduxjs/toolkit';
import { IResponseListData } from '../interfaces/common';
import { redirect, setLoading as setAppLoading } from './appSlice';
import type { TAppDispatch, TState, TAnyAction } from '~/src/store';

export const sliceName = 'address';

interface IAddressSlice {
    items: IResponseListData<IAddress>;
    item?: IAddress;
    search?: any;
    errors?: any;
    navLink?: string;
    baseLink?: string;
}

const initialState = {
    items: {},
    search: {
        sort: 'id_desc',
        limit: 25,
        page: 1,
    },
    navLink: '/address?page=1&limit=25',
    baseLink: '/address',
};

const AddressSlice: Slice<IAddressSlice> = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        setAll: (
            state: IAddressSlice,
            action: TAnyAction<IResponseListData<IAddress>>,
        ) => {
            state.items = action.payload;
        },
        set: (state: IAddressSlice, action: TAnyAction<IAddress>) => {
            state.item = action.payload;
        },
        setErrors: (state: IAddressSlice, action: TAnyAction<any>) => {
            state.errors = action.payload;
        },
        setSearch: (state: IAddressSlice, action: TAnyAction<any>) => {
            state.search = action.payload;
        },
    },
});

const { setAll, set, setErrors, setSearch } = AddressSlice.actions;

export const all = (queryParams: URLSearchParams = null) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(setAppLoading(true));
        const response = await api.all(queryParams);
        const obj = {};

        for (const key of queryParams.keys()) {
            if (queryParams.getAll(key).length > 1) {
                obj[key] = queryParams.getAll(key);
            } else {
                obj[key] = queryParams.get(key);
            }
        }

        dispatch(setSearch(obj));
        dispatch(setAll(response));
        dispatch(setAppLoading(false));
    };
};

export const read = (id: number) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const data = await api.read(id);
            dispatch(set(data));
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const update = (id: number, data: any) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const response = await api.update(id, data);
            if (response && response.success) {
                dispatch(set(response.data));
            } else {
                dispatch(setErrors(response.errors));
            }
        } catch (e) {
            console.error(e);
            dispatch(setErrors(e));
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const goTo =
    (params: Record<string, any>) =>
    (dispatch: TAppDispatch, getState: () => TState): void => {
        if (params.reset === true) {
            params = initialState.search;
        } else params = { ...getState().address.search, ...params };

        dispatch(
            redirect(
                `${initialState.baseLink}?${queryString.stringify(params, { skipNull: true })}`,
            ),
        );
    };

export const selectAddress = (state: TState): IAddressSlice => state.address;

export default AddressSlice.reducer;
