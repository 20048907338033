import { useRef, useEffect, useLayoutEffect } from 'react';
import { useDispatch, TypedUseSelectorHook, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { TAppDispatch, TState } from '../store';

export const useAppDispatch = () => useDispatch<TAppDispatch>();
export const useAppSelector: TypedUseSelectorHook<TState> = useSelector;

export const useQuery = (): URLSearchParams =>
    new URLSearchParams(useLocation().search);

export const useTimeout = (
    callback: () => void,
    delay: number | null,
): void => {
    const savedCallback = useRef(callback);

    useLayoutEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        if (!delay && delay !== 0) {
            return;
        }

        const id = setTimeout(() => savedCallback.current(), delay);

        return () => clearTimeout(id);
    }, [delay]);
};

export const useInterval = (callback: () => void, delay: number): void => {
    const savedCallback = useRef(callback);

    useLayoutEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }

        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
};
