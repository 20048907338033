export const realtyTypes = [
    {
        title: 'Вторичка',
        code: 'secondary',
    },
    {
        title: 'Новостройка',
        code: 'primary',
    },
    {
        title: 'Дом',
        code: 'house',
    },
    {
        title: 'Земельный участок',
        code: 'land',
    },
    {
        title: 'Таунхаус',
        code: 'townhouse',
    },
    {
        title: 'Дача',
        code: 'chalet',
    },
];
