const suburbanRenovationSearch = [
    { label: 'Все', value: '' },
    { label: 'Евро ремонт', value: 'euro' },
    { label: 'Дизайнерский', value: 'design' },
    { label: 'С ремонтом', value: 'standard' },
    { label: 'Без отделки', value: 'withoutFinishing' },
    { label: 'Требуется ремонт', value: 'noDecoration' },
    { label: 'White Box', value: 'whiteBox' },
    { label: 'Чистовая отделка', value: 'fineFinishing' },
];

const suburbanRenovation = [
    { title: '', value: '' },
    { title: 'Евро ремонт', value: 'euro' },
    { title: 'Дизайнерский', value: 'design' },
    { title: 'С ремонтом', value: 'standard' },
    { title: 'Без отделки', value: 'withoutFinishing' },
    { title: 'Требуется ремонт', value: 'noDecoration' },
    { title: 'White Box', value: 'whiteBox' },
    { title: 'Чистовая отделка', value: 'fineFinishing' },
];

export { suburbanRenovationSearch, suburbanRenovation };
