import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { selectApp } from '~/src/store/appSlice';
import { read, set } from '~/src/store/xml.feed';
import { useDispatch, useSelector } from 'react-redux';
import UpdateForm from '~/src/components/XmlFeed/update';

const UpdatePage = (): JSX.Element => {
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();

    const { loading } = useSelector(selectApp);

    useEffect(() => {
        if (id !== undefined) {
            dispatch(read(+id));
        } else dispatch(set({}));
    }, [id, dispatch]);

    return (
        <React.Fragment>
            {loading ? <div>Загрузка...</div> : <UpdateForm />}
        </React.Fragment>
    );
};

export default UpdatePage;
