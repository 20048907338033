import { useDispatch, useSelector } from 'react-redux';

import { PlotChessCard } from '../Plots/PlotChessCard';
import Pagination from '~/src/components/Common/Pagination';

import { selectCVP } from '~/src/store/cottage.village/cottage.village.plot.store';
import { useLocation } from 'react-router';
import { redirect } from '~/src/store/appSlice';

const ChessPhase = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const { items } = useSelector(selectCVP);

    const handlePaginationClick = (page: number) => {
        dispatch(redirect(`${location.pathname}?page=${page}`));
    };

    return (
        <div className='d-flex flex-wrap gap-2'>
            {items && items.items && items.items.length > 0
                ? items.items.map((item) => (
                      <PlotChessCard key={item.id} plot={item} />
                  ))
                : null}
            {items && items.pagination?.have && (
                <Pagination
                    pagination={items.pagination}
                    onClick={handlePaginationClick}
                />
            )}
        </div>
    );
};

export { ChessPhase };
