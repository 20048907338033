import { useSelector } from 'react-redux';

import { Field, useForm } from 'react-final-form';
import { Col, Container, Form, Row } from 'react-bootstrap';

import InputField from '../../Common/Form/InputField';
import SimpleSelect from '../../Common/Form/SimpleSelect';
import CheckboxField from '../../Common/Form/CheckboxField';
import ReactSelectField from '../../Common/Form/ReactSelectField';

import { suburbanMaterial } from '~/src/common/data/suburban.material';
import { suburbanGarageStatus } from '~/src/common/data/suburban.garage.status';

import { selectDeveloper } from '~/src/store/developer/developerSlice';

import {
    composeValidators,
    mustBeNumber,
    price,
    required,
    space,
} from '~/src/helpers/validators';
import { selectDHP } from '~/src/store/developer/developer.house.project';

const BasisForm = () => {
    const form = useForm();

    const { unloadedSuburban: developers } = useSelector(selectDeveloper);
    const { cv } = useSelector(selectDHP);

    const devChoosen =
        developers?.map((d) => ({
            value: d.id,
            label: d.title,
        })) || [];

    return (
        <Container fluid>
            <Row>
                <Col>
                    <InputField
                        name='title'
                        label='Название'
                        validators={required}
                    />
                    <SimpleSelect
                        label='Материал'
                        name='material'
                        opts={suburbanMaterial}
                        validators={required}
                    />
                    <InputField
                        label='Цена'
                        name='price'
                        type='text'
                        onChange={(e) =>
                            form.mutators.setPriceValue('price', e.target.value)
                        }
                        validators={composeValidators(required, price)}
                    />
                    <InputField
                        label='Площадь общая'
                        name='spaceTotal'
                        type='text'
                        onChange={(e) =>
                            form.mutators.setDecimalValue(
                                'spaceTotal',
                                e.target.value,
                            )
                        }
                        validators={composeValidators(required, space)}
                    />
                    <InputField
                        label='Этажность'
                        name='storeys'
                        type='text'
                        validators={composeValidators(required, mustBeNumber)}
                    />
                    {
                        cv && <InputField
                            label='Кол-во санузлов'
                            name='bathroomTotal'
                            type='text'
                            validators={composeValidators(required, mustBeNumber)}
                        />
                    }
                </Col>
                <Col>
                    <ReactSelectField
                        label='Застройщик'
                        name='developerId'
                        options={devChoosen}
                        value={devChoosen?.find(
                            (d) =>
                                d.value === form.getState().values.developerId,
                        )}
                        onChange={(v) =>
                            form.mutators.setSelectValue('developerId', v)
                        }
                        validators={required}
                    />
                    <SimpleSelect
                        label='Гараж'
                        name='garage'
                        opts={suburbanGarageStatus}
                        validators={required}
                    />
                    <InputField
                        label='Акционная цена'
                        name='priceDiscount'
                        type='text'
                        onChange={(e) =>
                            form.mutators.setPriceValue('priceDiscount', e.target.value)
                        }
                    />
                    {
                        cv && <InputField
                            label='Площадь участка'
                            name='area'
                            type='text'
                            onChange={(e) =>
                                form.mutators.setDecimalValue(
                                    'area',
                                    e.target.value,
                                )
                            }
                            validators={composeValidators(required, space)}
                        />
                    }
                    <InputField
                        label='Кол-во спальных комнат'
                        name='rooms'
                        type='text'
                        validators={composeValidators(required, mustBeNumber)}
                    />
                    {
                        !cv && <InputField
                            label='Кол-во санузлов'
                            name='bathroomTotal'
                            type='text'
                            validators={composeValidators(required, mustBeNumber)}
                        />
                    }
                </Col>
            </Row>
            <Field name='description'>
                {({ input, meta }) => (
                    <Form.Group className='mb-3'>
                        <Form.Label>Описание</Form.Label>
                        <Form.Control
                            {...input}
                            as='textarea'
                            rows={5}
                            size='sm'
                            isInvalid={meta.error || meta.submitError}
                            placeholder='Описание'
                        />
                        <Form.Control.Feedback type='invalid'>
                            {meta.error || meta.submitError}
                        </Form.Control.Feedback>
                    </Form.Group>
                )}
            </Field>
            <Row>
                <Col>
                    <CheckboxField name='terrace' label='Терраса' />
                </Col>
                <Col>
                    <CheckboxField name='isInstallment' label='Возможность строительства в рассрочку, без переплаты' />
                    <CheckboxField name='isDiscountFullPay' label='Строительство со скидкой при единовременной полной оплате' />
                    <CheckboxField name='isStateProgramm' label='Строительство по Государственным программам, сертификатам' />

                </Col>
                <Col>
                    <CheckboxField name='isExchange' label='Возможность строительства с обменом на свою недвижимость' />
                    <CheckboxField name='buildWoPv' label='Строительство без ПВ' />
                </Col>
            </Row>
            <Row style={{
                height: '100px'
            }}>

            </Row>
        </Container>
    );
};

export { BasisForm };
