import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Form as FinalForm } from 'react-final-form';
import TextareaField from '../../Common/Form/TextareaField';
import { create } from '~/src/store/comment';

export const CommentForm = ({
    section,
    entry,
}: {
    section: string;
    entry: number;
}) => {
    const dispatch = useDispatch();

    const onSubmit = (values: Record<string, any>) => {
        dispatch(create(values));
    };

    console.log('RENDER CommentForm');

    return (
        <div className='mb-1'>
            <FinalForm
                onSubmit={onSubmit}
                initialValues={{
                    section,
                    entry,
                }}
                mutators={{
                    setValue: ([field, value], state, { changeValue }) => {
                        changeValue(state, field, () => value);
                    },
                }}
                validate={(values) => {
                    const errors: any = {};
                    return errors;
                }}
                render={({ handleSubmit, form }) => (
                    <form
                        onSubmit={(values) => {
                            handleSubmit(values);
                            form.reset();
                        }}
                    >
                        <TextareaField
                            name={'content'}
                            label={'Введите новый комментарий'}
                            rows={3}
                        />
                        <Button size='sm' variant='success' type='submit'>
                            Сохранить
                        </Button>
                    </form>
                )}
            />
        </div>
    );
};
