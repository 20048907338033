import { FC, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { selectApp } from '~/src/store/appSlice';
import { logout, selectAuth } from '~/src/store/authSlice';
import { useDispatch, useSelector } from 'react-redux';

const Logout: FC = () => {
    const dispatch = useDispatch();

    const { me } = useSelector(selectAuth);
    const { loading } = useSelector(selectApp);

    useEffect(() => {
        dispatch(logout());
    }, [dispatch]);

    if (loading || me) {
        return null;
    }

    return <Navigate to={'/login'} replace />;
};

export default Logout;
