import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ButtonGroup } from 'react-bootstrap';
import { remove } from '~/src/store/permission';
import { IPermission } from '~/src/service/permission';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonWithConfirm from '../Common/ModalUI/ButtonWithConfirm';

const ListRow: FC<{ item: IPermission }> = ({ item }) => {
    const dispatch = useDispatch();

    const handleDelete = () => {
        dispatch(remove(item.id));
    };

    return (
        <React.Fragment>
            <tr>
                <td>
                    <p>{item.id}</p>
                </td>
                <td>
                    <p>{item.title}</p>
                </td>
                <td>{item.code}</td>
                <td>{item.group}</td>
                <td>
                    <ButtonGroup>
                        <Link
                            to={`/permission/update/${item.id}`}
                            className='btn btn-sm btn-warning'
                            title='Редактировать'
                        >
                            <FontAwesomeIcon icon={['fas', 'pencil-alt']} />
                        </Link>
                        <ButtonWithConfirm
                            label=''
                            modalText={`Удалить правило: ${item.title}?`}
                            variant='danger'
                            onClick={handleDelete}
                        >
                            <FontAwesomeIcon icon={['fas', 'trash']} />
                        </ButtonWithConfirm>
                    </ButtonGroup>
                </td>
            </tr>
        </React.Fragment>
    );
};

export default ListRow;
