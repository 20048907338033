import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

const LineChart = ({ dataSet }) => {
    // const isEmpty = data.datasets.every(dataset => dataset.data.every(value => Number(value) == 0));

    // цвет сегмента
    const getSegmentColor = (ctx) => {
        const { p0, p1 } = ctx;
        return p1.parsed.y > p0.parsed.y ? 'rgb(198, 1, 56)' : 'rgb(131, 231, 45)';
    };

    // цвет точки изгиба
    const getPointBackgroundColor = (index, data) => {
        if (index === 0) return 'rgb(0, 0, 0, 0.3)'; // Начальная точка
        return Number(data[index]) > Number(data[index - 1]) ? 'rgb(198, 1, 56)' : 'rgb(131, 231, 45)';
    };

    return <Line data={{
        labels: dataSet.map((data) => data.label),
        datasets: [
            {
                label: 'Цена',
                data: dataSet.map((data) => data.data),
                // backgroundColor: 'rgb(19, 175, 240, 0.4)',
                backgroundColor: dataSet.map((_, index) => getPointBackgroundColor(index, dataSet.map((data) => data.data))),
                segment: {
                    borderColor: (ctx) => getSegmentColor(ctx),
                },
                fill: false,
                spanGaps: true,
                pointStyle: 'circle',
                pointRadius: 10,
                pointHoverRadius: 15
            }
        ],

    }}
        options={
            {
                interaction: {
                    intersect: false
                },
                plugins: {
                    legend: {
                        display: true,
                        labels: {
                            usePointStyle: true,
                            pointStyle: 'line',
                        },
                    },
                    tooltip: {
                        backgroundColor: '#f5f5f5',
                        titleColor: '#333',
                        bodyColor: '#666',
                        borderColor: '#ddd',
                        borderWidth: 1,
                        callbacks: {
                            title: (tooltipItems) => {
                                return `Дата: ${tooltipItems[0].label}`;
                            },
                            label: (tooltipItem) => {
                                const user = dataSet[tooltipItem.dataIndex].user;
                                const price = `Цена: ${new Intl.NumberFormat('ru-RU').format(+tooltipItem.parsed.y)}`;
                                const userLabel = `Пользователь: ${user}`;
                                return [price, userLabel];
                            },
                            labelPointStyle: (context) => {
                                return {
                                    pointStyle: 'line',
                                    rotation: 0,
                                };
                            },
                        },
                    },
                },
            }
        }
    />;
};

export default LineChart;
