import React, { useState, ReactNode } from 'react';
import { Button, ButtonProps } from 'react-bootstrap';
import Confirm from '../AlertModals/Confirm';

type TProps = {
    label: string;
    variant?: string;
    onClick: (e) => void;
    modalText: string;
    children?: ReactNode;
};

interface IProps extends ButtonProps {
    modalText: string;
    children?: ReactNode;
    label?: string;
}

const ButtonWithConfirm = ({
    label,
    variant,
    onClick,
    modalText,
    children,
}: IProps): JSX.Element => {
    const [isOpened, setIsOpened] = useState(false);

    const handleClick = (e) => {
        onClick(e);
        setIsOpened(false);
    };

    const close = () => setIsOpened(false);
    const open = () => setIsOpened(true);

    return (
        <React.Fragment>
            <Button
                size='sm'
                variant={variant || 'secondary'}
                title={label}
                onClick={open}
            >
                {children || label}
            </Button>
            <Confirm
                text={modalText}
                onClose={close}
                isOpened={isOpened}
                primaryButtonOnClick={handleClick}
                secondaryButtonOnClick={close}
            />
        </React.Fragment>
    );
};

export default ButtonWithConfirm;
