import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Badge, ButtonGroup } from 'react-bootstrap';
import { IDeveloper } from '~/src/service/developer/developer';
import phoneFormatter from '~/src/helpers/phoneFormatter';

const ListRow: FC<{ item: IDeveloper }> = ({ item }) => {
    return (
        <React.Fragment>
            <tr>
                <td>{item.id}</td>
                <td style={{
                    maxWidth: '200px'
                }}>
                    {item.title}
                    {item?.isCrucialPartner == 1 && (
                        <>
                            <br />
                            <Badge className='bg-success'>Ключевой партнер</Badge>
                        </>
                    )}
                </td>
                <td>
                    {
                        item?.cv.length > 0 &&
                        <>
                            <b>КП</b>
                            <ul style={{
                                listStyleType: 'square'
                            }}>
                                {item?.cv.map((_, index) => <li key={index}>
                                    {
                                        _.title
                                    }
                                </li>)}
                            </ul>
                        </>
                    }
                    {
                        item?.estates.length > 0 &&
                        <>
                            <b>ЖК</b>
                            <ul style={{
                                listStyleType: 'square'
                            }}>
                                {item?.estates.map((_, index) => <li key={index}>
                                    {
                                        _.title
                                    }
                                </li>)}
                            </ul>
                        </>
                    }
                    {
                        item?.projects.length > 0 &&
                        <>
                            <b>Подрядчик</b>
                        </>
                    }
                </td>
                <td style={{
                    maxWidth: '400px'
                }}>{item?.description}</td>
                <td>
                    {item.contactPhone && (
                        <p>
                            <strong>Телефон основной:</strong>
                            <br />
                            <span>{phoneFormatter(item.contactPhone)}</span>
                        </p>
                    )}
                    {item.email && (
                        <p>
                            <strong>Email:</strong>
                            <br />
                            <span>{item.email}</span>
                        </p>
                    )}
                    {item.contacts?.map((contact) => {
                        return (
                            <React.Fragment key={contact.id}>
                                {contact.note && <p>{contact.note}</p>}
                                {contact.phone && (
                                    <p>{phoneFormatter(`${contact.phone}`)}</p>
                                )}
                                {contact.email && <p>{contact.email}</p>}
                                <br />
                            </React.Fragment>
                        );
                    })}
                </td>
                <td>
                    <ButtonGroup vertical>
                        <Link
                            to={`/developer/update/${item.id}`}
                            className='btn btn-sm btn-warning'
                            title='Редактировать'
                        >
                            Редактировать
                        </Link>
                    </ButtonGroup>
                </td>
            </tr>
        </React.Fragment>
    );
};

export default ListRow;
