import { FC } from 'react';
import classNames from 'classnames';
import { FormApi } from 'final-form';
import InputField from '../Common/Form/InputField';
import { FieldArray } from 'react-final-form-arrays';
import SimpleSelect from '../Common/Form/SimpleSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import HousingEstateHousePorchesFormBlock from './HousingEstateHousePorchesFormBlock';
import {
    composeValidators,
    maxEqValue,
    minEqValue,
    mustBeNumber,
    required,
} from '~/src/helpers/validators';
import { remove as removehouse } from '~/src/service/housing.estate.house';
import { useForm } from 'react-final-form';
import ConfirmButton from '~/src/components/Common/ModalUI/ButtonWithConfirm';

const HousingEstateHouseFormBlock: FC = (): JSX.Element => {
    const form: FormApi = useForm();

    const handleDeleteHouse = async (index: number, fields) => {
        const house = fields.value[index];

        if (house && house.id) {
            console.log('delete house from database');
            console.log('delete house by index');
            const response = await removehouse(house.id);
            response.success && fields.remove(index);
        } else {
            console.log('just remove from fields');
            fields.remove(index);
        }
    };

    return (
        <Container className={classNames('ps-5')} fluid>
            <Form.Label>Дома ЖК</Form.Label>
            <FieldArray name='houses'>
                {({ fields }) =>
                    fields.map((name, index) => (
                        <div key={name}>
                            <ConfirmButton
                                variant='warning'
                                modalText={`Удалить дом ${fields.value[index]?.title}`}
                                label={`Удалить дом ${fields.value[index]?.title}`}
                                onClick={() => handleDeleteHouse(index, fields)}
                            >
                                <FontAwesomeIcon icon={['fas', 'trash']} />{' '}
                                Удалить дом {fields.value[index]?.title}
                            </ConfirmButton>
                            <InputField
                                label='Название дома'
                                name={`${name}.title`}
                                validators={required}
                            />
                            <Row>
                                <Col>
                                    <Form.Label>
                                        Срок сдачи дома в эксплуатацию
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <SimpleSelect
                                        label='Квартал'
                                        name={`${name}.quarter`}
                                        opts={[
                                            { title: '', value: '' },
                                            { title: '1', value: '1' },
                                            { title: '2', value: '2' },
                                            { title: '3', value: '3' },
                                            { title: '4', value: '4' },
                                        ]}
                                        validators={composeValidators(required)}
                                    />
                                </Col>
                                <Col>
                                    <InputField
                                        label='Год'
                                        name={`${name}.year`}
                                        validators={composeValidators(
                                            required,
                                            mustBeNumber,
                                            minEqValue(1900),
                                            maxEqValue(2100),
                                        )}
                                    />
                                </Col>
                            </Row>
                            <SimpleSelect
                                label='Материал'
                                name={`${name}.houseMaterial`}
                                opts={[
                                    { title: '', value: '' },
                                    { title: 'Кирпич', value: 'brick' },
                                    { title: 'Панель', value: 'panel' },
                                    { title: 'Монолит', value: 'monolith' },
                                ]}
                                validators={required}
                            />
                            <Row>
                                <Col>
                                    <InputField
                                        label='Avito Xml Id'
                                        name={`${name}.avitoXmlId`}
                                        validators={composeValidators(required)}
                                    />
                                </Col>
                            </Row>
                            <Form.Label>
                                Подъезды дома{' '}
                                {form.getState().values.houses[index].title}
                            </Form.Label>
                            <HousingEstateHousePorchesFormBlock
                                houseName={name}
                            />
                            <Form.Group>
                                <Button
                                    type='button'
                                    size='sm'
                                    onClick={() =>
                                        form.mutators.push(
                                            `${name}.porches`,
                                            {},
                                        )
                                    }
                                >
                                    <FontAwesomeIcon icon={['fas', 'plus']} />{' '}
                                    Добавить Подъезд
                                </Button>
                            </Form.Group>
                            <hr />
                        </div>
                    ))
                }
            </FieldArray>
            <Form.Group>
                <Button
                    type='button'
                    size='sm'
                    onClick={() => form.mutators.push('houses', {})}
                >
                    <FontAwesomeIcon icon={['fas', 'plus']} /> Добавить дом
                </Button>
            </Form.Group>
        </Container>
    );
};

export default HousingEstateHouseFormBlock;
