function phoneFormatter(value: string | number, formatter = 'ru'): string {
    value = (value || '').toString();

    switch (formatter) {
        case 'ru':
            if (/^9\d{9}$/.test(value)) {
                value = value.replace(
                    /(\d{3})(\d{3})(\d{2})(\d{2})/,
                    '8 ($1) $2-$3-$4',
                );
            } else if (/^800\d{7}$/.test(value)) {
                value = value.replace(/(\d{3})(\d{4})(\d{3})/, '8 ($1) $2-$3');
            } else if (/^\d{10}$/.test(value)) {
                value = value.replace(/(\d{4})(\d{3})(\d{3})/, '8 ($1) $2-$3');
            }

            return value;

        default:
            return value;
    }
}

export default phoneFormatter;
