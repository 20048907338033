import React, { useEffect } from 'react';
import { read } from '~/src/store/agencySlice';
import { selectApp, setActiveMenuKey } from '~/src/store/appSlice';
import { selectAuth } from '~/src/store/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import UpdateForm from '~/src/components/Agency/update';

const MyAgencyPage = (): JSX.Element => {
    const dispatch = useDispatch();

    const { me } = useSelector(selectAuth);
    const { loading } = useSelector(selectApp);

    useEffect(() => {
        dispatch(setActiveMenuKey('AgencyProfile'));
    }, [dispatch]);

    useEffect(() => {
        if (me && me.isDirector && me.myAgency !== null) {
            dispatch(read(+me.myAgency.id));
        }
    }, [me, dispatch]);

    return (
        <React.Fragment>
            {loading ? <div>Загрузка...</div> : <UpdateForm />}
        </React.Fragment>
    );
};

export default MyAgencyPage;
