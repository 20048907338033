import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { selectApp } from '~/src/store/appSlice';
import { required } from '~/src/helpers/validators';
import { Form as FinalForm } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import SimpleSelectField from '../../Common/Form/SimpleSelect';
import ModalOneField from '../../Common/ModalUI/ModalOneField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createGroup, selectAgency } from '~/src/store/agencySlice';
import AgencyAgentPermissionListRow from './AgencyAgentPermissionListRow';
import { selectAgencyAgents, update } from '~/src/store/agencyAgentsSlice';
import createInitialFormValues from '~/src/helpers/createInitialFormValues';
import React from 'react';
import {
    Button,
    ButtonGroup,
    Col,
    Container,
    Navbar,
    Row,
    Table,
} from 'react-bootstrap';

export default function UpdateForm(): JSX.Element {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { loading } = useSelector(selectApp);
    const { item } = useSelector(selectAgencyAgents);
    const { unloadedPermissions } = useSelector(selectAgencyAgents);

    const [isOpenedGroupModal, setOpenedGroupModal] = useState(false);
    const [submittinG, setSubmitting] = useState(false);
    const [valiD, setValid] = useState(false);

    const { groups } = useSelector(selectAgency);

    const handleCreateGroup = async (title: string) => {
        dispatch(createGroup('my', title));
    };

    const onSubmit = (values) => {
        dispatch(update(item.id, values));
    };

    if (loading || item === null) {
        return <>Загрузка...</>;
    }

    let submit;

    return (
        <Container fluid>
            <Navbar
                className='justify-content-between'
                bg='light'
                expand='lg'
                variant='light'
            >
                <ButtonGroup>
                    <Button
                        size='sm'
                        onClick={() => navigate(-1)}
                        disabled={submittinG}
                    >
                        <FontAwesomeIcon icon={['fas', 'fast-backward']} />{' '}
                        Назад
                    </Button>
                    <Button
                        size='sm'
                        type='submit'
                        onClick={(event) => {
                            submit(event);
                        }}
                        className='btn btn-sm btn-success'
                        disabled={submittinG || !valiD}
                    >
                        <FontAwesomeIcon icon={['fas', 'save']} /> Сохранить
                    </Button>
                </ButtonGroup>
            </Navbar>
            <Row>
                <Col>
                    <FinalForm
                        onSubmit={onSubmit}
                        initialValues={{
                            ...createInitialFormValues(item),
                        }}
                        mutators={{
                            setValue: (
                                [field, value],
                                state,
                                { changeValue },
                            ) => {
                                changeValue(state, field, () => value);
                            },
                            setGroupIdValue: async (
                                [field, value]: [string, string],
                                state,
                                { changeValue },
                            ) => {
                                if (value === '-1') {
                                    setOpenedGroupModal(true);
                                    return;
                                }
                                changeValue(state, field, () => value);
                            },
                        }}
                        validate={() => {
                            const errors: any = {};
                            return errors;
                        }}
                        render={({ handleSubmit, form, submitting, valid }) => {
                            submit = handleSubmit;
                            setSubmitting(submitting);
                            setValid(valid);

                            return (
                                <form onSubmit={handleSubmit}>
                                    <SimpleSelectField
                                        label='Должность'
                                        name='position'
                                        initialValue={item?.position}
                                        opts={[
                                            { value: '', title: '' },
                                            { value: 'agent', title: 'Агент' },
                                            {
                                                value: 'director',
                                                title: 'Директор',
                                            },
                                            {
                                                value: 'supervisor',
                                                title: 'Руководитель',
                                            },
                                            {
                                                value: 'administrator',
                                                title: 'Администратор',
                                            },
                                        ]}
                                        validators={required}
                                    />
                                    <SimpleSelectField
                                        label='Группа'
                                        name='groupId'
                                        opts={[
                                            { id: 0, title: '' },
                                            { id: -1, title: 'Новая' },
                                        ]
                                            .concat(groups)
                                            .map((item) => {
                                                if (item?.id === 0) return {
                                                    value: '',
                                                    title: '',
                                                };
                                                return {
                                                    value: `${item?.id}`,
                                                    title: item?.title,
                                                };
                                            })}
                                        onChange={(e) =>
                                            form.mutators.setGroupIdValue(
                                                'groupId',
                                                e.target.value,
                                            )
                                        }
                                    />
                                    <ModalOneField
                                        text='Введите название'
                                        isOpened={isOpenedGroupModal}
                                        onClose={() =>
                                            setOpenedGroupModal(false)
                                        }
                                        primaryButtonOnClick={(v) =>
                                            handleCreateGroup(v)
                                        }
                                        secondaryButtonOnClick={() =>
                                            setOpenedGroupModal(false)
                                        }
                                    />
                                </form>
                            );
                        }}
                    />
                </Col>
                <Col>
                    <Table striped bordered hover>
                        <tbody>
                            {unloadedPermissions &&
                                unloadedPermissions.length > 0
                                ? unloadedPermissions
                                    .filter(
                                        (item) =>
                                            item.group ===
                                            'realty.secondary' ||
                                            item.group === 'realty.suburban',
                                    )
                                    .map((item) => (
                                        <AgencyAgentPermissionListRow
                                            key={item.code}
                                            item={item}
                                        />
                                    ))
                                : null}
                        </tbody>
                    </Table>
                </Col>
                <Col>
                    <Table striped bordered hover>
                        <tbody>
                            {unloadedPermissions &&
                                unloadedPermissions.length > 0
                                ? unloadedPermissions
                                    .filter(
                                        (item) =>
                                            item.group === 'agency' ||
                                            item.group === 'xml.feed',
                                    )
                                    .map((item) => (
                                        <AgencyAgentPermissionListRow
                                            key={item.code}
                                            item={item}
                                        />
                                    ))
                                : null}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
}
