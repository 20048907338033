import { createSlice, Slice } from '@reduxjs/toolkit';
import * as api from '~/src/service/housing.estate';
import type { TAppDispatch, TState, TAnyAction } from '~/src/store';
import { setLoading as setAppLoading } from './appSlice';

interface IChangePersonOnHousing {
    allHousingEstate?: { value: number; label: string }[];
}
const initialState = {
    allHousingEstate: null,
};
export const sliceName = 'changePersonOnHousing';
const changePersonOnHousingSlice: Slice<IChangePersonOnHousing> = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        unloadData: (
            state: IChangePersonOnHousing,
            action: TAnyAction<any>,
        ) => {
            state.allHousingEstate = [
                {
                    value: null, label: 'Нет'
                },
                ...action.payload
            ];
        },
    },
});

export const { unloadData } = changePersonOnHousingSlice.actions;

export const unloadAll = () => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        const response = await api.unload();
        dispatch(
            unloadData(
                response.map(({ id, title }) => ({ value: id, label: title })),
            ),
        );
    };
};

export const setChangeResponsible = (data: Record<string, any>) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const response = await api.changeResponsible(data);
            if (response && response.success) {
                console.log('Данные успешно обновлены');
            } else {
                console.log('При добавлении данных произошла ошибка');
            }
        } catch (e) {
            console.warn(e);
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const selectChangePersonOnHousing = (
    state: TState,
): IChangePersonOnHousing => state.changePersonOnHousing;

export default changePersonOnHousingSlice.reducer;
