import IList from './IList';
import { Link } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { useQuery } from '~/src/helpers/hooks';
import { redirect } from '~/src/store/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '~/src/components/Common/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    selectRS,
    goTo,
    all,
} from '~/src/store/realtySecondary/realtySecondarySlice';
import {
    Button,
    ButtonGroup,
    Col,
    Form,
    Navbar,
    Row,
    Table,
} from 'react-bootstrap';
import { selectAuth } from '~/src/store/authSlice';
import SearchForm from './searchForm/searchForm';
import queryString from 'query-string';
import UnloadingHousingArea from './searchForm/unloadingHousingArea';

const RList = (): JSX.Element => {
    const query = useQuery();
    const dispatch = useDispatch();

    const { items, search } = useSelector(selectRS);
    const { me } = useSelector(selectAuth);
    const [showForm, setShowForm] = useState<boolean>(false);

    const sortHandler = useCallback(
        (e) => {
            dispatch(goTo({ sort: e.currentTarget.value }));
        },
        [dispatch],
    );

    const limitHandler = useCallback(
        (e) => {
            dispatch(goTo({ limit: e.currentTarget.value }));
        },
        [dispatch],
    );

    const handlePaginationClick = (page: number) => {
        dispatch(goTo({ page }));
    };

    const toggleShowForm = () => {
        setShowForm(!showForm);
    };
    const handleClickArchive = () => {
        const queryArchive = queryString.parse(window.location.search);
        dispatch(
            goTo(
                Object.defineProperty(queryArchive, 'own', {
                    value: 'sold',
                }),
            ),
        );
    };
    return (
        <div>
            <Navbar
                className='justify-content-between'
                bg='light'
                expand='lg'
                variant='light'
            >
                <ButtonGroup>
                    <Link
                        to={'/realty-secondary/create/'}
                        className='btn btn-sm btn-success'
                    >
                        <FontAwesomeIcon icon={['fas', 'plus']} /> Добавить
                    </Link>
                </ButtonGroup>
                <ButtonGroup>
                    <Button
                        size='sm'
                        variant='primary'
                        onClick={toggleShowForm}
                    >
                        {!showForm ? (
                            <>
                                <FontAwesomeIcon icon={['fas', 'eye']} />{' '}
                                Открыть форму поиска
                            </>
                        ) : (
                            <>
                                <FontAwesomeIcon icon={['fas', 'eye-slash']} />{' '}
                                Закрыть форму поиска
                            </>
                        )}
                    </Button>
                    <Link
                        to={`/realty-secondary-map/?${query.toString()}`}
                        className='btn btn-sm btn-outline-secondary'
                        title='На карте'
                    >
                        <FontAwesomeIcon icon={['fas', 'globe']} /> На карте
                    </Link>
                    <Button
                        size='sm'
                        variant='secondary'
                        onClick={() => dispatch(all(query))}
                    >
                        <FontAwesomeIcon icon={['fas', 'sync']} /> Обновить
                    </Button>
                    <Button
                        size='sm'
                        variant='danger'
                        onClick={() => {
                            me?.agencyInfo
                                ? dispatch(
                                      redirect(
                                          '/realty-secondary?limit=25&own=my_agency&page=1&sort=created_at_desc',
                                      ),
                                  )
                                : dispatch(
                                      redirect(
                                          '/realty-secondary?limit=25&own=all&page=1&sort=created_at_desc',
                                      ),
                                  );
                        }}
                    >
                        <FontAwesomeIcon icon={['fas', 'sync']} /> Сбросить
                        фильтр
                    </Button>
                </ButtonGroup>
            </Navbar>
            <SearchForm showForm={showForm} toggleShowForm={toggleShowForm} />
            <UnloadingHousingArea />
            <div>
                <Row className='mb-2'>
                    <Col xs={4}>
                        <Form.Label>Сортировать</Form.Label>
                        <Form.Select
                            className='form-control'
                            size='sm'
                            value={search?.sort || 'price_asc'}
                            onChange={sortHandler}
                        >
                            <option value='id_asc'>ID &uarr;</option>
                            <option value='id_desc'>ID &darr;</option>
                            <option value='price_asc'>В начале дешевле</option>
                            <option value='price_desc'>В начале дороже</option>
                            <option value='created_at_desc'>
                                В начале новые
                            </option>
                            <option value='fresh_at_desc'>
                                По дате обновления
                            </option>
                        </Form.Select>
                    </Col>
                    <Col xs={4}>
                        <Form.Label>На странице</Form.Label>
                        <Form.Select
                            className='form-control'
                            size='sm'
                            value={search?.limit || 25}
                            onChange={limitHandler}
                        >
                            <option value={1}>1</option>
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={100}>100</option>
                        </Form.Select>
                    </Col>
                    <Col xs={4}>
                        <div className='mb-2'>
                            <Form.Label></Form.Label>
                        </div>
                        <Form.Label>
                            <strong>Всего объектов:</strong> {items?.count}
                        </Form.Label>
                    </Col>
                </Row>
                {items && items?.pagination?.have && (
                    <Pagination
                        pagination={items?.pagination}
                        onClick={handlePaginationClick}
                    />
                )}
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <td>#</td>
                            <td>Фото</td>
                            <td>Адрес</td>
                            <td>Параметры</td>
                            <td>Дом</td>
                            <td>Цена</td>
                            <td>Продавец</td>
                            <td>Управление</td>
                        </tr>
                    </thead>
                    <tbody>
                        {items && items.items && items.items.length > 0
                            ? items.items.map((item) => (
                                  <IList key={item.id} item={item} />
                              ))
                            : null}
                    </tbody>
                </Table>
                <Row>
                    <Col>
                        {items && items.pagination?.have && (
                            <Pagination
                                pagination={items.pagination}
                                onClick={handlePaginationClick}
                            />
                        )}
                    </Col>
                    {(items.pagination?.current === items.pagination?.last ||
                        items.pagination?.last === 0) && (
                        <Col>
                            <Button
                                size='sm'
                                variant='danger'
                                className='mb-3'
                                onClick={handleClickArchive}
                            >
                                Показать записи из архива
                            </Button>
                        </Col>
                    )}
                </Row>
            </div>
        </div>
    );
};

export default RList;
