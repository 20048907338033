import React from 'react';
import { FC } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import dateFormat from 'dateformat';
import { IUnloadGroup } from '~/src/service/agency.group';
import { remove } from '~/src/store/agency.group.slice';
import { selectAuth } from '~/src/store/authSlice';
import ButtonWithConfirm from '../../Common/ModalUI/ButtonWithConfirm';

const AgencyGroupList: FC<{ item: IUnloadGroup }> = ({ item }) => {
    const { me } = useSelector(selectAuth);
    const dispatch = useDispatch();

    const handleRemove = () => {
        dispatch(remove(item.id));
    };

    return (
        <React.Fragment>
            <tr>
                <td>фото</td>
                <td>
                    <p>
                        {item.title}
                    </p>
                </td>
                <td>
                    <p>
                        {item.title}
                    </p>
                </td>
                <td>
                    <p>Группа создана{dateFormat(item.createdAt, 'dd.mm.yyyy')}</p>
                </td>
                <td>
                    <ButtonGroup vertical={true} className='w-100'>
                        {me &&
                            (me.agencyInfo.isDirector ||
                                me.agencyInfo.permissions.includes(
                                    'agency.agent.permission',
                                )) &&
                            <Link
                                className='btn btn-sm btn-warning'
                                to={`/agency/group/${item.id}/update`}
                            >
                                Редактировать
                            </Link>
                        }
                        {me &&
                            (me.agencyInfo.isDirector ||
                                me.agencyInfo.permissions.includes(
                                    'agency.agent.invite',
                                )) &&
                            <ButtonWithConfirm
                                label='Удалить'
                                modalText='Точно?'
                                onClick={handleRemove}
                                variant='danger'
                            />
                        }
                    </ButtonGroup>
                </td>
            </tr>
        </React.Fragment>
    );
};

export default AgencyGroupList;
