import { redirect, setLoading as setAppLoading } from './appSlice';
import queryString from 'query-string';
import * as api from '~/src/service/housing.estate.house';
import {
    IHousingEstateHouse,
    IHousingEstateHouseUnloaded,
} from '~/src/service/housing.estate.house';
import { createSlice, Slice } from '@reduxjs/toolkit';
import { IResponseListData } from '../interfaces/common';
import type { TAppDispatch, TState, TAnyAction } from '~/src/store';

export const sliceName = 'housingEstateHouse';

export interface IHousingEstateHouseSlice {
    items: IResponseListData<IHousingEstateHouse>;
    item?: IHousingEstateHouse;
    unload?: { id: number; title: string }[];
    search?: any;
    errors?: any;
    loading: boolean;
}

const initialState = {
    items: {},
    unload: [],
    search: {
        sort: 'id_desc',
        limit: 25,
        page: 1,
    },
    loading: true,
};

const housingEstateHouseSlice: Slice<IHousingEstateHouseSlice> = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        setAll: (
            state: IHousingEstateHouseSlice,
            action: TAnyAction<IResponseListData<IHousingEstateHouse>>,
        ) => {
            state.items = action.payload;
        },
        setUnload: (
            state: IHousingEstateHouseSlice,
            action: TAnyAction<IHousingEstateHouseUnloaded[]>,
        ) => {
            state.unload = action.payload;
        },
        set: (
            state: IHousingEstateHouseSlice,
            action: TAnyAction<IHousingEstateHouse>,
        ) => {
            state.item = action.payload;
        },
        setErrors: (
            state: IHousingEstateHouseSlice,
            action: TAnyAction<any>,
        ) => {
            state.errors = action.payload;
        },
        setSearch: (
            state: IHousingEstateHouseSlice,
            action: TAnyAction<any>,
        ) => {
            state.search = action.payload;
        },
    },
});

const { setAll, set, setErrors, setSearch, setUnload } =
    housingEstateHouseSlice.actions;

export const all = (queryParams: URLSearchParams = null) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(setAppLoading(true));
        const response = await api.all(queryParams);
        const obj = {};

        for (const key of queryParams.keys()) {
            if (queryParams.getAll(key).length > 1) {
                obj[key] = queryParams.getAll(key);
            } else {
                obj[key] = queryParams.get(key);
            }
        }

        dispatch(setSearch(obj));
        dispatch(setAll(response));
        dispatch(setAppLoading(false));
    };
};

export const unload = (params: Record<string, any>) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        const response = await api.unload(new URLSearchParams(params));

        dispatch(setUnload(response));
    };
};

export const create = (data: IHousingEstateHouse, files?: any) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const item = await api.create(data, files);
            if (item && item.errors) {
                dispatch(setErrors(item.errors));
            } else if (item) {
                dispatch(
                    redirect(`/housing-estate/${item.housingEstateId}/houses`),
                );
            }
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const read = (id: number) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const data = await api.read(id);
            dispatch(set(data));
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const update = (id: number, data: IHousingEstateHouse) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const response = await api.update(id, data);
            if (response && response.success) {
                dispatch(set(response.data));
            } else {
                dispatch(setErrors(response.errors));
            }
        } catch (e) {
            console.error(e);
            dispatch(setErrors(e));
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const remove = (id: number) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const response = await api.remove(id);
            if (response && response.success) {
                dispatch(
                    setAll({
                        ...getState().housingEstateHouse.items,
                        items: getState().housingEstateHouse.items.items.filter(
                            (item: IHousingEstateHouse) => item.id != id,
                        ),
                    }),
                );
            } else {
                dispatch(setErrors(response.errors));
            }
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const goTo =
    (params: Record<string, any>) =>
    (dispatch: TAppDispatch, getState: () => TState): void => {
        if (params.reset === true) {
            params = initialState.search;
        } else params = { ...getState().housingEstateHouse.search, ...params };

        dispatch(
            redirect(
                `/housing-estate/${getState().housingEstate.item.id}/houses?${queryString.stringify(params, { skipNull: true })}`,
            ),
        );
    };

export const selectHEH = (state: TState): IHousingEstateHouseSlice =>
    state.housingEstateHouse;

export default housingEstateHouseSlice.reducer;
