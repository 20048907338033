import React, { BaseSyntheticEvent } from 'react';
import { ButtonGroup, Form, ToggleButton } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
    getChessPorch,
    selectHE,
    setActivePorch,
    setNavPanel,
} from '~/src/store/housingEstateSlice';
import { IHousingEstateHousePorch } from '~/src/service/housing.estate.house.porch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Title = ({ porch }: { porch: IHousingEstateHousePorch }) => {
    const _warning =
        porch.countSaleFlatsWithoutLayout > 0 ? (
            <FontAwesomeIcon
                icon={['fas', 'exclamation-triangle']}
                title='Нет планировок'
            />
        ) : null;
    return (
        <>
            {_warning} Подъезд: {porch.number} ({porch.countSaleFlats})
        </>
    );
};

const PorchesBtn = () => {
    const { navPanel, infoHousingEstateHouse } = useSelector(selectHE);
    const dispatch = useDispatch();

    const handleChangeValue = (
        e: BaseSyntheticEvent,
        radio: IHousingEstateHousePorch,
    ) => {
        dispatch(
            setNavPanel({
                ...navPanel,
                [e.target.name]: Number(e.target.value),
            }),
        );
        dispatch(setActivePorch(radio));
        dispatch(getChessPorch(Number(e.target.value)));
    };

    return (
        <Form.Group>
            <ButtonGroup className='d-flex mb-3'>
                {infoHousingEstateHouse?.porches.map((porch, idx) => (
                    <ToggleButton
                        key={idx}
                        id={`porchesTab-btns-${idx}`}
                        type='checkbox'
                        variant={
                            navPanel.porches === porch.id
                                ? 'secondary'
                                : 'outline-secondary'
                        }
                        name='porches'
                        value={porch.id}
                        checked={navPanel.porches === porch.id}
                        onChange={(e) => handleChangeValue(e, porch)}
                    >
                        <Title porch={porch} />
                    </ToggleButton>
                ))}
            </ButtonGroup>
        </Form.Group>
    );
};

export default PorchesBtn;
