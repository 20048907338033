import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { selectApp } from '~/src/store/appSlice';
import { read } from '~/src/store/developer/developer.house.project';
import { useDispatch, useSelector } from 'react-redux';
import { unloadSuburban } from '~/src/store/developer/developerSlice';
import { HouseProjectUpdateView } from '~/src/components/Developer/HouseProject/update';

const UpdateDHPPage = (): JSX.Element => {
    const dispatch = useDispatch();
    const params = useParams<{ id: string }>();

    const { loading } = useSelector(selectApp);

    useEffect(() => {
        dispatch(read(Number(params.id)));
    }, [params.id, dispatch]);

    useEffect(() => {
        dispatch(unloadSuburban());
    }, [dispatch]);

    return (
        <React.Fragment>
            {loading ? <div>Загрузка...</div> : <HouseProjectUpdateView />}
        </React.Fragment>
    );
};

export { UpdateDHPPage };
