import { Link, useNavigate } from 'react-router-dom';
import React, { useState, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputField from '../Common/Form/InputField';
import { Field, Form as FinalForm } from 'react-final-form';
import { updateUser } from '~/src/store/usersSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup, Col, Form, Navbar, Row } from 'react-bootstrap';
import {
    required,
    onlyLength,
    composeValidators,
    mustBeNumber,
} from '~/src/helpers/validators';
import DatePickerField from '../Common/Form/DatePickerField/DatePickerField';
import ChangePassword from './ChangePassword';
import Confirm from '../Common/ModalUI/AlertModals/Confirm';
import { resetPassword } from '~/src/service/user';
import HaveAccess from '../Common/HaveAccess';
import CheckboxField from '../Common/Form/CheckboxField';
import { selectUsersPA } from '~/src/store/paSlice';
import Rewards from './PA/Reward/Rewards';
import Select from 'react-select';
import Reviews from './PA/Reviews/Reviews';
import ProfilePhoto from './PA/ProfilePhoto/ProfilePhoto';

export default function RSUpdateForm(): JSX.Element {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [changePasswordOpen, setChangePasswordOpen] = useState(false);
    const [resetPasswordOpen, setResetPasswordOpen] = useState(false);

    // const [uploadFiles, setUploadFiles] = useState(null);
    // const [preview, setPreview] = useState([]);
    const { item, social } = useSelector(selectUsersPA);

    const onSubmit = (values) => {
        // const filesRecord = uploadFiles === null ? undefined : { 'photosUpload': uploadFiles };

        dispatch(
            updateUser(+values?.id, {
                ...values,
            }),
        );

        // setPreview([]);
        // setUploadFiles(null);
    };

    const handleResetPasswordClick = async () => {
        await resetPassword(item.id);
        setResetPasswordOpen(false);
    };

    return (
        <React.Fragment>
            <style>
                {`
                .overflow-auto::-webkit-scrollbar {height: 8px;}
                .overflow-auto::-webkit-scrollbar-thumb {background-color: #ccc;border-radius: 10px;}
                .overflow-auto::-webkit-scrollbar-track {background: transparent;}
                `}
            </style>
            <Suspense fallback={<div>Loading...</div>}>
                {item === null || item === undefined ? (
                    <div>Загрузка...</div>
                ) : (
                    <FinalForm
                        onSubmit={onSubmit}
                        initialValues={{
                            ...Object.keys(item)
                                .filter((k) => item[k] != null)
                                .reduce((a, k) => ({ ...a, [k]: item[k] }), {}),
                        }}
                        mutators={{
                            setValue: (
                                [field, value],
                                state,
                                { changeValue },
                            ) => {
                                changeValue(state, field, () => value);
                            },
                            setDateValue: (
                                [field, value],
                                state,
                                { changeValue },
                            ) => {
                                changeValue(state, field, () =>
                                    value.toString(),);
                            },
                        }}
                        validate={() => {
                            const errors: any = {};
                            return errors;
                        }}
                        render={({ handleSubmit, submitting, valid }) => (
                            <form onSubmit={handleSubmit}>
                                <Navbar
                                    className='justify-content-between'
                                    bg='light'
                                    expand='lg'
                                    variant='light'
                                >
                                    <ButtonGroup>
                                        <Button
                                            size='sm'
                                            type='button'
                                            onClick={() => navigate(-1)}
                                        >
                                            <FontAwesomeIcon
                                                icon={['fas', 'fast-backward']}
                                            />{' '}
                                            Назад
                                        </Button>
                                        <Button
                                            size='sm'
                                            type='submit'
                                            className='btn btn-sm btn-success'
                                            disabled={submitting || !valid}
                                        >
                                            <FontAwesomeIcon
                                                icon={['fas', 'save']}
                                            />{' '}
                                            Сохранить
                                        </Button>
                                    </ButtonGroup>
                                    <ButtonGroup>
                                        <Button
                                            size='sm'
                                            type='button'
                                            onClick={() =>
                                                setChangePasswordOpen(true)
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={['fas', 'key']}
                                            />{' '}
                                            Сменить пароль
                                        </Button>
                                        <HaveAccess access='*' roles='grand'>
                                            <Button
                                                size='sm'
                                                variant='danger'
                                                type='button'
                                                onClick={() =>
                                                    setResetPasswordOpen(true)
                                                }
                                            >
                                                Сбросить пароль
                                            </Button>
                                            <Link
                                                className='btn btn-sm btn-warning'
                                                to={`/user/${item.id}/permission`}
                                            >
                                                Права пользователя
                                            </Link>
                                        </HaveAccess>
                                    </ButtonGroup>
                                </Navbar>
                                <Row>
                                    <Col xs={4}>
                                        <ProfilePhoto photo={item.photo} id={item.id} />
                                    </Col>
                                    <Col xs={8}>
                                        <Row>
                                            <Col>
                                                <InputField
                                                    name='lastname'
                                                    label='Фамилия'
                                                />
                                            </Col>
                                            <Col>
                                                <InputField
                                                    name='firstname'
                                                    label='Имя'
                                                    validators={required}
                                                />
                                            </Col>
                                            <Col>
                                                <InputField
                                                    name='middlename'
                                                    label='Отчество'
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <DatePickerField
                                                    name='birthdate'
                                                    label='День рождения'
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <InputField
                                                    name='email'
                                                    label='Email'
                                                    type='email'
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <InputField
                                                    name='videoUrl'
                                                    label='Ссылка на видеопрезентацию'
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Field
                                                    name='replacementPhone'
                                                    validate={composeValidators(
                                                        mustBeNumber,
                                                        onlyLength(10),
                                                    )}
                                                >
                                                    {({ input, meta }) => (
                                                        <Form.Group className='mb-3'>
                                                            <Form.Label>
                                                                Подменный телефон{' '}
                                                                <small className='text-muted'>
                                                                    (10 цифр без 8)
                                                                </small>
                                                            </Form.Label>
                                                            <Form.Control
                                                                {...input}
                                                                type='number'
                                                                size='sm'
                                                                isInvalid={
                                                                    meta.error ||
                                                                    meta.submitError
                                                                }
                                                                placeholder='Подменный телефон'
                                                            />
                                                            <Form.Control.Feedback type='invalid'>
                                                                {meta.error ||
                                                                    meta.submitError}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    )}
                                                </Field>
                                                <CheckboxField
                                                    name='isReplacementPhoneActive'
                                                    label='Активировать подменный номер'
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Field name='housings'>
                                                    {({ input, meta }) => (
                                                        <Form.Group className='mb-3'>
                                                            <Form.Label>
                                                                Мессенджеры для связи
                                                            </Form.Label>
                                                            <Select
                                                                {...input}
                                                                isMulti
                                                                options={social}
                                                                placeholder='Мессенджеры для связи'
                                                                styles={{
                                                                    control: (
                                                                        baseStyles,
                                                                        state,
                                                                    ) => ({
                                                                        ...baseStyles,
                                                                        maxHeight: '100px',
                                                                        overflowY: 'auto',
                                                                        '::-webkit-scrollbar':
                                                                        {
                                                                            width: '4px',
                                                                        },
                                                                        '::-webkit-scrollbar-thumb':
                                                                        {
                                                                            backgroundColor:
                                                                                '#0d6efd',
                                                                            borderRadius:
                                                                                '2px',
                                                                        },
                                                                        '::-webkit-scrollbar-track':
                                                                        {
                                                                            backgroundColor:
                                                                                '#ECECEC',
                                                                            borderRadius:
                                                                                '2px',
                                                                        },
                                                                    }),
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    )}
                                                </Field>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <h5>Моя специализация</h5>
                                            <Col>
                                                <CheckboxField
                                                    name='specPrimary'
                                                    label='Покупка продажа квартир в новостройках'
                                                />
                                                <CheckboxField
                                                    name='specSecondary'
                                                    label='Покупка продажа квартир на вторичном рынке'
                                                />
                                                <CheckboxField
                                                    name='specSuburban'
                                                    label='Покупка продажа загородной недвижимости'
                                                />
                                            </Col>
                                            <Col>
                                                <CheckboxField
                                                    name=',,'
                                                    label='Ипотечное кредитование'
                                                />
                                                <CheckboxField
                                                    name='specComm'
                                                    label='Покупка продажа коммерческих объектов'
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Rewards />
                                <Reviews />
                                <Button
                                    size='sm'
                                    type='submit'
                                    className='btn btn-sm btn-success mb-2 mt-3'
                                    disabled={submitting || !valid}
                                >
                                    <FontAwesomeIcon icon={['fas', 'save']} />{' '}
                                    Сохранить
                                </Button>
                            </form>
                        )}
                    />
                )}
            </Suspense>
            <ChangePassword
                isOpen={changePasswordOpen}
                onClose={() => setChangePasswordOpen(false)}
            />
            <Confirm
                text='Сбросить пароль?'
                onClose={() => setResetPasswordOpen(false)}
                isOpened={resetPasswordOpen}
                primaryButtonOnClick={handleResetPasswordClick}
                secondaryButtonOnClick={() => setResetPasswordOpen(false)}
            />
        </React.Fragment>
    );
}
