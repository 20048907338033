import { BaseSyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import IList from './IList';
import { Link } from 'react-router-dom';
import { useQuery } from '~/src/helpers/hooks';
import { redirect } from '~/src/store/appSlice';
import { setCurrentComponent } from '~/src/store/appSlice';
import Pagination from '~/src/components/Common/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { selectAgency, all, goTo } from '~/src/store/agencySlice';
import {
    Button,
    ButtonGroup,
    Col,
    Form,
    Navbar,
    Row,
    Table,
} from 'react-bootstrap';
import SortLimitRow from '../Common/SortLimitRow';

const AgencyList = (): JSX.Element => {
    const query = useQuery();
    const dispatch = useDispatch();
    const { items, search } = useSelector(selectAgency);

    /* useEffect(() => {
        dispatch(setCurrentComponent('AgencyList'));
    }, [dispatch]); */

    const [showHiddenForm, setShowHiddenForm] = useState(false);

    const toggleShowForm = () => {
        setShowHiddenForm(!showHiddenForm);
    };

    const sortHandler = useCallback(
        (e: BaseSyntheticEvent) => {
            dispatch(goTo({ sort: e.currentTarget.value }));
        },
        [dispatch],
    );

    const limitHandler = useCallback(
        (e: BaseSyntheticEvent) => {
            dispatch(goTo({ limit: e.currentTarget.value }));
        },
        [dispatch],
    );

    const handlePaginationClick = (page: number) => {
        dispatch(goTo({ page }));
    };

    const sortOpts = [
        { value: 'id_asc', title: 'ID ', up: true },
        { value: 'id_desc', title: 'ID ', down: true },
        { value: 'title_asc', title: 'Название ', up: true },
        { value: 'title_desc', title: 'Название ', down: true },
    ];

    return (
        <div>
            <Navbar
                className='justify-content-between'
                bg='light'
                expand='lg'
                variant='light'
            >
                <ButtonGroup>
                    <Link
                        to={'/agency/create/'}
                        className='btn btn-sm btn-success'
                    >
                        <FontAwesomeIcon icon={['fas', 'plus']} /> Добавить
                    </Link>
                    <Link
                        to={'/agency/permission/'}
                        className='btn btn-sm btn-secondary'
                    >
                        <FontAwesomeIcon icon={['fas', 'shield-alt']} /> Доступы
                        агентств
                    </Link>
                </ButtonGroup>
                <ButtonGroup>
                    <Button
                        size='sm'
                        variant='outline-secondary'
                        onClick={toggleShowForm}
                    >
                        <FontAwesomeIcon icon={['fas', 'eye']} /> Форма поиска
                    </Button>
                    <Button
                        size='sm'
                        variant='secondary'
                        onClick={() => dispatch(all(query))}
                    >
                        <FontAwesomeIcon icon={['fas', 'sync']} /> Обновить
                    </Button>
                    <Button
                        size='sm'
                        variant='danger'
                        onClick={() => dispatch(redirect('/agency'))}
                    >
                        <FontAwesomeIcon icon={['fas', 'sync']} /> Сбросить
                        фильтр
                    </Button>
                </ButtonGroup>
            </Navbar>
            <SortLimitRow
                sort={search?.sort}
                limit={search?.limit}
                sortHandler={sortHandler}
                limitHandler={limitHandler}
                sortOpts={sortOpts}
            />
            {items && items.pagination?.have && (
                <Pagination
                    pagination={items.pagination}
                    onClick={handlePaginationClick}
                />
            )}
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <td>Название</td>
                        <td>Адрес</td>
                        <td>Директор</td>
                        <td>Контакты</td>
                        <td>Управление</td>
                    </tr>
                </thead>
                <tbody>
                    {items && items.items && items.items.length > 0
                        ? items.items.map((item) => (
                              <IList key={item.id} item={item} />
                          ))
                        : null}
                </tbody>
            </Table>
            {items && items.pagination?.have && (
                <Pagination
                    pagination={items.pagination}
                    onClick={handlePaginationClick}
                />
            )}
        </div>
    );
};

export default AgencyList;
