import React, { FC } from 'react';
import { useQuery } from '~/src/helpers/hooks';
import { IUserWithStatistics } from '~/src/service/user';

const IList: FC<{ item: IUserWithStatistics }> = ({ item }) => {
    const query = useQuery();

    console.log(query.get('dateFrom'));
    console.log(query.get('dateTo'));

    return (
        <React.Fragment>
            <tr>
                <td>{item.id}</td>
                <td>{item.fullname}</td>
                <td>{item.agencyInfo?.group?.title}</td>
                <td>
                    <a
                        href={`https://crm.citidom.com/realty-secondary?agentUid=${item.uid}&own=my_agency`}
                        rel='noreferrer'
                        target='_blank'
                    >
                        {item.statistics.secondaryTotal}
                    </a>
                </td>
                <td>
                    <a
                        href={`https://crm.citidom.com/realty-secondary?agentUid=${item.uid}&own=all`}
                        rel='noreferrer'
                        target='_blank'
                    >
                        {item.statistics.secondaryVerified}
                    </a>
                </td>
                <td>{item.statistics.secondaryCreatedCount}</td>
                <td>{item.statistics.secondaryPrimaryCreatedCount}</td>
                <td>{item.statistics.secondaryPriceDownCount}</td>
                <td>
                    <a
                        href={`https://crm.citidom.com/realty-secondary?agentUid=${item.uid}&own=outdated_soon`}
                        rel='noreferrer'
                        target='_blank'
                    >
                        {item.statistics.getSecondaryOutdatedSoonTotal}
                    </a>
                </td>

                <td>
                    <a
                        href={`https://crm.citidom.com/realty-suburban?agentUid=${item.uid}&own=my_agency`}
                        rel='noreferrer'
                        target='_blank'
                    >
                        {item.statistics.suburbanTotal}
                    </a>
                </td>
                <td>
                    <a
                        href={`https://crm.citidom.com/realty-suburban?agentUid=${item.uid}&own=all`}
                        rel='noreferrer'
                        target='_blank'
                    >
                        {item.statistics.suburbanVerified}
                    </a>
                </td>
                <td>{item.statistics.suburbanCreatedCount}</td>
                <td>{item.statistics.suburbanPriceDownCount}</td>
                <td>
                    <a
                        href={`https://crm.citidom.com/realty-suburban?agentUid=${item.uid}&own=outdated_soon`}
                        rel='noreferrer'
                        target='_blank'
                    >
                        {item.statistics.suburbanOutdatedSoonTotal}
                    </a>
                </td>
                <td>{item.statistics.buyerCreatedCount}</td>
                <td>{item.statistics.getBuyerCountByThermo[0].hot ? item.statistics.getBuyerCountByThermo[0].hot : 0}</td>
                <td>{item.statistics.getBuyerCountByThermo[0].warm ? item.statistics.getBuyerCountByThermo[0].warm : 0}</td>
                <td>{item.statistics.getBuyerCountByThermo[0].cold ? item.statistics.getBuyerCountByThermo[0].cold : 0}</td>
                <td>{item.statistics.getBuyerCountByThermo[0].all ? item.statistics.getBuyerCountByThermo[0].all : 0}</td>
            </tr>
        </React.Fragment>
    );
};

export default IList;
