import { IBuyer } from '~/src/service/buyer';
import { convertCodeToTitles } from '../data/data';
import dateFormat from 'dateformat';
import phoneFormatter from '~/src/helpers/phoneFormatter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TrelloInfoCard = ({ itemInfo }: { itemInfo: IBuyer }) => {
    const expresion =
        new Date(itemInfo?.controlDate).setHours(0, 0, 0, 0) <
        new Date().setHours(0, 0, 0, 0);

    const isNew = itemInfo.newbie ? (
        <FontAwesomeIcon icon={['fas', 'user-plus']} color='orange' />
    ) : null;

    return (
        <>
            <div className='nameByer' title={isNew ? 'Новый' : ''}>
                <div>
                    <strong>
                        {isNew} {itemInfo?.fullname}
                    </strong>
                </div>
                {expresion && itemInfo.status == 'active' && (
                    <div className='expired'>Просрочен</div>
                )}
            </div>
            <span>Внесен: {dateFormat(itemInfo?.createdAt, 'dd.mm.yyyy')}</span>
            <span>ID: {itemInfo?.id}</span>
            <div className='phone'>
                Телефон: {phoneFormatter(`${itemInfo?.contactPhone}`)}
            </div>
            <div className='date'>{`КД: ${dateFormat(itemInfo?.controlDate, 'dd.mm.yyyy')}`}</div>
            <div className='realty'>
                {convertCodeToTitles(
                    itemInfo?.fields?.realtyType || [],
                    'realtyType',
                )}
            </div>
            <div className='rooms'>
                {convertCodeToTitles(itemInfo?.fields?.rooms || [], 'rooms')}
            </div>
            <div className='price'>
                {itemInfo?.fields?.priceFrom && (
                    <>
                        Цена от: {itemInfo?.fields?.priceFrom} <br />
                    </>
                )}
                {itemInfo?.fields?.priceTo && (
                    <>
                        Цена до: {itemInfo?.fields?.priceTo} <br />
                    </>
                )}
            </div>
            <div className='livingSpace'>
                {itemInfo?.fields?.spaceFrom && (
                    <>
                        Площадь от: {itemInfo?.fields?.spaceFrom} <br />
                    </>
                )}
                {itemInfo?.fields?.spaceTo && (
                    <>
                        Площадь до: {itemInfo?.fields?.spaceTo} <br />
                    </>
                )}
            </div>
            <div className='fioAgent'>
                Агент: {itemInfo?.agent?.lastname} {itemInfo?.agent?.firstname}
            </div>
        </>
    );
};

export default TrelloInfoCard;
