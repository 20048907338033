import { Form as FinalForm } from 'react-final-form';

import createInitialFormValues from '~/src/helpers/createInitialFormValues';
import { Button, ButtonGroup, Container, Navbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { required } from '~/src/helpers/validators';
import InputField from '../Common/Form/InputField';
import { useNavigate } from 'react-router-dom';
import TextareaField from '../Common/Form/TextareaField';
import { useDispatch, useSelector } from 'react-redux';
import { selectContent, update } from '~/src/store/content';

const UpdateContent = (): JSX.Element => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { item } = useSelector(selectContent);

    const onSubmit = (values: Record<string, any>) => {
        console.log(values);

        dispatch(update(item.id, values));
    };

    return (
        <FinalForm
            onSubmit={onSubmit}
            initialValues={{
                ...createInitialFormValues(item),
            }}
            mutators={{
                // expect (field, value) args from the mutator
                setValue: ([field, value], state, { changeValue }) => {
                    changeValue(state, field, () => value);
                },
            }}
            validate={(values) => {
                const errors: any = {};
                return errors;
            }}
            render={({ handleSubmit, submitting, valid }) => (
                <form onSubmit={handleSubmit}>
                    <Navbar
                        className='justify-content-between'
                        bg='light'
                        expand='lg'
                        variant='light'
                    >
                        <ButtonGroup>
                            <Button
                                size='sm'
                                onClick={() => navigate(-1)}
                                disabled={submitting}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'fast-backward']}
                                />{' '}
                                Назад
                            </Button>
                            <Button
                                size='sm'
                                type='submit'
                                className='btn btn-sm btn-success'
                                disabled={submitting || !valid}
                            >
                                <FontAwesomeIcon icon={['fas', 'save']} />{' '}
                                Сохранить
                            </Button>
                        </ButtonGroup>
                    </Navbar>
                    <Container fluid>
                        <InputField
                            name='title'
                            label='Заголовок'
                            validators={required}
                        />
                        <TextareaField
                            name='content'
                            label='Контент'
                            validators={required}
                        />
                    </Container>
                    <Button
                        size='sm'
                        type='submit'
                        className='btn btn-sm btn-success'
                        disabled={submitting || !valid}
                    >
                        <FontAwesomeIcon icon={['fas', 'save']} /> Сохранить
                    </Button>
                </form>
            )}
        />
    );
};

export default UpdateContent;
