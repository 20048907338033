import {
    Form,
    Button,
    ButtonGroup,
    Col,
    Navbar,
    Row,
    Table,
} from 'react-bootstrap';
import {
    all,
    goTo,
    selectCV,
} from '~/src/store/cottage.village/cottage.village.store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { redirect } from '~/src/store/appSlice';
import { BaseSyntheticEvent, FC } from 'react';
import { useQuery } from '~/src/helpers/hooks';
import Pagination from '../Common/Pagination';
import QuickSearch from './QuickSearchList';
import { Link } from 'react-router-dom';
import { ListRow } from './ListRow';
import ChangeREsponsibleCV from './changeResponsible/ChangeResponsibleCV';
import HaveAccess from '../Common/HaveAccess';
import { COTTAGE_VILLAGE_UPDATE_PERMISSION } from './constants';

const CottageVillageListView: FC = () => {
    const query = useQuery();

    const dispatch = useDispatch();

    const { items, search } = useSelector(selectCV);

    const sortHandler = (e: BaseSyntheticEvent) =>
        dispatch(goTo({ sort: e.currentTarget.value }));

    const limitHandler = (e: BaseSyntheticEvent) =>
        dispatch(goTo({ limit: e.currentTarget.value }));

    const handlePaginationClick = (page: number) => {
        dispatch(goTo({ page }));
    };

    return (
        <>
            <Navbar
                className='justify-content-between'
                bg='light'
                expand='lg'
                variant='light'
            >
                <ButtonGroup>
                    <HaveAccess access={COTTAGE_VILLAGE_UPDATE_PERMISSION}>
                        <Link
                            to={'/cottage-village/create/'}
                            className='btn btn-sm btn-success'
                        >
                            <FontAwesomeIcon icon={['fas', 'plus']} /> Добавить
                            КП
                        </Link>
                    </HaveAccess>
                </ButtonGroup>
                <ButtonGroup>
                    <HaveAccess access={'*'} roles={['grand', 'admin']}>
                        <ChangeREsponsibleCV />
                    </HaveAccess>
                    <Button
                        size='sm'
                        variant='secondary'
                        onClick={() => dispatch(all(query))}
                    >
                        <FontAwesomeIcon icon={['fas', 'sync']} /> Обновить
                    </Button>
                    <Button
                        size='sm'
                        variant='danger'
                        onClick={() => dispatch(redirect('/cottage-village'))}
                    >
                        <FontAwesomeIcon icon={['fas', 'sync']} /> Сбросить
                        фильтр
                    </Button>
                </ButtonGroup>
            </Navbar>
            <Row className='mb-2'>
                <Col xs={4}>
                    <Form.Label>Сортировать</Form.Label>
                    <Form.Select
                        className='form-control'
                        size='sm'
                        value={search?.sort || 'fresh_at_desc'}
                        onChange={sortHandler}
                    >
                        <option value='id_asc'>ID &uarr;</option>
                        <option value='id_desc'>ID &darr;</option>
                        <option value='fresh_at_asc'>Обновлено &uarr;</option>
                        <option value='fresh_at_desc'>Обновлено &darr;</option>
                        <option value='popularity_asc'>
                            По популярности &uarr;
                        </option>
                        <option value='popularity_desc'>
                            По популярности &darr;
                        </option>
                    </Form.Select>
                </Col>
                <Col xs={4}>
                    <Form.Label>На странице</Form.Label>
                    <Form.Select
                        className='form-control'
                        size='sm'
                        value={search?.limit || 25}
                        onChange={limitHandler}
                    >
                        <option value={1}>1</option>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={100}>100</option>
                    </Form.Select>
                </Col>
                <Col xs={4}>
                    <QuickSearch />
                </Col>
            </Row>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <td>#</td>
                        <td>Название</td>
                        <td>Ответственный специалист</td>
                        <td>Информация</td>
                        <td>Параметры</td>
                        <td>Управление</td>
                    </tr>
                </thead>
                <tbody>
                    {items && items.items && items.items.length > 0
                        ? items.items.map((item) => (
                              <ListRow key={item.id} item={item} />
                          ))
                        : null}
                </tbody>
            </Table>
            {items && items.pagination?.have && (
                <Pagination
                    pagination={items.pagination}
                    onClick={handlePaginationClick}
                />
            )}
        </>
    );
};

export default CottageVillageListView;
