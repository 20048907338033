import React from 'react';
import { Col, Row } from 'react-bootstrap';
import InputField from '../Common/Form/InputField';
import SliderField from '../Common/Form/SliderField';
import CheckboxField from '../Common/Form/CheckboxField';

export default function UpdateAdditionalView(): JSX.Element {
    return (
        <React.Fragment>
            <Row>
                <InputField
                    label='Минимальный процент по ипотеке'
                    name='mortgage'
                />
            </Row>
            <Row>
                <Col>
                    <SliderField
                        name='popularity'
                        label='Популярность'
                        min={0}
                        max={100}
                        step={5}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <CheckboxField name='hasCctv' label='Видеонаблюдение' />
                    <CheckboxField
                        name='isClosedArea'
                        label='Закрытая территория'
                    />
                    <CheckboxField
                        name='isProtectedArea'
                        label='Охраняемая территория'
                    />
                    <CheckboxField name='cityhouse' label='Ситихаус' />
                    <CheckboxField
                        name='lowRiseEstate'
                        label='Малоэтажный ЖК'
                    />
                    <CheckboxField name='youngFamily' label='Молодая семья' />
                    <CheckboxField
                        name='escrowAccountSell'
                        label='Продается по эскроу счету'
                    />
                    <CheckboxField
                        name='smartHouseSystem'
                        label='Система Умный дом'
                    />
                    <CheckboxField name='soundproofing' label='Шумоизоляция' />
                    <CheckboxField
                        name='isRennovationFlats'
                        label='Квартиры с ремонтом'
                    />
                    <CheckboxField
                        name='wallDecorationChoice'
                        label='Можно выбрать отделку'
                    />
                </Col>
                <Col>
                    <CheckboxField
                        name='hasPlayGround'
                        label='Игровая площадка'
                    />
                    <CheckboxField
                        name='hasSportGround'
                        label='Спортивная площадка'
                    />
                    <CheckboxField
                        name='hasPicnicGround'
                        label='Площадка для пикника'
                    />
                    <CheckboxField
                        name='hasGroundParking'
                        label='Наземная парковка'
                    />
                    <CheckboxField
                        name='hasUndergroundParking'
                        label='Подземная парковка'
                    />
                    <CheckboxField name='hasBesidePark' label='Рядом парк' />
                    <CheckboxField name='hasBesideWater' label='Рядом водоем' />
                    <CheckboxField name='hasBesideForest' label='Рядом лес' />
                    <CheckboxField name='hasBesideMall' label='Рядом ТЦ' />
                    <CheckboxField name='hasBesideSchool' label='Рядом школа' />
                    <CheckboxField
                        name='hasBesidePreSchool'
                        label='Рядом детский сад'
                    />
                </Col>
            </Row>
        </React.Fragment>
    );
}
