import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RSUBCreateForm from '~/src/components/Realty/Suburban/create';
import {
    selectCV,
    unloadCV,
} from '~/src/store/cottage.village/cottage.village.store';
import { selectDirection, unload } from '~/src/store/directionSlice';

export default function RSUBCreatePage(): JSX.Element {
    const dispatch = useDispatch();
    const { unloadItems } = useSelector(selectDirection);
    const { unloadCVItems } = useSelector(selectCV);

    useEffect(() => {
        if (unloadItems.length === 0) {
            dispatch(unload());
        }
    }, [dispatch, unloadItems]);

    useEffect(() => {
        if (unloadCVItems.length === 0) {
            dispatch(unloadCV());
        }
    }, [dispatch, unloadCVItems]);

    return <RSUBCreateForm />;
}
