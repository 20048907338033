export const rooms = [
    {
        title: 'Студия',
        code: 'studio',
    },
    {
        title: '1',
        code: '1',
    },
    {
        title: '2',
        code: '2',
    },
    {
        title: '3',
        code: '3',
    },
    {
        title: '4',
        code: '4',
    },
    {
        title: '5+',
        code: '5',
    },
];
