import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { readByCode } from '~/src/store/content';
import View from '~/src/components/Content/update';
import { setActiveMenuKey } from '~/src/store/appSlice';

const Support: FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(readByCode('support'));
    }, [dispatch]);

    useEffect(() => {
        dispatch(setActiveMenuKey('Support'));
    }, [dispatch]);

    return <View />;
};

export default Support;
