import { Field } from 'react-final-form';
import ToggleButton, { ToggleButtonType } from 'react-bootstrap/ToggleButton';
import React from 'react';

const ToggleButtonField = ({
    type,
    label,
    name,
    value,
    variantChecked,
    variantUnChecked,
    size,
    onChange,
}: TToggleButtonFieldProps): JSX.Element => {
    return (
        <Field name={name} value={value} type={type}>
            {({ input }) => {
                return (
                    <ToggleButton
                        {...input}
                        type={input.type as ToggleButtonType}
                        size={size}
                        variant={
                            input.checked ? variantChecked : variantUnChecked
                        }
                        id={`radio-${name}-${value}`}
                        onChange={
                            typeof onChange === 'function'
                                ? onChange
                                : input.onChange
                        }
                    >
                        {label}
                    </ToggleButton>
                );
            }}
        </Field>
    );
};

export type TToggleButtonFieldProps = {
    type: ToggleButtonType;
    label: string;
    name: string;
    value: string | number;
    size: 'sm' | 'lg';
    variantChecked: string;
    variantUnChecked: string;
    onChange?: (event: React.ChangeEvent | any) => void;
};

export default ToggleButtonField;
