import classNames from 'classnames';
import React, { useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import SimpleSelectFieldV2 from '~/src/components/Common/Form/SimpleSelectV2';
import { IHousingEstateHousePorchStoreyFlat } from '~/src/service/housing.estate.house.porch.storey.flat';

type TProps = {
    flat: IHousingEstateHousePorchStoreyFlat;
};

export default function FlatChessAdditiolnalParams({
    flat,
}: TProps): JSX.Element {
    const [visible, setVisible] = useState(false);

    return (
        <React.Fragment>
            <Button variant='link' onClick={() => setVisible(!visible)}>
                Доп. праметры
            </Button>
            <div className={classNames({ 'd-none': !visible })}>
                <SimpleSelectFieldV2
                    name='stainedGlass'
                    label='Витражные окна'
                    initialValue={flat.stainedGlass ? '1' : 0}
                >
                    <option></option>
                    <option value='1'>Да</option>
                    <option value='0'>Нет</option>
                </SimpleSelectFieldV2>
                <SimpleSelectFieldV2
                    name='underfloorHeatingSystem'
                    label='Теплый пол'
                    initialValue={flat.underfloorHeatingSystem ? '1' : 0}
                >
                    <option></option>
                    <option value='1'>Да</option>
                    <option value='0'>Нет</option>
                </SimpleSelectFieldV2>
                <SimpleSelectFieldV2
                    name='kitchenLiving'
                    label='Кухня - гостиная'
                    initialValue={flat.kitchenLiving ? '1' : 0}
                >
                    <option></option>
                    <option value='1'>Да</option>
                    <option value='0'>Нет</option>
                </SimpleSelectFieldV2>
                <SimpleSelectFieldV2
                    name='masterBedroom'
                    label='Мастер - спальня'
                    initialValue={flat.masterBedroom ? '1' : 0}
                >
                    <option></option>
                    <option value='1'>Да</option>
                    <option value='0'>Нет</option>
                </SimpleSelectFieldV2>
                <SimpleSelectFieldV2
                    name='highflat'
                    label='Хайфлет'
                    initialValue={flat.highflat ? '1' : 0}
                >
                    <option></option>
                    <option value='1'>Да</option>
                    <option value='0'>Нет</option>
                </SimpleSelectFieldV2>
                <SimpleSelectFieldV2
                    name='terrace'
                    label='Наличие террасы'
                    initialValue={flat.terrace ? '1' : 0}
                >
                    <option></option>
                    <option value='1'>Да</option>
                    <option value='0'>Нет</option>
                </SimpleSelectFieldV2>
                <SimpleSelectFieldV2
                    name='viewApartment'
                    label='Видовая квартира'
                    initialValue={flat.viewApartment ? '1' : 0}
                >
                    <option></option>
                    <option value='1'>Да</option>
                    <option value='0'>Нет</option>
                </SimpleSelectFieldV2>
                <SimpleSelectFieldV2
                    name='priceDiscount'
                    label='Цена со скидкой'
                    initialValue={flat.priceDiscount ? '1' : 0}
                >
                    <option></option>
                    <option value='1'>Да</option>
                    <option value='0'>Нет</option>
                </SimpleSelectFieldV2>
            </div>
        </React.Fragment>
    );
}
