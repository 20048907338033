import { Button } from 'react-bootstrap';
import OverlayingPopup from '../OverlayingPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type TProps = {
    isOpened: boolean;
    text: string;
    onClose: () => void;
    primaryButtonText?: string;
    primaryButtonOnClick: (e) => void;
    secondaryButtonText?: string;
    secondaryButtonOnClick: (e) => void;
};

const Confirm = ({
    onClose,
    isOpened,
    text,
    primaryButtonText = 'Да',
    primaryButtonOnClick,
    secondaryButtonText = 'Отмена',
    secondaryButtonOnClick,
}: TProps): JSX.Element => {
    return (
        <OverlayingPopup isOpened={isOpened} onClose={onClose}>
            <div className='main_popup'>
                <p>{text}</p>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '1rem',
                    }}
                >
                    <Button
                        size='sm'
                        type='button'
                        variant='danger'
                        onClick={secondaryButtonOnClick}
                    >
                        <FontAwesomeIcon icon={['fas', 'ban']} />{' '}
                        {secondaryButtonText}
                    </Button>
                    <Button
                        size='sm'
                        type='button'
                        variant='success'
                        onClick={primaryButtonOnClick}
                    >
                        <FontAwesomeIcon icon={['fas', 'check']} />{' '}
                        {primaryButtonText}
                    </Button>
                </div>
            </div>
        </OverlayingPopup>
    );
};

export default Confirm;
