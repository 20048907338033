import React from 'react';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { selectAuth } from '~/src/store/authSlice';

type TProps = {
    children?: JSX.Element | JSX.Element[];
    position?: any;
    permission?: string;
    searchAction?: any;
    itemAgentUid?: any;
    itemAgentAgency?: any;
};

const AuthLayer: FC<TProps> = ({
    children,
    position,
    permission,
    searchAction,
    itemAgentUid,
    itemAgentAgency,
}) => {
    const { me: user, meGroup } = useSelector(selectAuth);

    if (user && position?.includes(meGroup?.position) && user?.agencyInfo) {
        return <React.Fragment>{children}</React.Fragment>;
    }
    if (user && user?.agencyInfo?.permissions?.includes(permission)) {
        return <React.Fragment>{children}</React.Fragment>;
    }
    if (itemAgentUid === user?.uid) {
        return <React.Fragment>{children}</React.Fragment>;
    }

    return <></>;
};

export default AuthLayer;
