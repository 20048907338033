import { createSlice, Slice } from '@reduxjs/toolkit';
import * as api from '~/src/service/cottageVillage/cottage.village.service';
import type { TAppDispatch, TState, TAnyAction } from '~/src/store';
import { setLoading as setAppLoading } from '.././appSlice';

interface IChangeResponsibleOnCV {
    allCottageVillage?: { value: number; label: string }[];
}
const initialState = {
    allCottageVillage: null,
};
export const sliceName = 'changeResponsibleOnCV';
const changeResponsibleOnCVSlice: Slice<IChangeResponsibleOnCV> = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        unloadData: (
            state: IChangeResponsibleOnCV,
            action: TAnyAction<any>,
        ) => {
            state.allCottageVillage = action.payload;
        },
    },
});

export const { unloadData } = changeResponsibleOnCVSlice.actions;

export const unloadAll = () => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        const response = await api.unload();
        dispatch(
            unloadData(
                response.map(({ id, title }) => ({ value: id, label: title })),
            ),
        );
    };
};

export const setChangeResponsible = (data: Record<string, any>) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const response = await api.changeResponsible(data);
            if (response && response.success) {
                console.log('Данные успешно обновлены');
            } else {
                console.log('При добавлении данных произошла ошибка');
            }
        } catch (e) {
            console.warn(e);
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const selectChangeResponsibleOnCV = (
    state: TState,
): IChangeResponsibleOnCV => state.changeResponsibleOnCV;

export default changeResponsibleOnCVSlice.reducer;
