import React from 'react';
import classNames from 'classnames';
import { Form } from 'react-bootstrap';
import { Field } from 'react-final-form';

type TProps = React.InputHTMLAttributes<HTMLInputElement> & {
    validators?: any;
    initialValue?: string | number;
    label: string;
    value?: string | number | readonly string[] | undefined;
};

export default function InputField(props: TProps): JSX.Element {
    const {
        name,
        label,
        type,
        validators,
        initialValue,
        placeholder,
        readOnly,
        onChange,
    } = props;

    return (
        <Field
            {...props}
            name={name}
            validate={validators}
            initialValue={initialValue}
            allowNull
            parse={(value) => (value === '' ? null : value)}
        >
            {({ input, meta, ...rest }) => (
                <Form.Group className='mb-3'>
                    <Form.Label>
                        <span>{label}</span>
                        <span
                            className={classNames({
                                'text-info': true,
                                'd-none': !meta.dirty,
                            })}
                        >
                            <small> изменено</small>
                        </span>
                    </Form.Label>
                    <Form.Control
                        className={classNames({ 'border-info': meta.dirty })}
                        {...input}
                        {...rest}
                        onChange={
                            typeof onChange === 'function'
                                ? onChange
                                : input.onChange
                        }
                        type={type || 'text'}
                        size='sm'
                        value={input.value as string | number | string[]}
                        readOnly={readOnly || false}
                        isInvalid={meta.error || meta.submitError}
                        placeholder={placeholder || label}
                    />
                    <Form.Control.Feedback type='invalid'>
                        {meta.error || meta.submitError}
                    </Form.Control.Feedback>
                </Form.Group>
            )}
        </Field>
    );
}
