import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import AdminToolsView from '~/src/components/AdminTools';
import { setActiveMenuKey } from '~/src/store/appSlice';

const AdminToolsPage = (): JSX.Element => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setActiveMenuKey('ADMT'));
    }, [dispatch]);

    return <AdminToolsView />;
};

export default AdminToolsPage;
