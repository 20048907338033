const convertStringToNumber = (data: any): any => {
    if (Array.isArray(data)) {
        return data.map(item => convertStringToNumber(item));
    } else if (typeof data === 'object' && data !== null) {
        return Object.fromEntries(
            Object.entries(data).map(([key, value]) => [key, convertStringToNumber(value)])
        );
    } else if (typeof data === 'string' && !isNaN(Number(data))) {
        return Number(data);
    }
    return data;
};

export { convertStringToNumber };
