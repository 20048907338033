import { IResponseListData, IUpdateData } from '../interfaces/common';
import { IHousingEstateHouse } from './housing.estate.house';
import { IAddressPlaceDetail } from './address';
import Api from '~/src/helpers/http-common';
import { IFilestore } from './filestore';
import { IDeveloper } from './developer/developer';
import { IUser } from './user';
import { AxiosRequestConfig } from 'axios';

export const all = async (
    queryParams?: URLSearchParams,
): Promise<IResponseListData<IHousingEstate>> => {
    return await Api.get(
        `/housing-estate?${queryParams != null ? queryParams.toString() : ''}`,
    );
};

export const unload = async (): Promise<any> => {
    return await Api.get('/housing-estate/unload');
};

export const unloadArea = async (): Promise<any> => {
    return await Api.get('/address/unload-living-area');
};

export const create = async (
    data: IHousingEstate,
    files?: Record<string, FileList>,
): Promise<IHousingEstate | any> => {
    return (await Api.post<IHousingEstate>(
        '/housing-estate',
        data,
        files,
    )) as IHousingEstate;
};

export const read = async (id: number): Promise<IHousingEstate> => {
    return await Api.get(`/housing-estate/${id}`);
};

export const update = async (
    id: string | number,
    data: IHousingEstate,
    files?: Record<string, FileList>,
): Promise<IUpdateData<IHousingEstate>> => {
    return await Api.put<IUpdateData<IHousingEstate>>(
        `/housing-estate/${id}`,
        data,
        files,
    );
};

export const remove = async (id: string | number): Promise<any> => {
    return await Api.delete(`/housing-estate/${id}`);
};

// получаем данные о домах и их количестве у жк
export const infoHousingEstate = async (
    id: number,
): Promise<IHousingEstate> => {
    return await Api.get(`/housing-estate/${id}`);
};

// получаем данные о доме и количество его подъездов
export const infoHousingEstateHouse = async (
    id: number,
): Promise<IHousingEstateHouse> => {
    return await Api.get(`/housing-estate/house/${id}`);
};

export const chessPorch = async (id: number): Promise<IHousingEstate> => {
    return await Api.get(`/housing-estate/house/porch/${id}/chess`);
};

export const chess = async (id: number): Promise<IHousingEstate> => {
    return await Api.get(`/housing-estate/${id}/chess`);
};

export const switchEnabled = async (id: number | string): Promise<any> => {
    return await Api.post(`/housing-estate/${id}/switch-enabled`);
};

export const fresh = async (id: number | string): Promise<any> => {
    return await Api.post(`/housing-estate/${id}/fresh`);
};

export const deleteFile = async (
    id: string | number,
    name: string,
): Promise<IFilestore | any> => {
    const res = await Api.delete<IFilestore>(
        `/housing-estate/${id}/files/${name}`,
    );

    return res;
};

export const uploadPresentation = async (
    id: string | number,
    file: File,
): Promise<{ success: boolean; files: IFilestore }> => {
    return await Api.uploadSingle(
        `/housing-estate/${id}/upload-presentation`,
        file,
    );
};

export const uploadPhotos = async (
    id: number,
    files: FileList | File[],
    config?: AxiosRequestConfig,
): Promise<IFilestore[]> => {
    console.dir(files);
    return await Api.post(
        `/housing-estate/upload-photo/${id}`,
        null,
        { files },
        config,
    );
};

export const changeResponsible = async (
    data: Record<string, any>,
): Promise<{ success: boolean }> => {
    return await Api.post('/housing-estate/change-responsible', data);
};

export type UploadProps = {
    files: File[] | FileList;
    id?: number;
    onUploadProgress?: (e) => void;
    entryGroup?: string;
};

export interface IHousingEstate {
    id: number;
    isEnabled: boolean;
    code: string;
    title: string;
    logotype?: string;
    userUid?: string;
    developer: IDeveloper;
    latitude?: number;
    longitude?: number;

    countSaleFlatsWithoutLayout?: number;

    addressDetailId?: number;
    addressDetail?: IAddressPlaceDetail;

    houses?: IHousingEstateHouse[];

    photos?: IFilestore[];

    description?: string;

    hasCctv?: boolean;
    isClosedArea?: boolean;
    isProtectedArea?: boolean;
    hasPlayGround?: boolean;
    hasSportGround?: boolean;
    hasPicnicGround?: boolean;
    hasGroundParking?: boolean;
    hasUndergroundParking?: boolean;
    hasBesidePark?: boolean;
    hasBesideWater?: boolean;
    hasBesideForest?: boolean;
    hasBesideMall?: boolean;
    hasBesideSchool?: boolean;
    hasBesidePreSchool?: boolean;

    freshAt?: Date;
    freshBy?: string;

    createdAt?: Date;
    createdBy?: string;

    updatedAt?: Date;
    updatedBy?: string;

    owner?: IUser;

    housesCount?: number;
    flatsCount?: number;
    mortgage?: number;
    endConstruction?: string;

    presentation?: IFilestore;
    popularity?: number;
}
