import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BaseSyntheticEvent, useState } from 'react';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Row, Col, Card, Container, Button, Form, Carousel } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import OverlayingPopup from '~/src/components/Common/ModalUI/OverlayingPopup';
import { createReviews, selectUsersPA } from '~/src/store/paSlice';
import { Field, Form as FinalForm } from 'react-final-form';
import { required } from '~/src/helpers/validators';
import FileField from '~/src/components/Common/Form/FileField';
import { compressImages } from '~/src/helpers/compressImage';
import { IFilestore } from '~/src/service/filestore';
import { format } from 'date-fns';
import UpdateReviews from './Control/UpdateReviews';

const Reviews = () => {
    const dispatch = useDispatch();

    const [isOpened, setIsOpened] = useState(false);
    const [preview, setPreview] = useState([]);
    const [files, setFiles] = useState<File[]>();

    const { reviews } = useSelector(selectUsersPA);
    const base = 'https://files.citidom.com/';

    const close = () => {
        setIsOpened(false);
        setPreview([]);
        setFiles([]);
    };
    const handleSubmit = (values: Record<string, any>) => {
        dispatch(createReviews({ ...values }, { ...files }));
        setIsOpened(false);
    };

    const changeHandler = async (e: BaseSyntheticEvent) => {
        const compressedFiles = await compressImages(e.target.files, {
            quality: 0.8,
            maxWidth: 1200,
        });

        const ff = [];
        for (const compressed of compressedFiles) {
            ff.push({
                name: compressed.name,
                url: URL.createObjectURL(compressed),
                progress: 0,
            });
        }

        setPreview(ff);
        setFiles(compressedFiles);
    };

    const removeImage = async (image: IFilestore) => {
        setPreview(prev => prev.filter(item => item.name !== image.name));
        if (files) {
            setFiles(prev => prev.filter(item => item.name !== image.name));
        }
    };

    return (
        <>
            <h4 className='mt-3'>Отзывы моих клиентов</h4>
            <Container fluid>
                <Row className='overflow-auto flex-nowrap' style={{ whiteSpace: 'nowrap', padding: '10px 0px' }}>
                    <Col xs='auto' style={{ minWidth: '200px' }}>
                        <Card
                            className='d-flex align-items-center justify-content-center'
                            style={{
                                height: '100%',
                                cursor: 'pointer',
                                textAlign: 'center',
                                fontSize: '20px'
                            }}
                            onClick={() => setIsOpened(true)}
                        >
                            <FontAwesomeIcon icon={['fas', 'plus']} />
                        </Card>
                    </Col>
                    {reviews?.items?.map((item) => (
                        <Col key={item.id} xs='auto' style={{ minWidth: '200px' }}>
                            <Card style={{
                                height: '350px',
                                width: '300px',
                            }}>
                                <UpdateReviews item={item} />
                                {
                                    item?.files?.length == 1
                                        ? <Card.Img variant='top' src={`${base}${item.files[0].name}`} alt={'Фотография'}
                                            style={{
                                                height: '150px',
                                                width: '230px',
                                                objectFit: 'cover',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }} />
                                        : item?.files?.length > 1
                                            ? <Carousel interval={null}>
                                                {item.files.map((image, index) => (
                                                    <Carousel.Item key={index} >
                                                        <img
                                                            src={`${base}${item.files[index].name}`}
                                                            alt={'Фотография'}
                                                            style={{
                                                                height: '150px',
                                                                width: '230px',
                                                                objectFit: 'cover'
                                                            }}
                                                        />
                                                    </Carousel.Item>
                                                ))}
                                            </Carousel>
                                            : null
                                }
                                <Card.Body>
                                    <Card.Text style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>{item.content}</Card.Text>
                                    <Card.Footer className='text-muted'>Добавлен {item?.createdAt ? format(new Date(item.createdAt), 'dd.MM.yyyy') : 'неизвестно'}</Card.Footer>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>

            {/* Модальное окно с фомой для создания записи */}
            <OverlayingPopup onClose={close} isOpened={isOpened}>
                <div className='main_popup' style={{ width: '800px' }}>
                    <p>Добавить</p>
                    <FinalForm
                        onSubmit={handleSubmit}
                        mutators={{
                            setValue: ([field, value], state, { changeValue }) => {
                                changeValue(state, field, () => value);
                            },
                        }}
                        validate={() => {
                            const errors: any = {};
                            return errors;
                        }}
                        render={({ handleSubmit, submitting, valid }) => (
                            <form onSubmit={handleSubmit}>
                                <Field name='content' validate={required}>
                                    {({ input, meta }) => (
                                        <Form.Group className='mb-3'>
                                            <Form.Label>Отзыв</Form.Label>
                                            <Form.Control
                                                {...input}
                                                as='textarea'
                                                rows={4}
                                                size='sm'
                                                isInvalid={
                                                    meta.error || meta.submitError
                                                }
                                                placeholder='Напишите отзыв'
                                            />
                                            <Form.Control.Feedback type='invalid'>
                                                {meta.error || meta.submitError}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>
                                <Form.Group className='mb-3 d-flex gap-3 mt-3'>
                                    <label className='btn btn-sm btn-primary'>
                                        <FileField
                                            name='files'
                                            onChange={(e) => changeHandler(e)}
                                            multiple
                                        />{' '}
                                        Загрузить фото
                                    </label>
                                </Form.Group>
                                <div className='mt-2 clearfix' style={{
                                    overflowY: 'auto',
                                    maxHeight: '200px',
                                }}>
                                    {preview &&
                                        preview.length > 0 &&
                                        preview.map((image, index) => {
                                            return (
                                                <div
                                                    key={`imagePreview${image.name}`}
                                                    className='float-start'
                                                    style={{
                                                        position: 'relative'
                                                    }}
                                                >
                                                    <div style={{
                                                        position: 'absolute',
                                                        top: '0px',
                                                        right: '5px',
                                                        cursor: 'pointer'
                                                    }}
                                                        onClick={() => removeImage(image)}
                                                    >
                                                        <FontAwesomeIcon icon={faTimesCircle} style={{ color: 'red', fontSize: '20px' }} />
                                                    </div>
                                                    <img
                                                        src={image.url}
                                                        alt={image.title}
                                                        style={{
                                                            height: '150px',
                                                            width: '230px',
                                                            objectFit: 'cover'
                                                        }}
                                                    />
                                                </div>
                                            );
                                        })}
                                </div>
                                <Button
                                    size='sm'
                                    type='submit'
                                    variant='success'
                                    disabled={submitting || !valid}
                                    className='mt-3'
                                >
                                    Сохранить
                                </Button>
                            </form>
                        )}
                    />
                </div>
            </OverlayingPopup>
        </>
    );
};

export default Reviews;
