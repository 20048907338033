import React, { BaseSyntheticEvent } from 'react';
import { Button, ButtonGroup, Form, ToggleButton } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

const MultiCheckboxValue = ({
    formFilter,
    setFormFilter,
    data,
    idSelector,
    nameSelector,
    goTo,
}): JSX.Element => {
    const dispatch = useDispatch();

    const handleChangeMultiCheckboxValue = (e: BaseSyntheticEvent) => {
        if (formFilter[e.target.name] === undefined) {
            setFormFilter((prevFormFilter) => {
                const updatedFormFilter = {
                    ...prevFormFilter,
                    [e.target.name]: e.target.value,
                };
                dispatch(goTo(updatedFormFilter));
                return updatedFormFilter;
            });
            return;
        }
        if (Array.isArray(formFilter[e.target.name])) {
            if (formFilter[e.target.name]?.includes(e.target.value)) {
                setFormFilter((prevFormFilter) => {
                    const updatedFormFilter = {
                        ...prevFormFilter,
                        [e.target.name]: Array.from(
                            formFilter[e.target.name],
                        ).filter((v: string) => v !== e.target.value),
                    };
                    dispatch(goTo(updatedFormFilter));
                    return updatedFormFilter;
                });
            } else {
                setFormFilter((prevFormFilter) => {
                    const updatedFormFilter = {
                        ...prevFormFilter,
                        [e.target.name]: [
                            ...formFilter[e.target.name],
                            e.target.value,
                        ],
                    };
                    dispatch(goTo(updatedFormFilter));
                    return updatedFormFilter;
                });
            }
            return;
        }
        if (formFilter[e.target.name] === e.target.value) {
            setFormFilter((prevFormFilter) => {
                const updatedFormFilter = {
                    ...prevFormFilter,
                    [e.target.name]: [],
                };
                dispatch(goTo(updatedFormFilter));
                return updatedFormFilter;
            });
        } else {
            setFormFilter((prevFormFilter) => {
                const updatedFormFilter = {
                    ...prevFormFilter,
                    [e.target.name]: [
                        formFilter[e.target.name],
                        e.target.value,
                    ],
                };
                dispatch(goTo(updatedFormFilter));
                return updatedFormFilter;
            });
        }
    };

    return (
        <Form.Group>
            <ButtonGroup>
                {data.map((radio, idx) => (
                    <ToggleButton
                        key={idx}
                        size='sm'
                        id={`${idSelector}-${idx}`}
                        type='checkbox'
                        variant={
                            formFilter[nameSelector]?.includes(radio.value)
                                ? radio.variantOn
                                : radio.variantOff
                        }
                        name={nameSelector}
                        value={radio.value}
                        checked={formFilter[nameSelector]?.includes(
                            radio.value,
                        )}
                        onChange={handleChangeMultiCheckboxValue}
                    >
                        {radio.name}
                    </ToggleButton>
                ))}
            </ButtonGroup>
        </Form.Group>
    );
};

export default MultiCheckboxValue;
