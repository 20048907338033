import React, { FC } from 'react';
import dateFormat from 'dateformat';
import { ButtonGroup } from 'react-bootstrap';
import { IFilestore } from '~/src/service/filestore';

const ListRow: FC<{ item: IFilestore }> = ({ item }) => {
    return (
        <React.Fragment>
            <tr>
                <td>
                    <p>{item.name}</p>
                </td>
                <td>
                    <p>{item.entryGroup}</p>
                    <p>{item.entryIndex}</p>
                </td>
                <td>
                    <p>{dateFormat(item.createdAt, 'dd.mm.yyyy')}</p>
                </td>
                <td>
                    <ButtonGroup></ButtonGroup>
                </td>
            </tr>
        </React.Fragment>
    );
};

export default ListRow;
