import Api from '~/src/helpers/http-common';
import { IResponseListData, IUpdateData } from '../interfaces/common';

export const all = async (
    queryParams?: URLSearchParams,
): Promise<IResponseListData<IContent>> => {
    return await Api.get(
        `/content?${queryParams != null ? queryParams.toString() : ''}`,
    );
};

export const read = async (id: number): Promise<IContent> => {
    return await Api.get(`content/${id}`);
};

export const readByCode = async (code: string): Promise<IContent> => {
    return await Api.get(`content/code/${code}`);
};

export const update = async (
    id: string | number,
    data: IContent,
): Promise<IUpdateData<IContent>> => {
    return await Api.put<IUpdateData<IContent>>(`/content/${id}`, data);
};

export interface IContent {
    id: number;
    title: string;
    content: string;
}
