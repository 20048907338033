import { FC } from 'react';
import classNames from 'classnames';
import InputField from '../Common/Form/InputField';
import { Container } from 'react-bootstrap';
import { FieldArray } from 'react-final-form-arrays';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { remove } from '~/src/service/housing.estate.house.porch';
import ConfirmButton from '~/src/components/Common/ModalUI/ButtonWithConfirm';
import {
    composeValidators,
    minEqValue,
    mustBeNumber,
    required,
} from '~/src/helpers/validators';

type TProps = {
    houseName: string;
};

const HousingEstateHousePorchesFormBlock: FC<TProps> = ({
    houseName,
}): JSX.Element => {
    const handleDeletePorch = async (index: number, fields) => {
        const porch = fields.value[index];

        console.dir(fields);

        if (porch && porch.id) {
            console.log('delete porch from database');
            console.log('delete porch by index');
            const response = await remove(porch.id);
            response.success && fields.remove(index);
        } else {
            console.log('just remove from fields');
            fields.remove(index);
        }
    };

    return (
        <Container className={classNames('ps-5')} fluid>
            <FieldArray name={`${houseName}.porches`}>
                {({ fields }) =>
                    fields.map((name, index) => (
                        <div key={name}>
                            <ConfirmButton
                                variant='warning'
                                label='Удалить подъезд'
                                modalText={`Точно удалить подъезд # ${fields.value[index]?.number}`}
                                onClick={() => handleDeletePorch(index, fields)}
                            >
                                <FontAwesomeIcon icon={['fas', 'trash']} />{' '}
                                Удалить подъезд {fields.value[index]?.number}
                            </ConfirmButton>
                            <InputField
                                label='Номер подъезда'
                                name={`${name}.number`}
                                initialValue={
                                    fields.value[index]?.number || index + 1
                                }
                                validators={composeValidators(
                                    required,
                                    mustBeNumber,
                                    minEqValue(1),
                                )}
                            />
                            <InputField
                                label='Количество этажей'
                                name={`${name}.floorsTotal`}
                                validators={composeValidators(
                                    required,
                                    mustBeNumber,
                                    minEqValue(1),
                                )}
                            />
                            <InputField
                                label='Количество лифтов'
                                name={`${name}.elevators`}
                                validators={composeValidators(
                                    required,
                                    mustBeNumber,
                                    minEqValue(0),
                                )}
                            />
                            <hr />
                        </div>
                    ))
                }
            </FieldArray>
        </Container>
    );
};

export default HousingEstateHousePorchesFormBlock;
