import { Link } from 'react-router-dom';
import React, { FC, useState } from 'react';
import { FILES_BASE } from '~/src/constants/urls';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button, ButtonGroup } from 'react-bootstrap';

import classNames from 'classnames';

import cianLogo from '~/src/assets/img/cian.png';
import avitoLogo from '~/src/assets/img/avito.png';
import yandexLogo from '~/src/assets/img/yandex.png';
import domclickLogo from '~/src/assets/img/domclick.png';

import {
    IMegaSaleRealtye,
    removeRealtyMegasale,
} from '~/src/store/megasaleSlice';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useDispatch } from 'react-redux';

const ListPrimaryRealty: FC<TListProps> = ({ item }) => {
    const [isOpen, setOpen] = useState(false);
    const dispatch = useDispatch();

    const price = new Intl.NumberFormat('ru-RU').format(+item.realty?.price);
    const ppriceMegaSale = new Intl.NumberFormat('ru-RU').format(+item?.price);
    const discount = new Intl.NumberFormat('ru-RU').format(
        +item.realty?.price - +item?.price,
    );

    // функционал для добавления в избранное?
    // const handleFavoriteClick = () => {
    //     dispatch(favorite(item.realty?.id));
    // };

    const handleRemoveRealtyMegasale = () => {
        dispatch(removeRealtyMegasale(item.id));
    };

    return (
        <React.Fragment>
            <tr>
                <td>
                    <Link to={'/sale'}>{item.realty?.id}</Link>
                    {item.realty?.verified && (
                        <React.Fragment>
                            <br />
                            <FontAwesomeIcon
                                icon={['fas', 'check-circle']}
                                style={{ color: 'blue' }}
                                title='Подтвержденный объект'
                            />
                        </React.Fragment>
                    )}
                    <br />
                    {!item.realty?.isFullfied && (
                        <React.Fragment>
                            <FontAwesomeIcon
                                icon={['fas', 'exclamation-triangle']}
                                title={item.realty?.fullfiedStatus
                                    ?.map((field) => {
                                        return `${field.key}: ${field.value === true ? 'Да' : 'Нет'}`;
                                    })
                                    .join('\n')}
                            />
                        </React.Fragment>
                    )}
                    {item.realty?.xmlfeedunload &&
                        item.realty?.xmlfeedunload.length > 0 && (
                            <React.Fragment>
                                <br />
                                {item.realty?.xmlfeedunload.map((feed) => {
                                    if (feed.enabled) {
                                        switch (feed.feed?.type) {
                                            case 'avito':
                                                return (
                                                    <p>
                                                        <img
                                                            src={avitoLogo}
                                                            width={16}
                                                            height={16}
                                                            title={
                                                                feed.feed.title
                                                            }
                                                        />
                                                    </p>
                                                );
                                            case 'cian':
                                                return (
                                                    <p>
                                                        <img
                                                            src={cianLogo}
                                                            width={16}
                                                            height={16}
                                                            title={
                                                                feed.feed.title
                                                            }
                                                        />
                                                    </p>
                                                );
                                            case 'domclick':
                                                return (
                                                    <p>
                                                        <img
                                                            src={domclickLogo}
                                                            width={16}
                                                            height={16}
                                                            title={
                                                                feed.feed.title
                                                            }
                                                        />
                                                    </p>
                                                );
                                            case 'yandex':
                                                return (
                                                    <p>
                                                        <img
                                                            src={yandexLogo}
                                                            width={16}
                                                            height={16}
                                                            title={
                                                                feed.feed.title
                                                            }
                                                        />
                                                    </p>
                                                );
                                        }
                                    }
                                })}
                            </React.Fragment>
                        )}
                    {item.realty?.isExclusive && (
                        <React.Fragment>
                            <br />
                            <Badge className='bg-success'>Эксклюзивный</Badge>
                        </React.Fragment>
                    )}
                    {item.realty?.isDiscount && (
                        <React.Fragment>
                            <br />
                            <Badge className='bg-success'>Промо</Badge>
                        </React.Fragment>
                    )}
                    {item.realty?.isLiquid && (
                        <React.Fragment>
                            <br />
                            <Badge className='bg-success'>Ликвид</Badge>
                        </React.Fragment>
                    )}
                </td>
                <td>
                    {item.realty?.layout !== null && (
                        <>
                            <LazyLoadImage
                                src={
                                    item.realty?.layout !== null
                                        ? `${FILES_BASE}${item.realty?.layout}`
                                        : null
                                }
                                className={classNames(['m-auto', 'd-block'])}
                                width={160}
                                height={160}
                                effect='blur'
                                style={{
                                    minHeight: 160,
                                    minWidth: 160,
                                    maxWidth: 160,
                                    maxHeight: 160,
                                }}
                                onClick={() => setOpen(true)}
                            />
                            {isOpen && (
                                <Lightbox
                                    mainSrc={
                                        item.realty?.layout !== null
                                            ? `${FILES_BASE}${item.realty?.layout}`
                                            : null
                                    }
                                    onCloseRequest={() => {
                                        setOpen(false);
                                    }}
                                />
                            )}
                        </>
                    )}
                    {item.realty?.sold && <Badge bg='danger'>В архиве</Badge>}
                </td>
                <td>
                    <p>
                        <strong>Новостройка</strong>
                    </p>
                    <p>
                        <strong>Жк</strong>
                        <br />
                        {item.realty?.estate?.title}
                    </p>
                    {item.realty?.realtyType === 'primary' &&
                        item.realty?.housingEstate && (
                            <p>{item.realty?.housingEstate?.title}</p>
                        )}
                </td>
                <td>
                    {item.realty?.rooms && (
                        <p>
                            <strong>Комнат</strong>
                            <br />
                            {item.realty?.rooms}
                        </p>
                    )}
                    <strong>Площадь:</strong>
                    <br />
                    {item.realty?.spaceTotal && (
                        <p>
                            <span>Общая: </span>
                            {item.realty?.spaceTotal}
                        </p>
                    )}
                    {item.realty?.spaceLiving && (
                        <p>
                            <span>Жилая: </span>
                            {item.realty?.spaceLiving}
                        </p>
                    )}
                    {item.realty?.spaceKitchen && (
                        <p>
                            <span>Кухня: </span>
                            {item.realty?.spaceKitchen}
                        </p>
                    )}
                </td>
                <td
                    style={{
                        maxWidth: '550px',
                        minWidth: '300px',
                    }}
                >
                    <p>{item.realty?.estate?.description}</p>
                </td>
                <td>
                    <p>
                        <strong style={{ color: 'red' }}>Цена</strong>
                        <br />
                        <span style={{ color: 'red' }}>{price}</span>
                        <br></br>
                        <strong style={{ color: 'green' }}>
                            Цена для распродажи
                        </strong>
                        <br />
                        <span style={{ color: 'green' }}>{ppriceMegaSale}</span>
                        <br></br>
                        <strong>Скидка</strong>
                        <br />
                        <span>{discount}</span>
                        <br></br>
                        <br></br>
                        <strong>
                            Цена за м<sup>2</sup>
                        </strong>
                        <br></br>
                        <span>
                            {new Intl.NumberFormat('ru-RU').format(
                                Math.floor(
                                    +item.realty?.price /
                                        +item.realty?.spaceTotal,
                                ),
                            )}
                        </span>
                    </p>
                    {item.realty?.isUnderstatementNeed ? (
                        <p>
                            <strong
                                className={classNames(
                                    'border',
                                    'border-2',
                                    'border-danger',
                                )}
                            >
                                Занижение
                            </strong>
                            <br />
                            {new Intl.NumberFormat('ru-RU').format(
                                +item.realty?.understatementSum,
                            )}
                            <br />
                        </p>
                    ) : null}
                    {+item.realty?.bankEncumbrance > 0 ? (
                        <p>
                            <strong
                                className={classNames(
                                    'border',
                                    'border-2',
                                    'border-danger',
                                )}
                            >
                                Обременение
                            </strong>
                            <br />
                            {item.realty?.bankEncumbranceTitle}
                            <br />
                            {new Intl.NumberFormat('ru-RU').format(
                                +item.realty?.bankEncumbrance,
                            )}
                            <br />
                        </p>
                    ) : null}
                </td>
                <td></td>
                <td>
                    <ButtonGroup vertical={true} className='w-100'>
                        <Button
                            size='sm'
                            variant={item.isVerified ? 'success' : 'warning'}
                            title='Верификация'
                        >
                            {item.isVerified
                                ? 'Объект прошел модерацию'
                                : 'Подтвердить объект'}
                        </Button>
                        <Button
                            size='sm'
                            variant='outline-danger'
                            title='Снять с распродажи'
                            onClick={handleRemoveRealtyMegasale}
                        >
                            Снять с распродажи
                        </Button>
                    </ButtonGroup>
                </td>
            </tr>
        </React.Fragment>
    );
};

type TListProps = {
    item: IMegaSaleRealtye;
};

export default ListPrimaryRealty;
