import MapUpdateView from './MapUpdateView';
import { useNavigate } from 'react-router-dom';
import arrayMutators from 'final-form-arrays';
import InputField from '../Common/Form/InputField';
import { useDispatch, useSelector } from 'react-redux';
import SimpleSelect from '../Common/Form/SimpleSelect';
import { create } from '~/src/store/housingEstateSlice';
import { Form as FinalForm, Field, useForm } from 'react-final-form';
import { selectDeveloper } from '~/src/store/developer/developerSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HousingEstateHousesFormBlock from './HousingEstateHousesFormBlock';
import { Button, ButtonGroup, Form, Navbar, Col, Row } from 'react-bootstrap';
import {
    composeValidators,
    required,
    mustBeNumber,
} from '~/src/helpers/validators';
import { FormApi } from 'final-form';
import { geocode } from '~/src/service/address';

export default function CreateForm(): JSX.Element {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { unloaded: developers } = useSelector(selectDeveloper);

    const onSubmit = (values: Record<string, any>) => {
        dispatch(create({ ...values }));
    };

    return (
        <FinalForm
            onSubmit={onSubmit}
            mutators={{
                // expect (field, value) args from the mutator
                setValue: ([field, value], state, { changeValue }) => {
                    changeValue(state, field, () => value);
                },
                ...arrayMutators,
            }}
            validate={() => {
                const errors: any = {};
                return errors;
            }}
            render={({ handleSubmit, form, submitting, valid }) => (
                <form onSubmit={handleSubmit}>
                    <Navbar
                        className='justify-content-between'
                        bg='light'
                        expand='lg'
                        variant='light'
                    >
                        <ButtonGroup>
                            <Button
                                size='sm'
                                onClick={() => navigate(-1)}
                                disabled={submitting}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'fast-backward']}
                                />{' '}
                                Назад
                            </Button>
                            <Button
                                size='sm'
                                type='submit'
                                className='btn btn-sm btn-success'
                                disabled={submitting || !valid}
                            >
                                <FontAwesomeIcon icon={['fas', 'save']} />{' '}
                                Сохранить
                            </Button>
                        </ButtonGroup>
                    </Navbar>
                    <Row>
                        <InputField
                            name='title'
                            label='Название'
                            validators={required}
                        />
                        <SimpleSelect
                            label='Застройщик'
                            name='developerId'
                            opts={[{ id: 0, title: '' }]
                                .concat(developers)
                                .map((developer) => {
                                    if (developer?.id === 0) return { value: '', title: '' };
                                    return {
                                        value: `${developer?.id}`,
                                        title: developer?.title,
                                    };
                                })}
                            validators={required}
                        />
                        <Row>
                            <Col>
                                <Field name='addressPlaceDetailId'>
                                    {() => <></>}
                                </Field>
                                <Form.Group>
                                    <Form.Label>Адрес</Form.Label>
                                    <Field name='addressName'>
                                        {({ input }) => (
                                            <>
                                                <Form.Control
                                                    {...input}
                                                    readOnly
                                                />
                                            </>
                                        )}
                                    </Field>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Широта</Form.Label>
                                    <Field name='latitude' validate={required}>
                                        {({ input, meta }) => (
                                            <>
                                                <Form.Control
                                                    {...input}
                                                    isInvalid={
                                                        meta.error ||
                                                        meta.submitError
                                                    }
                                                    readOnly
                                                />
                                                <Form.Control.Feedback type='invalid'>
                                                    {meta.error ||
                                                        meta.submitError}
                                                </Form.Control.Feedback>
                                            </>
                                        )}
                                    </Field>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Долгота</Form.Label>
                                    <Field name='longitude' validate={required}>
                                        {({ input, meta }) => (
                                            <>
                                                <Form.Control
                                                    {...input}
                                                    isInvalid={
                                                        meta.error ||
                                                        meta.submitError
                                                    }
                                                    readOnly
                                                />
                                                <Form.Control.Feedback type='invalid'>
                                                    {meta.error ||
                                                        meta.submitError}
                                                </Form.Control.Feedback>
                                            </>
                                        )}
                                    </Field>
                                </Form.Group>
                                <InputField
                                    name='mortgage'
                                    label='Минимальная ставка по ипотеке'
                                    validators={composeValidators(mustBeNumber)}
                                    initialValue={null}
                                />
                                <Form.Group>
                                    <a
                                        href='https://autoload.avito.ru/format/New_developments.xml'
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        Искать ID здесь
                                    </a>
                                </Form.Group>
                                <InputField
                                    name='avitoXmlId'
                                    label='Avito Xml Id'
                                    validators={composeValidators(required)}
                                    initialValue={null}
                                />
                            </Col>
                            <Col>
                                <MapUpdateView
                                    onClick={async (lat, lng) => {
                                        const _res = await geocode(lat, lng);
                                        lat = _res[0]?.latitude || lat;
                                        lng = _res[0]?.longitude || lng;
                                        if (_res[0]?.placeId) {
                                            form.mutators.setValue(
                                                'addressPlaceDetailId',
                                                _res[0]?.placeId,
                                            );
                                            form.mutators.setValue(
                                                'addressName',
                                                _res[0]?.address,
                                            );
                                        }
                                        form.mutators.setValue('latitude', lat);
                                        form.mutators.setValue(
                                            'longitude',
                                            lng,
                                        );
                                    }}
                                />
                            </Col>
                        </Row>
                        <HousingEstateHousesFormBlock />
                        <Field name='description'>
                            {({ input, meta }) => (
                                <Form.Group className='mb-3'>
                                    <Form.Label>Описание</Form.Label>
                                    <Form.Control
                                        {...input}
                                        as='textarea'
                                        rows={5}
                                        size='sm'
                                        isInvalid={
                                            meta.error || meta.submitError
                                        }
                                        placeholder='Описание'
                                    />
                                    <Form.Control.Feedback type='invalid'>
                                        {meta.error || meta.submitError}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            )}
                        </Field>
                    </Row>
                    <Button
                        size='sm'
                        type='submit'
                        className='btn btn-sm btn-success'
                        disabled={submitting || !valid}
                    >
                        <FontAwesomeIcon icon={['fas', 'save']} /> Сохранить
                    </Button>
                </form>
            )}
        />
    );
}
