import queryString from 'query-string';
import type { TAppDispatch, TState, TAnyAction } from '~/src/store';
import { IResponseListData } from '../interfaces/common';
import { redirect, setLoading as setAppLoading } from './appSlice';
import { createSlice, Slice } from '@reduxjs/toolkit';
import { IXmlFeed } from '~/src/service/xml.feed';
import * as api from '~/src/service/xml.feed';

export const sliceName = 'xmlFeed';

interface IXmlFeedSlice {
    items: IResponseListData<IXmlFeed>;
    myagency: IResponseListData<IXmlFeed>;
    item: IXmlFeed;
    search: any;
    baseLink?: string;
}

const initialState = {
    myagency: {},
    item: null,
    items: {},
    search: {},
    navLink: '/xml-feed?page=1&limit=25',
    baseLink: '/xml-feed',
};

const XmlFeedSlice: Slice<IXmlFeedSlice> = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        set: (state: IXmlFeedSlice, action: TAnyAction<IXmlFeed>) => {
            state.item = action.payload;
        },
        setAll: (
            state: IXmlFeedSlice,
            action: TAnyAction<IResponseListData<IXmlFeed>>,
        ) => {
            state.items = action.payload;
        },
        setMyagency: (
            state: IXmlFeedSlice,
            action: TAnyAction<IResponseListData<IXmlFeed>>,
        ) => {
            state.myagency = action.payload;
        },
        setSearch: (state: IXmlFeedSlice, action: TAnyAction<any>) => {
            state.search = action.payload;
        },
    },
});

export const { setAll, setMyagency, set, setErrors, setSearch } =
    XmlFeedSlice.actions;

export const all = (queryParams: URLSearchParams = null) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(setAppLoading(true));
        const response = await api.all(queryParams);
        const obj = {};

        if (queryParams !== null) {
            for (const key of queryParams.keys()) {
                if (queryParams.getAll(key).length > 1) {
                    obj[key] = queryParams.getAll(key);
                } else {
                    obj[key] = queryParams.get(key);
                }
            }
        }

        dispatch(setSearch(obj));
        dispatch(setAll(response));
        dispatch(setAppLoading(false));
    };
};

export const myagency = (queryParams: URLSearchParams = null) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(setAppLoading(true));
        const response = await api.myagency(queryParams);
        const obj = {};

        if (queryParams !== null) {
            for (const key of queryParams.keys()) {
                if (queryParams.getAll(key).length > 1) {
                    obj[key] = queryParams.getAll(key);
                } else {
                    obj[key] = queryParams.get(key);
                }
            }
        }

        dispatch(setSearch(obj));
        dispatch(setAll(response));
        dispatch(setAppLoading(false));
    };
};

export const create = (data: any, files?: any) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const item = await api.create(data, files);
            if (item && item.errors) {
                dispatch(setErrors(item.errors));
            } else if (item) {
                // dispatch(redirect(getState().housingEstate.navLink));
                // dispatch(redirect(`/housing-estate/update/${item.id}`));
            }
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const read = (id: number) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const data = await api.read(id);
            dispatch(set(data));
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const update = (id: number, data: any) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const response = await api.update(id, data);
            if (response && response.success) {
                dispatch(set(response.data));
            } else {
                dispatch(setErrors(response.errors));
            }
        } catch (e) {
            console.error(e);
            dispatch(setErrors(e));
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const remove = (id: number) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const response = await api.remove(id);
            if (response && response.success) {
                dispatch(
                    setAll({
                        ...getState().xmlFeed.items,
                        items: getState().xmlFeed.items.items.filter(
                            (item: IXmlFeed) => item.id != id,
                        ),
                    }),
                );
            } else {
                dispatch(setErrors(response.errors));
            }
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const clearFeed = (id: number) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const response = await api.clear(id);
            if (response && response.success) {
                dispatch(set(response.data));
                dispatch(
                    setAll({
                        ...getState().xmlFeed.items,
                        items: getState().xmlFeed.items.items.map(
                            (item: IXmlFeed) => {
                                if (item.id == id) {
                                    return response.data;
                                }

                                return item;
                            },
                        ),
                    }),
                );
            } else {
                dispatch(setErrors(response.errors));
            }
        } catch (e) {
            console.error(e);
            dispatch(setErrors(e));
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const build = (id: number) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            await api.build(id);
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const goTo =
    (params: Record<string, any>) =>
    (dispatch: TAppDispatch, getState: () => TState): void => {
        if (params.reset === true) {
            params = initialState.search;
        } else params = { ...getState().address.search, ...params };

        dispatch(
            redirect(
                `${initialState.baseLink}?${queryString.stringify(params, { skipNull: true })}`,
            ),
        );
    };

export const selectXmlFeed = (state: TState): IXmlFeedSlice => state.xmlFeed;

export default XmlFeedSlice.reducer;
