import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setScrollPos } from '~/src/store/appSlice';
import { IPagination } from '~/src/interfaces/common';
import { Pagination, PageItem } from 'react-bootstrap';

const CustomPagination: FC<TCustomPaginationProps> = ({
    pagination,
    onClick: handleClick,
}) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const onClick = (page: number) => {
        dispatch(setScrollPos({ position: 0, key: location.pathname }));
        handleClick(page);
    };

    return (
        <Pagination size='sm'>
            {pagination.current > pagination.first && (
                <React.Fragment>
                    <PageItem onClick={() => onClick(1)}>Первая</PageItem>
                    <PageItem onClick={() => onClick(pagination.previous)}>
                        Назад
                    </PageItem>
                </React.Fragment>
            )}

            {pagination.start && pagination.end && (
                <React.Fragment>
                    {Array.from(
                        {
                            length: pagination.end - pagination.start + 1,
                        },
                        (v, k) => k + pagination.start,
                    ).map((page) => (
                        <PageItem
                            onClick={() => onClick(page)}
                            key={page}
                            disabled={pagination.current == page}
                        >
                            {page}
                        </PageItem>
                    ))}
                </React.Fragment>
            )}

            {pagination.current < pagination.last && (
                <React.Fragment>
                    <PageItem onClick={() => onClick(pagination.next)}>
                        Вперед
                    </PageItem>
                    <PageItem onClick={() => onClick(pagination.last)}>
                        Последняя
                    </PageItem>
                </React.Fragment>
            )}
        </Pagination>
    );
};

type TCustomPaginationProps = {
    pagination: IPagination;
    onClick: (page: number) => void;
};

export default CustomPagination;
