const landAppointmentBtn = [
    {
        name: 'ИЖС',
        value: 'individual_housing_construction',
        variantOff: 'outline-primary',
        variantOn: 'primary',
    },
    {
        name: 'ЛПХ',
        value: 'private_household',
        variantOff: 'outline-primary',
        variantOn: 'primary',
    },
    {
        name: 'Садоводство/огородничество',
        value: 'gardening',
        variantOff: 'outline-primary',
        variantOn: 'primary',
    },
];

const landAppointmentSelect = [
    { label: 'ИЖС', value: 'individual_housing_construction' },
    { label: 'ЛПХ', value: 'private_household' },
    { label: 'Садоводство/огородничество', value: 'gardening' },
];

export { landAppointmentBtn, landAppointmentSelect };
