import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { ButtonGroup } from 'react-bootstrap';
import HaveAccess from '../Common/HaveAccess';
import { IAgency } from '~/src/service/agency';

const IList: FC<{ item: IAgency }> = ({ item }) => {
    return (
        <React.Fragment>
            <tr>
                <td>{item.title}</td>
                <td>{item.addressDetail.formattedAddress}</td>
                <td>
                    {item.director && (
                        <React.Fragment>
                            <strong>{item.director.fullname}</strong>
                            <br />
                            {item.director.phone}
                        </React.Fragment>
                    )}
                </td>
                <td>
                    {item.contactPhone && (
                        <p>
                            <strong>Телефон</strong>
                            <br />
                            <span>{item.contactPhone}</span>
                        </p>
                    )}
                    {item.email && (
                        <p>
                            <strong>Email</strong>
                            <br />
                            <span>{item.email}</span>
                        </p>
                    )}
                </td>
                <td>
                    <ButtonGroup vertical className='w-100'>
                        <Link
                            to={`/agency/card/${item.id}`}
                            className='btn btn-sm btn-secondary'
                            title='Карточка'
                        >
                            Карточка
                        </Link>
                        <HaveAccess
                            access='agency.all'
                            me={item.directorUid}
                            roles='grand'
                        >
                            <Link
                                to={`/agency/update/${item.id}`}
                                className='btn btn-sm btn-warning'
                                title='Редактировать'
                            >
                                Редактировать
                            </Link>
                        </HaveAccess>
                    </ButtonGroup>
                </td>
            </tr>
        </React.Fragment>
    );
};

export default IList;
