import React, { useState } from 'react';
import {
    IRSModel,
    favorite,
} from '~/src/store/realtySecondary/realtySecondarySlice';
import RSCardViewNav from './card/cardNav';
import RSCardView from './card/card';
import { useDispatch } from 'react-redux';

export default function RSCard({ item }: { item: IRSModel }): JSX.Element {
    const dispatch = useDispatch();

    const handleFavorite = () => {
        dispatch(favorite(item?.id));
    };

    return (
        <React.Fragment>
            <RSCardViewNav item={item} />
            <RSCardView item={item} handleFavorite={handleFavorite} />
        </React.Fragment>
    );
}
