import { useNavigate } from 'react-router-dom';
import InputField from '../Common/Form/InputField';
import { Form as FinalForm } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import DatePickerField from '../Common/Form/DatePickerField/DatePickerField';
import { createUser, selectUsers } from '~/src/store/usersSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Button,
    ButtonGroup,
    Container,
    Navbar,
    Row,
    Col,
} from 'react-bootstrap';
import {
    composeValidators,
    required,
    mustBeNumber,
    onlyLength,
    isEmail,
} from '~/src/helpers/validators';

export default function RSCreateForm(): JSX.Element {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { loading } = useSelector(selectUsers);

    const onSubmit = (values) => {
        dispatch(createUser({ ...values }));
    };

    return (
        <FinalForm
            onSubmit={onSubmit}
            mutators={{
                setValue: ([field, value], state, { changeValue }) => {
                    changeValue(state, field, () => value);
                },
            }}
            validate={() => {
                const errors: any = {};
                return errors;
            }}
            render={({ handleSubmit, submitting, valid }) => (
                <form onSubmit={handleSubmit}>
                    <Navbar
                        className='justify-content-between'
                        bg='light'
                        expand='lg'
                        variant='light'
                    >
                        <ButtonGroup>
                            <Button
                                size='sm'
                                onClick={() => navigate(-1)}
                                disabled={submitting || loading}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'fast-backward']}
                                />{' '}
                                Назад
                            </Button>
                            <Button
                                size='sm'
                                type='submit'
                                className='btn btn-sm btn-success'
                                disabled={submitting || !valid || loading}
                            >
                                {submitting || loading ? (
                                    <FontAwesomeIcon
                                        icon={['fas', 'spinner']}
                                        spin
                                    />
                                ) : (
                                    <FontAwesomeIcon icon={['fas', 'save']} />
                                )}{' '}
                                Сохранить
                            </Button>
                        </ButtonGroup>
                    </Navbar>
                    <Container fluid>
                        <Row>
                            <Col>
                                <InputField
                                    name='phone'
                                    label='Телефон'
                                    type='number'
                                    validators={composeValidators(
                                        required,
                                        mustBeNumber,
                                        onlyLength(10),
                                    )}
                                />
                            </Col>
                            <Col>
                                <InputField
                                    name='email'
                                    label='Email'
                                    type='email'
                                    validators={composeValidators(isEmail)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputField name='lastname' label='Фамилия' />
                            </Col>
                            <Col>
                                <InputField
                                    name='firstname'
                                    label='Имя'
                                    validators={required}
                                />
                            </Col>
                            <Col>
                                <InputField
                                    name='middlename'
                                    label='Отчество'
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <DatePickerField
                                    name='birthdate'
                                    label='День рождения'
                                />
                            </Col>
                        </Row>
                        <Button
                            size='sm'
                            type='submit'
                            className='btn btn-sm btn-success'
                            disabled={submitting || !valid || loading}
                        >
                            {submitting || loading ? (
                                <FontAwesomeIcon
                                    icon={['fas', 'spinner']}
                                    spin
                                />
                            ) : (
                                <FontAwesomeIcon icon={['fas', 'save']} />
                            )}
                            Сохранить
                        </Button>
                    </Container>
                </form>
            )}
        />
    );
}
