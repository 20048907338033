import React from 'react';
import classNames from 'classnames';
import getNoun from '~/src/helpers/getNoun';
import { TPriceChangedAgo } from '~/src/store/realtySecondary/realtySecondarySlice';

export default function PriceChangedAgo(info: TPriceChangedAgo): JSX.Element {
    if (info.status === 'outdated') {
        return (
            <div
                className={classNames(
                    'border',
                    'border-2',
                    'border-danger',
                    'p-1',
                )}
            >
                Цена не менялась {getNoun(info.days, 'день', 'дня', 'дней')}
            </div>
        );
    }

    return <React.Fragment></React.Fragment>;
}
