import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import RSUBMList from '~/src/components/Realty/SuburbanModerate';
import { useQuery } from '~/src/helpers/hooks';
import {
    setActiveMenuKey,
    setHandleScrollPosition,
} from '~/src/store/appSlice';
import { all } from '~/src/store/realtySuburban/realtySuburbanSlice.moderate';

const RSUBModerate = (): JSX.Element => {
    const query = useQuery();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setActiveMenuKey('RSUBM'));
    }, [dispatch]);

    useEffect(() => {
        dispatch(setHandleScrollPosition(false));
    }, [dispatch]);

    useEffect(() => {
        dispatch(all(query));
    }, [dispatch, query]);

    return <RSUBMList />;
};

export default RSUBModerate;
