import { Button, Container, Form, FormGroup } from 'react-bootstrap';
import MainPopup from '../../Common/ModalUI/MainPopup';
import { Field, Form as FinalForm } from 'react-final-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import arrayMutators from 'final-form-arrays';
import { Suspense, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectApp } from '~/src/store/appSlice';
import {
    selectChangePersonOnHousing,
    setChangeResponsible,
} from '~/src/store/changePersonOnHousingSlice';
import Select from 'react-select';
import DropDownListAgent from '~/src/components/Common/DropDownSearchApi/DropDownListAgent';
import { IUser } from '~/src/service/user';

const OpenChangePersonOnHousin = (): JSX.Element => {
    const dispatch = useDispatch();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [holdSubmit, setHoldSubmit] = useState<boolean>(false);
    const [allSelect, setAllSelect] = useState(true);
    const [isOpenModalPopup, setIsOpenModalPopup] = useState(false);

    const { allHousingEstate } = useSelector(selectChangePersonOnHousing);
    const { loading } = useSelector(selectApp);

    const onSubmit = (values) => {
        dispatch(
            setChangeResponsible({
                id: values.housings.map((id) => id.value),
                userUid: values.userUid,
            }),
        );
    };

    const matchCheck = (arr1, arr2) => {
        if (JSON.stringify(arr1) === JSON.stringify(arr2)) {
            setAllSelect((prev) => !prev);
        } else {
            setAllSelect((prev) => !prev);
        }
    };

    const SetIsCloseModalPopup = () => setIsOpenModalPopup(false);

    return (
        <>
            <Button
                size='sm'
                type='button'
                variant='success'
                onClick={() => {
                    setIsOpenModalPopup(true);
                    setAllSelect(true);
                }}
            >
                <FontAwesomeIcon icon={['fas', 'users']} /> Сменить
                ответственных
            </Button>
            <MainPopup
                title={'Смена ответственных для ЖК'}
                onClose={SetIsCloseModalPopup}
                isOpened={isOpenModalPopup}
                fullwidth={false}
                overflow={false}
            >
                <Suspense fallback={<div>Loading...</div>}>
                    {loading ? (
                        <>Загрузка...</>
                    ) : (
                        <FinalForm
                            onSubmit={onSubmit}
                            mutators={{
                                setValue: (
                                    [field, value],
                                    state,
                                    { changeValue },
                                ) => {
                                    changeValue(state, field, () => value);
                                },
                                ...arrayMutators,
                            }}
                            validate={(values) => {
                                const errors: any = {};
                                if (
                                    !values.housings ||
                                    Object.keys(values.housings).length == 0
                                ) {
                                    errors.housings = 'Выберите ЖК';
                                }
                                if (!values.fioAgent) {
                                    errors.fioAgentError = 'Введите данные';
                                }
                                if (!values.userUidName) {
                                    errors.fioAgentError =
                                        'Введите корректные данные';
                                }
                                return errors;
                            }}
                            render={({
                                handleSubmit,
                                submitting,
                                valid,
                                form,
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <DropDownListAgent
                                        label='ФИО | Телефон'
                                        name='fioAgent'
                                        defaultValue={''}
                                        onSelect={(item: IUser) => {
                                            form.mutators.setValue(
                                                'fioAgent',
                                                item.fullname,
                                            );
                                            form.mutators.setValue(
                                                'userUidName',
                                                item.fullname,
                                            );
                                            form.mutators.setValue(
                                                'userUid',
                                                item.uid,
                                            );
                                        }}
                                    >
                                        <Field name='fioAgentError'>
                                            {({ meta }) => (
                                                <Form.Group>
                                                    <Form.Control.Feedback
                                                        type='invalid'
                                                        style={{
                                                            display:
                                                                meta.error ||
                                                                    meta.submitError
                                                                    ? 'block'
                                                                    : 'none',
                                                        }}
                                                    >
                                                        {meta.error ||
                                                            meta.submitError}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            )}
                                        </Field>
                                    </DropDownListAgent>
                                    <Field name='userUidName'>
                                        {({ input }) => (
                                            <Form.Group className='mb-3'>
                                                <Form.Label>
                                                    Имя закрепленного агента
                                                </Form.Label>
                                                <Form.Control
                                                    {...input}
                                                    size='sm'
                                                    placeholder='Имя агента'
                                                    readOnly
                                                />
                                            </Form.Group>
                                        )}
                                    </Field>
                                    <Field name='housings'>
                                        {({ input, meta }) => (
                                            <Form.Group>
                                                <Form.Group className='d-flex gap-3'>
                                                    <Form.Label>
                                                        <>Жилой комплекс</>
                                                    </Form.Label>
                                                    {allSelect ? (
                                                        <Button
                                                            variant='primary'
                                                            style={{
                                                                height: '20px',
                                                            }}
                                                            className='btn btn-sm mt-1 d-flex align-items-center justify-content-center'
                                                            onClick={() => {
                                                                form.mutators.setValue(
                                                                    'housings',
                                                                    allHousingEstate.slice(1),
                                                                );
                                                                matchCheck(
                                                                    form.getState()
                                                                        .values[
                                                                    'housings'
                                                                    ],
                                                                    allHousingEstate,
                                                                );
                                                            }}
                                                        >
                                                            Выбрать все
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            variant='danger'
                                                            style={{
                                                                height: '20px',
                                                            }}
                                                            className='btn btn-sm mt-1 d-flex align-items-center justify-content-center'
                                                            onClick={() => {
                                                                form.mutators.setValue(
                                                                    'housings',
                                                                    [],
                                                                );
                                                                matchCheck(
                                                                    form.getState()
                                                                        .values[
                                                                    'housings'
                                                                    ],
                                                                    allHousingEstate,
                                                                );
                                                            }}
                                                        >
                                                            Убрать все
                                                        </Button>
                                                    )}
                                                </Form.Group>
                                                <Select
                                                    {...input}
                                                    isMulti
                                                    options={allHousingEstate}
                                                    placeholder='Выберите ЖК'
                                                    styles={{
                                                        control: (
                                                            baseStyles,
                                                            state,
                                                        ) => ({
                                                            ...baseStyles,
                                                            maxHeight: '100px',
                                                            overflowY: 'auto',
                                                            '::-webkit-scrollbar':
                                                            {
                                                                width: '4px',
                                                            },
                                                            '::-webkit-scrollbar-thumb':
                                                            {
                                                                backgroundColor:
                                                                    '#0d6efd',
                                                                borderRadius:
                                                                    '2px',
                                                            },
                                                            '::-webkit-scrollbar-track':
                                                            {
                                                                backgroundColor:
                                                                    '#ECECEC',
                                                                borderRadius:
                                                                    '2px',
                                                            },
                                                        }),
                                                    }}
                                                />
                                                {meta.error && meta.touched && (
                                                    <span
                                                        style={{
                                                            color: 'red',
                                                            fontSize: '14px',
                                                        }}
                                                    >
                                                        {meta.error}
                                                    </span>
                                                )}
                                            </Form.Group>
                                        )}
                                    </Field>
                                    <Container
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            gap: '5px',
                                            marginTop: '10px',
                                        }}
                                    >
                                        <Button
                                            type='submit'
                                            className='btn btn-sm btn-success mt-1'
                                            disabled={
                                                submitting ||
                                                holdSubmit ||
                                                !valid
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={['fas', 'save']}
                                            />{' '}
                                            Сохранить{' '}
                                        </Button>
                                    </Container>
                                </form>
                            )}
                        />
                    )}
                </Suspense>
            </MainPopup>
        </>
    );
};
export default OpenChangePersonOnHousin;
