import RSUBCardViewNav from './card/CardNav';
import RSUBCardView from './card/card';
import React from 'react';
import { useDispatch } from 'react-redux';

import {
    IRSUBModel,
    favorite,
} from '~/src/store/realtySuburban/realtySuburbanSlice';

export default function RSUBCard({ item }: { item: IRSUBModel }): JSX.Element {
    const dispatch = useDispatch();

    const handleFavorite = () => {
        dispatch(favorite(item.id));
    };

    return (
        <React.Fragment>
            <RSUBCardViewNav item={item} />
            <RSUBCardView item={item} handleFavorite={handleFavorite} />
        </React.Fragment>
    );
}
