import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '~/src/helpers/hooks';
import { all } from '~/src/store/agency.statistics';
import ListView from '~/src/components/Agency/Statistics';

const AgencyStatisticsPage = (): JSX.Element => {
    const dispatch = useDispatch();
    const query = useQuery();

    useEffect(() => {
        dispatch(all(query));
    }, [dispatch, query]);

    return <ListView />;
};

export default AgencyStatisticsPage;
