import { useDispatch } from 'react-redux';
import { all } from '~/src/store/comment';
import React, { useEffect } from 'react';
import CommentsView from './CommentsView';

type TProps = {
    entry: number;
    section: string;
};

const Comments = ({ section, entry }: TProps): JSX.Element => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(all(section, entry));
    });

    return <CommentsView />;
};

export default Comments;
