import {
    Button,
    ButtonGroup,
    Col,
    Form,
    Navbar,
    Row,
    Table,
} from 'react-bootstrap';
import ListRow from '../XmlFeed/ListRow';
import Pagination from '~/src/components/Common/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { myagency, goTo, selectXmlFeed } from '~/src/store/xml.feed';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const ListView = () => {
    const dispatch = useDispatch();

    const { items } = useSelector(selectXmlFeed);

    const handlePaginationClick = (page: number) => {
        dispatch(goTo({ page }));
    };

    return (
        <>
            <Navbar
                className='justify-content-between'
                bg='light'
                expand='lg'
                variant='light'
            >
                <ButtonGroup>
                    <Link
                        to={'/xml-feed/create'}
                        className='btn btn-sm btn-success'
                    >
                        <FontAwesomeIcon icon={['fas', 'plus']} /> Добавить
                        новый фид
                    </Link>
                    <Button
                        size='sm'
                        variant='secondary'
                        onClick={() => dispatch(myagency())}
                    >
                        <FontAwesomeIcon icon={['fas', 'sync']} /> Обновить
                    </Button>
                </ButtonGroup>
            </Navbar>
            {items && items.pagination?.have && (
                <Pagination
                    pagination={items.pagination}
                    onClick={handlePaginationClick}
                />
            )}
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <td>Название</td>
                        <td>Добавлено в фид</td>
                        <td>Управление</td>
                    </tr>
                </thead>
                <tbody>
                    {items && items.items && items.items.length > 0
                        ? items.items.map((item) => (
                              <ListRow key={item.id} item={item} />
                          ))
                        : null}
                </tbody>
            </Table>
        </>
    );
};

export default ListView;
