import App from './components/App';
import { createBrowserRouter } from 'react-router-dom';
import Dashboard from '~/src/pages/dashboard';

import Login from '~/src/pages/Login/login';
import Logout from '~/src/pages/Login/Logout';
import Registration from './pages/Login/Registration';
import ForgotPassword from './pages/Login/ForgotPassword';

import RSListPage from './pages/RealtySecondary/';
import RSMListPage from './pages/RealtySecondaryModerate/';
import RSCardPage from './pages/RealtySecondaryModerate/card';
import RSUpdatePage from './pages/RealtySecondary/update';
import RSCreatePage from './pages/RealtySecondary/create';
import RSPhotosPage from './pages/RealtySecondary/photos';

import RSUBListPage from './pages/RealtySuburban/';
import RSUBCreatePage from './pages/RealtySuburban/create';
import RSUBUpdatePage from './pages/RealtySuburban/update';
import RSUBMListPage from './pages/RealtySuburbanModerate/';
import RSUBCardPage from './pages/RealtySuburbanModerate/card';
import RSUBBigMap from './pages/RealtySuburban/bigmap';

import UsersPage from './pages/Users';
import UsersPaPage from './pages/Users/pa';
import UsersUpdatePage from './pages/Users/update';
import UsersCreatePage from './pages/Users/create';

import PermissionPage from './pages/Permission';
import PermissionUpdatePage from './pages/Permission/update';

import UserPermissionPage from './pages/Users/permission';

import AdminToolsPage from './pages/AdminTools';
import BigMap from './pages/RealtySecondary/bigmap';

import AgencyPage from './pages/Agency';
import AgencyCreatePage from './pages/Agency/create';
import MyAgencyPage from './pages/Agency/my';
import AgencyAgentsPage from './pages/Agency/agents';
import AgencyStatisticsPage from './pages/Agency/Statistics';
import AgencyAgentUpdatePage from './pages/Agency/Agent/update';

import AgencyPermissionPage from './pages/Agency/Permission';
import AgencyPermissionUpdatePage from './pages/Agency/Permission/update';

import HousingEstatePage from './pages/HousingEstate';
import HousingEstateCreatePage from './pages/HousingEstate/create';
import HousingEstateUpdatePage from './pages/HousingEstate/update';
import HousingEstateGenerateFlatsPage from './pages/HousingEstate/generate';

import DeveloperPage from './pages/Developer';
import DeveloperUpdatePage from './pages/Developer/update';

import HousingEstateHousesPage from './pages/HousingEstate/houses';
import React from 'react';
import AddressPage from './pages/Address';
import AddressUpdatePage from './pages/Address/update';

import AboutServicePage from './pages/AboutService';
import SupportPage from './pages/Support';
import FilestorePage from './pages/Filestore';

import AgencyXmlFeedPage from './pages/Agency/XmlFeed';
import XmlFeedUpdatePage from './pages/XmlFeed/update';
import AgencySettingsPage from './pages/Agency/Settings';

import InfoPage from './pages/Info';
import InfoAgentsPage from './pages/Info/agents';
import InfoUsersPage from './pages/Info/users';
import InfoUnverifiedAgentsPage from './pages/Info/unverified';

import BuyersListPage from './pages/Buyer';
import BuyersTrelloPage from './pages/Buyer/trello';
import BuyersUpdatePage from './pages/Buyer/update';
import SalePage from './pages/Sale';
import CottageVillagePage from './pages/CottageVillage';
import CottageVillageCreatePage from './pages/CottageVillage/create';
import CottageVillageUpdatePage from './pages/CottageVillage/update';
import { CottageVillagePhasesPage } from './pages/CottageVillage/phases';
import { DeveloperHPPage } from './pages/Developer/HouseProject';
import { CreateDHPPage } from './pages/Developer/HouseProject/create';
import { UpdateDHPPage } from './pages/Developer/HouseProject/update';
import { GenerateCottageVillagePhasePage } from './pages/CottageVillage/phases/generate';
import AgencyGroup from './pages/Agency/Group';
import AgencyGroupUpdatePage from './pages/Agency/Group/update';

export const router = createBrowserRouter([
    {
        path: '/login',
        element: <Login />,
    },
    {
        path: '/logout',
        element: <Logout />,
    },
    {
        path: '/registration',
        element: <Registration />,
    },
    {
        path: '/forgot',
        element: <ForgotPassword />,
    },
    {
        path: '/',
        element: <App />,
        children: [
            {
                index: true,
                element: <Dashboard />,
            },
            {
                path: '/realty-secondary',
                element: <RSListPage />,
            },
            {
                path: '/realty-secondary/create',
                element: <RSCreatePage />,
            },
            {
                path: '/realty-secondary/card/:id',
                element: <RSCardPage />,
            },
            {
                path: '/realty-secondary/edit/:id',
                element: <RSUpdatePage />,
            },
            {
                path: '/realty-secondary/photos/:id',
                element: <RSPhotosPage />,
            },
            {
                path: '/realty-secondary-map',
                element: <BigMap />,
            },
            {
                path: '/realty-secondary-moderate',
                element: <RSMListPage />,
            },
            {
                path: '/realty-suburban',
                element: <RSUBListPage />,
            },
            {
                path: '/realty-suburban/create',
                element: <RSUBCreatePage />,
            },
            {
                path: '/realty-suburban/card/:id',
                element: <RSUBCardPage />,
            },
            {
                path: '/realty-suburban/edit/:id',
                element: <RSUBUpdatePage />,
            },
            {
                path: '/realty-suburban-map',
                element: <RSUBBigMap />,
            },
            {
                path: '/realty-suburban-moderate',
                element: <RSUBMListPage />,
            },
            // {
            //     path: '/realty-secondary/photos/:id',
            //     element: <RSPhotosPage />
            // }, {
            //     path: '/realty-secondary-moderate',
            //     element: <RSMListPage />
            // },
            {
                path: '/buyer',
                element: <BuyersTrelloPage />,
            },
            {
                path: '/buyer/list',
                element: <BuyersListPage />,
            },
            {
                path: '/buyer/create/',
                element: <BuyersUpdatePage />,
            },
            {
                path: '/buyer/update/:id/',
                element: <BuyersUpdatePage />,
            },
            {
                path: '/agency',
                element: <AgencyPage />,
            },
            {
                path: '/agency/create',
                element: <AgencyCreatePage />,
            },
            {
                path: '/agency/my',
                element: <MyAgencyPage />,
            },
            {
                path: '/agency/:id/agent',
                element: <AgencyAgentsPage />,
            },
            {
                path: '/agency/:id/group',
                element: <AgencyGroup />,
            },
            {
                path: '/agency/group/:id/update',
                element: <AgencyGroupUpdatePage />,
            },
            {
                path: '/agency/my/statistics',
                element: <AgencyStatisticsPage />,
            },
            {
                path: '/agency/agent/:id/update',
                element: <AgencyAgentUpdatePage />,
            },
            {
                path: '/agency/permission',
                element: <AgencyPermissionPage />,
            },
            {
                path: '/agency/permission/create',
                element: <AgencyPermissionUpdatePage />,
            },
            {
                path: '/agency/permission/update/:id',
                element: <AgencyPermissionUpdatePage />,
            },
            {
                path: '/agency/my/xml-feed',
                element: <AgencyXmlFeedPage />,
            },
            {
                path: '/agency/my/settings',
                element: <AgencySettingsPage />,
            },
            {
                path: '/xml-feed/create',
                element: <XmlFeedUpdatePage />,
            },
            {
                path: '/xml-feed/update/:id',
                element: <XmlFeedUpdatePage />,
            },
            {
                path: '/housing-estate',
                element: <HousingEstatePage />,
            },
            {
                path: '/housing-estate/create',
                element: <HousingEstateCreatePage />,
            },
            {
                path: '/housing-estate/update/:id',
                element: <HousingEstateUpdatePage />,
            },
            {
                path: '/housing-estate/:housingEstateId/houses',
                element: <HousingEstateHousesPage />,
            },
            {
                path: '/housing-estate/:estateId/house/:houseId/porch/:porchId/generate',
                element: <HousingEstateGenerateFlatsPage />,
            },
            {
                path: '/cottage-village',
                element: <CottageVillagePage />,
            },
            {},
            {
                path: '/cottage-village/create',
                element: <CottageVillageCreatePage />,
            },
            {},
            {
                path: '/cottage-village/update/:id',
                element: <CottageVillageUpdatePage />,
            },
            {},
            {
                path: '/cottage-village/:id/phases/',
                element: <CottageVillagePhasesPage />,
            },
            {
                path: '/cottage-village/:id/phases/:phase_id/generate',
                element: <GenerateCottageVillagePhasePage />,
            },
            {
                path: '/developer',
                element: <DeveloperPage />,
            },
            {
                path: '/developer/create',
                element: <DeveloperUpdatePage />,
            },
            {
                path: '/developer/update/:id',
                element: <DeveloperUpdatePage />,
            },
            {},
            {
                path: '/developer-house-project',
                element: <DeveloperHPPage />,
            },
            {},
            {
                path: '/developer-house-project/create',
                element: <CreateDHPPage />,
            },
            {},
            {
                path: '/developer-house-project/update/:id',
                element: <UpdateDHPPage />,
            },
            {
                path: '/users',
                element: <UsersPage />,
            },
            {
                path: '/users/create',
                element: <UsersCreatePage />,
            },
            {
                path: '/users/edit/:id',
                element: <UsersUpdatePage />,
            },
            {
                path: '/pa',
                element: <UsersPaPage />,
            },
            {
                path: '/about-service',
                element: <AboutServicePage />,
            },
            {
                path: '/support',
                element: <SupportPage />,
            },
            {
                path: '/sale',
                element: <SalePage />,
            },
            {
                path: '/permission',
                element: <PermissionPage />,
            },
            {
                path: '/permission/create',
                element: <PermissionUpdatePage />,
            },
            {
                path: '/permission/update/:id',
                element: <PermissionUpdatePage />,
            },
            {
                path: '/user/:id/permission',
                element: <UserPermissionPage />,
            },
            {
                path: '/admin-tools',
                element: <AdminToolsPage />,
            },
            {
                path: '/address',
                element: <AddressPage />,
            },
            {
                path: '/address/update/:id',
                element: <AddressUpdatePage />,
            },
            {
                path: '/filestore-test',
                element: <FilestorePage />,
            },
            {
                path: '/info-agency',
                element: <InfoPage />,
            },
            {
                path: '/info-agents',
                element: <InfoAgentsPage />,
            },
            {
                path: '/info-users',
                element: <InfoUsersPage />,
            },
            {
                path: '/info-unverified-agents',
                element: <InfoUnverifiedAgentsPage />,
            },
        ],
    },
]);
