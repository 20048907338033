import React, { useState } from 'react';
import { selectAuth } from '~/src/store/authSlice';
import { Form as FinalForm } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Form } from 'react-bootstrap';
import SelectSearch from '../Common/Form/SelectSearch';
import OverlayingPopup from '../Common/ModalUI/OverlayingPopup';
import { TAppDispatch, TState } from '~/src/store';
import { IUser } from '~/src/service/user';

type TProps = {
    item: IItem;
    transfer: (id: number[] | string[], uid: string, name: string) => (dispatch: TAppDispatch, getState: () => TState) => Promise<void>
};

const ButtonWithTransferControl = ({ item, transfer }: TProps): JSX.Element => {
    const [isOpened, setIsOpened] = useState(false);

    const { me } = useSelector(selectAuth);

    const dispatch = useDispatch();

    const handleSubmit = (values: Record<string, any>) => {
        dispatch(transfer([item?.id], values.agentUid.value, values.agentUid.label));
        setIsOpened(false);
    };

    const close = () => setIsOpened(false);
    const open = () => setIsOpened(true);

    const agents = me?.agencyInfo?.agencyAgents?.map(({ fullname, uid }) => {
        return {
            value: uid,
            label: fullname,
        };
    });
    const meInfo = {
        value: item?.agent?.uid,
        label: item?.agent?.fullname,
    };

    return (
        <React.Fragment>
            <Button
                size='sm'
                variant='secondary'
                title='Трансфер'
                onClick={open}
            >
                Трансфер
            </Button>
            <OverlayingPopup onClose={close} isOpened={isOpened}>
                <div className='main_popup' style={{ width: '400px' }}>
                    <p>Трансфер объекта с ID {item?.id}</p>
                    <FinalForm
                        onSubmit={handleSubmit}
                        initialValues={{}}
                        mutators={{
                            setValue: (
                                [field, value],
                                state,
                                { changeValue },
                            ) => {
                                changeValue(state, field, () => value);
                            },
                        }}
                        validate={(values) => {
                            const errors: any = {};
                            if (!values.agentUid) {
                                errors.agentUid = 'Не указан агент';
                            }

                            return errors;
                        }}
                        render={({ handleSubmit, submitting, valid }) => {
                            return (
                                <form onSubmit={handleSubmit}>
                                    <Container fluid>
                                        <Form.Group className='mb-3'>
                                            <SelectSearch
                                                name='agentUid'
                                                opts={agents}
                                                label={'Выберите агента'}
                                                initialValue={meInfo}
                                            />
                                        </Form.Group>
                                        <Button
                                            size='sm'
                                            type='submit'
                                            variant='success'
                                            disabled={submitting || !valid}
                                        >
                                            Сохранить
                                        </Button>
                                    </Container>
                                </form>
                            );
                        }}
                    />
                </div>
            </OverlayingPopup>
        </React.Fragment>
    );
};

export default ButtonWithTransferControl;

export interface IItem {
    agent?: IUser,
    agentInfo?: IUser,
    id: number,
}
