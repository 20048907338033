import type { TAppDispatch, TState, TAnyAction } from '~/src/store';
import { setLoading as setAppLoading } from './appSlice';
import { createSlice, Slice } from '@reduxjs/toolkit';
import { IContent } from '../service/content';
import * as api from '~/src/service/content';

export const sliceName = 'content';

interface IContentSlice {
    item: IContent;
    errors: any;
}

const initialState = {
    item: null,
    errors: null,
};

const ContentSlice: Slice<IContentSlice> = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        set: (state: IContentSlice, action: TAnyAction<IContent>) => {
            state.item = action.payload;
        },
        setErrors: (state: IContentSlice, action: TAnyAction<any>) => {
            state.item = action.payload;
        },
    },
});

const { set, setErrors } = ContentSlice.actions;

export const read = (id: number) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const data = await api.read(id);
            dispatch(set(data));
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const readByCode = (code: string) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const data = await api.readByCode(code);
            dispatch(set(data));
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const update = (id: number, data: any) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const response = await api.update(id, data);
            if (response && response.success) {
                dispatch(set(response.data));
            } else {
                dispatch(setErrors(response.errors));
            }
        } catch (e) {
            console.error(e);
            dispatch(setErrors(e));
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const selectContent = (state: TState): IContentSlice => state.content;

export default ContentSlice.reducer;
