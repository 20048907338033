const benefitsWhenPurchasing = [
    {
        value: 'hypothecBenefit',
        label: 'Ипотека - Подбор ипотеки с минимальной ставкой и первоначальным взносом.',
    },
    {
        value: 'installmentPlan',
        label: 'Рассрочка - Возможность покупки в рассрочку, без переплаты.',
    },
    {
        value: 'isDiscount',
        label: 'Скидки - Получите скидку при единовремнной полной оплате.',
    },
    {
        value: 'isStateProgramm',
        label: 'Госпрограммы - Покупка на средства любой государственной программы.',
    },
    { value: 'isTradeIn', label: 'Трейд ин - Обмен на вашу недвижимость.' },
    {
        value: 'buyersCompensation',
        label: 'Компенсируем покупателям из других регионов УрФО проезд и проживание.',
    },
];

export { benefitsWhenPurchasing };
