import dateFormat from 'dateformat';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import HaveAccess from '../../Common/HaveAccess';
import { Card, Col, Row } from 'react-bootstrap';
import ModalOneTextAreaField from '../../Common/ModalUI/ModalOneField/TextAreaField';
import { update } from '~/src/store/comment';

const Comment = ({ comment }) => {
    const dispatch = useDispatch();

    const [isOpened, setIsOpened] = useState(false);

    const handleUpdateComment = (content: string) => {
        dispatch(update(comment.id, { content }));
    };

    return (
        <React.Fragment key={comment.id}>
            <Card>
                <Card.Header>
                    <Row>
                        <Col className='d-flex justify-content-between'>
                            <Card.Text>
                                {comment?.user?.lastname +
                                    ' ' +
                                    comment?.user?.firstname}
                            </Card.Text>
                            <Card.Text>
                                {dateFormat(
                                    comment?.createdAt,
                                    'dd.mm.yyyy HH:MM',
                                )}
                            </Card.Text>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body style={{ whiteSpace: 'pre-line' }}>
                    <Card.Text>{comment.content}</Card.Text>
                    {/** На будущее - возможность удаление и редактирования комментариев */}
                    {/* <HaveAccess
                        access={'*'}
                        me={comment.createdBy}
                    >
                        <Stack direction='horizontal' gap={3}>
                            <Button
                                size='sm'
                                variant='secondary'
                                onClick={() => setIsOpened(true)}
                            >
                                <FontAwesomeIcon icon={['fas', 'pencil-alt']} /> Редактировать
                            </Button>
                            <Button
                                size='sm'
                                variant='danger'
                            >
                                <FontAwesomeIcon icon={['fas', 'trash']} /> Удалить
                            </Button>
                        </Stack>
                    </HaveAccess> */}
                </Card.Body>
            </Card>
            <br></br>
            <ModalOneTextAreaField
                isOpened={isOpened}
                text={'Введите новый комментарий'}
                defaultValue={comment.content}
                onClose={() => setIsOpened(false)}
                primaryButtonOnClick={handleUpdateComment}
                secondaryButtonOnClick={() => setIsOpened(false)}
            />
        </React.Fragment>
    );
};

export default Comment;
