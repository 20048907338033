import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import UpdateView from '~/src/components/Users/update';
import { getUser, selectUsers } from '~/src/store/usersSlice';

const UpdatePage = (): JSX.Element => {
    const dispatch = useDispatch();
    const params = useParams<TParams>();

    const { loading } = useSelector(selectUsers);

    useEffect(() => {
        dispatch(getUser(+params.id));
    }, [params.id, dispatch]);

    return (
        <React.Fragment>
            {loading ? <div>Загрузка...</div> : <UpdateView />}
        </React.Fragment>
    );
};

type TParams = {
    id: string;
};

export default UpdatePage;
