import ModalOneField from './index';
import { Button, ButtonProps } from 'react-bootstrap';
import React, { useState, FC } from 'react';

const ButtonWithModalOneField: FC<IProps> = ({
    title,
    variant,
    primaryButtonOnClick,
    modalText,
    defaultValue,
    children,
}) => {
    const [isOpened, setIsOpened] = useState(false);

    const close = () => setIsOpened(false);
    const open = () => setIsOpened(true);

    const handleClick = (value: string) => {
        primaryButtonOnClick(value);
        close();
    };

    return (
        <React.Fragment>
            <Button
                size='sm'
                variant={variant || 'secondary'}
                title={title}
                onClick={open}
            >
                {children}
            </Button>
            <ModalOneField
                text={modalText}
                onClose={close}
                isOpened={isOpened}
                primaryButtonOnClick={handleClick}
                secondaryButtonOnClick={close}
                defaultValue={defaultValue}
            />
        </React.Fragment>
    );
};

interface IProps extends ButtonProps {
    modalText: string;
    defaultValue?: string;
    primaryButtonOnClick: (value: string) => void;
}

export default ButtonWithModalOneField;
