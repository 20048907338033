import { AxiosRequestConfig } from 'axios';
import Api from '~/src/helpers/http-common';
import { IResponseListData } from '../interfaces/common';

export const unload = async (): Promise<IUnloadSocial[]> => {
    return await Api.get('/social/unload');
};

export const getListSocial = async (): Promise<IResponseListData<ISocial>> => {
    return await Api.get('/social');
};

export interface ISocial {
    id?: number;
    title?: string;
    code?: string;
    type?: string;
    icon?: string;
    createdAt?: Date;
    updatedAt?: Date;
}

export interface IUnloadSocial {
    id: number;
    title: string;
}
