import { Form } from 'react-bootstrap';
import RangeSlider from 'react-bootstrap-range-slider';
import { Field } from 'react-final-form';

interface IProps {
    name: string;
    min?: number;
    max?: number;
    step?: number;
    label: React.ReactNode | string;
    validators?: any;
    initialValue?: string | number;
    onChange?: (event: React.ChangeEvent | any) => void;
    [otherProp: string]: any;
}

export default function SliderField({
    name,
    label,
    validators,
    initialValue,
    min,
    max,
    step,
    onChange,
}: IProps): JSX.Element {
    return (
        <Field
            name={name}
            validate={validators}
            initialValue={initialValue}
            allowNull
            parse={(value) => (value === '' ? null : value)}
        >
            {({ input, meta }) => (
                <Form.Group className='mb-3'>
                    <Form.Label>{label}</Form.Label>
                    <RangeSlider
                        {...input}
                        onChange={
                            typeof onChange === 'function'
                                ? onChange
                                : input.onChange
                        }
                        size='sm'
                        min={min}
                        max={max}
                        step={step}
                        tooltipPlacement='top'
                    />
                    <Form.Control.Feedback type='invalid'>
                        {meta.error || meta.submitError}
                    </Form.Control.Feedback>
                </Form.Group>
            )}
        </Field>
    );
}
