import queryString from 'query-string';
import { IFilestore } from '../../service/filestore';
import { createSlice, Slice } from '@reduxjs/toolkit';
import { successNotify } from '../../helpers/createNotify';
import { UploadProps } from '../../service/housing.estate';
import { IResponseListData } from '../../interfaces/common';
import { redirect, setLoading as setAppLoading } from '../appSlice';
import type { TAppDispatch, TState, TAnyAction } from '~/src/store';
import * as api from '~/src/service/cottageVillage/cottage.village.plot.service';
import { ICottageVillagePlot } from '~/src/service/cottageVillage/cottage.village.plot.service';
import { ICottageVillageConstructionPhase } from '../../service/cottageVillage/cottage.village.construction.phase.service';

export const sliceName = 'cottage.village.plot';

interface ICottageVillagePlotSlice {
    items: IResponseListData<ICottageVillagePlot>;
    item?: ICottageVillagePlot;
    itemPhotos?: IFilestore[];
    creating?: ICottageVillagePlot;
    activePhase?: ICottageVillageConstructionPhase;
    search?: any;
    errors?: any;
    navLink: string;
    loading: boolean;
}

const initialState = {
    items: {},
    search: {
        sort: 'id_desc',
        limit: 25,
        page: 1,
    },
    item: null,
    itemPhotos: [],
    activePhase: null,
    creating: { phases: [{}] } as unknown as ICottageVillagePlot,
    loading: false,
    navLink: '/cottage-village/plot?sort=id_desc&limit=25&page=1',
    baseLink: '/cottage-village/plot',
};

const cottageVillageSlice: Slice<ICottageVillagePlotSlice> = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        setAll: (
            state: ICottageVillagePlotSlice,
            action: TAnyAction<IResponseListData<ICottageVillagePlot>>,
        ) => {
            state.items = action.payload;
        },
        set: (
            state: ICottageVillagePlotSlice,
            action: TAnyAction<ICottageVillagePlot>,
        ) => {
            state.item = action.payload;
            state.itemPhotos = state.item.photos;
        },
        setErrors: (
            state: ICottageVillagePlotSlice,
            action: TAnyAction<any>,
        ) => {
            state.errors = action.payload;
        },
        setSearch: (
            state: ICottageVillagePlotSlice,
            action: TAnyAction<any>,
        ) => {
            state.search = action.payload;
        },
        setNavLink: (
            state: ICottageVillagePlotSlice,
            action: TAnyAction<string>,
        ) => {
            state.navLink = action.payload;
        },
        setCreating: (
            state: ICottageVillagePlotSlice,
            action: TAnyAction<ICottageVillagePlot>,
        ) => {
            state.creating = action.payload;
        },
        setPhotos: (
            state: ICottageVillagePlotSlice,
            action: TAnyAction<IFilestore[]>,
        ) => {
            state.itemPhotos = action.payload;
        },
        setActivePhase: (
            state: ICottageVillagePlotSlice,
            action: TAnyAction<any>,
        ) => {
            state.activePhase = action.payload;
        },
    },
});

export const {
    setAll,
    set,
    setErrors,
    setSearch,
    setNavLink,
    setCreating,
    setPhotos,
    setActivePhase,
} = cottageVillageSlice.actions;

export const all = (queryParams: URLSearchParams = null) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(setAppLoading(true));
        const response = await api.all(queryParams);
        const obj = {};

        for (const key of queryParams.keys()) {
            if (queryParams.getAll(key).length > 1) {
                obj[key] = queryParams.getAll(key);
            } else {
                obj[key] = queryParams.get(key);
            }
        }

        dispatch(setSearch(obj));
        dispatch(
            setNavLink(`${initialState.baseLink}?${queryParams.toString()}`),
        );
        dispatch(setAll(response));
        dispatch(setAppLoading(false));
    };
};

export const create = (data: any, files?: any) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            dispatch(setCreating(data));
            const item = await api.create(data, files);
            if (item && item.errors) {
                dispatch(setErrors(item.errors));
            } else if (item) {
                dispatch(setCreating(initialState.creating));
                dispatch(redirect(getState()[sliceName].navLink));
                dispatch(redirect(`/cottage-village/plot/update/${item.id}`));
            }
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const read = (id: number) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const data = await api.read(id);
            dispatch(set(data));
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const update = (
    id: number,
    data: any,
    files?: Record<string, FileList>,
) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const response = await api.update(id, data, files);
            if (response && response.success) {
                dispatch(set(response.data));
            } else {
                dispatch(setErrors(response.errors));
            }
        } catch (e) {
            console.error(e);
            dispatch(setErrors(e));
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const remove = (id: number) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const response = await api.remove(id);
            if (response && response.success) {
                dispatch(
                    setAll({
                        ...getState()[sliceName].items,
                        items: getState()[sliceName].items.items.filter(
                            (item: ICottageVillagePlot) => item.id != id,
                        ),
                    }),
                );
            } else {
                dispatch(setErrors(response.errors));
            }
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const uploadPhoto = ({ id, files, onUploadProgress }: UploadProps) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        const response = await api.uploadPhotos(id, files, {
            onUploadProgress: onUploadProgress,
        });

        console.log('upload response');
        console.dir(response);

        if (response && Array.isArray(response)) {
            if (getState()[sliceName].item.id === id) {
                dispatch(
                    setPhotos([
                        ...getState()[sliceName].itemPhotos,
                        ...response,
                    ]),
                );
            }

            successNotify({
                message: `Загружено ${response.length} фото`,
                title: 'Успешно!',
            });
        }
    };
};

export const deleteFile = (id: number, name: string) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        const response = await api.deleteFile(id, name);
        if (response) {
            dispatch(
                set({
                    ...getState()[sliceName].item,
                    photos: getState()[sliceName].item.photos.filter(
                        (photo: IFilestore) => photo.name !== name,
                    ),
                }),
            );
        } else {
            dispatch(setErrors(response.errors));
        }
    };
};

export const goTo =
    (params: Record<string, any>) =>
    (dispatch: TAppDispatch, getState: () => TState): void => {
        if (params.reset === true) {
            params = initialState.search;
        } else params = { ...getState()[sliceName].search, ...params };

        dispatch(
            redirect(
                `${initialState.baseLink}?${queryString.stringify(params, { skipNull: true })}`,
            ),
        );
    };

export const selectCVP = (state: TState): ICottageVillagePlotSlice =>
    state[sliceName];

export default cottageVillageSlice.reducer;
