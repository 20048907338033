import Api from '~/src/helpers/http-common';
import { IHousingEstate } from './housing.estate';
import { IHousingEstateHouse } from './housing.estate.house';
import { IResponseListData, IUpdateData } from '../interfaces/common';
import { IHousingEstateHousePorch } from './housing.estate.house.porch';
import { IHousingEstateHousePorchStorey } from './housing.estate.house.porch.storey';
import {
    IMegaSaleRealtye,
    IMegasaleInfoMegasale,
} from '../store/megasaleSlice';

export const all = async (
    queryParams?: URLSearchParams,
): Promise<IResponseListData<IHousingEstateHousePorchStoreyFlat>> => {
    return await Api.get(
        `/housing-estate/house/porch/storey/flat/?${queryParams != null ? queryParams.toString() : ''}`,
    );
};

export const create = async (
    data: IHousingEstateHousePorchStoreyFlat,
    files?: Record<string, FileList>,
): Promise<IHousingEstateHousePorchStoreyFlat | any> => {
    return (await Api.post<IHousingEstateHousePorchStoreyFlat>(
        '/housing-estate/house/porch/storey/flat/',
        data,
        files,
    )) as IHousingEstateHousePorchStoreyFlat;
};

export const read = async (
    id: number,
): Promise<IHousingEstateHousePorchStoreyFlat> => {
    return await Api.get(`/housing-estate/house/porch/storey/flat/${id}`);
};

export const update = async (
    id: string | number,
    data: Partial<IHousingEstateHousePorchStoreyFlat>,
    files?: Record<string, FileList>,
): Promise<IUpdateData<IHousingEstateHousePorchStoreyFlat>> => {
    return await Api.put<IUpdateData<IHousingEstateHousePorchStoreyFlat>>(
        `/housing-estate/house/porch/storey/flat/${id}`,
        data,
        files,
    );
};

export const updateSameByStoreys = async (
    id: string | number,
    data: Partial<IHousingEstateHousePorchStoreyFlat>,
    files?: Record<string, FileList>,
): Promise<IUpdateData<IHousingEstateHousePorchStoreyFlat>> => {
    return await Api.put<IUpdateData<IHousingEstateHousePorchStoreyFlat>>(
        `/housing-estate/house/porch/storey/flat/${id}/updatesamebystoreys/`,
        data,
        files,
    );
};

export const remove = async (
    id: string | number,
): Promise<IUpdateData<IHousingEstateHousePorchStoreyFlat>> => {
    return await Api.delete(`/housing-estate/house/porch/storey/flat/${id}`);
};

export const saleChange = async (
    id: number,
): Promise<Partial<IUpdateData<IHousingEstateHousePorchStoreyFlat>>> => {
    return await Api.post(
        `/housing-estate/house/porch/storey/flat/${id}/sale-change/`,
    );
};

export interface IHousingEstateHousePorchStoreyFlat {
    id?: number;
    housingEstateId?: number;
    housingEstateHouseId?: number;
    housingEstateHousePorchId?: number;
    housingEstateHousePorchStoreyId: number;
    price?: number | string;
    spaceTotal?: string | number;
    spaceKitchen?: string | number;
    spaceLiving?: string | number;
    spaceBathroom?: string | number;
    levels?: number;
    rooms?: number;
    room?: number;
    flatNumber?: string;
    layout?: string;
    ceiling?: number;
    sold?: boolean;

    stainedGlass?: boolean;
    underfloorHeatingSystem?: boolean;
    kitchenLiving?: boolean;
    masterBedroom?: boolean;
    terrace?: boolean;
    highflat?: boolean;
    viewApartment?: boolean;
    priceDiscount?: boolean;

    createdAt?: Date;
    createdBy?: string;
    updatedAt?: Date;
    updatedBy?: string;

    estate?: IHousingEstate;
    house?: IHousingEstateHouse;
    porch?: IHousingEstateHousePorch;
    storey?: IHousingEstateHousePorchStorey;
    megasale?: IMegaSaleRealtye;
}
