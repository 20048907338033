import Api from '~/src/helpers/http-common';
import { IHousingEstate } from './housing.estate';
import { IHousingEstateHouse } from './housing.estate.house';
import { IResponseListData, IUpdateData } from '../interfaces/common';
import { IHousingEstateHousePorchStorey } from './housing.estate.house.porch.storey';
import { IHousingEstateHousePorchStoreyFlat } from './housing.estate.house.porch.storey.flat';

export const all = async (
    queryParams?: URLSearchParams,
): Promise<IResponseListData<IHousingEstateHousePorch>> => {
    return await Api.get(
        `/housing-estate/house/porch/?${queryParams != null ? queryParams.toString() : ''}`,
    );
};

export const create = async (
    data: IHousingEstateHousePorch,
    files?: Record<string, FileList>,
): Promise<IHousingEstateHousePorch | any> => {
    return (await Api.post<IHousingEstateHousePorch>(
        '/housing-estate/house/porch/',
        data,
        files,
    )) as IHousingEstateHousePorch;
};

export const read = async (id: number): Promise<IHousingEstateHousePorch> => {
    return await Api.get(`/housing-estate/house/porch/${id}`);
};

export const update = async (
    id: string | number,
    data: IHousingEstateHousePorch,
): Promise<IUpdateData<IHousingEstateHousePorch>> => {
    return await Api.put<IUpdateData<IHousingEstateHousePorch>>(
        `/housing-estate/house/porch/${id}`,
        data,
    );
};

export const remove = async (
    id: string | number,
): Promise<IUpdateData<IHousingEstateHousePorch>> => {
    return await Api.delete(`/housing-estate/house/porch/${id}`);
};

export const generate = async (
    id: string | number,
    data: IGeneratePorchData,
): Promise<any> => {
    const files = [];

    for (const flat of data.flats) {
        files.push(flat.layout);
    }

    return Api.post<any>(`/housing-estate/house/porch/generate/${id}`, data, {
        files: files,
    });
};

export interface IGeneratePorchData {
    storeys: string;
    flats: Partial<IHousingEstateHousePorchStoreyFlat>[];
}

export interface IHousingEstateHousePorch {
    id?: number;
    housingEstateId?: number;
    housingEstateHouseId?: number;
    number?: number;
    elevators?: number;
    floorsTotal?: number;
    createdAt?: Date;
    createdBy?: string;
    updatedAt?: Date;
    updatedBy?: string;
    estate?: IHousingEstate;
    house?: IHousingEstateHouse;
    storeys?: IHousingEstateHousePorchStorey[];

    countSaleFlats?: number;
    countSaleFlatsWithoutLayout?: number;
}
