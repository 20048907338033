import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectApp, setScrollPos } from '~/src/store/appSlice';
import { BaseSyntheticEvent, ReactNode, useEffect, useRef } from 'react';

// type Tpops = {
//     chl: ReactNode
// };

const Container = ({ children }): JSX.Element => {
    const dispatch = useDispatch();

    const { scrollPosition, shouldHandleScrollPosition } =
        useSelector(selectApp);

    const ref = useRef(null);

    const handleScrollPosition = (e: BaseSyntheticEvent) => {
        if (shouldHandleScrollPosition) {
            console.log(location.pathname);
            dispatch(
                setScrollPos({
                    position: e.target.scrollTop,
                    key: location.pathname,
                }),
            );
        }
    };

    useEffect(() => {
        const cur = ref.current;

        if (scrollPosition[location.pathname] !== undefined) {
            cur?.scrollTo({ top: scrollPosition[location.pathname] });
        }
    });

    return (
        <div
            className='container-fluid ace-overflow-auto'
            ref={ref}
            onScroll={handleScrollPosition}
        >
            {children}
        </div>
    );
};

export default React.memo(Container, () => true);
