import React from 'react';
import getNoun from '~/src/helpers/getNoun';
import { IRSModel } from '~/src/store/realtySecondary/realtySecondarySlice';

export default function DaysOrHoursForRemove({
    freshAgo,
    freshAgoH,
    isFullfiedButPhoto,
}: IRSModel): JSX.Element {
    if (isFullfiedButPhoto) {
        return (
            <>{`В базе еще ${freshAgo > 1 ? getNoun(freshAgo, 'день', 'дня', 'дней') : getNoun(freshAgoH, 'час', 'часа', 'часов')}`}</>
        );
    } else {
        return <>В базе еще {getNoun(freshAgoH, 'час', 'часа', 'часов')}</>;
    }
}
