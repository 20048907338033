import { useQuery } from '~/src/helpers/hooks';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '~/src/components/Common/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BaseSyntheticEvent, useCallback } from 'react';
import { redirect } from '~/src/store/appSlice';
import {
    Button,
    ButtonGroup,
    Col,
    Form,
    Navbar,
    Row,
    Table,
} from 'react-bootstrap';
import ListRow from './ListRow';
import { selectFilestore, goTo, all, upload } from '~/src/store/filestore';
import compressImage from '~/src/helpers/compressImage';

const ListView = (): JSX.Element => {
    const query = useQuery();
    const dispatch = useDispatch();

    const { items, search } = useSelector(selectFilestore);

    const sortHandler = useCallback(
        (e: BaseSyntheticEvent) => {
            dispatch(goTo({ sort: e.currentTarget.value }));
        },
        [dispatch],
    );

    const limitHandler = useCallback(
        (e: BaseSyntheticEvent) => {
            dispatch(goTo({ limit: e.currentTarget.value }));
        },
        [dispatch],
    );

    const handlePaginationClick = (page: number) => {
        dispatch(goTo({ page }));
    };

    const onUploadProgress = (event) => {
        console.log('EVENT');
        console.dir(event);
        const percentCompleted = Math.round((event.loaded * 100) / event.total);
        console.log('onUploadProgress', percentCompleted);
    };

    const changeHandler = (e: BaseSyntheticEvent) => {
        const compressPromises: Promise<File>[] = [];
        for (const file of e.target.files) {
            compressPromises.push(compressImage(file, { quality: 0.6 }));
        }

        Promise.all(compressPromises)
            .then((compressedFiles) => {
                const objectUrs = [];
                for (const compressed of compressedFiles) {
                    objectUrs.push(URL.createObjectURL(compressed));
                    // dispatch(upload({ file: compressed, group: 'test', entry: 1, onUploadProgress: onUploadProgress }));
                }
                /* setPreview(objectUrs);
            setUploadFiles(compressedFiles); */
            })
            .catch((error) => console.error('ooops :(', error));
    };

    return (
        <>
            <Navbar
                className='justify-content-between'
                bg='light'
                expand='lg'
                variant='light'
            >
                <ButtonGroup>
                    <label className='btn btn-sm btn-primary'>
                        <FontAwesomeIcon icon={['fas', 'upload']} />
                        <input
                            type='file'
                            onChange={(e) => changeHandler(e)}
                            multiple
                        />{' '}
                        Загрузить файл
                    </label>
                    {/*
                    <Button
                        size='sm'
                        variant='primary'
                        onClick={() => console.log('upload')}
                    ><FontAwesomeIcon icon={['fas', 'upload']} /> Загрузить</Button> */}
                </ButtonGroup>
                <ButtonGroup>
                    <Button
                        size='sm'
                        variant='secondary'
                        onClick={() => dispatch(all(query))}
                    >
                        <FontAwesomeIcon icon={['fas', 'sync']} /> Обновить
                    </Button>
                    <Button
                        size='sm'
                        variant='danger'
                        onClick={() => dispatch(redirect('/filestore'))}
                    >
                        <FontAwesomeIcon icon={['fas', 'sync']} /> Сбросить
                        фильтр
                    </Button>
                </ButtonGroup>
            </Navbar>
            <Row className='mb-2'>
                <Col xs={4}>
                    <Form.Label>Сортировать</Form.Label>
                    <Form.Select
                        className='form-control'
                        size='sm'
                        value={search?.sort || 'price_asc'}
                        onChange={sortHandler}
                    >
                        <option value='id_asc'>ID &uarr;</option>
                        <option value='id_desc'>ID &darr;</option>
                    </Form.Select>
                </Col>
                <Col xs={4}>
                    <Form.Label>На странице</Form.Label>
                    <Form.Select
                        className='form-control'
                        size='sm'
                        value={search?.limit || 25}
                        onChange={limitHandler}
                    >
                        <option value={1}>1</option>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={100}>100</option>
                    </Form.Select>
                </Col>
            </Row>
            {items && items.pagination?.have && (
                <Pagination
                    pagination={items.pagination}
                    onClick={handlePaginationClick}
                />
            )}
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <td>#</td>
                        <td>Полный адрес</td>
                        <td>Инфо</td>
                        <td>Управление</td>
                    </tr>
                </thead>
                <tbody>
                    {items && items.items && items.items.length > 0
                        ? items.items.map((item) => (
                              <ListRow key={item.name} item={item} />
                          ))
                        : null}
                </tbody>
            </Table>
            {items && items.pagination?.have && (
                <Pagination
                    pagination={items.pagination}
                    onClick={handlePaginationClick}
                />
            )}
        </>
    );
};

export default ListView;
