import classNames from 'classnames';
import { Form } from 'react-bootstrap';
import { Field } from 'react-final-form';

interface IProps {
    name: string;
    label: string;
    validators?: any;
    onChange?: (event: React.ChangeEvent | any) => void;
    initialValue?: string | number;
    [otherProp: string]: any;
}

export default function SimpleSelectV2Field({
    name,
    label,
    validators,
    onChange,
    initialValue,
    multiple,
    children,
}: IProps): JSX.Element {
    return (
        <Field
            name={name}
            validate={validators}
            parse={(value) => (value === '' ? '' : value)}
            initialValue={initialValue}
        >
            {({ input, meta }) => {
                return (
                    <Form.Group className='mb-3'>
                        <Form.Label>
                            <span>{label}</span>
                            <span
                                className={classNames({
                                    'text-info': true,
                                    'd-none': !meta.dirty,
                                })}
                            >
                                <small> изменено</small>
                            </span>
                        </Form.Label>
                        <Form.Select
                            className={classNames({
                                'border-info': meta.dirty,
                            })}
                            {...input}
                            onChange={
                                typeof onChange === 'function'
                                    ? onChange
                                    : input.onChange
                            }
                            size='sm'
                            isInvalid={meta.error || meta.submitError}
                            placeholder={label}
                            multiple={multiple || false}
                        >
                            {children}
                        </Form.Select>
                        <Form.Control.Feedback type='invalid'>
                            {meta.error || meta.submitError}
                        </Form.Control.Feedback>
                    </Form.Group>
                );
            }}
        </Field>
    );
}
