import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '~/src/helpers/hooks';
import { all, setCV } from '~/src/store/developer/developer.house.project';
import ListView from '~/src/components/Developer/HouseProject';
import { setActiveMenuKey, setHandleScrollPosition } from '~/src/store/appSlice';
import { selectChangeResponsibleOnDHP, unloadAll } from '~/src/store/developer/developer.house.project.changeResponsible';
import { selectDeveloper, unloadSuburban } from '~/src/store/developer/developerSlice';
import { selectCV } from '~/src/store/cottage.village/cottage.village.store';

const DeveloperHPPage = (): JSX.Element => {
    const query = useQuery();
    const dispatch = useDispatch();

    const { allHouseProjects } = useSelector(selectChangeResponsibleOnDHP);
    const { unloadedSuburban } = useSelector(selectDeveloper);
    const { items } = useSelector(selectCV);

    useEffect(() => {
        dispatch(setActiveMenuKey('DHP'));
    }, [dispatch]);

    useEffect(() => {
        dispatch(setHandleScrollPosition(true));
    }, [dispatch]);

    useEffect(() => {
        if (allHouseProjects === null) {
            dispatch(unloadAll());
        }
    }, [dispatch, allHouseProjects]);

    useEffect(() => {
        if (unloadedSuburban === null) {
            dispatch(unloadSuburban());
        }
    }, [dispatch, unloadedSuburban]);


    useEffect(() => {
        dispatch(all(query));
    }, [dispatch, query]);

    useEffect(() => {
        if (query.get('cottageVillageId')) {
            if (items) {
                dispatch(setCV(items.items?.find(item => item.id == Number(query.get('cottageVillageId')))));
            } else {
                dispatch(setCV(Number(query.get('cottageVillageId'))));
            }
        } else {
            dispatch(setCV(null));
        }
    }, [dispatch, query]);

    return <ListView />;
};

export { DeveloperHPPage };
