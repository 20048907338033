import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AgencyGroupView from '~/src/components/Agency/Group';
import { selectAgencyGroup, unloadAllGroup } from '~/src/store/agency.group.slice';


const AgencyGroup = () => {
    const dispatch = useDispatch();
    const { itemsUnload } = useSelector(selectAgencyGroup);

    useEffect(() => {
        if (itemsUnload.length === 0) {
            dispatch(unloadAllGroup());
        }
    }, [dispatch, itemsUnload]);

    return (
        <AgencyGroupView />
    );
};

export default AgencyGroup;
