import { useState } from 'react';
import { Button, Form, ListGroup, ListGroupItem } from 'react-bootstrap';
import OverlayingPopup from '~/src/components/Common/ModalUI/OverlayingPopup';

export default function SUBPhotoUploadRules() {
    const [isOpened, setIsOpened] = useState(false);

    const open = () => setIsOpened(true);
    const close = () => setIsOpened(false);

    return (
        <Form.Group>
            <Button
                style={{ cursor: 'pointer' }}
                onClick={open}
                variant='warning'
            >
                Правила добавления фото
            </Button>
            <OverlayingPopup isOpened={isOpened} onClose={close}>
                <div
                    style={{
                        overflow: 'auto',
                        height: '80%',
                        backgroundColor: 'white',
                        position: 'relative',
                        padding: '10px 10px 0',
                    }}
                >
                    <div>
                        <p>
                            <strong>
                                Правила добавления фото для объектов в категории загородная недвижимость, для
                                появления вашего объекта на сайте и в приложении.
                            </strong>
                        </p>

                        <ListGroup style={{
                            border: 'none'
                        }}>
                            <ListGroup.Item >
                                <strong>
                                    1. Допускается использование только реальных фотографии, горизонтального размещения, предлагаемого объекта.
                                </strong>
                            </ListGroup.Item>
                            <ListGroup.Item ><strong>2. Для рекламы объекта необходимо минимум 3 фотографии:</strong></ListGroup.Item>
                            <ListGroup.Item >
                                <ul>
                                    <ListGroup.Item >2.1. Земельный участок минимум одно фото, остальные два допускаются фото самого поселка (КП, ДНТ, СНТ и т.д.)</ListGroup.Item>
                                    <ListGroup.Item >2.2. Дом/ коттедж/ дача, минимум три фото внутри самого объекта (без учета санузла, ванной, террасы).</ListGroup.Item>
                                </ul>
                            </ListGroup.Item>
                            <ListGroup.Item ><strong>3. Очередность фотографий выстраивается в следующем порядке:</strong></ListGroup.Item>
                            <ListGroup.Item >
                                <ul>
                                    <ListGroup.Item >3.1. Земельные участки главным фото может быть фото въезда в поселок (КП, ДНТ, СНТ и т.д.)<br />
                                        Так же допускается рендер хорошего качества с местом размещения земельного участка.
                                    </ListGroup.Item >
                                    <ListGroup.Item >3.2. Дом/ коттедж/ дача:</ListGroup.Item>
                                    <ListGroup.Item >
                                        <ul>
                                            <ListGroup.Item >Главное фото – самая удачная фотография фасада.</ListGroup.Item>
                                            <ListGroup.Item >Далее фотографии внутри дома.</ListGroup.Item>
                                            <ListGroup.Item > Фотографии участка, придомовой территории и построек (бани, гаражи, хоз. блоки).</ListGroup.Item>
                                            <ListGroup.Item >Планировка желательно должна быть отрисована.</ListGroup.Item>
                                        </ul>
                                    </ListGroup.Item>
                                </ul>
                            </ListGroup.Item>
                            <ListGroupItem ><strong>4. Запрещается публиковать фотографии, содержащие:</strong></ListGroupItem>
                            <ListGroupItem >
                                <ul>
                                    <ListGroupItem >Личные фотографии в интерьере</ListGroupItem>
                                    <ListGroupItem >Людей, животных, алкогольные напитки, табачные изделия</ListGroupItem>
                                    <ListGroupItem >Включенный телевизор</ListGroupItem>
                                    <ListGroupItem >Грязную посуду, раскиданный вещи</ListGroupItem>
                                    <ListGroupItem >В санузлах предметы личной гигиены</ListGroupItem>
                                    <ListGroupItem >Отражения в зеркале и блики от вспышки</ListGroupItem>
                                    <ListGroupItem >Любые водные знаки, дату время съемки и иные знаки.</ListGroupItem>
                                    <ListGroupItem >Размытые фотографии, увеличенные из маленьких, на фото перекошенные объекты.</ListGroupItem>
                                    <ListGroupItem >Рендеры.</ListGroupItem>
                                    <ListGroupItem >Ретушированные логотипы (ЗАМАЗАННЫЕ), растянутые фотографии.</ListGroupItem>
                                </ul>
                            </ListGroupItem>
                        </ListGroup>
                    </div>
                </div>
            </OverlayingPopup>
        </Form.Group>
    );
}
