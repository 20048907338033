import { useNavigate } from 'react-router-dom';
import InputField from '../Common/Form/InputField';
import { required } from '~/src/helpers/validators';
import { Form as FinalForm } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup, Container, Navbar } from 'react-bootstrap';
import { create, update, selectPermission } from '~/src/store/permission';
import createInitialFormValues from '~/src/helpers/createInitialFormValues';

export default function UpdateForm(): JSX.Element {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { item } = useSelector(selectPermission);

    const onSubmit = (values: Record<string, any>) => {
        if (item?.id === undefined) {
            dispatch(create({ ...values }));
        } else {
            dispatch(update(+values.id, { ...values }));
        }
    };

    return (
        <FinalForm
            onSubmit={onSubmit}
            initialValues={{
                ...createInitialFormValues(item),
            }}
            mutators={{
                // expect (field, value) args from the mutator
                setValue: ([field, value], state, { changeValue }) => {
                    changeValue(state, field, () => value);
                },
            }}
            validate={() => {
                const errors: any = {};
                return errors;
            }}
            render={({ handleSubmit, submitting, valid }) => (
                <form onSubmit={handleSubmit}>
                    <Navbar
                        className='justify-content-between'
                        bg='light'
                        expand='lg'
                        variant='light'
                    >
                        <ButtonGroup>
                            <Button
                                size='sm'
                                onClick={() => navigate(-1)}
                                disabled={submitting}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'fast-backward']}
                                />{' '}
                                Назад
                            </Button>
                            <Button
                                size='sm'
                                type='submit'
                                className='btn btn-sm btn-success'
                                disabled={submitting || !valid}
                            >
                                <FontAwesomeIcon icon={['fas', 'save']} />{' '}
                                Сохранить
                            </Button>
                        </ButtonGroup>
                    </Navbar>
                    <Container fluid>
                        <InputField
                            name='title'
                            label='Название'
                            validators={required}
                        />
                        <InputField
                            name='code'
                            label='Код'
                            validators={required}
                        />
                        <InputField
                            name='group'
                            label='Группа'
                            validators={required}
                        />
                    </Container>
                    <Button
                        size='sm'
                        type='submit'
                        className='btn btn-sm btn-success'
                        disabled={submitting || !valid}
                    >
                        <FontAwesomeIcon icon={['fas', 'save']} /> Сохранить
                    </Button>
                </form>
            )}
        />
    );
}
