import queryString from 'query-string';
import * as api from '~/src/service/agency.agents';
import { createSlice, Slice } from '@reduxjs/toolkit';
import { IAgencyAgent } from '~/src/service/agency.agents';
import { uploadAgents } from '~/src/service/agency';
import { redirect, setLoading as setAppLoading } from './appSlice';
import type { TAppDispatch, TState, TAnyAction } from '~/src/store';

export const sliceName = 'agencyAgents';

interface IAgencyAgentsSlice {
    items: IAgencyAgent[];
    filteredItems: IAgencyAgent[];
    item?: IAgencyAgent;
    search?: any;
    errors?: any;
    navLink: string;
    unloadedPermissions: any[];
}

const initialState = {
    items: [],
    filteredItems: [],
    search: {
        sort: 'id_desc',
        limit: 25,
        page: 1,
    },
    navLink: '/agency/my/agent?sort=id_desc&limit=25&page=1',
    baseLink: '/agency/my/agent',
    unloadedPermissions: [],
};

const agencyAgentsSlice: Slice<IAgencyAgentsSlice> = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        setAll: (
            state: IAgencyAgentsSlice,
            action: TAnyAction<IAgencyAgent[]>,
        ) => {
            state.items = action.payload;
        },
        set: (state: IAgencyAgentsSlice, action: TAnyAction<IAgencyAgent>) => {
            state.item = action.payload;
        },
        setErrors: (state: IAgencyAgentsSlice, action: TAnyAction<any>) => {
            state.errors = action.payload;
        },
        setSearch: (state: IAgencyAgentsSlice, action: TAnyAction<any>) => {
            state.search = action.payload;
        },
        setNavLink: (state: IAgencyAgentsSlice, action: TAnyAction<string>) => {
            state.navLink = action.payload;
        },
        setUnloadedPermissions: (
            state: IAgencyAgentsSlice,
            action: TAnyAction<any>,
        ) => {
            state.unloadedPermissions = action.payload;
        },
        setFilteredItems: (
            state: IAgencyAgentsSlice,
            action: TAnyAction<IAgencyAgent[]>,
        ) => {
            state.filteredItems = action.payload;
        },
    },
});

const {
    setAll,
    set,
    setErrors,
    setSearch,
    setUnloadedPermissions,
    setFilteredItems,
} = agencyAgentsSlice.actions;

export const all = (id: string, queryParams: URLSearchParams = null) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(setAppLoading(true));
        const agents = await api.all(id, queryParams);
        const obj = {};

        if (queryParams) {
            for (const key of queryParams.keys()) {
                if (queryParams.getAll(key).length > 1) {
                    obj[key] = queryParams.getAll(key);
                } else {
                    obj[key] = queryParams.get(key);
                }
            }
        }

        dispatch(setSearch(obj));
        dispatch(setAll(agents));
        dispatch(setFilteredItems(agents));
        dispatch(setAppLoading(false));
    };
};

export const create = (data: IAgencyAgent, files?: any) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const item = await api.create(data, files);
            if (item && item.errors) {
                dispatch(setErrors(item.errors));
            } else if (item) {
                dispatch(redirect(getState().agency.navLink));
                dispatch(redirect(`${initialState.baseLink}/edit/${item.id}`));
            }
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const read = (id: number) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const data = await api.read(id);
            console.log('DATA');
            console.dir(data);
            dispatch(set(data));
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const update = (id: number, data: IAgencyAgent) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const response = await api.update(id, data);
            if (response && response.success) {
                dispatch(set(response.data));
            } else {
                dispatch(setErrors(response.errors));
            }
        } catch (e) {
            console.error(e);
            dispatch(setErrors(e));
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const remove = (id: number) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const response = await api.remove(id);
            if (response && response.success) {
                dispatch(
                    setAll({
                        ...getState().agencyAgents.items,
                        items: getState().agencyAgents.items.filter(
                            (item: IAgencyAgent) => item.id != id,
                        ),
                    }),
                );
            } else {
                dispatch(setErrors(response.errors));
            }
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const fire = (id: number) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const response = await api.fire(id);
            if (response && response.success) {
                dispatch(
                    setAll([
                        ...getState().agencyAgents.items.filter(
                            (item: IAgencyAgent) => item.id != id,
                        ),
                    ]),
                );
                dispatch(
                    setFilteredItems([
                        ...getState().agencyAgents.filteredItems.filter(
                            (item: IAgencyAgent) => item.id != id,
                        ),
                    ]),
                );
            }
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const getUnloadedAgencyAgentPermissions = (id: string | number) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const response = await api.getUnloadedUserPermissions(id);
            dispatch(setUnloadedPermissions(response));
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

// wewqewew
export const filterList = (q: string) => {
    return (dispatch: TAppDispatch, getState: () => TState): void => {
        if (q.length > 0) {
            dispatch(
                setFilteredItems(
                    getState().agencyAgents.items.filter((item) =>
                        item.userInfo?.fullname
                            ?.toLocaleLowerCase()
                            .includes(q.toLocaleLowerCase()),),
                ),
            );
        } else {
            dispatch(setFilteredItems(getState().agencyAgents.items));
        }
    };
};

export const uploadAgentsByExcel = (files: FileList) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        const response = await uploadAgents(files);

        if (response && response.success) {
            dispatch(all('my'));
        }
    };
};

export const goTo =
    (params: Record<string, any>) =>
    (dispatch: TAppDispatch, getState: () => TState): void => {
        if (params.reset === true) {
            params = initialState.search;
        } else params = { ...getState().agencyAgents.search, ...params };

        dispatch(
            redirect(
                `${initialState.baseLink}?${queryString.stringify(params, { skipNull: true })}`,
            ),
        );
    };

export const selectAgencyAgents = (state: TState): IAgencyAgentsSlice =>
    state.agencyAgents;

export default agencyAgentsSlice.reducer;
