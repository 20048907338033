import { useState } from 'react';
import Error from '../../Common/Form/Error';
import { useNavigate } from 'react-router-dom';
import { geocode } from '~/src/service/address';
import { selectAuth } from '~/src/store/authSlice';
import { useDebouncedCallback } from 'use-debounce';
import { useDispatch, useSelector } from 'react-redux';
import { create } from '~/src/store/realtySecondary/realtySecondarySlice';
import MapUpdateView from '../../HousingEstate/MapUpdateView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ToggleButtonField from '../../Common/Form/ToggleButtonWrapper';
import { Form as FinalForm, Field, FormSpy } from 'react-final-form';
import { checkDouble } from '~/src/service/realty.secondary.service';
import DropDownSearchApi from '~/src/components/Common/DropDownSearchApi';
import React from 'react';

import {
    Button,
    ButtonGroup,
    Container,
    Form,
    Navbar,
    Row,
    Col,
} from 'react-bootstrap';
import {
    composeValidators,
    required,
    mustBeNumber,
    onlyLength,
    priceSixDigit,
} from '~/src/helpers/validators';
import { selectChangePersonOnHousing } from '~/src/store/changePersonOnHousingSlice';
import Select from 'react-select';
import CheckboxField from '../../Common/Form/CheckboxField';
import {
    unload as actionUnloadHEH,
} from '~/src/store/housingEstateHouseSlice';
import createInitialFormValues from '~/src/helpers/createInitialFormValues';

export default function RSCreateForm(): JSX.Element {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [uploadFiles, setUploadFiles] = useState(null);
    const [holdSubmit, setHoldSubmit] = useState(false);
    const [isDouble, setIsDouble] = useState(false);
    const [doubleId, setDoubleId] = useState(null);

    const { me } = useSelector(selectAuth);
    const { allHousingEstate } = useSelector(selectChangePersonOnHousing);

    const onSubmit = (values: Record<string, any>) => {
        dispatch(
            create(
                {
                    ...createInitialFormValues(values),
                    price: values.price.replaceAll(/\D/g, ''),
                    ...(values.housingEstateId && {
                        housingEstateId: values.housingEstateId.value,
                    }),
                },
                { photosUpload: uploadFiles },
            ),
        );
    };

    // Проверка на дубли проходит только внутри агентства или для агента
    const debounced = useDebouncedCallback(
        async (placeId, flatNumber, userUid) => {
            if (placeId && flatNumber && userUid) {
                const res = await checkDouble(placeId, flatNumber, userUid);
                setHoldSubmit(false);
                if (res) {
                    setIsDouble(res.double);
                    setDoubleId(res.id);
                }
            }
        },
        2000,
    );

    return (
        <FinalForm
            onSubmit={onSubmit}
            initialValues={{
                dealType: 'sale',
                // For tests
                // 'addressPlaceDetailId': '3697',
            }}
            mutators={{
                // expect (field, value) args from the mutator
                setValue: ([field, value], state, { changeValue }) => {
                    changeValue(state, field, () => value);
                },
                setPriceValue: ([field, value], state, { changeValue }) => {
                    value = value.replaceAll(/\D/g, '');
                    changeValue(state, field, () => new Intl.NumberFormat('ru-RU').format(value));
                },
                setAddressCheckValue: async (
                    [field, value],
                    state,
                    { changeValue },
                ) => {
                    changeValue(state, field, () => value);
                    setHoldSubmit(true);
                    debounced(
                        state.formState.values['addressPlaceDetailId'],
                        state.formState.values['flatNumber'],
                        me.uid,
                    );
                },
                changeRealtySubType: (
                    [field, value],
                    state,
                    { changeValue },
                ) => {
                    changeValue(state, field, () => value);
                    changeValue(state, 'realtySubtype', () => null);
                    changeValue(state, 'housingEstateId', () => null);
                    // if (value === 'primary') {
                    //     changeValue(
                    //         state,
                    //         'realtySubTypeDisabled',
                    //         () => 'none',
                    //     );
                    // } else {
                    //     changeValue(
                    //         state,
                    //         'realtySubTypeDisabled',
                    //         () => 'block',
                    //     );
                    // }
                },
                changeHousingEstate: (
                    [field, value],
                    state,
                    { changeValue },
                ) => {
                    changeValue(state, field, () => value);
                    dispatch(actionUnloadHEH({ housingEstateId: value.value }));
                },
            }}
            validate={(values) => {
                const errors: any = {};
                if (!values.realtySubtype) {
                    errors.realtySubtype = 'Укажите тип недвижимости';
                }
                if (!values.realtyType) {
                    errors.realtyType = 'Необходимо выбрать';
                }
                if (!values.housingEstateId && values.realtyType == 'primary') {
                    errors.housingEstateId = 'Выберите ЖК';
                }
                return errors;
            }}
            render={({ handleSubmit, form, submitting, valid }) => (
                <form onSubmit={handleSubmit}>
                    <Navbar
                        className='justify-content-between mb-3'
                        bg='light'
                        expand='lg'
                        variant='light'
                    >
                        <ButtonGroup>
                            <Button
                                size='sm'
                                onClick={() => navigate(-1)}
                                disabled={submitting}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'fast-backward']}
                                />{' '}
                                Назад
                            </Button>
                            <Button
                                size='sm'
                                type='submit'
                                className='btn btn-sm btn-success'
                                disabled={
                                    submitting ||
                                    !valid ||
                                    isDouble ||
                                    holdSubmit
                                }
                            >
                                <FontAwesomeIcon icon={['fas', 'save']} />{' '}
                                Сохранить
                            </Button>
                        </ButtonGroup>
                    </Navbar>
                    <Container fluid>
                        <Form.Group>
                            {/* <ButtonGroup className='mb-2'>
                                <ToggleButtonField
                                    label='Продажа'
                                    type='radio'
                                    name='dealType'
                                    value='sale'
                                    size='sm'
                                    variantChecked='primary'
                                    variantUnChecked='outline-primary'
                                />
                                <ToggleButtonField
                                    label='Аренда'
                                    type='radio'
                                    name='dealType'
                                    value='rent'
                                    size='sm'
                                    variantChecked='primary'
                                    variantUnChecked='outline-primary'
                                />
                            </ButtonGroup> */}
                        </Form.Group>
                        <Form.Group>
                            <ButtonGroup className='mb-2'>
                                <ToggleButtonField
                                    type='radio'
                                    label='Вторичка'
                                    name='realtyType'
                                    value='secondary'
                                    size='sm'
                                    variantChecked='primary'
                                    variantUnChecked='outline-primary'
                                    onChange={(e) =>
                                        form.mutators.changeRealtySubType(
                                            'realtyType',
                                            e.currentTarget.value,
                                        )
                                    }
                                />
                                <ToggleButtonField
                                    type='radio'
                                    label='Новостройка'
                                    name='realtyType'
                                    value='primary'
                                    size='sm'
                                    variantChecked='primary'
                                    variantUnChecked='outline-primary'
                                    onChange={(e) =>
                                        form.mutators.changeRealtySubType(
                                            'realtyType',
                                            e.currentTarget.value,
                                        )
                                    }
                                />
                            </ButtonGroup>
                        </Form.Group>
                        <Error name='realtyType' />
                        <Form.Group>
                            <ButtonGroup className='mb-2'>
                                <ToggleButtonField
                                    type='radio'
                                    label='Квартира'
                                    name='realtySubtype'
                                    value='flat'
                                    size='sm'
                                    variantChecked='primary'
                                    variantUnChecked='outline-primary'
                                />
                                <ToggleButtonField
                                    type='radio'
                                    label='Апартаменты'
                                    name='realtySubtype'
                                    value='apartments'
                                    size='sm'
                                    variantChecked='primary'
                                    variantUnChecked='outline-primary'
                                />
                                <ToggleButtonField
                                    type='radio'
                                    label='Студия'
                                    name='realtySubtype'
                                    value='studio'
                                    size='sm'
                                    variantChecked='primary'
                                    variantUnChecked='outline-primary'
                                />
                                <Form.Group
                                    style={{
                                        display: `${form.getState().values.realtySubTypeDisabled}`,
                                    }}
                                >
                                    <ToggleButtonField
                                        type='radio'
                                        label='Пансионат'
                                        name='realtySubtype'
                                        value='pansionat'
                                        size='sm'
                                        variantChecked='primary'
                                        variantUnChecked='outline-primary'
                                    />
                                    <ToggleButtonField
                                        type='radio'
                                        label='Малосемейка'
                                        name='realtySubtype'
                                        value='malosemeyka'
                                        size='sm'
                                        variantChecked='primary'
                                        variantUnChecked='outline-primary'
                                    />
                                    <ToggleButtonField
                                        type='radio'
                                        label='Общежитие'
                                        name='realtySubtype'
                                        value='obshchezhitie'
                                        size='sm'
                                        variantChecked='primary'
                                        variantUnChecked='outline-primary'
                                    />
                                </Form.Group>
                            </ButtonGroup>
                        </Form.Group>
                        <Error name='realtySubtype' />
                        <Row>
                            <Col>
                                <Field name='housingEstateId'>
                                    {({ input }) => (
                                        <Form.Group className='mb-2'>
                                            <Form.Label>
                                                <>Жилой комплекс</>
                                            </Form.Label>
                                            <Select
                                                {...input}
                                                placeholder='Выберите ЖК'
                                                onChange={(e) =>
                                                    form.mutators.changeHousingEstate(
                                                        'housingEstateId',
                                                        e,
                                                    )
                                                }
                                                options={allHousingEstate}
                                            />
                                        </Form.Group>
                                    )}
                                </Field>
                                {form.getState().values.realtyType ==
                                    'primary' && (
                                        <Error name='housingEstateId' />
                                    )}
                                <DropDownSearchApi
                                    label='Адрес'
                                    name='address'
                                    validator={required}
                                    defaultValue={''}
                                    onSelect={(item) => {
                                        form.mutators.setAddressCheckValue(
                                            'addressPlaceDetailId',
                                            item?.placeId,
                                        );
                                        form.mutators.setValue(
                                            'address',
                                            item?.address,
                                        );
                                        form.mutators.setValue(
                                            'latitude',
                                            item?.latitude,
                                        );
                                        form.mutators.setValue(
                                            'longitude',
                                            item?.longitude,
                                        );
                                    }}
                                >
                                    <Field name='addressPlaceDetailId'>
                                        {({ meta }) => (
                                            <Form.Group>
                                                <Form.Control.Feedback
                                                    type='invalid'
                                                    style={{
                                                        display:
                                                            meta.error ||
                                                                meta.submitError ||
                                                                isDouble
                                                                ? 'block'
                                                                : 'none',
                                                    }}
                                                >
                                                    {meta.error ||
                                                        meta.submitError ||
                                                        (isDouble && (
                                                            <a
                                                                href={`/realty-secondary/card/${doubleId}`}
                                                                target='_blank'
                                                                rel='noreferrer'
                                                            >
                                                                Такой объект уже
                                                                есть в базе{' '}
                                                                {doubleId}
                                                            </a>
                                                        ))}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        )}
                                    </Field>
                                </DropDownSearchApi>
                                <Field name='flatNumber' validate={required}>
                                    {({ input, meta }) => (
                                        <Form.Group className='mb-2'>
                                            <Form.Label>
                                                Номер квартиры
                                            </Form.Label>
                                            <Form.Control
                                                {...input}
                                                type='text'
                                                size='sm'
                                                placeholder='Номер квартиры'
                                                onChange={(e) =>
                                                    form.mutators.setAddressCheckValue(
                                                        'flatNumber',
                                                        e.target.value,
                                                    )
                                                }
                                                isInvalid={
                                                    meta.error ||
                                                    meta.submitError
                                                }
                                            />
                                            <Form.Control.Feedback type='invalid'>
                                                {meta.error || meta.submitError}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>
                                <CheckboxField
                                    name='isExclusive'
                                    label='Эксклюзив'
                                />
                                <Field name='price' validate={priceSixDigit}>
                                    {({ input, meta }) => (
                                        <Form.Group className='mb-3'>
                                            <Form.Label>Цена</Form.Label>
                                            <Form.Control
                                                {...input}
                                                type='text'
                                                size='sm'
                                                placeholder='Цена'
                                                isInvalid={
                                                    meta.error || meta.submitError
                                                }
                                                onChange={(e) =>
                                                    form.mutators.setPriceValue(
                                                        'price',
                                                        e.target.value,
                                                    )
                                                }
                                            />
                                            <Form.Control.Feedback type='invalid'>
                                                {meta.error || meta.submitError}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>
                                <Row>
                                    <Col>
                                        <Field name='sellerName' validate={required}>
                                            {({ input, meta }) => (
                                                <Form.Group className='mb-3'>
                                                    <Form.Label>Продавец</Form.Label>
                                                    <Form.Control
                                                        {...input}
                                                        type='text'
                                                        size='sm'
                                                        isInvalid={
                                                            meta.error ||
                                                            meta.submitError
                                                        }
                                                        placeholder='Продавец'
                                                    />
                                                    <Form.Control.Feedback type='invalid'>
                                                        {meta.error || meta.submitError}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            )}
                                        </Field>
                                    </Col>
                                    <Col>
                                        <Field
                                            name='sellerPhone'
                                            validate={composeValidators(
                                                required,
                                                mustBeNumber,
                                                onlyLength(10),
                                            )}
                                        >
                                            {({ input, meta }) => (
                                                <Form.Group className='mb-3'>
                                                    <Form.Label>
                                                        Телефон продавца{' '}
                                                        <small className='text-muted'>
                                                            (10 цифр без 8)
                                                        </small>
                                                    </Form.Label>
                                                    <Form.Control
                                                        {...input}
                                                        type='number'
                                                        size='sm'
                                                        isInvalid={
                                                            meta.error ||
                                                            meta.submitError
                                                        }
                                                        placeholder='Телефон продавца'
                                                    />
                                                    <Form.Control.Feedback type='invalid'>
                                                        {meta.error || meta.submitError}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            )}
                                        </Field>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <FormSpy subscription={{ values: true }}>
                                    {({ values }) => (
                                        <MapUpdateView
                                            latitude={
                                                values.latitude || undefined
                                            }
                                            longitude={
                                                values.longitude || undefined
                                            }
                                            onClick={async (lat, lng) => {
                                                const res = await geocode(
                                                    lat,
                                                    lng,
                                                );

                                                if (
                                                    Array.isArray(res) &&
                                                    res.length > 0
                                                ) {
                                                    form.mutators.setAddressCheckValue(
                                                        'addressPlaceDetailId',
                                                        res[0].placeId,
                                                    );
                                                    form.mutators.setValue(
                                                        'address',
                                                        res[0].address,
                                                    );
                                                    form.mutators.setValue(
                                                        'latitude',
                                                        res[0].latitude,
                                                    );
                                                    form.mutators.setValue(
                                                        'longitude',
                                                        res[0].longitude,
                                                    );
                                                }
                                            }}
                                        />
                                    )}
                                </FormSpy>
                            </Col>
                        </Row>

                        <Field name='privateNote'>
                            {({ input, meta }) => (
                                <Form.Group className='mb-3'>
                                    <Form.Label>Заметка для себя</Form.Label>
                                    <Form.Control
                                        {...input}
                                        as='textarea'
                                        rows={4}
                                        size='sm'
                                        isInvalid={
                                            meta.error || meta.submitError
                                        }
                                        placeholder='Заметка для себя'
                                    />
                                    <Form.Control.Feedback type='invalid'>
                                        {meta.error || meta.submitError}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            )}
                        </Field>
                        {/* <Form.Group className='mb-3'>
                            <label className='btn btn-sm btn-primary'>
                                <FileField
                                    name='photosUpload'
                                    onChange={e => changeHandler(e, form)}
                                    multiple
                                /> Загрузить фото
                            </label>
                            {preview && preview.length > 0 && <div>{preview.map((image, index) => {
                                return <Image key={`imagePreview${index}`} src={image} width={160} height={90} thumbnail />;
                            })}</div>}
                        </Form.Group> */}
                        <Button
                            size='sm'
                            type='submit'
                            className='btn btn-sm btn-success'
                            disabled={
                                submitting || !valid || isDouble || holdSubmit
                            }
                        >
                            <FontAwesomeIcon icon={['fas', 'save']} /> Сохранить
                        </Button>
                    </Container>
                </form>
            )}
        />
    );
}
