import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '~/src/helpers/hooks';
import { all } from '~/src/store/realtySecondary/realty.secondary.moderate';
import {
    setActiveMenuKey,
    setHandleScrollPosition,
} from '~/src/store/appSlice';
import RList from '~/src/components/Realty/SecondaryModerate';

const RealtySecondary = (): JSX.Element => {
    const query = useQuery();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setActiveMenuKey('RSM'));
    }, [dispatch]);

    useEffect(() => {
        dispatch(setHandleScrollPosition(false));
    }, [dispatch]);

    useEffect(() => {
        dispatch(all(query));
    }, [dispatch, query]);

    return <RList />;
};

export default RealtySecondary;
