import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { ButtonGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IAddressPlaceDetail } from '~/src/service/address';

const ListRow: FC<{ item: IAddressPlaceDetail }> = ({ item }) => {
    return (
        <React.Fragment>
            <tr>
                <td>
                    <p>{item.id}</p>
                </td>
                <td>
                    <p>{item.fullAddress}</p>
                </td>
                <td>
                    <strong>Адрес</strong>
                    <br />
                    <span>{item.formattedAddress}</span>
                    <br />
                    <strong>Адрес кратко</strong>
                    <br />
                    <span>{item.shortAddress}</span>
                    <br />
                    <strong>Заблокирован для автообновления</strong>
                    <br />
                    <span>{item.isLocked ? 'Да' : 'Нет'}</span>
                </td>
                <td>
                    <ButtonGroup>
                        <Link
                            to={`/address/update/${item.id}`}
                            className='btn btn-sm btn-warning'
                            title='Редактировать'
                        >
                            <FontAwesomeIcon icon={['fas', 'pencil-alt']} />
                        </Link>
                    </ButtonGroup>
                </td>
            </tr>
        </React.Fragment>
    );
};

export default ListRow;
