import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import arrayMutators from 'final-form-arrays';
import { Button, Container, Form } from 'react-bootstrap';
import { Field, Form as FinalForm } from 'react-final-form';
import OverlayingPopup from '../ModalUI/OverlayingPopup';
import CheckboxField from '~/src/components/Common/Form/CheckboxField';

type TProps = {
    id: number;
    unverify: any;
};

const ButtonWithModerateReject = ({ id, unverify }: TProps): JSX.Element => {
    const [isOpened, setIsOpened] = useState(false);

    const dispatch = useDispatch();

    const handleSubmit = (values: Record<string, any>) => {
        const content = [];

        for (const key in values) {
            switch (key) {
                case 'badPhoto':
                    values[key] &&
                        content.push(
                            'Фото не соответствуют правилам размещения',
                        );
                    break;
                case 'badFields':
                    values[key] &&
                        content.push(
                            'Не правильно указаны основные характеристики',
                        );
                    break;
                case 'badDescription':
                    values[key] &&
                        content.push('В описании указаны некорректные фразы');
                    break;
                case 'fake':
                    values[key] && content.push('Фейковое объявление');
                    break;
                default:
                    content.push(values[key]);
            }
        }

        const joined = content.join(`
`);

        dispatch(unverify(id, { note: joined }));
        setIsOpened(false);
    };

    const close = () => setIsOpened(false);
    const open = () => setIsOpened(true);

    return (
        <React.Fragment>
            <Button
                size='sm'
                variant='warning'
                title='Отклонить'
                onClick={open}
            >
                Отклонить
            </Button>
            <OverlayingPopup onClose={close} isOpened={isOpened}>
                <div className='main_popup'>
                    <FinalForm
                        onSubmit={handleSubmit}
                        mutators={{
                            setValue: (
                                [field, value],
                                state,
                                { changeValue },
                            ) => {
                                changeValue(state, field, () => value);
                            },
                            ...arrayMutators,
                        }}
                        validate={(values) => {
                            const errors: any = {};

                            if (
                                !values['badPhoto'] &&
                                !values['badFields'] &&
                                !values['badDescription'] &&
                                !values['fake'] &&
                                !values['note']
                            ) {
                                errors['note'] =
                                    'Оставьте комментарий или укажите иную причину';
                            }

                            return errors;
                        }}
                        render={({ handleSubmit, submitting, valid }) => (
                            <form onSubmit={handleSubmit}>
                                <Container fluid>
                                    <CheckboxField
                                        name='badPhoto'
                                        label='Фото не соответствуют правилам размещения'
                                    />
                                    <CheckboxField
                                        name='badFields'
                                        label='Не правильно указаны основные характеристики'
                                    />
                                    <CheckboxField
                                        name='badDescription'
                                        label='В описании указаны некорректные фразы'
                                    />
                                    <CheckboxField
                                        name='fake'
                                        label='Фейковое объявление'
                                    />
                                    <Field name='note'>
                                        {({ input, meta }) => (
                                            <Form.Group className='mb-3'>
                                                <Form.Label>
                                                    Комментарий
                                                </Form.Label>
                                                <Form.Control
                                                    {...input}
                                                    as='textarea'
                                                    rows={5}
                                                    size='sm'
                                                    isInvalid={
                                                        meta.error ||
                                                        meta.submitError
                                                    }
                                                    placeholder='Комментарий'
                                                />
                                                <Form.Control.Feedback type='invalid'>
                                                    {meta.error ||
                                                        meta.submitError}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        )}
                                    </Field>
                                    <Button
                                        size='sm'
                                        type='submit'
                                        variant='warning'
                                        disabled={submitting || !valid}
                                    >
                                        Отклонить
                                    </Button>
                                </Container>
                            </form>
                        )}
                    />
                </div>
            </OverlayingPopup>
        </React.Fragment>
    );
};

export default ButtonWithModerateReject;
