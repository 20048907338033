import queryString from 'query-string';
import { createSlice, Slice } from '@reduxjs/toolkit';
import * as api from '~/src/service/agency.statistics';
import { IUserWithStatistics } from '~/src/service/user';
import { redirect, setLoading as setAppLoading } from './appSlice';
import type { TAppDispatch, TState, TAnyAction } from '~/src/store';
import moment from 'moment';

export const sliceName = 'agency.statistics';

interface ISlice {
    items: IUserWithStatistics[];
    search?: any;
    errors?: any;
    loading?: boolean;
    baseLink: string;
}

const initialState = {
    items: [],
    search: {
        dateFrom: moment().startOf('month').format('YYYY-MM-DD'),
        dateTo: moment().endOf('month').format('YYYY-MM-DD'),
    },
    loading: true,
    baseLink: `/agency/my/statistics?dateFrom=${moment().startOf('month').format('YYYY-MM-DD')}&dateTo=${moment().endOf('month').format('YYYY-MM-DD')}`,
};

const statisticsSlice: Slice<ISlice> = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        setAll: (state: ISlice, action: TAnyAction<any[]>) => {
            state.items = action.payload;
        },
        setErrors: (state: ISlice, action: TAnyAction<any>) => {
            state.errors = action.payload;
        },
        setSearch: (state: ISlice, action: TAnyAction<ISlice>) => {
            state.search = action.payload;
        },
    },
});

export const { setAll, setErrors, setSearch } = statisticsSlice.actions;

export const all = (queryParams: URLSearchParams = null) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(setAppLoading(true));
        const response = await api.all(queryParams);

        const obj = {};

        for (const key of queryParams.keys()) {
            if (queryParams.getAll(key).length > 1) {
                obj[key] = queryParams.getAll(key);
            } else {
                obj[key] = queryParams.get(key);
            }
        }

        dispatch(setSearch(obj));

        dispatch(setAll(response));
        dispatch(setAppLoading(false));
    };
};

export const goTo =
    (params: Record<string, any>) =>
    (dispatch: TAppDispatch, getState: () => TState): void => {
        if (params.reset === true) {
            params = initialState.search;
        } else params = { ...getState()[sliceName].search, ...params };

        dispatch(
            redirect(
                `/agency/my/statistics?${queryString.stringify(params, { skipNull: true })}`,
            ),
        );
    };

export const selectAgencyStatistics = (state: TState): ISlice =>
    state[sliceName];

export default statisticsSlice.reducer;
