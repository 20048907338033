import InputField from '../../Common/Form/InputField';
import ReactSelectField from '../../Common/Form/ReactSelectField';
import { Button, ButtonGroup, Col, Form, Row } from 'react-bootstrap';

import { suburbanGarageStatus } from '~/src/common/data/suburban.garage.status';
import CheckboxField from '../../Common/Form/CheckboxField';
import { useForm } from 'react-final-form';
import ToggleButtonField from '../../Common/Form/ToggleButtonWrapper';

const SearchFormHidden = ({ showForm }): JSX.Element => {

    const suburbanGaragePrepared = suburbanGarageStatus.map((field) => ({
        label: field.title,
        value: field.value,
    }));
    const form = useForm();

    if (showForm) {
        return (
            <>
                <Row>
                    <Col>
                        <InputField
                            name='priceMin'
                            label='Цена от'
                            onChange={(e) =>
                                form.mutators.setPriceValue('priceMin', e.target.value)
                            }
                        />
                    </Col>
                    <Col>
                        <InputField
                            name='priceMax'
                            label='Цена до'
                            onChange={(e) =>
                                form.mutators.setPriceValue('priceMax', e.target.value)
                            }
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputField
                            name='spaceTotalMin'
                            label='Площадь от'
                        />
                    </Col>
                    <Col>
                        <InputField
                            name='spaceTotalMax'
                            label='Площадь до'
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={2}>
                        <Form.Group className='mb-2'>
                            <div>
                                <Form.Label>Спален</Form.Label>
                            </div>
                            <ButtonGroup>
                                {[1, 2, 3, 4, 5].map((radio, idx) => (
                                    <ToggleButtonField
                                        key={`rooms-${idx}`}
                                        label={`${radio}`}
                                        name='rooms'
                                        size='sm'
                                        type='checkbox'
                                        value={radio}
                                        variantChecked='primary'
                                        variantUnChecked='outline-primary'
                                    />
                                ))}
                            </ButtonGroup>
                        </Form.Group>
                    </Col>
                    <Col xs={2}>
                        <Form.Group className='mb-2'>
                            <div>
                                <Form.Label>Сан узлов</Form.Label>
                            </div>
                            <ButtonGroup>
                                {[1, 2, 3].map((radio, idx) => (
                                    <ToggleButtonField
                                        key={`bathroomTotal-${idx}`}
                                        label={`${radio}`}
                                        name='bathroomTotal'
                                        size='sm'
                                        type='checkbox'
                                        value={radio}
                                        variantChecked='primary'
                                        variantUnChecked='outline-primary'
                                    />
                                ))}
                            </ButtonGroup>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col>
                        <CheckboxField name='terrace' label='Терраса' />
                        <CheckboxField name='hasGarage' label='Гараж' />
                    </Col>
                    <Col>
                        <CheckboxField name='isInstallment' label='Возможность строительства в рассрочку, без переплаты' />
                        <CheckboxField name='isDiscountFullPay' label='Строительство со скидкой при единовременной полной оплате' />
                        <CheckboxField name='isStateProgramm' label='Строительство по Государственным программам, сертификатам' />
                    </Col>
                    <Col>
                        <CheckboxField name='isExchange' label='Возможность строительства с обменом на свою недвижимость' />
                        <CheckboxField name='buildWoPv' label='Строительство без ПВ' />
                    </Col>
                </Row>
                <ButtonGroup className='mt-3 mb-3'>
                    <Button
                        type='submit'
                        size='sm'
                        variant='primary'
                    >
                        Найти
                    </Button>
                </ButtonGroup>
            </>
        );
    } else {
        return <></>;
    }
};

export { SearchFormHidden };
