import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectApp, setHandleScrollPosition } from '~/src/store/appSlice';
import { read } from '~/src/store/realtySuburban/realtySuburbanSlice';
import RSUBUpdateForm from '~/src/components/Realty/Suburban/Update/update';
import { selectDirection, unload } from '~/src/store/directionSlice';
import {
    selectCV,
    unloadCV,
} from '~/src/store/cottage.village/cottage.village.store';

const RSUBUpdatePage = (): JSX.Element => {
    const dispatch = useDispatch();
    const params = useParams<TParams>();

    const { loading } = useSelector(selectApp);
    const { unloadItems } = useSelector(selectDirection);
    const { unloadCVItems } = useSelector(selectCV);

    useEffect(() => {
        dispatch(read(+params.id));
    }, [params.id, dispatch]);

    useEffect(() => {
        if (unloadItems.length === 0) {
            dispatch(unload());
        }
    }, [dispatch, unloadItems]);

    useEffect(() => {
        if (unloadCVItems.length === 0) {
            dispatch(unloadCV());
        }
    }, [dispatch, unloadCVItems]);

    useEffect(() => {
        dispatch(setHandleScrollPosition(false));
    }, [dispatch]);

    return (
        <React.Fragment>
            {loading ? <div>Загрузка...</div> : <RSUBUpdateForm />}
        </React.Fragment>
    );
};

type TParams = {
    id: string;
};

export default RSUBUpdatePage;
