import { Field, Form as FinalForm } from 'react-final-form';
import DatePickerField from '../Common/Form/DatePickerField/DatePickerField';
import { required } from '~/src/helpers/validators';
import { useDispatch } from 'react-redux';
import { createMegasale } from '~/src/store/megasaleSlice';
import { Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputField from '../Common/Form/InputField';

const FormMegasaleStart = () => {
    const dispatch = useDispatch();

    const onSubmit = (values) => {
        dispatch(
            createMegasale({
                ...values,
                note: '',
            }),
        );
    };

    return (
        <FinalForm
            onSubmit={onSubmit}
            mutators={{
                setValue: ([field, value], state, { changeValue }) => {
                    changeValue(state, field, () => value);
                },
            }}
            validate={(values) => {
                const errors: any = {};
                if (
                    new Date(values.dateStart).setHours(0, 0, 0, 0) <
                    new Date().setHours(0, 0, 0, 0)
                ) {
                    errors.dateErrorStart =
                        'Дата начал должна быть не раньше сегоднешней';
                }
                if (
                    new Date(values.dateFinish).setHours(0, 0, 0, 0) <
                    new Date().setHours(0, 0, 0, 0)
                ) {
                    errors.dateError =
                        'Дата конца должна быть не раньше сегоднешней';
                }
                if (
                    new Date(values.dateFinish).setHours(0, 0, 0, 0) <
                    new Date(values.dateStart).setHours(0, 0, 0, 0)
                ) {
                    errors.dateError =
                        'Дата конца не может быть меньше даты начала';
                }
                return errors;
            }}
            render={({ handleSubmit, submitting, valid }) => (
                <form onSubmit={handleSubmit}>
                    <InputField
                        name='title'
                        label='Название'
                        validators={required}
                    />
                    <Form.Group>
                        <DatePickerField
                            name='dateStart'
                            label='Дата начала распродажи'
                            showTimeSelect
                            validators={required}
                        />
                        <Field name='dateErrorStart'>
                            {({ meta }) => (
                                <Form.Group>
                                    <Form.Control.Feedback
                                        type='invalid'
                                        style={{
                                            display:
                                                meta.error || meta.submitError
                                                    ? 'block'
                                                    : 'none',
                                        }}
                                    >
                                        {meta.error || meta.submitError}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            )}
                        </Field>
                    </Form.Group>
                    <Form.Group>
                        <DatePickerField
                            name='dateFinish'
                            label='Дата конца распродажи'
                            showTimeSelect
                            validators={required}
                        />
                        <Field name='dateError'>
                            {({ meta }) => (
                                <Form.Group>
                                    <Form.Control.Feedback
                                        type='invalid'
                                        style={{
                                            display:
                                                meta.error || meta.submitError
                                                    ? 'block'
                                                    : 'none',
                                        }}
                                    >
                                        {meta.error || meta.submitError}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            )}
                        </Field>
                    </Form.Group>
                    <Button
                        type='submit'
                        className='btn btn-sm btn-success mt-1'
                        disabled={submitting || !valid}
                    >
                        <FontAwesomeIcon icon={['fas', 'play']} /> Запустить
                        распродажу{' '}
                    </Button>
                </form>
            )}
        />
    );
};

export default FormMegasaleStart;
