import { IResponseListData, IUpdateData } from '../interfaces/common';
import Api from '~/src/helpers/http-common';
import { IAgency } from './agency';

export const all = async (
    queryParams?: URLSearchParams,
): Promise<IResponseListData<IXmlFeed>> => {
    return await Api.get(
        `/xml-feed?${queryParams != null ? queryParams.toString() : ''}`,
    );
};

export const myagency = async (
    queryParams?: URLSearchParams,
): Promise<IResponseListData<IXmlFeed>> => {
    return await Api.get(
        `/agency/my/xml-feed?${queryParams != null ? queryParams.toString() : ''}`,
    );
};

export const create = async (
    data: IXmlFeed,
    files?: Record<string, FileList>,
): Promise<IXmlFeed | any> => {
    return await Api.post<IXmlFeed>('/xml-feed', data, files);
};

export const read = async (id: number): Promise<IXmlFeed> => {
    return await Api.get(`/xml-feed/${id}`);
};

export const update = async (
    id: string | number,
    data: IXmlFeed,
    files?: Record<string, FileList>,
): Promise<IUpdateData<IXmlFeed>> => {
    return await Api.put<IUpdateData<IXmlFeed>>(`/xml-feed/${id}`, data, files);
};

export const remove = async (id: string | number): Promise<any> => {
    return await Api.delete(`/xml-feed/${id}`);
};

export const clear = async (
    id: string | number,
): Promise<IUpdateData<IXmlFeed>> => {
    return await Api.post<IUpdateData<IXmlFeed>>(`/xml-feed/${id}/clear`);
};

export const build = async (
    id: string | number,
): Promise<{ success: boolean }> => {
    return await Api.post(`/xml-feed/${id}/build`);
};

export interface IXmlFeed {
    id: number;
    agencyId: number;
    title: string;
    type: string;
    addAll: boolean;
    filename: string;

    createdAt: Date;
    createdBy: string;
    updatedAt: Date;
    updatedBy: string;

    agency?: IAgency;
    totalUnload?: number;
}

export interface IXmlFeedRealty {
    id: number;
    xmlFeedId: number;
    realtyId: number;
    enabled: boolean;
    promotion?: string[];
    unloadStartAt: Date;
    unloadEndAt: Date;
    feed?: IXmlFeed;
}
