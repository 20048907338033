import React, { FC } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import { IAgencySetting } from '~/src/service/agency.settings';
import createInitialFormValues from '~/src/helpers/createInitialFormValues';
import CheckboxField from '../../Common/Form/CheckboxField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form } from 'react-bootstrap';
import InputField from '../../Common/Form/InputField';

const IList: FC<{ item: IAgencySetting }> = ({ item }) => {
    return (
        <React.Fragment>
            <FinalForm
                onSubmit={(values) => console.dir(values)}
                initialValues={{
                    ...createInitialFormValues(item),
                }}
                mutators={{
                    setValue: ([field, value], state, { changeValue }) => {
                        changeValue(state, field, () => value);
                    },
                }}
                validate={(values) => {
                    const errors: any = {};
                    return errors;
                }}
                render={({ handleSubmit }) => (
                    <React.Fragment>
                        <form
                            onSubmit={handleSubmit}
                            id={`setting-${item.code}`}
                        ></form>
                        <tr>
                            <td>{item.title}</td>
                            <td>
                                <Field
                                    name='value'
                                    allowNull
                                    parse={(value) =>
                                        value === '' ? null : value
                                    }
                                >
                                    {({ input, meta }) => (
                                        <Form.Control
                                            {...input}
                                            type='text'
                                            size='sm'
                                            form={`setting-${item.code}`}
                                        />
                                    )}
                                </Field>
                            </td>
                            <td>
                                <Field
                                    name='enabled'
                                    type='checkbox'
                                    format={(v) => v == 1}
                                    parse={(v) => (v ? 1 : 0)}
                                >
                                    {({ input }) => (
                                        <Form.Group className='mb-3'>
                                            <Form.Check type='switch'>
                                                <Form.Check.Input
                                                    {...input}
                                                    type='checkbox'
                                                    checked={input.checked}
                                                    form={`setting-${item.code}`}
                                                />
                                            </Form.Check>
                                        </Form.Group>
                                    )}
                                </Field>
                            </td>
                            <td>
                                <Button
                                    type='submit'
                                    size='sm'
                                    form={`setting-${item.code}`}
                                >
                                    <FontAwesomeIcon icon={['fas', 'save']} />
                                </Button>
                            </td>
                        </tr>
                    </React.Fragment>
                )}
            />
        </React.Fragment>
    );
};

export default IList;
