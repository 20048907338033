import { IUser } from './user';
import { IAgencyGroup } from './agency';
import Api from '~/src/helpers/http-common';
import { IUserPermissionUnloaded } from './permission';
import { IResponseListData, IUpdateData } from '../interfaces/common';

export const all = async (
    id: string,
    queryParams?: URLSearchParams,
): Promise<IResponseListData<IAgencyAgent>> => {
    return await Api.get(
        `/agency/${id}/agent?${queryParams != null ? queryParams.toString() : ''}`,
    );
};

export const create = async (
    data: IAgencyAgent,
    files?: Record<string, FileList>,
): Promise<IAgencyAgent | any> => {
    return (await Api.post('/agency-agent', data, files)) as IAgencyAgent;
};

export const read = async (id: number): Promise<IAgencyAgent> => {
    return await Api.get(`/agency-agent/${id}`);
};

export const update = async (
    id: string | number,
    data: IAgencyAgent,
): Promise<IUpdateData<IAgencyAgent>> => {
    return await Api.put<IUpdateData<IAgencyAgent>>(
        `/agency-agent/${id}`,
        data,
    );
};

export const remove = async (id: string | number): Promise<any> => {
    return await Api.delete(`/agency-agent/${id}`);
};

export const fire = async (
    id: string | number,
): Promise<{ success: boolean }> => {
    return await Api.post(`/agency-agent/${id}/fire`);
};

export const getUnloadedUserPermissions = async (
    id: string | number,
): Promise<IUserPermissionUnloaded[]> => {
    return await Api.get(`/agency-agent/${id}/permission/unload`);
};

export interface IAgencyAgent {
    id?: number;
    agencyId?: number;
    agentUid?: string;
    position?: string;

    isVerified?: boolean;

    isInvited: boolean;
    invitedAt: Date;
    doNotDisturb?: boolean;
    userInfo?: IUser;

    group?: IAgencyGroup;
}
