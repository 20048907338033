import { redirect, setLoading as setAppLoading } from './appSlice';
import queryString from 'query-string';
import * as api from '~/src/service/user';
import { IUser } from '~/src/service/user';
import { createSlice, Slice } from '@reduxjs/toolkit';
import { IResponseListData } from '../interfaces/common';
import type { TAppDispatch, TState, TAnyAction } from '~/src/store';

export const sliceName = 'users';

interface IUsersSlice {
    items: IResponseListData<IUser>;
    item?: IUser;
    search?: any;
    errors?: any;
    navLink: string;
    loading: boolean;
    unloadedPermissions: any[];
}

const initialState = {
    items: {},
    search: {
        sort: 'id_desc',
        limit: 25,
        page: 1,
    },
    loading: false,
    unloadedPermissions: [],
    navLink: '/users?sort=id_desc&limit=25&page=1',
    baseLink: '/users',
};

const usersSlice: Slice<IUsersSlice> = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        setAll: (
            state: IUsersSlice,
            action: TAnyAction<IResponseListData<IUser>>,
        ) => {
            state.items = action.payload;
        },
        set: (state: IUsersSlice, action: TAnyAction<IUser>) => {
            state.item = action.payload;
        },
        setErrors: (state: IUsersSlice, action: TAnyAction<any>) => {
            state.errors = action.payload;
        },
        setSearch: (state: IUsersSlice, action: TAnyAction<any>) => {
            state.search = action.payload;
        },
        setNavLink: (state: IUsersSlice, action: TAnyAction<string>) => {
            state.navLink = action.payload;
        },
        setLoading: (state: IUsersSlice, action: TAnyAction<boolean>) => {
            state.loading = action.payload;
        },
        setUnloadedPermissions: (
            state: IUsersSlice,
            action: TAnyAction<any>,
        ) => {
            state.unloadedPermissions = action.payload;
        },
    },
});

const {
    setAll,
    set,
    setErrors,
    setSearch,
    setNavLink,
    setUnloadedPermissions,
} = usersSlice.actions;

export const getUsers = (queryParams: URLSearchParams = null) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(setAppLoading(true));
        const users = await api.getUserList(queryParams);
        const obj = {};

        for (const key of queryParams.keys()) {
            if (queryParams.getAll(key).length > 1) {
                obj[key] = queryParams.getAll(key);
            } else {
                obj[key] = queryParams.get(key);
            }
        }

        dispatch(setSearch(obj));
        dispatch(setNavLink(`/users?${queryParams.toString()}`));
        dispatch(setAll(users));
        dispatch(setAppLoading(false));
    };
};

export const getUser = (id: number) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const user = await api.getUser(id);
            dispatch(set(user));
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const createUser = (data: IUser, files?: any) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const item = await api.create(data, files);
            if (item && item.errors) {
                dispatch(setErrors(item.errors));
            } else if (item) {
                dispatch(redirect(getState().users.navLink));
                dispatch(redirect(`/users/edit/${item.id}`));
            }
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const updateUser = (id: number, user: IUser) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const response = await api.updateUser(id, user);
            if (response.success) {
                dispatch(set(response.data));
            } else {
                dispatch(setErrors(response.errors));
            }
        } catch (e) {
            console.error(e);
            dispatch(setErrors(e));
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const remove = (id: number) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const response = await api.removeUser(id);
            if (response && response.success) {
                dispatch(
                    setAll({
                        ...getState().users.items,
                        items: getState().users.items.items.filter(
                            (item: IUser) => item.id != id,
                        ),
                    }),
                );
            } else {
                dispatch(setErrors(response.errors));
            }
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const getUnloadedUserPermissions = (id: string | number) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const response = await api.getUnloadedUserPermissions(id);
            dispatch(setUnloadedPermissions(response));
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const goTo =
    (params: Record<string, any>) =>
    (dispatch: TAppDispatch, getState: () => TState): void => {
        if (params.reset === true) {
            params = initialState.search;
        } else params = { ...getState().users.search, ...params };

        dispatch(
            redirect(
                `/users?${queryString.stringify(params, { skipNull: true })}`,
            ),
        );
    };

export const selectUsers = (state: TState): IUsersSlice => state.users;

export default usersSlice.reducer;
