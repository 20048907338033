import queryString from 'query-string';
import * as api from '~/src/service/agency';
import { createSlice, Slice } from '@reduxjs/toolkit';
import { IResponseListData } from '../interfaces/common';
import { IAgency, IAgencyGroup } from '~/src/service/agency';
import { redirect, setLoading as setAppLoading } from './appSlice';
import type { TAppDispatch, TState, TAnyAction } from '~/src/store';
import { IXmlFeed } from '../service/xml.feed';
import { IFilestore } from '../service/filestore';

export const sliceName = 'agency';

interface IAgencySlice {
    items: IResponseListData<IAgency>;
    item?: IAgency;
    logo?: IFilestore;
    search?: any;
    errors?: any;
    navLink: string;
    loading: boolean;
    logoLoading: boolean;
    groups?: IAgencyGroup[];
    xmlFeeds?: IXmlFeed[];
}

const initialState = {
    items: {},
    item: null,
    logo: null,
    search: {
        sort: 'id_desc',
        limit: 25,
        page: 1,
    },
    loading: true,
    logoLoading: false,
    navLink: '/agency?sort=id_desc&limit=25&page=1',
    baseLink: '/agency',
    groups: null,
    xmlFeeds: null,
};

const agencySlice: Slice<IAgencySlice> = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        setAll: (
            state: IAgencySlice,
            action: TAnyAction<IResponseListData<IAgency>>,
        ) => {
            state.items = action.payload;
        },
        set: (state: IAgencySlice, action: TAnyAction<IAgency>) => {
            state.item = action.payload;
        },
        setErrors: (state: IAgencySlice, action: TAnyAction<any>) => {
            state.errors = action.payload;
        },
        setSearch: (state: IAgencySlice, action: TAnyAction<any>) => {
            state.search = action.payload;
        },
        setNavLink: (state: IAgencySlice, action: TAnyAction<string>) => {
            state.navLink = action.payload;
        },
        setLoading: (state: IAgencySlice, action: TAnyAction<boolean>) => {
            state.loading = action.payload;
        },
        setGroups: (
            state: IAgencySlice,
            action: TAnyAction<IAgencyGroup[]>,
        ) => {
            state.groups = action.payload;
        },
        setXmlFeeds: (state: IAgencySlice, action: TAnyAction<IXmlFeed[]>) => {
            state.xmlFeeds = action.payload;
        },
        setLogoLoading: (state: IAgencySlice, action: TAnyAction<boolean>) => {
            state.logoLoading = action.payload;
        },
        setLogo: (state: IAgencySlice, action: TAnyAction<IFilestore>) => {
            state.logo = action.payload;
        },
    },
});

const {
    setAll,
    set,
    setErrors,
    setSearch,
    setNavLink,
    setGroups,
    setXmlFeeds,
    setLogoLoading,
    setLogo,
} = agencySlice.actions;

export const all = (queryParams: URLSearchParams = null) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(setAppLoading(true));
        const agency = await api.all(queryParams);
        const obj = {};

        for (const key of queryParams.keys()) {
            if (queryParams.getAll(key).length > 1) {
                obj[key] = queryParams.getAll(key);
            } else {
                obj[key] = queryParams.get(key);
            }
        }

        dispatch(setSearch(obj));
        dispatch(setNavLink(`/agency?${queryParams.toString()}`));
        dispatch(setAll(agency));
        dispatch(setAppLoading(false));
    };
};

export const create = (data: IAgency, files?: any) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const item = await api.create(data, files);
            if (item && item.errors) {
                dispatch(setErrors(item.errors));
            } else if (item) {
                dispatch(redirect(getState().agency.navLink));
            }
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const read = (id: number) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const data = await api.read(id);
            dispatch(set(data));
            dispatch(setLogo(data.logo));
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const update = (id: number, data: IAgency) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const response = await api.update(id, data);
            if (response && response.success) {
                dispatch(set(response.data));
            } else {
                dispatch(setErrors(response.errors));
            }
        } catch (e) {
            console.error(e);
            dispatch(setErrors(e));
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const remove = (id: number) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const response = await api.remove(id);
            if (response && response.success) {
                dispatch(
                    setAll({
                        ...getState().agency.items,
                        items: getState().agency.items.items.filter(
                            (item: IAgency) => item.id != id,
                        ),
                    }),
                );
            } else {
                dispatch(setErrors(response.errors));
            }
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const myUploadLogo = (files?: any) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setLogoLoading(true));
            const res = await api.myUploadLogo(files);
            if (res && res.name) {
                dispatch(setLogo(res));
            }
        } finally {
            dispatch(setLogoLoading(false));
        }
    };
};

export const unloadGroups = (agencyId: string) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const data = await api.unloadGroups(agencyId);
            dispatch(setGroups(data));
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const unloadXmlFeeds = (agencyId: number) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const data = await api.unloadXmlFeeds(agencyId);
            dispatch(setXmlFeeds(data));
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const createGroup = (agencyId: string, title: string) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        try {
            // dispatch(setAppLoading(true));
            const item = await api.createGroup(agencyId, title);
            dispatch(setGroups([...getState().agency.groups, item]));
        } finally {
            // finally
        }
    };
};

export const goTo =
    (params: Record<string, any>) =>
    (dispatch: TAppDispatch, getState: () => TState): void => {
        if (params.reset === true) {
            params = initialState.search;
        } else params = { ...getState().agency.search, ...params };

        dispatch(
            redirect(
                `/agency?${queryString.stringify(params, { skipNull: true })}`,
            ),
        );
    };

export const selectAgency = (state: TState): IAgencySlice => state.agency;

export default agencySlice.reducer;
