import { IResponseListData, IUpdateData } from '../../interfaces/common';
import { IAddressPlaceDetail } from '../address';
import Api from '~/src/helpers/http-common';
import { IFilestore } from '../filestore';
import { IDeveloper } from '../developer/developer';
import { AxiosRequestConfig } from 'axios';
import { ICottageVillageConstructionPhase } from './cottage.village.construction.phase.service';
import { IUser } from '../user';

export const all = async (
    queryParams?: URLSearchParams,
): Promise<IResponseListData<ICottageVillage>> => {
    return await Api.get(
        `/cottage-village?${queryParams != null ? queryParams.toString() : ''}`,
    );
};

export const unload = async (): Promise<any> => {
    return await Api.get('/cottage-village/unload');
};

export const create = async (
    data: ICottageVillage,
    files?: Record<string, FileList>,
): Promise<ICottageVillage | any> => {
    return await Api.post<ICottageVillage>('/cottage-village', data, files);
};

export const read = async (id: number): Promise<ICottageVillage> => {
    return await Api.get(`/cottage-village/${id}`);
};

export const update = async (
    id: string | number,
    data: ICottageVillage,
    files?: Record<string, FileList>,
): Promise<IUpdateData<ICottageVillage>> => {
    return await Api.put<IUpdateData<ICottageVillage>>(
        `/cottage-village/${id}`,
        data,
        files,
    );
};

export const remove = async (id: string | number): Promise<any> => {
    return await Api.delete(`/cottage-village/${id}`);
};

export const generatePhase = async (id: number, data: Record<string, any>) => {
    return await Api.post(
        `/cottage-village/construction-phase/generate/${id}`,
        data,
    );
};

export const changeResponsible = async (
    data: Record<string, any>,
): Promise<{ success: boolean }> => {
    return await Api.post('/cottage-village/change-responsible', data);
};

export const uploadPhotos = async (
    id: number,
    files: FileList | File[],
    config?: AxiosRequestConfig,
): Promise<IFilestore[]> => {
    console.dir(files);
    return await Api.post(
        `/cottage-village/upload-photo/${id}`,
        null,
        { files },
        config,
    );
};

export const deleteFile = async (
    id: string | number,
    name: string,
): Promise<IFilestore | any> => {
    return await Api.delete<IFilestore>(`/cottage-village/${id}/files/${name}`);
};

export interface ICottageVillage {
    id: number;
    isEnabled: boolean;
    isPublic: boolean;
    title: string;
    userUid?: string;
    developerId: number;
    addressPlaceDetailId: number;
    latitude: number;
    longitude: number;
    youtube?: string;
    presentationLink?: string;
    popularity?: number;
    description?: string;
    mortgage?: number;
    hasCctv: boolean;
    isClosedArea: boolean;
    isProtectedArea: boolean;
    hasPlayGround: boolean;
    hasSportGround: boolean;
    hasPicnicGround: boolean;
    hasBesideWater: boolean;
    hasBesideMall: boolean;
    hasBesideSchool: boolean;
    hasBesidePreSchool: boolean;
    freshAt: Date;
    freshBy: string;
    createdAt: Date;
    createdBy: string;
    updatedAt: Date;
    updatedBy: string;

    plotsCount: TPlotsCount;

    developer: IDeveloper;
    addressDetail: IAddressPlaceDetail;

    photos?: IFilestore[];
    phases: ICottageVillageConstructionPhase[];
    // plots: CottageVillagePlotModel[];
    owner?: Partial<IUser>;

    subtitle?: string;
    isComfortAndClosed?: boolean;
    uniformStyle?: boolean;
    besideForestAndWater?: boolean;
    interestFreeInstallments?: boolean;
    withRenovationAndFurniture?: boolean;
    isLastOffer?: boolean;
    idealForInvestment?: boolean;
    hypothecBenefit?: boolean;
    installmentPlan?: boolean;
    isDiscount?: boolean;
    isStateProgramm?: boolean;
    isTradeIn?: boolean;
    landSaleAreaFrom?: number;
    landSaleAreaTo?: number;
    landSalePriceFrom?: number;
    landSalePriceTo?: number;
    landSaleDiscount?: number;
    commissionReward?: number;
    initialFee?: number;
}

export type TPlotsCount = {
    all: number;
    land: number;
    withHouse: number;
    withConstructionContruct: number;
};
