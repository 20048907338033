import queryString from 'query-string';
import * as api from '~/src/service/developer/developer';
import { IDeveloper } from '../../service/developer/developer';
import { createSlice, Slice } from '@reduxjs/toolkit';
import { IResponseListData } from '../../interfaces/common';
import { redirect, setLoading as setAppLoading } from '../appSlice';
import type { TAppDispatch, TState, TAnyAction } from '~/src/store';

export const sliceName = 'developer';

interface IDeveloperSlice {
    items: IResponseListData<IDeveloper>;
    item?: IDeveloper;
    search?: any;
    errors?: any;
    unloaded?: { id: number; title: string }[];
    unloadedSuburban?: { id: number; title: string }[];
    baseLink: string;
}

const initialState = {
    items: {},
    search: {
        sort: 'id_desc',
        limit: 25,
        page: 1,
    },
    unloaded: null,
    unloadedSuburban: null,
    loading: true,
    baseLink: '/developer',
};

const developerSlice: Slice<IDeveloperSlice> = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        setAll: (
            state: IDeveloperSlice,
            action: TAnyAction<IResponseListData<IDeveloper>>,
        ) => {
            state.items = action.payload;
        },
        set: (state: IDeveloperSlice, action: TAnyAction<IDeveloper>) => {
            state.item = action.payload;
        },
        setErrors: (state: IDeveloperSlice, action: TAnyAction<any>) => {
            state.errors = action.payload;
        },
        setSearch: (state: IDeveloperSlice, action: TAnyAction<any>) => {
            state.search = action.payload;
        },
        setUnload: (state: IDeveloperSlice, action: TAnyAction<any>) => {
            state.unloaded = action.payload;
        },
        setUnloadSuburban: (
            state: IDeveloperSlice,
            action: TAnyAction<any>,
        ) => {
            state.unloadedSuburban = action.payload;
        },
    },
});

export const {
    setAll,
    set,
    setErrors,
    setSearch,
    setUnload,
    setUnloadSuburban,
} = developerSlice.actions;

export const unload = () => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(setAppLoading(true));
        const response = await api.unload();
        dispatch(setUnload(response));
        dispatch(setAppLoading(false));
    };
};

export const unloadSuburban = () => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(setAppLoading(true));
        const query = new URLSearchParams({ isSuburban: '1' });
        const response = await api.unload(query);
        dispatch(setUnloadSuburban(response));
        dispatch(setAppLoading(false));
    };
};

export const all = (queryParams: URLSearchParams = null) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(setAppLoading(true));
        const response = await api.all(queryParams);
        const obj = {};

        for (const key of queryParams.keys()) {
            if (queryParams.getAll(key).length > 1) {
                obj[key] = queryParams.getAll(key);
            } else {
                obj[key] = queryParams.get(key);
            }
        }

        dispatch(setSearch(obj));
        dispatch(setAll(response));
        dispatch(setAppLoading(false));
    };
};

export const create = (data: any, files?: any) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const item = await api.create(data, files);
            if (item && item.errors) {
                dispatch(setErrors(item.errors));
            } else if (item) {
                dispatch(
                    setUnload(
                        getState().developer.unloaded?.concat([
                            { id: item.id, title: item.title },
                        ]),
                    ),
                );
                dispatch(redirect(getState().developer.baseLink));
            }
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const read = (id: number) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const data = await api.read(id);
            dispatch(set(data));
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const update = (
    id: number,
    data: any,
    files?: Record<string, FileList>,
) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const response = await api.update(id, data, files);
            if (response && response.success) {
                dispatch(set(response.data));
                dispatch(
                    setUnload([
                        ...getState().developer.unloaded.map((item) => {
                            if (item.id == id) {
                                return {
                                    ...item,
                                    title: response.data.title,
                                };
                            }

                            return item;
                        }),
                    ]),
                );
            } else {
                dispatch(setErrors(response.errors));
            }
        } catch (e) {
            console.error(e);
            dispatch(setErrors(e));
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const remove = (id: number) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const response = await api.remove(id);
            if (response && response.success) {
                dispatch(
                    setAll({
                        ...getState().developer.items,
                        items: getState().developer.items.items.filter(
                            (item: IDeveloper) => item.id != id,
                        ),
                    }),
                );
                dispatch(
                    setUnload([
                        ...getState().developer.unloaded.filter(
                            (item) => item.id != id,
                        ),
                    ]),
                );
            } else {
                dispatch(setErrors(response.errors));
            }
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

/* export const deleteFile = (id: number, name: string) => {
    return async (dispatch: TAppDispatch, getState: () => TState): Promise<void> => {
        const response = await api.deleteFile(id, name);
        if (response) {
            dispatch(set({
                ...getState().developer.item,
                photos: getState().developer.item.photos.filter((photo: IFilestore) => photo.name !== name)
            }));
        } else {
            dispatch(setErrors(response.errors));
        }
    };
}; */

export const goTo =
    (params: Record<string, any>) =>
    (dispatch: TAppDispatch, getState: () => TState): void => {
        if (params.reset === true) {
            params = initialState.search;
        } else params = { ...getState()[sliceName].search, ...params };

        dispatch(
            redirect(
                `${initialState.baseLink}?${queryString.stringify(params, { skipNull: true })}`,
            ),
        );
    };

export const selectDeveloper = (state: TState): IDeveloperSlice =>
    state.developer;

export default developerSlice.reducer;
