import IList from '../agentsRow';
import InviteAgent from './InviteAgent';
import { useParams } from 'react-router-dom';
import { useQuery } from '~/src/helpers/hooks';
import { selectAuth } from '~/src/store/authSlice';
import InviteAgentByExcel from './InviteAgentByExcel';
import { useDispatch, useSelector } from 'react-redux';
import React, { BaseSyntheticEvent, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    all,
    selectAgencyAgents,
    filterList,
} from '~/src/store/agencyAgentsSlice';
import {
    Button,
    ButtonGroup,
    Form,
    InputGroup,
    Navbar,
    Table,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

const AgentsView = (): JSX.Element => {
    const query = useQuery();
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();

    const { me } = useSelector(selectAuth);
    const { filteredItems } = useSelector(selectAgencyAgents);

    const [value, setValue] = useState('');

    const filterItems = (e: BaseSyntheticEvent) => {
        setValue(e.target.value);
        dispatch(filterList(e.target.value));
    };

    return (
        <React.Fragment>
            <Navbar
                className='justify-content-between'
                bg='light'
                expand='lg'
                variant='light'
            >
                <ButtonGroup>
                    {me &&
                        (me.agencyInfo.isDirector ||
                            me.agencyInfo.permissions.includes(
                                'agency.agent.invite',
                            )) && (
                            <React.Fragment>
                                <InviteAgent />
                                <InviteAgentByExcel />
                            </React.Fragment>
                        )}
                    <Button
                        size='sm'
                        variant='secondary'
                        onClick={() => dispatch(all(id, query))}
                    >
                        <FontAwesomeIcon icon={['fas', 'sync']} /> Обновить
                    </Button>
                    <Link
                        className='btn btn-sm btn-primary'
                        title='Группы'
                        to={'/agency/my/group'}
                    >
                        Группы
                    </Link>
                </ButtonGroup>
                <ButtonGroup>
                    <>
                        <Form.Label>&nbsp;</Form.Label>
                        <InputGroup size='sm'>
                            <Form.Control
                                value={value}
                                onChange={filterItems}
                                autoComplete='off'
                            />
                        </InputGroup>
                    </>
                </ButtonGroup>
            </Navbar>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <td>Фото</td>
                        <td>ФИО</td>
                        <td>Контакты</td>
                        <td>Данные</td>
                        <td>Управление</td>
                    </tr>
                </thead>
                <tbody>
                    {filteredItems && filteredItems.length > 0
                        ? filteredItems.map((item) => (
                            <IList key={item.id} item={item} />
                        ))
                        : null}
                </tbody>
            </Table>
        </React.Fragment>
    );
};

export default AgentsView;
