import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HaveAccess from '../../Common/HaveAccess';
import ButtonWithConfirm from '../../Common/ModalUI/ButtonWithConfirm';
import FlatChess from './FlatChess';
import {
    IHousingEstateHousePorchStorey,
    remove,
} from '~/src/service/housing.estate.house.porch.storey';
import { useNavigate } from 'react-router-dom';

const StoreyChess = ({
    id,
    number,
    flats,
    ceiling,
    floorsTotal,
}: IHousingEstateHousePorchStorey): JSX.Element => {
    const navigate = useNavigate();

    const deleteStorey = async () => {
        console.log(`delete storey with number ${number} and id ${id}`);
        const res = await remove(id);
        if (res.success) {
            navigate(0);
        }
    };

    return (
        <tr>
            <th
                style={{
                    backgroundColor: 'white',
                    zIndex: 999,
                    position: 'sticky',
                    left: 0,
                }}
            >
                <p>Этаж #{number}</p>
                <p>Высота потолков {ceiling}m</p>
                <HaveAccess access='housing.estate.storey.delete'>
                    <ButtonWithConfirm
                        label=''
                        onClick={deleteStorey}
                        modalText='Вместе с этажем удаляются квартиры. Удалить?'
                        variant='danger'
                    >
                        <FontAwesomeIcon icon={['fas', 'trash']} />
                    </ButtonWithConfirm>
                </HaveAccess>
            </th>
            {flats?.map((flat, index) => {
                if (flat?.id) {
                    return (
                        <td key={index}>
                            <FlatChess flat={flat} floorsTotal={floorsTotal} />
                        </td>
                    );
                }

                return <td key={index}></td>;
            })}
        </tr>
    );
};

export default StoreyChess;
