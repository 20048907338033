import { Form } from 'react-bootstrap';
import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { switchUserPermission } from '~/src/service/user';
import { IUserPermissionUnloaded } from '~/src/service/permission';

const ListRow: FC<{ item: IUserPermissionUnloaded }> = ({ item }) => {
    const { id: userId } = useParams<{ id: string }>();
    const [isEnabled, setEnabled] = useState(item?.isEnabled);

    const handleClick = async () => {
        const res = await switchUserPermission(userId, item.id);

        if (res && res.success) {
            setEnabled(!isEnabled);
        }
    };

    return (
        <React.Fragment>
            <tr>
                <td>
                    <p>{item.id}</p>
                </td>
                <td>
                    <p>{item.title}</p>
                </td>
                <td>{item.code}</td>
                <td>{item.group}</td>
                <td>
                    {item.isEnabled ? 'enabled' : 'disabled'}
                    <Form.Group className='mb-3'>
                        <Form.Check type='switch'>
                            <Form.Check.Input
                                type='checkbox'
                                checked={!!isEnabled}
                                onClick={handleClick}
                            />
                        </Form.Check>
                    </Form.Group>
                </td>
            </tr>
        </React.Fragment>
    );
};

export default ListRow;
