import React, { Dispatch, SetStateAction, useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import OverlayingPopup from '../Common/ModalUI/OverlayingPopup';
import InputField from '../Common/Form/InputField';
import {
    addRealtyMegasale,
    removeRealtyMegasale,
    selectMegaSale,
} from '~/src/store/megasaleSlice';
import { required } from '~/src/helpers/validators';
import { IHousingEstateHousePorchStoreyFlat } from '~/src/service/housing.estate.house.porch.storey.flat';
import {
    IRSModel,
    addRealtyMegasaleInfo,
    removeRealtyMegasaleInfo,
} from '~/src/store/realtySecondary/realtySecondarySlice';
import CheckboxField from '../Common/Form/CheckboxField';

type TProps = {
    item: IHousingEstateHousePorchStoreyFlat | IRSModel;
    type: string;
    setFlatItem?: Dispatch<SetStateAction<IHousingEstateHousePorchStoreyFlat>>;
};

const ButtonWithAddMegaSale = ({
    item,
    type,
    setFlatItem,
}: TProps): JSX.Element => {
    const { megasaleActive, items, addMegasaleRealty } =
        useSelector(selectMegaSale);
    const [isOpened, setIsOpened] = useState(false);
    const dispatch = useDispatch();

    const addRealtyPrimaryMegasaleInfo = (values, megasle) => {
        setFlatItem({
            ...(item as IHousingEstateHousePorchStoreyFlat),
            megasale: {
                ...megasle,
                isVerified: true,
                price: values.price.replaceAll(/\D/g, ''),
            },
        });
    };

    const removeRealtyPrimaryMegasaleInfo = () => {
        setFlatItem({
            ...(item as IHousingEstateHousePorchStoreyFlat),
            megasale: null,
        });
    };

    const handleSubmit = async (values) => {
        const response = await dispatch(
            addRealtyMegasale({
                ...values,
                price: values.price.replaceAll(/\D/g, ''),
                realtyId: item.id,
                realtyType: type,
            }),
        );
        if (response) {
            type === 'secondary'
                ? dispatch(addRealtyMegasaleInfo(item?.id, response.data))
                : addRealtyPrimaryMegasaleInfo(values, response.data);
        }
        setIsOpened(false);
        return;
    };
    const handleRemoveRealtyMegasale = async () => {
        dispatch(removeRealtyMegasale(item?.megasale?.id));
        type === 'secondary'
            ? dispatch(removeRealtyMegasaleInfo(item?.id))
            : removeRealtyPrimaryMegasaleInfo();
        return;
    };

    const close = () => setIsOpened(false);
    const open = () => setIsOpened(true);

    return (
        <React.Fragment>
            {item?.megasale ? (
                <Button
                    type='button'
                    size='sm'
                    variant='outline-danger'
                    title='Распродажа'
                    onClick={handleRemoveRealtyMegasale}
                >
                    Снять с распродажи
                    {item.megasale.isVerified ? (
                        <div style={{ alignItems: 'center' }}>
                            ( Прошел модерацию )
                        </div>
                    ) : (
                        <div style={{ alignItems: 'center' }}>
                            ( На модерации )
                        </div>
                    )}
                </Button>
            ) : (
                <>
                    <Button
                        type='button'
                        size='sm'
                        variant='success'
                        title='Распродажа'
                        onClick={open}
                    >
                        Распродажа
                    </Button>
                    <OverlayingPopup onClose={close} isOpened={isOpened}>
                        <div className='main_popup' style={{ width: '400px' }}>
                            <p>Добавить в распродажу объект с ID {item?.id}</p>
                            <FinalForm
                                onSubmit={handleSubmit}
                                initialValues={{
                                    price: new Intl.NumberFormat('ru-RU').format(
                                        +item?.price,
                                    ),
                                }}
                                mutators={{
                                    setValue: (
                                        [field, value],
                                        state,
                                        { changeValue },
                                    ) => {
                                        changeValue(state, field, () => value);
                                    },
                                    setPriceValue: (
                                        [field, value],
                                        state,
                                        { changeValue },
                                    ) => {
                                        value = value.replaceAll(/\D/g, '');
                                        changeValue(state, field, () =>
                                            new Intl.NumberFormat(
                                                'ru-RU',
                                            ).format(value),);
                                    },
                                }}
                                validate={(values) => {
                                    const errors: any = {};
                                    if (
                                        Number(
                                            values.price.replaceAll(/\D/g, '') >
                                            Number(item.price),
                                        )
                                    ) {
                                        errors.price =
                                            'Сумма для распродажи не может быть выше';
                                    }
                                    return errors;
                                }}
                                render={({
                                    handleSubmit,
                                    submitting,
                                    valid,
                                    form,
                                }) => {
                                    return (
                                        <form onSubmit={handleSubmit}>
                                            <Container fluid>
                                                <Form.Group className='mb-3'>
                                                    <Row>
                                                        <Col>
                                                            <InputField
                                                                name='price'
                                                                type='text'
                                                                label={
                                                                    'Новая цена на распродажу'
                                                                }
                                                                validators={
                                                                    required
                                                                }
                                                                onChange={(e) =>
                                                                    form.mutators.setPriceValue(
                                                                        'price',
                                                                        e.target
                                                                            .value,
                                                                    )
                                                                }
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <CheckboxField name={'isLiquid'} label={'Объект с ликвидной ценой'} />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Button
                                                    size='sm'
                                                    type='submit'
                                                    variant='success'
                                                    disabled={
                                                        submitting || !valid
                                                    }
                                                >
                                                    Сохранить
                                                </Button>
                                            </Container>
                                        </form>
                                    );
                                }}
                            />
                        </div>
                    </OverlayingPopup>
                </>
            )}
        </React.Fragment>
    );
};

export default ButtonWithAddMegaSale;
