import React from 'react';
import { useSelector } from 'react-redux';
import { selectComment } from '~/src/store/comment';
import Comment from '~/src/components/Comment/views/Comment';

export default function CommentsView() {
    const { items } = useSelector(selectComment);

    return (
        <>
            {items && items.length > 0
                ? items.map((comment) => (
                      <Comment key={comment.id} comment={comment} />
                  ))
                : null}
        </>
    );
}
