import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import UpdateForm from '~/src/components/Buyer/update';
import {
    read,
    set,
    getPopularSources,
    selectBuyerList,
} from '~/src/store/buyer/buyer.list.slice';

const UpdatePage = (): JSX.Element => {
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();

    const { loading } = useSelector(selectBuyerList);

    useEffect(() => {
        if (id !== undefined) {
            dispatch(read(+id));
        } else dispatch(set({}));
        dispatch(getPopularSources());
    }, [id, dispatch]);

    return (
        <React.Fragment>
            {loading ? <div>Загрузка...</div> : <UpdateForm />}
        </React.Fragment>
    );
};

export default UpdatePage;
