import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { read } from '~/src/store/housingEstateSlice';
import { useDispatch, useSelector } from 'react-redux';
import UpdateForm from '~/src/components/HousingEstate/update';
import { unload, selectDeveloper } from '~/src/store/developer/developerSlice';
import { selectApp, setHandleScrollPosition } from '~/src/store/appSlice';

const UpdatePage = (): JSX.Element => {
    const dispatch = useDispatch();
    const params = useParams<{ id: string }>();

    const { loading } = useSelector(selectApp);
    const { unloaded } = useSelector(selectDeveloper);

    useEffect(() => {
        dispatch(read(+params.id));
    }, [params.id, dispatch]);

    useEffect(() => {
        dispatch(setHandleScrollPosition(false));
    }, [dispatch]);

    useEffect(() => {
        if (unloaded === null) {
            dispatch(unload());
        }
    }, [dispatch, unloaded]);

    return (
        <React.Fragment>
            {loading ? <div>Загрузка...</div> : <UpdateForm />}
        </React.Fragment>
    );
};

export default UpdatePage;
