const fieldsLand = [
    {
        name: 'area',
        translate: 'Общая площадь участка',
    },
    {
        name: 'photos',
        translate: 'Количевство фото',
    },
    {
        name: 'description',
        translate: 'Описание',
    },
    {
        name: 'electricity',
        translate: 'Электричество',
    },
];

const fieldsHouse = [
    {
        name: 'area',
        translate: 'Общая площадь участка',
    },
    {
        name: 'photos',
        translate: 'Количевство фото',
    },
    {
        name: 'description',
        translate: 'Описание',
    },
    {
        name: 'gas',
        translate: 'Газ',
    },
    {
        name: 'houseSpaceTotal',
        translate: 'Площадь дома',
    },
    {
        name: 'heating',
        translate: 'Отопление',
    },
    {
        name: 'houseStoreys',
        translate: 'Этажей в доме',
    },
    {
        name: 'houseMaterial',
        translate: 'Материал дома',
    },
    {
        name: 'renovation',
        translate: 'Ремонт',
    },
    {
        name: 'houseRooms',
        translate: 'Кол-во спальных комнат',
    },
    {
        name: 'electricity',
        translate: 'Электричество',
    },
];

export { fieldsLand, fieldsHouse };
