import { useEffect } from 'react';
import { selectApp } from '~/src/store/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setGenerateData } from '~/src/store/housingEstateSlice';
import GenerateView from '~/src/components/HousingEstate/Generate';

const GeneratePage = (): JSX.Element => {
    const dispatch = useDispatch();
    const { loading } = useSelector(selectApp);

    useEffect(() => {
        return function cleanup() {
            dispatch(setGenerateData(null));
        };
    }, [dispatch]);

    return loading ? <>Загрузка ...</> : <GenerateView />;
};

export default GeneratePage;
