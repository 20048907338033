import classNames from 'classnames';
import { Form } from 'react-bootstrap';
import { Field } from 'react-final-form';
import Select from 'react-select';

interface IProps {
    name: string;
    label: string;
    validators?: any;
    opts: { label: string; value: string }[];
    onChange?: (event: React.ChangeEvent | any) => void;
    initialValue?: { label: string; value: string };
    [otherProp: string]: any;
}

export default function SelectSearch({
    name,
    label,
    validators,
    opts,
    onChange,
    initialValue,
}: IProps): JSX.Element {
    return (
        <Field name={name} validate={validators} initialValue={initialValue}>
            {({ input, meta }) => {
                return (
                    <Form.Group className='mb-3'>
                        <Form.Label>
                            <span>{label}</span>
                            <span
                                className={classNames({
                                    'text-info': true,
                                    'd-none': !meta.dirty,
                                })}
                            >
                                <small> изменено</small>
                            </span>
                        </Form.Label>
                        <Select
                            {...input}
                            onChange={
                                typeof onChange === 'function'
                                    ? onChange
                                    : input.onChange
                            }
                            options={opts}
                            placeholder={label}
                        />
                        <Form.Control.Feedback type='invalid'>
                            {meta.error || meta.submitError}
                        </Form.Control.Feedback>
                    </Form.Group>
                );
            }}
        </Field>
    );
}
