import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import InputField from '../Common/Form/InputField';
import SliderField from '../Common/Form/SliderField';
import CheckboxField from '../Common/Form/CheckboxField';
import { keyBenefits } from '~/src/common/data/cottage.village.key.benefits';
import { benefitsWhenPurchasing } from '~/src/common/data/cottage.village.benefitsWhenPurchasing';
import { useForm } from 'react-final-form';

export default function UpdateAdditionalView(): JSX.Element {
    const form = useForm();

    return (
        <React.Fragment>
            <Row>
                <InputField
                    label='Минимальный процент по ипотеке'
                    name='mortgage'
                />
                <InputField
                    label='Комиссионное вознаграждение'
                    name='commissionReward'
                    onChange={(e) =>
                        form.mutators.setPriceValue(
                            'commissionReward',
                            e.target.value,
                        )
                    }
                />
                <InputField
                    label='Минимальный первоначальный взнос ₽'
                    name='initialFee'
                    placeholder='0'
                    onChange={(e) =>
                        form.mutators.setPriceValue(
                            'initialFee',
                            e.target.value,
                        )
                    }
                />
            </Row>
            <Row>
                <Col>
                    <SliderField
                        name='popularity'
                        label='Популярность'
                        min={0}
                        max={100}
                        step={5}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className='mb-2'>
                        Ключевые приемущества
                    </Form.Group>
                    {keyBenefits.map(({ label, value }) => (
                        <CheckboxField key={value} name={value} label={label} />
                    ))}
                </Col>
                <Col>
                    <div className='mb-2'>Выгоды при покупке</div>
                    {benefitsWhenPurchasing.map(({ label, value }) => (
                        <CheckboxField key={value} name={value} label={label} />
                    ))}
                </Col>
            </Row>
            {/* <hr />
            <Row>
                <Col>
                    <CheckboxField name='hasCctv' label='Видеонаблюдение' />
                    <CheckboxField name='isClosedArea' label='Закрытая территория' />
                    <CheckboxField name='isProtectedArea' label='Охраняемая территория' />
                    <CheckboxField name='hasBesideSchool' label='Рядом школа' />
                    <CheckboxField name='hasBesidePreSchool' label='Рядом детский сад' />
                </Col>
                <Col>
                    <CheckboxField name='hasPlayGround' label='Игровая площадка' />
                    <CheckboxField name='hasSportGround' label='Спортивная площадка' />
                    <CheckboxField name='hasPicnicGround' label='Площадка для пикника' />
                    <CheckboxField name='hasBesidePark' label='Рядом парк' />
                    <CheckboxField name='hasBesideWater' label='Рядом водоем' />
                    <CheckboxField name='hasBesideMall' label='Рядом ТЦ' />
                </Col>
            </Row> */}
        </React.Fragment>
    );
}
