import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { getUserByPhone } from '~/src/service/user';
import {
    composeValidators,
    onlyLength,
    required,
} from '~/src/helpers/validators';
import Loading from '~/src/components/Common/Loading';
import InputField from '../../Common/Form/InputField';
import MainPopup from '../../Common/ModalUI/MainPopup';
import SimpleSelect from '../../Common/Form/SimpleSelect';
import { Field, Form as FinalForm } from 'react-final-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useParams } from 'react-router-dom';
import { inviteagent, invitenewagent } from '~/src/service/agency';
import { all } from '~/src/store/agencyAgentsSlice';
import { useDispatch, useSelector } from 'react-redux';
import ModalOneField from '../../Common/ModalUI/ModalOneField';
import { selectAgency, createGroup } from '~/src/store/agencySlice';
import ModalNewAgent from './ModalNewAgent';

const InviteAgent = (): JSX.Element => {
    const dispatch = useDispatch();
    const { id: agencyId } = useParams<{ id: string }>();

    const [isOpen, setOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [holdSubmit, setHoldSubmit] = useState(false);

    const [isOpenedGroupModal, setOpenedGroupModal] = useState(false);
    const [isOpenedNewAgentModal, setOpenedNewAgentModal] = useState(false);

    const { groups } = useSelector(selectAgency);

    const handleInvite = () => {
        setOpen(true);
    };

    const onClose = () => {
        dispatch(all(agencyId));
        setOpen(false);
    };

    const handleCreateGroup = async (title: string) => {
        dispatch(createGroup(agencyId, title));
    };

    const handleCreateNewAgent = async (values: Record<string, any>) => {
        setLoading(true);
        await invitenewagent(values as any);
        setLoading(false);
        setOpenedNewAgentModal(false);
    };

    const onSubmit = async (values: Record<string, any>) => {
        setLoading(true);
        await inviteagent(values as any);
        setLoading(false);
    };

    return (
        <React.Fragment>
            <Button size='sm' variant='success' onClick={handleInvite}>
                <FontAwesomeIcon icon={['fas', 'plus']} /> Добавить
            </Button>
            <MainPopup
                isOpened={isOpen}
                title='Введите данные'
                onClose={onClose}
            >
                {isLoading ? (
                    <Loading />
                ) : (
                    <FinalForm
                        onSubmit={onSubmit}
                        mutators={{
                            setAgentUidValue: async (
                                [field, value]: [string, string],
                                state,
                                { changeValue },
                            ) => {
                                const replaced = value.replaceAll(/\D/g, '');
                                changeValue(state, field, () => replaced);
                                if (replaced.length == 10) {
                                    setHoldSubmit(true);
                                    const founded =
                                        await getUserByPhone(+replaced);

                                    if (founded && founded.success) {
                                        changeValue(
                                            state,
                                            'agentUid',
                                            () => founded.data.uid,
                                        );
                                        changeValue(
                                            state,
                                            'agentUidName',
                                            () => founded.data.fullname,
                                        );
                                    }
                                    if (founded && founded.success == false) {
                                        console.log(
                                            'setOpenedNewAgentModal true',
                                        );
                                        setOpenedNewAgentModal(true);
                                    }
                                    setHoldSubmit(false);
                                } else {
                                    changeValue(state, 'agentUid', () => null);
                                    changeValue(
                                        state,
                                        'agentUidName',
                                        () => '',
                                    );
                                }
                            },
                            setGroupIdValue: async (
                                [field, value]: [string, string],
                                state,
                                { changeValue },
                            ) => {
                                if (value === '-1') {
                                    setOpenedGroupModal(true);
                                    return;
                                }
                                changeValue(state, field, () => value);
                            },
                        }}
                        validate={(values) => {
                            const errors: any = {};
                            return errors;
                        }}
                        render={({
                            handleSubmit,
                            form,
                            submitting,
                            valid,
                            values,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <InputField
                                    name='phone'
                                    label='Телефон'
                                    validators={composeValidators(
                                        required,
                                        onlyLength(10),
                                    )}
                                    onChange={(e) =>
                                        form.mutators.setAgentUidValue(
                                            'phone',
                                            e.target.value,
                                        )
                                    }
                                />
                                <Field name='agentUid'>
                                    {({ meta }) => (
                                        <Form.Group>
                                            <Form.Control.Feedback
                                                type='invalid'
                                                style={{
                                                    display:
                                                        meta.error ||
                                                        meta.submitError
                                                            ? 'block'
                                                            : 'none',
                                                }}
                                            >
                                                {meta.error || meta.submitError}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>
                                <Field name='agentUidName'>
                                    {({ input }) => (
                                        <Form.Group className='mb-3'>
                                            <Form.Label>Имя агента</Form.Label>
                                            <Form.Control
                                                {...input}
                                                size='sm'
                                                placeholder='Имя агента'
                                                readOnly
                                            />
                                        </Form.Group>
                                    )}
                                </Field>
                                <SimpleSelect
                                    label='Группа'
                                    name='groupId'
                                    opts={[
                                        { id: 0, title: '' },
                                        { id: -1, title: 'Новая' },
                                    ]
                                        .concat(groups)
                                        .map((item) => {
                                            if (item?.id === 0) return { value: '', title: '' };
                                            return {
                                                value: `${item?.id}`,
                                                title: item?.title,
                                            };
                                        })}
                                    onChange={(e) =>
                                        form.mutators.setGroupIdValue(
                                            'groupId',
                                            e.target.value,
                                        )
                                    }
                                />
                                <ModalOneField
                                    text='Введите название'
                                    isOpened={isOpenedGroupModal}
                                    onClose={() => setOpenedGroupModal(false)}
                                    primaryButtonOnClick={(v) =>
                                        handleCreateGroup(v)
                                    }
                                    secondaryButtonOnClick={() =>
                                        setOpenedGroupModal(false)
                                    }
                                />
                                <ModalNewAgent
                                    text='Пользователь с таким номером не найден, отправить приглашение?'
                                    isOpened={isOpenedNewAgentModal}
                                    onSubmit={handleCreateNewAgent}
                                    phone={values.phone}
                                    onClose={() =>
                                        setOpenedNewAgentModal(false)
                                    }
                                />
                                <SimpleSelect
                                    label='Должность'
                                    name='position'
                                    opts={[
                                        { value: '', title: '' },
                                        { value: 'agent', title: 'Агент' },
                                        {
                                            value: 'director',
                                            title: 'Директор',
                                        },
                                        {
                                            value: 'supervisor',
                                            title: 'Руководитель',
                                        },
                                        {
                                            value: 'administrator',
                                            title: 'Администратор',
                                        },
                                    ]}
                                    validators={required}
                                />
                                <Button
                                    size='sm'
                                    type='submit'
                                    className='btn btn-sm btn-success mb-2'
                                    disabled={
                                        submitting || !valid || holdSubmit
                                    }
                                >
                                    <FontAwesomeIcon icon={['fas', 'save']} />{' '}
                                    Пригласить
                                </Button>
                            </form>
                        )}
                    />
                )}
            </MainPopup>
        </React.Fragment>
    );
};

export default InviteAgent;
