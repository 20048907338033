export const realtyTypes = [
    {
        title: 'Вторичка',
        code: 'secondary',
    },
    {
        title: 'Новостройка',
        code: 'primary',
    },
    {
        title: 'Дом',
        code: 'house',
    },
    {
        title: 'Земельный участок',
        code: 'land',
    },
    {
        title: 'Таунхаус',
        code: 'townhouse',
    },
    {
        title: 'Дача',
        code: 'chalet',
    },
];

export const rooms = [
    {
        title: 'Студия',
        code: 'studio',
    },
    {
        title: '1',
        code: '1',
    },
    {
        title: '2',
        code: '2',
    },
    {
        title: '3',
        code: '3',
    },
    {
        title: '4',
        code: '4',
    },
    {
        title: '5+',
        code: '5',
    },
];

export const controlDate = [
    {
        title: 'Все',
        date: null,
        date_number: 0,
    },
    {
        title: 'Позвонить сегодня',
        date: 'today',
        date_number: 0,
    },
    {
        title: 'Позвонить завтра',
        date: 'tomorrow',
        date_number: 1,
    },
    {
        title: '3 дня и более',
        date: 'three',
        date_number: 3,
    },
    {
        title: '7 дней и более',
        date: 'week',
        date_number: 7,
    },
    {
        title: '30 дней и более',
        date: 'month',
        date_number: 30,
    },
];

export const thermometer = [
    {
        value: 'cold',
        label: 'Холодный',
    },
    {
        value: 'warm',
        label: 'Теплый',
    },
    {
        value: 'hot',
        label: 'Горячий',
    },
];

export function convertCodeToTitles(codes: Array<string>, name) {
    const titles = [];
    codes.map((code) => {
        const findType =
            name === 'realtyType'
                ? realtyTypes.find((type) => type.code === code)
                : rooms.find((type) => type.code === code);
        findType && titles.push(findType.title);
    });

    const stringTitles =
        name === 'realtyType'
            ? titles.join(', ')
            : delimiterRooms(titles).join(', ');

    return stringTitles;
}

function delimiterRooms(rooms: Array<string>) {
    const roomsDelimiter = [];
    rooms.map((room) => {
        if (room === 'Студия' || room === '5+') {
            roomsDelimiter.push(room);
        } else {
            roomsDelimiter.push(`${room}к`);
        }
    });
    return roomsDelimiter;
}
