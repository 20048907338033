import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Button,
    ButtonGroup,
    Col,
    Form,
    Navbar,
    Row,
    Table,
} from 'react-bootstrap';
import moment from 'moment';
import { selectMegaSale } from '~/src/store/megasaleSlice';
import FormMegasaleStart from '~/src/components/Sale/FormMegaSaleStart';
// import Pagination from '~/src/components/Common/Pagination';
import ListSecondaryRealty from '~/src/components/Sale/ListMegasale/ListSecondaryRealty';
import ListPrimaryRealty from './ListMegasale/ListPrimaryRealty';

const SaleView = (): JSX.Element => {
    const navigate = useNavigate();

    const { megasaleActive, items } = useSelector(selectMegaSale);

    return (
        <div style={{ height: '100vh' }}>
            <Navbar
                className='justify-content-between'
                bg='light'
                expand='lg'
                variant='light'
            >
                <ButtonGroup>
                    <Button size='sm' onClick={() => navigate(-1)}>
                        <FontAwesomeIcon icon={['fas', 'fast-backward']} />{' '}
                        Назад
                    </Button>
                </ButtonGroup>
            </Navbar>
            {megasaleActive ? (
                <>
                    <Row>
                        <Col xs={4}>
                            <Col>На данный момент распродажа активна</Col>
                            <Col>Название:  {megasaleActive?.title}</Col>
                        </Col>
                        <Col xs={3}>
                            Дата начала:{' '}
                            {moment
                                .utc(megasaleActive.dateStart)
                                .format('YYYY-MM-DD HH:mm:ss')}
                            <br />
                            Дата конца:{' '}
                            {moment
                                .utc(megasaleActive.dateFinish)
                                .format('YYYY-MM-DD HH:mm:ss')}
                        </Col>
                    </Row>
                    <div>
                        <Row className='mb-2'>
                            {/* Сделать логику по количеству отображения элементов на странице */}
                            {/* <Col xs={4}>
                        <Form.Label>На странице</Form.Label>
                        <Form.Select
                            className='form-control'
                            size='sm'
                            value={search?.limit || 25}
                            onChange={limitHandler}
                        >
                            <option value={1}>1</option>
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={100}>100</option>
                        </Form.Select>
                    </Col> */}
                            <Col xs={4}>
                                <div className='mb-2'>
                                    <Form.Label></Form.Label>
                                </div>
                                <Form.Label>
                                    <strong>
                                        Всего объектов на распродаже:
                                    </strong>{' '}
                                    {items.count}
                                </Form.Label>
                            </Col>
                        </Row>
                        {/* {items && items.pagination?.have && <Pagination pagination={items.pagination} onClick={handlePaginationClick} />} */}
                        {/* Сделать пагинацию */}
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <td>#</td>
                                    <td>Фото</td>
                                    <td>Адрес</td>
                                    <td>Параметры</td>
                                    <td>Дом</td>
                                    <td>Цена</td>
                                    <td>Продавец</td>
                                    <td>Управление</td>
                                </tr>
                            </thead>
                            <tbody>
                                {items && items.items && items.items.length > 0
                                    ? items.items.map((item) =>
                                        item.realtyType === 'secondary' ? (
                                            <ListSecondaryRealty
                                                key={item.id}
                                                item={item}
                                            />
                                        ) : (
                                            <ListPrimaryRealty
                                                key={item.id}
                                                item={item}
                                            />
                                        ),)
                                    : null}
                            </tbody>
                        </Table>
                    </div>
                </>
            ) : (
                <FormMegasaleStart />
            )}
        </div>
    );
};

export default SaleView;
