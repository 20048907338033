import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { BaseSyntheticEvent, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import OverlayingPopup from '~/src/components/Common/ModalUI/OverlayingPopup';
import { deleteReviewsFile, updateReviews } from '~/src/store/paSlice';
import { Field, Form as FinalForm } from 'react-final-form';
import { required } from '~/src/helpers/validators';
import FileField from '~/src/components/Common/Form/FileField';
import { compressImages } from '~/src/helpers/compressImage';
import { IReviews } from '~/src/service/user';
import { IFilestore } from '~/src/service/filestore';

const UpdateReviews = ({ item }: {
    item: IReviews;
}) => {
    const dispatch = useDispatch();

    const base = 'https://files.citidom.com/';

    const [isOpened, setIsOpened] = useState(false);
    const [preview, setPreview] = useState<any>([]);
    const [files, setFiles] = useState<File[]>();

    const close = () => {
        setIsOpened(false);
    };

    const handleSubmit = (values: Record<string, any>) => {
        dispatch(updateReviews(values.id, { ...values }, { ...files }));
        close();
    };

    const changeHandler = async (e: BaseSyntheticEvent) => {
        const compressedFiles = await compressImages(e.target.files, {
            quality: 0.8,
            maxWidth: 1200,
        });

        const ff = [];
        for (const compressed of compressedFiles) {
            ff.push({
                name: URL.createObjectURL(compressed),
                identfy: compressed.name,
                progress: 0,
            });
        }
        setPreview([...preview, ...ff]);
        setFiles(compressedFiles);
    };

    const removeImage = async (image: IFilestore) => {
        setPreview(prev => prev.filter(item => item.name !== image.name));
        if (image.type) {
            dispatch(deleteReviewsFile(item.id, image.name));
        }
        if (files) {
            setFiles(prev => prev.filter(item => item.name !== image.identfy));
        }
    };

    useEffect(() => {
        if (isOpened) {
            setPreview([...item.files]);
        }
    }, [isOpened, item.files]);

    return (
        <>
            <div style={{
                position: 'absolute',
                top: '0px',
                right: '10px',
                cursor: 'pointer'
            }}
                onClick={() => setIsOpened(true)}
                title='Изменить'
            >
                <FontAwesomeIcon icon={['fas', 'pen']} />
            </div>
            {
                isOpened && <OverlayingPopup onClose={close} isOpened={isOpened}>
                    <div className='main_popup' style={{ width: '800px' }}>
                        <p>Изменить</p>
                        <FinalForm
                            onSubmit={handleSubmit}
                            mutators={{
                                setValue: ([field, value], state, { changeValue }) => {
                                    changeValue(state, field, () => value);
                                },
                            }}
                            initialValues={{
                                ...Object.keys(item)
                                    .filter((k) => item[k] != null)
                                    .reduce((a, k) => ({ ...a, [k]: item[k] }), {}),
                            }}
                            validate={() => {
                                const errors: any = {};
                                return errors;
                            }}
                            render={({ handleSubmit, submitting, valid }) => (
                                <form onSubmit={handleSubmit}>
                                    <Field name='content' validate={required}>
                                        {({ input, meta }) => (
                                            <Form.Group className='mb-3'>
                                                <Form.Label>Отзыв</Form.Label>
                                                <Form.Control
                                                    {...input}
                                                    as='textarea'
                                                    rows={4}
                                                    size='sm'
                                                    isInvalid={
                                                        meta.error || meta.submitError
                                                    }
                                                    placeholder='Напишите отзыв'
                                                />
                                                <Form.Control.Feedback type='invalid'>
                                                    {meta.error || meta.submitError}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        )}
                                    </Field>
                                    <Form.Group className='mb-3 d-flex gap-3 mt-3'>
                                        <label className='btn btn-sm btn-primary'>
                                            <FileField
                                                name='files'
                                                onChange={(e) => changeHandler(e)}
                                                multiple
                                            />{' '}
                                            Загрузить фото
                                        </label>
                                    </Form.Group>
                                    <div className='mt-2 clearfix' style={{
                                        overflowY: 'auto',
                                        maxHeight: '200px',
                                    }}>
                                        {preview &&
                                            preview.length > 0 &&
                                            preview.map((image, index) => {
                                                return (
                                                    <div
                                                        key={`imagePreview${image.name}`}
                                                        className='float-start'
                                                        style={{
                                                            position: 'relative'
                                                        }}
                                                    >
                                                        <div style={{
                                                            position: 'absolute',
                                                            top: '0px',
                                                            right: '5px',
                                                            cursor: 'pointer'
                                                        }}
                                                            onClick={() => removeImage(image)}
                                                            title='Удалить'
                                                        >
                                                            <FontAwesomeIcon icon={faTimesCircle} style={{ color: 'red', fontSize: '20px' }} />
                                                        </div>
                                                        <img
                                                            src={image.type ? `${base}${image.name}` : image.name}
                                                            alt={image.title}
                                                            style={{
                                                                height: '150px',
                                                                width: '230px',
                                                                objectFit: 'cover'
                                                            }}
                                                        />
                                                    </div>
                                                );
                                            })}
                                    </div>
                                    <Button
                                        size='sm'
                                        type='submit'
                                        variant='success'
                                        disabled={submitting || !valid}
                                        className='mt-3'
                                    >
                                        Сохранить
                                    </Button>
                                </form>
                            )}
                        />
                    </div>
                </OverlayingPopup>
            }
        </>
    );
};

export default UpdateReviews;
