import { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { FILES_BASE } from '~/src/constants/urls';
import { IFilestore } from '~/src/service/filestore';
import { LazyLoadImage } from 'react-lazy-load-image-component';

type RSListPhotosProps = {
    photos: IFilestore[];
    label?: string;
    width?: number;
    height?: number;
};

const PhotoSlider: React.FC<RSListPhotosProps> = ({
    photos,
    width = 200,
    height = 112,
    label = 'Фото'
}) => {
    const [isOpen, setOpen] = useState(false);
    const [index, setIndex] = useState(0);

    if (photos && photos.length > 0) {
        return (
            <div>
                <p>
                    <strong>{label}</strong>
                    <br />
                    Всего: {photos.length}
                </p>
                <LazyLoadImage
                    width={width}
                    height={height}
                    style={{ height: height }}
                    src={`${FILES_BASE}${photos[0].name}`}
                    onClick={() => setOpen(true)}
                    effect='blur'
                />
                {isOpen && (
                    <Lightbox
                        mainSrc={`${FILES_BASE}${photos[index].name}`}
                        nextSrc={`${FILES_BASE}${photos[(index + 1) % photos.length].name}`}
                        prevSrc={`${FILES_BASE}${photos[(index + photos.length - 1) % photos.length].name}`}
                        onCloseRequest={() => {
                            setOpen(false);
                            setIndex(0);
                        }}
                        onMovePrevRequest={() =>
                            setIndex(
                                (index + photos.length - 1) % photos.length,
                            )
                        }
                        onMoveNextRequest={() =>
                            setIndex((index + 1) % photos.length)
                        }
                    />
                )}
            </div>
        );
    } else {
        return null;
    }
};

export default PhotoSlider;
