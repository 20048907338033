import React, { FC } from 'react';
import dateFormat from 'dateformat';
import { IUser } from '~/src/service/user';
import { ButtonGroup } from 'react-bootstrap';
import ButtonWithConfirm from '../Common/ModalUI/ButtonWithConfirm';

const AgencyRow: FC<{ item: IUser }> = ({ item }) => {
    const handleBlock = () => {
        console.log('handleBlock');
    };

    const handleRemove = () => {
        console.log('handleRemove');
    };

    return (
        <React.Fragment>
            <tr>
                <td>{item.id}</td>
                <td>
                    <span>{item.fullname}</span>
                    <br />
                </td>
                <td>
                    <span>Название АН (ИНН): {item.inn}</span>
                    <br />
                    <span>
                        Регистрация в системе:{' '}
                        {dateFormat(item.createdAt, 'dd.mm.yyyy')}
                    </span>
                    <br />
                    <span>Контактный телефон: {item.phone}</span>
                    <br />
                    <span>Email: {item.email}</span>
                    <br />
                </td>
                <td>
                    <span>Вторичка всего: {item.secondaryTotal}</span>
                    <br />
                    <span>Вторичка в приложении: {item.secondaryVerified}</span>
                    <br />
                </td>
                <td>
                    <ButtonGroup vertical className='w-100'>
                        <ButtonWithConfirm
                            variant='secondary'
                            onClick={handleBlock}
                            modalText={'Точно заблокировать?'}
                        >
                            Заблокировать учетку
                        </ButtonWithConfirm>
                        <ButtonWithConfirm
                            variant='danger'
                            modalText={'Точно удалить?'}
                            onClick={handleRemove}
                        >
                            Удалить учетку
                        </ButtonWithConfirm>
                    </ButtonGroup>
                </td>
            </tr>
        </React.Fragment>
    );
};

export default AgencyRow;
