import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { all } from '~/src/store/address';
import { useQuery } from '~/src/helpers/hooks';
import ListView from '~/src/components/Address';
import {
    setActiveMenuKey,
    setHandleScrollPosition,
} from '~/src/store/appSlice';

const AddressPage = (): JSX.Element => {
    const query = useQuery();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(all(query));
    }, [dispatch, query]);

    useEffect(() => {
        dispatch(setActiveMenuKey('ADR'));
    }, [dispatch]);

    useEffect(() => {
        dispatch(setHandleScrollPosition(false));
    }, [dispatch]);

    return <ListView />;
};

export default AddressPage;
