import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    read,
    selectRS,
} from '~/src/store/realtySecondary/realtySecondarySlice';
import { selectApp, setHandleScrollPosition } from '~/src/store/appSlice';
import RSCard from '~/src/components/Realty/Secondary/card';

const RSCardPage: FC = () => {
    const dispatch = useDispatch();
    const params = useParams<{ id: string }>();

    const { item } = useSelector(selectRS);
    const { loading } = useSelector(selectApp);

    useEffect(() => {
        dispatch(read(+params.id));
    }, [params.id, dispatch]);

    useEffect(() => {
        dispatch(setHandleScrollPosition(false));
    }, [dispatch]);

    return (
        <React.Fragment>
            {loading || !item?.id ? (
                <div>Загрузка...</div>
            ) : (
                <RSCard item={item} />
            )}
        </React.Fragment>
    );
};

export default RSCardPage;
