const payment = [
    {
        title: 'Ипотека',
        value: 'mortgage',
    },
    {
        title: 'Наличные',
        value: 'cash',
    },
    {
        title: 'Сертификат',
        value: 'certificate',
    },
];

const mortgage = [
    {
        title: 'Одобрена или на одобрении',
        value: 'approved',
    },
    {
        title: 'Не одобрена, готов одобраться',
        value: 'ready_to_be_approved',
    },
    {
        title: 'Не одобрена, не торопится',
        value: 'not_approved',
    },
];

const cash = [
    {
        title: 'Есть в наличии, либо уже идет сделка по продаже своей недвижимости',
        value: 'available',
    },
    {
        title: 'Планируется с продажи',
        value: 'from_sale',
    },
    {
        title: 'Нет денег, либо есть но мало и не торопится, квартира еще не выставлена',
        value: 'no',
    },
];

const certificate = [
    {
        title: 'Есть, выдан',
        value: 'available',
    },
    {
        title: 'Нет, но жду получения в этом году',
        value: 'this_year',
    },
    {
        title: 'Нет, но предвидится',
        value: 'no',
    },
];

export { payment, mortgage, cash, certificate };
