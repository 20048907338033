import React, { useEffect } from 'react';
import { useQuery } from '~/src/helpers/hooks';
import { selectApp, setActiveMenuKey } from '~/src/store/appSlice';
import { myagency } from '~/src/store/xml.feed';
import { useDispatch, useSelector } from 'react-redux';
import XmlFeedListView from '~/src/components/Agency/xml.feed';

const AgencyXmlFeedPage = (): JSX.Element => {
    const query = useQuery();
    const dispatch = useDispatch();

    const { loading } = useSelector(selectApp);

    useEffect(() => {
        dispatch(myagency(query));
    }, [dispatch, query.toString()]);

    useEffect(() => {
        dispatch(setActiveMenuKey('AgencyXmlFeed'));
    }, [dispatch]);

    return loading ? <>Loading ...</> : <XmlFeedListView />;
};

export default AgencyXmlFeedPage;
