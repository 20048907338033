import { Link, useNavigate } from 'react-router-dom';
import { IHousingEstate } from '~/src/service/housing.estate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup, Navbar, Table } from 'react-bootstrap';
import ButtonWithConfirm from '../../Common/ModalUI/ButtonWithConfirm';
import { useDispatch, useSelector } from 'react-redux';
import { fresh, selectHE } from '~/src/store/housingEstateSlice';
import HousesBtn from './NavPanel/HousesBtn';
import PorchesBtn from './NavPanel/PorchesBtn';
import StoreyChess from './StoreyChess';

export default function HousingEstateHouses() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        chessPorch,
        loading,
        activePorch,
        infoHousingEstate: estate,
    } = useSelector(selectHE);

    const handleFresh = () => {
        console.log('fresh');
        dispatch(fresh(estate.id));
    };

    if (estate === null) return <>...</>;

    return (
        <>
            <Navbar
                className='justify-content-between'
                bg='light'
                expand='lg'
                variant='light'
            >
                <ButtonGroup>
                    <Button size='sm' onClick={() => navigate(-1)}>
                        <FontAwesomeIcon icon={['fas', 'fast-backward']} />{' '}
                        Назад
                    </Button>
                </ButtonGroup>
                <ButtonGroup>
                    <ButtonWithConfirm
                        onClick={handleFresh}
                        label={''}
                        variant='success'
                        modalText={'Обновить?'}
                    >
                        <FontAwesomeIcon icon={['fas', 'pepper-hot']} />{' '}
                        Обновить
                    </ButtonWithConfirm>
                </ButtonGroup>
            </Navbar>
            <HousesBtn houses={estate?.houses} />
            <PorchesBtn />
            <Navbar
                className='justify-content-between'
                bg='light'
                expand='lg'
                variant='light'
            >
                <ButtonGroup>
                    <Link
                        to={`/housing-estate/${activePorch?.housingEstateId}/house/${activePorch?.housingEstateHouseId}/porch/${activePorch?.id}/generate`}
                        className='btn btn-sm btn-success'
                    >
                        <FontAwesomeIcon icon={['fas', 'magic']} />{' '}
                        Сгенерировать квартиры
                    </Link>
                </ButtonGroup>
            </Navbar>
            {loading ? (
                <>Данные загружаются</>
            ) : (
                <div
                    style={{
                        overflowX: 'scroll',
                        // height: '80vh'
                    }}
                >
                    <Table bordered>
                        <tbody>
                            {chessPorch?.storeys?.map((storey) => (
                                <StoreyChess
                                    key={storey.id}
                                    {...storey}
                                    floorsTotal={chessPorch.floorsTotal}
                                />
                            ))}
                        </tbody>
                    </Table>
                </div>
            )}
        </>
    );
}
