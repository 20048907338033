import { useState } from 'react';
import { Button, Form, ListGroup, ListGroupItem } from 'react-bootstrap';
import OverlayingPopup from '../../Common/ModalUI/OverlayingPopup';

export default function RSPhotoUploadRules() {
    const [isOpened, setIsOpened] = useState(false);

    const open = () => setIsOpened(true);
    const close = () => setIsOpened(false);

    return (
        <Form.Group>
            <Button
                style={{ cursor: 'pointer' }}
                onClick={open}
                variant='warning'
            >
                Правила добавления фото
            </Button>
            <OverlayingPopup isOpened={isOpened} onClose={close}>
                <div
                    style={{
                        overflow: 'auto',
                        height: '80%',
                        width: '700px',
                        backgroundColor: 'white',
                        position: 'relative',
                        padding: '10px 10px 0',
                    }}
                >
                    <div>
                        <p>
                            <strong>
                                Правила добавления фото для объектов в категории квартиры, для появления вашего
                                объекта на сайте и в приложении.
                            </strong>
                        </p>
                        <ListGroup>
                            <ListGroup.Item>
                                <strong>1. Допускается использование только реальных фотографии, горизонтального
                                    размещения, предлагаемого объекта.</strong>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <strong> 2. Для рекламы объекта необходимо минимум 3 фотографии самого объекта (без
                                    учета санузла, ванной и балкона/лоджии).</strong>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <strong>3. Очередность фотографий выстраивается в следующем порядке от квартиры к
                                    дому:</strong>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <ul>
                                    <ListGroup.Item>
                                        3.1. Главное фото – самая удачная фотография кухни, кухни гостиной.
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        3.2. Далее фотографии жилых комнат, сану узлы и лоджии только после жилых
                                        комнат.
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        3.3. Фотографии подъезда. (не более 2 шт.)
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        3.4. Фотографии дома. (не более 3 шт.)
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        3.5. Планировка отрисованная.
                                    </ListGroup.Item>
                                </ul>
                            </ListGroup.Item>
                            <ListGroupItem>
                                <strong>4. Фото дома можно ставить первой фотографией в объекте, если:</strong>
                            </ListGroupItem>
                            <ListGroupItem>
                                <ul>
                                    <ListGroupItem>
                                        4.1. Фотографии самой квартиры не эстетичны.
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        4.2. Квартира продается в черновой отделке.
                                    </ListGroupItem>
                                </ul>
                            </ListGroupItem>
                            <ListGroup.Item>
                                В этих случаях, фото дома и двора не более 3-х первых.
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <strong> 5. Квартиры в новостройке от дольщика рекомендуется первой фото поставить
                                    планировку. Если квартира на стадии строительства и нет возможности поставить
                                    фото самого объекта, то допускается размещение фотографий дома, рендеры не
                                    допускаются. При этом в любом случае кол-во фотографий не должно быть мене 3-х.</strong>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                6. Запрещается публиковать фотографии, содержащие:
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <ul>
                                    <ListGroup.Item>
                                        6.1. Личные фотографии в интерьере
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        6.2. Людей, животных, алкогольные
                                        напитки, табачные изделия
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        6.3. Включенный телевизор
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        6.4. Грязную посуду, раскиданный вещи
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        6.5. В санузлах предметы личной гигиены
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        6.6. Отражения в зеркале и блики от
                                        вспышки
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        6.7. Любые водные знаки, дату время
                                        съемки и иные знаки.
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        6.8. Размытые фотографии, увеличенные из
                                        маленьких, на фото перекошенные объекты.
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        6.9. Рендеры
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        6.10. Ретушированные логотипы
                                        (ЗАМАЗАННЫЕ), растянутые фотографии.
                                    </ListGroup.Item>
                                </ul>
                            </ListGroup.Item>
                        </ListGroup>
                    </div>
                </div>
            </OverlayingPopup>
        </Form.Group>
    );
}
