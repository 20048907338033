import classNames from 'classnames';
import { IRSModel } from '~/src/store/realtySecondary/realtySecondarySlice';
import { IRSUBModel } from '~/src/store/realtySuburban/realtySuburbanSlice';

const EncumbranceUnderstatementNeed = ({ item }: {
    item: IRSUBModel | IRSModel
}) => {
    return (
        <>
            {item?.isUnderstatementNeed ? (
                <p>
                    <strong
                        className={classNames(
                            'border',
                            'border-2',
                            'border-danger',
                        )}
                    >
                        Занижение
                    </strong>
                    <br />
                    {new Intl.NumberFormat('ru-RU').format(
                        +item?.understatementSum,
                    )}
                    <br />
                </p>
            ) : null}
            {+item?.bankEncumbrance > 0 ? (
                <p>
                    <strong
                        className={classNames(
                            'border',
                            'border-2',
                            'border-danger',
                        )}
                    >
                        Обременение
                    </strong>
                    <br />
                    {item?.bankEncumbranceTitle}
                    <br />
                    {new Intl.NumberFormat('ru-RU').format(
                        +item?.bankEncumbrance,
                    )}
                    <br />
                </p>
            ) : null}
        </>
    );
};

export default EncumbranceUnderstatementNeed;
