import dateFormat from 'dateformat';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import React, { FC, useState } from 'react';
import { IBuyer } from '~/src/service/buyer';
import { Button, ButtonGroup } from 'react-bootstrap';
import ModalOneDateField from '../Common/ModalUI/ModalOneField/DateField';
import {
    prepareCardDescription,
    addCommentFromRow,
    changeControlDate,
    changeStatus,
    transfer,
} from '~/src/store/buyer/buyer.list.slice';
import ButtonWithConfirmModal from '../Common/ModalUI/ButtonWithConfirm/btnModal';
import AuthLayer from '../Common/Hoc';
import ModalComment from './ModalComments';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonWithTransferControl, { IItem as IItemTranser } from './ButtonWithTransferControl';

const ListRow: FC<{ item: IBuyer }> = ({ item }) => {
    const [openCDModal, setOpenCDModal] = useState<boolean>(false);

    const dispatch = useDispatch();

    const handleLeaveComment = async (content: string) => {
        dispatch(addCommentFromRow(item.id, content));
    };

    const handleCDChange = async (date: any) => {
        dispatch(
            changeControlDate(item.id, {
                controlDate: dateFormat(date, 'yyyy-mm-dd'),
            }),
        );
    };

    const handleArchive = async (value) => {
        dispatch(addCommentFromRow(item.id, value));
        item?.status === 'archive'
            ? dispatch(changeStatus(item?.id, 'active'))
            : dispatch(changeStatus(item?.id, 'archive'));
    };

    const handleDeal = async (value) => {
        dispatch(addCommentFromRow(item.id, value));
        item?.status === 'deal'
            ? dispatch(changeStatus(item?.id, 'active'))
            : dispatch(changeStatus(item?.id, 'deal'));
    };

    const expresion =
        new Date(item?.controlDate).setHours(0, 0, 0, 0) <
        new Date().setHours(0, 0, 0, 0);

    const isNew = item.newbie ? (
        <FontAwesomeIcon icon={['fas', 'user-plus']} color='orange' />
    ) : null;

    return (
        <React.Fragment>
            <tr>
                <td className={`${item?.thermometer}List`}>{item.id}</td>
                <td>
                    <p title={isNew ? 'Новый' : null}>
                        {isNew} Покупатель: {item.fullname}
                    </p>
                    <p>Агент: {item.agent?.fullname}</p>
                    <p>Внесен: {dateFormat(item?.createdAt, 'dd.mm.yyyy')}</p>
                    <p>
                        {expresion && <div className='expired'>Просрочен</div>}
                    </p>
                </td>
                <td
                    style={{
                        minWidth: '200px',
                    }}
                >
                    {item &&
                        prepareCardDescription<string[]>(item).map((i) => (
                            <span key={i}>
                                {i}
                                <br />
                            </span>
                        ))}
                </td>
                <td
                    style={{
                        wordWrap: 'break-word',
                    }}
                >
                    <p
                        style={{
                            maxWidth: '450px',
                        }}
                    >
                        {item.note}
                    </p>
                </td>
                <td>
                    <AuthLayer
                        position={['director', 'supervisor', 'administrator']}
                        itemAgentUid={item.agentUid}
                    >
                        <Button onClick={() => setOpenCDModal(true)}>
                            {dateFormat(item.controlDate, 'dd.mm.yyyy')}
                        </Button>
                        <ModalOneDateField
                            isOpened={openCDModal}
                            text={'Новая контрольная дата'}
                            onClose={() => setOpenCDModal(false)}
                            primaryButtonOnClick={handleCDChange}
                            secondaryButtonOnClick={() => setOpenCDModal(false)}
                            defaultValue={item.controlDate}
                        />
                    </AuthLayer>
                </td>
                <td style={{ wordWrap: 'break-word', maxWidth: '400px' }}>
                    <AuthLayer
                        position={['director', 'supervisor', 'administrator']}
                        itemAgentUid={item.agentUid}
                    >
                        <p>{item.lastComment?.content}</p>
                        {item?.lastComment && (
                            <ModalComment
                                id={item.id}
                                handleLeaveComment={handleLeaveComment}
                            />
                        )}
                    </AuthLayer>
                </td>
                <td>
                    <ButtonGroup vertical={true} className='w-100'>
                        <AuthLayer
                            position={['director', 'supervisor', 'administrator']}
                            permission='agency.permission.buyer.all'
                            itemAgentUid={item.agentUid}
                        >
                            <Link
                                className='btn btn-sm btn-warning'
                                title='Редактировать'
                                to={`/buyer/update/${item.id}`}
                            >
                                Редактировать
                            </Link>
                            <ButtonWithConfirmModal
                                modalText=''
                                text={'Оставьте комментарий'}
                                label={
                                    item?.status === 'archive'
                                        ? 'Убрать из архива'
                                        : 'В архив'
                                }
                                variant={
                                    item?.status === 'archive'
                                        ? 'primary'
                                        : 'danger'
                                }
                                primaryButtonOnClick={handleArchive}
                            />
                            <ButtonWithConfirmModal
                                modalText=''
                                text={'Оставьте комментарий'}
                                label={
                                    item?.status === 'deal'
                                        ? 'Находится в сделке'
                                        : 'Добавить в сделку'
                                }
                                variant={
                                    item?.status === 'deal'
                                        ? 'success'
                                        : 'primary'
                                }
                                primaryButtonOnClick={handleDeal}
                            />
                        </AuthLayer>
                        <AuthLayer
                            position={['director', 'supervisor', 'administrator']}
                            permission='agency.permission.buyer.all'
                        >
                            <ButtonWithTransferControl item={item as IItemTranser} transfer={transfer} />
                        </AuthLayer>
                    </ButtonGroup>

                </td>
            </tr>
        </React.Fragment>
    );
};

export default ListRow;
