import { FormApi } from 'final-form';
import arrayMutators from 'final-form-arrays';
import FileField from '../Common/Form/FileField';
import { setLoading } from '~/src/store/appSlice';
import renameFile from '~/src/helpers/rename.file';
import InputField from '../Common/Form/InputField';
import { FieldArray } from 'react-final-form-arrays';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import CheckboxField from '../Common/Form/CheckboxField';
import { Form as FinalForm, Field } from 'react-final-form';
import SimpleSelectField from '../Common/Form/SimpleSelect';
import { BaseSyntheticEvent, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    getChessPorch,
    selectHE,
    setGenerateData,
} from '~/src/store/housingEstateSlice';
import SimpleSelectFieldV2 from '~/src/components/Common/Form/SimpleSelectV2';
import {
    composeValidators,
    mustBeNumber,
    regex,
    required,
    space,
} from '~/src/helpers/validators';
import {
    read,
    generate,
    IGeneratePorchData,
} from '~/src/service/housing.estate.house.porch';
import {
    Button,
    ButtonGroup,
    Card,
    Col,
    Container,
    Form,
    Navbar,
    Row,
    Image,
} from 'react-bootstrap';

export default function Generate(): JSX.Element {
    const { porchId } = useParams<{ porchId: string }>();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [porch, setPorch] = useState<any>(null);

    const { generateData } = useSelector(selectHE);

    useEffect(() => {
        read(+porchId).then((res) => setPorch(res));
    }, [porchId]);

    const changeHandler = (
        e: BaseSyntheticEvent,
        form: FormApi,
        index: number,
    ) => {
        const renamed = renameFile(e.target.files[0], `layout${index + 1}`);

        form.mutators.update('flats', index, {
            ...form.getState().values.flats[index],
            layout: renamed,
            layoutName: renamed.name,
            layoutImage: URL.createObjectURL(renamed),
        });
    };

    const onSubmit = async (values: IGeneratePorchData) => {
        try {
            dispatch(setGenerateData(values));

            dispatch(setLoading(true));

            const response = await generate(+porchId, {
                ...values,
                flats: values.flats?.map((flat) => {
                    return {
                        ...flat,
                        price: flat.price.toString().replaceAll(/\D/g, ''),
                    };
                }),
            });

            if (response.success) {
                dispatch(setGenerateData(null));
                dispatch(getChessPorch(+porchId));
                navigate(-1);
            }
        } finally {
            dispatch(setLoading(false));
        }
    };

    const initial = generateData || { flats: [{ room: 1 }] };

    return (
        <>
            <p>
                Генерация квартир для подъезда #{porch?.number} Всего этажей:{' '}
                {porch?.floorsTotal}
            </p>
            <FinalForm
                onSubmit={onSubmit}
                initialValues={initial}
                mutators={{
                    // expect (field, value) args from the mutator
                    setValue: ([field, value], state, { changeValue }) => {
                        changeValue(state, field, () => value);
                    },
                    setPriceValue: ([field, value], state, { changeValue }) => {
                        value = value.replaceAll(/\D/g, '');
                        changeValue(state, field, () =>
                            new Intl.NumberFormat('ru-RU').format(value),);
                    },
                    setDecimalValue: (
                        [field, value],
                        state,
                        { changeValue },
                    ) => {
                        value = value.replaceAll(',', '.');
                        changeValue(state, field, () => value);
                    },
                    ...arrayMutators,
                }}
                validate={() => {
                    const errors: any = {};
                    return errors;
                }}
                render={({ handleSubmit, form, submitting, valid }) => (
                    <form onSubmit={handleSubmit}>
                        <Container fluid>
                            <Navbar
                                className='justify-content-between'
                                bg='light'
                                expand='lg'
                                variant='light'
                            >
                                <ButtonGroup>
                                    <Button
                                        size='sm'
                                        onClick={() => navigate(-1)}
                                    >
                                        <FontAwesomeIcon
                                            icon={['fas', 'fast-backward']}
                                        />{' '}
                                        Назад
                                    </Button>
                                    <Button
                                        type='submit'
                                        variant='success'
                                        size='sm'
                                        disabled={!valid || submitting}
                                    >
                                        Сгенерировать
                                    </Button>
                                </ButtonGroup>
                            </Navbar>
                            <Card.Text className='text-muted mb-2'>
                                Можно ввести номера через запятую (1, 2, 5, 10),
                                используя тире для промежутка (1-5, 7, 10,
                                13-15), либо нажать кнопку - «Все»
                            </Card.Text>
                        </Container>
                        <Button
                            type='button'
                            size='sm'
                            variant='primary'
                            onClick={() =>
                                form.mutators.setValue(
                                    'storeys',
                                    `1-${porch?.floorsTotal}`,
                                )
                            }
                        >
                            Все
                        </Button>
                        <InputField
                            name='storeys'
                            label='Этажи'
                            validators={composeValidators(
                                required,
                                regex(
                                    /(([1-9][0-9]*\s*-\s*[1-9][0-9]*)|([1-9][0-9]*))/g,
                                ),
                            )}
                        />
                        <CheckboxField
                            label='Обновить уже внесенные квартиры'
                            name='update'
                        />
                        <Form.Group>
                            <Button
                                type='button'
                                size='sm'
                                onClick={() => form.mutators.push('flats', {})}
                            >
                                <FontAwesomeIcon icon={['fas', 'plus']} />{' '}
                                Добавить квартиру
                            </Button>
                        </Form.Group>
                        <Row>
                            <FieldArray name='flats'>
                                {({ fields }) =>
                                    fields.map((name, index) => (
                                        <Col key={index}>
                                            <Card.Text>
                                                На площадке #
                                                {
                                                    form.getState().values
                                                        .flats[index].room
                                                }
                                            </Card.Text>
                                            <Button
                                                type='button'
                                                size='sm'
                                                variant='warning'
                                                title='Убрать'
                                                onClick={() =>
                                                    fields.remove(index)
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={['fas', 'trash']}
                                                />
                                            </Button>
                                            <InputField
                                                name={`${name}.room`}
                                                initialValue={index + 1}
                                                label='На площадке'
                                                validators={composeValidators(
                                                    required,
                                                    mustBeNumber,
                                                )}
                                            />
                                            <SimpleSelectField
                                                name={`${name}.rooms`}
                                                label='Кол-во комнат'
                                                validators={composeValidators(
                                                    required,
                                                )}
                                                opts={Array.from(
                                                    { length: 12 },
                                                    (_, i) => {
                                                        if (i == 0) {
                                                            return {
                                                                title: '',
                                                                value: '',
                                                            };
                                                        }
                                                        return {
                                                            title:
                                                                i == 1
                                                                    ? 'Студия'
                                                                    : `${i - 1}`,
                                                            value:
                                                                i == 1
                                                                    ? 'studio'
                                                                    : `${i - 1}`,
                                                        };
                                                    },
                                                )}
                                            />
                                            <InputField
                                                name={`${name}.ceiling`}
                                                label='Высота потолков'
                                                onChange={(e) =>
                                                    form.mutators.setDecimalValue(
                                                        `${name}.ceiling`,
                                                        e.target.value,
                                                    )
                                                }
                                                initialValue={
                                                    fields.value[index - 1]
                                                        ?.ceiling
                                                }
                                                validators={composeValidators(
                                                    required,
                                                )}
                                            />
                                            <Form.Group className='mb-3'>
                                                <label className='btn btn-sm btn-primary'>
                                                    <FileField
                                                        name={`${name}.layout`}
                                                        onChange={(e) =>
                                                            changeHandler(
                                                                e,
                                                                form,
                                                                index,
                                                            )
                                                        }
                                                        multiple
                                                    />{' '}
                                                    Загрузить фото
                                                </label>
                                            </Form.Group>
                                            {form.getState().values.flats[index]
                                                .layoutImage && (
                                                <Image
                                                    src={
                                                        form.getState().values
                                                            .flats[index]
                                                            .layoutImage
                                                    }
                                                    width={160}
                                                    height={90}
                                                    thumbnail
                                                />
                                            )}
                                            <Field name={`${name}.layoutName`}>
                                                {({ input }) => (
                                                    <Form.Control
                                                        {...input}
                                                        readOnly
                                                    />
                                                )}
                                            </Field>
                                            <InputField
                                                name={`${name}.price`}
                                                label='Цена'
                                                onChange={(e) =>
                                                    form.mutators.setPriceValue(
                                                        `${name}.price`,
                                                        e.target.value,
                                                    )
                                                }
                                                validators={composeValidators(
                                                    required,
                                                )}
                                            />
                                            <InputField
                                                name={`${name}.spaceTotal`}
                                                label='Общая площадь'
                                                onChange={(e) =>
                                                    form.mutators.setDecimalValue(
                                                        `${name}.spaceTotal`,
                                                        e.target.value,
                                                    )
                                                }
                                                validators={composeValidators(
                                                    required,
                                                    space,
                                                )}
                                            />
                                            <InputField
                                                name={`${name}.spaceLiving`}
                                                label='Жилая площадь'
                                                onChange={(e) =>
                                                    form.mutators.setDecimalValue(
                                                        `${name}.spaceLiving`,
                                                        e.target.value,
                                                    )
                                                }
                                                validators={composeValidators(
                                                    required,
                                                    space,
                                                )}
                                            />
                                            <InputField
                                                name={`${name}.spaceKitchen`}
                                                label='Площадь кухни'
                                                onChange={(e) =>
                                                    form.mutators.setDecimalValue(
                                                        `${name}.spaceKitchen`,
                                                        e.target.value,
                                                    )
                                                }
                                                validators={composeValidators(
                                                    required,
                                                    space,
                                                )}
                                            />
                                            <SimpleSelectFieldV2
                                                name={`${name}.levels`}
                                                label='Уровней'
                                            >
                                                <option
                                                    value='1'
                                                    selected={true}
                                                >
                                                    1
                                                </option>
                                                <option value='2'>2</option>
                                                <option value='3'>3</option>
                                            </SimpleSelectFieldV2>
                                            <SimpleSelectFieldV2
                                                name={`${name}.stainedGlass`}
                                                label='Витражные окна'
                                            >
                                                <option></option>
                                                <option value='1'>Да</option>
                                                <option value='0'>Нет</option>
                                            </SimpleSelectFieldV2>
                                            <SimpleSelectFieldV2
                                                name={`${name}.underfloorHeatingSystem`}
                                                label='Теплый пол'
                                            >
                                                <option></option>
                                                <option value='1'>Да</option>
                                                <option value='0'>Нет</option>
                                            </SimpleSelectFieldV2>
                                            <SimpleSelectFieldV2
                                                name={`${name}.kitchenLiving`}
                                                label='Кухня - гостиная'
                                            >
                                                <option></option>
                                                <option value='1'>Да</option>
                                                <option value='0'>Нет</option>
                                            </SimpleSelectFieldV2>
                                            <SimpleSelectFieldV2
                                                name={`${name}.masterBedroom`}
                                                label='Мастер - спальня'
                                            >
                                                <option></option>
                                                <option value='1'>Да</option>
                                                <option value='0'>Нет</option>
                                            </SimpleSelectFieldV2>
                                            <SimpleSelectFieldV2
                                                name={`${name}.highflat`}
                                                label='Хайфлет'
                                            >
                                                <option></option>
                                                <option value='1'>Да</option>
                                                <option value='0'>Нет</option>
                                            </SimpleSelectFieldV2>
                                            <SimpleSelectFieldV2
                                                name={`${name}.terrace`}
                                                label='Наличие террасы'
                                            >
                                                <option></option>
                                                <option value='1'>Да</option>
                                                <option value='0'>Нет</option>
                                            </SimpleSelectFieldV2>
                                            <SimpleSelectFieldV2
                                                name={`${name}.viewApartment`}
                                                label='Видовая квартира'
                                            >
                                                <option></option>
                                                <option value='1'>Да</option>
                                                <option value='0'>Нет</option>
                                            </SimpleSelectFieldV2>
                                            <SimpleSelectFieldV2
                                                name={`${name}.priceDiscount`}
                                                label='Цена со скидкой'
                                            >
                                                <option></option>
                                                <option value='1'>Да</option>
                                                <option value='0'>Нет</option>
                                            </SimpleSelectFieldV2>
                                        </Col>
                                    ))
                                }
                            </FieldArray>
                        </Row>
                    </form>
                )}
            />
        </>
    );
}
