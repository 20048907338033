import Api from '~/src/helpers/http-common';
import { IUser } from '~/src/service/user';

export const login = async (
    phone: string | number,
    password: string,
): Promise<IUser> => {
    return (await Api.post('/auth/login', { phone, password })) as IUser;
};

export const registration = async (
    params: IRegistrationParams,
): Promise<IUser> => {
    return (await Api.post('/auth/registration', params)) as IUser;
};

export const logout = async (): Promise<void> => {
    await Api.post('/auth/logout');
};

export const getMe = async (): Promise<IUser> => {
    return await Api.get('/auth/getme');
};

export const forgotPassword = async (phone: string): Promise<any> => {
    return await Api.post('/user/forgot-password', { phone: phone });
};

export const confirmCode = async (
    phone: string,
    confirmCode: string,
): Promise<any> => {
    return await Api.post('/user/confirm-code', {
        phone: phone,
        confirmCode: confirmCode,
    });
};

export interface IRegistrationParams {
    phone: number | string;
    firstname: string;
    role?: 'user' | 'agent';
    inn?: string;
}
