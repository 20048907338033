import MainPopup from '../ModalUI/MainPopup';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';

type TProps = {
    latitude: number;
    longitude: number;
    zoom?: number;
    isOpened: boolean;
    onClose: () => void;
};

const MapPopupWithMarker = ({
    latitude,
    longitude,
    isOpened,
    onClose,
    zoom = 10,
}: TProps) => {
    const rnd = Math.random() * 1000;

    const setMap = (map) => {
        const resizeObserver = new ResizeObserver(() => {
            map.invalidateSize();
        });

        const container = document.getElementById(`map-container-${rnd}`);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        resizeObserver.observe(container!);
    };

    return (
        <MainPopup isOpened={isOpened} onClose={onClose} fullwidth={true}>
            <MapContainer
                center={[latitude, longitude]}
                zoom={zoom}
                maxZoom={18}
                id={`map-container-${rnd}`}
                whenCreated={setMap}
                attributionControl={false}
            >
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url='http://{s}.maps.2gis.com/tiles?x={x}&y={y}&z={z}'
                    subdomains={['tile0', 'tile1', 'tile2', 'tile3']}
                />
                <Marker position={[latitude, longitude]}></Marker>
            </MapContainer>
        </MainPopup>
    );
};

export { MapPopupWithMarker };
