import { useNavigate } from 'react-router-dom';
import React from 'react';
import arrayMutators from 'final-form-arrays';
import { selectApp } from '~/src/store/appSlice';
import { Form as FinalForm } from 'react-final-form';
import { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ShowErrorsBadge from '../Common/ShowErrorBadge';
import UpdateAdditionalView from './UpdateAdditionalView';
import UpdateDescriptionView from './UpdateDescriptionView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup, Navbar, Tab, Nav } from 'react-bootstrap';
import createInitialFormValues from '~/src/helpers/createInitialFormValues';
import UpdatePhotosView from '~/src/components/Common/Filestore/Photos/UpdatePhotosView';
import { ICottageVillage } from '~/src/service/cottageVillage/cottage.village.service';
import {
    deleteFile,
    selectCV,
    setCurrentIndex,
    update,
    uploadPhoto,
} from '~/src/store/cottage.village/cottage.village.store';
import UpdateContactView from './UpdateContactView';
import { getUserByPhone } from '~/src/service/user';

export default function UpdateForm(): JSX.Element {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [item, setItem] = useState<ICottageVillage>(null);
    // const [uploadFiles, setUploadFiles] = useState(null);
    const [holdSubmit, setHoldSubmit] = useState(false);
    const [preview, setPreview] = useState([]);

    const { item: data, itemPhotos, currentIndex } = useSelector(selectCV);
    const { loading } = useSelector(selectApp);

    useEffect(() => {
        setItem(data);
    }, [data]);

    const onSubmit = (values: Record<string, any>) => {
        dispatch(update(values.id, values));
    };

    const onUploadProgress = (event) => {
        const percentCompleted = Math.round((event.loaded * 100) / event.total);
        if (percentCompleted === 100) {
            setPreview(null);
        }
    };

    const setUploadFiles = (compressedFiles: File[] | FileList) => {
        dispatch(
            uploadPhoto({
                files: compressedFiles,
                id: item.id,
                onUploadProgress,
            }),
        );
    };

    const findUserByPhone = async (phone: number) => {
        const _res = await getUserByPhone(phone);

        return _res;
    };

    return (
        <Suspense fallback={<div>Loading...</div>}>
            {loading || item === null ? (
                <>Загрузка...</>
            ) : (
                <FinalForm
                    onSubmit={onSubmit}
                    initialValues={{
                        ...createInitialFormValues(item),
                        landSalePriceFrom: new Intl.NumberFormat(
                            'ru-RU',
                        ).format(+item?.landSalePriceFrom),
                        landSalePriceTo: new Intl.NumberFormat('ru-RU').format(
                            +item?.landSalePriceTo,
                        ),
                        address: item.addressDetail?.formattedAddress,
                        userUidName: `${item.owner?.lastname || ''} ${item.owner?.firstname || ''} ${item.owner?.middlename || ''}`,
                        userUidPhone: item.owner?.phone || '',
                        landPlotsForSale:
                            item?.landSaleAreaFrom > 0 ||
                            item?.landSaleAreaTo > 0 ||
                            item?.landSalePriceFrom > 0 ||
                            item?.landSalePriceTo > 0,
                        commissionReward: new Intl.NumberFormat('ru-RU').format(
                            +item?.commissionReward,
                        ),
                        initialFee: new Intl.NumberFormat('ru-RU').format(
                            +item?.initialFee,
                        ),
                        landSaleDiscount: new Intl.NumberFormat('ru-RU').format(
                            +item?.landSaleDiscount,
                        ),
                    }}
                    mutators={{
                        // expect (field, value) args from the mutator
                        setValue: ([field, value], state, { changeValue }) => {
                            changeValue(state, field, () => value);
                        },
                        setPriceValue: (
                            [field, value],
                            state,
                            { changeValue },
                        ) => {
                            value = value.replaceAll(/\D/g, '');
                            changeValue(state, field, () => new Intl.NumberFormat('ru-RU').format(value));
                        },
                        setDecimalValue: (
                            [field, value],
                            state,
                            { changeValue },
                        ) => {
                            value = value.replaceAll(',', '.');
                            changeValue(state, field, () => value);
                        },
                        setLandPlotsForSale: (
                            [field, value],
                            state,
                            { changeValue },
                        ) => {
                            changeValue(state, field, () => value);
                            if (value === false) {
                                changeValue(
                                    state,
                                    'landSaleAreaFrom',
                                    () => null,
                                );
                                changeValue(
                                    state,
                                    'landSaleAreaTo',
                                    () => null,
                                );
                                changeValue(
                                    state,
                                    'landSalePriceFrom',
                                    () => null,
                                );
                                changeValue(
                                    state,
                                    'landSalePriceTo',
                                    () => null,
                                );
                                changeValue(
                                    state,
                                    'landSaleDiscount',
                                    () => null,
                                );
                            }
                        },
                        setUserUidValue: async (
                            [field, value],
                            state,
                            { changeValue },
                        ) => {
                            changeValue(state, field, () => value);
                            if (value.length == 10) {
                                setHoldSubmit(true);
                                const founded = await findUserByPhone(value);

                                if (founded && founded.success) {
                                    changeValue(
                                        state,
                                        'userUid',
                                        () => founded.data.uid,
                                    );
                                    changeValue(
                                        state,
                                        'userUidName',
                                        () => founded.data.fullname,
                                    );
                                    // -- setDirectorName(founded.fullname);
                                }
                                setHoldSubmit(false);
                            } else {
                                changeValue(state, 'userUid', () => null);
                                changeValue(state, 'userUidName', () => '');
                            }
                        },
                        ...arrayMutators,
                    }}
                    validate={(values) => {
                        const errors: any = {};
                        // if (values.keyBenefits && values.keyBenefits.length > 3) {
                        //     errors.keyBenefits = 'Вы должны выбрать не более 3 приимуществ';
                        // }
                        // if (!values.keyBenefits || Object.keys(values.keyBenefits).length == 0) {
                        //     errors.keyBenefits = 'Выберите ключевые приимущества';
                        // }
                        // if (!values.benefitsWhenPurchasing || Object.keys(values.benefitsWhenPurchasing).length == 0) {
                        //     errors.benefitsWhenPurchasing = 'Выберите выгоды при покупке';
                        // }
                        return errors;
                    }}
                    render={({ handleSubmit, submitting, valid }) => (
                        <form onSubmit={handleSubmit}>
                            <Navbar
                                className='justify-content-between'
                                bg='light'
                                expand='lg'
                                variant='light'
                            >
                                <ButtonGroup>
                                    <Button
                                        size='sm'
                                        onClick={() => navigate(-1)}
                                        disabled={submitting}
                                    >
                                        <FontAwesomeIcon
                                            icon={['fas', 'fast-backward']}
                                        />{' '}
                                        Назад
                                    </Button>
                                    <Button
                                        size='sm'
                                        type='submit'
                                        className='btn btn-sm btn-success'
                                        disabled={
                                            submitting || !valid || holdSubmit
                                        }
                                    >
                                        <FontAwesomeIcon
                                            icon={['fas', 'save']}
                                        />{' '}
                                        Сохранить
                                    </Button>
                                </ButtonGroup>
                            </Navbar>
                            <Tab.Container defaultActiveKey='description'>
                                <Nav justify variant='tabs'>
                                    <Nav.Item>
                                        <Nav.Link eventKey='description'>
                                            <span>Описание </span>
                                            <ShowErrorsBadge fields={[]} />
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey='photos'>
                                            <span>Фото </span>
                                            <ShowErrorsBadge fields={[]} />
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey='contact'>
                                            Контакты
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey='additional'>
                                            <span>Параметры </span>
                                            <ShowErrorsBadge fields={[]} />
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content className='container-fluid mt-2 p-0'>
                                    <Tab.Pane eventKey='description'>
                                        <UpdateDescriptionView />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey='photos'>
                                        <UpdatePhotosView
                                            filesFieldName='photosUpload'
                                            photos={itemPhotos}
                                            preview={preview}
                                            setPreview={setPreview}
                                            setUploadFiles={setUploadFiles}
                                            currentIndex={currentIndex}
                                            setCurrentIndex={setCurrentIndex}
                                            onDelete={(name) =>
                                                dispatch(
                                                    deleteFile(item.id, name),
                                                )
                                            }
                                        />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey='contact'>
                                        <UpdateContactView />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey='additional'>
                                        <UpdateAdditionalView />
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                            <Button
                                size='sm'
                                type='submit'
                                className='btn btn-sm btn-success'
                                disabled={submitting || !valid || holdSubmit}
                            >
                                <FontAwesomeIcon icon={['fas', 'save']} />{' '}
                                Сохранить
                            </Button>
                        </form>
                    )}
                />
            )}
        </Suspense>
    );
}
