import { useDispatch } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
import { registration } from '~/src/store/authSlice';
import { Form as FinalForm, Field } from 'react-final-form';

import './index.css';
import { createAgencyRequest } from '~/src/service/agency';
import InputField from '~/src/components/Common/Form/InputField';
import { composeValidators, isPhone, required } from '~/src/helpers/validators';

const Registration = (): JSX.Element => {
    const dispatch = useDispatch();

    const registerAgent = (values) => {
        console.log('registerAgent');
        dispatch(registration(values));
    };

    const registerAgency = async (values) => {
        console.log('registerAgency');
        await createAgencyRequest(values);
    };

    const onSubmit = async (values) => {
        console.log('onSubmit');

        if (values['role'] === 'agency') {
            console.log('register agency');
            await registerAgency(values);
        } else {
            console.log('register agents');
            registerAgent(values);
        }
    };

    return (
        <div className='container-fluid login-body'>
            <div className='d-flex justify-content-center'>
                <div className='login-form p-3'>
                    <FinalForm
                        onSubmit={onSubmit}
                        subscription={{
                            submitting: true,
                            pristine: true,
                            valid: true,
                            submitSucceeded: true,
                            values: true,
                        }}
                        initialValues={{
                            role: 'user',
                        }}
                        mutators={{
                            setValue: (
                                [field, value],
                                state,
                                { changeValue },
                            ) => {
                                console.info(`SET VALUE ${field}`);
                                changeValue(state, field, () => value);
                            },
                        }}
                        validate={(values) => {
                            const errors: any = {};
                            return errors;
                        }}
                        render={({
                            handleSubmit,
                            submitting,
                            valid,
                            values,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <Form.Group className='mb-3'>
                                    <Form.Group>
                                        <Form.Label>
                                            <Field
                                                name='role'
                                                component='input'
                                                type='radio'
                                                value='user'
                                            />{' '}
                                            Физическое лицо
                                        </Form.Label>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>
                                            <Field
                                                name='role'
                                                component='input'
                                                type='radio'
                                                value='agent'
                                            />{' '}
                                            Агент по недвижимости
                                        </Form.Label>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>
                                            <Field
                                                name='role'
                                                component='input'
                                                type='radio'
                                                value='agency'
                                            />{' '}
                                            Хочу добавить компанию
                                        </Form.Label>
                                    </Form.Group>
                                </Form.Group>
                                <InputField
                                    label={'Имя'}
                                    name='firstname'
                                    validators={composeValidators(required)}
                                />
                                <InputField
                                    label={'Телефон'}
                                    name='phone'
                                    validators={composeValidators(
                                        required,
                                        isPhone,
                                    )}
                                />
                                {values.role !== 'user' && (
                                    <InputField
                                        label={'Название агентства или ИНН'}
                                        name='inn'
                                        validators={composeValidators(required)}
                                    />
                                )}
                                <div className='d-grid'>
                                    <Button
                                        type='submit'
                                        variant='primary'
                                        disabled={!valid || submitting}
                                    >
                                        {values.role === 'agency'
                                            ? 'Отправить заявку'
                                            : 'Зарегистрироваться'}
                                    </Button>
                                </div>
                            </form>
                        )}
                    />
                </div>
            </div>
        </div>
    );
};

export default Registration;
