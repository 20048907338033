import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectUsers } from '~/src/store/usersSlice';
import UserPermissionListRow from './UserPermissionListRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup, Navbar, Table } from 'react-bootstrap';

export default function UserPermission(): JSX.Element {
    const navigate = useNavigate();

    const { unloadedPermissions: items } = useSelector(selectUsers);

    return (
        <>
            <Navbar
                className='justify-content-between'
                bg='light'
                expand='lg'
                variant='light'
            >
                <ButtonGroup>
                    <Button
                        size='sm'
                        variant='primary'
                        onClick={() => navigate(-1)}
                    >
                        <FontAwesomeIcon icon={['fas', 'fast-backward']} />{' '}
                        Назад
                    </Button>
                </ButtonGroup>
            </Navbar>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <td>#</td>
                        <td>Название</td>
                        <td>Код</td>
                        <td>Группа</td>
                        <td>Управление</td>
                    </tr>
                </thead>
                <tbody>
                    {items && items.length > 0
                        ? items.map((item) => (
                              <UserPermissionListRow
                                  key={item.id}
                                  item={item}
                              />
                          ))
                        : null}
                </tbody>
            </Table>
        </>
    );
}
