import dateFormat from 'dateformat';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import React, { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import HaveAccess from '../../Common/HaveAccess';
import MainPopup from '../../Common/ModalUI/MainPopup';
import phoneFormatter from '~/src/helpers/phoneFormatter';
import RSUBListPhotos from '../../Realty/Suburban/IList/RSUBListPhotos';
import { IRSUBModel } from '~/src/store/realtySuburban/realtySuburbanSlice';
import { Badge, Button, ButtonGroup } from 'react-bootstrap';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import {
    unverify,
    verify,
} from '~/src/store/realtySuburban/realtySuburbanSlice.moderate';
import ButtonWithModerateReject from '../../Common/Button/ButtonWithModerateReject';
import ConfirmButton from '~/src/components/Common/ModalUI/ButtonWithConfirm';
import IListUpdateInfo from '../../Realty/Suburban/IList/IListUpdateInfo';
import IListPriceRise from '../../Realty/Suburban/IList/IListPriceRise';

const IListRSUBM: FC<TListProps> = ({ item }) => {
    const dispatch = useDispatch();

    const [showOnMap, setShowOnMap] = useState(false);

    const handleClickAddress = () => setShowOnMap(true);

    const setMap = (map) => {
        const resizeObserver = new ResizeObserver(() => {
            map.invalidateSize();
        });

        const container = document.getElementById(`map-container-${item.id}`);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        resizeObserver.observe(container!);
    };

    const handleConfirmModerate = () => {
        dispatch(verify(item.id));
    };

    return (
        <React.Fragment>
            <tr>
                <td>
                    <Link to={`/realty-suburban/${item.id}`}>{item.id}</Link>
                    {item.verified && (
                        <React.Fragment>
                            <br />
                            <FontAwesomeIcon
                                icon={['fas', 'check-circle']}
                                style={{ color: 'blue' }}
                                title='Подтвержденный объект'
                            />
                        </React.Fragment>
                    )}
                    <br />
                    {item.fromLegalEntity && (
                        <React.Fragment>
                            <br />
                            <Badge className='bg-success'>От юр. лица</Badge>
                        </React.Fragment>
                    )}
                    {/* {!item.isFullfied && <FontAwesomeIcon
                        icon={['fas', 'exclamation-triangle']}
                        title={item.fullfiedStatus.map(field => {
                            return `${field.key}: ${field.value === true ? 'Да' : 'Нет'}`;
                        }).join('\n')}
                    />} */}
                </td>
                <td>
                    <RSUBListPhotos photos={item.photos} />
                    <p>
                        Внесен: {dateFormat(item.createdAt, 'dd.mm.yyyy')}
                        <br />
                        <IListUpdateInfo item={item} />
                        {/* <PriceChangedAgo {...item.priceChangedAgo} /> */}
                        {/* <DaysOrHoursForRemove {...item} /> */}В базе еще{' '}
                        {item.freshAgo > 1
                            ? `${item.freshAgo} дней`
                            : `${item.freshAgoH} часов`}
                    </p>
                    {item.sold && <Badge bg='danger'>В архиве</Badge>}
                </td>
                <td>
                    <p>
                        <strong>
                            <FormattedMessage
                                id={`realty_suburban_type_${item?.type}`}
                            />
                        </strong>
                    </p>
                    <p>
                        <strong>
                            <FormattedMessage
                                id={`realty_suburban_landAppointment_${item?.landAppointment}`}
                            />
                        </strong>
                    </p>
                    <p>
                        <strong>Адрес</strong>
                        <br />
                        <Button
                            size='sm'
                            type='button'
                            variant='link'
                            onClick={handleClickAddress}
                        >
                            {item.addressDetail?.formattedAddress}
                        </Button>
                    </p>
                </td>
                <td>
                    {item.area && (
                        <p>
                            <strong>Площадь участка: </strong>
                            {item.area}
                        </p>
                    )}
                    {item.houseSpaceTotal && (
                        <p>
                            <strong>Площадь дома: </strong>
                            {item.houseSpaceTotal}
                        </p>
                    )}
                    {item.gas && (
                        <p>
                            <strong>Газ</strong>
                        </p>
                    )}
                    {item.electricity && (
                        <p>
                            <strong>Электричество</strong>
                        </p>
                    )}
                </td>
                <td>
                    {item.houseMaterial && (
                        <p>
                            <strong>Материал дома</strong>
                            <br />
                            <FormattedMessage
                                id={`house_material_${item.houseMaterial}`}
                            />
                        </p>
                    )}
                    {item.houseStoreys && (
                        <p>
                            <strong>Этажей в доме</strong>
                            <br />
                            {item.houseStoreys}
                        </p>
                    )}
                </td>
                <td>
                    <p>
                        {!item?.megasale ? (
                            <>
                                <strong>Цена</strong>
                                <br />
                                <>
                                    {new Intl.NumberFormat('ru-RU').format(
                                        +item.price,
                                    )}
                                </>{' '}
                                <IListPriceRise item={item} />
                            </>
                        ) : (
                            <>
                                <strong style={{ color: 'red' }}>Цена</strong>
                                <br />
                                <span style={{ color: 'red' }}>
                                    {new Intl.NumberFormat('ru-RU').format(
                                        +item?.price,
                                    )}
                                </span>
                                <br></br>
                                <strong style={{ color: 'green' }}>
                                    Цена для распродажи
                                </strong>
                                <br />
                                <span style={{ color: 'green' }}>
                                    {new Intl.NumberFormat('ru-RU').format(
                                        +item?.megasale?.price,
                                    )}
                                </span>
                                <br></br>
                                <br></br>
                                <strong>Скидка</strong>
                                <br />
                                <span>
                                    {new Intl.NumberFormat('ru-RU').format(
                                        +item?.price - +item?.megasale?.price,
                                    )}
                                </span>
                                <br></br>
                            </>
                        )}
                        <br></br>
                        {item.type !== 'land'
                            ? item.price &&
                            item?.area &&
                            item?.houseSpaceTotal && (
                                <>
                                    <strong>
                                        Цена за м<sup>2</sup> дома
                                    </strong>
                                    <br></br>
                                    <span>
                                        {new Intl.NumberFormat(
                                            'ru-RU',
                                        ).format(
                                            Math.floor(
                                                +item.price /
                                                +item.houseSpaceTotal,
                                            ),
                                        )}
                                    </span>
                                    <br></br>
                                    <strong>Цена за сотку участка</strong>
                                    <br></br>
                                    <span>
                                        {new Intl.NumberFormat(
                                            'ru-RU',
                                        ).format(
                                            Math.floor(
                                                +item.price / +item.area,
                                            ),
                                        )}
                                    </span>
                                </>
                            )
                            : item.price &&
                            item?.area && (
                                <>
                                    <strong>Цена за сотку участка</strong>
                                    <br></br>
                                    <span>
                                        {new Intl.NumberFormat(
                                            'ru-RU',
                                        ).format(
                                            Math.floor(
                                                +item.price / +item.area,
                                            ),
                                        )}
                                    </span>
                                </>
                            )}
                    </p>
                </td>
                <td>
                    {item.isMy ? (
                        <React.Fragment>
                            {item.sellerName && (
                                <p>
                                    <strong>Продавец</strong>
                                    <br />
                                    {item.sellerName}
                                </p>
                            )}

                            {item.sellerPhone && (
                                <p>
                                    <strong>Телефон</strong>
                                    <br />
                                    {phoneFormatter(`${item.sellerPhone}`)}
                                </p>
                            )}
                        </React.Fragment>
                    ) : (
                        item.agentInfo && (
                            <React.Fragment>
                                {item.agentInfo?.agencyInfo?.agency && (
                                    <p>
                                        <strong>Агентство</strong>
                                        <br></br>
                                        <span>
                                            {
                                                item.agentInfo?.agencyInfo
                                                    ?.agency.title
                                            }
                                        </span>
                                    </p>
                                )}
                                <p>
                                    <strong>
                                        {item?.agentInfo?.role != 'agent'
                                            ? 'Собственник'
                                            : 'Агент'}
                                    </strong>
                                    <br />
                                    {item.agentInfo?.fullname}
                                </p>

                                <p>
                                    <strong>Телефон</strong>
                                    <br />
                                    {phoneFormatter(`${item.agentInfo?.phone}`)}
                                </p>
                            </React.Fragment>
                        )
                    )}
                </td>
                <td>
                    <ButtonGroup vertical={true} className='w-100'>
                        <Link
                            to={`/realty-suburban/card/${item.id}`}
                            className='btn btn-sm btn-secondary'
                            title='Карточка'
                        >
                            Карточка
                        </Link>
                        <HaveAccess access='realty.secondary.moderate'>
                            <ConfirmButton
                                variant='success'
                                label='Подтвердить'
                                modalText={`Объект ${item.id} окажется в общей базе, подтвердить?`}
                                onClick={handleConfirmModerate}
                            >
                                Подтвердить
                            </ConfirmButton>
                            <ButtonWithModerateReject
                                id={item.id}
                                unverify={unverify}
                            />
                        </HaveAccess>
                    </ButtonGroup>
                </td>
            </tr>
            <MainPopup
                isOpened={showOnMap}
                onClose={() => setShowOnMap(false)}
                fullwidth={true}
            >
                <MapContainer
                    center={[
                        +item.addressDetail?.latitude,
                        +item.addressDetail?.longitude,
                    ]}
                    zoom={15}
                    maxZoom={18}
                    id={`map-container-${item.id}`}
                    whenCreated={setMap}
                    attributionControl={false}
                >
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url='http://{s}.maps.2gis.com/tiles?x={x}&y={y}&z={z}'
                        subdomains={['tile0', 'tile1', 'tile2', 'tile3']}
                    />
                    <Marker
                        position={[
                            +item.addressDetail?.latitude,
                            +item.addressDetail?.longitude,
                        ]}
                    ></Marker>
                </MapContainer>
            </MainPopup>
        </React.Fragment>
    );
};

type TListProps = {
    item: IRSUBModel;
};

export default IListRSUBM;
