import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IXmlFeed } from '~/src/service/xml.feed';
import download from '~/src/helpers/download';
import { FILES_BASE } from '~/src/constants/urls';
import HaveAccess from '../Common/HaveAccess';
import * as APConstants from '~/src/constants/agency.permission';
import ButtonWithConfirm from '../Common/ModalUI/ButtonWithConfirm';
import { useDispatch } from 'react-redux';
import { remove, clearFeed, build } from '~/src/store/xml.feed';

import avitoLogo from '~/src/assets/img/avito.png';
import cianLogo from '~/src/assets/img/cian.png';
import domclickLogo from '~/src/assets/img/domclick.png';
import yandexLogo from '~/src/assets/img/yandex.png';

const ListRow: FC<{ item: IXmlFeed }> = ({ item }) => {
    const dispatch = useDispatch();

    const handleDelete = () => {
        console.log('handleDelete');
        dispatch(remove(item.id));
    };

    const handleClearFeed = () => {
        dispatch(clearFeed(item.id));
    };

    const handleRebuildFeed = () => {
        dispatch(build(item.id));
    };

    const renderLogo = () => {
        switch (item.type) {
            case 'avito':
                return (
                    <p>
                        <img
                            src={avitoLogo}
                            width={16}
                            height={16}
                            title={item.title}
                        />
                    </p>
                );
            case 'cian':
                return (
                    <p>
                        <img
                            src={cianLogo}
                            width={16}
                            height={16}
                            title={item.title}
                        />
                    </p>
                );
            case 'domclick':
                return (
                    <p>
                        <img
                            src={domclickLogo}
                            width={16}
                            height={16}
                            title={item.title}
                        />
                    </p>
                );
            case 'yandex':
                return (
                    <p>
                        <img
                            src={yandexLogo}
                            width={16}
                            height={16}
                            title={item.title}
                        />
                    </p>
                );
        }
    };

    return (
        <React.Fragment>
            <tr>
                <td>
                    <p>{item.title}</p>
                    <p>{renderLogo()}</p>
                </td>
                <td>
                    <p>{item.totalUnload}</p>
                    {item.filename && (
                        <ButtonGroup>
                            <Button
                                size='sm'
                                onClick={() =>
                                    download(
                                        `${FILES_BASE}${item.filename}`,
                                        `${item.filename}`,
                                    )
                                }
                            >
                                <FontAwesomeIcon icon={['fas', 'save']} />{' '}
                                Скачать фид
                            </Button>
                            <Button
                                size='sm'
                                onClick={() =>
                                    navigator.clipboard.writeText(
                                        `${FILES_BASE}${item.filename}`,
                                    )
                                }
                            >
                                <FontAwesomeIcon icon={['fas', 'copy']} />{' '}
                                Скопировать ссылку на фид
                            </Button>
                        </ButtonGroup>
                    )}
                </td>
                <td>
                    <ButtonGroup vertical className='w-100'>
                        <Link
                            className='btn btn-sm btn-warning'
                            to={`/xml-feed/update/${item.id}`}
                        >
                            <FontAwesomeIcon icon={['fas', 'pencil-alt']} />{' '}
                            Редактировать
                        </Link>
                        <ButtonWithConfirm
                            label='Убрать все с автовыгрузки'
                            modalText='Вы уверены?'
                            onClick={handleClearFeed}
                            variant='warning'
                        />
                        <ButtonWithConfirm
                            label='Пересобрать фид'
                            modalText='Пересобрать фид принудительно'
                            onClick={handleRebuildFeed}
                            variant='success'
                        />
                        <HaveAccess
                            access='xml.feed.delete'
                            agencyId={item.agencyId}
                            agencyAccess={APConstants.XML_FEED_AGENCY_DELETE}
                            roles='grand'
                        >
                            <ButtonWithConfirm
                                label='Удалить фид'
                                modalText='Точно?'
                                onClick={handleDelete}
                                variant='danger'
                            />
                        </HaveAccess>
                    </ButtonGroup>
                </td>
            </tr>
        </React.Fragment>
    );
};

export default ListRow;
