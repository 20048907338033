import queryString from 'query-string';
import { IFilestore } from '../service/filestore';
import * as api from '~/src/service/housing.estate';
import { createSlice, Slice } from '@reduxjs/toolkit';
import { IResponseListData } from '../interfaces/common';
import { IHousingEstate, UploadProps } from '~/src/service/housing.estate';
import { redirect, setLoading as setAppLoading } from './appSlice';
import type { TAppDispatch, TState, TAnyAction } from '~/src/store';
import { IHousingEstateHousePorch } from '../service/housing.estate.house.porch';
import { IHousingEstateHouse } from '../service/housing.estate.house';
import { successNotify } from '../helpers/createNotify';

export const sliceName = 'housingEstate';

interface IHousingEstateSlice {
    items: IResponseListData<any>;
    item?: IHousingEstate;
    itemPhotos?: IFilestore[];
    chess?: IHousingEstate;
    infoHousingEstate?: IHousingEstate;
    infoHousingEstateHouse?: IHousingEstateHouse;
    activePorch?: IHousingEstateHousePorch;
    chessPorch?: IHousingEstateHousePorch;
    search?: any;
    navPanel?: any;
    errors?: any;
    navLink: string;
    loading: boolean;
    generateData?: Record<string, any>;
}

const initialState = {
    items: {},
    search: {
        sort: 'id_desc',
        limit: 25,
        page: 1,
    },
    navPanel: {},
    item: null,
    chess: null,
    infoHousingEstate: null,
    activePorch: null,
    chessPorch: null,
    loading: false,
    navLink: '/housing-estate?sort=fresh_at_desc&limit=25&page=1',
    baseLink: '/housing-estate',
    generateData: null,
};

const housingEstateSlice: Slice<IHousingEstateSlice> = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        setAll: (
            state: IHousingEstateSlice,
            action: TAnyAction<IResponseListData<IHousingEstate>>,
        ) => {
            state.items = action.payload;
        },
        set: (
            state: IHousingEstateSlice,
            action: TAnyAction<IHousingEstate>,
        ) => {
            state.item = action.payload;
            state.itemPhotos = state.item.photos;
        },
        setErrors: (state: IHousingEstateSlice, action: TAnyAction<any>) => {
            state.errors = action.payload;
        },
        setSearch: (state: IHousingEstateSlice, action: TAnyAction<any>) => {
            state.search = action.payload;
        },
        setNavLink: (
            state: IHousingEstateSlice,
            action: TAnyAction<string>,
        ) => {
            state.navLink = action.payload;
        },
        setChess: (
            state: IHousingEstateSlice,
            action: TAnyAction<IHousingEstate>,
        ) => {
            state.chess = action.payload;
        },
        setInfoHousingEstate: (
            state: IHousingEstateSlice,
            action: TAnyAction<IHousingEstate>,
        ) => {
            state.infoHousingEstate = action.payload;
        },
        setInfoHousingEstateHouse: (
            state: IHousingEstateSlice,
            action: TAnyAction<IHousingEstateHouse>,
        ) => {
            state.infoHousingEstateHouse = action.payload;
        },
        setChessPorch: (
            state: IHousingEstateSlice,
            action: TAnyAction<IHousingEstateHousePorch>,
        ) => {
            state.chessPorch = action.payload;
        },
        setGenerateData: (
            state: IHousingEstateSlice,
            action: TAnyAction<Record<string, any>>,
        ) => {
            state.generateData = action.payload;
        },
        setNavPanel: (
            state: IHousingEstateSlice,
            action: TAnyAction<number>,
        ) => {
            state.navPanel = action.payload;
        },
        setActivePorch: (
            state: IHousingEstateSlice,
            action: TAnyAction<IHousingEstateHousePorch>,
        ) => {
            state.activePorch = action.payload;
        },
        setLoadingChess: (
            state: IHousingEstateSlice,
            action: TAnyAction<boolean>,
        ) => {
            state.loading = action.payload;
        },
        setPhotos: (
            state: IHousingEstateSlice,
            action: TAnyAction<IFilestore[]>,
        ) => {
            state.itemPhotos = action.payload;
        },
    },
});

export const {
    setAll,
    set,
    setErrors,
    setSearch,
    setNavLink,
    setChess,
    setGenerateData,
    setInfoHousingEstate,
    setInfoHousingEstateHouse,
    setChessPorch,
    setNavPanel,
    setLoadingChess,
    setActivePorch,
    setPhotos,
} = housingEstateSlice.actions;

export const all = (queryParams: URLSearchParams = null) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(setAppLoading(true));
        const response = await api.all(queryParams);
        const obj = {};

        for (const key of queryParams.keys()) {
            if (queryParams.getAll(key).length > 1) {
                obj[key] = queryParams.getAll(key);
            } else {
                obj[key] = queryParams.get(key);
            }
        }

        dispatch(setSearch(obj));
        dispatch(
            setNavLink(`${initialState.baseLink}?${queryParams.toString()}`),
        );
        dispatch(setAll(response));
        dispatch(setAppLoading(false));
    };
};

export const create = (data: any, files?: any) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const item = await api.create(data, files);
            if (item && item.errors) {
                dispatch(setErrors(item.errors));
            } else if (item) {
                dispatch(redirect(getState().housingEstate.navLink));
                dispatch(redirect(`/housing-estate/update/${item.id}`));
            }
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const read = (id: number) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const data = await api.read(id);
            dispatch(set(data));
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const update = (
    id: number,
    data: any,
    files?: Record<string, FileList>,
) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const response = await api.update(id, data, files);
            if (response && response.success) {
                dispatch(set(response.data));
            } else {
                dispatch(setErrors(response.errors));
            }
        } catch (e) {
            console.error(e);
            dispatch(setErrors(e));
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const remove = (id: number) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const response = await api.remove(id);
            if (response && response.success) {
                dispatch(
                    setAll({
                        ...getState().housingEstate.items,
                        items: getState().housingEstate.items.items.filter(
                            (item: IHousingEstate) => item.id != id,
                        ),
                    }),
                );
            } else {
                dispatch(setErrors(response.errors));
            }
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const deleteFile = (id: number, name: string) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        const response = await api.deleteFile(id, name);
        if (response) {
            dispatch(
                set({
                    ...getState().housingEstate.item,
                    photos: getState().housingEstate.item.photos.filter(
                        (photo: IFilestore) => photo.name !== name,
                    ),
                }),
            );
        } else {
            dispatch(setErrors(response.errors));
        }
    };
};

export const getChess = (id: number) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const data = await api.chess(id);
            dispatch(setChess(data));
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const getInfoHousingEstate = (id: number) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            dispatch(setChessPorch(null));
            const data = await api.infoHousingEstate(id);
            if (data) {
                dispatch(setInfoHousingEstateHouse(data?.houses[0]));
                dispatch(
                    setNavPanel({
                        estate: id,
                        houses: data?.houses[0].id,
                        porches: data?.houses[0]?.porches[0]?.id,
                    }),
                );
                dispatch(setActivePorch(data?.houses[0]?.porches[0]));
                dispatch(setInfoHousingEstate(data));
                const res = await api.chessPorch(
                    data?.houses[0]?.porches[0]?.id,
                );
                dispatch(setChessPorch(res));
            }
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const getInfoHousingEstateHouse = (id: number) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            const data = await api.infoHousingEstateHouse(id);
            if (data) {
                dispatch(setInfoHousingEstateHouse(data));
            }
        } finally {
            dispatch(setAppLoading(false));
        }
    };
};

export const getChessPorch = (id: number) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        try {
            dispatch(setAppLoading(true));
            dispatch(setLoadingChess(true));
            const data = await api.chessPorch(id);
            dispatch(setChessPorch(data));
        } finally {
            dispatch(setLoadingChess(false));
            dispatch(setAppLoading(false));
        }
    };
};

export const switchEnabled = (id: number) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        const response = await api.switchEnabled(id);
        if (response && response.success) {
            dispatch(
                setAll({
                    ...getState().housingEstate.items,
                    items: getState().housingEstate.items.items.map(
                        (item: IHousingEstate) => {
                            if (item.id === id) {
                                return { ...item, isEnabled: !item.isEnabled };
                            }

                            return item;
                        },
                    ),
                }),
            );
            if (getState().housingEstate.item) {
                dispatch(
                    set({
                        ...getState().housingEstate.item,
                        isEnabled: !getState().housingEstate.item.isEnabled,
                    }),
                );
            }
        } else {
            dispatch(setErrors(response.errors));
        }
    };
};

export const fresh = (id: number) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        const response = await api.fresh(id);
        if (response && response.success) {
            dispatch(
                setAll({
                    ...getState().housingEstate.items,
                    items: getState().housingEstate.items.items.map(
                        (item: IHousingEstate) => {
                            if (item.id === id) {
                                return { ...item, freshAt: new Date() };
                            }

                            return item;
                        },
                    ),
                }),
            );
            if (getState().housingEstate.item) {
                dispatch(
                    set({
                        ...getState().housingEstate.item,
                        freshAt: new Date(),
                    }),
                );
            }
        } else {
            dispatch(setErrors(response.errors));
        }
    };
};

export const uploadPhoto = ({ id, files, onUploadProgress }: UploadProps) => {
    return async (
        dispatch: TAppDispatch,
        getState: () => TState,
    ): Promise<void> => {
        const response = await api.uploadPhotos(id, files, {
            onUploadProgress: onUploadProgress,
        });

        console.log('upload response');
        console.dir(response);

        if (response && Array.isArray(response)) {
            if (getState()[sliceName].item.id === id) {
                dispatch(
                    setPhotos([
                        ...getState()[sliceName].itemPhotos,
                        ...response,
                    ]),
                );
            }

            successNotify({
                message: `Загружено ${response.length} фото`,
                title: 'Успешно!',
            });
        }
    };
};

export const goTo =
    (params: Record<string, any>) =>
    (dispatch: TAppDispatch, getState: () => TState): void => {
        if (params.reset === true) {
            params = initialState.search;
        } else params = { ...getState().housingEstate.search, ...params };

        dispatch(
            redirect(
                `${initialState.baseLink}?${queryString.stringify(params, { skipNull: true })}`,
            ),
        );
    };

export const selectHE = (state: TState): IHousingEstateSlice =>
    state.housingEstate;

export default housingEstateSlice.reducer;
