import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '~/src/helpers/hooks';
import ListView from '~/src/components/Info/UnverifiedAgents';
import { getUnverified } from '~/src/store/info.agency.users.slice';
import {
    setActiveMenuKey,
    setHandleScrollPosition,
} from '~/src/store/appSlice';

const Page = (): JSX.Element => {
    const query = useQuery();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setActiveMenuKey('info-unverified-agents'));
    }, [dispatch]);

    useEffect(() => {
        dispatch(getUnverified(query));
    }, [dispatch, query]);

    useEffect(() => {
        dispatch(setHandleScrollPosition(true));
    }, [dispatch]);

    return <ListView />;
};

export default Page;
