import IList from './IList';
import { useCallback } from 'react';
import { useQuery } from '~/src/helpers/hooks';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '~/src/components/Common/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    selectRSM,
    goTo,
    all,
} from '~/src/store/realtySecondary/realty.secondary.moderate';
import {
    Button,
    ButtonGroup,
    Col,
    Form,
    Navbar,
    Row,
    Table,
} from 'react-bootstrap';

const RList = (): JSX.Element => {
    const query = useQuery();
    const dispatch = useDispatch();

    const { items, search } = useSelector(selectRSM);

    const sortHandler = useCallback(
        (e) => {
            dispatch(goTo({ sort: e.currentTarget.value }));
        },
        [dispatch],
    );

    const limitHandler = useCallback(
        (e) => {
            dispatch(goTo({ limit: e.currentTarget.value }));
        },
        [dispatch],
    );

    const handlePaginationClick = (page: number) => {
        dispatch(goTo({ page }));
    };

    return (
        <div>
            <Navbar
                className='justify-content-between'
                bg='light'
                expand='lg'
                variant='light'
            >
                <ButtonGroup>
                    <Button
                        size='sm'
                        variant='secondary'
                        onClick={() => dispatch(all(query))}
                    >
                        <FontAwesomeIcon icon={['fas', 'sync']} /> Обновить
                    </Button>
                </ButtonGroup>
            </Navbar>
            <div>
                <Row className='mb-2'>
                    <Col xs={4}>
                        <Form.Label>Сортировать</Form.Label>
                        <Form.Select
                            className='form-control'
                            size='sm'
                            value={search?.sort || 'price_asc'}
                            onChange={sortHandler}
                        >
                            <option value='id_asc'>ID &uarr;</option>
                            <option value='id_desc'>ID &darr;</option>
                            <option value='price_asc'>В начале дешевле</option>
                            <option value='price_desc'>В начале дороже</option>
                            <option value='created_at_desc'>
                                В начале новые
                            </option>
                            <option value='fresh_at_desc'>
                                По дате обновления
                            </option>
                        </Form.Select>
                    </Col>
                    <Col xs={4}>
                        <Form.Label>На странице</Form.Label>
                        <Form.Select
                            className='form-control'
                            size='sm'
                            value={search?.limit || 25}
                            onChange={limitHandler}
                        >
                            <option value={1}>1</option>
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={100}>100</option>
                        </Form.Select>
                    </Col>
                    <Col xs={4}>
                        <div className='mb-2'>
                            <Form.Label></Form.Label>
                        </div>
                        <Form.Label>
                            <strong>Всего объектов:</strong> {items.count}
                        </Form.Label>
                    </Col>
                </Row>
                {items && items.pagination?.have && (
                    <Pagination
                        pagination={items.pagination}
                        onClick={handlePaginationClick}
                    />
                )}
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <td>#</td>
                            <td>Фото</td>
                            <td>Адрес</td>
                            <td>Квартира</td>
                            <td>Дом</td>
                            <td>Цена</td>
                            <td>Продавец</td>
                            <td>Управление</td>
                        </tr>
                    </thead>
                    <tbody>
                        {items && items.items && items.items.length > 0
                            ? items.items.map((item) => (
                                  <IList key={item.id} item={item} />
                              ))
                            : null}
                    </tbody>
                </Table>
                {items && items.pagination?.have && (
                    <Pagination
                        pagination={items.pagination}
                        onClick={handlePaginationClick}
                    />
                )}
            </div>
        </div>
    );
};

export default RList;
