import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '~/src/helpers/hooks';
import { all } from '~/src/store/developer/developerSlice';
import ListView from '~/src/components/Developer/';
import { setActiveMenuKey, setHandleScrollPosition } from '~/src/store/appSlice';

const DevelopersPage = (): JSX.Element => {
    const query = useQuery();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setHandleScrollPosition(true));
    }, [dispatch]);

    useEffect(() => {
        dispatch(setActiveMenuKey('DEV'));
    }, [dispatch]);

    useEffect(() => {
        dispatch(all(query));
    }, [dispatch, query]);

    return <ListView />;
};

export default DevelopersPage;
