import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button, Image } from 'react-bootstrap';
import user from '~/src/assets/img/user.svg';
import OverlayingPopup from '~/src/components/Common/ModalUI/OverlayingPopup';
import React, { useRef } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { useDispatch } from 'react-redux';
import { updateUser } from '~/src/store/paSlice';

const ProfilePhoto = ({ photo, id }: {
    photo: string;
    id: number
}) => {
    const base = 'https://files.citidom.com/';

    const centre = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    const cropperRef = useRef<ReactCropperElement>(null);
    const [linkFiles, setLinkFiles] = useState('');
    const dispatch = useDispatch();

    const onCrop = () => {
        cropperRef.current?.cropper;
    };

    const close = () => {
        setLinkFiles('');
    };

    const changeHandler = async (e: any) => {
        const fileInput = e.target;
        const file: File = fileInput.files[0];
        if (file) {
            setLinkFiles(URL.createObjectURL(file));
        } else {
            alert('Ошибка при загрузке файла');
        }
        fileInput.value = '';
    };

    const handleUpload = async () => {
        const cropper = cropperRef.current?.cropper;
        if (!cropper) return;
        cropper.getCroppedCanvas().toBlob(async (blob) => {
            if (blob) {
                const file = new File([blob], 'cropped_image.png', { type: 'image/png' });
                const files = { photo: [file] };
                dispatch(updateUser(id, {}, files));
            }
        }, 'image/png');
        setLinkFiles('');
    };

    return (
        <>
            <div style={{
                width: '100%',
                height: '300px',
                ...centre,
            }}>
                <div style={{
                    width: '300px',
                    height: '300px',
                    borderRadius: '100%',
                    backgroundColor: '#ccc',
                    position: 'relative',
                    ...centre,
                }}>
                    <label style={{
                        position: 'absolute',
                        bottom: '0',
                        left: '50%',
                        transform: 'translate(-50%, 0)',
                        gap: '10px',
                        padding: '5px 10px',
                        background: '#0d1117',
                        borderRadius: '20px',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <div style={{ color: 'white' }}>Изменить</div>
                        <FontAwesomeIcon icon={['fas', 'pen']} style={{ color: 'white' }} />

                        <input
                            type='file'
                            style={{ display: 'none' }}
                            onChange={(e) => changeHandler(e)}
                        />
                    </label>
                    {
                        photo
                            ? <Image src={`${base}${photo}`} alt={'Фотография'} roundedCircle style={{
                                width: '300px',
                                height: '300px',
                            }} />
                            : <Image src={user} alt={'Фотография'} roundedCircle style={{
                                width: '250px',
                                height: '250px',
                            }} />
                    }
                </div>
            </div>
            <OverlayingPopup onClose={close} isOpened={!!linkFiles}>
                <div style={{
                    ...centre,
                    flexDirection: 'column'
                }}>
                    <div className='main_popup'
                        style={{
                            height: '500px',
                            width: '500px',
                            maxHeight: '90vh',
                            maxWidth: '90vw',
                            overflow: 'auto'
                        }}>
                        <Cropper
                            src={linkFiles}
                            style={{ height: '100%', width: '100%' }}
                            initialAspectRatio={NaN}
                            aspectRatio={1}
                            viewMode={1}
                            background={false}
                            guides={false}
                            crop={onCrop}
                            ref={cropperRef}
                            wheelZoomRatio={0}
                            zoomOnWheel={false}
                        />
                    </div>
                    <div className='main_popup'
                        style={{
                            width: '500px',
                            padding: '15px',
                            ...centre
                        }}>
                        <Button
                            size='sm'
                            type='submit'
                            variant='success'
                            className='w-100'
                            onClick={handleUpload}
                        >
                            Установить новое изображение профиля
                        </Button>
                    </div>
                </div>
            </OverlayingPopup>
        </>
    );
};

export default ProfilePhoto;
