import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RSCreateForm from '~/src/components/Realty/Secondary/create';
import {
    selectChangePersonOnHousing,
    unloadAll,
} from '~/src/store/changePersonOnHousingSlice';

export default function RSCreatePage(): JSX.Element {
    const dispatch = useDispatch();
    const { allHousingEstate } = useSelector(selectChangePersonOnHousing);

    useEffect(() => {
        if (allHousingEstate === null) {
            dispatch(unloadAll());
        }
    }, [dispatch, allHousingEstate]);

    return <RSCreateForm />;
}
