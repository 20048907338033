import { createSlice, Slice } from '@reduxjs/toolkit';
import type { TState, TAnyAction } from '~/src/store';

export const sliceName = 'app';

interface IAppSlice {
    redirect?: string;
    activeMenuKey: string;
    currentComponent?: string;
    scrollPosition: Record<string, number>;
    loading: boolean;
    shouldHandleScrollPosition: boolean;
}

type TScrollPosition = {
    key: string;
    position: number;
};

const appSlice: Slice<IAppSlice> = createSlice({
    name: sliceName,
    initialState: {
        activeMenuKey: 'info',
        scrollPosition: {
            dashboard: 0,
        },
        loading: false,
        shouldHandleScrollPosition: false,
    },
    reducers: {
        redirect: (state: IAppSlice, action: TAnyAction<string>) => {
            state.redirect = action.payload;
        },
        menuClick: (state: IAppSlice, action: TAnyAction<string>) => {
            state.activeMenuKey = action.payload;
        },
        setScrollPos: (
            state: IAppSlice,
            action: TAnyAction<TScrollPosition>,
        ) => {
            state.scrollPosition[action.payload.key] = action.payload.position;
        },
        setCurrentComponent: (state: IAppSlice, action: TAnyAction<string>) => {
            state.currentComponent = action.payload;
        },
        setLoading: (state: IAppSlice, action: TAnyAction<boolean>) => {
            state.loading = action.payload;
        },
        setActiveMenuKey: (state: IAppSlice, action: TAnyAction<string>) => {
            state.activeMenuKey = action.payload;
        },
        setHandleScrollPosition: (
            state: IAppSlice,
            action: TAnyAction<boolean>,
        ) => {
            state.shouldHandleScrollPosition = action.payload;
        },
    },
});

export const {
    redirect,
    menuClick,
    setScrollPos,
    setCurrentComponent,
    setLoading,
    setActiveMenuKey,
    setHandleScrollPosition,
} = appSlice.actions;

export const selectApp = (state: TState): IAppSlice => state.app;

export default appSlice.reducer;
