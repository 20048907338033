import React, { useState } from 'react';
import { IRealtySecondaryPrice, IRSModel } from '~/src/store/realtySecondary/realtySecondarySlice';
import OverlayingPopup from '../../Common/ModalUI/OverlayingPopup';
import { Button, Table } from 'react-bootstrap';
import dateFormat from 'dateformat';
import LineChart from '../../Common/Chart/LineChart';

const IListPriceRise = ({ item }: { item: IRSModel }): JSX.Element => {
    const [isOpened, setOpened] = useState(false);

    const close = () => setOpened(false);
    const open = () => setOpened(true);

    const createDataSet = (arr: IRealtySecondaryPrice[]) => {
        const sortArr = [...arr];
        sortArr.sort((a, b) => a.createdAt > b.createdAt ? 1 : -1);
        const dataset = [];
        dataset.push({
            label: dateFormat(item?.createdAt, 'dd.mm.yyyy'),
            data: sortArr[0]?.oldPrice,
            user: `${item?.agent?.lastname || ''} ${item?.agent?.firstname || ''}`,
        });
        sortArr.map((_item, index) => {
            dataset.push({
                label: dateFormat(_item?.createdAt, 'dd.mm.yyyy'),
                data: _item?.newPrice,
                user: `${_item?.userCreatedBy?.lastname || ''} ${_item?.userCreatedBy?.firstname || ''}`,
            });
        });
        return dataset;
    };

    const handlePriceRiseClick = () => {
        if (item?.priceHistory && item?.priceHistory.length > 0) {
            open();
        }
    };

    return (
        <React.Fragment>
            <React.Fragment>
                {item?.priceRise == true ? (
                    <Button variant='outline-warning' onClick={handlePriceRiseClick} className='mb-2 mt-2'>Динамика</Button>
                ) : item?.priceRise == false ? (
                    <Button variant='outline-warning' onClick={handlePriceRiseClick} className='mb-2 mt-2'>Динамика</Button>
                ) : null}
            </React.Fragment>
            <OverlayingPopup onClose={close} isOpened={isOpened}>
                <div className='main_popup' style={{ width: '800px' }}>
                    <LineChart dataSet={ createDataSet(item?.priceHistory) }/>
                </div>
            </OverlayingPopup>
        </React.Fragment>
    );
};

export default IListPriceRise;
