import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '~/src/helpers/hooks';
import { getUsers } from '~/src/store/usersSlice';
import UserList from '~/src/components/Users';
import {
    setActiveMenuKey,
    setHandleScrollPosition,
} from '~/src/store/appSlice';

const UsersPage = (): JSX.Element => {
    const query = useQuery();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUsers(query));
    }, [dispatch, query]);

    useEffect(() => {
        dispatch(setActiveMenuKey('USR'));
    }, [dispatch]);

    useEffect(() => {
        dispatch(setHandleScrollPosition(true));
    }, [dispatch]);

    return <UserList />;
};

export default UsersPage;
