import { useEffect, useState } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import { useDebouncedCallback } from 'use-debounce';
import { FormSpy, useForm } from 'react-final-form';
import InputField from '../../Common/Form/InputField';
import { IUser, getUserByPhone, getUsersByFullname } from '~/src/service/user';

export default function DropDownListAgent(
    props: Record<any, any>,
): JSX.Element {
    const form = useForm();

    const findUserByPhone = async (phone: number) => {
        const _res = await getUserByPhone(phone);
        return _res;
    };

    const { label, name, readOnly, defaultValue, onSelect, children } = props;

    const [value, setValue] = useState('');
    const [show, setShow] = useState(false);
    const [items, setItems] = useState([]);

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    const debounced = useDebouncedCallback((v: string) => {
        if (value && value.length != null && v.length > 3) {
            searchAgent(v);
        }
    }, 1000);

    const handleChange = (v: string) => {
        debounced(v);
    };

    const searchAgent = async (value) => {
        if ((/^\d{10}$/).test(value)) {
            const founded = await findUserByPhone(value);
            if (founded && founded.success) {
                form.mutators.setValue('userUidName', founded.data.fullname);
                form.mutators.setValue('userUidPhone', founded.data.phone);
                form.mutators.setValue('userUid', founded.data.uid);
            }
            setShow(false);
        } else {
            const res = await getUsersByFullname(value);
            if (res && res.length > 0) {
                setShow(true);
            }
            setItems(res);
        }
    };

    const handleSelect = async (item) => {
        setValue(item.fullname);
        onSelect(item);
        setShow(false);
        setItems([]);
    };

    return (
        <Form.Group className='mb-3'>
            <InputField
                label={label}
                name={name}
                autoComplete='off'
                readOnly={readOnly}
            />
            <FormSpy
                subscription={{ values: true, dirty: true, active: true }}
                onChange={({ values, dirty, active }) => {
                    setValue(values.fioAgent);
                    if (values.fioAgent !== value && dirty && active === name) {
                        handleChange(values.fioAgent);
                    }
                }}
            />
            {children}
            <Dropdown show={show}>
                <Dropdown.Menu>
                    {items.map((item: IUser) => (
                        <Dropdown.Item
                            key={item.phone}
                            eventKey={item.phone}
                            onClick={() => handleSelect(item)}
                        >
                            {item.fullname}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </Form.Group>
    );
}
