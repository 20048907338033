import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { create } from '~/src/store/agencySlice';
import { useState } from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePickerField from '../Common/Form/DatePickerField/DatePickerField';
import DropDownSearchApi from '~/src/components/Common/DropDownSearchApi';
import { Button, ButtonGroup, Container, Form, Navbar } from 'react-bootstrap';
import {
    composeValidators,
    required,
    mustBeNumber,
    onlyLength,
} from '~/src/helpers/validators';
import InputField from '../Common/Form/InputField';
import { getUserByPhone } from '~/src/service/user';

export default function CreateForm(): JSX.Element {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [holdSubmit, setHoldSubmit] = useState(false);
    const [directorName, setDirectorName] = useState('');

    const onSubmit = (values) => {
        dispatch(create({ ...values }));
    };

    const findUserByPhone = async (phone: number) => {
        const _res = await getUserByPhone(phone);

        return _res;
    };

    return (
        <FinalForm
            onSubmit={onSubmit}
            initialValues={{}}
            mutators={{
                // expect (field, value) args from the mutator
                setValue: ([field, value], state, { changeValue }) => {
                    changeValue(state, field, () => value);
                },
                setDirectorUidValue: async (
                    [field, value],
                    state,
                    { changeValue },
                ) => {
                    changeValue(state, field, () => value);
                    if (value.length == 10) {
                        setHoldSubmit(true);
                        const founded = await findUserByPhone(value);

                        if (founded && founded.success) {
                            changeValue(
                                state,
                                'directorUid',
                                () => founded.data.uid,
                            );
                            setDirectorName(founded.data.fullname);
                        }
                        setHoldSubmit(false);
                    } else {
                        changeValue(state, 'directorUid', () => null);
                        setDirectorName('');
                    }
                },
            }}
            validate={(values) => {
                const errors: any = {};
                if (!values.addressPlaceDetailId) {
                    errors.addressPlaceDetailId = 'Введите адрес';
                }
                if (!values.directorUid) {
                    errors.directorUid = 'Не указан директор';
                }
                return errors;
            }}
            render={({ handleSubmit, form, submitting, valid }) => (
                <form onSubmit={handleSubmit}>
                    <Navbar
                        className='justify-content-between'
                        bg='light'
                        expand='lg'
                        variant='light'
                    >
                        <ButtonGroup>
                            <Button
                                size='sm'
                                onClick={() => navigate(-1)}
                                disabled={submitting || holdSubmit}
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'fast-backward']}
                                />{' '}
                                Назад
                            </Button>
                            <Button
                                size='sm'
                                type='submit'
                                className='btn btn-sm btn-success'
                                disabled={submitting || holdSubmit || !valid}
                            >
                                <FontAwesomeIcon icon={['fas', 'save']} />{' '}
                                Сохранить
                            </Button>
                        </ButtonGroup>
                    </Navbar>
                    <Container fluid>
                        <div>
                            <DropDownSearchApi
                                label='Адрес'
                                name='address'
                                defaultValue={''}
                                onSelect={(item) => {
                                    form.mutators.setValue(
                                        'addressPlaceDetailId',
                                        item.placeId,
                                    );
                                    form.mutators.setValue(
                                        'address',
                                        item.address,
                                    );
                                }}
                            >
                                <Field name='addressPlaceDetailId'>
                                    {({ meta }) => (
                                        <Form.Group>
                                            <Form.Control.Feedback
                                                type='invalid'
                                                style={{
                                                    display:
                                                        meta.error ||
                                                        meta.submitError
                                                            ? 'block'
                                                            : 'none',
                                                }}
                                            >
                                                {meta.error || meta.submitError}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    )}
                                </Field>
                            </DropDownSearchApi>
                            <Field name='addressNote'>
                                {({ input, meta }) => (
                                    <Form.Group className='mb-2'>
                                        <Form.Label>
                                            Дополнения к адресу
                                        </Form.Label>
                                        <Form.Control
                                            {...input}
                                            type='text'
                                            size='sm'
                                            placeholder='Дополнения к адресу'
                                            isInvalid={
                                                meta.error || meta.submitError
                                            }
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {meta.error || meta.submitError}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                            </Field>
                        </div>
                        <Field name='title' validate={required}>
                            {({ input, meta }) => (
                                <Form.Group className='mb-3'>
                                    <Form.Label>Название</Form.Label>
                                    <Form.Control
                                        {...input}
                                        type='text'
                                        size='sm'
                                        placeholder='Название'
                                        isInvalid={
                                            meta.error || meta.submitError
                                        }
                                    />
                                    <Form.Control.Feedback type='invalid'>
                                        {meta.error || meta.submitError}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            )}
                        </Field>
                        <InputField
                            name='directorPhone'
                            label='Телефон директора'
                            onChange={(e) =>
                                form.mutators.setDirectorUidValue(
                                    'directorPhone',
                                    e.target.value,
                                )
                            }
                            validators={composeValidators(
                                required,
                                mustBeNumber,
                                onlyLength(10),
                            )}
                        />
                        <Field name='directorUid'>
                            {({ meta }) => (
                                <Form.Group>
                                    <Form.Control.Feedback
                                        type='invalid'
                                        style={{
                                            display:
                                                meta.error || meta.submitError
                                                    ? 'block'
                                                    : 'none',
                                        }}
                                    >
                                        {meta.error || meta.submitError}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            )}
                        </Field>
                        <Form.Group className='mb-3'>
                            <Form.Label>Имя директора</Form.Label>
                            <Form.Control
                                size='sm'
                                value={directorName}
                                placeholder='Имя директора'
                                readOnly
                            />
                        </Form.Group>
                        <DatePickerField
                            name='foundationDate'
                            label='Дата основания компании'
                        />
                        <Field
                            name='contactPhone'
                            validate={composeValidators(
                                mustBeNumber,
                                onlyLength(10),
                            )}
                        >
                            {({ input, meta }) => (
                                <Form.Group className='mb-3'>
                                    <Form.Label>Контактный телефон</Form.Label>
                                    <Form.Control
                                        {...input}
                                        size='sm'
                                        isInvalid={
                                            meta.error || meta.submitError
                                        }
                                        placeholder='Контактный телефон'
                                    />
                                    <Form.Control.Feedback type='invalid'>
                                        {meta.error || meta.submitError}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            )}
                        </Field>
                        <Field name='email'>
                            {({ input, meta }) => (
                                <Form.Group className='mb-3'>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        {...input}
                                        size='sm'
                                        isInvalid={
                                            meta.error || meta.submitError
                                        }
                                        placeholder='Email'
                                    />
                                    <Form.Control.Feedback type='invalid'>
                                        {meta.error || meta.submitError}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            )}
                        </Field>
                        <Button
                            size='sm'
                            type='submit'
                            className='btn btn-sm btn-success'
                            disabled={submitting || holdSubmit || !valid}
                        >
                            <FontAwesomeIcon icon={['fas', 'save']} /> Сохранить
                        </Button>
                    </Container>
                </form>
            )}
        />
    );
}
