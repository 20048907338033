import { useDispatch, useSelector } from 'react-redux';

import NavBarInfo from './NavBarInfo';
import Table from 'react-bootstrap/Table';
import UnverifiedRow from './UnverifiedRow';
import Pagination from '~/src/components/Common/Pagination';
import { selectInfo, goTo } from '~/src/store/info.agency.users.slice';

const AgentsList = (): JSX.Element => {
    const dispatch = useDispatch();
    const { unverified: agents } = useSelector(selectInfo);

    const handlePaginationClick = (page: number) => {
        dispatch(goTo({ page }));
    };

    return (
        <div>
            <NavBarInfo />
            {agents && agents.pagination?.have && (
                <Pagination
                    pagination={agents.pagination}
                    onClick={handlePaginationClick}
                />
            )}
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <td>#</td>
                        <td>ФИО</td>
                        <td>Контактные данные</td>
                        <td>Управление</td>
                    </tr>
                </thead>
                <tbody>
                    {agents && agents.items && agents.items.length > 0
                        ? agents.items.map((item) => (
                              <UnverifiedRow key={item.id} item={item} />
                          ))
                        : null}
                </tbody>
            </Table>
            {agents && agents.pagination?.have && (
                <Pagination
                    pagination={agents.pagination}
                    onClick={handlePaginationClick}
                />
            )}
        </div>
    );
};

export default AgentsList;
