import React, { BaseSyntheticEvent, useState } from 'react';

import { Form } from 'react-bootstrap';
import MainPopup from '../Common/ModalUI/MainPopup';
import Loading from '~/src/components/Common/Loading';
import { uploadUsers } from '~/src/service/admin.tools.service';

const UploadUsers = ({ isOpen, onClose }): JSX.Element => {
    const [isLoading, setLoading] = useState(false);

    const changeHandler = async (e: BaseSyntheticEvent) => {
        setLoading(true);
        await uploadUsers({ users: e.target.files });
        setLoading(false);
    };

    return (
        <MainPopup
            isOpened={isOpen}
            title='Выберите файл с пользователями'
            onClose={onClose}
        >
            {isLoading ? (
                <Loading />
            ) : (
                <React.Fragment>
                    <Form.Group className='mb-3'>
                        <label className='btn btn-sm btn-primary'>
                            <input
                                type='file'
                                name='users'
                                onChange={changeHandler}
                            />{' '}
                            Загрузить файл
                        </label>
                    </Form.Group>
                </React.Fragment>
            )}
        </MainPopup>
    );
};

export default UploadUsers;
