import React from 'react';
import DropDownSearchApi from '../Common/DropDownSearchApi';
import { useSelector } from 'react-redux';
import { selectAgency } from '~/src/store/agencySlice';
import { Field, useForm } from 'react-final-form';
import { Form } from 'react-bootstrap';
import InputField from '../Common/Form/InputField';
import DatePickerField from '../Common/Form/DatePickerField/DatePickerField';
import {
    composeValidators,
    isEmail,
    mustBeNumber,
    onlyLength,
    required,
} from '~/src/helpers/validators';

const UpdateForm = () => {
    const { item } = useSelector(selectAgency);

    const form = useForm();

    console.log('render update agency form');

    return (
        <React.Fragment>
            <div>
                <DropDownSearchApi
                    label='Адрес'
                    name='address'
                    defaultValue={item.addressDetail?.formattedAddress || ''}
                    onSelect={(item) => {
                        form.mutators.setValue(
                            'addressPlaceDetailId',
                            item.placeId,
                        );
                        form.mutators.setValue('address', item.address);
                    }}
                >
                    <Field name='addressPlaceDetailId'>
                        {({ meta }) => (
                            <Form.Group>
                                <Form.Control.Feedback
                                    type='invalid'
                                    style={{
                                        display:
                                            meta.error || meta.submitError
                                                ? 'block'
                                                : 'none',
                                    }}
                                >
                                    {meta.error || meta.submitError}
                                </Form.Control.Feedback>
                            </Form.Group>
                        )}
                    </Field>
                </DropDownSearchApi>
                <InputField name='addressNote' label='Дополнение к адресу' />
            </div>
            <InputField name='title' label='Название' validators={required} />
            <DatePickerField
                name='foundationDate'
                label='Дата основания компании'
            />
            <Field
                name='contactPhone'
                validate={composeValidators(mustBeNumber, onlyLength(10))}
            >
                {({ input, meta }) => (
                    <Form.Group className='mb-3'>
                        <Form.Label>Контактный телефон</Form.Label>
                        <Form.Control
                            {...input}
                            size='sm'
                            isInvalid={meta.error || meta.submitError}
                            placeholder='Контактный телефон'
                        />
                        <Form.Control.Feedback type='invalid'>
                            {meta.error || meta.submitError}
                        </Form.Control.Feedback>
                    </Form.Group>
                )}
            </Field>
            <Field name='email' validate={composeValidators(isEmail)}>
                {({ input, meta }) => (
                    <Form.Group className='mb-3'>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            {...input}
                            size='sm'
                            isInvalid={meta.error || meta.submitError}
                            placeholder='Email'
                        />
                        <Form.Control.Feedback type='invalid'>
                            {meta.error || meta.submitError}
                        </Form.Control.Feedback>
                    </Form.Group>
                )}
            </Field>
            <Field name='aboutNote'>
                {({ input, meta }) => (
                    <Form.Group className='mb-3'>
                        <Form.Label>О компании</Form.Label>
                        <Form.Control
                            {...input}
                            as='textarea'
                            rows={5}
                            size='sm'
                            isInvalid={meta.error || meta.submitError}
                            placeholder='О компании'
                        />
                        <Form.Control.Feedback type='invalid'>
                            {meta.error || meta.submitError}
                        </Form.Control.Feedback>
                    </Form.Group>
                )}
            </Field>
        </React.Fragment>
    );
};

export default UpdateForm;
