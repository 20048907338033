import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    read,
    selectSUB,
} from '~/src/store/realtySuburban/realtySuburbanSlice';
import { selectApp, setHandleScrollPosition } from '~/src/store/appSlice';
import RSUBCard from '~/src/components/Realty/Suburban/card';

const RSUBMCardPage = () => {
    const dispatch = useDispatch();
    const params = useParams<{ id: string }>();

    const { item } = useSelector(selectSUB);
    const { loading } = useSelector(selectApp);

    useEffect(() => {
        dispatch(read(+params.id));
    }, [params.id, dispatch]);

    useEffect(() => {
        dispatch(setHandleScrollPosition(false));
    }, [dispatch]);

    return (
        <React.Fragment>
            {loading || !item?.id ? (
                <div>Загрузка...</div>
            ) : (
                <RSUBCard item={item} />
            )}
        </React.Fragment>
    );
};

export default RSUBMCardPage;
