import { createSlice, Slice } from '@reduxjs/toolkit';
import type { TAppDispatch, TState, TAnyAction } from '~/src/store';
import { IRSUBModel } from '~/src/store/realtySuburban/realtySuburbanSlice';
import { read, favorite } from '~/src/service/realty.suburban';

interface IRSUBMap {
    items: IRSUBModel[];
    selectItem: IRSUBModel;
}
const initialState = {
    items: [],
    selectItem: null,
};
export const sliceName = 'RSUBMap';
const imageSlice: Slice<IRSUBMap> = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        setSuburban: (state: IRSUBMap, action: TAnyAction<IRSUBModel>) => {
            const newObj = action.payload;
            if (!state.items.some((item) => item.id === newObj.id)) {
                state.items.push(action.payload);
            }
        },
        setSelectedItem: (state: IRSUBMap, action: TAnyAction<IRSUBModel>) => {
            state.selectItem = action.payload;
        },
        setFavorite: (state: IRSUBMap, action: TAnyAction<number>) => {
            state.items
                .filter((elem) => elem.id == action.payload)
                .map((elems) => (elems.isFavorite = !elems.isFavorite));
        },

        setFavoriteSelectedItem: (state: IRSUBMap) => {
            state.selectItem.isFavorite = !state.selectItem.isFavorite;
        },

        removeSuburban: (state: IRSUBMap, action: TAnyAction<number>) => {
            state.items.splice(action.payload, 1);
        },
        clearItems: (state: IRSUBMap) => {
            state.items = [];
        },
        clearSelectedItem: (state: IRSUBMap) => {
            state.selectItem = null;
        },
    },
});

export const {
    addIdMarker,
    setSuburban,
    removeSuburban,
    clearItems,
    setFavorite,
    setSelectedItem,
    clearSelectedItem,
    setFavoriteSelectedItem,
} = imageSlice.actions;

export const favorites = (id: number) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        const response = await favorite(id);
        if (response.success) {
            dispatch(setFavorite(id));
        }
    };
};

export const favoriteSelectedItem = (item: IRSUBModel) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(setFavoriteSelectedItem(item));
    };
};

export const addItem = (id: number) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        const item = await read(id);
        dispatch(setSuburban(item));
    };
};

export const addSelectedItem = (item: IRSUBModel) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(setSelectedItem(item));
    };
};

export const clearSelectedItemNull = () => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(clearSelectedItem(null));
    };
};

export const deleteItem = (id: number) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(removeSuburban(id));
    };
};

export const clear = () => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(clearItems({}));
    };
};

export const selectRSUBMap = (state: TState): IRSUBMap => state.RSUBMap;

export default imageSlice.reducer;
