import { getAllMap } from '~/src/store/realtySuburban/realtySuburbanSlice';
import BigMapView from '~/src/components/Realty/Suburban/BigMap/BigMap';
import { useDispatch } from 'react-redux';
import { useQuery } from '~/src/helpers/hooks';
import { useEffect, FC } from 'react';

const BigMap: FC = () => {
    const query = useQuery();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllMap(query));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, query.toString()]);

    return <BigMapView />;
};

export default BigMap;
