import PhotoView from './RSPhotoViewPhoto';
import { IFilestore } from '~/src/service/filestore';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { useDispatch } from 'react-redux';
import { setCurrenIndex } from '~/src/store/realtySecondary/realtySecondarySlice';
import React from 'react';

const SortablePhoto = SortableElement<TPhotoProps>(
    ({ photos, photo, onClick }) => {
        return (
            <div>
                <PhotoView photo={photo} photos={photos} onClick={onClick} />
            </div>
        );
    },
);

// eslint-disable-next-line new-cap
const SortableGallery = SortableContainer<TPhotosProps>(
    ({ photos, onClick }) => {
        const dispatch = useDispatch();
        const handleClickPhoto = (index) => {
            dispatch(setCurrenIndex(index));
            onClick();
        };

        return (
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '10px',
                }}
            >
                {photos.map((photo, index) => (
                    <SortablePhoto
                        key={photo.name}
                        index={index}
                        photos={photos}
                        photo={photo}
                        onClick={() => handleClickPhoto(index)}
                    />
                ))}
            </div>
        );
    },
);

type TPhotosProps = {
    photos: IFilestore[];
    onClick: (index) => void;
};

type TPhotoProps = {
    photo: IFilestore;
    photos: IFilestore[];
    onClick: (index) => void;
};

export default SortableGallery;
