import classNames from 'classnames';
import { Form } from 'react-bootstrap';
import { Field } from 'react-final-form';

interface IProps {
    name: string;
    label: string;
    validators?: any;
    type?: string;
    rows?: number;
    initialValue?: string | number;
    placeholder?: string;
    onChange?: (event: React.ChangeEvent | any) => void;
    [otherProp: string]: any;
}

export default function TextareaField({
    name,
    label,
    type,
    rows,
    validators,
    initialValue,
    placeholder,
    onChange,
    ...other
}: IProps): JSX.Element {
    return (
        <Field
            name={name}
            validate={validators}
            initialValue={initialValue}
            allowNull
            parse={(value) => (value === '' ? null : value)}
            {...other}
        >
            {({ input, meta }) => (
                <Form.Group className='mb-3'>
                    <Form.Label>
                        <span>{label}</span>
                        <span
                            className={classNames({
                                'text-info': true,
                                invisible: !meta.dirty,
                            })}
                        >
                            <small> изменено</small>
                        </span>
                    </Form.Label>
                    <Form.Control
                        className={classNames({ 'border-info': meta.dirty })}
                        {...input}
                        as='textarea'
                        rows={rows ?? 5}
                        onChange={
                            typeof onChange === 'function'
                                ? onChange
                                : input.onChange
                        }
                        type={type || 'text'}
                        size='sm'
                        isInvalid={meta.error || meta.submitError}
                        placeholder={placeholder || label}
                    />
                    <Form.Control.Feedback type='invalid'>
                        {meta.error || meta.submitError}
                    </Form.Control.Feedback>
                </Form.Group>
            )}
        </Field>
    );
}
