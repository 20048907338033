const suburbanWaterSuplySearch = [
    { label: 'Все', value: '' },
    { label: 'Колодец', value: 'well' },
    { label: 'Скважина', value: 'chink' },
    { label: 'Центральное', value: 'central' },
];

const suburbanWaterSuply = [
    { title: '', value: '' },
    { title: 'Колодец', value: 'well' },
    { title: 'Скважина', value: 'chink' },
    { title: 'Центральное', value: 'central' },
];

export { suburbanWaterSuplySearch, suburbanWaterSuply };
