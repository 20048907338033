import { Field } from 'react-final-form';
import ToggleButton, { ToggleButtonProps } from 'react-bootstrap/ToggleButton';

const ToggleButtonFieldV2 = ({
    type,
    label,
    name,
    value,
    variantChecked = 'primary',
    variantUnChecked = 'outline-primary',
    size = 'sm',
    onChange,
    checked,
}: IToggleButtonFieldProps): JSX.Element => {
    return (
        <Field name={name} type={type} value={value}>
            {({ input }) => (
                <ToggleButton
                    {...input}
                    type={type}
                    size={size}
                    variant={input.checked ? variantChecked : variantUnChecked}
                    id={`${type}-${name}-${value}`}
                    checked={checked}
                    onChange={
                        typeof onChange === 'function'
                            ? onChange
                            : input.onChange
                    }
                >
                    {label}
                </ToggleButton>
            )}
        </Field>
    );
};

interface IToggleButtonFieldProps extends ToggleButtonProps {
    type: 'radio' | 'checkbox';
    label: string;
    name: string;
    value: string;
    size?: 'sm' | 'lg';
    variantChecked?: string;
    variantUnChecked?: string;
    checked?: boolean;
    onChange?: (event: React.ChangeEvent | any) => void;
}

export { ToggleButtonFieldV2 };
