import queryString from 'query-string';
import * as api from '~/src/service/filestore';
import { IFilestore } from '../service/filestore';
import { createSlice, Slice } from '@reduxjs/toolkit';
import { IResponseListData } from '../interfaces/common';
import { redirect, setLoading as setAppLoading } from './appSlice';
import type { TAppDispatch, TState, TAnyAction } from '~/src/store';

export const sliceName = 'filestore';

interface IFilestoreSlice {
    items: IResponseListData<IFilestore>;
    item?: IFilestore;
    search?: any;
    errors?: any;
    navLink?: string;
    baseLink?: string;
}

const initialState = {
    items: {},
    search: {
        sort: 'id_desc',
        limit: 25,
        page: 1,
    },
    navLink: '/filestore?page=1&limit=25',
    baseLink: '/filestore',
};

const FilestoreSlice: Slice<IFilestoreSlice> = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        setAll: (
            state: IFilestoreSlice,
            action: TAnyAction<IResponseListData<IFilestore>>,
        ) => {
            state.items = action.payload;
        },
        set: (state: IFilestoreSlice, action: TAnyAction<IFilestore>) => {
            state.item = action.payload;
        },
        setErrors: (state: IFilestoreSlice, action: TAnyAction<any>) => {
            state.errors = action.payload;
        },
        setSearch: (state: IFilestoreSlice, action: TAnyAction<any>) => {
            state.search = action.payload;
        },
    },
});

const { setAll, setSearch } = FilestoreSlice.actions;

export type UploadProps = {
    files: File[] | FileList;
    group?: string;
    entry?: number;
    onUploadProgress?: (e) => void;
};

export const upload = ({
    files,
    group,
    entry,
    onUploadProgress,
}: UploadProps) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        const response = await api.upload(files, group, entry, {
            onUploadProgress: onUploadProgress,
        });

        console.log('upload response');
        console.dir(response);
    };
};

export const all = (queryParams: URLSearchParams = null) => {
    return async (dispatch: TAppDispatch): Promise<void> => {
        dispatch(setAppLoading(true));
        const response = await api.all(queryParams);
        const obj = {};

        if (queryParams !== null) {
            for (const key of queryParams.keys()) {
                if (queryParams.getAll(key).length > 1) {
                    obj[key] = queryParams.getAll(key);
                } else {
                    obj[key] = queryParams.get(key);
                }
            }
        }

        dispatch(setSearch(obj));
        dispatch(setAll(response));
        dispatch(setAppLoading(false));
    };
};

export const goTo =
    (params: Record<string, any>) =>
    (dispatch: TAppDispatch, getState: () => TState): void => {
        if (params.reset === true) {
            params = initialState.search;
        } else params = { ...getState().address.search, ...params };

        dispatch(
            redirect(
                `${initialState.baseLink}?${queryString.stringify(params, { skipNull: true })}`,
            ),
        );
    };

export const selectFilestore = (state: TState): IFilestoreSlice =>
    state.filestore;

export default FilestoreSlice.reducer;
