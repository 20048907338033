import { useState } from 'react';

import { Button, Col, Row } from 'react-bootstrap';
import MainPopup from '../Common/ModalUI/MainPopup';
import Loading from '~/src/components/Common/Loading';
import InputField from '../Common/Form/InputField';
import { required } from '~/src/helpers/validators';
import { Form as FinalForm } from 'react-final-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { changePassword } from '~/src/service/user';

type TProps = {
    isOpen: boolean;
    onClose: () => void;
};

const ChangePassword = ({ isOpen, onClose }: TProps): JSX.Element => {
    const [isLoading, setLoading] = useState(false);

    const onSubmit = async (values) => {
        setLoading(true);

        try {
            await changePassword(values.old, values.newpass);
        } finally {
            setLoading(false);
        }
    };

    return (
        <MainPopup isOpened={isOpen} title='Введите данные' onClose={onClose}>
            {isLoading ? (
                <Loading />
            ) : (
                <FinalForm
                    onSubmit={onSubmit}
                    validate={() => {
                        const errors: any = {};
                        return errors;
                    }}
                    render={({ handleSubmit, submitting, valid }) => (
                        <form onSubmit={handleSubmit}>
                            <Row>
                                <Col>
                                    <InputField
                                        name='old'
                                        label='Старый пароль'
                                        type='password'
                                        validators={required}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <InputField
                                        name='newpass'
                                        label='Новый пароль'
                                        type='password'
                                        validators={required}
                                    />
                                </Col>
                            </Row>
                            <Button
                                size='sm'
                                type='submit'
                                className='btn btn-sm btn-success mb-2'
                                disabled={submitting || !valid}
                            >
                                <FontAwesomeIcon icon={['fas', 'save']} />{' '}
                                Сохранить
                            </Button>
                        </form>
                    )}
                />
            )}
        </MainPopup>
    );
};

export default ChangePassword;
