import Api from '~/src/helpers/http-common';
import { IUpdateData } from '../interfaces/common';

export const unloadGroup = async (): Promise<IUnloadGroup> => {
    return await Api.get('/agency/my/unloadgroups');
};

export const read = async (id: number): Promise<IUnloadGroup> => {
    return await Api.get(`/agency/group/${id}`);
};

export const update = async (id: number, data: IUnloadGroup): Promise<IUpdateData<IUnloadGroup>> => {
    return await Api.put<IUpdateData<IUnloadGroup>>(`/agency/group/${id}`, data);
};

export const remove = async (id: string | number): Promise<any> => {
    return await Api.delete(`/agency/group/${id}`);
};

export interface IUnloadGroup {
    id?: number;
    agencyId?: number;
    title?: string;
    createdAt?: string;
}
