import { Link, Navigate } from 'react-router-dom';
import { login } from '~/src/store/authSlice';
import { selectApp } from '~/src/store/appSlice';
import React, { KeyboardEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonGroup } from 'react-bootstrap';
import CustomControl from 'react-leaflet-custom-control';
import { FC, useCallback, useEffect, useState } from 'react';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { all } from '~/src/service/realty.secondary.service';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';

const FormLoginView: FC = () => {
    const dispatch = useDispatch();

    const [phone, setPhone] = useState('');
    const [password, setPass] = useState('');

    const loginHandler = useCallback(() => {
        dispatch(login(phone, password));
    }, [dispatch, phone, password]);

    const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            dispatch(login(phone, password));
        }
    };

    return (
        <div style={{ height: '3rem' }}>
            <div
                style={{
                    display: 'flex',
                    backgroundColor: '#18181b',
                    height: '100%',
                }}
            >
                <div
                    style={{
                        flexGrow: 1,
                        flexShrink: 2,
                        justifyContent: 'flex-end',
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        gap: '4px',
                    }}
                >
                    <div className='me-1'>
                        <Link to='/forgot' className='btn btn-sm btn-info'>
                            Забыли пароль
                        </Link>
                    </div>
                    <div className='me-1'>
                        <Link
                            to='/registration'
                            className='btn btn-sm btn-info'
                        >
                            Регистрация
                        </Link>
                    </div>
                    <div className='qwe'>
                        <input
                            type='text'
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            placeholder='Логин'
                        />
                    </div>
                    <div>
                        <input
                            type='password'
                            value={password}
                            onChange={(e) => setPass(e.target.value)}
                            placeholder='Пароль'
                            onKeyDown={onKeyDown}
                        />
                    </div>
                    <div>
                        <Button
                            variant='outline-info'
                            size='sm'
                            onClick={loginHandler}
                        >
                            Войти
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const LoginView: FC = () => {
    const [markers, setMarkers] = useState(null);

    useEffect(() => {
        const qp = new URLSearchParams({ map: '1' });
        all(qp).then((items) => {
            if (items && items.length > 0) {
                setMarkers(items);
            }
        });
    }, []);

    const { redirect } = useSelector(selectApp);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
            }}
        >
            {redirect && <Navigate to={redirect} replace />}
            <FormLoginView />
            <div style={{ height: '100%' }}>
                <MapContainer
                    style={{ height: '100%', width: '100%' }}
                    center={[57.14941, 65.550389]}
                    zoom={13}
                    maxZoom={18}
                    attributionControl={false}
                >
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url='http://{s}.maps.2gis.com/tiles?x={x}&y={y}&z={z}'
                        subdomains={['tile0', 'tile1', 'tile2', 'tile3']}
                    />

                    {markers && (
                        <MarkerClusterGroup>
                            {markers.map((item) => (
                                <Marker
                                    key={item.id}
                                    position={[item?.latitude, item?.longitude]}
                                    title={item.title}
                                />
                            ))}
                        </MarkerClusterGroup>
                    )}
                </MapContainer>
            </div>
        </div>
    );
};

const ControlContainer = (props) => {
    const [draw, setDraw] = useState(false);

    const toggleDraw = () => setDraw(!draw);

    const map = useMapEvents({
        click(e) {
            // map.flyTo(e.latlng);
            console.log(e);
        },
        mousemove(e) {
            if (draw) {
                console.log('move');
                console.log(e);
            }
        },
    });

    return (
        <CustomControl {...props}>
            <ButtonGroup>
                <Button variant='secondary' onClick={toggleDraw}>
                    {draw ? 'Draw Active' : 'Draw Disable'}
                </Button>
            </ButtonGroup>
        </CustomControl>
    );
};

export default LoginView;
