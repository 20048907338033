import { useState } from 'react';
import React, { useEffect } from 'react';
import { MapContainer, Marker, TileLayer, useMapEvents } from 'react-leaflet';
import iconSimpleBlue from '~/src/components/Common/Map/Marker/SimpleMarker';

type TProps = {
    latitude?: number;
    longitude?: number;
    onClick: (lat: number, lng: number) => void;
};

const MapUpdateView = ({
    latitude,
    longitude,
    onClick,
}: TProps): JSX.Element => {
    const [position, setPosition] = useState(null);

    const handeClick = (p) => {
        onClick(p.lat, p.lng);
    };

    useEffect(() => {
        if (latitude !== undefined && longitude !== undefined) {
            setPosition({ lat: latitude, lng: longitude });
        } else setPosition(null);
    }, [latitude, longitude]);

    return (
        <div style={{ height: 600 }}>
            <MapContainer
                style={{ height: '100%', width: '100%' }}
                center={[latitude || 57.1522, longitude || 65.5272]}
                zoom={13}
                maxZoom={18}
                attributionControl={false}
            >
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url='http://{s}.maps.2gis.com/tiles?x={x}&y={y}&z={z}'
                    subdomains={['tile0', 'tile1', 'tile2', 'tile3']}
                />
                <LocationMarker onClick={handeClick} position={position} />
            </MapContainer>
        </div>
    );
};

const LocationMarker = ({ onClick, position }) => {
    const [clickedPosition, setPosition] = useState(null);

    useEffect(() => {
        setPosition(position);
    }, [position]);

    const map = useMapEvents({
        click(e) {
            console.dir(e);
            map.flyTo(e.latlng);
            setPosition(e.latlng);
            onClick(e.latlng);
        },
    });

    if (clickedPosition !== null) {
        map.flyTo(clickedPosition);
    }

    return clickedPosition === null ? null : (
        <Marker position={clickedPosition} icon={iconSimpleBlue} />
    );
};

export default React.memo(MapUpdateView, (prev, next) => {
    if (prev.latitude === next.latitude && prev.longitude === next.longitude) {
        return true;
    }

    return false;
});
